<div class="fullheight">
    <form [formGroup]="equipmentForm" (ngSubmit)="onSubmit()" class="fullheight__form">
        <div class="form__field">
            <mat-form-field appearance="standard" class="field__formField" [hideRequiredMarker]="true">
                <mat-label>{{ prefix + '.equipment' | translate }}<span class="field__required">*</span></mat-label>
                <mat-select formControlName="referenceEquip" [tabIndex]="tabIndex" class="equipRef">
                    <input matInput placeholder="Recherche" formControlName="searchEquipment" class="searchInput" [ngStyle]="{'height.px': '48', 'padding.px': '0 16', 'margin': '2px 1px 2px 0px', 'border': '3px #1b75bb double', 'width': 'calc(100% - 1px)'}" autocomplete="off"/>
                    <mat-option [ngStyle]="{'font-size.px': '16', 'height': 'auto'}" *ngFor="let equip of referenceEquipment$ | async" [value]="equip.number">
                        <span *ngIf="isNumberOptionDisplay(equip)">({{equip.number}}) </span>{{equip.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form__field">
            <mat-form-field appearance="standard" class="field__formField" [hideRequiredMarker]="true">
                <mat-label>{{ prefix + '.description' | translate }}<span class="field__required" *ngIf="canAddEditTime">*</span></mat-label>
                <mat-select formControlName="referenceEquipCode" [tabIndex]="tabIndex">
                    <input matInput placeholder="Recherche" formControlName="searchEquipmentCode" class="searchInput" [ngStyle]="{'height.px': '48', 'padding.px': '0 16', 'margin': '2px 1px 2px 0px', 'border': '3px #1b75bb double', 'width': 'calc(100% - 1px)'}" autocomplete="off"/>
                    <mat-option [ngStyle]="{'font-size.px': '14', 'height': 'auto', 'letter-spacing': '-0.75px'}" *ngFor="let equipCode of referenceEquipCode$ | async" [value]="equipCode.code">
                        <span *ngIf="isCodeOptionDisplay(equipCode)">({{equipCode.code}}) </span>{{equipCode.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form__field" *ngIf="canAddEditTime">
            <div class="field__row">
                <i class="far fa-clock row__logo"></i>
                <mat-form-field appearance="standard" class="field__formField row__input" [hideRequiredMarker]="true">
                    <mat-label>{{ prefix + '.hours' | translate }}<span class="field__required">*</span></mat-label>
                    <input matInput type="number" [attr.min]="0" [attr.max]="24" formControlName="hour" autocomplete="off" [tabIndex]="tabIndex">
                </mat-form-field>
            </div>
        </div>

        <app-generic-form-buttons [disabled]="equipmentForm.invalid" (cancel)="cancelForm()" [freezeTabIndex]="freezeTabIndex">
        </app-generic-form-buttons>
    </form>
</div>
