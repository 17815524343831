import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { SelectItem } from 'primeng/api';

import { Unit } from '../../../../../shared/models/database/unit.model';
import { TimesheetState } from '../../../../store/reducers';
import { LoadUnitCodes } from '../../../../../ogiv-core/store/actions';
import { Observable, Subscription } from 'rxjs';
import { getActiveUnitCodesOptions, getActiveUnitCodesByCode, getUnitBySearchOptions } from '../../../../../ogiv-core/store/selectors';
import { switchMap, tap } from 'rxjs/operators';
import { UnitCode } from '../../../../../shared/models/database/unit-code.model';
import { OrderType } from '../../../../../shared/models/database/order-type.enum';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';

@Component({
    selector: 'app-work-done-form',
    templateUrl: './work-done-form.component.html',
    styleUrls: ['./work-done-form.component.scss'],
})
export class WorkDoneFormComponent implements OnInit, OnDestroy, OnChanges {
    public prefix = 'timesheet.order.tab.accordionTitle.unitOfWorkDone.form';
    public orderType: OrderType;
    public workDoneForm: FormGroup;
    public isDetailRequired = false;
    private subscriptions: Subscription[] = [];

    public unitCodes$: Observable<SelectItem[]>;

    @Input() public unit: Unit;
    @Input() public postLineId: number | null;
    @Input() public canAddEditTime: boolean;
    @Input() public timesheetDateId: string;
    @Input() public specificDetail: {};
    @Input() public pathChoice: AlternativePath;

    @Output() public create: EventEmitter<Unit> = new EventEmitter<Unit>();
    @Output() public update: EventEmitter<Unit> = new EventEmitter<Unit>();
    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        formBuilder: FormBuilder,
        private readonly store: Store<TimesheetState>
    ) {
        this.workDoneForm = formBuilder.group({
            id: [''],
            postLineId: ['', [Validators.required]],
            code: ['', [Validators.required]],
            description: ['', [Validators.required]],
            quantity: ['', [Validators.required, Validators.min(1)]],
            hour: [0],
            detail: [null],
            fromInventory: [false],
            search: [''],
        });
    }

    public setDetailValidator(isDetailRequired: boolean | null): void {
        if (isDetailRequired) {
            this.isDetailRequired = true;
            this.workDoneForm.controls.detail.setValidators([Validators.required]);
            this.workDoneForm.controls.detail.updateValueAndValidity();
        } else {
            this.isDetailRequired = false;
            this.workDoneForm.controls.detail.clearValidators();
            this.workDoneForm.controls.detail.updateValueAndValidity();
        }
    }

    public ngOnInit(): void {

        this.unitCodes$ = this.store.pipe(
            select(getActiveUnitCodesOptions({ date: this.timesheetDateId })),
        );

        this.subscriptions.push(
            this.workDoneForm.controls.code.valueChanges.pipe(
                switchMap((code: string) => {
                    return this.store.pipe(
                        // eslint-disable-next-line
                        select(getActiveUnitCodesByCode({ code: code, date: this.timesheetDateId })),
                        tap((foundUnitCode: UnitCode | null) => {
                            if (foundUnitCode !== null) {
                                this.workDoneForm.controls.description.setValue(foundUnitCode.description);
                                this.setDetailValidator(foundUnitCode.detail_requis);
                            } else {
                                this.workDoneForm.controls.description.setValue('');
                            }
                            this.workDoneForm.controls.search.setValue('');
                        }),
                    );
                })
            ).subscribe(),

            this.workDoneForm.controls.search.valueChanges.pipe(
                tap((textTosearch: string) => {
                    this.unitCodes$ = this.store.pipe(
                        select(getUnitBySearchOptions({ text: textTosearch, date: this.timesheetDateId })),
                    );
                }),
            ).subscribe(),

            this.workDoneForm.controls.detail.valueChanges.pipe(
                tap((value: string) => {
                    if (value === ' ') {
                        this.workDoneForm.controls.detail.setValue('', { emitEvent: false });
                    }
                })
            ).subscribe(),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.unit && changes.unit.currentValue) {
            this.workDoneForm.reset(changes.unit.currentValue);
            if (this.workDoneForm.controls.fromInventory.value) {
                this.workDoneForm.controls.description.disable();
                this.workDoneForm.controls.quantity.disable();
            } else {
                this.workDoneForm.controls.description.enable();
                this.workDoneForm.controls.quantity.enable();
            }

            if (changes.unit.currentValue.code && changes.unit.currentValue.code !== '') {
                this.store.pipe(
                    select(getActiveUnitCodesByCode({ code: changes.unit.currentValue.code, date: this.timesheetDateId }))
                ).subscribe((unit: UnitCode | null) => {
                    if (unit !== null) {
                        this.setDetailValidator(unit.detail_requis);
                    } else {
                        this.setDetailValidator(false);
                    }
                });
            }
        }

        if (changes && changes.postLineId && changes.postLineId.currentValue) {
            this.workDoneForm.controls.postLineId.setValue(changes.postLineId.currentValue);
        }

        if (changes && changes.specificDetail && changes.specificDetail.currentValue && changes.specificDetail.currentValue.orderType) {
            this.orderType = changes.specificDetail.currentValue.orderType;
            this.store.dispatch(new LoadUnitCodes(this.orderType));
        }

        if (changes && changes.canAddEditTime && changes.canAddEditTime.currentValue) {
            if (changes.canAddEditTime.currentValue) {
                this.workDoneForm.controls.hour.setValidators([Validators.required, Validators.min(0.25)]);
                this.workDoneForm.controls.hour.updateValueAndValidity();
            } else {
                this.workDoneForm.controls.hour.clearValidators();
                this.workDoneForm.controls.hour.updateValueAndValidity();
            }
        }
    }

    public cancelForm(): void {
        this.cancel.emit(true);
    }

    public onSubmit(): void {
        const { valid } = this.workDoneForm;

        const value = {
            ...this.workDoneForm.value,
        };

        if (valid) {
            if (this.unit && this.unit.id) {
                const { search, ...cleanValue } = value;
                this.update.emit(cleanValue);
            } else {
                const { id, fromInventory, search, ...cleanValue } = value;
                this.create.emit(cleanValue);
            }
        }
    }
}
