
<div mat-dialog-title>
    <ng-container *ngIf="logoString !== null">
        <i class="{{ logoString }} logo" ></i>
    </ng-container>
    <h3 class="text">
        {{ title }}
    </h3>
</div>
<div mat-dialog-content>
    <ng-container [ngSwitch]="formName">
        <ng-container *ngSwitchCase="formNameEntrepreneur">
            <app-entrepreneur-form
                [entrepreneur]="entity"
                [entrepreneurs]="entityArray"
                [translatePrefix]="translatePrefix"
                (create)="onCreate($event)"
                (update)="onUpdate($event)"
                (cancel)="onClose()">
            </app-entrepreneur-form>
        </ng-container>
        <ng-container *ngSwitchCase="formNameReassignEntreprise">
            <p>{{message}}</p>
            <app-reassign-entreprise-form
                [translatePrefix]="translatePrefix"
                [utilisateurExterne]="entity"
                (update)="onUpdate($event)"
                (cancel)="onClose()">
            </app-reassign-entreprise-form>
        </ng-container>
        <ng-container *ngSwitchCase="formNameUtilisateurExterne">
            <app-maj-utilisateur-externe-form
                [utilisateurExterne]="entity"
                [translatePrefix]="translatePrefix"
                [isAddMode]="isAddMode"
                (update)="onUpdate($event)"
                (create)="onCreate($event)"
                (cancel)="onClose()">
            </app-maj-utilisateur-externe-form>
        </ng-container>
        <ng-container *ngSwitchCase="formNameUtilisateurInterne">
            <app-maj-utilisateur-interne-form
                [utilisateurInterne]="entity"
                [translatePrefix]="translatePrefix"
                (update)="onUpdate($event)"
                (cancel)="onClose()">
            </app-maj-utilisateur-interne-form>
        </ng-container>
        <ng-container *ngSwitchCase="formNameTableCodesUnite">
            <app-table-code-unite-form
                [translatePrefix]="translatePrefix"
                (create)="onCreate($event)"
                (cancel)="onClose()">
            </app-table-code-unite-form>
        </ng-container>
        <ng-container *ngSwitchCase="formNameEditTimesheetChargeNumber">
            <app-edit-charge-number-form
                [translatePrefix]="translatePrefix"
                (create)="onCreate($event)"
                (cancel)="onClose()">
            </app-edit-charge-number-form>
        </ng-container>
        <ng-container *ngSwitchCase="formNameEditTimesheetCommandePosteType">
            <app-edit-commande-poste-type-form
                [translatePrefix]="translatePrefix"
                (create)="onCreate($event)"
                (cancel)="onClose()">
            </app-edit-commande-poste-type-form>
        </ng-container>
    </ng-container>
</div>
