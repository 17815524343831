import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { tagStatusMap } from '../../models/tag-status-map.model';

@Component({
    selector: 'app-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
    @Input() public set status(value: string) {
        this.tagClass = this.getTagTypeByStatus(value)
            ? `tag--${tagStatusMap[value].type}`
            : '';
    }

    @Input() public borderRadius = 0;
    @Input() public isIndicator = false;

    @Input() public capitalize = true;
    @Input() public isButton = false;

    public tagClass: string;

    private getTagTypeByStatus(status: string): string | null {
        return (status && tagStatusMap[status] && tagStatusMap[status].type) || null;
    }
}
