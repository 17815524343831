import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../core/guards/role.guard';
import { LoadPageGuard } from '../guards';

import { AlternativePath } from '../shared/models/atlernative-path.model';
import { PageSelector } from '../shared/models/page-selector.model';
import { UserRole } from '../shared/models/user-roles.model';
import { DemandesProjetComponent } from './components/demandes-projet.component';
import { DemandesProjetLinksComponent } from './modules/components/demandes-projet-links/demandes-projet-links.component';

const routes: Routes = [
    {
        path: 'hq',
        data: {
            pathChoice: AlternativePath.hq,
            pageSelector: [PageSelector.projectRequest],
            roles: [UserRole.pilote, UserRole.techinter, UserRole.tech, UserRole.planificateur],
        },
        component: DemandesProjetComponent,
        canActivate: [LoadPageGuard, RoleGuard],
        children: [
            {
                path: '',
                component: DemandesProjetLinksComponent,
            }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DemandesProjetRoutingModule { }
