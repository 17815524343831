import { Injectable } from '@angular/core';

import { catchError, switchMap, map, mergeMap, } from 'rxjs/operators';
import { of } from 'rxjs';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { AddOneAppNotification } from '../../../core/store/actions';
import { ApiService } from '../../../ogiv-core/services/api.service';
import { BillingOrder } from '../../../shared/models/database/billing-order.model';
import { BillingOrderActionTypes, LoadBillingOrders, LoadBillingOrdersFail, LoadBillingOrdersSuccess } from '../actions';
import { ErrorNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class BillingOrdersEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public loadBillingOrders$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingOrders>(BillingOrderActionTypes.LOAD_BILLING_ORDERS),
        switchMap((action: LoadBillingOrders) => {
            return this.apiService.get<BillingOrder[]>(`${action.path}/rapports/rapportsfacturation/postecommande`).pipe(
                map((returnedBillingOrder: BillingOrder[]) =>
                    new LoadBillingOrdersSuccess(returnedBillingOrder)
                ),
                catchError((error: any) => of(new LoadBillingOrdersFail(error))),
            );
        }),
    ));

    public loadBillingOrdersFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingOrdersFail>(BillingOrderActionTypes.LOAD_BILLING_ORDERS_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'billing.notifications.loadOrders.error',
            }))
        ]),
    ));
}
