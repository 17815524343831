<div class="message">{{ message | translate }}</div>
<div class="order order__refused">
    <div class="text">
        {{ tranlationPrefix + '.refused' | translate }}
    </div>
</div>
<div class="order order__waiting">
    <div class="text">
            {{ tranlationPrefix + '.waiting' | translate }}
    </div>
</div>
<div class="order order__validated">
    <div class="text">
        {{ tranlationPrefix + '.validated' | translate }}
    </div>
</div>

<div class="order order__locked">
    <div class="text">
        {{ tranlationPrefix + '.locked' | translate }}
    </div>
</div>

<app-generic-form-buttons [displayCancelButton]="displayCancelButton" (save)="onClose()"></app-generic-form-buttons>
