import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserRoleCategory } from '../../../shared/models/user-roles-category.model';
import { UserRole, getUserRoleNameMap } from '../../../shared/models/user-roles.model';

import { UserState } from '../reducers/user.reducer';

export const getUserState = createFeatureSelector<UserState>('user');

export const getUserInfos = createSelector(
    getUserState,
    (state: UserState) => state && state.user || {}
);

export const getUserRoles = createSelector(
    getUserState,
    (state: UserState) => (state && state.roles) || []
);

export const getUserRolesMap = createSelector(
    getUserRoles,
    (userRoles: UserRole[]): string[] => {
        const readableRoles = userRoles.map(userRole => getUserRoleNameMap(userRole));
        return readableRoles || [];
    }
);

export const getUserRoleCategory = createSelector(
    getUserRoles,
    (roles: UserRole[]): UserRoleCategory => {
        let ent = 0;
        let hq = 0;
        roles.forEach((role: UserRole) => {
            switch (role) {
                case UserRole.entrepreneur:
                case UserRole.chefgroupe:
                case UserRole.chefequipe: ent++; break;
                case UserRole.visualisateur:
                case UserRole.pilote:
                case UserRole.techinter:
                case UserRole.tech:
                case UserRole.planificateur:
                case UserRole.cgc:
                case UserRole.support: hq++;
            }
        });

        if (ent > 0 && hq > 0) {
            return UserRoleCategory.both;
        } else if (ent > 0) {
            return UserRoleCategory.ent;
        } else {
            return UserRoleCategory.hq;
        }
    }
);
