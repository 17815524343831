import { Action } from '@ngrx/store';
import { TimesheetReportSummary } from '../../../shared/models/database/timesheet-report-summary.model';

/**
 * Types
 */

export enum TimesheetReportSummaryActionTypes {
    LOAD_TIMESHEET_REPORTS_SUMMARY = '[TimesheetReport] Load Timesheet Reports Summary',
    LOAD_TIMESHEET_REPORTS_SUMMARY_SUCCESS = '[TimesheetReport] Load Timesheet Reports Summary Success',
    LOAD_TIMESHEET_REPORTS_SUMMARY_NO_RESULTS = '[TimesheetReport] Load Timesheet Reports Summary No Results',
    LOAD_TIMESHEET_REPORTS_SUMMARY_FAIL = '[TimesheetReport] Load Timesheet Reports Summary Fail',
}

/**
 * ACTIONS
 */

export class LoadTimesheetReportsSummary implements Action {
    readonly type = TimesheetReportSummaryActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY;
    constructor(public queryString: string, public path: string) { }
}

export class LoadTimesheetReportsSummaryNoresult implements Action {
    readonly type = TimesheetReportSummaryActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_NO_RESULTS;
    constructor(public timesheetReports: TimesheetReportSummary[]) { }
}

export class LoadTimesheetReportsSummarySuccess implements Action {
    readonly type = TimesheetReportSummaryActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_SUCCESS;
    constructor(public timesheetReports: TimesheetReportSummary[]) { }
}

export class LoadTimesheetReportsSummaryFail implements Action {
    readonly type = TimesheetReportSummaryActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_FAIL;
    constructor(public error: any) { }
}

export type TimesheetReportsSummaryAction =
    | LoadTimesheetReportsSummary
    | LoadTimesheetReportsSummaryNoresult
    | LoadTimesheetReportsSummarySuccess
    | LoadTimesheetReportsSummaryFail;
