import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../ogiv-core/services';
import {
    LoadProfilFormationUtilisateur,
    ProfilFormationActionType,
    LoadProfilFormationUtilisateurSuccess,
    LoadProfilFormationUtilisateurFail,
    LoadProfilFormationUtilisateurNoResult
} from '../actions';
import { FormationUtilisateurData, FormationUtilisateurRequest } from '../../models/profil-formation-utilisateur.model';

@Injectable()
export class ProfilFormationUtilisateurEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService
    ) { }

    public loadProfilFormationUtilisateur$ = createEffect(() => this.action$.pipe(
        ofType<LoadProfilFormationUtilisateur>(ProfilFormationActionType.LOAD_PROFIL_FORMATION),
        switchMap((action: LoadProfilFormationUtilisateur) => {
            const liste: FormationUtilisateurRequest = {
                listeUtilisateurs: action.utilisateurs
            };
            return this.apiService.post<FormationUtilisateurData, any>(`${action.path}/pilotage/utilisateurs/actions/consulter/formation`,
                liste).pipe(
                    map((data: FormationUtilisateurData) => {
                        if (data && data.listeFormations && data.listeFormations.length > 0) {
                            return new LoadProfilFormationUtilisateurSuccess(data.listeFormations);
                        } else {
                            return new LoadProfilFormationUtilisateurNoResult([]);
                        }
                    }),
                    catchError((error: any) => of(new LoadProfilFormationUtilisateurFail(error))),
                );

        }),
    ));
}
