import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, switchMap, map, mergeMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiService } from '../../../ogiv-core/services/api.service';
import { Order } from '../../../shared/models/database/order.model';
import { Timesheet } from '../../../shared/models/database/timesheet.model';
import { CreateTimesheetSuccess } from '../../../timesheets/store/actions';
import {
    LoadOrders,
    LoadOrdersFail,
    LoadOrdersSuccess,
    OrderActionTypes,
    CreateOrder,
    LoadOrdersNoresult,
    CreateOrderFail,
    UpdateOneOrder,
    UpdateOneOrderFail,
    CreateOrderSuccess,
    UpdateOneOrderSuccess,
    DeleteOneOrder,
    DeleteOneOrderSuccess,
    DeleteOneOrderFail
} from '../actions/orders.action';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class OrdersEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    /*************************/
    /*******  LOADING  *******/
    /*************************/
    public loadOrders$ = createEffect(() => this.actions$.pipe(
        ofType<LoadOrders>(OrderActionTypes.LOAD_ORDERS),
        switchMap((action: LoadOrders) => {
            return this.apiService.get<Order[]>(`${action.path}/fdt/data/orders`).pipe(
                map((returnedJobCode: Order[]) =>
                    (returnedJobCode.length > 0)
                        ? new LoadOrdersSuccess(returnedJobCode)
                        : new LoadOrdersNoresult()
                ),
                catchError((error: any) => of(new LoadOrdersFail(error))),
            );
        }),
    ));

    /************************/
    /*******  CREATE  *******/
    /************************/
    public createOrder$ = createEffect(() => this.actions$.pipe(
        ofType<CreateOrder>(OrderActionTypes.CREATE_ORDER),
        mergeMap((action: CreateOrder) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/orders`;
                } else {
                    url += `${action.timesheetDateId}/orders`;
                }
            } else {
                url += `${action.timesheetDateId}/orders`;
            }

            return this.apiService.post<Timesheet, Order>(url, action.order).pipe(
                mergeMap((createdTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(createdTimesheet)),
                    new CreateOrderSuccess(),
                ]),
                catchError((error: any) => of(new CreateOrderFail(error))),
            );
        }),
    ));

    public createOrderSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<CreateOrderSuccess>(OrderActionTypes.CREATE_ORDER_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'ogivCore.notifications.order.create.success',
            }))
        ]
        ),
    ));

    public createOrderFail$ = createEffect(() => this.actions$.pipe(
        ofType<CreateOrderFail>(OrderActionTypes.CREATE_ORDER_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'ogivCore.notifications.order.create.error',
            }))
        ]),
    ));

    /************************/
    /*******  UPDATE  *******/
    /************************/
    public updateOneOrder$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneOrder>(OrderActionTypes.UPDATE_ONE_ORDER),
        mergeMap((action: UpdateOneOrder) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/orders`;
                } else {
                    url += `${action.timesheetDateId}/orders`;
                }
            } else {
                url += `${action.timesheetDateId}/orders`;
            }

            return this.apiService.put<Timesheet, Order>(url, action.order).pipe(
                mergeMap((updatedTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(updatedTimesheet)),
                    new UpdateOneOrderSuccess(),
                ]),
                catchError((error: any) => of(new UpdateOneOrderFail(error))),
            );
        }),
    ));

    public updateOneOrderSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneOrderSuccess>(OrderActionTypes.UPDATE_ONE_ORDER_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'ogivCore.notifications.order.update.success',
            }))
        ]
        ),
    ));

    public updateOneOrderFail$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneOrderFail>(OrderActionTypes.UPDATE_ONE_ORDER_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'ogivCore.notifications.order.update.error',
            }))
        ]),
    ));

    /************************/
    /*******  DELETE  *******/
    /************************/
    public deleteOneOrder$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneOrder>(OrderActionTypes.DELETE_ONE_ORDER),
        mergeMap((action: DeleteOneOrder) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/orders/${action.order.id}`;
                } else {
                    url += `${action.timesheetDateId}/orders/${action.order.id}`;
                }
            } else {
                url += `${action.timesheetDateId}/orders/${action.order.id}`;
            }

            return this.apiService.delete<Timesheet>(url).pipe(
                mergeMap((updatedTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(updatedTimesheet)),
                    new DeleteOneOrderSuccess(),
                ]),
                catchError((error: any) => of(new DeleteOneOrderFail(error))),
            );
        }),
    ));

    public deleteOneOrderSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneOrderSuccess>(OrderActionTypes.DELETE_ONE_ORDER_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'ogivCore.notifications.order.delete.success',
            }))
        ]
        ),
    ));

    public deleteOneOrderFail$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneOrderFail>(OrderActionTypes.DELETE_ONE_ORDER_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'ogivCore.notifications.order.delete.error',
            }))
        ]),
    ));
}
