import moment from 'moment';

export enum TypeEngagementEnum {
    A1 = 'A1', // Date de fin potentielle (Engagement à prendre)
    E1 = 'E1', // Engagement Communiqué au Client (Par défaut)
    R1 = 'R1', // Engagement Révisé à la Demande d'HQ
    R2 = 'R2', // Engagement Révisé, Délai Causé par un Tiers
    R3 = 'R3', // Engagement Révisé à la Demande du Client
    R4 = 'R4', // Engagement Révisé, Délai Causé par le Client
}

export enum WorkCenterEnum {
    DVMTLSV1 = 'DVMTLSV1',
    DVHYACV1 = 'DVHYACV1',
    DVDRUMV1 = 'DVDRUMV1',
    DVSBDMV1 = 'DVSBDMV1',
    DVSALAV1 = 'DVSALAV1',
    DVGRNBV1 = 'DVGRNBV1',
    DVSJSRV1 = 'DVSJSRV1',
    DVCHTGV1 = 'DVCHTGV1',
    DVVADOV1 = 'DVVADOV1',
    DVSHRBV1 = 'DVSHRBV1',
    DVTRRVV1 = 'DVTRRVV1',
    DVTHETV1 = 'DVTHETV1',
    DVLEVIV1 = 'DVLEVIV1',
    DVSJDBV1 = 'DVSJDBV1',
    DVRIMOV1 = 'DVRIMOV1',
    DVFOREV1 = 'DVFOREV1',
    DVBLAIV1 = 'DVBLAIV1',
    DVHULLV1 = 'DVHULLV1',
    DVMANIV1 = 'DVMANIV1',
    DVLCHTV1 = 'DVLCHTV1',
    DVJOLIV1 = 'DVJOLIV1',
    DVRIERV1 = 'DVRIERV1',
    DVVMORV1 = 'DVVMORV1',
    DVCHOMV1 = 'DVCHOMV1',
    DVCHICV1 = 'DVCHICV1',
    DVROUYV1 = 'DVROUYV1',
    DVBPRTV1 = 'DVBPRTV1',
    DVQUEBV1 = 'DVQUEBV1',
    DVJEROV1 = 'DVJEROV1',
    DVM30147 = 'DVM30147',
}

export enum MoyenEnum {
    M01 = 'M01', // De personne à personne
    M02 = 'M02', // Appel téléphonique
    M04 = 'M04', // Courriel
    M07 = 'M07', // Message laissé au répondeur, boite vocale ou téléavertisseur
}

export class DemandeClient {
    public globalId: string;
    public pole: string;
    public site: string;
    public statutOGIV: StatutOgivEnum;
    public assigneOGIV: AssigneOGIVEnum | '';
    public notes: string | null;
    public groupeEcart: GroupeEcartEnum | '';
    public ecartEngagement: number | null;
    public contactTechnicien: string | null;
    public numeroOrdre: string;
    public ordre: Ordre;

    constructor(data: DemandeClientRawData) {
        Object.keys(data).forEach((key: string) => {
            if (key === 'Ordre') {
                this[this.convertFirstChar(key)] = new Ordre(data[key]);
            } else {
                if (key.startsWith('Date') && data[key] !== null) {
                    this[this.convertFirstChar(key)] = moment.utc(data[key]).toDate();
                } else {
                    this[this.convertFirstChar(key)] = data[key];
                }
            }
        });
    }

    private convertFirstChar(value: string): string {
        return `${(value.substring(0, 1).toLowerCase())}${value.substring(1)}`;
    }
}

export class NouvelEngagementInfo {
    public moyen: MoyenEnum;
    public prisPar: string;
    public justificationInfos: JustificationInfo[] | null;
}

export class JustificationInfo {
    public numerosOrdre: string[];
    public justification: string;
}

export class RemarqueInfo {
    public numerosOrdre: string[];
    public remarque: string;
}

export class Ordre {
    public numeroOrdre: string; // Char 12
    public poste: string;  // Char 3
    public ligne: string;  // Char 3
    public statutSysteme: string;
    public statutUtilisateur: string;
    public designation: string; // Description
    public remarque: string;
    public remarqueAbregee?: string;
    public dateDebutPlan: Date | null;
    public adresse: string;
    public rue: string;
    public ville: string;
    public codePostal: string;
    public dateEngagement: Date | null;
    public typeEngagement: TypeEngagementEnum | '';
    public nombreOperations: number;
    public indexOperation?: number;
    public operationCourante?: Operation;
    public operations: Operation[];
    public priorite: string; // PrioriteClick
    public lot: string; // Clé de planification
    public identificationTechnique: string; // LCLC
    public typeOrdre: string;
    public division: string;
    public numeroClient: string;
    public nomClient: string;
    public telephoneClient: string;
    public personneContact: string;
    public langueContact: string;
    public pieceJointeFlag: boolean;
    public timestampEtag: Date;
    public moyen: MoyenEnum;
    public prisPar: string;
    public justification: string;
    public commande: string;

    constructor(data: OrdreRawData) {
        Object.keys(data).forEach((key: string) => {
            if (key === 'Operations') {
                this[this.convertFirstChar(key)] = data[key].map((operation: OperationRawData) => new Operation(operation));
            } else {
                if (key.startsWith('Date') && data[key] !== null || key === 'TimestampEtag') {
                    this[this.convertFirstChar(key)] = moment.utc(data[key]).toDate();
                } else {
                    this[this.convertFirstChar(key)] = data[key];
                }
            }
        });
    }

    private convertFirstChar(value: string): string {
        return `${(value.substring(0, 1).toLowerCase())}${value.substring(1)}`;
    }
}

export class Operation {
    public numeroOperation: string;
    public statutSysteme: string; // Demande en cour SAP
    public designation: string; // Description
    public matricule: string;
    public nomResponsable: string; // provient de SQL
    public workCenter: WorkCenterEnum | ''; // Poste de travail
    public dateStatutLance: Date | null;
    public statutUtilisateur: string;
    public clefReference: string;
    public dateFinTravaux?: Date;

    constructor(data: OperationRawData) {
        Object.keys(data).forEach((key: string) => {
            if (key.startsWith('Date') && data[key] !== null) {
                this[this.convertFirstChar(key)] = moment.utc(data[key]).toDate();
            } else {
                this[this.convertFirstChar(key)] = data[key];
            }
        });
    }

    private convertFirstChar(value: string): string {
        return `${(value.substring(0, 1).toLowerCase())}${value.substring(1)}`;
    }
}

export interface DemandeClientRawData {
    globalId: string;
    pole: string;
    site: string;
    statutOGIV: string;
    assigneOGIV: string;
    notes: string | null;
    groupeEcart: string | null;
    ecartEngagement: number | null;
    contactTechnicien: string | null;
    numeroOrdre: string;
    ordre: OrdreRawData;
}

export interface OrdreRawData {
    numeroOrdre: string; // Char 12
    poste: string;  // Char 3
    ligne: string;  // Char 3
    statutSysteme: string;
    statutUtilisateur: string;
    designation: string; // Description
    remarque: string;
    remarqueAbregee?: string;
    dateDebutPlan: Date;
    adresse: string;
    rue: string;
    ville: string;
    codePostal: string;
    dateEngagement: Date;
    typeEngagement: string;
    nombreOperations: number;
    indexOperation?: number;
    operationCourante?: Operation;
    operations: OperationRawData[];
    priorite: string; // PrioriteClick
    lot: string; // Cle de planification
    identificationTechnique: string;
    typeOrdre: string;
    division: string;
    numeroClient: string;
    nomClient: string;
    telephoneClient: string;
    personneContact: string;
    langueContact: string;
    pieceJointeFlag: boolean;
    timestampEtag: Date;
    moyen: string | null;
    prisPar: string;
    justification: string;
}

export class OperationRawData {
    public numeroOperation: string;
    public statutSysteme: string; // Demande en cour SAP
    public designation: string; // Description
    public matricule: string;
    public nomResponsable: string; // provient de SQL
    public workCenter: string; // Poste de travail
    public dateStatutLance: Date;
    public statutUtilisateur: string;
    public clefReference: string;
}

export interface UpdateDemandeClient {
    globalIds: string[];
    dateEngagement: Date | null;
    typeEngagement: TypeEngagementEnum | null;
    nouvelEngagement: NouvelEngagementInfo | null;
    statutOGIV: StatutOgivEnum | null;
    assigneOGIV: AssigneOGIVEnum | null;
    notes: string | null;
    contactTechnicien: string | null;
    remarqueInfos: RemarqueInfo[] | null;
    numerosOrdre: string[];
}

export interface EngagementDemandeClient {
    dateEngagement: Date | null;
    typeEngagement: TypeEngagementEnum | null;
    moyen: MoyenEnum;
    prisPar: string;
    justificationInfos: JustificationInfo[] | null;
}

export interface FailedUpdates {
    numeroOrdre: string;
    relatedField: string;
    message: string;
}

export class UpdateDemandeClientResultRawData {
    successCount: number;
    failedCount: number;
    failedUpdates: FailedUpdates[];
    demandesClientsOGIV: DemandeClientRawData[];
}

export class UpdateDemandeClientResult {
    successCount: number;
    failedCount: number;
    failedUpdates: FailedUpdates[];
    demandesClientsOGIV: DemandeClient[];

    constructor(data: UpdateDemandeClientResultRawData) {
        Object.keys(data).forEach((key: string) => {
            if (key === 'demandesClientsOGIV') {
                this[key] = [];
                data[key].forEach((demande: DemandeClientRawData, index: number) => this[key][index] = new DemandeClient(demande));
            } else {
                this[key] = data[key];
            }
        });
    }
}

export interface DemandeClientOGIV {
    pole: string;
    site: string;
    statutOGIV: string | null;
    assigneOGIV: string | null;
    notes: string | null;
    numeroOrdre: string;
    groupeEcart: string | null;
    ecartEngagement: number | null;
    contactTechnicien: string | null;
}

/** ------------------------------------------------------ **/
/** |                                                    | **/
/** |          Attention:                                | **/
/** |    / \     On désative la convention de nomage     | **/
/** |   / ! \    car les noms proviennent du backend     | **/
/** |  /_____\    (eslint-disable ...)                   | **/
/** |                                                    | **/
/** ------------------------------------------------------ **/

/* eslint-disable @typescript-eslint/naming-convention*/
export enum StatutOgivEnum {
    Filtrage = 'Filtrage',
    AttenteClientLorsFiltrage = 'Attente client lors filtrage',
    DemandeTermineeAuFiltrage = 'Demande terminée au filtrage',
    Visite = 'Visite',
    AttenteClientLorsVisite = 'Attente client lors visite',
    DemandeTermineeVisite = 'Demande terminée à la visite',
    Travaux = 'Travaux',
    MesureParticuliereTravaux = 'Mesure particulière travaux',
    TravauxExecutes = 'Travaux exécutés',
    DemandeTermineeTravaux = 'Demande terminée aux travaux',
    PrescriptionsMultiples = 'Prescriptions multiples',
}

export enum AssigneOGIVEnum {
    Lecture = 'Lecture',
    Lu = 'Lu',
    FiltreurEnSite = 'Filtreur en site',
    TechnicienIntermédiaire = 'Technicien intermédiaire',
}

export enum GroupeEcartEnum {
    V1a9 = 'V1 à 9',
    V10a19 = 'V10 à 19',
    V20plus = 'V20 et +',
    VhorsDelais = 'Vhors délais',
    '1a9' = '1 à 9',
    '10a19' = '10 à 19',
    '20plus' = '20 et +',
    horsDelais = 'Hors délais',
}
