import { createFeatureSelector } from '@ngrx/store';

import { specialMeasuresReducer, PrescriptionsState } from './special-measures.reducers';

export interface SpecialMeasuresState {
    prescriptions: PrescriptionsState;
}

export const specialMeasuresStoreReducers: any = {
    prescriptions: specialMeasuresReducer
};

export const getSpecialMeasuresState = createFeatureSelector<SpecialMeasuresState>('specialMeasures');
