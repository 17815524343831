import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UtilisateurInterne } from '../../models/utilisateur-interne.model';
import {
    UtilisateurInterneAction,
    UtilisateurInterneActionType
} from '../actions';

export interface UtilisateursInternesState extends EntityState<UtilisateurInterne> {
    loading: boolean;
    loaded: boolean;
}

export const utilisateursInternesAdapter: EntityAdapter<UtilisateurInterne> = createEntityAdapter<UtilisateurInterne>({
    selectId: (utilisateurInterne: UtilisateurInterne) => utilisateurInterne.id,
});

export const utilisateursInternesInitialState = utilisateursInternesAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function utilisateurInterneReducer(
    state: UtilisateursInternesState = utilisateursInternesInitialState,
    action: UtilisateurInterneAction,
): UtilisateursInternesState {
    switch (action.type) {
        case UtilisateurInterneActionType.UPDATE_ONE_UTILISATEUR_INTERNE:
        case UtilisateurInterneActionType.LOAD_UTILISATEURS_INTERNES: {
            return {
                ...state,
                loading: true,
            };
        }

        case UtilisateurInterneActionType.UPDATE_ONE_UTILISATEUR_INTERNE_SUCCESS: {
            return utilisateursInternesAdapter.upsertOne(action.utilisateurInterne, {
                ...state,
                loading: false,
            });
        }

        case UtilisateurInterneActionType.UPDATE_ONE_UTILISATEUR_INTERNE_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case UtilisateurInterneActionType.LOAD_UTILISATEURS_INTERNES_SUCCESS: {
            return utilisateursInternesAdapter.setAll(action.utilisateursInternes, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case UtilisateurInterneActionType.LOAD_UTILISATEURS_INTERNES_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case UtilisateurInterneActionType.LOAD_UTILISATEURS_INTERNES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;

    }
}
