<form [formGroup]="engagementForm">
    <p-panel>
        <div class="p-field p-grid engagement-field inFormButtonAction" [class.hideActionContainer]="!parentFormIsEditing">
            <div *ngIf="parentFormIsEditing" [class.actionsContainer]="parentFormIsEditing">
                <app-generic-other-menu
                    [deleteOnly]="true"
                    [addborder]="true"
                    [isRedButton]="true"
                    [disabledButton]="!isNouvelEngagementClicked"
                    (delete)="annulerEngagementModifications()"></app-generic-other-menu>
                <app-generic-other-menu
                    [addOnly]="true"
                    [addborder]="true"
                    (add)="nouvelEngagement()"></app-generic-other-menu>
            </div>
        </div>
        <div class="p-field p-grid engagement-field">
            <label class="p-col-4">{{ commonTranslatePrefix + 'DateEngagement' | translate }} *</label>
            <div class="p-col-8">
                <p-calendar [disabled]="!parentFormIsEditing || !isNouvelEngagementClicked"
                    formControlName="dateEngagement" [dateFormat]="calendarDateFormat"
                    [placeholder]="translatePrefix + 'selectDate' | translate"
                    [ngClass]="{'p-invalid': dateEngagement.errors?.required }"
                    aria-describedby="dateEngagement-help" appendTo="body" [showButtonBar]="true"
                    (change)="clearDate('dateEngagement')"
                    (onClearClick)="clearDate('dateEngagement')"
                    (onSelect)="selectDate($event, 'dateEngagement')">
                </p-calendar>
                <small id="dateEngagement-help" class="p-invalid"
                    *ngIf="dateEngagement.errors?.required">
                    {{ translatePrefix + 'errors.required' | translate }}
                </small>
            </div>
        </div>
        <div class="p-field p-grid engagement-field">
            <label class="p-col-4">{{ commonTranslatePrefix + 'TypeEngagement' | translate }} *</label>
            <div class="p-col-8">
                <p-dropdown *ngIf="typeEngagementSelectOptions" [options]="typeEngagementSelectOptions"
                    [filter]="true" appendTo="body" formControlName="typeEngagement"
                    [disabled]="!parentFormIsEditing || !isNouvelEngagementClicked"
                    placeholder="{{ translatePrefix + 'selectTypeEngagement' | translate }}"
                    [ngClass]="{'p-invalid': typeEngagement.errors?.required }"
                    aria-describedby="typeEngagement-help"
                    (onChange)="onChange()">
                </p-dropdown>
                <small id="typeEngagement-help" class="p-invalid"
                    *ngIf="typeEngagement.errors?.required">
                    {{ translatePrefix + 'errors.required' | translate }}
                </small>
            </div>
        </div>
        <div class="p-field p-grid engagement-field">
            <label class="p-col-4">{{ translatePrefix + 'moyen' | translate }} *</label>
            <div class="p-col-8">
                <p-dropdown *ngIf="moyenSelectOptions" [options]="moyenSelectOptions" [filter]="true"
                    appendTo="body" formControlName="moyen"
                    [disabled]="!parentFormIsEditing || !isNouvelEngagementClicked"
                    placeholder="{{ translatePrefix + 'selectMoyen' | translate }}"
                    [ngClass]="{'p-invalid': moyen.errors?.required }" aria-describedby="moyen-help"
                    (onChange)="onChange()">
                </p-dropdown>
                <small id="moyen-help" class="p-invalid" *ngIf="moyen.errors?.required">
                    {{ translatePrefix + 'errors.required' | translate }}
                </small>
            </div>
        </div>
        <div class="p-field p-grid engagement-field">
            <label class="p-col-4">{{ translatePrefix + 'prisPar' | translate }} *</label>
            <div class="p-col-8">
                <p-dropdown [options]="$any(prisParSelectOptions$ | async)" [filter]="true" appendTo="body"
                    formControlName="prisPar" [disabled]="!parentFormIsEditing || !isNouvelEngagementClicked"
                    placeholder="{{ translatePrefix + 'selectResponsable' | translate }}"
                    [ngClass]="{'p-invalid': prisPar.errors?.required }"
                    aria-describedby="prisPar-help"
                    (onChange)="onChange()">
                </p-dropdown>
                <small id="prisPar-help" class="p-invalid" *ngIf="prisPar.errors?.required">
                    {{ translatePrefix + 'errors.required' | translate }}
                </small>
            </div>
        </div>
        <div class="p-field p-grid engagement-field">
            <label class="p-col-4">{{ translatePrefix + 'justification' | translate }} *</label>
            <div class="p-col-8">
                <textarea pInputTextarea formControlName="justification" maxlength="255"
                    [attr.disabled]="!parentFormIsEditing || !isNouvelEngagementClicked || demandesClient.length > 1 ? true : null" rows="5"
                    [ngClass]="{'p-invalid': justification.errors?.required }"
                    aria-describedby="justification-help" (change)="onChange()">
                </textarea>
                <small id="justification-help" class="p-invalid" *ngIf="justification.errors?.required">
                    {{ translatePrefix + 'errors.required' | translate }}
                </small>
            </div>
        </div>
    </p-panel>
</form>
