import moment, { Moment } from 'moment';
import { unitOfTime } from 'moment-timezone';

export class DateHelpers {
    public static getCurrentYear = (): number => {
        return moment().year();
    };

    public static getCurrentDate = (): Date => {
        return moment().toDate();
    };

    public static getNow = (): number => {
        return Date.now();
    };

    public static getUTCDate = (date?: Moment | string | Date, format?: string): Moment => {
        return moment(date, format).utc();
    };

    public static areSameDates(date1: Moment | Date | string, date2: Moment | Date | string, granularity?: unitOfTime.StartOf): Boolean {
        return moment(date1).isSame(date2, granularity as any);
    };
}
