import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Output, EventEmitter, ViewChildren, QueryList, AfterViewInit, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { Table } from 'primeng/table';
import { FilterService } from 'primeng/api';
import { MatCheckbox, MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';

import { select, Store } from '@ngrx/store';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { GestionCodesSAP, GestionCodesSAPStatus } from '../../../../models/gestion-code-sap.model';
import { TableColumns } from '../../../../../shared/models/table-columns.model';
import { trackByColumnField } from '../../../../../shared/helpers/track-by.helper';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { Observable } from 'rxjs';
import { getAllGestionCodesSAPFormated, getGestionCodesSAPLoading } from '../../../../store/selectors';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { LoadGestionCodesSAP, UpdateOneGestionCodeSAP } from '../../../../store/actions';
import { gestionCodeSAPColumns } from './gestion-codes-sap-table.column';
import { LoadEquipmentCodes, LoadJobCodes, LoadMealCodes, LoadTransportCodes, LoadSapBillingItems } from '../../../../../ogiv-core/store/actions';
import {
    doesSelectedBillingItemsIsActive,
    doesSelectedEquipmentCodeIsActive,
    doesSelectedJobCodeIsActive,
    doesSelectedMealCodeIsActive,
    doesSelectedTransportCodeIsActive,
    getMealCodesLoading,
    getTransportCodesLoading
} from '../../../../../ogiv-core/store/selectors';

export interface GestionCodeSapError {
    ratio: boolean;
    article_unite: boolean;
    article_nature_comptable: boolean;
}

@Component({
    selector: 'app-gestion-codes-sap-table',
    templateUrl: './gestion-codes-sap-table.component.html',
    styleUrls: ['./gestion-codes-sap-table.component.scss'],
    providers: [
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'check' } as MatCheckboxDefaultOptions }
    ]
})
export class GestionCodesSapTableComponent implements OnInit, OnChanges, AfterViewInit {
    public selectedStatus = '';
    public trackByColumnField = trackByColumnField;
    public inputTextColumnNameFilter: string[] = [];
    public dynamicField = {};
    public dynamicFieldDependOn: string[] = [];
    public checkboxesField: string[] = [];
    public modifiedData = false;
    public originalGestionCodesSAP: GestionCodesSAP[];
    public cloneGestionCodesSAP: GestionCodesSAP[] = [];
    public filterChange = true;
    public rowIndiexOnEdition: number | null = null;
    public rowIsEditMode = false;

    public disableInputButton: { [key: string]: boolean } = {};
    public divStatusForDependencies: { [key: string]: boolean } = {};
    public statusEditButtons: boolean[] = [];
    public disabledUnitArticle = false;
    public disabledCheckButton = false;
    public inputErrror = false;

    public dateFiltersCreeLe: Moment[];
    public dateFiltersModifieLe: Moment[];

    public cancelEdit = 'fas fa-times';
    public editLogo = 'fas fa-pen';
    public checkLogo = 'fas fa-check';
    public exclamationMarkLogo = 'fas fa-exclamation-triangle';
    public gestionCodesSAP: GestionCodesSAP[] = [];
    public loaderLogoSize = 75;
    public columns: TableColumns[] = gestionCodeSAPColumns;
    public uiErrors: GestionCodeSapError = {
        ratio: false,
        article_unite: false,
        article_nature_comptable: false
    };

    public currentMatCheckboxes: MatCheckbox[] = [];

    @Input() public translatePrefix: string;
    @Input() public sortField: string;
    @Input() public sortOrder: number;

    @Input() public pathChoice: AlternativePath;
    @Input() public reloadData = false;

    @Output() public selectedModule: EventEmitter<string> = new EventEmitter();
    @Output() public reloadedDone: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('gestionCodeSAPDataTable') gestionCodeSAPDataTable: Table;
    @ViewChildren('editeCheckbox') editeCheckbox: QueryList<MatCheckbox>;
    @ViewChildren('inputContainers') inputContainers: QueryList<ElementRef>;

    public statuses: any[] = [
        { label: this.translateService.instant('pilotages.gestionCodesSAP.importSAP.statuses.new'), value: GestionCodesSAPStatus.new },
        { label: this.translateService.instant('pilotages.gestionCodesSAP.importSAP.statuses.treated'), value: GestionCodesSAPStatus.treated },
        { label: this.translateService.instant('pilotages.gestionCodesSAP.importSAP.statuses.all'), value: GestionCodesSAPStatus.all },
    ];

    /**********************************************************************/
    // Observables from store
    /**********************************************************************/
    // gestion code SAP
    public gestionCodesSAP$: Observable<GestionCodesSAP[] | null> = this.store.pipe(
        select(getAllGestionCodesSAPFormated),
        distinctUntilChanged(),
        tap((gestionCodesSAP: GestionCodesSAP[]) => {
            this.reloadedDone.emit(true);
            this.reset(gestionCodesSAP, true);
            this.resetVisual();
        }),
    );

    public gestionCodesSAPLoading$: Observable<boolean> = this.store.pipe(
        select(getGestionCodesSAPLoading),
    );

    // Transport-code
    // public transportCodes$: Observable<TransportCode[] | null> = this.store.pipe(
    //     select(getAllTransportCodes),
    // );

    public transportCodesLoading$: Observable<boolean> = this.store.pipe(
        select(getTransportCodesLoading),
    );

    public mealCodesLoading$: Observable<boolean> = this.store.pipe(
        select(getMealCodesLoading),
    );

    public constructor(
        private readonly store: Store,
        public dialog: MatDialog,
        private translateService: TranslateService,
        private filterService: FilterService,
    ) { }

    /**********************************************************************/
    // Angular life cycle Hooks methods
    /**********************************************************************/
    public loadSapValues(): void {
        this.store.dispatch(new LoadTransportCodes());
        this.store.dispatch(new LoadMealCodes());
        this.store.dispatch(new LoadEquipmentCodes());
        this.store.dispatch(new LoadJobCodes());
        this.store.dispatch(new LoadSapBillingItems());
    }

    public loadAllValues(): void {
        this.store.dispatch(new LoadGestionCodesSAP(this.pathChoice));
        this.loadSapValues();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.reloadData && changes.reloadData.currentValue) {
            this.loadAllValues();
        }
    }

    public ngOnInit(): void {
        this.loadAllValues();
        this.selectedModule.emit('GestionCodesSAP');

        // Added for MB-ANGULAR-11
        this.filterService.register('dateRangeFilterModifieLe', (value: any): boolean => {
            return this.filtrerDates(value, this.dateFiltersModifieLe);
        });

        // Added for MB-ANGULAR-11
        this.filterService.register('dateRangeFilterCreeLe', (value: any): boolean => {
            return this.filtrerDates(value, this.dateFiltersCreeLe);
        });

        // // Remove for MB-ANGULAR-11
        // FilterUtils['dateRangeFilterModifieLe'] = (value: any): boolean => {
        //     return this.filtrerDates(value, this.dateFiltersModifieLe);
        // };

        // // Remove for MB-ANGULAR-11
        // FilterUtils['dateRangeFilterCreeLe'] = (value: any): boolean => {
        //     return this.filtrerDates(value, this.dateFiltersCreeLe);
        // };

        this.selectedStatus = this.statuses[0].value;

        if (this.columns) {
            this.columns.forEach((column: TableColumns) => {
                if (column.type === undefined) {
                    this.checkboxesField.push(column.field);
                } else if (column.type === 'string' || column.type === 'number') {
                    if (column.type === 'string') {
                        this.inputTextColumnNameFilter.push(column.field);
                    }

                    this.setDynamicFields(column);
                }
            });
        }
    }

    public ngAfterViewInit(): void {
        this.editeCheckbox.changes.subscribe(data => {
            return data.forEach((checkbox: MatCheckbox) => {
                this.currentMatCheckboxes.forEach((currentCheckbox: MatCheckbox) => {
                    if (currentCheckbox.id !== checkbox.id) {
                        this.currentMatCheckboxes.push(checkbox);
                    }
                });
            });
        });
    }

    public setDynamicFields(column: TableColumns) {
        if (column.isDynamic && column.dependOnField) {
            if (this.dynamicField[column.dependOnField] !== undefined) {
                this.dynamicField[column.dependOnField].push(column.field);
            } else {
                this.dynamicField[column.dependOnField] = [column.field];
            }

            if (!this.dynamicFieldDependOn.includes(column.dependOnField)) {
                this.dynamicFieldDependOn.push(column.dependOnField);
            }

            if (this.disableInputButton[column.field] === undefined) {
                this.disableInputButton[column.field] = false;
            }
        }
    }

    /**********************************************************************/
    // Class methods
    /**********************************************************************/
    /**************************/
    // Ré-initialiser les datas
    /**************************/
    public createStatusForDependentInput(gestionCodesSAP: GestionCodesSAP[]): void {
        gestionCodesSAP.forEach((gestionCodeSAP: GestionCodesSAP) => {
            if (this.divStatusForDependencies[`div-article_unite-${gestionCodeSAP.id}`] === undefined) {
                this.divStatusForDependencies[`div-article_unite-${gestionCodeSAP.id}`] = gestionCodeSAP.article === null ? false : gestionCodeSAP.article;
            }

            if (this.divStatusForDependencies[`div-article_nature_comptable-${gestionCodeSAP.id}`] === undefined) {
                this.divStatusForDependencies[`div-article_nature_comptable-${gestionCodeSAP.id}`] = gestionCodeSAP.article === null ? false : gestionCodeSAP.article;
            }

            if (this.divStatusForDependencies[`div-ratio-${gestionCodeSAP.id}`] === undefined) {
                this.divStatusForDependencies[`div-ratio-${gestionCodeSAP.id}`] = gestionCodeSAP.emploi === null ? false : gestionCodeSAP.emploi;
            }
        });
    }

    public reset(gestionCodesSAP: GestionCodesSAP[], applyStatusFilter: boolean): void {
        this.gestionCodesSAP = [...gestionCodesSAP];
        this.originalGestionCodesSAP = [...gestionCodesSAP];

        if (this.gestionCodeSAPDataTable) {
            this.createStatusForDependentInput(gestionCodesSAP);

            if (applyStatusFilter) {
                this.applyStatusFilter(gestionCodesSAP);
            }
        }
    }

    public resetVisual(): void {
        this.cloneGestionCodesSAP = [];
        this.modifiedData = false;
    }

    public resetAllStatus(): void {
        this.gestionCodesSAP.forEach((gestionCodeSap: GestionCodesSAP) => {
            this.statusEditButtons[gestionCodeSap.id] = false;
        });
    }

    public disableAllStatusExcept(gestionCodeSapId: number): void {
        this.gestionCodesSAP.forEach((gestionCodeSap: GestionCodesSAP) => {
            this.statusEditButtons[gestionCodeSap.id] = gestionCodeSap.id !== gestionCodeSapId;
        });
    }

    public inputDisabled(checked: boolean | null, columnName: any/*, gestionCodeSAP: GestionCodesSAP*/): void {
        checked = checked || false;
        if (this.dynamicFieldDependOn.includes(columnName)) {
            this.dynamicField[columnName].forEach((element: string) => {
                this.disableInputButton[element] = !checked;
            });
        }
    }

    public disableCheckboxInEditMode(fieldName: string, rowData: GestionCodesSAP): boolean {
        return (fieldName === 'unite_travail' && (rowData[fieldName] || false));
    }

    public checkRequiredArticleField(clonedData: GestionCodesSAP): boolean {
        if (clonedData['article']) {
            this.uiErrors['article_unite'] = clonedData['article_unite'] === undefined
                || clonedData['article_unite'] === null
                || clonedData['article_unite'].length === 0;

            this.uiErrors['article_nature_comptable'] = clonedData['article_nature_comptable'] === undefined
                || clonedData['article_nature_comptable'] === null
                || Number(clonedData['article_nature_comptable']) === 0;
        } else {
            this.uiErrors['article_unite'] = false;
            this.uiErrors['article_nature_comptable'] = false;
        }

        return (this.uiErrors['article_unite'] || this.uiErrors['article_nature_comptable']);
    }

    public checkRequiredEmploiField(clonedData: GestionCodesSAP): boolean {
        if (clonedData['emploi']) {
            this.uiErrors['ratio'] = clonedData['ratio'] === undefined || clonedData['ratio'] === null || Number(clonedData['ratio']) <= 0;
        } else {
            this.uiErrors['ratio'] = false;
        }

        return this.uiErrors['ratio'];
    }

    public checkRequiredField(clonedData: GestionCodesSAP): boolean {
        const emploiError = this.checkRequiredEmploiField(clonedData);
        const articleError = this.checkRequiredArticleField(clonedData);

        return (emploiError || articleError);
    }

    public disableCheckButton(rowIndex: number): void {
        const fieldFail: boolean[] = [];

        this.dynamicFieldDependOn.forEach((field: string) => {
            const fieldValue = this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id][field] || false;

            this.dynamicField[field].forEach((element: string) => {
                const dynamicFieldValue = this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id][element];

                if (fieldValue && (dynamicFieldValue === undefined || dynamicFieldValue === null || dynamicFieldValue === '')) {
                    fieldFail.push(true);
                }
            });
        });

        if (this.checkRequiredField(this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id])) {
            fieldFail.push(true);
        }

        if (this.modifiedData) {
            if (fieldFail.length > 0) {
                this.disabledCheckButton = true;
            } else {
                this.disabledCheckButton = false;
            }
        } else {
            this.disabledCheckButton = true;
        }
    }

    /**************************/
    // Filtrer les datas
    /**************************/
    public inputFilterColumn(value: Event, field: string, filterMatchMode?: string): void {
        this.columnFilter((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains');
    }
    // Appeler pour filtrer les colonnes
    public columnFilter(value: string | boolean | null, columnName: string, matchMode: string): void {
        this.filterChange = true;
        this.gestionCodeSAPDataTable.filter(value, columnName, matchMode);
    }

    // Appeler seulement par les checkboxes
    public checkboxFilter(checked: boolean, columnName: string, matchMode: string): void {
        const value = checked || null;
        this.columnFilter(value, columnName, matchMode);
    }

    public applyStatusFilter(gestionCodesSAP: GestionCodesSAP[]): void {
        this.gestionCodeSAPDataTable.editingRowKeys = {};
        this.rowIndiexOnEdition = null;

        const NEW_STATUS = this.statuses[0].value;
        const CODE_WITH_STATUT_NEW = gestionCodesSAP.filter((codeSAP: GestionCodesSAP) => codeSAP.statut === NEW_STATUS);
        if (CODE_WITH_STATUT_NEW.length > 0) {
            this.columnFilter(this.statuses[0].value, 'statut', 'equals');
            this.selectedStatus = this.statuses[0].value;
        } else {
            this.columnFilter(this.statuses[2].value, 'statut', 'equals');
            this.selectedStatus = this.statuses[2].value;
        }
    }

    public restructureObjects(event: any): void {
        if (this.filterChange) {
            this.filterChange = false;
            this.reset(event.filteredValue, false);

            this.gestionCodesSAP.forEach((gestionCodeSap: GestionCodesSAP) => {
                this.statusEditButtons[gestionCodeSap.id] = false;
            });
        }
    }

    public shouldDisplayInputFilter(col: TableColumns): boolean {
        return this.inputTextColumnNameFilter.includes(col.field);
    }

    public filtrerDates(value: any, dateFilters: Moment[]): boolean {
        if (dateFilters) {
            const tableValue = moment(value);
            const firstFilterDate = moment(dateFilters[0]);
            const secondFilterDate = moment(dateFilters[1]);

            if (tableValue.isValid() && firstFilterDate.isValid()) {
                if (secondFilterDate.isValid()) {
                    return tableValue.isSameOrAfter(firstFilterDate, 'day') && tableValue.isSameOrBefore(secondFilterDate, 'day');
                } else {
                    return tableValue.isSame(firstFilterDate, 'day');
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    /**************************/
    // Modification du data
    /**************************/
    public saveValue(value: string | number | boolean, columnName: any, rowIndex: number): void {
        const codeSAP = this.gestionCodesSAP[rowIndex];
        if (codeSAP && this.cloneGestionCodesSAP[codeSAP.id][columnName] !== undefined) {
            this.cloneGestionCodesSAP[codeSAP.id][columnName] = value;
            this.areDataModified(rowIndex);
            this.disableCheckButton(rowIndex);
        }
    }

    public saveInput(value: string | number, gestionCodeSAP: GestionCodesSAP, columnName: any) {
        const rowIndex = this.getIndexOfInOriginal(gestionCodeSAP.id);
        this.inputErrror = false;
        const myValue = typeof (value) === 'string' ? value.trim() : value;

        if (columnName === 'article_unite' && (typeof (myValue) === 'string' && (myValue.length === 0 || myValue.length > 50))) {
            this.inputErrror = true;
            this.saveValue(myValue, columnName, rowIndex);
            this.disableCheckButton(rowIndex);
        } else {
            this.saveValue(myValue, columnName, rowIndex);
            this.disableCheckButton(rowIndex);
        }
    }

    public displayErrorExist(checked: boolean, gestionCodeSAP: GestionCodesSAP, columnName: string): void {
        const realColumnName = this.translateService.instant(this.translatePrefix + '.codeExist.columnName.' + columnName);
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.codeExist' + '.title'),
                message: this.translateService.instant(this.translatePrefix + '.codeExist' + '.message', { code: gestionCodeSAP.code, table: realColumnName }),
                translatePrefix: this.translatePrefix + '.codeExist.boutons',
                logoString: 'fas fa-exclamation',
                isForDelete: true,
                displayDeleteButton: false
            } as ConfirmModalComponent,
            width: '560px',
            maxWidth: '560px',
            height: 'fit-content'
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                const originalValue = !checked;
                this.editeCheckbox.forEach((checkbox: MatCheckbox) => {
                    if (checkbox.id === `checkbox-${columnName}-${gestionCodeSAP.id}`) {
                        checkbox.checked = originalValue;
                    }
                });
            }
        });
    }

    public finalSavingCheckbox(checked: boolean, columnName: any, rowIndex: number/*, gestionCodeSAP: GestionCodesSAP*/) {
        this.saveValue(checked, columnName, rowIndex);
        this.inputDisabled(checked, columnName/*, gestionCodeSAP*/);
    }

    public displayDependencies(checked: boolean, columnName: any, gestionCodeSAP: GestionCodesSAP) {
        if (columnName === 'article') {
            this.divStatusForDependencies[`div-article_unite-${gestionCodeSAP.id}`] = checked; // gestionCodeSAP.article === null ? false : checked;
            this.divStatusForDependencies[`div-article_nature_comptable-${gestionCodeSAP.id}`] = checked; // gestionCodeSAP.article === null ? false : checked;
        }

        if (columnName === 'emploi') {
            this.divStatusForDependencies[`div-ratio-${gestionCodeSAP.id}`] = checked; // gestionCodeSAP.emploi === null ? false : checked;
        }
    }

    public async saveCheckbox(event: any, gestionCodeSAP: GestionCodesSAP, columnName: any): Promise<void> {
        const checked = event.checked;
        const rowIndex = this.getIndexOfInOriginal(gestionCodeSAP.id);
        this.displayDependencies(checked, columnName, gestionCodeSAP);

        if (checked) {
            if (await this.verifyIfExist(checked, columnName, rowIndex)) {
                this.displayErrorExist(checked, gestionCodeSAP, columnName);
            } else {
                this.finalSavingCheckbox(checked, columnName, rowIndex/*, gestionCodeSAP*/);
            }
        } else {
            this.finalSavingCheckbox(checked, columnName, rowIndex/*, gestionCodeSAP*/);
        }
    }

    public async verifyIfExist(value: boolean, columnName: string, rowIndex: number): Promise<boolean> {
        const codeSAP = this.gestionCodesSAP[rowIndex];
        const originalCodeSAP = this.originalGestionCodesSAP[rowIndex];
        let itDoesExist = false;

        if (!value || (value && value === originalCodeSAP[columnName])) {
            return itDoesExist;
        } else if (value && value !== originalCodeSAP[columnName]) {
            switch (columnName) {
                case 'emploi': await this.store.select(doesSelectedJobCodeIsActive({ code: codeSAP.code || '' })).subscribe(t => itDoesExist = t); break;
                case 'transport': await this.store.select(doesSelectedTransportCodeIsActive({ code: codeSAP.code || '' })).subscribe(t => itDoesExist = t); break;
                case 'repas': await this.store.select(doesSelectedMealCodeIsActive({ code: codeSAP.code || '' })).subscribe(t => itDoesExist = t); break;
                case 'equipement': await this.store.select(doesSelectedEquipmentCodeIsActive({ code: codeSAP.code || '' })).subscribe(t => itDoesExist = t); break;
                case 'article': await this.store.select(doesSelectedBillingItemsIsActive({ code: codeSAP.code || '' })).subscribe(t => itDoesExist = t); break;
            }
            return itDoesExist;
        } else {
            return itDoesExist;
        }
    }

    public resetDependencies(gestionCodeSAP: GestionCodesSAP) {
        this.displayDependencies(gestionCodeSAP.emploi === null ? false : gestionCodeSAP.emploi, 'emploi', gestionCodeSAP);
        this.displayDependencies(gestionCodeSAP.article === null ? false : gestionCodeSAP.article, 'article', gestionCodeSAP);
    }

    public cancelModification(result: boolean, rowIndex: number): void {
        if (result) {
            if (this.rowIndiexOnEdition !== null && this.rowIndiexOnEdition !== rowIndex) {
                if (this.gestionCodeSAPDataTable.editingRowKeys[this.gestionCodesSAP[this.rowIndiexOnEdition].id]) {
                    delete this.gestionCodeSAPDataTable.editingRowKeys[this.gestionCodesSAP[this.rowIndiexOnEdition].id];
                }

                if (this.cloneGestionCodesSAP[this.gestionCodesSAP[this.rowIndiexOnEdition].id]) {
                    delete this.cloneGestionCodesSAP[this.gestionCodesSAP[this.rowIndiexOnEdition].id];
                }
            }

            if (this.rowIndiexOnEdition === rowIndex) {
                this.resetDependencies(this.gestionCodesSAP[this.rowIndiexOnEdition]);

                this.gestionCodesSAP[this.rowIndiexOnEdition] = this.originalGestionCodesSAP[this.rowIndiexOnEdition];
                if (this.cloneGestionCodesSAP[this.gestionCodesSAP[this.rowIndiexOnEdition].id]) {
                    delete this.cloneGestionCodesSAP[this.gestionCodesSAP[this.rowIndiexOnEdition].id];
                }
                delete this.gestionCodeSAPDataTable.editingRowKeys[this.gestionCodesSAP[this.rowIndiexOnEdition].id];
                this.areDataModified(this.rowIndiexOnEdition);
                this.rowIndiexOnEdition = null;
            }
            this.inputErrror = false;

            this.resetAllStatus();

        } else {
            if (this.rowIndiexOnEdition !== null) {
                this.gestionCodesSAP[this.rowIndiexOnEdition] = this.originalGestionCodesSAP[this.rowIndiexOnEdition];
            }
        }
    }

    public areDataModified(rowIndex: number): void {
        const dynamicFieldKeys = Object.keys(this.dynamicField);
        const dynamicFieldValue: string[] = [];
        // dynamicFieldKeys.forEach((key: string) => dynamicFieldValue.push(this.dynamicField[key]));
        dynamicFieldKeys.forEach((key: string) =>
            this.dynamicField[key].forEach((value: string) => dynamicFieldValue.push(value))
        );

        const keys = Object.keys(this.gestionCodesSAP[rowIndex]);
        const originalValues: any = {};
        const clonedValues: any = {};

        if (this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id] !== undefined) {
            keys.forEach((key: string) => {
                if (dynamicFieldValue.includes(key)) {
                    originalValues[key] = this.originalGestionCodesSAP[rowIndex][key] === null ? '' : this.originalGestionCodesSAP[rowIndex][key];
                    clonedValues[key] = this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id][key] === null ?
                        '' : this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id][key];
                } else {
                    if (this.checkboxesField.includes(key)) {
                        originalValues[key] = (this.originalGestionCodesSAP[rowIndex][key] === null || this.originalGestionCodesSAP[rowIndex][key] === false) ?
                            '' : this.originalGestionCodesSAP[rowIndex][key];
                        clonedValues[key] = (this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id][key] === null
                            || this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id][key] === false) ?
                            '' : this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id][key];
                    } else {
                        originalValues[key] = this.originalGestionCodesSAP[rowIndex][key];
                        clonedValues[key] = this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id][key];
                    }
                }
            });

            const original = JSON.stringify(originalValues);
            const cloned = JSON.stringify(clonedValues);

            if (cloned === original) {
                this.modifiedData = false;
            } else {
                this.modifiedData = true;
            }
        } else {
            this.modifiedData = false;
        }
    }

    public emptyObject(object: any): boolean {
        return Object.keys(object).length === 0;
    }

    /**************************/
    // Interaction avec utilisateur
    /**************************/
    public changeSelection(gestionCodeSAP: GestionCodesSAP, rowIndex: number): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.changeSelecytionConfirmModalComponent' + '.title'),
                message: this.translateService.instant(this.translatePrefix + '.changeSelecytionConfirmModalComponent' + '.message'),
                translatePrefix: this.translatePrefix + '.changeSelecytionConfirmModalComponent',
                logoString: this.cancelEdit,
                isForDelete: true,
            } as ConfirmModalComponent,
            width: '525px',
            height: 'fit-content'
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            this.cancelModification(result, rowIndex);
            this.finishOnRowEditInit(gestionCodeSAP, rowIndex, result);
        });
    }

    public finishOnRowEditInit(gestionCodeSAP: GestionCodesSAP, rowIndex: number, result: boolean): void {
        if (result) {
            if (this.rowIndiexOnEdition !== null && this.rowIndiexOnEdition !== rowIndex) {
                if (this.gestionCodeSAPDataTable.editingRowKeys[this.gestionCodesSAP[this.rowIndiexOnEdition].id]) {
                    delete this.gestionCodeSAPDataTable.editingRowKeys[this.gestionCodesSAP[this.rowIndiexOnEdition].id];
                }

                if (this.cloneGestionCodesSAP[this.gestionCodesSAP[this.rowIndiexOnEdition].id]) {
                    delete this.cloneGestionCodesSAP[this.gestionCodesSAP[this.rowIndiexOnEdition].id];
                }
            }
            this.gestionCodeSAPDataTable.editingRowKeys = { [gestionCodeSAP.id]: true };
            this.disableAllStatusExcept(gestionCodeSAP.id);

            if (this.cloneGestionCodesSAP[gestionCodeSAP.id]) {
                this.gestionCodesSAP[rowIndex] = { ...this.cloneGestionCodesSAP[gestionCodeSAP.id] };
                this.dynamicFieldDependOn.forEach((field: string) => {
                    this.inputDisabled(this.gestionCodesSAP[rowIndex][field], field/*, this.gestionCodesSAP[rowIndex]*/);
                });
                this.disableCheckButton(rowIndex);
            } else {
                this.cloneGestionCodesSAP[gestionCodeSAP.id] = { ...this.gestionCodesSAP[rowIndex] };
                this.dynamicFieldDependOn.forEach((field: string) => {
                    this.inputDisabled(this.gestionCodesSAP[rowIndex][field], field/*, this.gestionCodesSAP[rowIndex]*/);
                });
            }

            this.rowIndiexOnEdition = rowIndex;
            this.rowIsEditMode = true;
        }
    }

    public onRowEditInit(gestionCodeSAP: GestionCodesSAP, event: any): void {
        event.stopPropagation();
        this.loadSapValues();
        const rowIndex = this.getIndexOfInOriginal(gestionCodeSAP.id);

        if (this.modifiedData) {
            if (!this.emptyObject(this.gestionCodeSAPDataTable.editingRowKeys)) {
                this.changeSelection(gestionCodeSAP, rowIndex);
            }
        } else {
            this.finishOnRowEditInit(gestionCodeSAP, rowIndex, true);
            this.rowIndiexOnEdition = rowIndex;
        }
    }

    public sendDataToServer(rowIndex: number): void {
        const dataToSend = {
            ...this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id]
        };
        delete this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id];
        this.store.dispatch(new UpdateOneGestionCodeSAP(dataToSend, this.pathChoice));
    }

    public onRowEditSave(gestionCodeSAP: GestionCodesSAP, event: any): void {
        event.stopPropagation();
        const rowIndex = this.getIndexOfInOriginal(gestionCodeSAP.id);
        delete this.gestionCodeSAPDataTable.editingRowKeys[this.gestionCodesSAP[rowIndex].id];
        this.rowIndiexOnEdition = null;
        this.rowIsEditMode = false;

        if (this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id]) {
            if (this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id]['unite_travail']) {
                const dialogRef = this.dialog.open(ConfirmModalComponent, {
                    data: {
                        title: this.translateService.instant(this.translatePrefix + '.saveUnitOfWork' + '.title'),
                        message: this.translateService.instant(this.translatePrefix + '.saveUnitOfWork' + '.message'),
                        translatePrefix: this.translatePrefix + '.saveUnitOfWork',
                        logoString: this.exclamationMarkLogo,
                        isForDelete: true,
                        displayDeleteButton: false,
                        logoFontSize: 24,
                    } as ConfirmModalComponent,
                    width: '525px',
                    height: 'fit-content'
                });

                dialogRef.afterClosed().subscribe(() => {
                    this.sendDataToServer(rowIndex);
                });
            } else {
                this.sendDataToServer(rowIndex);
            }
        }
    }

    public onRowEditCancel(gestionCodeSAP: GestionCodesSAP, event: any): void {
        event.stopPropagation();
        const rowIndex = this.getIndexOfInOriginal(gestionCodeSAP.id);

        if (this.modifiedData) {
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                data: {
                    title: this.translateService.instant(this.translatePrefix + '.cancelConfirmModalComponent' + '.title'),
                    message: this.translateService.instant(this.translatePrefix + '.cancelConfirmModalComponent' + '.message'),
                    translatePrefix: this.translatePrefix + '.cancelConfirmModalComponent',
                    logoString: this.cancelEdit,
                    isForDelete: true,
                    logoFontSize: 24,
                } as ConfirmModalComponent,
                width: '525px',
                height: 'fit-content'
            });

            dialogRef.afterClosed().subscribe((result: boolean) => {
                this.cancelModification(result, rowIndex);
            });
        } else {
            if (this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id]) {
                delete this.cloneGestionCodesSAP[this.gestionCodesSAP[rowIndex].id];
            }
            delete this.gestionCodeSAPDataTable.editingRowKeys[this.gestionCodesSAP[rowIndex].id];
            this.rowIndiexOnEdition = null;
            this.rowIsEditMode = false;
            this.resetAllStatus();
        }
    }

    public getCssClass(type: 'article' | 'emploi', col: TableColumns, gestionCodeSAP: GestionCodesSAP): boolean {
        const rowIndex = this.getIndexOfInOriginal(gestionCodeSAP.id);
        if (type === 'article') {
            return rowIndex === this.rowIndiexOnEdition && this.rowIsEditMode
                && (col.field === 'article' || col.field === 'article_unite' || col.field === 'article_nature_comptable');
        }

        if (type === 'emploi') {
            return rowIndex === this.rowIndiexOnEdition && this.rowIsEditMode && (col.field === 'emploi' || col.field === 'ratio');
        }

        return false;
    }

    public getIndexOfInOriginal(searchId: number) {
        return this.gestionCodesSAP.findIndex((item: GestionCodesSAP) => {
            return item.id === searchId;
        });
    }

    public getMinNumber(colField: string) {
        let minNumber = -99999;
        if (colField === 'ratio' || colField === 'article_nature_comptable') {
            minNumber = 1;
        }
        return minNumber;
    }
}
