import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TreeTableModule } from 'primeng/treetable';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';

export const reportsVendorsModules = [
    // Angular Material
    MatDialogModule,
    MatExpansionModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDividerModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatInputModule,

    // Prime NG
    ButtonModule,
    TableModule,
    CalendarModule,
    ToastModule,
    TooltipModule,
    FieldsetModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule,
    MenubarModule,
    TreeTableModule,
    MultiSelectModule
];
