import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalWindowData } from './modal-window-data.model';

@Component({
    selector: 'app-modal-window',
    templateUrl: './modal-window.component.html',
    styleUrls: ['./modal-window.component.scss'],
})
export class ModalWindowComponent {
    public icon: string;
    public componentName: string;

    public translatePrefix: string;
    public titleKey: string;

    public report?: any | null;
    public message?: string;
    public displayDeleteButton: boolean;

    constructor(
        public dialogRef: MatDialogRef<ModalWindowComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ModalWindowData,
    ) {
        this.icon = data.icon;
        this.componentName = data.componentName;
        this.translatePrefix = data.translatePrefix || '';
        this.titleKey = data.titleKey || '';
        this.report = data.report || null;
        this.message = data.message || '';
        this.displayDeleteButton = data.displayDeleteButton == null ? true : data.displayDeleteButton;
    }

    public isTranslatePrefixEmpty(): boolean {
        return this.translatePrefix === '';
    }

    public onClose(): void {
        this.dialogRef.close();
    }

    public onCreate(event: any): void {
        this.dialogRef.close(event);
    }

    public onUpdate(event: any): void {
        this.dialogRef.close(event);
    }

    public onSave(event: any): void {
        this.dialogRef.close(event);
    }

    public onCancel(event: any): void {
        this.dialogRef.close(event);
    }
}
