import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { AppNotificationActions } from '../actions';
import { AppNotification } from '../models/app-notification.model';

export interface AppNotificationsState extends EntityState<AppNotification> { }

export const appNotificationsAdapter = createEntityAdapter<AppNotification>();

const initialState: AppNotificationsState = appNotificationsAdapter.getInitialState();

export function appNotificationsReducer(
    state: AppNotificationsState = initialState,
    action: AppNotificationActions
): AppNotificationsState {
    switch (action.type) {
        default:
            return state;
    }
}
