import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { OgivHelpers } from '../../../../../../shared/helpers/ogiv.helper';
import { trackByColumnField } from '../../../../../../shared/helpers/track-by.helper';
import { TableColumns } from '../../../../../../shared/models/table-columns.model';
import { OrderType } from '../../../../../../shared/models/database/order-type.enum';
import { Table } from 'primeng/table';
import { MatDialog } from '@angular/material/dialog';
import { ModalWindowComponent } from '../../../../../../shared/components/modal-window/modal-window.component';
import { ModalWindowData } from '../../../../../../shared/components/modal-window/modal-window-data.model';

@Component({
    selector: 'app-timesheet-report-part-table',
    templateUrl: './timesheet-report-part-table.component.html',
    styleUrls: ['./timesheet-report-part-table.component.scss'],
})
export class TimesheetReportPartTableComponent implements OnChanges {
    @Input() public translatePrefix: string;
    @Input() public sortField: string;
    @Input() public disableSort = false;
    @Input() public weekDayLabelLength = 3;
    @Input() public sortOrder: number;
    @Input() public reportPartData: any[];
    @Input() public cols: TableColumns[];
    @Input() public billingType: OrderType;

    @Input() public showTotal = true;
    @Input() public alignTotalColumn = false;
    @Input() public multipleColumnTotals = false;
    @Input() public hideColumnCode = false;

    @ViewChild('timesheetReportPartTable') timesheetReportPartTable: Table;

    public currentBillingType: OrderType;
    public displayCurrentTotal: string;
    public displayCurrentUnitHours: boolean;
    public trackByColumnField = trackByColumnField;

    constructor(
        public dialog: MatDialog,
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.billingType && changes.billingType.currentValue) {
            this.currentBillingType = changes.billingType.currentValue;
            if (changes.billingType.currentValue !== OrderType.outage) {
                this.cols.forEach((value, index) => {
                    if (value.field === 'outageNumber') {
                        this.cols.splice(index, 1);
                    }
                });
            }
            switch (changes.billingType.currentValue) {
                case OrderType.unit: {
                    this.showTotal = false;
                    this.multipleColumnTotals = true;
                    this.alignTotalColumn = false;
                } break;

                default: {
                    if (this.multipleColumnTotals) {
                        this.showTotal = false;
                    }
                }
            }
        }
    }

    public getRowTotal(data: any): number {
        return Object.keys(data).reduce((acc: number, weekDayCode: string) => {
            if (OgivHelpers.isWeekReportDay(weekDayCode)) {
                return acc + (data[weekDayCode] as number);
            }

            return acc;
        }, 0);
    }

    public getOverallTotal(): number {
        return this.reportPartData.reduce((acc: any, data: any) => {
            return acc + this.getRowTotal(data);
        }, 0);
    }

    public areAllNonFilterableColumns(): boolean {
        return this.cols.every(col => col.noFilter);
    }

    public getColumnTotal(column: TableColumns): number {
        return this.reportPartData.reduce((acc: any, data: any) => {
            return acc + data[column.field];
        }, 0);
    }

    public hasColumnTotal(column: TableColumns): boolean {
        return column.columnTotal || false;
    }

    public getColspanBeforeFirstTotal(): number {
        const span: number =
            this.cols.findIndex((col: TableColumns) => {
                return col.columnTotal;
            });

        if (span >= 0) {
            return span;
        }

        return 0;
    }

    public displayTimeSheetColumn(column: TableColumns): boolean {
        let visible = true;
        if (this.currentBillingType === OrderType.unit && column.field === 'hours') {
            visible = false;
        }
        if (this.currentBillingType !== OrderType.outage && column.field === 'outageNumber') {
            visible = false;
        }
        return visible;
    }

    public displayTotal(rowIndex: number): string {
        if (rowIndex === this.reportPartData.length - 1) {
            if (!this.multipleColumnTotals && !this.showTotal) {
                return 'none';
            } else {
                if (this.multipleColumnTotals) {
                    this.showTotal = false;
                    return 'multiTotal';
                } else if (this.showTotal) {
                    return 'singleTotal';
                }
            }
        }

        return 'null';
    }

    public displayCodeColumn(column: TableColumns): boolean {
        if (column.type === 'code' && this.billingType === OrderType.unit && this.hideColumnCode) {
            return false;
        }
        return true;
    }

    public filterColumn(value: Event, field: string, filterMatchMode?: string): void {
        this.timesheetReportPartTable.filter((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains');
    }

    public detailClicked(detail: string): void {
        this.dialog.open(ModalWindowComponent, {
            width: '80%',
            maxWidth: '650px',
            data: {
                icon: 'fas fa-exclamation',
                componentName: 'info',
                translatePrefix: this.translatePrefix,
                titleKey: 'detail',
                message: detail,
                displayDeleteButton: false,
            } as ModalWindowData
        });
    }

    public columnTooltipIsDisabled(col: TableColumns): boolean {
        return !col.hasOwnProperty('toolTipField') || (col.type === 'weekDay' && this.currentBillingType !== OrderType.unit);
    }
}
