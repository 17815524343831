import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { BillingReport } from '../../../../../shared/models/database/billing-report.model';
import { FormMode } from '../../../../../shared/models/form-mode.model';
import { getAllBillingReports, getBillingReportsLoading } from '../../../../store/selectors/billing-report.selector';
import { Go } from '../../../../../core/store/actions/router.actions';
import { LoadBillingReports } from '../../../../store/actions/billing-report.action';
import { ModalWindowComponent } from '../../../../../shared/components/modal-window/modal-window.component';
import { ModalWindowData } from '../../../../../shared/components/modal-window/modal-window-data.model';
import { TableColumns } from '../../../../../shared/models/table-columns.model';
import { TableAction, TableActionIconType, TableActionType, TableEventParam } from '../../../../../shared/models/table-action.model';

@Component({
    selector: 'app-cgc-billing-reports-list',
    templateUrl: './cgc-billing-reports-list.component.html',
    styleUrls: ['./cgc-billing-reports-list.component.scss'],
})
export class CGCBillingReportsListComponent implements OnInit {
    public sortField = 'createdDate';
    public sortOrder = -1;
    public translatePrefix = 'billing.cgc.summary';
    public commonTranslatePrefix = 'billing.common.summary';
    public pathChoice = AlternativePath.hq;

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<any>,
    ) { }

    public reports: BillingReport[];
    public cols: TableColumns[] = [
        { field: 'company', header: 'company' },
        { field: 'invoiceNumber', header: 'invoiceNumber' },
        { field: 'order', header: 'order' },
        { field: 'accountingEntry', header: 'accountingEntry' },
        { field: 'totalQuantities', header: 'totalQuantities' },
        { field: 'totalHours', header: 'totalHours' },
        { field: 'status', header: 'status', type: 'tag' },
        { field: 'createdDate', header: 'invoiceDate', type: 'date', format: 'yyyy-MM-dd' },
        { field: 'validate', header: 'validate', type: 'yesno' },
        { field: 'validatedDate', header: 'validatedDate', type: 'date', format: 'yyyy-MM-dd' },
        { field: 'validatedBy', header: 'validatedBy' },
        { field: 'validatedComment', header: 'remark', type: 'remark', noFilter: true }
    ];

    public actions: TableAction[] = [
        { code: TableActionType.OPEN, icon: TableActionIconType.SEARCH },
        { code: TableActionType.PREVIEW, icon: TableActionIconType.PREVIEW },
        { code: TableActionType.REPORT, icon: TableActionIconType.REPORT, labelTranslateKey: this.commonTranslatePrefix + '.sap-entries-report' },
    ];

    public billingReports$: Observable<BillingReport[]> = this.store.pipe(
        select(getAllBillingReports)
    );

    public billingReportsLoading$: Observable<boolean> = this.store.pipe(
        select(getBillingReportsLoading)
    );

    public ngOnInit(): void {
        this.store.dispatch(new LoadBillingReports(AlternativePath.hq));
    }

    public onTableEvent(event: TableEventParam): void {

        const report = event.rowData as BillingReport;

        switch (event.actionCode) {
            case TableActionType.OPEN: {
                this.store.dispatch(new Go(
                    { path: ['facturation', 'rapports', this.pathChoice, FormMode.display, report && report.id.toString()] }
                ));
                break;
            }
            case TableActionType.PREVIEW: {
                this.store.dispatch(new Go(
                    { path: ['facturation', 'rapports', this.pathChoice, 'preview', report && report.id.toString()] }
                ));
                break;
            }
            case TableActionType.REPORT: {
                this.store.dispatch(new Go(
                    { path: ['facturation', 'rapports', this.pathChoice, 'sap-entries-report', report && report.id.toString()] }
                ));
                break;
            }
        }
    }

    public onDisplayRemarks(report: BillingReport): void {
        this.dialog.open(ModalWindowComponent, {
            width: '80%',
            maxWidth: '650px',
            data: {
                icon: 'fas fa-exclamation',
                componentName: 'displayRemark',
                translatePrefix: this.translatePrefix + '.remarkPopup',
                titleKey: 'title',
                report: report as any,
            } as ModalWindowData
        });
    }
}
