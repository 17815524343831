import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { FormationUtilisateur, UtilisateurRequest } from '../../models/profil-formation-utilisateur.model';

/**
 * Types
 */
export enum ProfilFormationActionType {
    LOAD_PROFIL_FORMATION = '[ProfilFormation] Load Profil de Formation',
    LOAD_PROFIL_FORMATION_SUCCESS = '[ProfilFormation] Load Profil de Formation Success',
    LOAD_PROFIL_FORMATION_FAIL = '[ProfilFormation] Load Profil de Formation Fail',
    LOAD_PROFIL_FORMATION_NO_RESULTS = '[ProfilFormation] Load Profil de Formation No Result'
}

/**
 *  ACTIONS
 */

/**
 * LOAD
 */

export class LoadProfilFormationUtilisateur implements Action {
    readonly type = ProfilFormationActionType.LOAD_PROFIL_FORMATION;
    constructor(public utilisateurs: UtilisateurRequest[], public path: AlternativePath) { }
}

export class LoadProfilFormationUtilisateurNoResult implements Action {
    readonly type = ProfilFormationActionType.LOAD_PROFIL_FORMATION_NO_RESULTS;
    constructor(public profilFormation: FormationUtilisateur[]) { }
}

export class LoadProfilFormationUtilisateurSuccess implements Action {
    readonly type = ProfilFormationActionType.LOAD_PROFIL_FORMATION_SUCCESS;
    constructor(public profilFormation: FormationUtilisateur[]) { }
}

export class LoadProfilFormationUtilisateurFail implements Action {
    readonly type = ProfilFormationActionType.LOAD_PROFIL_FORMATION_FAIL;
    constructor(public error: any) { }
}

export type ProfilFormationAction =
    | LoadProfilFormationUtilisateur
    | LoadProfilFormationUtilisateurNoResult
    | LoadProfilFormationUtilisateurSuccess
    | LoadProfilFormationUtilisateurFail;
