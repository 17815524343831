<h2 mat-dialog-title>
    <ng-container *ngIf="logoString !== null">
        <i class="{{ logoString }} space" [class.red]="isForDelete" [ngStyle]="{'font-size': logoFontSize + 'px'}"></i>
    </ng-container>
    {{ title }}
</h2>
<div mat-dialog-content>
    <p style="white-space: pre-wrap; text-align: center;">{{ message }}</p>
    <p *ngIf="subMessage !== null" [class.bold]="isSubMessageBold" style="white-space: pre-wrap; text-align: center;">{{ subMessage }}</p>
    <p *ngIf="question !== null" style="white-space: pre-wrap; text-align: center;">{{ question }}</p>
</div>
<div mat-dialog-actions>
    <app-generic-form-buttons class="fullwidth"
        [cancelTooltipLabel]="this.translatePrefix + '.cancel' | translate"
        [saveTooltipLabel]="this.translatePrefix + '.confirm' | translate"
        [displayCancelButton]="displayDeleteButton"
        (cancel)="cancel()"
        (save)="confirm()"></app-generic-form-buttons>
</div>
