import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngrx/store';

import { FormModalData } from '../generic-form-modal/generic-form-modal-data.model';
import { GenericFormModalComponent } from '../generic-form-modal/generic-form-modal.component';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import { ModalData, ModalEntityType, ModalType } from '../generic-modal/generic-modal-data.model';
import { PostLine } from '../../../../../shared/models/database/post-line.model';
import { Unit } from '../../../../../shared/models/database/unit.model';
import { TimesheetState } from '../../../../store/reducers';
import { CreateUnit, DeleteOnePostLine, UpdateOnePostLine } from '../../../../store/actions';
import { OrderType } from '../../../../../shared/models/database/order-type.enum';
import { UserRole } from '../../../../../shared/models/user-roles.model';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { CurrentUser, UserService } from '../../../../../core/services/user.service';

@Component({
    selector: 'app-work-done-pillar-line-accordion',
    templateUrl: './work-done-pillar-line-accordion.component.html',
    styleUrls: ['./work-done-pillar-line-accordion.component.scss']
})
export class WorkDonePillarLineAccordionComponent implements OnChanges {
    public numberOfUnits = 0;
    public hoursOfUnits = 0;
    public displayMenu = false;
    public hideButtonMenu = ['add'];

    @Input() public timesheetDateId: string;
    @Input() public postLine: PostLine;
    @Input() public canAddEditTime = true;
    @Input() public orderType: OrderType;
    @Input() public isLock = false;
    @Input() public editAllowedRoles: UserRole[] = [];
    @Input() public pathChoice: AlternativePath;

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<TimesheetState>,
        private userService: UserService,
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.postLine && changes.postLine.currentValue) {
            this.numberOfUnits = this.calculNumberOfUnits(changes.postLine.currentValue);
            this.hideButtonMenu = (this.numberOfUnits !== 0) ? ['add', 'delete'] : ['add'];

            this.hoursOfUnits = this.calculHoursOfUnits(changes.postLine.currentValue);
            this.displayMenu = this.shouldDisplayMenu(changes.postLine.currentValue);
        }
    }

    public calculNumberOfUnits(postLine: PostLine): number {
        return (postLine.units && postLine.units.length > 0) ? postLine.units.length : 0;
    }

    public calculHoursOfUnits(postLine: PostLine): number {
        let number = 0;
        if (postLine.units && postLine.units.length > 0) {
            postLine.units.forEach((unit: Unit) => {
                number += unit.hour;
            });
        }
        return number;
    }

    public shouldDisplayMenu(postLine: PostLine): boolean {
        return (postLine && !postLine.fromInventory) || false;
    }

    public addUnit(event: MouseEvent): void {
        event.stopPropagation();
        const addPostLineDialog = this.dialog.open(GenericFormModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                icon: 'fas fa-plus',
                formName: 'workDone',
                translatePrefix: 'timesheet.order.tab.accordionTitle.unitOfWorkDone.form',
                parentId: this.postLine.id,
                canAddEditTime: this.canAddEditTime,
                timesheetDateId: this.timesheetDateId,
                specificDetail: {
                    orderType: this.orderType,
                },
                pathChoice: this.pathChoice,
            } as FormModalData,
        });

        addPostLineDialog.afterClosed().subscribe((result: Unit) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }

                this.store.dispatch(new CreateUnit(result, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }

    public editPostLine(_: MouseEvent, postLine: PostLine): void {
        const editPostLineDialog = this.dialog.open(GenericFormModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                icon: 'fas fa-pen',
                formName: 'postLine',
                translatePrefix: 'timesheet.order.tab.accordionTitle.unitOfWorkDone.postLineForm',
                entity: postLine,
                canAddEditTime: this.canAddEditTime,
                specificDetail: {
                    orderType: this.orderType,
                },
                pathChoice: this.pathChoice,
            } as FormModalData,
        });

        editPostLineDialog.afterClosed().subscribe((result: PostLine) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }

                this.store.dispatch(new UpdateOnePostLine(result, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }

    public deletePostLine(_: MouseEvent, postLine: PostLine): void {
        const deleteUnitDialog = this.dialog.open(GenericModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                formType: ModalType.delete,
                title: 'timesheet.order.tab.accordionTitle.unitOfWorkDone.deletePostLineForm.title',
                message: 'timesheet.order.tab.accordionTitle.unitOfWorkDone.deletePostLineForm.message',
                entityType: ModalEntityType.postLine,
                entity: postLine,
                canAddEditTime: this.canAddEditTime,
                displayOutageNumber: this.orderType === OrderType.outage,
            } as ModalData
        });

        deleteUnitDialog.afterClosed().subscribe((result: boolean) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }

                this.store.dispatch(new DeleteOnePostLine(postLine, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }
}
