<div class="modalTimesheetStatus">
    <ng-container *ngIf="errors === undefined || errors === null">
        <div class="message">
            {{ message | translate }}
        </div>
    </ng-container>
    <ng-container *ngIf="errors && errors !== null">
        <div class="message error">Correction(s) avant de soumettre</div>
        <app-recursive-error [errorList]="errorsList"></app-recursive-error>
    </ng-container>
    <app-generic-form-buttons [disabled]="disableButton" (cancel)="onClose()" (save)="onConfirm()"></app-generic-form-buttons>
</div>
