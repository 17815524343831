import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';

import { GenericFormModalComponent } from '../generic-form-modal/generic-form-modal.component';
import { ModalData, ModalType, ModalEntityType } from '../generic-modal/generic-modal-data.model';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import { FormModalData } from '../generic-form-modal/generic-form-modal-data.model';
import { TimesheetState } from '../../../../store/reducers';
import { Unit } from '../../../../../shared/models/database/unit.model';
import { UpdateOneUnit, DeleteOneUnit } from '../../../../store/actions';
import { OrderType } from '../../../../../shared/models/database/order-type.enum';
import { Observable } from 'rxjs';
import { getUnitsLoading } from '../../../../store/selectors';
import { UserRole } from '../../../../../shared/models/user-roles.model';
import { getUserRoles } from '../../../../../core/store/selectors';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { CurrentUser, UserService } from '../../../../../core/services/user.service';

@Component({
    selector: 'app-work-done-list',
    templateUrl: './work-done-list.component.html',
    styleUrls: ['./work-done-list.component.scss']
})
export class WorkDoneListComponent {
    public currentUserRoles: UserRole[] = [];
    public originalHideMenuItems: string[] = ['add'];
    public hideMenuItems: string[] = [];
    public displayItemMenu = false;
    public workDoneOverIndex: number | null;

    @Input() public units: Unit[] = [];
    @Input() public canAddEditTime = true;
    @Input() public timesheetDateId: string;
    @Input() public orderType: OrderType;
    @Input() public isLock = false;
    @Input() public editAllowedRoles: UserRole[] = [];
    @Input() public pathChoice: AlternativePath;

    public unitsLoading$: Observable<boolean> = this.store.pipe(
        select(getUnitsLoading),
    );

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<TimesheetState>,
        private userService: UserService
    ) {
        this.store.pipe(
            select(getUserRoles),
        ).subscribe((roles: UserRole[]) => {
            this.currentUserRoles = roles;
        });
    }

    public workTrackById(_: number, { id }: Unit): number {
        return id;
    }

    public analyseDisplayMenu(unit: Unit): boolean {
        if (unit) {
            if (!unit.fromInventory || (unit.fromInventory && unit.quantity === 0)) {
                this.hideMenuItems = [...this.originalHideMenuItems];
                this.displayItemMenu = true;
                return true;
            } else if (this.orderType === OrderType.hourly) {
                this.hideMenuItems = [...this.originalHideMenuItems];
                this.hideMenuItems.push('delete');
                this.displayItemMenu = true;
                return true;
            } else if (this.orderType === OrderType.unit) {
                this.displayItemMenu = false;
                return false;
            }
        }
        return false;
    }

    public isRoleDisabled(): boolean {
        return !!this.editAllowedRoles.length &&
            this.currentUserRoles.every(role => !this.editAllowedRoles.includes(role));
    }

    public editRecord(unit: Unit): void {
        if ((this.canAddEditTime || !unit.fromInventory) && !this.isLock && !this.isRoleDisabled()) {
            const workDoneDialog = this.dialog.open(GenericFormModalComponent, {
                width: '80%',
                maxWidth: '450px',
                data: {
                    icon: 'fas fa-pen',
                    formName: 'workDone',
                    titleKey: 'description',
                    entity: unit,
                    canAddEditTime: this.canAddEditTime,
                    timesheetDateId: this.timesheetDateId,
                    translatePrefix: '',
                    specificDetail: {
                        orderType: this.orderType,
                    },
                    pathChoice: this.pathChoice,
                } as FormModalData
            });

            workDoneDialog.afterClosed().subscribe((result: Unit) => {
                if (result) {
                    let imperson: CurrentUser | undefined;
                    if (this.userService.currentUser.isImpersonnified) {
                        imperson = this.userService.currentUser;
                    }

                    this.store.dispatch(new UpdateOneUnit(result, this.timesheetDateId, this.pathChoice, imperson));
                }
            });
        }
    }

    public deleteRecord(unit: Unit): void {
        const deleteUnitDialog = this.dialog.open(GenericModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                formType: ModalType.delete,
                title: 'timesheet.order.tab.accordionTitle.unitOfWorkDone.deleteUnitForm.title',
                message: 'timesheet.order.tab.accordionTitle.unitOfWorkDone.deleteUnitForm.message',
                entityType: ModalEntityType.unit,
                entity: unit,
                canAddEditTime: this.canAddEditTime,
            } as ModalData
        });

        deleteUnitDialog.afterClosed().subscribe((result: boolean) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }
                this.store.dispatch(new DeleteOneUnit(unit, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }

    public mouseOver(index: number): void {
        this.workDoneOverIndex = index;
    }

    public mouseOut(): void {
        this.workDoneOverIndex = null;
    }

    public displayEditNotMenu(isHover: boolean): boolean {
        return !(isHover && this.displayItemMenu);
    }
}
