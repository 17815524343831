import { Action } from '@ngrx/store';
import { OpenIdClaims, OpenIdUserInfo } from '@ngxhq/security';

import { UserRole } from '../../../shared/models/user-roles.model';

export enum UserActionTypes {
    GET_USER_FROM_CLAIMS = '[User] Get User From Claims',
    SET_ROLES = '[User] Set Roles',
}

export class GetUserFromClaims implements Action {
    readonly type = UserActionTypes.GET_USER_FROM_CLAIMS;

    constructor(
        public userInfo: OpenIdUserInfo,
        public claims: OpenIdClaims,
    ) { }
}

export class SetRoles implements Action {
    readonly type = UserActionTypes.SET_ROLES;

    constructor(
        public roles: UserRole[],
    ) { }
}

export type UserActions =
    | GetUserFromClaims
    | SetRoles;
