import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../ogiv-core/services';
import {
    TimesheetsCommandePosteTypeActionType,
    LoadTimesheetsCommandePosteType,
    LoadTimesheetsCommandePosteTypeSuccess,
    LoadTimesheetsCommandePosteTypeFail,
    UpdateOneTimesheetsCommandePosteType,
    UpdateAllTimesheetsCommandePosteTypeSuccess,
    UpdateOneTimesheetsCommandePosteTypeFail,
} from '../actions/timesheet-commande-poste-type.action';

import {
    TimesheetCommandePosteType,
    TimesheetCommandePosteTypeSavingValues,
    TimesheetCommandePosteTypeSavingFilters,
} from '../../models/timesheet-commande-poste-type.model';

import { TimesheetBasicSaving } from '../../models/timesheet-basic-saving.model';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class TimesheetCommandePosteTypeEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService
    ) { }

    public loadTimesheetsCommandePosteType$ = createEffect(() => this.action$.pipe(
        ofType<LoadTimesheetsCommandePosteType>(TimesheetsCommandePosteTypeActionType.LOAD_TIMESHEETS_COMMANDE_POSTE_TYPE),
        mergeMap(() => {
            return this.apiService.get<TimesheetCommandePosteType[]>(`hq/pilotage/timesheets/orders`).pipe(
                map((returnedTimesheetsCommandePosteType: TimesheetCommandePosteType[]) => {
                    return new LoadTimesheetsCommandePosteTypeSuccess(returnedTimesheetsCommandePosteType);
                }
                ),
                catchError((error: any) => of(new LoadTimesheetsCommandePosteTypeFail(error))),
            );

        }),
    ));

    public updatedTimesheetsCommandePosteType$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneTimesheetsCommandePosteType>(TimesheetsCommandePosteTypeActionType.UPDATE_ONE_TIMESHEETS_COMMANDE_POSTE_TYPE),
        mergeMap((action: UpdateOneTimesheetsCommandePosteType) => {
            return this.apiService.put<TimesheetCommandePosteType[],
                TimesheetBasicSaving<TimesheetCommandePosteTypeSavingValues, TimesheetCommandePosteTypeSavingFilters>>(
                    `hq/pilotage/timesheets/orders`, action.valueToSave).pipe(
                        map((updatedTimesheetCommandePosteType: TimesheetCommandePosteType[]) => {
                            return new UpdateAllTimesheetsCommandePosteTypeSuccess(updatedTimesheetCommandePosteType);
                        }
                        ),
                        catchError((error: any) => of(new UpdateOneTimesheetsCommandePosteTypeFail(error))),
                    );
        })
    ));

    public updateAllTimesheetsCommandePosteTypeSuccess$ = createEffect(() => this.action$.pipe(
        ofType<UpdateAllTimesheetsCommandePosteTypeSuccess>(TimesheetsCommandePosteTypeActionType.UPDATE_ALL_TIMESHEETS_COMMANDE_POSTE_TYPE_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'pilotages.notifications.orderPostType.update.success',
            }))
        ]
        ),
    ));

    public updateOneTimesheetsCommandePosteTypeFail$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneTimesheetsCommandePosteTypeFail>(TimesheetsCommandePosteTypeActionType.UPDATE_ONE_TIMESHEETS_COMMANDE_POSTE_TYPE_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.orderPostType.update.error',
            }))
        ]
        ),
    ));
}
