import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { ApiService } from '../../../ogiv-core/services/api.service';
import {
    TransportCodeActionType,
    LoadTransportCodes,
    LoadTransportCodesSuccess,
    LoadTransportCodesFail,
    LoadTransportCodesNoResult
} from '../actions/transport-codes.action';
import { TransportCode } from '../../../shared/models/database/transport-code.model';

@Injectable()
export class TransportCodesEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService,
    ) { }

    public loadTransportCode$ = createEffect(() => this.action$.pipe(
        ofType<LoadTransportCodes>(TransportCodeActionType.LOAD_TRANSPORT_CODES),
        mergeMap(() => {
            return this.apiService.get<TransportCode[]>('sap/data/transportCodes').pipe(
                map((returnedTransportCodes: TransportCode[]) =>
                    (returnedTransportCodes.length > 0)
                        ? new LoadTransportCodesSuccess(returnedTransportCodes)
                        : new LoadTransportCodesNoResult()
                ),
                catchError((error: any) => of(new LoadTransportCodesFail(error))),
            );
        }),
    ));
}
