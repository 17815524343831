import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { UtilisateurExterne } from '../../../../models/utilisateur-externe.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getAllEntrepreneursActifsSelectOptions } from '../../../../store/selectors';
import { SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { LoadEntrepreneurs } from '../../../../store/actions';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';

@Component({
    selector: 'app-maj-utilisateur-externe-form',
    templateUrl: './maj-utilisateur-externe-form.component.html',
    styleUrls: ['./maj-utilisateur-externe-form.component.scss']
})
export class MajUtilisateurExterneFormComponent implements OnChanges, OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    public utilisateurExterneForm: FormGroup;
    public pattern = { 0: { pattern: new RegExp('\\d'), symbol: '#' } };
    public postalCodePattern = '^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$';

    public horaires: any[] = [
        { value: 'Aucun', viewValue: 'Aucun' },
        { value: 'Lundi', viewValue: 'Lundi' },
        { value: 'Mardi', viewValue: 'Mardi' },
        { value: 'Mercredi', viewValue: 'Mercredi' },
        { value: 'Jeudi', viewValue: 'Jeudi' },
        { value: 'Vendredi', viewValue: 'Vendredi' }
    ];

    public habilitations: any[] = [
        { value: 'Informé', viewValue: 'Informé' },
        { value: 'Initié', viewValue: 'Initié' },
        { value: 'Habilité', viewValue: 'Habilité' },
        { value: 'Autorisé D.25.12', viewValue: 'Autorisé D.25.12' },
        { value: 'Inconnu', viewValue: 'Inconnu' },
    ];

    public entreprises$: Observable<SelectItem<number>[]> = this.store.pipe(
        select(getAllEntrepreneursActifsSelectOptions),
        tap((ent: SelectItem<number>[]) => {
            if (ent.length > 1 && !this.isAddMode) {
                this.disableBasicValues();
            }
        })
    );

    @Input() public utilisateurExterne: UtilisateurExterne | null;
    @Input() public translatePrefix: string;
    @Input() public isAddMode: boolean;

    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public create: EventEmitter<UtilisateurExterne> = new EventEmitter<UtilisateurExterne>();
    @Output() public update: EventEmitter<UtilisateurExterne> = new EventEmitter<UtilisateurExterne>();

    constructor(
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MajUtilisateurExterneFormComponent>,
        public translateService: TranslateService,
        private readonly store: Store
    ) {
        this.store.dispatch(new LoadEntrepreneurs(AlternativePath.hq));

        this.utilisateurExterneForm = this.formBuilder.group({
            pole: [''],
            site: [''],
            numero_vehicule: [''],
            numero_tablette: [''],
            numero_cellulaire: [''],
            horaire: [''],
            habilitation: [''],
            telephone: [''],
            adresse_personnelle: this.formBuilder.group({
                numero_civique: [''],
                rue: [''],
                ville: [''],
                code_postal: ['', [Validators.pattern(this.postalCodePattern)]]
            }),
            carte_acces: [''],
            telephone_residence: [''],
            nom: [''],
            prenom: [''],
            ddEntreprise: [0],
            id_entreprise: [0],
        });

    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    public ngOnInit(): void {
        this.subscriptions.push(
            this.utilisateurExterneForm.controls.ddEntreprise.valueChanges.pipe(
                tap((entrepriseId: number) => {
                    if (entrepriseId !== 0 && this.utilisateurExterneForm.controls.id_entreprise.value !== entrepriseId) {
                        this.utilisateurExterneForm.controls.id_entreprise.setValue(entrepriseId);
                    }
                }),
            ).subscribe()
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.utilisateurExterne && changes.utilisateurExterne.currentValue) {
            this.setUtilisateurExterneValues(changes.utilisateurExterne.currentValue);
        }

        if (changes && changes.isAddMode && changes.isAddMode.currentValue === true) {
            this.initializeForAdding();
        }
    }

    public disableBasicValues(): void {
        this.utilisateurExterneForm.controls.nom.clearValidators();
        this.utilisateurExterneForm.controls.nom.disable();
        this.utilisateurExterneForm.controls.nom.updateValueAndValidity();

        this.utilisateurExterneForm.controls.prenom.clearValidators();
        this.utilisateurExterneForm.controls.prenom.disable();
        this.utilisateurExterneForm.controls.prenom.updateValueAndValidity();

        this.utilisateurExterneForm.controls.ddEntreprise.clearValidators();
        this.utilisateurExterneForm.controls.ddEntreprise.disable();
        this.utilisateurExterneForm.controls.ddEntreprise.updateValueAndValidity();
    }

    public initializeForAdding(): void {
        this.utilisateurExterneForm.controls.nom.setValidators(Validators.required);
        this.utilisateurExterneForm.controls.nom.updateValueAndValidity();

        this.utilisateurExterneForm.controls.prenom.setValidators(Validators.required);
        this.utilisateurExterneForm.controls.prenom.updateValueAndValidity();

        this.utilisateurExterneForm.controls.ddEntreprise.setValidators(Validators.required);
        this.utilisateurExterneForm.controls.ddEntreprise.updateValueAndValidity();
    }

    public setUtilisateurExterneValues(utilisateurExterne: UtilisateurExterne) {
        if (utilisateurExterne.adresse_personnelle) {
            this.utilisateurExterneForm.reset(utilisateurExterne);
            // this.utilisateurExterneForm.controls.adresse_personnelle.reset({
            //     numero_civique: utilisateurExterne.adresse_personnelle,
            //     rue: null,
            //     ville: null,
            //     code_postal: null
            // });
        } else {
            this.utilisateurExterneForm.reset({
                nom: utilisateurExterne.nom,
                prenom: utilisateurExterne.prenom,
                id_entreprise: utilisateurExterne.id_entreprise,
                pole: utilisateurExterne.pole,
                site: utilisateurExterne.site,
                numero_vehicule: utilisateurExterne.numero_vehicule,
                numero_tablette: utilisateurExterne.numero_tablette,
                numero_cellulaire: utilisateurExterne.numero_cellulaire,
                horaire: utilisateurExterne.horaire,
                habilitation: utilisateurExterne.habilitation,
                telephone: utilisateurExterne.telephone,
                carte_acces: utilisateurExterne.carte_acces,
                telephone_residence: utilisateurExterne.telephone_residence,
            });
            this.utilisateurExterneForm.controls.adresse_personnelle.reset({
                numero_civique: null,
                rue: null,
                ville: null,
                code_postal: null
            });
        }

        const value = this.utilisateurExterneForm.controls.id_entreprise.value;
        this.utilisateurExterneForm.controls.ddEntreprise.setValue(value);
    }

    public cancelForm(): void {
        this.cancel.emit(true);
    }

    private removeSpaceFromString(value: string): string {
        return value.replace(/\s+/g, '');
    }

    private removeSpaceAndSave(name: string) {
        const foundController = this.utilisateurExterneForm.controls[name];
        if (foundController && foundController.value !== null && foundController.value !== '') {
            this.utilisateurExterneForm.controls[name].setValue(this.removeSpaceFromString(foundController.value));
        }
    }

    private formatPhonesNumber(): void {
        this.removeSpaceAndSave('numero_cellulaire');
        this.removeSpaceAndSave('telephone');
        this.removeSpaceAndSave('telephone_residence');
    }

    public formatAdressePersonel(): string | null {
        const addPerso = this.utilisateurExterneForm.value.adresse_personnelle;
        if ((addPerso.rue === null || addPerso.rue === '')
            && (addPerso.numero_civique === null || addPerso.numero_civique === '')
            && (addPerso.ville === null || addPerso.ville === '')
            && (addPerso.code_postal === null || addPerso.code_postal === '')) {
            return null;
        }
        return JSON.stringify(this.utilisateurExterneForm.value.adresse_personnelle).replace(/\"\"/g, 'null');
    }

    public onSubmit(): void {
        this.formatPhonesNumber();

        const { valid, touched } = this.utilisateurExterneForm;
        const adresse = this.formatAdressePersonel();
        const value = {
            ...this.utilisateurExterne,
            ...this.utilisateurExterneForm.value,
            adresse_personnelle: adresse
        };
        const { ddEntreprise, ...cleanedContent } = value;

        if (valid && touched) {
            if (this.isAddMode) {
                this.create.emit(cleanedContent);
            } else {
                this.update.emit(cleanedContent);
            }
        }
    }

}
