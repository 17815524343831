import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { CommonUiModule } from '@ngxhq/common-ui';
import { ThemeHqCommonUiSupportModule } from '@ngxhq/theme-ngxhq';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '../shared/shared.module';
import { ReportsRoutingModule } from './reports-routing.module';
import { reportsVendorsModules } from './reports-vendors-modules';
import { REPORTS_REDUCER_TOKEN, getReportsReducers } from './store';
import { reportsStoreEffects } from './store/effects';
import { ReportsComponent } from './components/reports.component';
import { BILLING_REDUCER_TOKEN, getBillingReducers } from '../billing/store';
import { billingStoreEffects } from '../billing/store/effects';

@NgModule({
    imports: [
        CommonModule,
        CommonUiModule,
        SharedModule,
        ThemeHqCommonUiSupportModule,
        TranslateModule,
        ReactiveFormsModule,

        // Vendors
        ...reportsVendorsModules,

        // Routing
        ReportsRoutingModule,

        // Store
        StoreModule.forFeature('reports', REPORTS_REDUCER_TOKEN),
        EffectsModule.forFeature(reportsStoreEffects),
        // Importing billing store to have access to LoadBillingOrders and getAccountingEntrySelectOptions
        StoreModule.forFeature('billing', BILLING_REDUCER_TOKEN),
        EffectsModule.forFeature(billingStoreEffects),
    ],
    declarations: [
        ReportsComponent,
    ],
    providers: [
        {
            provide: REPORTS_REDUCER_TOKEN,
            useFactory: getReportsReducers,
        },
        {
            provide: BILLING_REDUCER_TOKEN,
            useFactory: getBillingReducers,
        },
    ]
})
export class ReportsModule {
}
