<mat-tab-group mat-stretch-tabs [animationDuration]="'350'">
    <mat-tab *ngIf="isOrderByUnit()">
        <ng-template mat-tab-label>
            <app-tab-panel-header class="fullwidth"
                [headerLogo]="'fab fa-buromobelexperte'"
                [headerText]="prefixTranslate + '.Unitary'"
                [displayBubbleTime]="false"
                [isLock]="orderGroup.unitly?.isLock || false"
                [orderStatus]="orderGroup.unitly!.status"
                [taskValue]="getOrderUnitDone(orderGroup.unitly)">
            </app-tab-panel-header>
        </ng-template>
        <app-tab-content [pathChoice]="pathChoice" [editAllowedRoles]="editAllowedRoles" [canAddEditTime]="false" [order]="orderGroup.unitly!" [timesheetDateId]="timesheetDateId">
        </app-tab-content>
    </mat-tab>
    <mat-tab *ngIf="isOrderByHour()">
        <ng-template mat-tab-label>
            <app-tab-panel-header class="fullwidth"
                [headerLogo]="'fas fa-hourglass-half'"
                [headerText]="prefixTranslate + '.Hourly'"
                [orderStatus]="orderGroup.hourly!.status"
                [isLock]="orderGroup.hourly!.isLock"
                [taskValue]="getOrderUnitDone(orderGroup.hourly)">
            </app-tab-panel-header>
        </ng-template>
        <app-tab-content [pathChoice]="pathChoice" [editAllowedRoles]="editAllowedRoles" [order]="orderGroup.hourly!" [timesheetDateId]="timesheetDateId">
        </app-tab-content>
    </mat-tab>
    <mat-tab *ngIf="isOrderByOutage()">
        <ng-template mat-tab-label>
            <app-tab-panel-header class="fullwidth"
                [headerLogo]="'fas fa-bolt'"
                [headerText]="prefixTranslate + '.Failure'"
                [orderStatus]="orderGroup.outage!.status"
                [isLock]="orderGroup.outage!.isLock"
                [taskValue]="getOrderUnitDone(orderGroup.outage)">
            </app-tab-panel-header>
        </ng-template>
        <app-tab-content [pathChoice]="pathChoice" [editAllowedRoles]="editAllowedRoles" [requiredSubtitle]="true" [order]="orderGroup.outage!" [timesheetDateId]="timesheetDateId">
        </app-tab-content>
    </mat-tab>
</mat-tab-group>
