import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../../ogiv-core/services';
import {
    LoadUtilisateursInternes,
    UtilisateurInterneActionType,
    LoadUtilisateursInternesSuccess,
    LoadUtilisateursInternesNoResult,
    LoadUtilisateursInternesFail,
    UpdateOneUtilisateurInterne,
    UpdateOneUtilisateurInterneSuccess,
    UpdateOneUtilisateurInterneFail
} from '../actions/utilisateurs-internes.action';
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators';
import { UtilisateurInterne } from '../../models/utilisateur-interne.model';
import { of } from 'rxjs';

@Injectable()
export class UtilisateursInternesEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService
    ) { }

    public loadUtilisateursInternes$ = createEffect(() => this.action$.pipe(
        ofType<LoadUtilisateursInternes>(UtilisateurInterneActionType.LOAD_UTILISATEURS_INTERNES),
        switchMap((action: LoadUtilisateursInternes) => {
            return this.apiService.get<UtilisateurInterne[]>(`${action.path}/pilotage/utilisateurs-hq`).pipe(
                map((returnedUtilisateurInterne: UtilisateurInterne[]) =>
                    (returnedUtilisateurInterne.length > 0)
                        ? new LoadUtilisateursInternesSuccess(returnedUtilisateurInterne)
                        : new LoadUtilisateursInternesNoResult()
                ),
                catchError((error: any) => of(new LoadUtilisateursInternesFail(error))),
            );
        })
    ));

    public updateOneUtilisateurInterne$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneUtilisateurInterne>(UtilisateurInterneActionType.UPDATE_ONE_UTILISATEUR_INTERNE),
        mergeMap((action: UpdateOneUtilisateurInterne) => {
            return this.apiService.put<UtilisateurInterne, UtilisateurInterne>(`hq/pilotage/utilisateurs-hq/${action.utilisateurInterne.id}`,
                action.utilisateurInterne).pipe(
                    map((updatedUtilisateurExterne: UtilisateurInterne) =>
                        new UpdateOneUtilisateurInterneSuccess(updatedUtilisateurExterne)
                    ),
                    catchError((error: any) => of(new UpdateOneUtilisateurInterneFail(error))),
                );
        })
    ));
}
