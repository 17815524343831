import { Injectable } from '@angular/core';
import { CanActivate, Params } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap, map, filter, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { getRouterStateUrl } from '../../../../core/store/selectors';
import { shouldLoadGivenTimesheet } from '../../../store/selectors/timesheets.selector';
import { LoadTimesheetById, LoadTimesheetByIdAndImpersonnification } from '../../../store/actions';
import { RouterStateUrl } from '../../../../core/store/models';
import { AlternativePath } from '../../../../shared/models/atlernative-path.model';
import { UserService } from '../../../../core/services/user.service';

interface ShouldLoadTimesheet {
    shouldLoad: boolean;
    timesheetId: string;
}

@Injectable()
export class LoadTimesheetGuard implements CanActivate {
    constructor(
        private readonly store: Store,
        private userService: UserService,
    ) { }

    public canActivate(): Observable<boolean> {
        const currentUser = this.userService.currentUser;
        let pathChoice = AlternativePath.ent;

        return this.store.pipe(
            select(getRouterStateUrl)
        ).pipe(
            tap((state: RouterStateUrl) => {
                if (state && state.data && state.data.pathChoice) {
                    pathChoice = state.data.pathChoice;
                }
            }),
            map((state: RouterStateUrl) => state && state.params || {}),
            switchMap((params: Params) => {
                return this.store.pipe(
                    select(shouldLoadGivenTimesheet),
                    map((shouldLoad: boolean) => ({ shouldLoad, timesheetId: params.timesheetId }))
                );
            }),
            map(({ shouldLoad, timesheetId }: ShouldLoadTimesheet) => {
                if (timesheetId && shouldLoad) {
                    if (currentUser.isImpersonnified && currentUser.idAgol) {
                        this.store.dispatch(new LoadTimesheetByIdAndImpersonnification(timesheetId, pathChoice, currentUser.idAgol));
                    } else {
                        this.store.dispatch(new LoadTimesheetById(timesheetId, pathChoice));
                    }
                }

                return !shouldLoad;
            }),
            filter(hasLoaded => hasLoaded),
            take(1),
        );
    }
}
