import {
    Component,
    Output,
    EventEmitter,
    Input,
    OnInit,
    OnDestroy,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { switchMap, filter, tap } from 'rxjs/operators';

import { Equipment } from '../../../../../shared/models/database/equipment.model';
import { Vehicle } from '../../../../../shared/models/database/vehicle.model';
import { LoadVehicles } from '../../../../store/actions';
import { LoadEquipmentCodes } from '../../../../../ogiv-core/store/actions';
import { EquipmentCode } from '../../../../../shared/models/database/equipment-code.model';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { getActiveVehicleOptions, getActiveVehicleByNumber, getActiveVehicleSearchOptions } from '../../../../store/selectors';
import { getEquipmentCodeObjectOptions, getEquipmentCodeByCode, getEquipmentCodeSearchOptions } from '../../../../../ogiv-core/store/selectors';

@Component({
    selector: 'app-equipment-form',
    templateUrl: './equipment-form.component.html',
    styleUrls: ['./equipment-form.component.scss'],
})
export class EquipmentFormComponent implements OnInit, OnDestroy, OnChanges {
    public prefix = 'timesheet.order.tab.accordionTitle.equipment.form';
    public equipmentForm: FormGroup;
    public vehicleByCompany: Vehicle[];
    public tabIndex = 0;
    public referenceEquipment$: Observable<Vehicle[]>;
    public referenceEquipCode$: Observable<EquipmentCode[]>;

    @Input() public canAddEditTime = true;
    @Input() public timesheetDateId: string;
    @Input() public equipment: Equipment | null;
    @Input() public orderId: number | null;
    @Input() public reloadVehicleData: boolean;
    @Input() public freezeTabIndex = false;
    @Input() public pathChoice: AlternativePath;

    @Output() public create: EventEmitter<Equipment> = new EventEmitter<Equipment>();
    @Output() public update: EventEmitter<Equipment> = new EventEmitter<Equipment>();
    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public isCreateMode: EventEmitter<string> = new EventEmitter<string>();

    private subscriptions: Subscription[] = [];
    private vehicleAddEmptyOption = true;
    private vehicleAddCreateOption = true;
    private equipCodeAddEmptyOption = true;

    constructor(
        formBuilder: FormBuilder,
        private readonly store: Store,
    ) {
        this.referenceEquipment$ = this.store.pipe(
            select(getActiveVehicleOptions({ addEmptyOption: this.vehicleAddEmptyOption, addCreateOption: this.vehicleAddCreateOption }))
        );

        this.equipmentForm = formBuilder.group({
            referenceEquip: [''],
            referenceEquipCode: [''],
            id: [],
            orderId: [null],
            number: ['', Validators.required],
            description: [''],
            code: [''],
            hour: [''],
            searchEquipment: [''],
            searchEquipmentCode: [''],
        });
    }

    public ngOnInit(): void {
        this.store.dispatch(new LoadVehicles(this.pathChoice));
        this.store.dispatch(new LoadEquipmentCodes());

        this.referenceEquipCode$ = this.store.pipe(
            select(getEquipmentCodeObjectOptions({ addEmptyOption: this.equipCodeAddEmptyOption, date: this.timesheetDateId })),
        );

        this.subscriptions.push(
            this.equipmentForm.controls.referenceEquip.valueChanges.pipe(
                tap((selectedVehicleNumber: string) => {
                    if (selectedVehicleNumber === '') {
                        this.equipmentForm.controls.number.setValue('');
                    } else if (selectedVehicleNumber === 'createVehicle') {
                        this.createEquipment();
                    }
                    this.equipmentForm.controls.searchEquipment.setValue('');
                }),
                filter((selectedVehicleNumber: string) => selectedVehicleNumber !== '' && selectedVehicleNumber !== 'createVehicle'),
                switchMap((selectedVehicleNumber: string) => {
                    return this.store.pipe(
                        select(getActiveVehicleByNumber({ number: selectedVehicleNumber })),
                        tap((foundVehicle: Vehicle | null) => {
                            if (foundVehicle !== null) {
                                this.equipmentForm.controls.number.setValue(foundVehicle.number);
                            } else {
                                this.equipmentForm.controls.number.setValue('');
                            }
                        }),
                    );
                }),
            ).subscribe(),

            this.equipmentForm.controls.referenceEquipCode.valueChanges.pipe(
                tap((equipCode: string) => {
                    if (equipCode === '') {
                        this.equipmentForm.controls.code.setValue('');
                        this.equipmentForm.controls.description.setValue('');
                    }
                    this.equipmentForm.controls.searchEquipmentCode.setValue('');
                }),
                filter((equipCode: string) => equipCode !== ''),
                switchMap((equipCode: string) => {
                    return this.store.pipe(
                        /* eslint-disable */
                        select(getEquipmentCodeByCode({ code: equipCode, date: this.timesheetDateId })),
                        tap((equipmentCode: EquipmentCode | null) => {
                            if (equipmentCode !== null) {
                                this.equipmentForm.controls.code.setValue(equipmentCode.code);
                                this.equipmentForm.controls.description.setValue(equipmentCode.description);
                            } else {
                                this.equipmentForm.controls.code.setValue('');
                                this.equipmentForm.controls.description.setValue('');
                            }
                        }),
                    );
                }),
            ).subscribe(),

            this.equipmentForm.controls.searchEquipment.valueChanges.pipe(
                tap((text: string) => {
                    this.referenceEquipment$ = this.store.pipe(
                        select(getActiveVehicleSearchOptions({ addEmptyOption: this.vehicleAddEmptyOption, addCreateOption: this.vehicleAddCreateOption, text: text })),
                    );
                }),
            ).subscribe(),

            this.equipmentForm.controls.searchEquipmentCode.valueChanges.pipe(
                tap((text: string) => {
                    this.referenceEquipCode$ = this.store.pipe(
                        select(getEquipmentCodeSearchOptions({ addEmptyOption: this.equipCodeAddEmptyOption, text: text, date: this.timesheetDateId })),
                    );
                }),
            ).subscribe(),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.equipment && changes.equipment.currentValue) {
            if (changes.equipment.currentValue !== null) {
                this.equipmentForm.reset(changes.equipment.currentValue);
                this.equipmentForm.controls.referenceEquip.setValue(changes.equipment.currentValue.number);
                this.equipmentForm.controls.referenceEquip.updateValueAndValidity();

                this.equipmentForm.controls.referenceEquipCode.setValue(changes.equipment.currentValue.code);
                this.equipmentForm.controls.referenceEquipCode.updateValueAndValidity();
            }
        }

        if (changes && changes.reloadVehicleData && changes.reloadVehicleData.currentValue) {
            this.store.dispatch(new LoadVehicles(this.pathChoice));
            this.equipmentForm.controls.referenceEquip.setValue('');
        }

        if (changes && changes.orderId && changes.orderId.currentValue) {
            this.equipmentForm.controls.orderId.setValue(changes.orderId.currentValue);
        }

        if (changes && changes.canAddEditTime && changes.canAddEditTime.currentValue) {
            this.equipmentForm.controls.hour.setValidators([Validators.required, Validators.min(0.25), Validators.max(24)]);
            this.equipmentForm.controls.hour.updateValueAndValidity();
            this.equipmentForm.controls.code.setValidators([Validators.required]);
            this.equipmentForm.controls.code.updateValueAndValidity();
            this.equipmentForm.controls.description.setValidators([Validators.required]);
            this.equipmentForm.controls.description.updateValueAndValidity();
        }

        if (changes && changes.freezeTabIndex && changes.freezeTabIndex.currentValue !== undefined && changes.freezeTabIndex.currentValue !== null) {
            this.tabIndex = changes.freezeTabIndex.currentValue ? -1 : 0;
        }
    }

    public onSubmit(): void {
        const { valid } = this.equipmentForm;

        if (valid) {
            const value = {
                ...this.equipmentForm.value,
            };

            const { referenceEquip, referenceEquipCode, searchEquipment, searchEquipmentCode, ...cleanEquipment } = value;
            this.tabIndex = 0;

            if (this.equipment && this.equipment.id) {
                this.update.emit(cleanEquipment);
            } else {
                const { id, ...cleanValues } = cleanEquipment;
                this.create.emit(cleanValues);
            }
        }
    }

    public cancelForm(): void {
        this.tabIndex = 0;
        this.cancel.emit(true);
    }

    public createEquipment(): void {
        this.tabIndex = -1;
        this.isCreateMode.emit('timesheet.order.tab.accordionTitle.equipment.createForm.title');
    }

    public isCodeOptionDisplay(equip: any): boolean {
        return (equip.code !== undefined && equip.code !== null && equip.code !== '');
    }

    public isNumberOptionDisplay(vehicle: Vehicle): boolean {
        return vehicle.number !== '' && vehicle.number !== 'createVehicle';
    }
}
