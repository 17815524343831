<p-table #timesheetReportsDatatable [columns]="cols" [value]="myReports" [paginator]="true" [rows]="10"
    [sortField]="sortField" [sortOrder]="sortOrder" [rowHover]="true" [autoLayout]="true"
    currentPageReportTemplate="Affiche de {first} à {last} sur {totalRecords} items" [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10,25,50,100,200]">
    <ng-template pTemplate="caption">
        <div class="table__header">
            <div class="header__container">
                <div class="container__clearFilter">
                    <app-erase-all-filter (clicked)="clearAllFilters()" [disabled]="!filtersApply"></app-erase-all-filter>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col && col.isSortable !== undefined && col.isSortable === false ? '' : col.field"
                [class.site]="col.field === 'site'"
                [class.week]="col.field === 'week'"
                [class.type]="col.field === 'billingType'"
                [class.remark]="col.field === 'remark'"
                [class.tag]="col.field === 'status' || col.field === 'partialStatus'">
            {{ translatePrefix + '.' + col.header | translate }}

            <ng-container *ngIf="col && (col.isSortable === undefined || col.isSortable === true)">
                <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                    [attr.aria-label-desc]="'common.sortDesc' | translate"
                    [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
            </ng-container>

            </th>
            <th class="action">{{ 'common.actions' | translate }}</th>
        </tr>
        <tr>
            <th *ngFor="let col of columns">
                <ng-container *ngIf="!col.noFilter" [ngSwitch]="col.filterType">
                    <p-multiSelect *ngSwitchCase="'multi-select'"
                        appendTo="body"
                        defaultLabel="{{'common.filter' | translate}}"
                        [(ngModel)]="filtersEntries[col.field]"
                        [ngModelOptions]="{standalone: true}"
                        [options]="tableFiltersItems[col.field]"
                        optionLabel="name"
                        [filter]="false"
                        [maxSelectedLabels]="1"
                        [showToggleAll]="true"
                        [selectedItemsLabel]="'common.selectedItems' | translate"
                        class="p-column-filter"
                        (onChange)="onFilterChange($event.value, col.field)"
                        >
                    </p-multiSelect>
                    <input pInputText *ngSwitchDefault type="text"
                        id="id-{{col.field}}"
                        (input)="inputFilterColumn($event, col.field, col.filterMatchMode)"
                        [placeholder]="'common.filter' | translate"
                        [attr.aria-placeholder]="'common.filter' | translate" >
                </ng-container>
            </th>
            <th></th>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length+1">
                <h3>
                    {{ translatePrefix + '.noDataFound' | translate }}
                </h3>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr (click)="onGoToReport(rowData)" class="p-selectable-row" (mouseover)="rowOver(rowIndex)" (mouseout)="rowOver(null)">
            <td *ngFor="let col of columns; trackBy: trackByColumnField">
                <ng-container [ngSwitch]="col.type">
                    <ng-container *ngSwitchCase="'tag'">
                        <ng-container *ngIf="rowData[col.field] !== null">
                            <app-tag [status]="rowData[col.field]">
                                {{ coreTranslatePrefix + '.status.' + rowData[col.field] | translate }}
                            </app-tag>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'remark'">
                        <ng-container *ngIf="hasRemark(rowData)">
                            <div class="remarkcontainer">
                                <button pButton pRipple type="button" class="p-button-text p-button-primary remarkcontainer__button" icon="fas fa-exclamation"
                                [pTooltip]="translatePrefix + '.open' | translate" tooltipPosition="top" (click)="remarkClicked(rowData, $event)"></button>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'date'">
                        {{ rowData[col.field] | date: col.format }}
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{ rowData[col.field] }}
                    </ng-container>
                </ng-container>
            </td>

            <td class="action__buttons">
                <div class="buttons__container">
                    <app-generic-other-menu
                        [mySingleButtonOnly]="true"
                        [singleButtonIcon]="'fa fa-search'"
                        [addborder]="true"
                        [hoverButtonOrMenu]="rowIndex === buttonOverIndex"
                        (singleButtonPressed)="onGoToReport(rowData)">
                    </app-generic-other-menu>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
