export class TableCodeUnite {
    id: number;
    code_sap: string | null;
    code_presc: string | null;
    description: string | null;
    nature_comptable?: string | null = undefined;
    type_cycle: string | null;
    residu: string | null;
    fiche_service: string | null;
    demande_client: boolean | null;
    ajout_unitaire: boolean | null;
    ajout_horaire: boolean | null;
    travaux_mecanise: boolean | null;
    detail_requis: boolean | null;
    statut: string;
    cree_le: Date | null;
    cree_par: Date | null;
    modifie_le: Date | null;
    modifie_par: string | null;
}

export enum TableCodeUniteStatus {
    new = 'Nouveau',
    active = 'Actif',
    inactive = 'Inactif',
    all = '',
}

export enum TableCodeUniteCycleTypeTitle {
    none = 'Aucun',
    dlr = 'Réduite',
    dlrCc = 'Réduite, court',
    dlu = 'Usuelle',
    dluCc = 'Usuelle, court'
}

export enum TableCodeUniteCycleType {
    none = '',
    dlr = 'Déboisement à largeur réduite',
    dlrCc = 'Déboisement à largeur réduite Cycle court',
    dlu = 'Déboisement à largeur usuelle',
    dluCc = 'Déboisement à largeur usuelle Cycle court',
}
