import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { getPageNameToLoad, getRouterStateUrl, shouldLoadGivenPage } from '../core/store/selectors';
import { RouterStateUrl } from '../core/store/models';
import { AppConfiguration } from '../shared/config/app-config.model';
import { Go } from '../core/store/actions';

interface ShouldLoadPage {
    shouldLoad: boolean;
    pageToLoad: string[];
}

@Injectable()
export class LoadPageGuard implements CanActivate {
    constructor(
        private readonly store: Store,
        private readonly configService: AppConfiguration,
    ) { }

    public canActivate(): Observable<boolean> {
        const config = this.configService;

        return this.store.pipe(
            select(getRouterStateUrl)
        ).pipe(
            switchMap((_: RouterStateUrl) => {
                return this.store.pipe(
                    select(shouldLoadGivenPage),
                    map((shouldLoadPage: boolean) => (shouldLoadPage)),
                );
            }),
            switchMap((shouldLoadPage: boolean) => {
                return this.store.pipe(
                    select(getPageNameToLoad),
                    map((pageNames: string[]) => ({ shouldLoad: shouldLoadPage, pageToLoad: pageNames } as ShouldLoadPage))
                );
            }),
            map(({ shouldLoad, pageToLoad }: ShouldLoadPage) => {
                let total = 0;
                let quantity = 0;

                if (shouldLoad && pageToLoad.length > 0) {
                    total = pageToLoad.length;

                    pageToLoad.forEach(page => {
                        if (config.pages[page]) {
                            quantity++;
                        }
                    });

                    if ((total === 1 && quantity === 1) || (total > 1 && quantity > 0)) {
                        return true;
                    }
                }

                this.store.dispatch(new Go({ path: ['/'] }));
                return false;
            }),
        );
    }
}
