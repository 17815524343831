import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BillingReport } from '../../../../shared/models/database/billing-report.model';
import { trackByColumnField } from '../../../../shared/helpers/track-by.helper';
import { TableAction, TableActionIconType, TableEventParam } from '../../../../shared/models/table-action.model';
import { TableColumns } from '../../../../shared/models/table-columns.model';
import { TranslateService } from '@ngx-translate/core';
import { AlternativePath } from '../../../../shared/models/atlernative-path.model';
import { Table } from 'primeng/table';
import { FilterService } from 'primeng/api';
import moment from 'moment';

@Component({
    selector: 'app-billing-report-table',
    templateUrl: './billing-report-table.component.html',
    styleUrls: ['./billing-report-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingReportTableComponent implements OnChanges {
    public searchIcon: string = TableActionIconType.SEARCH;
    public firstColSpan = 0;
    public lastColSpan = 0;
    public actionColWidth = '95px';
    public filtersApply = false;
    public currentFilters: string[] = [];

    @Input() public translatePrefix: string;
    @Input() public sortField: string;
    @Input() public sortOrder: number;
    @Input() public reports: BillingReport[];
    @Input() public cols: TableColumns[];
    @Input() public actions: TableAction[];
    @Input() public isEnabledActionButtonFunction: (actionCode: string, report?: BillingReport) => boolean;
    @Input() public pathChoice: AlternativePath;

    @Output() public tableEvent: EventEmitter<TableEventParam> = new EventEmitter();
    @Output() public displayRemark: EventEmitter<BillingReport> = new EventEmitter();

    @ViewChild('billingReportsDatatable') billingReportsDatatable: Table;
    @ViewChild('globalFilterObj') globalFilterObj: ElementRef;

    public buttonOverIndex: number | null = null;
    public trackByColumnField = trackByColumnField;
    public translateCommonPrefix = 'billing.common.summary';

    constructor(
        public activatedRoute: ActivatedRoute,
        public translate: TranslateService,
        private filterService: FilterService
    ) {
        // Added for MB-ANGULAR-11
        this.filterService.register('searchDate', (value: any, filter: any): boolean => {
            return this.customFilter(value, filter);
        });
        // Remove for MB-ANGULAR-11
        // FilterUtils['searchDate'] = (value: string, filter: any): boolean => {
        //     return this.customFilter(value, filter);
        // };
    }

    public customFilter(value: string, filter: any): boolean {
        if (filter === undefined || filter === null || filter.value === null) {
            return true;
        }

        if (value === undefined || value === null || value.trim() === '') {
            return false;
        }

        return (moment(value).format('yyyy-MM-DD')).includes(filter);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.cols && changes.cols.currentValue.length > 0) {
            this.firstColSpan = this.getTotalColSpan();
            this.lastColSpan = changes.cols.currentValue.length - this.firstColSpan - 2;
        }

        if (changes && changes.cols && changes.cols.currentValue.length > 0) {
            const isFound = changes.cols.currentValue.filter((col: TableColumns) => col.field === 'validatedBy').length > 0;
            this.actionColWidth = isFound ? '145px' : '100px';
        }
    }

    public onTableEvent(actionCode: string, report?: BillingReport): void {
        this.tableEvent.emit({ actionCode: actionCode, rowData: report });
    }

    public getTotalColSpan(): number {
        return this.cols.findIndex((col) => col.field.startsWith('total'));
    }

    public showAddButton(): boolean {
        return this.pathChoice === AlternativePath.ent;
    }

    public formatYesNo(value?: number): string {
        if (value === undefined || value === null) {
            return '';
        }
        return this.translate.instant('common.yesno.' + value.toString());
    }

    public isEnabledActionButton(actionCode: string, report?: BillingReport): boolean {
        if (!this.isEnabledActionButtonFunction) {
            return true;
        }
        return this.isEnabledActionButtonFunction(actionCode, report);
    }

    public hasRemark(report: BillingReport): boolean {
        return (report.validatedComment) ? true : false;
    }

    public remarkClicked(report: BillingReport, event: MouseEvent): void {
        event.stopPropagation();
        this.displayRemark.emit(report);
    }

    public rowOver(rowIndex: number | null): void {
        this.buttonOverIndex = rowIndex;
    }

    public eraseFilterButtonStatus(value: string, field: string): void {
        const index = this.currentFilters.findIndex((activeFilter: string) => activeFilter === field);
        if (index === -1 && value !== '') {
            this.currentFilters.push(field);
        } else if (index !== -1 && value === '') {
            this.currentFilters.splice(this.currentFilters.findIndex((activeFilter: string) => activeFilter === field), 1);
        }

        this.filtersApply = this.currentFilters.length > 0;
    }

    public inputFilterColumn(value: Event, field: string, filterMatchMode?: string, col?: TableColumns): void {
        this.filterColumn((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains', col);
    }

    public filterColumn(value: string, field: string, match?: string, col?: TableColumns): void {
        this.eraseFilterButtonStatus(value, field);

        let searchValue = value;
        if (col && col.type && col.type === 'yesno') {
            const trueValues = ['o', 'ou', 'oui', 'y', 'ye', 'yes'];
            const falseValues = ['n', 'no', 'non'];

            if (trueValues.includes(value.toLowerCase())) {
                searchValue = '1';
            } else if (falseValues.includes(value.toLowerCase())) {
                searchValue = '0';
            }
        }

        if (col && col.type && col.type === 'date') {
            this.billingReportsDatatable.filter(searchValue, field, 'searchDate');
        } else {
            this.billingReportsDatatable.filter(searchValue, field, match || 'contains');
        }
    }

    public inputGlobalFilter(value: Event, match: string = 'contains'): void {
        this.globalFilter((value.target as HTMLInputElement).value, match);
    }

    public globalFilter(value: string, match: string = 'contains'): void {
        this.eraseFilterButtonStatus(value, 'globalFilter');
        this.billingReportsDatatable.filterGlobal(value, match);
    }

    public clearAllFilters(): void {
        this.cols.forEach((col: TableColumns) => {
            if ((<HTMLInputElement>document.getElementById(`id-${col.field}`))) {
                (<HTMLInputElement>document.getElementById(`id-${col.field}`)).value = '';
            }
            this.filterColumn('', col.field);
        });

        if (this.globalFilterObj.nativeElement.value !== '') {
            this.globalFilterObj.nativeElement.value = '';
            this.globalFilter('', 'contains');
        }
    }
}
