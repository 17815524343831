<app-element-loader id="spinner" *ngIf="(isBillingReportLoading$ | async) ||
           (isBillingReportSaving$ | async) ||
           (isBillingCompanyLoading$ | async) ||
           (areBillingOrdersLoading$ | async) ||
           (areBillingItemsOptionsLoading$ | async)">
</app-element-loader>

<div *ngIf="isAllowed && (isCreatingBillingReport || (!isCreatingBillingReport && billingReport$ | async))">
    <div *ngIf="isHQ" class="actions">
        <button pButton icon="fa fa-check" (click)="validBillingReport()"
            class="actions__button right p-button-rounded p-button-success button__approve"
            [label]="translatePrefix + '.valid' | translate" [disabled]="isApproveButtonDisabled"></button>

        <button pButton icon="fa fa-times" (click)="refuseBillingReport()" [disabled]="isRefuseButtonDisabled"
            class="actions__button right p-button-rounded p-button-danger button__refuse"
            [label]="translatePrefix + '.refuse' | translate"></button>
    </div>

    <form [formGroup]="billingReportForm" id="billing-report-form" class="report-form" (ngSubmit)="onSubmit()">
        <p-fieldset [legend]="translateCommonPrefix + '.invoiceDetails' | translate" styleClass="fieldset">
            <div class="fieldset">
                <div class="fieldset__field">
                    <mat-form-field appearance="standard">
                        <mat-label>{{ translateCommonPrefix + '.contractor' | translate }}</mat-label>
                        <input matInput type="text" formControlName="company">
                    </mat-form-field>
                </div>
                <div class="fieldset__field">
                    <mat-form-field appearance="standard">
                        <mat-label>{{ translateCommonPrefix + '.invoiceNumber' | translate }}</mat-label>
                        <input matInput type="text" formControlName="invoiceNumber">
                    </mat-form-field>
                </div>
                <div *ngIf="isFormReadOnly" class="fieldset__field">
                    <mat-form-field appearance="standard">
                        <mat-label>{{ translateCommonPrefix + '.order' | translate }}</mat-label>
                        <input matInput type="text" formControlName="order">
                    </mat-form-field>
                </div>
                <div *ngIf="!isFormReadOnly" class="fieldset__field dropdown">
                    <label class="dropdown-label">{{ translateCommonPrefix + '.order' | translate }} <span class="required">*</span></label>
                    <p-dropdown [options]="$any(billingOrdersOptions$ | async)" formControlName="order" [filter]="true"
                        placeholder="{{ translateCommonPrefix + '.selectOrder' | translate }}">
                    </p-dropdown>
                </div>
                <div *ngIf="isFormReadOnly" class="fieldset__field">
                    <mat-form-field appearance="standard">
                        <mat-label>{{ translateCommonPrefix + '.accountingEntry' | translate }}</mat-label>
                        <input matInput type="text" formControlName="accountingEntry">
                    </mat-form-field>
                </div>
                <div *ngIf="!isFormReadOnly" class="fieldset__field dropdown">
                    <label class="dropdown-label">{{ translateCommonPrefix + '.accountingEntry' | translate }} <span class="required">*</span></label>
                    <p-dropdown id="accountingEntry" [options]="$any(accountingEntryOptions$ | async)"
                        formControlName="accountingEntry" [filter]="true"
                        placeholder="{{ translateCommonPrefix + '.selectAccountingEntry' | translate }}">
                    </p-dropdown>
                </div>
            </div>
        </p-fieldset>

        <p-fieldset [legend]="translateCommonPrefix + '.timesheets' | translate" styleClass="fieldset">
            <label class="timesheets-label" for="timesheets-table">{{ translateCommonPrefix + '.selectFDT' | translate
                }}</label>
            <p-table id="timesheets-table" [columns]="cols" [value]="$any(billingTimesheets$ | async)"
                #billingTimesheetsDatatable [paginator]="true" [rows]="10" dataKey="id"
                [(selection)]="selectedBillingTimesheets" (onRowSelect)="onRowSelect($event)"
                (onRowUnselect)="onRowUnselect($event)">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 3em"></th>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{ translateCommonPrefix + '.' + col.header | translate }}
                            <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                                [attr.aria-label-desc]="'common.sortDesc' | translate"
                                [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>

                        </th>
                        <th *ngIf="false">{{ 'common.actions' | translate }}</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field">
                            <input pInputText *ngSwitchDefault type="text"
                                (input)="inputFilterColumn($event, col.field, 'contains')"
                                [placeholder]="'common.filter' | translate"
                                [attr.aria-placeholder]="'common.filter' | translate">

                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [class.not-approved]="!isBillingTimesheetApproved(rowData)">
                        <th class="fieldset__table-checkbox">
                            <p-tableCheckbox [value]="rowData"
                                [disabled]="isFormReadOnly || !isBillingTimesheetApproved(rowData)"
                                [pTooltip]="(!isBillingTimesheetApproved(rowData) ? translateCommonPrefix + '.disabledNotApproved' : '') | translate"
                                tooltipPosition="top"
                                (click)="timesheetCheckboxClicked()">
                            </p-tableCheckbox>
                        </th>
                        <td *ngFor="let col of columns; trackBy: trackByColumnField">
                            <ng-container [ngSwitch]="col.type">
                                <ng-container *ngSwitchCase="'tag'">
                                    <app-tag [status]="rowData[col.field]">
                                        {{ translateCommonPrefix + '.' + rowData[col.field] | translate }}
                                    </app-tag>
                                </ng-container>

                                <ng-container *ngSwitchDefault>
                                    {{ rowData[col.field] }}
                                </ng-container>
                            </ng-container>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-fieldset>

        <p-fieldset [legend]="translateCommonPrefix + '.items' | translate" styleClass="fieldset items">
            <ng-container *ngFor="let item of itemsArray.controls; let i = index;">
                <div formArrayName="items" class="items_items">
                    <div [formGroupName]="i" class="items__container">
                        <div class="container__item">
                            <div class="item__properties">
                                <div class="properties__property">
                                    <div class="property__field">
                                        <mat-form-field appearance="standard" [hideRequiredMarker]="true">
                                            <mat-label>{{ translateCommonPrefix + '.itemNumeroImputation' | translate }} <span *ngIf="!isFormReadOnly" class="field__required">*</span></mat-label>
                                            <input matInput
                                                formControlName="chargeNumber"
                                                [id]="'item-imputation-' + i"
                                                type="text"
                                                [readonly]="isFormReadOnly"
                                                placeholder="{{ translateCommonPrefix + '.itemNumeroImputation' | translate }}"
                                                autocomplete="off">
                                            <small id="code-help" class="p-invalid"  *ngIf="itemsArray.controls[i]['controls'].numeroImputation?.required"> {{
                                                translateCommonPrefix + '.errors.imputationRequired' | translate }}</small>
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="isFormReadOnly" class="property__field dropdownField">
                                        <mat-form-field appearance="standard" [hideRequiredMarker]="true">
                                            <mat-label>{{ translateCommonPrefix + '.itemDescription' | translate }}</mat-label>
                                            <input [id]="'item-code-' + i" matInput type="text" formControlName="description">
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="!isFormReadOnly" class="property__dropdown">
                                        <label class="dropdown__label">{{ translateCommonPrefix + '.itemDescription' | translate
                                            }} <span class="label__required">*</span></label>
                                        <p-dropdown [id]="'item-code-' + i" [options]="$any(billingItemsOptions$ | async)"
                                            formControlName="code" [filter]="true"
                                            placeholder="{{ translateCommonPrefix + '.selectItem' | translate }}"
                                            [ngClass]="{'p-invalid': itemsArray.controls[i]['controls'].code.errors?.required }"
                                            aria-describedby="code-help">
                                        </p-dropdown>
                                        <small id="code-help" class="p-invalid"  *ngIf="itemsArray.controls[i]['controls'].code.errors?.required"> {{
                                            translateCommonPrefix + '.errors.codeRequired' | translate }}</small>
                                    </div>
                                    <div class="property__field">
                                        <mat-form-field appearance="standard" [hideRequiredMarker]="true">
                                            <mat-label>{{ translateCommonPrefix + '.itemQuantity' | translate }} <span *ngIf="!isFormReadOnly" class="required">*</span></mat-label>
                                            <input matInput class="articles_quantity" type="number" [attr.min]="0" formControlName="quantity" [readonly]="isFormReadOnly" placeholder="0" autocomplete="off">
                                            <mat-error *ngIf="itemsArray.controls[i]['controls'].quantity.errors?.required"> {{
                                                translateCommonPrefix + '.errors.quantityRequired' | translate }}</mat-error>
                                            <mat-error *ngIf="itemsArray.controls[i]['controls'].quantity.errors?.pattern"> {{
                                                translateCommonPrefix + '.errors.quantityPattern' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="property__field articles_unit">
                                        <mat-form-field appearance="standard" [hideRequiredMarker]="true">
                                            <input matInput type="text" min="0" formControlName="unit" [readonly]=true placeholder="" autocomplete="off">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="properties__details">
                                    <div class="details__field">
                                        <mat-form-field class="field__wrapper" appearance="standard" [hideRequiredMarker]="true">
                                            <mat-label>{{ translateCommonPrefix + '.details' | translate }} </mat-label>
                                            <input matInput #detail
                                                formControlName="detail"
                                                [id]="'item-details-' + i"
                                                type="text"
                                                [readonly]="isFormReadOnly"
                                                placeholder="{{ translateCommonPrefix + '.details' | translate }}"
                                                autocomplete="off"
                                                maxlength="255">
                                            <mat-hint class="wrapper__hint" [align]="'end'">{{detail.value.length}} / 255</mat-hint>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="item__remove" *ngIf="!isFormReadOnly" (click)="removeItem(i)" [pTooltip]="translateCommonPrefix + '.removeItem' | translate"
                                [attr.aria-label]="translateCommonPrefix + '.removeItem' | translate">
                                <i class="far fa-trash-alt deleteLogo"></i>
                            </div>
                        </div>
                        <div class="container__trailSpace"></div>
                    </div>
                </div>
            </ng-container>
            <div class="items__footer" *ngIf="!isFormReadOnly">
                <button pButton icon="fa fa-plus" (click)="addNewItem()" class="footer__add-item-button" type="button"
                    [disabled]="disableAddItem()"
                    [label]="translateCommonPrefix + '.addItem' | translate"></button>
            </div>
        </p-fieldset>
        <section class="c_actions">
            <div class="c_actions__left">
                <button *ngIf="!isHQ" pButton icon="far fa-trash-alt" class="actions__button delete" type="button"
                    [disabled]="isCreatingBillingReport || isFormReadOnly" (click)="deleteForm()"
                    tooltipPosition="top" [pTooltip]="translateCommonPrefix + '.deleteReport' | translate"
                    [label]="'common.delete' | translate"></button>
            </div>

            <div class="c_actions__center">
                <button pButton icon="fa fa-eye" class="actions__button preview" type="button"
                    [disabled]="isCreatingBillingReport" (click)="previewForm()"
                    [label]="(isHQ ? 'common.preview' : 'common.saveAndPreview') | translate"></button>
                <button *ngIf="isHQ" pButton icon="fa fa-file-alt" class="actions__button" type="button"
                    (click)="sapEntriesReportForm()" [label]="'billing.common.summary.sap-entries-report' | translate"></button>
            </div>

            <div *ngIf="!isHQ" class="c_actions__right">
                <button pButton icon="fa fa-check" class="actions__button p-button-rounded p-button-success button__approve" type="submit"
                    tooltipPosition="top" [pTooltip]="translateCommonPrefix + '.saveModif' | translate"
                    [disabled]="billingReportForm.invalid || isFormReadOnly"
                    [label]="'common.save' | translate"></button>
                <button pButton icon="fa fa-times-circle" class="actions__button p-button-rounded p-button-danger button__refuse" type="button" (click)="cancelForm()"
                    tooltipPosition="top" [pTooltip]="translateCommonPrefix + '.cancelModif' | translate"
                    [label]="'common.cancel' | translate" ></button>
            </div>
            <div *ngIf="isHQ" class="c_actions__right">
                <button pButton icon="fa fa-check" (click)="validBillingReport()"
                    class="actions__button p-button-rounded p-button-success button__approve" type="button"
                    [label]="translatePrefix + '.valid' | translate" [disabled]="isApproveButtonDisabled"></button>
                <button pButton icon="fa fa-times" (click)="refuseBillingReport()" [disabled]="isRefuseButtonDisabled"
                    class="actions__button p-button-rounded p-button-danger button__refuse" type="button"
                    [label]="translatePrefix + '.refuse' | translate"></button>
            </div>
        </section>
    </form>
</div>

<p-confirmDialog></p-confirmDialog>
