import { Injectable } from '@angular/core';

import { catchError, switchMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { AddOneAppNotification } from '../../../core/store/actions';
import { ApiService } from '../../../ogiv-core/services';
import { BillingReportPreviewRow } from '../../../shared/models/database/billing-report-preview.model';
import {
    BillingReportPreviewActionType,
    LoadBillingReportPreview,
    LoadBillingReportPreviewSuccess,
    LoadBillingReportPreviewFail
} from '../actions/billing-report-preview.action';
import { ErrorNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class BillingReportPreviewEffects {
    _apiService: any;
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    /************************/
    /*******  LOADING *******/
    /************************/
    public loadBillingReportPreview$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingReportPreview>(BillingReportPreviewActionType.LOAD_BILLING_REPORT_PREVIEW),
        switchMap(((action: LoadBillingReportPreview) => {
            return this.apiService.get<BillingReportPreviewRow[]>(`${action.path}/rapports/rapportsfacturation/preview/${action.billingReportId}`).pipe(
                map((returnedBillingReportPreviewRows: BillingReportPreviewRow[]) =>
                    new LoadBillingReportPreviewSuccess(returnedBillingReportPreviewRows)
                ),
                catchError((error: any) => of(new LoadBillingReportPreviewFail(error))),
            );
        }))
    ));

    public loadBillingReportPreviewFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingReportPreviewFail>(BillingReportPreviewActionType.LOAD_BILLING_REPORT_PREVIEW_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'billing.notifications.loadPreview.error',
            }))
        ]),
    ));
}
