import { MealCode } from '../../../shared/models/database/meal-code.model';
import { MealCodeAction, MealCodeActionType } from '../actions/meal-codes.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface MealCodesState extends EntityState<MealCode> {
    loading: boolean;
    loaded: boolean;
}

export const mealCodesAdapter: EntityAdapter<MealCode> = createEntityAdapter<MealCode>({
    selectId: (mealCode: MealCode) => mealCode.id,
});

export const mealCodesInitialState = mealCodesAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function mealCodeReducer(
    state: MealCodesState = mealCodesInitialState,
    action: MealCodeAction,
): MealCodesState {
    switch (action.type) {
        case MealCodeActionType.CREATE_MEAL_CODE:
        case MealCodeActionType.UPDATE_ONE_MEAL_CODE:
        case MealCodeActionType.LOAD_MEAL_CODES: {
            return {
                ...state,
                loading: true,
            };
        }

        case MealCodeActionType.CREATE_MEAL_CODE_SUCCESS: {
            return mealCodesAdapter.addOne(action.mealCode, {
                ...state,
                loading: false,
            });
        }

        case MealCodeActionType.UPDATE_ONE_MEAL_CODE_SUCCESS: {
            return mealCodesAdapter.upsertOne(action.mealCode, {
                ...state,
                loading: false,
            });
        }

        case MealCodeActionType.CREATE_MEAL_CODE_FAIL:
        case MealCodeActionType.UPDATE_ONE_MEAL_CODE_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case MealCodeActionType.LOAD_MEAL_CODES_SUCCESS: {
            return mealCodesAdapter.setAll(action.mealCodes, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case MealCodeActionType.LOAD_MEAL_CODES_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case MealCodeActionType.LOAD_MEAL_CODES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
