<p-table #timesheetReportPartTable [columns]="cols" [value]="reportPartData" [paginator]="false" [rows]="15"
    [sortField]="sortField" [sortOrder]="sortOrder" [class.height]="true">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns; trackBy: trackByColumnField">
                <th *ngIf="displayTimeSheetColumn(col)" [pSortableColumn]="col.field"
                    [class.small]="col.small || col.type === 'weekDay'" [class.code]="col.type === 'code'"
                    class="header__cellText">

                    <ng-container *ngIf="col.type === 'code' && displayCodeColumn(col)">
                        {{ translatePrefix + '.' + col.header | translate }}
                    </ng-container>

                    <ng-container *ngIf="col.type === 'weekDay'">
                        {{ 'common' + '.' + col.header | translate | slice:0:weekDayLabelLength }}
                    </ng-container>

                    <ng-container *ngIf="col.type !== 'weekDay' && col.type !== 'code'">
                        <ng-container *ngIf="displayTimeSheetColumn(col)">
                            {{ translatePrefix + '.' + col.header | translate }}
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="displayTimeSheetColumn(col) && displayCodeColumn(col)">
                        <p-sortIcon *ngIf="!disableSort && col.type !== 'weekDay'" [field]="col.field"
                            [attr.aria-label]="'common.sort' | translate"
                            [attr.aria-label-desc]="'common.sortDesc' | translate"
                            [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
                    </ng-container>

                </th>
            </ng-container>
            <th *ngIf="showTotal" class="small totals header__cellText">{{ 'common.total' | translate }}</th>
            <th *ngIf="alignTotalColumn" class="small transparent header__cellText"></th>
        </tr>
        <tr *ngIf="!areAllNonFilterableColumns()">
            <ng-container *ngFor="let col of columns; trackBy: trackByColumnField">
                <th *ngIf="displayTimeSheetColumn(col)" [class.small]="col.type === 'weekDay'"
                    [class.code]="col.type === 'code'">
                    <ng-container *ngIf="!col.noFilter && displayCodeColumn(col)" [ngSwitch]="col.field">
                        <input pInputText *ngSwitchDefault type="text"
                            (input)="filterColumn($event, col.field, 'contains')"
                            [placeholder]="'common.filter' | translate"
                            [attr.aria-placeholder]="'common.filter' | translate">
                    </ng-container>
                </th>
            </ng-container>
            <th *ngIf="showTotal" class="small totals"></th>
            <th *ngIf="alignTotalColumn" class="small transparent"></th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr>
            <ng-container *ngFor="let col of columns; trackBy: trackByColumnField">
                <td *ngIf="displayTimeSheetColumn(col)" [class.small]="col.small || col.type === 'weekDay'"
                    [class.code]="col.type === 'code'" >
                    <div pTooltip="{{ rowData[col.toolTipField] }}" [tooltipDisabled]="columnTooltipIsDisabled(col)">
                        <ng-container [ngSwitch]="true">
                            <ng-container *ngSwitchCase="col.type==='tag'">
                                <app-tag [status]="rowData[col.field]">
                                    {{ translatePrefix + '.' + rowData[col.field] | translate }}
                                </app-tag>
                            </ng-container>

                            <ng-container *ngSwitchCase="col.type==='code'">
                                <ng-container *ngIf="displayCodeColumn(col)"></ng-container>
                                <ng-container *ngIf="displayCodeColumn(col)">
                                    {{ rowData[col.field] }}
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="col.field === 'jobClassDescr'">
                                {{ rowData["jobClassCode"] + " " + rowData[col.field]}}
                            </ng-container>

                            <ng-container *ngSwitchCase="col.field === 'detail'">
                                <ng-container *ngIf="rowData['detail'] !== null && rowData['detail'] !== ''">
                                    <div class="detailcontainer">
                                        <button pButton pRipple type="button" class="p-button-text p-button-primary detailcontainer__button" icon="fas fa-exclamation"
                                        [pTooltip]="translatePrefix + '.open' | translate" tooltipPosition="top" (click)="detailClicked(rowData['detail'])"></button>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                                {{ rowData[col.field] }}
                            </ng-container>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <td *ngIf="showTotal" class="small totals">
                {{ getRowTotal(rowData) }}
            </td>

            <td *ngIf="alignTotalColumn" class="small transparent"></td>
        </tr>

        <tr *ngIf="displayTotal(rowIndex) === 'singleTotal'" class="last totals__row">
            <td colspan="8"></td>
            <td colspan="2">{{ 'common.total' | translate }}:</td>
            <td class="small totals">{{ getOverallTotal() }}</td>
        </tr>

        <tr *ngIf="displayTotal(rowIndex) === 'none'" class="last totals__row">
            <td colspan="11"></td>
        </tr>

        <tr *ngIf="displayTotal(rowIndex) === 'multiTotal'" class="last totals__row">
            <ng-container *ngFor="let col of columns; let colIndex = index; trackBy: trackByColumnField">

                <ng-container *ngIf="displayTimeSheetColumn(col)">
                    <td *ngIf="getColspanBeforeFirstTotal() > 0 && colIndex === 0"
                        [attr.colspan]="getColspanBeforeFirstTotal()" class="totals__title">
                        {{ 'common.total' | translate }}:
                    </td>

                    <td *ngIf="getColspanBeforeFirstTotal() === 0 || colIndex >= getColspanBeforeFirstTotal()"
                        [class.small]="col.small || col.type === 'weekDay'" [class.empty]="!hasColumnTotal(col)"
                        class="totals">

                        <ng-container *ngIf="col.columnTotal">
                            {{ getColumnTotal(col) }}
                        </ng-container>
                    </td>
                </ng-container>
            </ng-container>
        </tr>
    </ng-template>
</p-table>
