<div class="date-of-data-wrapper">
    <span class="date-of-data">{{ 'progressReport.general.lastUpdateDateLabel' | translate }}{{ dateOfData }}</span>
</div>

<h3> {{ 'progressReport.general.links.specificReport' | translate }}</h3>

<form [formGroup]="specificReportForm">
    <div class="bottom-spacing">
        <h5> {{ 'progressReport.specificReport.status' | translate }}</h5>
        <p-dropdown
            optionLabel="name"
            [options]="progressReportsGlobal"
            formControlName="status"
            (onChange)="onChangeDropdownFilterData()"
            [emptyFilterMessage]="'common.emptyFilterMessage' | translate"
        >
        </p-dropdown>
    </div>

    <div class="multi-selection-wrapper bottom-spacing">
        <div>
            <h5> {{ 'progressReport.specificReport.billingType' | translate }}</h5>
            <p-multiSelect
                formControlName="billingType"
                [options]="billingTypeItems"
                (onChange)="onChangeDropdownFilterData()"
                [selectedItemsLabel]="'common.selectedItems' | translate"
                [emptyFilterMessage]="'common.emptyFilterMessage' | translate"
            ></p-multiSelect>
        </div>
        <div>
            <h5> {{ 'progressReport.specificReport.site' | translate }}</h5>
            <p-multiSelect
                formControlName="site"
                [options]="siteItems"
                (onChange)="onChangeDropdownFilterData()"
                [selectedItemsLabel]="'common.selectedItems' | translate"
                [emptyFilterMessage]="'common.emptyFilterMessage' | translate"
            ></p-multiSelect>
        </div>
        <div>
            <h5> {{ 'progressReport.specificReport.order' | translate }}</h5>
            <p-multiSelect
                formControlName="order"
                [options]="orderItems"
                (onChange)="onChangeDropdownFilterData()"
                [selectedItemsLabel]="'common.selectedItems' | translate"
                [emptyFilterMessage]="'common.emptyFilterMessage' | translate"
            ></p-multiSelect>
        </div>
        <div>
            <h5> {{ 'progressReport.specificReport.accountingEntry' | translate }}</h5>
            <p-multiSelect
                formControlName="accountingEntry"
                [options]="accountingEntryItems"
                (onChange)="onChangeDropdownFilterData()"
                [selectedItemsLabel]="'common.selectedItems' | translate"
                [emptyFilterMessage]="'common.emptyFilterMessage' | translate"
            ></p-multiSelect>
        </div>
        <div>
            <h5> {{ 'progressReport.specificReport.executedBy' | translate }}</h5>
            <p-multiSelect
                formControlName="executedBy"
                [options]="executedByItems"
                (onChange)="onChangeDropdownFilterData()"
                [maxSelectedLabels]=1
                [selectedItemsLabel]="'common.selectedItems' | translate"
                [emptyFilterMessage]="'common.emptyFilterMessage' | translate"
            ></p-multiSelect>
        </div>
        <div>
            <h5> {{ 'progressReport.specificReport.assignedBy' | translate }}</h5>
            <p-multiSelect
                formControlName="assignedBy"
                [options]="assignedByItems"
                (onChange)="onChangeDropdownFilterData()"
                [maxSelectedLabels]=1
                [selectedItemsLabel]="'common.selectedItems' | translate"
                [emptyFilterMessage]="'common.emptyFilterMessage' | translate"
            ></p-multiSelect>
        </div>
        <div>
            <h5> {{ 'progressReport.specificReport.week' | translate }}</h5>
            <p-multiSelect
                formControlName="week"
                [options]="weekItems"
                (onChange)="onChangeDropdownFilterData()"
                [selectedItemsLabel]="'common.selectedItems' | translate"
                [emptyFilterMessage]="'common.emptyFilterMessage' | translate"
            ></p-multiSelect>
        </div>
    </div>
</form>

<p-treeTable #treetable [value]="treenodes">
    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let column of treetableColumns">
                <input pInputText type="text" placeholder="{{ 'common.filter' | translate }}"
                (input)="inputFilterColumn($event, column.field, column.filterMatchMode)"
            ></th>
            <th id="prescription-count">{{ 'progressReport.specificReport.prescriptionCount' | translate }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr>
            <td>
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                {{rowData.name}}
            </td>
            <td>{{rowData.prescriptionCount}}</td>
        </tr>
    </ng-template>
</p-treeTable>
