import { TableColumns } from '../../../../../shared/models/table-columns.model';

export const utilisateursInternesColumns: TableColumns[] = [
    { field: 'indicator', header: 'indicator', type: 'shortTag', width: '28px' },
    { field: 'id', header: 'id' },
    { field: 'nom', header: 'nom' },
    { field: 'prenom', header: 'prenom' },
    { field: 'cip', header: 'cip' },
    { field: 'upn', header: 'upn' },
    { field: 'id_agol', header: 'id_agol' },
    { field: 'pole', header: 'pole' },
    { field: 'site', header: 'site' },
    { field: 'numero_vehicule', header: 'numero_vehicule' },
    { field: 'numero_tablette', header: 'numero_tablette' },
    { field: 'numero_cellulaire', header: 'numero_cellulaire' },
    { field: 'numero_ordinateur', header: 'numero_ordinateur' },
    { field: 'numero_ecran', header: 'numero_ecran' },
    { field: 'horaire', header: 'horaire' },
    { field: 'statut', header: 'statut', type: 'tag' },
    { field: 'cree_le', header: 'cree_le' },
    { field: 'modifie_par', header: 'modifie_par' },
    { field: 'modifie_le', header: 'modifie_le' },
    { field: 'carte_essence', header: 'carte_essence' },
    { field: 'telephone_residence', header: 'telephone_residence' },
    { field: 'cadena', header: 'cadena' },
    { field: 'carte_acces', header: 'carte_acces' },
    { field: 'carte_sim_tablette', header: 'carte_sim_tablette' },
    { field: 'carte_sim_cellulaire', header: 'carte_sim_cellulaire' },
    { field: 'bureau', header: 'bureau' },
    { field: 'adresse_bureau', header: 'adresse_bureau' },
    { field: 'courriel', header: 'courriel' },
    { field: 'telephone_externe', header: 'telephone_externe' },
    { field: 'telephone_interne', header: 'telephone_interne' },
    { field: 'fax', header: 'fax' },
    { field: 'groupe', header: 'groupe' },
    { field: 'matricule', header: 'matricule' },
    { field: 'roles', header: 'roles' },
    { field: 'titre_emploi', header: 'titre_emploi' }
];
