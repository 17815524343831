import {
    TimesheetCommandePosteType,
    TimesheetCommandePosteTypeSavingValues,
    TimesheetCommandePosteTypeSavingFilters
} from '../../models/timesheet-commande-poste-type.model';
import { TimesheetBasicSaving } from '../../models/timesheet-basic-saving.model';
import { Action } from '@ngrx/store';

/**
 * Types
 */
export enum TimesheetsCommandePosteTypeActionType {
    LOAD_TIMESHEETS_COMMANDE_POSTE_TYPE = '[TimesheetsCommandePosteType] Load',
    LOAD_TIMESHEETS_COMMANDE_POSTE_TYPE_SUCCESS = '[TimesheetsCommandePosteType] Load Success',
    LOAD_TIMESHEETS_COMMANDE_POSTE_TYPE_FAIL = '[TimesheetsCommandePosteType] Load Fail',

    UPDATE_ONE_TIMESHEETS_COMMANDE_POSTE_TYPE = '[TimesheetsCommandePosteType] Update One',
    UPDATE_ONE_TIMESHEETS_COMMANDE_POSTE_TYPE_FAIL = '[TimesheetsCommandePosteType] Update One Fail',
    UPDATE_ALL_TIMESHEETS_COMMANDE_POSTE_TYPE_SUCCESS = '[TimesheetsCommandePosteType] Update All Success',
}

/**
 *  ACTIONS
 */

/**
 * LOAD
 */

export class LoadTimesheetsCommandePosteType implements Action {
    readonly type = TimesheetsCommandePosteTypeActionType.LOAD_TIMESHEETS_COMMANDE_POSTE_TYPE;
    constructor() { }
}

export class LoadTimesheetsCommandePosteTypeSuccess implements Action {
    readonly type = TimesheetsCommandePosteTypeActionType.LOAD_TIMESHEETS_COMMANDE_POSTE_TYPE_SUCCESS;
    constructor(public timesheetsCommandePosteType: TimesheetCommandePosteType[]) { }
}

export class LoadTimesheetsCommandePosteTypeFail implements Action {
    readonly type = TimesheetsCommandePosteTypeActionType.LOAD_TIMESHEETS_COMMANDE_POSTE_TYPE_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneTimesheetsCommandePosteType implements Action {
    readonly type = TimesheetsCommandePosteTypeActionType.UPDATE_ONE_TIMESHEETS_COMMANDE_POSTE_TYPE;
    constructor(public valueToSave: TimesheetBasicSaving<TimesheetCommandePosteTypeSavingValues, TimesheetCommandePosteTypeSavingFilters>) { }
}

export class UpdateAllTimesheetsCommandePosteTypeSuccess implements Action {
    readonly type = TimesheetsCommandePosteTypeActionType.UPDATE_ALL_TIMESHEETS_COMMANDE_POSTE_TYPE_SUCCESS;
    constructor(public timesheetsCommandePosteType: TimesheetCommandePosteType[]) { }
}

export class UpdateOneTimesheetsCommandePosteTypeFail implements Action {
    readonly type = TimesheetsCommandePosteTypeActionType.UPDATE_ONE_TIMESHEETS_COMMANDE_POSTE_TYPE_FAIL;
    constructor(public error: any) { }
}

export type TimesheetsCommandePosteTypeAction =
    | LoadTimesheetsCommandePosteType
    | LoadTimesheetsCommandePosteTypeSuccess
    | LoadTimesheetsCommandePosteTypeFail
    | UpdateOneTimesheetsCommandePosteType
    | UpdateAllTimesheetsCommandePosteTypeSuccess
    | UpdateOneTimesheetsCommandePosteTypeFail;
