import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { TimesheetState, timesheetStoreReducers } from './reducers';

export const TIMESHEET_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<TimesheetState>>('Timesheet reducers');

export function getTimesheetReducers(): ActionReducerMap<TimesheetState> {
    return timesheetStoreReducers;
}
