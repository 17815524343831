import {
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
    Inject,
} from '@angular/core';

export class LetContext<T> {
    constructor(
        private readonly dir: NgLetDirective<T>,
    ) { }

    get ngLet(): T {
        return this.dir.ngLet;
    }
}

@Directive({
    selector: '[ngLet]',
})
export class NgLetDirective<T> {
    @Input() public ngLet: T;

    constructor(
        @Inject(ViewContainerRef) viewContainer: ViewContainerRef,
        @Inject(TemplateRef) templateRef: TemplateRef<LetContext<T>>
    ) {
        viewContainer.createEmbeddedView(templateRef, new LetContext<T>(this));
    }
}
