import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-generic-accordion',
    templateUrl: './generic-accordion.component.html',
    styleUrls: ['./generic-accordion.component.scss'],
})
export class GenericAccordionComponent {
    public panelOpenState = false;
    public genericAccodionActiveIndex: number | null = null;

    @Input() public isLock = false;
    @Input() public title = '';
    @Input() public subtitle: string | null = null;
    @Input() public totalQuantity = 0;
    @Input() public icon = '';
    @Input() public bubbleIcon = '';
    @Input() public totalTime = 0;
    @Input() public canAddOrEdit = true;
    @Input() public isWorkDoneEditable = true;
    @Input() public requiredSubtitle = false;
    @Input() public canAddEditTime = true;

    // gestion du menu
    @Input() public addOnly = true;
    @Input() public editOnly = false;
    @Input() public deleteOnly = false;
    @Input() public addBackground = true;

    @Input() public displayMenu = false;
    @Input() public hideItems: string[] = [];
    @Input() public addBackgroundMenu = false;

    @Output() public addClicked: EventEmitter<boolean> = new EventEmitter();
    @Output() public editClicked: EventEmitter<boolean> = new EventEmitter();
    @Output() public deleteClicked: EventEmitter<boolean> = new EventEmitter();

    public genericAccordionOpen(accordion: any): void {
        this.genericAccodionActiveIndex = accordion.index;
    }

    public genericAccordionClose(): void {
        this.genericAccodionActiveIndex = null;
    }

    public addRecord(event: any): void {
        event.stopPropagation();
        this.addClicked.emit(true);
    }

    public editRecord(event: any): void {
        event.stopPropagation();
        this.editClicked.emit(true);
    }

    public deleteRecord(event: any): void {
        event.stopPropagation();
        this.deleteClicked.emit(true);
    }

    public isEmptyIcon(): boolean {
        return this.icon === '';
    }

    public isEmptyBubbleIcon(): boolean {
        return this.bubbleIcon === '';
    }

    public isExtraInformationHidden(): boolean {
        return this.panelOpenState;
    }

    public isTimeHidden(): boolean {
        return this.isExtraInformationHidden() || this.totalTime === 0 || !this.canAddEditTime;
    }

    public isAddButtonHidden(): boolean {
        return !this.isExtraInformationHidden() && this.canAddOrEdit;
    }

    public getHeaderHeight(): string {
        return this.requiredSubtitle ? '64px' : '48px';
    }

}
