import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { timesheetsAdapter, TimesheetsState } from '../reducers/timesheets.reducer';
import { getTimesheetState, TimesheetState } from '../reducers';
import { Timesheet } from '../../../shared/models/database/timesheet.model';
import { getRouterParams } from '../../../core/store/selectors/router.selectors';
import { Params } from '@angular/router';

const {
    selectAll,
    selectEntities,
    selectIds,
} = timesheetsAdapter.getSelectors();

/**
 * General Timesheet State selector
 */
export const getTimesheetsState = createSelector(
    getTimesheetState,
    (state: TimesheetState) => state.timesheets,
);

export const getTimesheetsLoading = createSelector(
    getTimesheetsState,
    (state: TimesheetsState) => state.loading,
);

export const getTimesheetsLoaded = createSelector(
    getTimesheetsState,
    (state: TimesheetsState) => state.loaded,
);

export const shouldLoadTimesheets = createSelector(
    getTimesheetsLoading,
    getTimesheetsLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllTimesheets = createSelector(
    getTimesheetsState,
    selectAll,
);

export const getAllFetchedTimesheets = createSelector(
    getAllTimesheets,
    (timesheets: Timesheet[]) => timesheets.map((timesheet: Timesheet) => new Timesheet(timesheet)),
);

export const getTimesheetsEntities = createSelector(
    getTimesheetsState,
    selectEntities,
);

export const getTimesheetsIds = createSelector(
    getTimesheetsState,
    selectIds,
);

export const shouldLoadGivenTimesheet = createSelector(
    getTimesheetsLoading,
    (loading: boolean) => !loading
);

export const getCurrentTimesheet = createSelector(
    getRouterParams,
    getTimesheetsEntities,
    (params: Params, entities: Dictionary<Timesheet>) => {
        return entities && params.timesheetId && entities[params.timesheetId] || null;
    }
);

export const getTimesheetByDateId = (props: any) => createSelector(
    getTimesheetsEntities,
    (entities: Dictionary<Timesheet>) => {
        return entities && entities[props.entityId] || null;
    }
);
