export enum OrderStatus {
    waiting = 'En attente',
    refused = 'Refusé',
    validated = 'Validé',
}

export function getOrderCss(value: string): string {
    if (value === undefined || value === null || value === '') {
        return '';
    }

    return OrderStatusMap[value].value;
}

/** ------------------------------------------------------ **/
/** |                                                    | **/
/** |          Attention:                                | **/
/** |    / \     On désative la convention de nomage     | **/
/** |   / ! \    car le nom des statuts proviennent      | **/
/** |  /_____\   du backend. (eslint-disable ...)        | **/
/** |                                                    | **/
/** ------------------------------------------------------ **/

/* eslint-disable @typescript-eslint/naming-convention*/

export const OrderStatusMap = {
    'En attente': { text: 'En attente', value: 'waiting' },
    Refusé: { text: 'Refusé', value: 'refused' },
    Validé: { text: 'Validé', value: 'validated' },
};
