<div class="fullheight">
    <form [formGroup]="utilisateurEntrepriseForm" (ngSubmit)="onUpdate()">
        <div class="group__field">
            <div class="field__row">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ "Nom de l'entreprise" }}
                    </mat-label>
                    <mat-select formControlName="abreviation_entreprise" name="abreviation_entreprise" class="dropdown">

                        <mat-option *ngFor="let dropdownItem of dropdownItems$| async" [value]="dropdownItem.value">
                            {{dropdownItem.viewValue}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="utilisateurEntrepriseForm.controls.abreviation_entreprise.invalid">
                        {{ this.translatePrefix + '.errors' + '.abreviation_entreprise' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <app-generic-form-buttons
            [disabled]="utilisateurEntrepriseForm.invalid || !isSaveable"
            [saveTooltipLabel]="'Confirmer'"
            (cancel)="onCancel()">
        </app-generic-form-buttons>
    </form>
</div>
