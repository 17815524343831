import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import moment from 'moment';
import { Store } from '@ngrx/store';

import { Order } from '../../../../../shared/models/database/order.model';
import { OrderType } from '../../../../../shared/models/database/order-type.enum';
import { Timesheet } from '../../../../../shared/models/database/timesheet.model';
import { OrderGroup } from '../../../../../shared/models/database/order-group.model';
import { PostLineGroup } from '../../../../../shared/models/database/post-line-group.model';
import { PostLine } from '../../../../../shared/models/database/post-line.model';
import { GenericFormModalComponent } from '../generic-form-modal/generic-form-modal.component';
import { FormModalData } from '../generic-form-modal/generic-form-modal-data.model';
import { CreateOrder, DeleteOneOrder } from '../../../../../ogiv-core/store/actions/orders.action';
import { Unit } from '../../../../../shared/models/database/unit.model';
import { OrderStatus, getOrderCss } from '../../../../../shared/models/database/order-status.enum';
import { UserRole } from '../../../../../shared/models/user-roles.model';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { CurrentUser, UserService } from '../../../../../core/services/user.service';

@Component({
    selector: 'app-order-accordion',
    templateUrl: './order-accordion.component.html',
    styleUrls: ['./order-accordion.component.scss']
})
export class OrderAccordionComponent implements OnChanges {
    public childrenStatus = '';
    public timesheetDateId = '';
    public timesheetId: number;
    public activeIndex: number | null = null;
    public displayAddButton = false;
    public displayMenu = true;
    public hideButtonMenu = ['add', 'edit'];

    @Input() public timesheet: Timesheet;
    @Input() public pathChoice: AlternativePath;
    @Input() public editAllowedRoles: UserRole[] = [];
    @Input() public orderGroup: OrderGroup;
    @Input() public accordionIndex = 0;

    constructor(
        public dialog: MatDialog,
        private readonly store: Store,
        private userService: UserService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.timesheet && changes.timesheet.currentValue) {
            this.timesheetDateId = moment(changes.timesheet.currentValue.date).format('YYYYMMDD');
            this.timesheetId = changes.timesheet.currentValue.id;
        }
    }

    public hasUnitlyOrder(orderGroup: OrderGroup): boolean {
        return orderGroup.unitly !== null;
    }

    public hasHourlyOrder(orderGroup: OrderGroup): boolean {
        return orderGroup.hourly !== null;
    }

    public hasOutageOrder(orderGroup: OrderGroup): boolean {
        return orderGroup.outage !== null;
    }

    public getUnitDone(order: Order): number {
        let number = 0;

        if (order != null && order.postLineGroups && order.postLineGroups !== null) {
            const flatPostline: PostLine[] = [];
            order.postLineGroups.forEach((myPostLinesGroup: PostLineGroup) => {
                if (myPostLinesGroup.postLines && myPostLinesGroup.postLines !== null && myPostLinesGroup.postLines.length > 0) {
                    myPostLinesGroup.postLines.forEach((postLine: PostLine) => {
                        flatPostline.push(postLine);
                    });
                }
            });
            if (flatPostline.length > 0) {
                const flatUnits: Unit[] = [];
                flatPostline.forEach((postLine: PostLine) => {
                    if (postLine.units && postLine.units !== null && postLine.units.length > 0) {
                        postLine.units.forEach((unit: Unit) => {
                            flatUnits.push(unit);
                        });
                    }
                });
                if (flatUnits.length > 0) {
                    number = flatUnits.map((unit: Unit) => unit.quantity).reduce((acc: number, ele: number) => acc + ele, number);
                }
            }
        }

        return number;
    }

    public createOrder(orderGroup: OrderGroup, event: Event): void {
        event.stopPropagation();
        const addOrderDialog = this.dialog.open(GenericFormModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                icon: 'fas fa-plus',
                formName: 'order',
                translatePrefix: 'timesheet.order.form',
                parentId: this.timesheetId,
                titleKey: '',
                specificDetail: {
                    number: orderGroup.number,
                    accountingEntry: orderGroup.accountingEntry,
                    billingType: this.getExistingOrderType(orderGroup),
                },
                pathChoice: this.pathChoice,
            } as FormModalData,
        });

        addOrderDialog.afterClosed().subscribe((result: Order) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }

                this.store.dispatch(new CreateOrder(result, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }

    public getExistingOrderType(orderGroup: OrderGroup): OrderType[] {
        const existingType: OrderType[] = [];
        if (orderGroup.unitly) {
            existingType.push(OrderType.unit);
        }

        if (orderGroup.hourly) {
            existingType.push(OrderType.hourly);
        }

        if (orderGroup.outage) {
            existingType.push(OrderType.outage);
        }

        return existingType;
    }

    public openPanel(index: number): void {
        if (this.activeIndex !== index) {
            this.activeIndex = index;
        }
    }

    public closePanel(index: number): void {
        if (this.activeIndex === index) {
            this.activeIndex = null;
        }
    }

    public shouldDisplayBubble(accordionIndex: number): boolean {
        return this.activeIndex === accordionIndex;
    }

    public shouldDisplayActionsButtons(accordionIndex: number, orderGroup: OrderGroup) {
        if (this.shouldDisplayBubble(accordionIndex)) {
            this.displayAddButton = this.shouldDisplayAddButton(orderGroup);
            this.displayMenu = this.shouldDisplayMenuButton(orderGroup);
            return this.displayAddButton || this.displayMenu;
        }

        return false;
    }

    public shouldLockActionButton(orderGroup: OrderGroup): boolean {
        return !this.shouldDisplayAddButton(orderGroup) && !this.shouldDisplayMenuButton(orderGroup);
    }

    public lockOrderGroup(orderGroup: OrderGroup): boolean {
        let numberOfOrder = 0;
        let numberOfLock = 0;

        if (orderGroup !== null) {
            if (orderGroup.unitly !== null) {
                numberOfOrder++;
                numberOfLock += +(orderGroup.unitly.isLock);
            }

            if (orderGroup.hourly !== null) {
                numberOfOrder++;
                numberOfLock += +(orderGroup.hourly.isLock);
            }

            if (orderGroup.outage !== null) {
                numberOfOrder++;
                numberOfLock += +(orderGroup.outage.isLock);
            }
        }

        return numberOfOrder === numberOfLock;
    }

    public shouldDisplayAddButton(orderGroup: OrderGroup): boolean {
        return orderGroup.unitly === null || orderGroup.hourly === null || orderGroup.outage === null;
    }

    public shouldDisplayMenuButton(orderGroup: OrderGroup): boolean {
        return (orderGroup.unitly !== null || orderGroup.hourly !== null || orderGroup.outage !== null) && !this.lockOrderGroup(orderGroup);
    }

    public getChildrenStatus(orderGroup: OrderGroup): string {
        let childrenStatus = '';
        if (orderGroup !== null) {
            if ((orderGroup.unitly !== null && orderGroup.unitly.status === OrderStatus.refused) ||
                (orderGroup.hourly !== null && orderGroup.hourly.status === OrderStatus.refused) ||
                (orderGroup.outage !== null && orderGroup.outage.status === OrderStatus.refused)) {
                childrenStatus = OrderStatus.refused;
            } else if ((orderGroup.unitly !== null && orderGroup.unitly.status === OrderStatus.waiting) ||
                (orderGroup.hourly !== null && orderGroup.hourly.status === OrderStatus.waiting) ||
                (orderGroup.outage !== null && orderGroup.outage.status === OrderStatus.waiting)) {
                childrenStatus = OrderStatus.waiting;
            } else {
                let count = 0;
                let countValidated = 0;

                if (orderGroup.unitly !== null) {
                    count++;

                    if (orderGroup.unitly.status === OrderStatus.validated) {
                        countValidated++;
                    }
                }

                if (orderGroup.hourly !== null) {
                    count++;

                    if (orderGroup.hourly.status === OrderStatus.validated) {
                        countValidated++;
                    }
                }

                if (orderGroup.outage !== null) {
                    count++;

                    if (orderGroup.outage.status === OrderStatus.validated) {
                        countValidated++;
                    }
                }

                if (count > 0 && countValidated === count) {
                    childrenStatus = OrderStatus.validated;
                }
            }
        }
        return childrenStatus;
    }

    public getCssChildrenStatus(orderGroup: OrderGroup): string {
        const cssStatus = this.getChildrenStatus(orderGroup);
        return !!cssStatus ? getOrderCss(cssStatus) : '';
    }

    public deleteOrder(orderGroup: OrderGroup, event: Event) {
        event.stopPropagation();
        const deleteOrderDialog = this.dialog.open(GenericFormModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                icon: 'far fa-trash-alt',
                formName: 'orderDelete',
                translatePrefix: 'timesheet.order.form',
                parentId: this.timesheetId,
                titleKey: '',
                entity: orderGroup,
                pathChoice: this.pathChoice,
                deleteTitleKey: 'deleteTitle',
            } as FormModalData,
        });

        deleteOrderDialog.afterClosed().subscribe((orderToDelete: Order) => {
            if (orderToDelete) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }

                this.store.dispatch(new DeleteOneOrder(orderToDelete, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }
}
