import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { DemandeClient } from '../../../../shared/models/database/demande-client.model';
import { DemandesClientEcartsLoad } from '../../../store/actions';
import { getAllDemandesClientEcarts, getDemandesClientEcartsLoading } from '../../../store/selectors';
import { TableColumns } from '../../../../shared/models/table-columns.model';

@Component({
    selector: 'app-demandes-client-ecarts',
    templateUrl: './demandes-client-ecarts.component.html',
    styleUrls: ['./demandes-client-ecarts.component.scss']
})
export class DemandesClientEcartsComponent implements OnInit {

    public sortOrder = -1;
    public sortField = 'numeroOrdre';
    public translatePrefix = 'demandes-client.ecarts.';
    public commonTranslatePrefix = 'demandes-client.common.';

    public columns: TableColumns[] = [
        { field: 'numeroOrdre', header: this.translate.instant(this.commonTranslatePrefix + 'NumeroOrdre'), width: '125px' },
        { field: 'statutOGIV', header: this.translate.instant(this.commonTranslatePrefix + 'StatutOGIV'), width: '175px' },
    ];

    public demandesClientEcarts$: Observable<DemandeClient[]> = this.store.pipe(
        select(getAllDemandesClientEcarts),
    );

    public demandesClientEcartsLoading$: Observable<boolean> = this.store.pipe(
        select(getDemandesClientEcartsLoading),
    );

    constructor(
        private readonly store: Store,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {

        this.store.dispatch(new DemandesClientEcartsLoad());

    }
}
