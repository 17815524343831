import { JobClass } from '../../../shared/models/database/job-class.model';
import { JobClassesAction, JobClassesActionTypes } from '../actions/job-classes.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface JobClassesState extends EntityState<JobClass> {
    loading: boolean;
    loaded: boolean;
}

export const jobClassesAdapter: EntityAdapter<JobClass> = createEntityAdapter<JobClass>({
    selectId: (jobClass: JobClass) => jobClass.id,
});

export const jobClassesInitialState = jobClassesAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function jobClassReducer(
    state: JobClassesState = jobClassesInitialState,
    action: JobClassesAction,
): JobClassesState {
    switch (action.type) {
        case JobClassesActionTypes.CREATE_JOB_CLASS:
        case JobClassesActionTypes.UPDATE_ONE_JOB_CLASS:
        case JobClassesActionTypes.LOAD_JOB_CLASSES: {
            return {
                ...state,
                loading: true,
            };
        }

        case JobClassesActionTypes.CREATE_JOB_CLASS_SUCCESS: {
            return jobClassesAdapter.setAll(action.jobClasses, {
                ...state,
                loading: false,
            });
        }

        case JobClassesActionTypes.UPDATE_ONE_JOB_CLASS_SUCCESS: {
            return jobClassesAdapter.upsertOne(action.jobClass, {
                ...state,
                loading: false,
            });
        }

        case JobClassesActionTypes.CREATE_JOB_CLASS_FAIL:
        case JobClassesActionTypes.UPDATE_ONE_JOB_CLASS_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case JobClassesActionTypes.LOAD_JOB_CLASSES_SUCCESS: {
            return jobClassesAdapter.setAll(action.jobClasses, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case JobClassesActionTypes.LOAD_JOB_CLASSES_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case JobClassesActionTypes.LOAD_JOB_CLASSES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
