import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { billingTimesheetsAdapter, BillingTimesheetsState } from '../reducers/billing-timesheet.reducer';
import { BillingState, getBillingState } from '../reducers';
import { BillingTimesheet } from '../../../shared/models/database/billing-timesheet.model';
import { FormMode } from '../../../shared/models/form-mode.model';

const {
    selectAll,
    selectEntities,
    selectIds,
} = billingTimesheetsAdapter.getSelectors();

export const getBillingTimesheetsState = createSelector(
    getBillingState,
    (state: BillingState) => state.billingTimesheets,
);

export const getBillingTimesheetsLoading = createSelector(
    getBillingTimesheetsState,
    (state: BillingTimesheetsState) => state.loading,
);

export const getBillingTimesheetsLoaded = createSelector(
    getBillingTimesheetsState,
    (state: BillingTimesheetsState) => state.loaded,
);

export const shouldLoadBillingTimesheets = createSelector(
    getBillingTimesheetsLoading,
    getBillingTimesheetsLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllBillingTimesheets = createSelector(
    getBillingTimesheetsState,
    selectAll
);

export const getBillingTimesheetsEntities = createSelector(
    getBillingTimesheetsState,
    selectEntities,
);

export const getBillingTimesheetsIds = createSelector(
    getBillingTimesheetsState,
    selectIds,
);

export const getBillingTimesheetById = (prop: any) => createSelector(
    getBillingTimesheetsEntities,
    (entities: Dictionary<BillingTimesheet>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getBillingTimesheetsByOrderAndAccountingEntry = (props: { formMode: FormMode | null, selectedOrder?: string, selectedAccountingEntry?: string } =
    { formMode: null, selectedOrder: '', selectedAccountingEntry: '' }) => createSelector(
        getAllBillingTimesheets,
        (billingTimesheets: BillingTimesheet[]): BillingTimesheet[] => {
            const { formMode, selectedOrder, selectedAccountingEntry } = props;
            let filteredBillingTimesheets: BillingTimesheet[] = [];
            if (selectedOrder && selectedOrder.length > 0 &&
                selectedAccountingEntry && selectedAccountingEntry.length > 0) {
                filteredBillingTimesheets = billingTimesheets
                    .filter((timesheet: BillingTimesheet) => timesheet.order === selectedOrder && timesheet.accountingEntry === selectedAccountingEntry);
                if (formMode === FormMode.display) {
                    filteredBillingTimesheets =
                        filteredBillingTimesheets.filter((billingTimesheet: BillingTimesheet) => billingTimesheet.billingReportId !== null);
                }
            }

            return filteredBillingTimesheets;
        }
    );
