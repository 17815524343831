import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { Worker } from '../../../shared/models/database/worker.model';
import { WorkerActionTypes, WorkersAction } from '../actions/team-mates.action';

export interface WorkersState extends EntityState<Worker> {
    loading: boolean;
    loaded: boolean;
}

export const workersAdapter: EntityAdapter<Worker> = createEntityAdapter<Worker>({
    selectId: (worker: Worker) => worker.id,
});

export const workersInitialState = workersAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function workersReducer(
    state: WorkersState = workersInitialState,
    action: WorkersAction
): WorkersState {
    switch (action.type) {
        case WorkerActionTypes.CREATE_WORKER:
        case WorkerActionTypes.UPDATE_ONE_WORKER:
        case WorkerActionTypes.DELETE_ONE_WORKER: {
            return {
                ...state,
                loading: true,
            };
        }

        case WorkerActionTypes.CREATE_WORKER_SUCCESS: {
            // return workersAdapter.addOne(action.worker, {
            return {
                ...state,
                loading: false,
            };
        }

        case WorkerActionTypes.UPDATE_ONE_WORKER_SUCCESS: {
            // return workersAdapter.upsertOne(action.worker, {
            return {
                ...state,
                loading: false,
            };
        }

        case WorkerActionTypes.DELETE_ONE_WORKER_FAIL:
        case WorkerActionTypes.UPDATE_ONE_WORKER_FAIL:
        case WorkerActionTypes.CREATE_WORKER_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case WorkerActionTypes.LOAD_WORKERS: {
            return {
                ...state,
                loading: true,
            };
        }

        case WorkerActionTypes.LOAD_WORKERS_SUCCESS: {
            return workersAdapter.setAll(action.workers, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case WorkerActionTypes.LOAD_WORKERS_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case WorkerActionTypes.LOAD_WORKERS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        case WorkerActionTypes.DELETE_ONE_WORKER_SUCCESS: {
            // return workersAdapter.removeOne(action.worker.id, {
            return {
                ...state,
                loading: false,
            };
        }

        default:
            return state;
    }
}
