export class UtilisateurSynchro {
    type: UtilisateurSynchroType;
    // canDelete: string;
    creationDate: number;
    lastSyncDate: number;
    replicaID: string;
    // replicaName: string;
    replicaOwner: string;
    vue: string;
}

export enum UtilisateurSynchroType {
    execution = 'Execution',
    inventory = 'Inventaire',
}
