import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Order } from '../../../../../shared/models/database/order.model';
import { OrderType } from '../../../../../shared/models/database/order-type.enum';

@Component({
    selector: 'app-order-form',
    templateUrl: './order-form.component.html',
    styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnChanges {
    public orderForm: FormGroup;

    public isUnitlyExist = false;
    public isHourlyExist = false;
    public isOutageExist = false;

    public unitly = OrderType.unit;
    public hourly = OrderType.hourly;
    public outage = OrderType.outage;

    @Input() public timesheetId: number | null;
    @Input() public translatePrefix: string;
    @Input() public specificDetail: {} | null;

    @Output() public create: EventEmitter<Order> = new EventEmitter<Order>();
    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        formBuilder: FormBuilder,
    ) {
        this.orderForm = formBuilder.group({
            timesheetId: ['', [Validators.required]],
            number: ['', [Validators.required]],
            accountingEntry: ['', [Validators.required]],
            billingType: ['', [Validators.required]],
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.timesheetId && changes.timesheetId.currentValue) {
            this.orderForm.controls.timesheetId.setValue(changes.timesheetId.currentValue);
        }

        if (changes && changes.specificDetail && changes.specificDetail.currentValue) {
            if (changes.specificDetail.currentValue.number) {
                this.orderForm.controls.number.setValue(changes.specificDetail.currentValue.number);
                this.orderForm.controls.number.disable({ onlySelf: true });
            }

            if (changes.specificDetail.currentValue.accountingEntry) {
                this.orderForm.controls.accountingEntry.setValue(changes.specificDetail.currentValue.accountingEntry);
                this.orderForm.controls.accountingEntry.disable({ onlySelf: true });
            }

            if (changes.specificDetail.currentValue.billingType) {
                this.disabledExistingOrder(changes.specificDetail.currentValue.billingType);
            }
        }
    }

    public cancelForm() {
        this.cancel.emit(true);
    }

    public onSubmit() {
        const { valid } = this.orderForm;

        const value = {
            ...this.orderForm.getRawValue(),
        };

        if (valid) {
            this.create.emit(value);
        }
    }

    private disabledExistingOrder(billingTypes: OrderType[]): void {
        billingTypes.forEach(billingType => {
            if (billingType === OrderType.unit) {
                this.isUnitlyExist = true;
            }

            if (billingType === OrderType.hourly) {
                this.isHourlyExist = true;
            }

            if (billingType === OrderType.outage) {
                this.isOutageExist = true;
            }
        });
    }
}
