/**
 * Types
 */

import { Action } from '@ngrx/store';
import { CurrentUser } from '../../../core/services/user.service';
import { Timesheet } from '../../../shared/models/database/timesheet.model';

export enum TimesheetsActionTypes {
    LOAD_TIMESHEET_BY_ID = '[Timesheet] Load Timesheet by id',
    LOAD_TIMESHEET_BY_ID_AND_IMPERSONNIFICATION = '[Timesheet] Load Timesheet by id and impersonnification',
    LOAD_TIMESHEET_SUCCESS = '[Timesheet] Load Timesheet Success',
    LOAD_TIMESHEET_NO_RESULT = '[Timesheet] Load Timesheet No Result',
    LOAD_TIMESHEET_FAIL = '[Timesheet] Load Timesheet Fail',
    CREATE_TIMESHEET = '[Timesheet] Create timesheet',
    CREATE_TIMESHEET_SUCCESS = '[Timesheet] Create timesheet Success',
    CREATE_TIMESHEET_FAIL = '[Timesheet] Create timesheet Fail',
    DELETE_TIMESHEET = '[Timesheet] Delete timesheet',
    DELETE_TIMESHEET_SUCCESS = '[Timesheet] Delete timesheet Success',
    DELETE_TIMESHEET_FAIL = '[Timesheet] Delete timesheet Fail',
    APPROVE_TIMESHEET = '[Timesheet] Approve timesheet',
    APPROVE_TIMESHEET_SUCCESS = '[Timesheet] Approve timesheet Success',
    APPROVE_TIMESHEET_FAIL = '[Timesheet] Approve timesheet Fail',
    SUBMIT_TIMESHEET = '[Timesheet] Submit timesheet',
    SUBMIT_TIMESHEET_SUCCESS = '[Timesheet] Submit timesheet Success',
    SUBMIT_TIMESHEET_FAIL = '[Timesheet] Submit timesheet Fail',
}

/**
 * ACTIONS
 */
export class LoadTimesheetById implements Action {
    readonly type = TimesheetsActionTypes.LOAD_TIMESHEET_BY_ID;
    constructor(public dateId: string, public path: string) { }
}

export class LoadTimesheetByIdAndImpersonnification implements Action {
    readonly type = TimesheetsActionTypes.LOAD_TIMESHEET_BY_ID_AND_IMPERSONNIFICATION;
    constructor(public dateId: string, public path: string, public idAgol: string) { }
}

export class LoadTimesheetsNoresult implements Action {
    readonly type = TimesheetsActionTypes.LOAD_TIMESHEET_NO_RESULT;
}

export class LoadTimesheetsSuccess implements Action {
    readonly type = TimesheetsActionTypes.LOAD_TIMESHEET_SUCCESS;
    constructor(public timesheet: Timesheet) { }
}

export class LoadTimesheetsFail implements Action {
    readonly type = TimesheetsActionTypes.LOAD_TIMESHEET_FAIL;
    constructor(public error: any) { }
}

export class CreateTimesheet implements Action {
    readonly type = TimesheetsActionTypes.CREATE_TIMESHEET;
    constructor(public timesheet: Timesheet, public path: string) { }
}

export class CreateTimesheetSuccess implements Action {
    readonly type = TimesheetsActionTypes.CREATE_TIMESHEET_SUCCESS;
    constructor(public timesheet: Timesheet) { }
}

export class CreateTimesheetFail implements Action {
    readonly type = TimesheetsActionTypes.CREATE_TIMESHEET_FAIL;
    constructor(public error: any) { }
}

export class ApproveTimesheet implements Action {
    readonly type = TimesheetsActionTypes.APPROVE_TIMESHEET;
    constructor(public timesheetDateId: string, public path: string) { }
}

export class ApproveTimesheetSuccess implements Action {
    readonly type = TimesheetsActionTypes.APPROVE_TIMESHEET_SUCCESS;
    constructor(public timesheet: Timesheet) { }
}

export class ApproveTimesheetFail implements Action {
    readonly type = TimesheetsActionTypes.APPROVE_TIMESHEET_FAIL;
    constructor(public error: any) { }
}

export class DeleteTimesheet implements Action {
    readonly type = TimesheetsActionTypes.DELETE_TIMESHEET;
    constructor(public timesheetDateId: string, public path: string) { }
}

export class DeleteTimesheetSuccess implements Action {
    readonly type = TimesheetsActionTypes.DELETE_TIMESHEET_SUCCESS;
    constructor(public timesheetDateId: string) { }
}

export class DeleteTimesheetFail implements Action {
    readonly type = TimesheetsActionTypes.DELETE_TIMESHEET_FAIL;
    constructor(public error: any) { }
}

export class SubmitTimesheet implements Action {
    readonly type = TimesheetsActionTypes.SUBMIT_TIMESHEET;
    constructor(public timesheetDateId: string, public path: string, public imperson?: CurrentUser) { }
}

export class SubmitTimesheetSuccess implements Action {
    readonly type = TimesheetsActionTypes.SUBMIT_TIMESHEET_SUCCESS;
    constructor(public timesheet: Timesheet) { }
}

export class SubmitTimesheetFail implements Action {
    readonly type = TimesheetsActionTypes.SUBMIT_TIMESHEET_FAIL;
    constructor(public error: any) { }
}

export type TimesheetsAction =
    | LoadTimesheetById
    | LoadTimesheetByIdAndImpersonnification
    | LoadTimesheetsNoresult
    | LoadTimesheetsSuccess
    | LoadTimesheetsFail
    | CreateTimesheet
    | CreateTimesheetSuccess
    | CreateTimesheetFail
    | DeleteTimesheet
    | DeleteTimesheetSuccess
    | DeleteTimesheetFail
    | ApproveTimesheet
    | ApproveTimesheetSuccess
    | ApproveTimesheetFail
    | SubmitTimesheet
    | SubmitTimesheetSuccess
    | SubmitTimesheetFail;
