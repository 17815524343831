import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { OgivCoreState, getOgivCoreState } from '../reducers';
import { jobCodesAdapter, JobCodesState } from '../reducers/job-codes.reducer';
import { JobCode, JobCodeStatus } from '../../../shared/models/database/job-code.model';
import { SelectItem } from 'primeng/api';
import moment from 'moment';

const {
    selectAll,
    selectEntities,
    selectIds,
} = jobCodesAdapter.getSelectors();

export const getJobCodesState = createSelector(
    getOgivCoreState,
    (state: OgivCoreState) => state.jobCodes,
);

export const getJobCodesLoading = createSelector(
    getJobCodesState,
    (state: JobCodesState) => state.loading,
);

export const getJobCodesLoaded = createSelector(
    getJobCodesState,
    (state: JobCodesState) => state.loaded,
);

export const shouldLoadJobCodes = createSelector(
    getJobCodesLoading,
    getJobCodesLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllJobCodes = createSelector(
    getJobCodesState,
    selectAll
);

export const getJobCodesEntities = createSelector(
    getJobCodesState,
    selectEntities,
);

export const getJobCodesIds = createSelector(
    getJobCodesState,
    selectIds,
);

export const getJobCodeById = (prop: { entityId: number }) => createSelector(
    getJobCodesEntities,
    (entities: Dictionary<JobCode>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllJobCodesSorted = createSelector(
    getAllJobCodes,
    (jobCodes: JobCode[]): JobCode[] => {
        const temporaire = [...jobCodes];
        temporaire.sort((a: JobCode, b: JobCode): number => {
            return (a.code.toLocaleLowerCase()).localeCompare(b.code.toLocaleLowerCase());
        });
        return temporaire;
    }
);

export const getActiveJobCodes = (props: { date: string } = { date: '' }) => createSelector(
    getAllJobCodesSorted,
    (jobCodes: JobCode[]): JobCode[] => {
        const activeJobCodes = getAllActiveJobCodes(jobCodes, props.date);
        return activeJobCodes;
    }
);

export const doesSelectedJobCodeIsActive = (props: { code: string } = { code: '' }) => createSelector(
    getActiveJobCodes(),
    (jobCodes: JobCode[]): boolean => {
        const found = (jobCodes.filter((jobCode: JobCode) => jobCode.code === props.code));
        return found.length > 0;
    }
);

export const getActiveJobCodesFullObjectOptions = (props: { addEmptyOption: boolean, date: string } = { addEmptyOption: false, date: '' }) => createSelector(
    getAllJobCodesSorted,
    (jobCodes: JobCode[]): SelectItem[] => {
        const filteredJobCodes = getAllActiveJobCodes(jobCodes, props.date);
        const jobCodesOptions = filteredJobCodes.map((jobCode: JobCode) => {
            return {
                label: `(${jobCode.code}) ${jobCode.description}`,
                value: jobCode,
            } as SelectItem;
        });

        if (props.addEmptyOption) {
            jobCodesOptions.unshift({
                label: 'Sélectionner un code',
                value: '',
            } as SelectItem);
        }

        return jobCodesOptions;
    }
);

export const getActiveJobCodesOptions = (props: { addEmptyOption: boolean, date: string } = { addEmptyOption: false, date: '' }) => createSelector(
    getAllJobCodesSorted,
    (jobCodes: JobCode[]): SelectItem[] => {
        const filteredJobCodes = getAllActiveJobCodes(jobCodes, props.date);
        const jobCodesOptions = filteredJobCodes.map((jobCode: JobCode) => {
            return {
                label: `(${jobCode.code}) ${jobCode.description}`,
                value: jobCode.code,
            } as SelectItem;
        });

        if (props.addEmptyOption) {
            jobCodesOptions.unshift({
                label: 'Sélectionner un code',
                value: '',
            } as SelectItem);
        }

        return jobCodesOptions;
    }
);

export const getActiveJobCodesSearchFullObjectOptions = (props: { addEmptyOption: boolean, text: string, date: string }
    = { addEmptyOption: false, text: '', date: '' }) => createSelector(
        getAllJobCodesSorted,
        (jobCodes: JobCode[]): SelectItem[] => {
            const activeJobCodes = getAllActiveJobCodes(jobCodes, props.date);
            const filteredJobCodes = activeJobCodes.filter(jobCode => {
                return jobCode.code.toUpperCase().includes(props.text.toUpperCase()) || jobCode.description.toUpperCase().includes(props.text.toUpperCase());
            });

            const jobCodesOptions = filteredJobCodes.map((jobCode: JobCode) => {
                return {
                    label: `(${jobCode.code}) ${jobCode.description}`,
                    value: jobCode,
                } as SelectItem;
            });

            if (props.addEmptyOption) {
                jobCodesOptions.unshift({
                    label: 'Sélectionner un code',
                    value: '',
                } as SelectItem);
            }

            return jobCodesOptions;
        }
    );

export const getActiveJobCodesSearchOptions = (props: { addEmptyOption: boolean, text: string, date: string }
    = { addEmptyOption: false, text: '', date: '' }) => createSelector(
        getAllJobCodesSorted,
        (jobCodes: JobCode[]): SelectItem[] => {
            const activeJobCodes = getAllActiveJobCodes(jobCodes, props.date);
            const filteredJobCodes = activeJobCodes.filter(jobCode => {
                return jobCode.code.toUpperCase().includes(props.text.toUpperCase()) || jobCode.description.toUpperCase().includes(props.text.toUpperCase());
            });

            const jobCodesOptions = filteredJobCodes.map((jobCode: JobCode) => {
                return {
                    label: `(${jobCode.code}) ${jobCode.description}`,
                    value: jobCode.code,
                } as SelectItem;
            });

            if (props.addEmptyOption) {
                jobCodesOptions.unshift({
                    label: 'Sélectionner un code',
                    value: '',
                } as SelectItem);
            }

            return jobCodesOptions;
        }
    );

export const getJobCodeByCode = ({ code = '' }: { code?: string } = {}) => createSelector(
    getAllJobCodesSorted,
    (jobCodes: JobCode[]): JobCode | null => {
        const foundJobCode = jobCodes.filter((jobCode: JobCode) => jobCode.code === code);
        return foundJobCode.length === 1 ? foundJobCode[0] : null;
    }
);

export const getJobCodeByCodeAndDescription = ({ code = '', description = '', date = '' }: { code?: string, description?: string, date?: string } = {}) => createSelector(
    getAllJobCodesSorted,
    (jobCodes: JobCode[],): JobCode | null => {
        const activeJobCodes = getAllActiveJobCodes(jobCodes, date);
        const foundJobCode = activeJobCodes.filter((jobCode: JobCode) => jobCode.code === code && jobCode.description === description);
        return foundJobCode.length === 1 ? foundJobCode[0] : null;
    }
);

function getAllActiveJobCodes(codes: JobCode[], date: string): JobCode[] {
    let activeCodes;
    if (date === undefined || date === '') {
        activeCodes = codes.filter(code => code.status.toLocaleLowerCase() === JobCodeStatus.active.toLocaleLowerCase());
    } else {
        activeCodes = codes.filter((code: JobCode) => code.status.toLocaleLowerCase() === JobCodeStatus.active.toLocaleLowerCase()
            || moment(code.date_inactif || '1900-01-01').format('YYYYMMDD') > date
        );
    }
    return activeCodes;
}
