import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { TimesheetReport } from '../../../shared/models/database/timesheet-report.model';

/**
 * Types
 */

export enum TimesheetReportActionTypes {
    REMARK_TIMESHEET_REPORT = '[TimesheetReport] Remark Timesheet Report',
    REMARK_TIMESHEET_REPORT_SUCCESS = '[TimesheetReport] Remark Timesheet Report Success',
    REMARK_TIMESHEET_REPORT_FAIL = '[TimesheetReport] Remark Timesheet Report Fail',
    LOAD_TIMESHEET_REPORT_BY_ID = '[TimesheetReport] Load Timesheet Report By ID',
    LOAD_TIMESHEET_REPORT_BY_ID_SUCCESS = '[TimesheetReport] Load Timesheet Report By ID Success',
    LOAD_TIMESHEET_REPORT_BY_ID_FAIL = '[TimesheetReport] Load Timesheet Report By ID Fail',
    APPROVE_TIMESHEET_REPORT = '[TimesheetReport] Approve Timesheet Report',
    APPROVE_TIMESHEET_REPORT_SUCCESS = '[TimesheetReport] Approve Timesheet Report Success',
    APPROVE_TIMESHEET_REPORT_FAIL = '[TimesheetReport] Approve Timesheet Report Fail',
    REFUSE_TIMESHEET_REPORT = '[TimesheetReport] Refuse Timesheet Report',
    REFUSE_TIMESHEET_REPORT_SUCCESS = '[TimesheetReport] Refuse Timesheet Report Success',
    REFUSE_TIMESHEET_REPORT_FAIL = '[TimesheetReport] Refuse Timesheet Report Fail',

    LOAD_TIMESHEET_REPORT_BY_ID_AND_SITE_NAME = '[TimesheetReport] Load Timesheet Report By ID and site name',
    LOAD_TIMESHEET_REPORT_BY_ID_AND_SITE_NAME_SUCCESS = '[TimesheetReport] Load Timesheet Report By ID and site name Success',
    LOAD_TIMESHEET_REPORT_BY_ID_AND_SITE_NAME_FAIL = '[TimesheetReport] Load Timesheet Report By ID and site name Fail',
    APPROVE_TIMESHEET_REPORT_TECH = '[TimesheetReport] Approve Timesheet Report tech',
    APPROVE_TIMESHEET_REPORT_TECH_SUCCESS = '[TimesheetReport] Approve Timesheet Report tech Success',
    APPROVE_TIMESHEET_REPORT_TECH_FAIL = '[TimesheetReport] Approve Timesheet Report tech Fail',
    REFUSE_TIMESHEET_REPORT_TECH = '[TimesheetReport] Refuse Timesheet Report tech',
    REFUSE_TIMESHEET_REPORT_TECH_SUCCESS = '[TimesheetReport] Refuse Timesheet Report tech Success',
    REFUSE_TIMESHEET_REPORT_TECH_FAIL = '[TimesheetReport] Refuse Timesheet Report tech Fail',
}

/**
 * ACTIONS
 */

/**
 * Remark
 */
export class RemarkTimesheetReport implements Action {
    readonly type = TimesheetReportActionTypes.REMARK_TIMESHEET_REPORT;
    constructor(public timesheetReportId: number | string, public path: AlternativePath, public remark: string) { }
}

export class RemarkTimesheetReportSuccess implements Action {
    readonly type = TimesheetReportActionTypes.REMARK_TIMESHEET_REPORT_SUCCESS;
    constructor(public timesheetReport: TimesheetReport) { }
}

export class RemarkTimesheetReportFail implements Action {
    readonly type = TimesheetReportActionTypes.REMARK_TIMESHEET_REPORT_FAIL;
    constructor(public error: any) { }
}

/**
 * Load (ent)
 */
export class LoadTimesheetReportById implements Action {
    readonly type = TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID;
    constructor(public timesheetReportId: string | number, public path: AlternativePath) { }
}

export class LoadTimesheetReportByIdSuccess implements Action {
    readonly type = TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID_SUCCESS;
    constructor(public timesheetReport: TimesheetReport) { }
}

export class LoadTimesheetReportByIdFail implements Action {
    readonly type = TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID_FAIL;
    constructor(public error: any) { }
}

/**
 * Approve
 */
export class ApproveTimesheetReport implements Action {
    readonly type = TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT;
    constructor(public timesheetReportId: string | number, public path: AlternativePath) { }
}

export class ApproveTimesheetReportSuccess implements Action {
    readonly type = TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_SUCCESS;
    constructor(public timesheetReport: TimesheetReport) { }
}

export class ApproveTimesheetReportFail implements Action {
    readonly type = TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_FAIL;
    constructor(public error: any) { }
}

/**
 * Refuse
 */
export class RefuseTimesheetReport implements Action {
    readonly type = TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT;
    constructor(public timesheetReportId: string | number, public comment: string, public rejectedDays: string[], public path: AlternativePath) { }
}

export class RefuseTimesheetReportSuccess implements Action {
    readonly type = TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_SUCCESS;
    constructor(public timesheetReport: TimesheetReport) { }
}

export class RefuseTimesheetReportFail implements Action {
    readonly type = TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_FAIL;
    constructor(public error: any) { }
}

/**
 * Load (technicien)
 */
export class LoadTimesheetReportByIdAndSiteName implements Action {
    readonly type = TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID_AND_SITE_NAME;
    constructor(public timesheetReportId: string | number, public siteName: string, public path: AlternativePath) { }
}

export class LoadTimesheetReportByIdAndSiteNameSuccess implements Action {
    readonly type = TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID_AND_SITE_NAME_SUCCESS;
    constructor(public timesheetReport: TimesheetReport) { }
}

export class LoadTimesheetReportByIdAndSiteNameFail implements Action {
    readonly type = TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID_AND_SITE_NAME_FAIL;
    constructor(public error: any) { }
}

/**
 * Approve (technicien)
 */
export class ApproveTimesheetReportTech implements Action {
    readonly type = TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_TECH;
    constructor(public timesheetReportId: string | number, public site: string, public path: AlternativePath) { }
}

export class ApproveTimesheetReportTechSuccess implements Action {
    readonly type = TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_TECH_SUCCESS;
    constructor(public timesheetReport: TimesheetReport) { }
}

export class ApproveTimesheetReportTechFail implements Action {
    readonly type = TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_TECH_FAIL;
    constructor(public error: any) { }
}

/**
 * Refuse (technicien)
 */
export class RefuseTimesheetReportTech implements Action {
    readonly type = TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_TECH;
    constructor(public timesheetReportId: string | number, public comment: string, public site: string, public path: AlternativePath) { }
}

export class RefuseTimesheetReportTechSuccess implements Action {
    readonly type = TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_TECH_SUCCESS;
    constructor(public timesheetReport: TimesheetReport) { }
}

export class RefuseTimesheetReportTechFail implements Action {
    readonly type = TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_TECH_FAIL;
    constructor(public error: any) { }
}

export type TimesheetReportsAction =
    | RemarkTimesheetReport
    | RemarkTimesheetReportSuccess
    | RemarkTimesheetReportFail
    | LoadTimesheetReportById
    | LoadTimesheetReportByIdSuccess
    | LoadTimesheetReportByIdFail
    | ApproveTimesheetReport
    | ApproveTimesheetReportSuccess
    | ApproveTimesheetReportFail
    | RefuseTimesheetReport
    | RefuseTimesheetReportSuccess
    | RefuseTimesheetReportFail
    | LoadTimesheetReportByIdAndSiteName
    | LoadTimesheetReportByIdAndSiteNameSuccess
    | LoadTimesheetReportByIdAndSiteNameFail
    | ApproveTimesheetReportTech
    | ApproveTimesheetReportTechSuccess
    | ApproveTimesheetReportTechFail
    | RefuseTimesheetReportTech
    | RefuseTimesheetReportTechSuccess
    | RefuseTimesheetReportTechFail;
