import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ModalData, ModalEntityType, ModalType } from '../generic-modal/generic-modal-data.model';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';

@Component({
    selector: 'app-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnChanges {
    public noteForm: FormGroup;
    public isEditMode = false;
    private originalRemark = '';

    @Input() public remark: string;
    @Input() public isLock = false;
    @Input() public maxCharactersLength = 255;
    @Output() public update: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        public formBuilder: FormBuilder,
        public dialog: MatDialog,
    ) {
        this.noteForm = formBuilder.group({
            id: [''],
            note: [this.remark],
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.remark && changes.remark.currentValue) {
            this.originalRemark = changes.remark.currentValue;
            this.noteForm.controls.note.setValue(changes.remark.currentValue);
        }
    }

    public isNoteEmpty(): boolean {
        return this.remark === '';
    }

    public onSubmit(): void {
        this.remark = this.noteForm.controls.note.value;
        this.toggleEditMode();
        this.update.emit(this.remark);
    }

    public toggleEditMode(): void {
        if (!this.isLock) {
            this.isEditMode = !this.isEditMode;
        }
    }

    public resetForm(): void {
        this.noteForm.controls.note.reset(this.originalRemark);
        this.toggleEditMode();
    }

    public deleteComment(): void {
        const deleteNoteDialog = this.dialog.open(GenericModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                formType: ModalType.delete,
                title: 'timesheet.order.deleteNoteForm.title',
                message: 'timesheet.order.deleteNoteForm.message',
                entityType: ModalEntityType.note,
                entity: this.remark,
            } as ModalData
        });

        deleteNoteDialog.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.remark = '';
                this.noteForm.controls.note.reset(this.remark);
                this.toggleEditMode();
                this.update.emit(this.remark);
            }
        });
    }
}
