import { ProgressReportGlobal } from './database/progress-report-global.model';

export interface IProgressReportSpecificSavedFilters {
    status?: ProgressReportGlobal;
    billingType?: string[];
    site?: string[];
    order?: string[];
    accountingEntry?: string[];
    executedBy?: string[];
    assignedBy?: string[];
    week?: string[];
    haveFilters: boolean;
}

export class ProgressReportSpecificSavedFiltersRaw {
    status?: ProgressReportGlobal = undefined;
    billingType?: string[] = undefined;
    site?: string[] = undefined;
    order?: string[] = undefined;
    accountingEntry?: string[] = undefined;
    executedBy?: string[] = undefined;
    assignedBy?: string[] = undefined;
    week?: string[] = undefined;
    haveFilters = false;
}

export class ProgressReportSpecificSavedFilters {
    status?: ProgressReportGlobal;
    billingType?: string[];
    site?: string[];
    order?: string[];
    accountingEntry?: string[];
    executedBy?: string[];
    assignedBy?: string[];
    week?: string[];
    haveFilters = false;

    constructor(data?: ProgressReportSpecificSavedFiltersRaw) {
        if (data) {
            Object.keys(data).forEach((key: string) => {
                if (key in new ProgressReportSpecificSavedFiltersRaw) {
                    this[key] = data[key];
                }
            });
        }
    }

    public fetchFilterFromLocalStorage(localStorageName: string): void {
        const localStorageString = localStorage.getItem(localStorageName);
        const savedFilters: ProgressReportSpecificSavedFilters | null = localStorageString ?
            JSON.parse(localStorageString) as ProgressReportSpecificSavedFilters : null;

        if (savedFilters) {
            this.status = savedFilters.status;
            this.billingType = savedFilters.billingType;
            this.site = savedFilters.site;
            this.order = savedFilters.order;
            this.accountingEntry = savedFilters.accountingEntry;
            this.executedBy = savedFilters.executedBy;
            this.assignedBy = savedFilters.assignedBy;
            this.week = savedFilters.week;
            this.haveFilters = savedFilters.haveFilters;

        } else {
            this.haveFilters = false;
        }
    }
}
