<div *ngIf="layout === 'shell'" class="shellLayout">
    <app-header (expandedChange)='setMenuExpanded($event)' [expanded]="isMainMenuExpanded"></app-header>

    <mat-sidenav-container [ngClass]="{'menu--is-expanded': isMainMenuExpanded}" (backdropClick)="backdropClick()">
        <mat-sidenav #menu [mode]="navigationMode" class="sideNav" [opened]="isOpen"
            [fixedInViewport]="true" [fixedTopGap]="56" [@openClose]="isMainMenuExpanded ? openName : closeName">

            <app-main-menu class="main-menu"></app-main-menu>

            <div class="navigation__button-container">
                <button mat-mini-fab class="button-container__button" aria-label="fermer le menu latérale"
                (click)="isMainMenuExpanded = !isMainMenuExpanded" (mouseenter)="hoverCloseButton=true" (mouseleave)="hoverCloseButton=false">
                    <span class="button__intern" [class.intern-normal]="!hoverCloseButton" [class.intern-hover]="hoverCloseButton">
                        <i *ngIf="!isMainMenuExpanded" class="fas fa-arrow-right"></i>
                        <i *ngIf="isMainMenuExpanded" class="fas fa-arrow-left"></i>
                    </span>
                </button>
            </div>
        </mat-sidenav>
        <mat-sidenav-content class="main-content">

            <div class="router-outlet-container">
                <router-outlet></router-outlet>
            </div>

            <section *ngIf="(autoLogoutIn || 9999) <= 120" class="hq-app-message--warning" aria-live="polite">
                <div class="hq-app-message__inner">
                    <div class="hq-app-message"><span
                            class="hq-app-message__icon appicons ic-exclamation-triangle"></span>Votre
                        session expirera dans {{ autoLogoutIn }} secondes.
                    </div>
                </div>
            </section>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<div *ngIf="layout === 'fullscreen'">
    <router-outlet></router-outlet>
</div>
