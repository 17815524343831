<hq-header image="{{imagePath}}" [headerTitle]="'header.title' | translate"
    (expandedChange)='expanded=$event'>
    <hq-header-menu>
        <hq-header-menu-section>
            <hq-header-menu-item-group [icon]="user ? 'appicons ic-user' : 'appicons ic-sign-in-alt'"
                [label]="user ? 'Profil de l\'utilisateur' : 'header.signIn' | translate">
                <hq-header-menu-section *ngIf="user">
                    <hq-header-menu-item-container>
                        <div class='hq-header-menu-profile'>
                            <div class='hq-header-menu-profile__image'>
                                <img *ngIf="user?.profileImage" [src]='user.profileImage' alt='' />
                                <div class='hq-header-menu-profile__initials' *ngIf="!user?.profileImage">
                                    {{user.initials | uppercase}}
                                </div>
                            </div>
                            <div class='hq-header-menu-profile__description'>
                                <div class='hq-header-menu-profile__user-fullname'>{{user.fullname}}</div>
                            </div>
                        </div>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>

                <hq-header-menu-section *ngIf="!user">
                    <hq-header-menu-item-container>
                        <button pButton type="button" [label]="'header.signIn' | translate"
                            icon='appicons ic-sign-in-alt' [hqClickCommand]="login">
                        </button>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>

                <hq-header-menu-section *ngIf="user">
                    <hq-header-menu-item-container>
                        <span class="roleSpan">{{'header.role' | translate}} </span> {{userRoles}}
                    </hq-header-menu-item-container>
                    <hq-header-menu-item-container>
                        <button pButton type="button" [label]="'header.signOut' | translate"
                            icon='appicons ic-sign-out-alt' [hqClickCommand]="logout">
                        </button>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>

            </hq-header-menu-item-group>
        </hq-header-menu-section>
    </hq-header-menu>
</hq-header>
