<app-element-loader *ngIf="utilisateursInternesLoading$ | async"
    [size]="loaderLogoSize"
    [displayLabel]="true"
    [labelFontSize]="20"></app-element-loader>
<p-table #utilisateursInternesDataTable [columns]="columns" [value]="$any(utilisateursInternes$ | async)" [paginator]="true" [rows]="10"
    [sortField]="sortField" [sortOrder]="sortOrder" [autoLayout]="true" [reorderableColumns]="true"
    currentPageReportTemplate="Affiche de {first} à {last} sur {totalRecords} items" [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10,25,50,100,200]" (onFilter)="saveFilteredValues(utilisateursInternesDataTable)">

    <ng-template pTemplate="caption">
        <div class="table__header">
            <div class="search">
                <i class="fas fa-search search__icon"></i>
                <input type="text" class="search__input" pInputText size="30"
                    [placeholder]="'common.search' | translate" [attr.aria-placeholder]="'common.search' | translate"
                    (input)="inputGlobalFilter($event, 'contains')">
            </div>
            <div class="add">
                <button id="formation-button" [disabled]="selectedUsersFormation.length === 0 || selectedUsersFormation.length > 200" pButton icon="fa fa-id-badge" class="ui-button-raised" (click)="showProfilsFormationPlusieursUtilisateurs()"
                    [label]="translatePrefix + '.boutons.afficher-profil-formation' | translate:nbUtilisateurs"
                    [pTooltip]="translatePrefix + '.tooltip.afficher-plusieurs-profils' | translate" tooltipPosition="top"></button>
                        <h6 class="profil-formation-warning" *ngIf="selectedUsersFormation.length === 0">{{ 'pilotages.general.profil-formation.min' | translate }}</h6>
                        <h6 class="profil-formation-warning" *ngIf="selectedUsersFormation.length > 200"> {{ 'pilotages.general.profil-formation.max' | translate }}</h6>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
                <th *ngIf="col.type === 'shortTag'">
                </th>
                <th *ngIf="col.type !== 'shortTag'" [pSortableColumn]="col.field" pReorderableColumn>
                    <div class="p-d-flex">
                        {{ translatePrefix + '.' + col.header | translate }}

                        <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                            [attr.aria-label-desc]="'common.sortDesc' | translate"
                            [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
                    </div>
                </th>
            </ng-container>
            <th class="sticky-column">{{ 'common.actions' | translate }}</th>
        </tr>
        <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <div *ngSwitchCase="'indicator'">
                </div>
                <div *ngSwitchCase="'statut'">
                    <p-dropdown appendTo="body" [options]="statuses" autoWidth="false" [style]="{'width':'fit-content'}"
                        (onChange)="utilisateursInternesDataTable.filter($event.value, 'statut', 'equals')"
                        styleClass="p-column-filter" [placeholder]="'pilotages.status-dropdown.placeholder' | translate"
                        [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span>{{option.label}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div *ngSwitchCase="'modifie_le'" style="width: 175px;">
                    <p-calendar
                        [(ngModel)]="dateFiltersModifieLe"
                        appendTo="body"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="'pilotages.general.date-filter' | translate"
                        (onClearClick)="utilisateursInternesDataTable.filter($event, col.field, 'dateRangeFilterModifieLe')"
                        (onSelect)="utilisateursInternesDataTable.filter($event, col.field, 'dateRangeFilterModifieLe')">
                    </p-calendar>
                </div>
                <div *ngSwitchCase="'cree_le'" style="width: 175px;">
                    <p-calendar
                        [(ngModel)]="dateFiltersCreeLe"
                        appendTo="body"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="'pilotages.general.date-filter' | translate"
                        (onClearClick)="utilisateursInternesDataTable.filter($event, col.field, 'dateRangeFilterCreeLe')"
                        (onSelect)="utilisateursInternesDataTable.filter($event, col.field, 'dateRangeFilterCreeLe')">
                    </p-calendar>
                </div>
                <div *ngSwitchCase="'horaire'">
                    <p-dropdown appendTo="body" [options]="scheduleStatuses"
                        (onChange)="utilisateursInternesDataTable.filter($event, col.field, 'horaire')"
                        styleClass="p-column-filter" autoWidth="false" [style]="{'width':'fit-content'}"
                        [placeholder]="'pilotages.horaire-dropdown.placeholder' | translate" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span>{{option.label}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div *ngSwitchDefault>
                    <input pInputText *ngSwitchDefault type="text" styleClass="p-column-filter"
                        (input)="inputFilterColumn($event, col.field, 'contains')"
                        [placeholder]="'common.filter' | translate"
                        [attr.aria-placeholder]="'common.filter' | translate">
                </div>
            </th>
            <th class="sticky-column"></th>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length+1">
                <h3>
                    {{translatePrefix + '.empty' | translate}}
                </h3>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [pReorderableRow]="rowIndex">
            <td *ngFor="let col of columns">
                <ng-container [ngSwitch]="true">
                    <ng-container *ngSwitchCase="col.type === 'tag'">
                        <app-tag [status]="rowData[col.field]">
                            {{  translatePrefix + '.' + rowData[col.field] | translate}}
                        </app-tag>
                    </ng-container>
                    <ng-container *ngSwitchCase="col.type === 'shortTag'">
                        <div class="indicatorContainer">
                            <app-tag [isIndicator]="true" [status]="rowData['statut']"></app-tag>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="col.field === 'telephone_externe' || col.field === 'numero_cellulaire' || col.field === 'telephone_residence'  || col.field === 'telephone_interne' || col.field === 'fax'">
                        <label>
                            {{ rowData[col.field] | mask: "(000) 000-0000 ,000000" }}
                        </label>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <ng-container *ngIf="col.field === 'horaire'; else cree">
                            <span>
                                {{  (rowData[col.field] === null || rowData[col.field] === '' || rowData[col.field] === 'Aucun' ? 'pilotages.general.horaire.Aucun' : 'pilotages.general.horaire.' + rowData[col.field]) | translate}}
                            </span>
                            <!-- {{  'pilotages.general.horaire.' + rowData[col.field] | translate}} -->
                        </ng-container>
                        <ng-template #cree>
                            <ng-container *ngIf="col.field === 'cree_le'; else modifie">
                                {{ rowData[col.field] | date:'dd-MM-yyyy' }}
                            </ng-container>
                        </ng-template>
                        <ng-template #modifie>
                            <ng-container *ngIf="col.field === 'modifie_le'; else default">
                                {{ rowData[col.field] | date:'dd-MM-yyyy' }}
                            </ng-container>
                        </ng-template>
                        <ng-template #default>
                            {{ rowData[col.field] }}
                        </ng-template>
                    </ng-container>
                </ng-container>
            </td>
            <td class="sticky-column">
                <ng-container *ngFor="let col of columns">
                    <div class="p-d-flex">
                        <ng-container *ngIf="col.field === 'statut'">
                            <ng-container [ngSwitch]="rowData[col.field]">
                                <ng-container *ngSwitchCase="'En erreur'">
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <app-generic-other-menu
                                        [displayMenu]="true"
                                        [addborder]="true"
                                        [myOwnMenu]="true"
                                        [toolTipLabel]="translatePrefix + '.options' | translate"
                                        [myOwnMenu]="true"
                                        [myOwnMenuListItems]="updateUserItems"
                                        (myOwnMenuItemPressed)="menuItemPressed($event, rowData)">
                                    </app-generic-other-menu>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>
