import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProgressReportGlobal } from '../../../shared/models/database/progress-report-global.model';
import { ProgressReportsGlobalAction, ProgressReportsGlobalActionType } from '../actions';

export interface ProgressReportsGlobalState extends EntityState<ProgressReportGlobal> {
    loading: boolean;
    loaded: boolean;
    dateOfData: Date | null;
}

export const progressReportsGlobalAdapter: EntityAdapter<ProgressReportGlobal> = createEntityAdapter<ProgressReportGlobal>({
    selectId: (progressReportGlobal: ProgressReportGlobal) => progressReportGlobal.name,
});

export const progressReportsGlobalState = progressReportsGlobalAdapter.getInitialState({
    loading: false,
    loaded: false,
    dateOfData: null
});

export function progressReportsGlobalReducer(
    state: ProgressReportsGlobalState = progressReportsGlobalState,
    action: ProgressReportsGlobalAction,
): ProgressReportsGlobalState {
    switch (action.type) {
        case ProgressReportsGlobalActionType.LOAD_PROGRESS_REPORTS_GLOBAL: {
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        }

        case ProgressReportsGlobalActionType.LOAD_PROGRESS_REPORTS_GLOBAL_SUCCESS: {
            return progressReportsGlobalAdapter.setAll(action.globalReportWrapper.report_data, {
                ...state,
                loading: false,
                loaded: true,
                dateOfData: action.globalReportWrapper.date_of_data
            });
        }

        case ProgressReportsGlobalActionType.LOAD_PROGRESS_REPORTS_GLOBAL_NO_RESULTS: {
            return progressReportsGlobalAdapter.setAll([], {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case ProgressReportsGlobalActionType.LOAD_PROGRESS_REPORTS_GLOBAL_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
