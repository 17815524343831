<app-generic-accordion [title]="title" [icon]="'fas fa-clipboard-list'" [bubbleIcon]="'fas fa-map-pin'"
    [totalTime]="hoursPostLines" [totalQuantity]="numberPostLines" [subtitle]="subTitle"
    [requiredSubtitle]="requiredSubtitle" [canAddEditTime]="canAddEditTime" [isLock]="isLock">
    <app-generic-other-menu actions *hasRole="editAllowedRoles" [addOnly]="true" [isLock]="isLock" [addborder]="true" (add)="addPostLine($event, postLineGroup)">
    </app-generic-other-menu>
    <ng-container itemsList *ngFor="let postLine of displayPostLines">
        <app-work-done-pillar-line-accordion [pathChoice]="pathChoice" [editAllowedRoles]="editAllowedRoles" [postLine]="postLine" [isLock]="isLock" [canAddEditTime]="canAddEditTime"
            [timesheetDateId]="timesheetDateId" [orderType]="orderType">
        </app-work-done-pillar-line-accordion>
    </ng-container>
</app-generic-accordion>
