<div class="workDoneForm">
    <form [formGroup]="workDoneForm" (ngSubmit)="onSubmit()">
        <div class="group__field">
            <mat-form-field *ngIf="this.workDoneForm.controls.fromInventory.value" appearance="standard" class="field__formField" [hideRequiredMarker]="true">
                <mat-label>{{ prefix + '.description' | translate }}<span *ngIf="!this.workDoneForm.controls.fromInventory.value" class="required">*</span></mat-label>
                <input matInput formControlName="description" />
            </mat-form-field>
            <mat-form-field *ngIf="!this.workDoneForm.controls.fromInventory.value" appearance="standard" class="field__formField" [hideRequiredMarker]="true">
                <mat-label>{{ prefix + '.description' | translate }}<span *ngIf="!this.workDoneForm.controls.fromInventory.value" class="required">*</span></mat-label>
                <mat-select formControlName="code">
                    <input matInput placeholder="Recherche" formControlName="search" [ngStyle]="{'height.px': '48', 'padding.px': '0 16', 'margin': '2px 1px 2px 0px', 'border': '3px #1b75bb double', 'width': 'calc(100% - 1px)'}" />
                    <mat-option [ngStyle]="{'font-size.px': '13'}" *ngFor="let unitCode of unitCodes$ | async" [value]="unitCode.value">
                        {{ unitCode.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField" [hideRequiredMarker]="true">
                <mat-label>{{ prefix + '.quantity' | translate }}<span *ngIf="!this.workDoneForm.controls.fromInventory.value" class="required">*</span></mat-label>
                <input matInput type="number" [attr.min]="0" formControlName="quantity" autocomplete="off">
            </mat-form-field>
        </div>

        <div *ngIf="canAddEditTime" class="group__field">
            <div class="field__row">
                <i class="far fa-clock row__logo"></i>
                <mat-form-field appearance="standard" class="field__formField row__input" [hideRequiredMarker]="true">
                    <mat-label>{{ prefix + '.hours' | translate }}<span class="required">*</span></mat-label>
                    <input matInput type="number" [attr.min]="0" [attr.max]="24" formControlName="hour" autocomplete="off">
                </mat-form-field>
            </div>
        </div>

        <div class="group__field">
            <div class="field__row">
            <mat-form-field appearance="standard" class="field__formField" [hideRequiredMarker]="true">
                <mat-label>
                    <span class="detail">{{ prefix + '.detailRequis' | translate }} </span>
                    <span class="explication">{{ prefix + '.detailRequis-autre' | translate }}</span>
                    <span *ngIf="isDetailRequired" class="required">*</span>
                </mat-label>
                <textarea matInput #detail formControlName="detail"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="6"
                    maxlength="255"
                    ></textarea>
                <mat-hint [align]="'end'"> {{detail.value.length}} / 255</mat-hint>
            </mat-form-field>
            </div>
        </div>

        <app-generic-form-buttons [disabled]="workDoneForm.invalid" (cancel)="cancelForm()"></app-generic-form-buttons>
    </form>
</div>
