import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';

export const billingVendorsModules = [
    ToastModule,
    TableModule,
    TreeTableModule,
    ButtonModule,
    TooltipModule,
    FieldsetModule,
    InputTextModule,
    DropdownModule,
    ConfirmDialogModule,
    DialogModule,

    // Material
    MatButtonModule,
    MatInputModule,
    MatDividerModule
];
