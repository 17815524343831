<div class="container__button">
    <div class="button__left" *ngIf="displayCancelButton">
        <button #cancelButton mat-flat-button type="button"
            class="left__cancel"
            matTooltip="{{cancelTooltipLabel}}"
            [matTooltipPosition]="toolTipPosition"
            [matTooltipDisabled]="cancelTooltipLabel === null"
            (click)="canceled()" [tabIndex]="tabIndex">
            <i *ngIf="cancelLogo === 'cancel'" class="fas fa-times"></i>
            <i *ngIf="cancelLogo === 'back'" class="fas fa-arrow-left"></i>
        </button>
    </div>

    <div class="button--spacer"></div>

    <div class="button__right">
        <button #saveButton type="submit" mat-flat-button
            class="right__save"
            [disabled]="disableButton"
            [tabIndex]="tabIndex"
            matTooltip="{{saveTooltipLabel}}"
            [matTooltipPosition]="toolTipPosition"
            [matTooltipDisabled]="saveTooltipLabel === null"
            (click)="saved()">
            <i class="fas fa-check"></i>
        </button>
    </div>
</div>
