import { Component, Input, ChangeDetectionStrategy, } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-generic-slide-panel',
    templateUrl: './generic-slide-panel.component.html',
    styleUrls: ['./generic-slide-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('slide', [
            state('left', style({ transform: 'translateX(0)' })),
            state('right', style({ transform: 'translateX(-50%)' })),
            transition('* => *', animate(250))
        ])
    ],
})

export class GenericSlidePanelComponent {
    @Input() public activePanel: PanelType = 'left';
}

type PanelType = 'left' | 'right';
