import { Action } from '@ngrx/store';
import { BillingOrder } from '../../../shared/models/database/billing-order.model';

/**
 * Types
 */
export enum BillingOrderActionTypes {
    LOAD_BILLING_ORDERS = '[Billing Order] Load Billing Orders',
    LOAD_BILLING_ORDERS_SUCCESS = '[Billing Order] Load Billing Orders Success',
    LOAD_BILLING_ORDERS_FAIL = '[Billing Order] Load Billing Orders Fail',
}

/**
 * ACTIONS
 */

/**
 * Loading
 */
export class LoadBillingOrders implements Action {
    readonly type = BillingOrderActionTypes.LOAD_BILLING_ORDERS;
    constructor(public path: string) { }
}

export class LoadBillingOrdersSuccess implements Action {
    readonly type = BillingOrderActionTypes.LOAD_BILLING_ORDERS_SUCCESS;
    constructor(public billingOrders: BillingOrder[]) { }
}

export class LoadBillingOrdersFail implements Action {
    readonly type = BillingOrderActionTypes.LOAD_BILLING_ORDERS_FAIL;
    constructor(public error: any) { }
}

export type BillingOrdersAction =
    | LoadBillingOrders
    | LoadBillingOrdersSuccess
    | LoadBillingOrdersFail;
