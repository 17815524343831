<div class="fullheight">
    <form [formGroup]="utilisateurExterneForm" (ngSubmit)="onSubmit()">
        <div class="group__field">
            <div class="field__row">
                <mat-form-field [hideRequiredMarker]="true" [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.prenom' | translate }}<span *ngIf="isAddMode" class="required">*</span></mat-label>
                    <input matInput type="text" formControlName="prenom" />
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.nom' | translate }}<span *ngIf="isAddMode" class="required">*</span></mat-label>
                    <input matInput type="text" formControlName="nom" />
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.entreprise' | translate }}<span *ngIf="isAddMode" class="required">*</span></mat-label>
                    <mat-select formControlName="ddEntreprise">
                        <mat-option *ngFor="let entreprise of entreprises$ | async" [value]="entreprise.value">{{entreprise.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="field__row">
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.pole' | translate }}</mat-label>
                    <input matInput type="text" formControlName="pole" />
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.site' | translate }}</mat-label>
                    <input matInput type="text" formControlName="site" />
                </mat-form-field>
            </div>
            <div class="field__row">
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.numero_vehicule' | translate }}
                    </mat-label>
                    <input matInput type="text" formControlName="numero_vehicule" />
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.numero_tablette' | translate }}
                    </mat-label>
                    <input matInput type="text" formControlName="numero_tablette" />
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.numero_cellulaire' | translate }}
                    </mat-label>
                    <input [title]="translatePrefix + '.tooltip.telephonePattern' | translate"
                        formControlName="numero_cellulaire"
                        matInput type="text"
                        [patterns]="pattern"
                        [specialCharacters]="['#',':', '(', ')', '-', ' ']"
                        mask="(000) 000-0000||(000) 000-0000    #: 0 ||(000) 000-0000    #: 00 ||(000) 000-0000    #: 000 ||(000) 000-0000    #: 0000 ||(000) 000-0000    #: 00000 ||(000) 000-0000    #: 000000" />
                    <mat-error *ngIf="utilisateurExterneForm.controls.numero_cellulaire.invalid">
                        {{ this.translatePrefix + '.errors' + '.telephonePattern' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="field__row">
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.horaire' | translate }}
                    </mat-label>
                    <mat-select formControlName="horaire" name="horaire">
                        <mat-option *ngFor="let horaire of horaires" [value]="horaire.value">
                            {{horaire.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.habilitation' | translate }}
                    </mat-label>
                    <mat-select formControlName="habilitation" name="habilitation">
                        <mat-option *ngFor="let habilitation of habilitations" [value]="habilitation.value">
                            {{habilitation.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.carte_acces' | translate }}
                    </mat-label>
                    <input matInput type="text" formControlName="carte_acces" />
                </mat-form-field>
            </div>

            <div class="field__row">
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.telephone' | translate }}</mat-label>
                    <input [title]="translatePrefix + '.tooltip.telephonePattern' | translate"
                        formControlName="telephone"
                        matInput type="text"
                        [patterns]="pattern"
                        [specialCharacters]="['#',':', '(', ')', '-', ' ']"
                        mask="(000) 000-0000||(000) 000-0000    #: 0 ||(000) 000-0000    #: 00 ||(000) 000-0000    #: 000 ||(000) 000-0000    #: 0000 ||(000) 000-0000    #: 00000 ||(000) 000-0000    #: 000000" />
                    <mat-error *ngIf="utilisateurExterneForm.controls.telephone.invalid">
                        {{ this.translatePrefix + '.errors' + '.telephonePattern' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.telephone_residence' | translate }}</mat-label>
                    <input [title]="translatePrefix + '.tooltip.telephonePattern' | translate"
                        formControlName="telephone_residence"
                        matInput type="text"
                        [patterns]="pattern"
                        [specialCharacters]="['#',':', '(', ')', '-', ' ']"
                        mask="(000) 000-0000||(000) 000-0000    #: 0 ||(000) 000-0000    #: 00 ||(000) 000-0000    #: 000 ||(000) 000-0000    #: 0000 ||(000) 000-0000    #: 00000 ||(000) 000-0000    #: 000000" />
                    <mat-error *ngIf="utilisateurExterneForm.controls.telephone_residence.invalid">
                        {{ this.translatePrefix + '.errors' + '.telephonePattern' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>


            <div formGroupName="adresse_personnelle">
                <div class="field__row">
                    <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                        <mat-label>{{ this.translatePrefix + '.numero_civique' | translate }}
                        </mat-label>
                        <input matInput type="text" formControlName="numero_civique" />
                    </mat-form-field>
                    <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                        <mat-label>{{ this.translatePrefix + '.rue' | translate }}
                        </mat-label>
                        <input matInput type="text" formControlName="rue" />
                    </mat-form-field>
                    <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                        <mat-label>{{ this.translatePrefix + '.ville' | translate }}
                        </mat-label>
                        <input matInput type="text" formControlName="ville" />
                    </mat-form-field>
                    <mat-form-field [hideRequiredMarker]="true" appearance="standard" class="field__formField">
                        <mat-label>{{ this.translatePrefix + '.code_postal' | translate }}
                        </mat-label>
                        <input matInput type="text" formControlName="code_postal" />
                        <mat-error *ngIf="utilisateurExterneForm.get('adresse_personnelle.code_postal') &&
                        utilisateurExterneForm.get('adresse_personnelle.code_postal')?.invalid">
                            {{ this.translatePrefix + '.errors' + '.postalCodePattern' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>
        </div>

        <app-generic-form-buttons
            [disabled]="utilisateurExterneForm.invalid"
            [saveTooltipLabel]="'Confirmer'"
            [cancelTooltipLabel]="'Cancel'"
            (cancel)="cancelForm()">
        </app-generic-form-buttons>
        <!-- <button [disabled]="utilisateurExterneForm.invalid" style="float: right;" type="text" pButton
            label="Confirmer"></button> -->
    </form>

</div>
