import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiService } from '../../../ogiv-core/services/api.service';
import {
    UnitCodeActionType,
    LoadUnitCodes,
    LoadUnitCodesSuccess,
    LoadUnitCodesFail,
    LoadUnitCodesNoResult
} from '../actions/unit-codes.action';
import { UnitCode } from '../../../shared/models/database/unit-code.model';
import { OrderType } from '../../../shared/models/database/order-type.enum';

@Injectable()
export class UnitCodesEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService,
    ) { }

    public loadUnitCode$ = createEffect(() => this.action$.pipe(
        ofType<LoadUnitCodes>(UnitCodeActionType.LOAD_UNIT_CODES),
        switchMap((action: LoadUnitCodes) => {
            let url = '';
            if (action.orderType === undefined) {
                url = 'unitCodes';
            } else {
                switch ((action.orderType as string).toLowerCase()) {
                    case OrderType.unit: url = 'unitCodesUnitly'; break;
                    case OrderType.hourly: url = 'unitCodesHourly'; break;
                    case OrderType.outage: url = 'unitCodesOutage'; break;
                    default: url = 'unitCodes'; break;
                }
            }

            return this.apiService.get<UnitCode[]>(`sap/data/${url}`).pipe(
                map((returnedUnitCode: UnitCode[]) =>
                    (returnedUnitCode.length > 0)
                        ? new LoadUnitCodesSuccess(returnedUnitCode)
                        : new LoadUnitCodesNoResult()
                ),
                catchError((error: any) => of(new LoadUnitCodesFail(error))),
            );
        }),
    ));
}
