import { HasId } from '../models/has-id.model';
import { TableColumns } from '../models/table-columns.model';

export const trackByColumnField = (_: number, item: TableColumns): string => {
    return item.field;
};

export const trackById = (_: number, item: HasId) => (idKey: string): string | number => {
    return item[idKey];
};
