<div class="date-of-data-wrapper">
    <span class="date-of-data">{{ 'progressReport.general.lastUpdateDateLabel' | translate }}{{ dateOfData }}</span>
</div>

<h3> {{ 'progressReport.general.links.globalReport' | translate }}</h3>

<div class="global-progress-report">
    <form [formGroup]="globalReportForm">
        <div class="dropdown-button-wrapper">
            <app-element-loader *ngIf="areBillingOrdersLoading$ | async" [size]="100" [displayLabel]="true"></app-element-loader>

            <p-dropdown id="order" [options]="$any(billingOrdersOptions$ | async)"
                formControlName="order" [filter]="true"
                [emptyFilterMessage]="'common.emptyFilterMessage' | translate"
            ></p-dropdown>

            <p-dropdown id="accountingEntry" [options]="$any(accountingEntryOptions$ | async)"
                formControlName="accountingEntry" [filter]="true"
                placeholder="{{ 'progressReport.general.dropdownSelectAnAccountingEntry' | translate }}"
                [emptyFilterMessage]="'common.emptyFilterMessage' | translate"
            ></p-dropdown>

            <button mat-raised-button type="button" class="action__button erase" (click)="erase()">
                {{ 'progressReport.general.buttons.erase' | translate | uppercase }}
            </button>
        </div>
    </form>

    <p-table *ngLet="progressReportsGlobal$ | async" [value]="progressReportsGlobal" sortMode="multiple">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="name">{{ 'common.total' | translate }} <p-sortIcon field="name"></p-sortIcon></th>
                <th pSortableColumn="prescriptionCount">{{totalPrescriptionCount}}<p-sortIcon field="prescriptionCount"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-globalReports>
            <tr>
                <td>{{globalReports.name}}</td>
                <td>{{globalReports.prescriptionCount}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
