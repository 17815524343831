import { Action } from '@ngrx/store';
import { CurrentUser } from '../../../core/services/user.service';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { Unit } from '../../../shared/models/database/unit.model';

/**
 * Types
 */

export enum UnitActionTypes {
    LOAD_UNITS = '[Unit] Load Units',
    LOAD_UNITS_SUCCESS = '[Unit] Load Units Success',
    LOAD_UNITS_NO_RESULTS = '[Unit] Load Units No Results',
    LOAD_UNITS_FAIL = '[Unit] Load Units Fail',

    CREATE_UNIT = '[Unit] Create Unit',
    CREATE_UNIT_SUCCESS = '[Unit] Create Unit Success',
    CREATE_UNIT_FAIL = '[Unit] Create Unit Fail',

    UPDATE_ONE_UNIT = '[Unit] Update One Unit',
    UPDATE_ONE_UNIT_FAIL = '[Unit] Update One Unit Fail',
    UPDATE_ONE_UNIT_SUCCESS = '[Unit] Update One Unit Success',

    DELETE_ONE_UNIT = '[Unit] Delete One Unit',
    DELETE_ONE_UNIT_FAIL = '[Unit] Delete One Unit Fail',
    DELETE_ONE_UNIT_SUCCESS = '[Unit] Delete One Unit Success',
}

/**
 * ACTIONS
 */

/**
 * CREATE
 */
export class CreateUnit implements Action {
    readonly type = UnitActionTypes.CREATE_UNIT;
    constructor(public unit: Unit, public timesheetDateId: string, public path: AlternativePath, public imperson?: CurrentUser) { }
}

export class CreateUnitSuccess implements Action {
    readonly type = UnitActionTypes.CREATE_UNIT_SUCCESS;
}

export class CreateUnitFail implements Action {
    readonly type = UnitActionTypes.CREATE_UNIT_FAIL;
    constructor(public error: any) { }
}

/**
 * LOAD
 */
export class LoadUnits implements Action {
    readonly type = UnitActionTypes.LOAD_UNITS;
    constructor(public path: AlternativePath) { }
}

export class LoadUnitsNoresult implements Action {
    readonly type = UnitActionTypes.LOAD_UNITS_NO_RESULTS;
}

export class LoadUnitsSuccess implements Action {
    readonly type = UnitActionTypes.LOAD_UNITS_SUCCESS;
    constructor(public units: Unit[]) { }
}

export class LoadUnitsFail implements Action {
    readonly type = UnitActionTypes.LOAD_UNITS_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneUnit implements Action {
    readonly type = UnitActionTypes.UPDATE_ONE_UNIT;
    constructor(public unit: Unit, public timesheetDateId: string, public path: AlternativePath, public imperson?: CurrentUser) { }
}

export class UpdateOneUnitSuccess implements Action {
    readonly type = UnitActionTypes.UPDATE_ONE_UNIT_SUCCESS;
    // constructor(public unit: Unit) { }
}

export class UpdateOneUnitFail implements Action {
    readonly type = UnitActionTypes.UPDATE_ONE_UNIT_FAIL;
    constructor(public error: any) { }
}

/**
 * DELETE
 */
export class DeleteOneUnit implements Action {
    readonly type = UnitActionTypes.DELETE_ONE_UNIT;
    constructor(public unit: Unit, public timesheetDateId: string, public path: AlternativePath, public imperson?: CurrentUser) { }
}

export class DeleteOneUnitSuccess implements Action {
    readonly type = UnitActionTypes.DELETE_ONE_UNIT_SUCCESS;
    // constructor(public unit: Unit) { }
}

export class DeleteOneUnitFail implements Action {
    readonly type = UnitActionTypes.DELETE_ONE_UNIT_FAIL;
    constructor(public error: any) { }
}

export type UnitsAction =
    | CreateUnit
    | CreateUnitSuccess
    | CreateUnitFail
    | LoadUnits
    | LoadUnitsNoresult
    | LoadUnitsSuccess
    | LoadUnitsFail
    | UpdateOneUnit
    | UpdateOneUnitSuccess
    | UpdateOneUnitFail
    | DeleteOneUnit
    | DeleteOneUnitSuccess
    | DeleteOneUnitFail;
