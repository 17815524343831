import { Error } from './error.model';
import { OrderType } from './order-type.enum';

export class EquipmentRawData {
    public id: number;
    public orderId: number;
    public number: string;         // max-length: 255
    public description: string;    // max-length: 255
    public code: string;           // max-length: 255
    public hour: number;
}
export class Equipment {
    public id: number;
    public orderId: number;
    public number: string;         // max-length: 255
    public description: string;    // max-length: 255
    public code: string;           // max-length: 255
    public hour: number;

    constructor(data: EquipmentRawData) {
        Object.keys(data).forEach((key: string) => {
            this[key] = data[key];
        });
    }

    public validate(orderType: OrderType): Error | null {
        let errors: Error | null = null;
        const equipmentsError: string[] = [];

        if ((orderType === OrderType.hourly || orderType === OrderType.outage) && this.hour === 0) {
            equipmentsError.push('doit contenir des heures');
        }

        if (this.code === '' && orderType !== OrderType.unit) {
            equipmentsError.push('doit contenir un code');
        }

        if (this.description === '' && orderType !== OrderType.unit) {
            equipmentsError.push('doit contenir une description');
        }

        if (equipmentsError.length > 0) {
            errors = { name: `${this.number} - ${this.description}`, list: equipmentsError, last: true };
        }

        return errors;
    }
}
