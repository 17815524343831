<div class="fullheight">
    <form [formGroup]="tableCodeUniteForm" (ngSubmit)="onSubmit()">
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ formTranslatePrefix + '.fiche_service' | translate }}<span class="required"> *</span></mat-label>
                <input matInput #ficheService type="text" formControlName="fiche_service" />
                <mat-error *ngIf="!tableCodeUniteForm['ficheService'].valid">{{getError('fiche_service')}}</mat-error>
            </mat-form-field>
        </div>
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ formTranslatePrefix + '.description' | translate }}<span class="required"> *</span></mat-label>
                <input matInput #description_input type="text" formControlName="description" />
                <mat-error *ngIf="!tableCodeUniteForm['description'].valid">{{getError('description')}}</mat-error>
            </mat-form-field>
        </div>
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ formTranslatePrefix + '.residu' | translate }}</mat-label>
                <input matInput #residu maxlength="1" type="text" formControlName="residu"/>
                <mat-hint [align]="'end'">{{residu.value.length}} / 1</mat-hint>
            </mat-form-field>
        </div>
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ formTranslatePrefix + '.code_sap' | translate }}<span class="required"> *</span></mat-label>
                <input matInput #code_sap_input type="text" formControlName="code_sap" />
                <mat-error *ngIf="!tableCodeUniteForm['code_sap'].valid">{{getError('code_sap')}}</mat-error>
             </mat-form-field>
        </div>
        <div class="specialSection">
            <div class="specialSection__container">
                <h4 class="container__title" *ngIf="code_sap_input.value === null || code_sap_input.value === ''">{{ formTranslatePrefix + '.AssociationTextEmpty' | translate }}</h4>
                <h4 class="container__title" *ngIf="code_sap_input.value && code_sap_input.value.length > 0">{{ formTranslatePrefix + '.associationText' | translate }}</h4>
                <div class="container__association" *ngIf="code_sap_input.value && code_sap_input.value.length > 0">
                    <div class="group__field">
                        <mat-form-field appearance="standard" class="field__formField">
                            <mat-label>{{ formTranslatePrefix + '.code_presc' | translate }}</mat-label>
                            <input matInput type="text" formControlName="code_presc" />
                        </mat-form-field>
                    </div>
                    <i class="fas fa-long-arrow-alt-right association__between"></i>
                    <div class="group__field label">
                        <mat-label class="field__onlyLabel">{{ code_sap_input.value ? code_sap_input.value : '' }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="specialSection__container">
                <h4 class="container__title">{{ formTranslatePrefix + '.sectionExplication' | translate }}</h4>
                <div class="container__checkboxes">
                    <div class="checkboxes__section p">
                        <label for="demandeClient">{{ formTranslatePrefix + '.demande_client' | translate }}</label>
                        <mat-checkbox formControlName="demande_client" ></mat-checkbox>
                    </div>
                    <div class="checkboxes__section f">
                        <label for="ajout_unitaire">{{ formTranslatePrefix + '.ajout_unitaire' | translate }}</label>
                        <mat-checkbox formControlName="ajout_unitaire" ></mat-checkbox>
                    </div>
                    <div class="checkboxes__section p">
                        <label for="ajout_horaire">{{ formTranslatePrefix + '.ajout_horaire' | translate }}</label>
                        <mat-checkbox formControlName="ajout_horaire" ></mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="group__field">
            <div class="container__checkboxes">
                <div class="checkboxes__section main">
                    <mat-label for="detail_requis">{{ formTranslatePrefix + '.detail_requis' | translate }}</mat-label>
                    <mat-checkbox formControlName="detail_requis" ></mat-checkbox>
                </div>
            </div>
        </div>
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ formTranslatePrefix + '.statut' | translate }}<span class="required"> *</span></mat-label>
                <mat-select #statut formControlName="statut">
                    <mat-option [ngStyle]="{'font-size.px': '14'}" *ngFor="let status of statuses" [value]="status.value">{{status.label | translate}}</mat-option>
                </mat-select>
                <mat-error *ngIf="tableCodeUniteForm.controls['statut'].hasError('required')">{{getError('statut')}}</mat-error>
            </mat-form-field>
        </div>
        <app-generic-form-buttons
            [disabled]="tableCodeUniteForm.invalid"
            [cancelTooltipLabel]="'common.cancel' | translate"
            [saveTooltipLabel]="'common.confirm' | translate"
            (cancel)="cancelForm()">
        </app-generic-form-buttons>
    </form>

</div>
