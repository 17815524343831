<p class="subtitle">{{ translatePrefix + 'description' | translate }}</p>

<div style="max-width: 320px;">

    <p-table #demandeClientEcartsDatatable dataKey="numeroOrdre" [value]="$any(demandesClientEcarts$ | async)"
        [columns]="columns" [sortField]="sortField" [sortOrder]="sortOrder" [autoLayout]="false" [scrollable]="true"
        scrollHeight="600px">
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length">
                    <h3>{{ translatePrefix + 'noData' | translate }}</h3>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
                <col *ngFor="let col of columns" [ngStyle]="{'width': col.width } " [pSortableColumn]="col.field">
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr class="headerRow">
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width }">
                    {{col.header}}
                    <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                        [attr.aria-label-desc]="'common.sortDesc' | translate"
                        [attr.aria-label-asc]="'common.sortAsc' | translate">
                    </p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-demandeClient let-columns="columns">
            <tr class="bodyRow">

                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width }">
                    <ng-container>
                        {{ demandeClient[col.field] }}
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-element-loader id="spinner" *ngIf="demandesClientEcartsLoading$ | async"></app-element-loader>
