import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { ApiService } from '../../../ogiv-core/services/api.service';
import {
    BillingCompanyActionType,
    LoadBillingCompany,
    LoadBillingCompanySuccess,
    LoadBillingCompanyFail
} from '../actions/billing-company.action';
import { BillingCompany } from '../../../shared/models/database/billing-company.model';

@Injectable()
export class BillingCompanyEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public loadBillingCompany$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingCompany>(BillingCompanyActionType.LOAD_BILLING_COMPANY),
        switchMap((action: LoadBillingCompany) => {
            return this.apiService.get<BillingCompany>(`${action.path}/entrepreneur`).pipe(
                map((returnedBillingCompany: BillingCompany) => {
                    const billingCompanyArray: BillingCompany[] = [];
                    billingCompanyArray.push(returnedBillingCompany);
                    return new LoadBillingCompanySuccess(billingCompanyArray);
                }),
                catchError((error: any) => of(new LoadBillingCompanyFail(error))),
            );
        }),
    ));
}
