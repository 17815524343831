import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import moment from 'moment';

import { ReportsState, getReportsState } from '../reducers';
import { timesheetReportsAdapter, TimesheetReportsState } from '../reducers/timesheet-reports.reducer';
import { TimesheetReport } from '../../../shared/models/database/timesheet-report.model';
import { getRouterParams } from '../../../core/store/selectors';
import { Params } from '@angular/router';

const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = timesheetReportsAdapter.getSelectors();

/**
 * General TimesheetReport State selector
 */

export const getTimesheetReportsState = createSelector(
    getReportsState,
    (state: ReportsState) => state.timesheetReports,
);

export const getTimesheetReportsLoading = createSelector(
    getTimesheetReportsState,
    (state: TimesheetReportsState) => state.loading,
);

export const getTimesheetReportsLoaded = createSelector(
    getTimesheetReportsState,
    (state: TimesheetReportsState) => state.loaded,
);

export const shouldLoadTimesheetReports = createSelector(
    getTimesheetReportsLoading,
    getTimesheetReportsLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);
export const getAllTimesheetReports = createSelector(
    getTimesheetReportsState,
    selectAll,
);

export const getTimesheetReportsEntities = createSelector(
    getTimesheetReportsState,
    selectEntities,
);

export const getTimesheetReportsIds = createSelector(
    getTimesheetReportsState,
    selectIds,
);

export const getCurrentTimesheetReport = createSelector(
    getRouterParams,
    getTimesheetReportsEntities,
    (params: Params, entities: Dictionary<TimesheetReport>) => {
        return entities && params.timesheetReportId && entities[params.timesheetReportId] || null;
    }
);

export const getTimesheetReportById = (props: any) => createSelector(
    getTimesheetReportsEntities,
    (entities: Dictionary<TimesheetReport>) => {
        return entities && entities[props.entityId] || null;
    }
);

export const shouldLoadGivenTimesheetReport = ({ timesheetReportId }: { timesheetReportId: string }) => createSelector(
    getTimesheetReportsLoading,
    getTimesheetReportsIds,
    (loading: boolean, ids: any[]) => {
        return !loading && !ids.includes(timesheetReportId);
    }
);

export const getTimesheetReportsCount = createSelector(
    getTimesheetReportsState,
    selectTotal,
);

export const getTimesheetReportsByWeek = createSelector(
    getAllTimesheetReports,
    (reports: TimesheetReport[], { selectedDate }: { selectedDate?: string | Date } = {}) => {
        if (!selectedDate) {
            return reports;
        }

        return reports.filter((report: TimesheetReport) => {
            const isSame = moment(report.week).isSame(moment(selectedDate), 'week');
            return isSame;
        });
    }
);
