import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { TableCodeUnite } from '../../models/table-code-unite.model';

/**
 * Types
 */
export enum TableCodesUniteActionType {
    LOAD_TABLE_CODES_UNITE = '[TableCodesUnite] Load TableCodesUnite',
    LOAD_TABLE_CODES_UNITE_SUCCESS = '[TableCodesUnite] Load TableCodesUnite Success',
    LOAD_TABLE_CODES_UNITE_FAIL = '[TableCodesUnite] Load TableCodesUnite Fail',
    LOAD_TABLE_CODES_UNITE_NO_RESULTS = '[TableCodesUnite] Load TableCodesUnite No Result',

    CREATE_ONE_TABLE_CODES_UNITE = '[TableCodesUnite] Create One TableCodesUnite',
    CREATE_ONE_TABLE_CODES_UNITE_SUCCESS = '[TableCodesUnite] Create One TableCodesUnite Success',
    CREATE_ONE_TABLE_CODES_UNITE_FAIL = '[TableCodesUnite] Create One TableCodesUnite Fail',

    UPDATE_ONE_TABLE_CODES_UNITE = '[TableCodesUnite] Update One TableCodesUnite',
    UPDATE_ONE_TABLE_CODES_UNITE_SUCCESS = '[TableCodesUnite] Update One TableCodesUnite Success',
    UPDATE_ALL_TABLE_CODES_UNITE_SUCCESS = '[TableCodesUnite] Update ALL TableCodesUnite Success',
    UPDATE_ONE_TABLE_CODES_UNITE_FAIL = '[TableCodesUnite] Update One TableCodesUnite Fail',
}

/**
 *  ACTIONS
 */

/**
 * LOAD
 */

export class LoadTableCodesUnite implements Action {
    readonly type = TableCodesUniteActionType.LOAD_TABLE_CODES_UNITE;
    constructor(public path: AlternativePath) { }
}

export class LoadTableCodesUniteNoResult implements Action {
    readonly type = TableCodesUniteActionType.LOAD_TABLE_CODES_UNITE_NO_RESULTS;
}

export class LoadTableCodesUniteSuccess implements Action {
    readonly type = TableCodesUniteActionType.LOAD_TABLE_CODES_UNITE_SUCCESS;
    constructor(public tableCodeUnites: TableCodeUnite[]) { }
}

export class LoadTableCodesUniteFail implements Action {
    readonly type = TableCodesUniteActionType.LOAD_TABLE_CODES_UNITE_FAIL;
    constructor(public error: any) { }
}

/**
 * Create
 */
export class CreateOneTableCodeUnite implements Action {
    readonly type = TableCodesUniteActionType.CREATE_ONE_TABLE_CODES_UNITE;
    constructor(public tableCodeUnite: TableCodeUnite, public path: AlternativePath) { }
}

export class CreateOneTableCodeUniteSuccess implements Action {
    readonly type = TableCodesUniteActionType.CREATE_ONE_TABLE_CODES_UNITE_SUCCESS;
    constructor(public tableCodeUnites: TableCodeUnite[]) { }
}

export class CreateOneTableCodeUniteFail implements Action {
    readonly type = TableCodesUniteActionType.CREATE_ONE_TABLE_CODES_UNITE_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneTableCodeUnite implements Action {
    readonly type = TableCodesUniteActionType.UPDATE_ONE_TABLE_CODES_UNITE;
    constructor(public tableCodeUnite: TableCodeUnite, public path: AlternativePath) { }
}

export class UpdateOneTableCodeUniteSuccess implements Action {
    readonly type = TableCodesUniteActionType.UPDATE_ONE_TABLE_CODES_UNITE_SUCCESS;
    constructor(public tableCodeUnite: TableCodeUnite) { }
}

export class UpdateAllTableCodeUniteSuccess implements Action {
    readonly type = TableCodesUniteActionType.UPDATE_ALL_TABLE_CODES_UNITE_SUCCESS;
    constructor(public tableCodeUnites: TableCodeUnite[]) { }
}

export class UpdateOneTableCodeUniteFail implements Action {
    readonly type = TableCodesUniteActionType.UPDATE_ONE_TABLE_CODES_UNITE_FAIL;
    constructor(public error: any) { }
}

export type TableCodesUniteAction =
    | LoadTableCodesUnite
    | LoadTableCodesUniteNoResult
    | LoadTableCodesUniteSuccess
    | LoadTableCodesUniteFail
    | CreateOneTableCodeUnite
    | CreateOneTableCodeUniteSuccess
    | CreateOneTableCodeUniteFail
    | UpdateOneTableCodeUnite
    | UpdateOneTableCodeUniteSuccess
    | UpdateAllTableCodeUniteSuccess
    | UpdateOneTableCodeUniteFail;
