import { createSelector } from '@ngrx/store';

import { DemandesClientGlobalState, getDemandesClientGlobalState } from '../reducers';
import { demandesClientUtilsAdapter } from '../reducers/demandes-client-utils.reducers';


const {
    selectAll,
} = demandesClientUtilsAdapter.getSelectors();

/**
 * General State selector
 */

export const getDemandesClientUtilsState = createSelector(
    getDemandesClientGlobalState,
    (state: DemandesClientGlobalState) => state.demandesClientUtils,
);

export const getDemandesClientUtils = createSelector(
    getDemandesClientUtilsState,
    selectAll
);
