import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import {
    WorkerActionTypes,
    UpdateOneWorker,
    CreateWorker,
    CreateWorkerFail,
    UpdateOneWorkerFail,
    DeleteOneWorker,
    DeleteOneWorkerFail,
    UpdateOneWorkerSuccess,
    CreateWorkerSuccess,
    DeleteOneWorkerSuccess,
} from '../actions/team-mates.action';
import { ApiService } from '../../../ogiv-core/services/api.service';
import { CreateTimesheetSuccess } from '../actions';
import { Timesheet } from '../../../shared/models/database/timesheet.model';
import { Worker } from '../../../shared/models/database/worker.model';
import { AddOneAppNotification } from '../../../core/store/actions/app-notifications.actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class WorkersEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    /************************/
    /*******  CREATE  *******/
    /************************/
    public createWorker$ = createEffect(() => this.actions$.pipe(
        ofType<CreateWorker>(WorkerActionTypes.CREATE_WORKER),
        mergeMap((action: CreateWorker) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/workers`;
                } else {
                    url += `${action.timesheetDateId}/workers`;
                }
            } else {
                url += `${action.timesheetDateId}/workers`;
            }

            return this.apiService.post<Timesheet, Worker>(url, action.worker).pipe(
                mergeMap((refreshedTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(refreshedTimesheet)),
                    new CreateWorkerSuccess(),
                ]),
                catchError((error: any) => of(new CreateWorkerFail(error))),
            );
        }),
    ));

    public createWorkerSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<CreateWorkerSuccess>(WorkerActionTypes.CREATE_WORKER_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.teammate.create.success',
            }))
        ]
        ),
    ));

    public createWorkerFail$ = createEffect(() => this.actions$.pipe(
        ofType<CreateWorkerFail>(WorkerActionTypes.CREATE_WORKER_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.teammate.create.error',
            }))
        ]
        ),
    ));

    /************************/
    /*******  UPDATE  *******/
    /************************/
    public updateWorker$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneWorker>(WorkerActionTypes.UPDATE_ONE_WORKER),
        mergeMap((action: UpdateOneWorker) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/workers`;
                } else {
                    url += `${action.timesheetDateId}/workers`;
                }
            } else {
                url += `${action.timesheetDateId}/workers`;
            }

            return this.apiService.put<Timesheet, Worker>(url, action.worker).pipe(
                mergeMap((refreshedTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(refreshedTimesheet)),
                    new UpdateOneWorkerSuccess(),
                ]),
                catchError((error: any) => of(new UpdateOneWorkerFail(error))),
            );
        }),
    ));

    public updateOneWorkerSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneWorkerSuccess>(WorkerActionTypes.UPDATE_ONE_WORKER_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.teammate.update.success',
            }))
        ]
        ),
    ));

    public updateOneWorkerFail$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneWorkerFail>(WorkerActionTypes.UPDATE_ONE_WORKER_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.teammate.update.error',
            }))
        ]
        ),
    ));

    /************************/
    /*******  DELETE  *******/
    /************************/
    public deleteWorker$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneWorker>(WorkerActionTypes.DELETE_ONE_WORKER),
        mergeMap((action: DeleteOneWorker) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/workers/${action.worker.id}`;
                } else {
                    url += `${action.timesheetDateId}/workers/${action.worker.id}`;
                }
            } else {
                url += `${action.timesheetDateId}/workers/${action.worker.id}`;
            }

            return this.apiService.delete<Timesheet>(url).pipe(
                mergeMap((refreshedTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(refreshedTimesheet)),
                    new DeleteOneWorkerSuccess(),
                ]),
                catchError((error: any) => of(new DeleteOneWorkerFail(error))),
            );
        }),
    ));

    public deleteOneWorkerSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneWorkerSuccess>(WorkerActionTypes.DELETE_ONE_WORKER_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.teammate.delete.success',
            }))
        ]
        ),
    ));

    public deleteOneWorkerFail$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneWorkerFail>(WorkerActionTypes.DELETE_ONE_WORKER_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.teammate.delete.error',
            }))
        ]
        ),
    ));
}
