import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';

import { CommonUiModule } from '@ngxhq/common-ui';
import { ThemeHqCommonUiSupportModule } from '@ngxhq/theme-ngxhq';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../../../shared/shared.module';
import { timesheetReportsComponents } from './components';
import { TimesheetReportsRoutingModule } from './timesheet-report-routing.module';
import { reportsVendorsModules } from '../../reports-vendors-modules';
import { timesheetReportsGuards } from './guards';

@NgModule({
    imports: [
        CommonModule,
        CommonUiModule,
        SharedModule,
        MatButtonModule,
        MatTreeModule,
        MatIconModule,
        MatListModule,
        MatSelectModule,

        ThemeHqCommonUiSupportModule,
        TranslateModule,

        // Vendors
        ...reportsVendorsModules,

        // Router
        TimesheetReportsRoutingModule,
    ],
    declarations: [
        ...timesheetReportsComponents,
    ],
    providers: [
        ...timesheetReportsGuards,
    ]
})
export class TimesheetReportsModule {
}
