import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';

import { TranslateModule } from '@ngx-translate/core';
import { CommonConfigurationService } from '@ngxhq/config';
import { ThemeHqCommonUiSupportModule, ThemeHqCustomComponentModule } from '@ngxhq/theme-ngxhq';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppConfiguration } from './config/app-config.model';
import { sharedComponents } from './components';
import { sharedDirectives } from './directives';

import { sharedPipes } from './pipes';

@NgModule({
    imports: [
        CommonModule,
        ThemeHqCommonUiSupportModule,
        ThemeHqCustomComponentModule,
        ProgressSpinnerModule,
        MatDialogModule,
        MatDividerModule,
        MatButtonModule,
        MatMenuModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,
        ButtonModule,
        InputTextareaModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatFormFieldModule,
    ],
    exports: [
        ReactiveFormsModule,
        ThemeHqCommonUiSupportModule,
        ThemeHqCustomComponentModule,
        ProgressSpinnerModule,
        ...sharedComponents,
        ...sharedDirectives,
        ...sharedPipes,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        ...sharedComponents,
        ...sharedDirectives,
        ...sharedPipes
    ],
    providers: [
        {
            provide: AppConfiguration,
            useFactory: (commonConfigurationService: CommonConfigurationService<AppConfiguration>) => commonConfigurationService.getConfiguration(AppConfiguration),
            deps: [CommonConfigurationService]
        }
    ]
})
export class SharedModule {
}
