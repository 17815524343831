import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-error-table',
    templateUrl: './error-table.component.html',
    styleUrls: ['./error-table.component.scss'],
})
export class ErrorTableComponent {
    public translatePrefix = 'timesheet.reports.detail.error';

    @Input() errors: string[];

    public errorLengthGT(number: number): boolean {
        return this.errors && this.errors.length > number;
    }
}
