export interface TableAction {
    code: TableActionType;
    icon: TableActionIconType;
    labelTranslateKey?: string;
}

export enum TableActionType {
    OPEN = 'open',
    DELETE = 'delete',
    PREVIEW = 'preview',
    CREATE = 'create',
    EXPORT = 'export',
    REPORT = 'report'
}

export enum TableActionIconType {
    SEARCH = 'fa-search',
    DELETE = 'fa-trash-alt',
    PREVIEW = 'fa-eye',
    DOWNLOAD = 'fa-download',
    REPORT = 'fa-file-alt',
}

export interface TableEventParam {
    actionCode: string;
    rowData: any;
}
