import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { UtilisateurInterne } from '../../models/utilisateur-interne.model';

export enum UtilisateurInterneActionType {
    LOAD_UTILISATEURS_INTERNES = '[UtilisateurInterne] Load Utilisateurs Internes',
    LOAD_UTILISATEURS_INTERNES_SUCCESS = '[UtilisateurInterne] Load Utilisateurs Internes Success',
    LOAD_UTILISATEURS_INTERNES_FAIL = '[UtilisateurInterne] Load Utilisateurs Internes Fail',
    LOAD_UTILISATEURS_INTERNES_NO_RESULTS = '[UtilisateurInterne] Load Utilisateurs Internes No Result',

    UPDATE_ONE_UTILISATEUR_INTERNE = '[UtilisateurInterne] Update One Utilisateur Interne',
    UPDATE_ONE_UTILISATEUR_INTERNE_SUCCESS = '[UtilisateurInterne] Update One Utilisateur Interne Success',
    UPDATE_ONE_UTILISATEUR_INTERNE_FAIL = '[UtilisateurInterne] Update One Utilisateur Interne Fail',
}

/**
 *  ACTIONS
 */

/**
 *  LOAD
 */

export class LoadUtilisateursInternes implements Action {
    readonly type = UtilisateurInterneActionType.LOAD_UTILISATEURS_INTERNES;
    constructor(public path: AlternativePath) { }
}

export class LoadUtilisateursInternesNoResult implements Action {
    readonly type = UtilisateurInterneActionType.LOAD_UTILISATEURS_INTERNES_NO_RESULTS;
}

export class LoadUtilisateursInternesSuccess implements Action {
    readonly type = UtilisateurInterneActionType.LOAD_UTILISATEURS_INTERNES_SUCCESS;
    constructor(public utilisateursInternes: UtilisateurInterne[]) { }
}

export class LoadUtilisateursInternesFail implements Action {
    readonly type = UtilisateurInterneActionType.LOAD_UTILISATEURS_INTERNES_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneUtilisateurInterne implements Action {
    readonly type = UtilisateurInterneActionType.UPDATE_ONE_UTILISATEUR_INTERNE;
    constructor(public utilisateurInterne: UtilisateurInterne, public path: AlternativePath) { }
}

export class UpdateOneUtilisateurInterneSuccess implements Action {
    readonly type = UtilisateurInterneActionType.UPDATE_ONE_UTILISATEUR_INTERNE_SUCCESS;
    constructor(public utilisateurInterne: UtilisateurInterne) { }
}
export class UpdateOneUtilisateurInterneFail implements Action {
    readonly type = UtilisateurInterneActionType.UPDATE_ONE_UTILISATEUR_INTERNE_FAIL;
    constructor(public error: any) { }
}

export type UtilisateurInterneAction =
    | LoadUtilisateursInternes
    | LoadUtilisateursInternesNoResult
    | LoadUtilisateursInternesSuccess
    | LoadUtilisateursInternesFail
    | UpdateOneUtilisateurInterne
    | UpdateOneUtilisateurInterneSuccess
    | UpdateOneUtilisateurInterneFail;
