import { Component, Output, EventEmitter, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
    selector: 'app-generic-module-header',
    templateUrl: './generic-module-header.component.html',
    styleUrls: ['./generic-module-header.component.scss'],
})
export class GenericModuleHeaderComponent implements AfterViewInit {
    @Input() public moduleLogo = '';
    @Input() public moduleTitle = 'Change Title';
    @Input() public buttonLogo = 'fas fa-sync-alt';
    @Input() public buttonTitle = 'RECHARGER';
    @Input() public hideButton = false;

    @Output() public buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('button') button: ElementRef<HTMLElement>;

    constructor(private _focusMonitor: FocusMonitor) { }

    ngAfterViewInit() {
        this._focusMonitor.stopMonitoring(this.button);
    }

    public reload(): void {
        this.buttonClick.emit(true);
    }
}
