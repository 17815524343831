import { Action } from '@ngrx/store';

import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { BillingTimesheet } from '../../../shared/models/database/billing-timesheet.model';

/**
 * Types
 */
export enum BillingTimesheetActionType {
    LOAD_BILLING_TIMESHEETS = '[Billing Timesheet] Load Billing Timesheets',
    LOAD_BILLING_TIMESHEETS_SUCCESS = '[Billing Timesheet] Load Billing Timesheets Success',
    LOAD_BILLING_TIMESHEETS_FAIL = '[Billing Timesheet] Load Billing Timesheets Fail',
}

/**
 * ACTIONS
 */
/**
 * LOAD
 */
export class LoadBillingTimesheets implements Action {
    readonly type = BillingTimesheetActionType.LOAD_BILLING_TIMESHEETS;
    constructor(public billingReportId: number, public path: AlternativePath) { }
}

export class LoadBillingTimesheetsSuccess implements Action {
    readonly type = BillingTimesheetActionType.LOAD_BILLING_TIMESHEETS_SUCCESS;
    constructor(public billingTimesheets: BillingTimesheet[]) { }
}

export class LoadBillingTimesheetsFail implements Action {
    readonly type = BillingTimesheetActionType.LOAD_BILLING_TIMESHEETS_FAIL;
    constructor(public error: any) { }
}

export type BillingTimesheetAction =
    | LoadBillingTimesheets
    | LoadBillingTimesheetsSuccess
    | LoadBillingTimesheetsFail;
