import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import moment from 'moment';
import { FilterService } from 'primeng/api';

import { trackByColumnField } from '../../../../../shared/helpers/track-by.helper';
import { TableColumns } from '../../../../../shared/models/table-columns.model';
import { TimesheetReport } from '../../../../../shared/models/database/timesheet-report.model';
import { TimesheetReportSummary } from '../../../../../shared/models/database/timesheet-report-summary.model';
import { Approval } from '../../../../../shared/models/database/approval.model';
import { TimesheetReportPartialStatus } from '../../../../../shared/models/database/timesheet-report-status.enum';
import { Table } from 'primeng/table';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-timesheet-report-table',
    templateUrl: './timesheet-report-table.component.html',
    styleUrls: ['./timesheet-report-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetReportsTableComponent implements OnChanges {
    public myReports: TimesheetReport[];
    public buttonOverIndex: number | null = null;
    public filtersApply = false;
    public currentFilters: string[] = [];
    public tableFiltersItems: any = {};
    public filtersEntries: any = {};

    @Input() public translatePrefix: string;
    @Input() public coreTranslatePrefix: string;
    @Input() public sortField: string;
    @Input() public sortOrder: number;
    @Input() public reports: TimesheetReport[] = [];
    @Input() public cols: TableColumns[];

    @Output() public goToReport: EventEmitter<TimesheetReport> = new EventEmitter();
    @Output() public displayRemark: EventEmitter<TimesheetReport> = new EventEmitter();

    @ViewChild('timesheetReportsDatatable') timesheetReportsDatatable: Table;

    public trackByColumnField = trackByColumnField;

    constructor(private filterService: FilterService) {
        this.filterService.register('date', (value: string, filterValue: string): boolean => {
            if (!filterValue || filterValue.trim() === '') {
                return true;
            }

            if (!value) {
                return false;
            }

            return moment(value).format('DD-MM-yyyy').includes(filterValue.toString());
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.reports && changes.reports.currentValue) {
            if (changes.reports.currentValue.length > 0) {
                this.myReports = changes.reports.currentValue;
                this.myReports = this.myReports.map((report: TimesheetReport) => this.changeNullToEmptyString(cloneDeep(report)));
                this.createDynamicFilterList(this.myReports);
            } else {
                this.myReports = [];
            }
        }
    }

    private createDynamicFilterList(reports: TimesheetReport[]) {
        reports.forEach((report: TimesheetReport) => {
            this.addFilterValueIfNotExist('site', report.site);
        });

        this.addEmptyValueToOneFilter('site');
        this.sortDinamicFilter();
    }

    private changeNullToEmptyString(report: TimesheetReport): TimesheetReport {
        if (report && report.site === null) {
            report.site = '';
        }

        return report;
    }

    private addEmptyValueToOneFilter(filterName: string): void {
        if (this.tableFiltersItems[filterName]?.findIndex((filter: any) => filter.code === '') === -1) {
            this.tableFiltersItems[filterName].unshift({
                name: '- Vide -',
                code: '',
            });
        }
    }

    private addFilterValueIfNotExist(filterName: string, value?: string | null): void {
        if (!this.tableFiltersItems[filterName]) {
            this.tableFiltersItems[filterName] = [];
        }
        if (value !== undefined) {
            const checkedValue = (value === null || value === '') ? '- Vide -' : value;
            const checkedCode = checkedValue === '- Vide -' ? '' : value;

            if (this.tableFiltersItems[filterName].findIndex((filter: any) =>
                filter.code === checkedCode) === -1) {

                this.tableFiltersItems[filterName].push({
                    name: checkedValue,
                    code: checkedCode,
                });
            }
        }
    }

    public onFilterChange(value: any, fieldName: string): void {
        const field = this.cols.find((col: TableColumns) => col.field === fieldName);
        if (field) {
            switch (field.filterType) {
                case 'multi-select': {
                    let emptySelected = false;
                    const values = value.map((selectedValue: any) => {
                        // eslint-disable-next-line
                        emptySelected = !emptySelected && selectedValue.code === '';
                        return selectedValue.code;
                    });
                    if (emptySelected) {
                        values.push(null);
                    }
                    this.filterColumn(values, fieldName, 'in');
                } break;
                default: {
                    this.filterColumn(value, fieldName);
                }
            }
        }
    }

    public sortDinamicFilter() {
        Object.keys(this.tableFiltersItems).forEach((key) => {
            const temp = this.tableFiltersItems[key].sort((a: { name: string, code: string }, b: { name: string, code: string }) => {
                return (a.name).localeCompare(b.name);
            });

            this.tableFiltersItems[key] = temp;
        });
    }

    public onGoToReport(report?: TimesheetReport): void {
        this.goToReport.emit(report);
    }

    public hasRemark(report: TimesheetReportSummary): boolean {
        if (report.remark || report.remarkNCEnt || report.remarkNCHQ || (report.approvals && report.approvals.length > 0)) {
            let hasRemark = false;
            if (report.approvals && report.approvals.length > 0) {
                report.approvals.forEach((appr: Approval) => {
                    if (appr.status === TimesheetReportPartialStatus.Refusé && appr.remark !== undefined && appr.remark !== null && appr.remark.trim() !== '') {
                        hasRemark = true;
                    }
                });
            }

            if (report.remark || report.remarkNCEnt || report.remarkNCHQ) {
                hasRemark = true;
            }
            return hasRemark;
        }
        return (report.remark || report.remarkNCEnt || report.remarkNCHQ) ? true : false;
    }

    public remarkClicked(report: TimesheetReportSummary, event: MouseEvent) {
        event.stopPropagation();
        this.displayRemark.emit(report);
    }

    public rowOver(rowIndex: number | null): void {
        this.buttonOverIndex = rowIndex;
    }

    public inputFilterColumn(value: Event, field: string, filterMatchMode?: string): void {
        this.filterColumn((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains');
    }

    public filterColumn(value: string, field: string, filterMatchMode?: string): void {
        this.timesheetReportsDatatable.filter(value, field, filterMatchMode || 'contains');
        this.eraseFilterButtonStatus(value, field);
    }

    public eraseFilterButtonStatus(value: string, field: string): void {
        const index = this.currentFilters.findIndex((activeFilter: string) => activeFilter === field);
        if (index === -1 && (value !== '' || Array.isArray(value) && value.length > 0)) {
            this.currentFilters.push(field);
        } else if (index !== -1 && (value === '' || Array.isArray(value) && value.length === 0)) {
            this.currentFilters.splice(this.currentFilters.findIndex((activeFilter: string) => activeFilter === field), 1);
        }

        this.filtersApply = this.currentFilters.length > 0;
    }

    public clearAllFilters(): void {
        this.cols.forEach((col: TableColumns) => {
            let value: any;
            let matchMode = 'contains';

            if (col.field !== 'site') {
                value = '';
                if ((<HTMLInputElement>document.getElementById(`id-${col.field}`))) {
                    (<HTMLInputElement>document.getElementById(`id-${col.field}`)).value = value;
                }
            } else {
                value = [];
                matchMode = 'in';
            }

            this.filterColumn(value, col.field, matchMode);
        });

        this.filtersEntries = {};
    }
}
