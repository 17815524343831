import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { Entrepreneur } from '../../../../models/entrepreneur.model';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-entrepreneur-form',
    templateUrl: './entrepreneur-form.component.html',
    styleUrls: ['./entrepreneur-form.component.scss']
})
export class EntrepreneurFormComponent implements OnInit, OnChanges, OnDestroy {
    public logoAbreviationMatchFound: boolean | null = null;
    public diplayAbeviationStatusLogo = true;
    public entrepreneurForm: FormGroup;
    public pattern = { 0: { pattern: new RegExp('\\d'), symbol: '#' } };
    private subscriptions: Subscription[] = [];
    private abbriviationPattern = new RegExp('^(^[a-zA-Z0-9_]+)$');

    @Input() public entrepreneur: Entrepreneur | null;
    @Input() public translatePrefix: string;
    @Input() public entrepreneurs: Entrepreneur[] | null;

    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public create: EventEmitter<Entrepreneur> = new EventEmitter<Entrepreneur>();
    @Output() public update: EventEmitter<Entrepreneur> = new EventEmitter<Entrepreneur>();

    constructor(
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<EntrepreneurFormComponent>,
        public translateService: TranslateService
    ) {
        this.entrepreneurForm = this.formBuilder.group({
            id: [''],
            nom: ['', [Validators.required]],
            abreviation: ['', [Validators.required]],
            adresse: [''],
            responsable: [''],
            telephone: [''],
            statut: ['']
        });
    }

    public ngOnInit() {
        this.subscriptions.push(
            this.entrepreneurForm.controls.abreviation.valueChanges.pipe(
                tap((abreviation: string) => {
                    this.entrepreneurForm.controls.abreviation.markAsDirty({ onlySelf: true });
                    this.entrepreneurForm.controls.abreviation.markAsTouched();
                    this.verifyAbbreviationStatus(abreviation);
                }),
            ).subscribe(),
        );
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.entrepreneur && changes.entrepreneur.currentValue) {
            this.setEntrepreneurValues(changes.entrepreneur.currentValue);
            this.entrepreneurForm.controls.nom.disable({ emitEvent: false });
        } else {
            this.setEntrepreneurValidators();
        }
    }

    private removeSpaceFromString(value: string): string {
        return value.replace(/\s+/g, '');
    }

    private setEntrepreneurValues(entrepreneur: Entrepreneur): void {
        this.entrepreneurForm.reset(entrepreneur);
        const phoneValue = this.entrepreneurForm.controls.telephone.value;
        if (phoneValue && phoneValue !== '') {
            this.entrepreneurForm.controls.telephone.patchValue(this.removeSpaceFromString(phoneValue), { emitEvent: false });
        }

        if (this.entrepreneurForm.controls.abreviation.value !== null && this.entrepreneurForm.controls.abreviation.value !== '') {
            this.diplayAbeviationStatusLogo = false;
            this.entrepreneurForm.controls.abreviation.disable();
        } else {
            this.diplayAbeviationStatusLogo = true;
        }
    }

    private setEntrepreneurValidators(): void {
        this.entrepreneurForm.controls.abreviation.setValidators([Validators.required, (control: AbstractControl) => {
            let matches = false;
            if (this.entrepreneurs) {
                matches = (this.entrepreneurs.filter(function (e) { return e.abreviation.toUpperCase() === control.value.toUpperCase(); })).length > 0;
            }

            if (matches) {
                this.logoAbreviationMatchFound = true;
                return { 'abreviationMatch': true };
            }

            this.logoAbreviationMatchFound = false;
            return null;
        }]);
    }

    public verifyAbbreviationStatus(value: any): void {
        let matches = false;

        if (value !== null && value !== '') {
            if (this.abbriviationPattern.test(value)) {
                if (this.entrepreneurs) {
                    matches = (this.entrepreneurs.filter((e) => {
                        if (e.abreviation !== null && e.abreviation !== '') {
                            return e.abreviation.toUpperCase() === value.toUpperCase();
                        }
                        return false;
                    })).length > 0;
                }

                if (matches) {
                    this.logoAbreviationMatchFound = true;
                    this.entrepreneurForm.controls.abreviation.setErrors({ abreviationMatch: true });
                } else {
                    this.logoAbreviationMatchFound = false;
                    this.entrepreneurForm.controls.abreviation.setErrors(null);
                }
            } else {
                this.logoAbreviationMatchFound = null;
                this.entrepreneurForm.controls.abreviation.setErrors({ badCharacter: true });
            }
        } else {
            this.logoAbreviationMatchFound = null;
            this.entrepreneurForm.controls.abreviation.setErrors({ required: true });
        }

        this.entrepreneurForm.updateValueAndValidity();
    }

    public getErrorMessageName(): string {
        if (this.entrepreneurForm.controls.nom.hasError('required')) {
            return this.translateService.instant(this.translatePrefix + '.errors' + '.required');
        }

        return 'Erreur';
    }

    public getErrorMessageAbbreviation(): string {
        if (this.entrepreneurForm.controls.abreviation.hasError('required')) {
            return this.translateService.instant(this.translatePrefix + '.errors' + '.required');
        }
        if (this.entrepreneurForm.controls.abreviation.hasError('abreviationMatch')) {
            return this.translateService.instant(this.translatePrefix + '.errors' + '.abreviationUnique');
        }
        if (this.entrepreneurForm.controls.abreviation.hasError('badCharacter')) {
            return this.translateService.instant(this.translatePrefix + '.errors' + '.badCharacter');
        }

        return 'Erreur';
    }

    public getErrorMessageTelephone(): string {
        if (this.entrepreneurForm.controls.telephone.hasError('pattern')) {
            return this.translateService.instant(this.translatePrefix + '.errors' + '.telephonePattern');
        }
        if (this.entrepreneurForm.controls.telephone.hasError('mask')) {
            return this.translateService.instant(this.translatePrefix + '.errors' + '.telephonePattern');
        }
        return 'Erreur';
    }

    public cancelForm(): void {
        this.cancel.emit(true);
    }

    public formatPhoneNumber(): void {
        const tel = this.entrepreneurForm.controls.telephone.value;
        if (tel !== undefined && tel !== null && tel !== '') {
            this.entrepreneurForm.controls.telephone.setValue(this.removeSpaceFromString(this.entrepreneurForm.controls.telephone.value));
        }

    }

    public onSubmit(): void {
        this.formatPhoneNumber();
        const { valid } = this.entrepreneurForm;

        if (valid) {
            const value = {
                ...this.entrepreneurForm.getRawValue(),
            };

            if (this.entrepreneur && this.entrepreneur.id) {
                this.update.emit(value);
            } else {
                const { id, statut, ...cleanValue } = value;
                this.create.emit(cleanValue);
            }
        }
    }
}
