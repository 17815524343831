<div class="header">
    <div *ngIf="!hideButton" class="header__action">
        <div class="action__buttons">
            <button mat-button (click)="backCLick()">
                <i class="{{buttonLogo}} buttonIcon"></i>
                <span class="buttonText">{{ 'timesheet.reports.detail.back' | translate}}</span>
            </button>
        </div>
    </div>

    <h2 class="header__title">
        <i class="{{ pageLogo }} space" ></i>
        <span>
            {{ pageTitle }}
        </span>
    </h2>
    <p *ngIf="subtitle !== ''" class="subtitle">{{ subtitle | translate }}</p>
</div>
