import { ArboristsEffects } from './arborists.effect';
import { EquipmentsEffects } from './equipments.effect';
import { JobClassesEffects } from './job-classes.effect';
import { PostLinesEffects } from './post-lines.effect';
import { WorkersEffects } from './team-mates.effect';
import { TimesheetsEffects } from './timesheets.effect';
import { UnitsEffects } from './units.effect';
import { VehiclesEffects } from './vehicles.effect';

export const timesheetStoreEffects = [
    ArboristsEffects,
    EquipmentsEffects,
    JobClassesEffects,
    PostLinesEffects,
    WorkersEffects,
    TimesheetsEffects,
    UnitsEffects,
    VehiclesEffects,
];
