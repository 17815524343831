<div mat-dialog-title class="dialog__title">
    <i *ngIf="icon" class="{{ icon }} title__logo"></i>
    <div class="title__text">
        <span *ngIf="!isTranslatePrefixEmpty()">
            {{ translatePrefix + '.' + titleKey | translate }}
        </span>
    </div>
</div>
<div mat-dialog-content class="dialog__content">
    <ng-container [ngSwitch]="componentName">
        <ng-container *ngSwitchCase="'displayRemark'">
            <app-modal-remark [report]="report" [translationPrefix]="translatePrefix" (closed)="onClose()"></app-modal-remark>
        </ng-container>
        <ng-container *ngSwitchCase="'info'">
            <app-modal-question [message]="message || ''" [displayDeleteButton]="displayDeleteButton" (save)="onSave($event)" (cancel)="onCancel($event)">
            </app-modal-question>
        </ng-container>
    </ng-container>
</div>
