<app-element-loader *ngIf="isBillingSapEntriesLoading$ | async"></app-element-loader>

<app-generic-detail-page-header
    [pageLogo]="'fas fa-file-alt'"
    [pageTitle]="'billing.cgc.sap-entries-report.title' | translate"
    (back)="backToReport()">
</app-generic-detail-page-header>

<div class="pageHeaderTable">
    <p-table [value]="pageHeaders">
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td class="rowHeadings">{{ rowData.rowTitle }}</td>
                <td>{{ rowData.rowValue }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="reportTable">
    <p-table *ngIf="billingSapEntries" [value]="billingSapEntries">
        <ng-template pTemplate="header">
            <tr>
                <th>{{ translatePrefix + 'numeroImputation' | translate }}</th>
                <th>{{ translatePrefix + 'natureComptable' | translate }}</th>
                <th>{{ translatePrefix + 'numeroFicheService' | translate }}</th>
                <th>{{ translatePrefix + 'code' | translate }}</th>
                <th>{{ translatePrefix + 'heure' | translate }}</th>
                <th>{{ translatePrefix + 'quantite' | translate }}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr>
                <td class="groupingCell" *ngIf="rowGroupMetadata[rowData.numeroImputation].index === rowIndex"
                    [attr.rowspan]="rowGroupMetadata[rowData.numeroImputation].size">
                    <span class="p-text-bold p-ml-2">{{ rowData.numeroImputation }}</span>
                </td>
                <td class="groupingCell"
                    *ngIf="rowGroupMetadata[rowData.numeroImputation + '-' + rowData.natureComptable].index === rowIndex"
                    [attr.rowspan]="rowGroupMetadata[rowData.numeroImputation + '-' + rowData.natureComptable].size">
                    <span class="p-text-bold p-ml-2">{{ rowData.natureComptable }}</span>
                </td>
                <td class="itemCode">{{ rowData.numeroFicheService }}</td>
                <td class="itemCode">{{ rowData.code }}</td>
                <td class="numericCell">{{ rowData.heure | number: '1.3' }}</td>
                <td class="numericCell">{{ rowData.quantite | number: '1.3' }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<section class="actions">
    <div class="actions__spacer"></div>
    <button pButton icon="fa fa-download" class="actions__button right" type="button" (click)="exportExcel()"
        [label]="'common.export' | translate"></button>
</section>
