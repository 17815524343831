import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { Vehicle } from '../../../shared/models/database/vehicle.model';

/**
 * Types
 */
export enum VehicleActionType {
    LOAD_VEHICLES = '[Vehicle] Load Vehicles',
    LOAD_VEHICLES_SUCCESS = '[Vehicle] Load Vehicles Success',
    LOAD_VEHICLES_FAIL = '[Vehicle] Load Vehicles Fail',
    LOAD_VEHICLES_NO_RESULTS = '[Vehicle] Load Vehicles No Result',

    CREATE_VEHICLE = '[Vehicle] Create Vehicle',
    CREATE_VEHICLE_SUCCESS = '[Vehicle] Create Vehicle Success',
    CREATE_VEHICLE_FAIL = '[Vehicle] Create Vehicle Fail',

    UPDATE_ONE_VEHICLE = '[Vehicle] Update One Vehicle',
    UPDATE_ONE_VEHICLE_SUCCESS = '[Vehicle] Update One Vehicle Success',
    UPDATE_ONE_VEHICLE_FAIL = '[Vehicle] Update One Vehicle Fail',
}

/**
 * ACTIONS
 */
/**
 * CREATE
 */
export class CreateVehicle implements Action {
    readonly type = VehicleActionType.CREATE_VEHICLE;
    constructor(public vehicle: Vehicle, public path: AlternativePath) { }
}

export class CreateVehicleSuccess implements Action {
    readonly type = VehicleActionType.CREATE_VEHICLE_SUCCESS;
    constructor(public vehicles: Vehicle[]) { }
}

export class CreateVehicleFail implements Action {
    readonly type = VehicleActionType.CREATE_VEHICLE_FAIL;
    constructor(public error: any) { }
}

/**
 * LOAD
 */
export class LoadVehicles implements Action {
    readonly type = VehicleActionType.LOAD_VEHICLES;
    constructor(public path: AlternativePath) { }
}

export class LoadVehiclesNoResult implements Action {
    readonly type = VehicleActionType.LOAD_VEHICLES_NO_RESULTS;
}

export class LoadVehiclesSuccess implements Action {
    readonly type = VehicleActionType.LOAD_VEHICLES_SUCCESS;
    constructor(public vehicles: Vehicle[]) { }
}

export class LoadVehiclesFail implements Action {
    readonly type = VehicleActionType.LOAD_VEHICLES_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneVehicle implements Action {
    readonly type = VehicleActionType.UPDATE_ONE_VEHICLE;
    constructor(public vehicle: Vehicle, public path: AlternativePath) { }
}

export class UpdateOneVehicleSuccess implements Action {
    readonly type = VehicleActionType.UPDATE_ONE_VEHICLE_SUCCESS;
    constructor(public vehicle: Vehicle) { }
}

export class UpdateOneVehicleFail implements Action {
    readonly type = VehicleActionType.UPDATE_ONE_VEHICLE_FAIL;
    constructor(public error: any) { }
}

export type VehicleAction =
    | CreateVehicle
    | CreateVehicleSuccess
    | CreateVehicleFail
    | LoadVehicles
    | LoadVehiclesNoResult
    | LoadVehiclesSuccess
    | LoadVehiclesFail
    | UpdateOneVehicle
    | UpdateOneVehicleSuccess
    | UpdateOneVehicleFail;
