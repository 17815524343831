import { GroupLeaderTimesheetReportsListComponent } from './list/roles-report-list/gl-timesheet-reports-list/gl-timesheet-reports-list.component';
import { TechnicianTimesheetReportsListComponent } from './list/roles-report-list/technician-timesheet-reports-list/technician-timesheet-reports-list.component';
import { TimesheetReportsListEntComponent } from './list/timesheet-reports-list-ent/timesheet-reports-list-ent.component';
import { TimesheetReportsListHqComponent } from './list/timesheet-reports-list-hq/timesheet-reports-list-hq.component';
import { TimesheetReportPartTableComponent } from './report/table/timesheet-report-part-table.component';
import { TimesheetReportComponent } from './report/timesheet-report.component';
import { TimesheetReportsTableComponent } from './timesheet-report-table/timesheet-report-table.component';
import { TimesheetReportsComponent } from './timesheet-reports.component';
import { ReportFiltersComponent } from './list/report-filters/report-filters.component';
import { TeaxtAreaCrudComponent } from './report/text-area-crud/text-area-crud.component';
import { ErrorTableComponent } from './report/error-table/error-table.component';
import { TimesheetRemarkComponent } from './report/timesheet-remark/timesheet-remark.component';

export const timesheetReportsComponents = [
    TimesheetReportsComponent,
    TimesheetReportsTableComponent,
    TimesheetReportsListEntComponent,
    TimesheetReportsListHqComponent,
    GroupLeaderTimesheetReportsListComponent,
    TechnicianTimesheetReportsListComponent,
    TimesheetReportComponent,
    TimesheetReportPartTableComponent,
    ReportFiltersComponent,
    TeaxtAreaCrudComponent,
    ErrorTableComponent,
    TimesheetRemarkComponent,
];

export * from './list/roles-report-list/gl-timesheet-reports-list/gl-timesheet-reports-list.component';
export * from './list/roles-report-list/technician-timesheet-reports-list/technician-timesheet-reports-list.component';
export * from './list/timesheet-reports-list-ent/timesheet-reports-list-ent.component';
export * from './list/timesheet-reports-list-hq/timesheet-reports-list-hq.component';
export * from './timesheet-report-table/timesheet-report-table.component';
export * from './timesheet-reports.component';
export * from './report/timesheet-report.component';
export * from './report/table/timesheet-report-part-table.component';
export * from './list/report-filters/report-filters.component';
export * from './report/text-area-crud/text-area-crud.component';
export * from './report/error-table/error-table.component';
export * from './report/timesheet-remark/timesheet-remark.component';
