import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../../ogiv-core/services';
import {
    LoadProgressReportsSpecific,
    LoadProgressReportsSpecificFail,
    LoadProgressReportsSpecificNoResult,
    LoadProgressReportsSpecificSuccess,
    ProgressReportsSpecificActionType
} from '../actions';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification } from '../../../core/store/models/app-notification.model';
import { ProgressReportSpecificWrapper } from '../../../shared/models/database/progress-report-specific-wrapper.model';
@Injectable()
export class ProgressReportSpecificEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public loadProgressReportsSpecific$ = createEffect(() => this.actions$.pipe(
        ofType<LoadProgressReportsSpecific>(ProgressReportsSpecificActionType.LOAD_PROGRESS_REPORTS_SPECIFIC),
        switchMap((action: LoadProgressReportsSpecific) => {
            const queryString = (action.queryString !== undefined && action.queryString !== null && action.queryString !== '') ? `${action.queryString}` : '';
            return this.apiService.get<ProgressReportSpecificWrapper>(`${action.path}/rapports/avancement/specific${queryString}`).pipe(
                map((specificReportWrapper: ProgressReportSpecificWrapper) =>
                    (specificReportWrapper && specificReportWrapper.report_data && specificReportWrapper.report_data.length > 0)
                        ? new LoadProgressReportsSpecificSuccess(specificReportWrapper)
                        : new LoadProgressReportsSpecificNoResult()
                ),
                catchError((error: any) => of(new LoadProgressReportsSpecificFail(error))),
            );
        }),
    ));

    public loadProgressReportsSpecificFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadProgressReportsSpecificFail>(ProgressReportsSpecificActionType.LOAD_PROGRESS_REPORTS_SPECIFIC_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'progressReport.specificReport.load.error',
            }))
        ]),
    ));
}
