<app-generic-module-header
    [moduleLogo]="'fas fa-cogs space'"
    [moduleTitle]="'pilotages.general.title' | translate"
    [buttonTitle]="'pilotages.general.buttons.reload' | translate | uppercase"
    (buttonClick)="reload()"
></app-generic-module-header>

<mat-tab-group [animationDuration]="'0'" #mainTabs >
    <mat-tab #cieTab>
        <ng-template mat-tab-label>
            <i class="fa fa-briefcase tabLogo" ></i>
            <span>
                {{ 'pilotages.general.tabs.entrepreneurs' | translate }}
            </span>
        </ng-template>
        <ng-container *ngIf="cieTab.isActive">
            <h3> {{ 'pilotages.entrepreneurs.title' | translate }}</h3>
            <ng-container>
                <app-entrepreneurs-table [translatePrefix]="translatePrefixEntrepreneurs + '.table-entrepreneurs'"
                    [sortOrder]="sortOrder" [pathChoice]="pathChoice"
                    [reloadData]="currentView === 'Entrepreneurs' ? reloadData : false"
                    (selectedModule)="setCurrentView($event)" (reloadedDone)="reloadedData()">
                </app-entrepreneurs-table>
            </ng-container>
        </ng-container>
    </mat-tab>
    <mat-tab #usersTab>
        <ng-template mat-tab-label>
            <i class="fa fa-users tabLogo" ></i>
            <span>
                {{ 'pilotages.general.tabs.utilisateurs' | translate }}
            </span>
        </ng-template>
        <ng-container *ngIf="usersTab.isActive">
            <mat-tab-group [animationDuration]="'0'" #userTabs>
                <mat-tab #internUser [label]="'UserInterne'">
                    <ng-template mat-tab-label>
                        <i class="fa fa-users tabLogo" ></i>
                        <span>
                            {{ 'pilotages.general.tabs.subTabs.intern' | translate }}
                        </span>
                    </ng-template>
                    <ng-container *ngIf="internUser.isActive">
                        <h3> {{ 'pilotages.utilisateurs.table-utilisateurs-internes.title' | translate }}</h3>
                        <ng-container>
                            <app-utilisateurs-internes-table
                                [translatePrefix]="translatePrefixUtilisateurs + '.table-utilisateurs-internes'"
                                [sortOrder]="sortOrder" [pathChoice]="pathChoice"
                                [reloadData]="currentView === 'InternalUsers' ? reloadData : false"
                                (selectedModule)="setCurrentView($event)" (reloadedDone)="reloadedData()">
                            </app-utilisateurs-internes-table>
                        </ng-container>
                    </ng-container>
                </mat-tab>
                <mat-tab #externUser>
                    <ng-template mat-tab-label>
                        <i class="fa fa-users tabLogo" ></i>
                        <span>
                            {{ 'pilotages.general.tabs.subTabs.extern' | translate }}
                        </span>
                    </ng-template>
                    <ng-container *ngIf="externUser.isActive">
                        <h3> {{ 'pilotages.utilisateurs.table-utilisateurs-externes.title' | translate }}</h3>
                        <ng-container>
                            <app-utilisateurs-externes-table
                                [translatePrefix]="translatePrefixUtilisateurs + '.table-utilisateurs-externes'"
                                [sortOrder]="sortOrder" [pathChoice]="pathChoice"
                                [reloadData]="currentView === 'ExternalUsers' ? reloadData : false"
                                (selectedModule)="setCurrentView($event)" (reloadedDone)="reloadedData()">
                            </app-utilisateurs-externes-table>
                        </ng-container>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
    </mat-tab>
    <mat-tab #syncTab>
        <ng-template mat-tab-label>
            <i class="fas fa-exchange-alt tabLogo" ></i>
            <span>
                {{ 'pilotages.general.tabs.synchronisation' | translate }}
            </span>
        </ng-template>
        <ng-container *ngIf="syncTab.isActive">
            <h3> {{ 'pilotages.utilisateurs-synchronisation.title' | translate }}</h3>
            <ng-container>
                <app-etat-synchronisations-table [translatePrefix]="translatePrefixUtilisateursSynchro + '.table-utilisateurs-synchronisation'"
                [sortOrder]="sortOrder" [pathChoice]="pathChoice"
                [reloadData]="currentView === 'EtatSync' ? reloadData : false"
                (selectedModule)="setCurrentView($event)" (reloadedDone)="reloadedData()">
                </app-etat-synchronisations-table>
            </ng-container>
        </ng-container>
    </mat-tab>
    <mat-tab #gestionSAPTab>
        <ng-template mat-tab-label>
            <i class="fas fa-sitemap tabLogo"></i>
            <span>
                {{ 'pilotages.general.tabs.gestionCodeSAP' | translate }}
            </span>
        </ng-template>
        <ng-container *ngIf="gestionSAPTab.isActive">
            <mat-tab-group [animationDuration]="'0'" #gestionSAPTabs >
                <mat-tab #tabSAP1>
                    <ng-template mat-tab-label>
                        <i class="fas fa-atlas tabLogo"></i>
                        <span>
                            {{ 'pilotages.general.tabs.subTabs.associationCodeSAP' | translate }}
                        </span>
                    </ng-template>
                    <ng-container *ngIf="tabSAP1.isActive">
                        <h3> {{ 'pilotages.gestionCodesSAP.importSAP.title' | translate }}</h3>
                        <ng-container>
                            <app-gestion-codes-sap-table [translatePrefix]="translatePrefixGestionCodesSAP + '.importSAP'"
                            [sortOrder]="sortOrder" [reloadData]="currentView === 'GestionCodesSAP' ? reloadData : false"
                            [pathChoice]="pathChoice" (selectedModule)="setCurrentView($event)" (reloadedDone)="reloadedData()">
                            </app-gestion-codes-sap-table>
                        </ng-container>
                    </ng-container>
                </mat-tab>
                <mat-tab #tabSAP2>
                    <ng-template mat-tab-label>
                        <i class="fas fa-table tabLogo"></i>
                        <span>
                            {{ 'pilotages.general.tabs.subTabs.tableUnitOfWork' | translate }}
                        </span>
                    </ng-template>
                    <ng-container *ngIf="tabSAP2.isActive">
                        <h3> {{ 'pilotages.gestionCodesSAP.tableUnitOfWork.title' | translate }}</h3>
                        <ng-container>
                            <app-table-unite-travail [reloadData]="currentView === 'tableUniteTravail' ? reloadData : false"
                            [pathChoice]="pathChoice" (selectedModule)="setCurrentView($event)" (reloadedDone)="reloadedData()">
                            </app-table-unite-travail>
                        </ng-container>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
    </mat-tab>
    <mat-tab #editTimeSheetTab>
        <ng-template mat-tab-label>
            <i class="far fa-calendar-alt tabLogo"></i>
            <span>
                {{ 'pilotages.general.tabs.editTimesheet' | translate }}
            </span>
        </ng-template>
        <ng-container *ngIf="editTimeSheetTab.isActive">
            <mat-tab-group [animationDuration]="'0'" #editTimeSheetTabs >
                <mat-tab #editTS1>
                    <ng-template mat-tab-label>
                        <i class="fas fa-hashtag tabLogo"></i>
                        <span>
                            {{ 'pilotages.general.tabs.subTabs.chargeNumber' | translate }}
                        </span>
                    </ng-template>
                    <ng-container *ngIf="editTS1.isActive">
                        <h3> {{ 'pilotages.editTimesheet.chargeNumber.title' | translate }}</h3>
                        <ng-container>
                            <app-edit-timesheet-charge-number  [reloadData]="currentView === 'editTimesheetChargeNumber' ? reloadData : false"
                            [pathChoice]="pathChoice" (selectedModule)="setCurrentView($event)" (reloadedDone)="reloadedData()">
                            </app-edit-timesheet-charge-number>
                        </ng-container>
                    </ng-container>
                </mat-tab>
                <mat-tab #editTS2>
                    <ng-template mat-tab-label>
                        <i class="fas fa-list-ul tabLogo"></i>
                        <span>
                            {{ 'pilotages.general.tabs.subTabs.commandePosteType' | translate }}
                        </span>
                    </ng-template>
                    <ng-container *ngIf="editTS2.isActive">
                        <h3> {{ 'pilotages.editTimesheet.orderPostType.title' | translate }}</h3>
                        <ng-container>
                            <app-edit-timesheet-commande-poste-type [reloadData]="currentView === 'editTimesheetCommandePosteType' ? reloadData : false"
                            [pathChoice]="pathChoice" (selectedModule)="setCurrentView($event)" (reloadedDone)="reloadedData()">
                            </app-edit-timesheet-commande-poste-type>
                        </ng-container>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
    </mat-tab>
</mat-tab-group>
