import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, switchMap, map, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiService } from '../../../ogiv-core/services/api.service';
import {
    BillingSapEntriesActionType,
    LoadBillingSapEntries,
    LoadBillingSapEntriesFail,
    LoadBillingSapEntriesSuccess,
} from '../actions/billing-sap-entries.action';
import { BillingSapEntry } from '../../../shared/models/database/billing-sap-entry.model';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class BillingSapEntriesEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public loadBillingSapEntries$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingSapEntries>(BillingSapEntriesActionType.LOAD_BILLING_SAP_ENTRIES),
        switchMap(((action: LoadBillingSapEntries) => {
            return this.apiService.get<BillingSapEntry[]>(`hq/rapports/rapportsfacturation/saisiesap/${action.billingReportId}`).pipe(
                map((returnedBillingSapEntries: BillingSapEntry[]) => {
                    const mappedValues = returnedBillingSapEntries.map(x => new BillingSapEntry(x));
                    return new LoadBillingSapEntriesSuccess(mappedValues);
                }),
                catchError((error: any) => of(new LoadBillingSapEntriesFail(error))),
            );
        }),
        )));

    public loadBillingReportsFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingSapEntriesFail>(BillingSapEntriesActionType.LOAD_BILLING_SAP_ENTRIES_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'billing.notifications.load.error',
            }))
        ]),
    ));
}
