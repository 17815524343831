import { Injectable } from '@angular/core';
import { CanActivate, Params } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap, map, filter, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { getRouterStateUrl } from '../../../../core/store/selectors';
import { LoadTimesheetReportById, LoadTimesheetReportByIdAndSiteName } from '../../../store/actions';
import { shouldLoadGivenTimesheetReport } from '../../../store/selectors/timesheet-reports.selector';
import { AlternativePath } from '../../../../shared/models/atlernative-path.model';
import { RouterStateUrl } from '../../../../core/store/models';

interface ShouldLoadTimesheetReport {
    shouldLoad: boolean;
    timesheetReportId: string;
    siteName?: string;
}

@Injectable()
export class LoadTimesheetReportGuard implements CanActivate {
    constructor(
        private readonly store: Store,
    ) { }

    public canActivate(): Observable<boolean> {
        let pathChoice = AlternativePath.ent;

        return this.store.pipe(
            select(getRouterStateUrl),
        ).pipe(
            tap((state: RouterStateUrl) => {
                if (state && state.data && state.data.pathChoice) {
                    pathChoice = state.data.pathChoice;
                }
            }),
            map((state: RouterStateUrl) => state && state.params || {}),
            switchMap((params: Params) => this.store.pipe(
                select(shouldLoadGivenTimesheetReport({ timesheetReportId: params.timesheetReportId })),
                map((shouldLoad: boolean) => ({ shouldLoad, timesheetReportId: params.timesheetReportId, siteName: (params.siteName || null) })),
            )),
            tap((currentState: ShouldLoadTimesheetReport) => {
                if (pathChoice === AlternativePath.hq) {
                    currentState.siteName = currentState.siteName || 'undefined';
                }

                return currentState;
            }),
            map(({ shouldLoad, timesheetReportId, siteName }: ShouldLoadTimesheetReport) => {
                if (timesheetReportId && shouldLoad) {
                    if (siteName) {
                        this.store.dispatch(new LoadTimesheetReportByIdAndSiteName(timesheetReportId, siteName, pathChoice));
                    } else {
                        this.store.dispatch(new LoadTimesheetReportById(timesheetReportId, pathChoice));
                    }
                }

                return !shouldLoad;
            }),
            filter(hasLoaded => hasLoaded),
            take(1),
        );
    }
}
