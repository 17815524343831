import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { TimesheetReport } from '../../../../../shared/models/database/timesheet-report.model';
import { TableColumns } from '../../../../../shared/models/table-columns.model';
import {
    ApproveTimesheetReport,
    ApproveTimesheetReportTech,
    RefuseTimesheetReport,
    RefuseTimesheetReportTech,
    RemarkTimesheetReport
} from '../../../../store/actions/timesheet-reports.action';
import { getCurrentTimesheetReport, getTimesheetReportsLoading } from '../../../../store/selectors';
import { CommentFormModalComponent } from '../../../../../shared/components/comment-form-modal/comment-form-modal.component';
import { Back } from '../../../../../core/store/actions';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { getRouterData } from '../../../../../core/store/selectors';
import { TimesheetReportStatus } from '../../../../../shared/models/database/timesheet-report-status.enum';
import { CommentFormModalData } from '../../../../../shared/components/comment-form-modal/comment-form-modal-data.model';

@Component({
    selector: 'app-timesheet-report',
    templateUrl: './timesheet-report.component.html',
    styleUrls: ['./timesheet-report.component.scss'],
})
export class TimesheetReportComponent implements OnInit {
    public disableApprove = false;
    public disableRefuse = false;
    public timesheetError: string[] = [];

    public timesheetReport$: Observable<TimesheetReport | null> = this.store.pipe(
        select(getCurrentTimesheetReport),
        distinctUntilChanged(),
        tap((report: TimesheetReport) => {
            if (report && report.errors !== undefined && report.errors !== null) {
                this.timesheetError = [];
                report.errors.forEach((error: string) => {
                    this.manageError(error);
                });
            }
        })
    );

    public timesheetReportLoading$: Observable<boolean> = this.store.pipe(
        select(getTimesheetReportsLoading),
    );

    public pathChoice: AlternativePath;
    public translatePrefix = 'timesheet.reports.detail';
    public teamTranslatePrefix = '.team';
    public transportTranslatePrefix = '.transport';
    public mealTranslatePrefix = '.meal';
    public equipmentTranslatePrefix = '.equipment';
    public workUnitTranslatePrefix = '.workUnit';

    public teamCols: TableColumns[] = [
        { field: 'jobClassDescr', header: 'description' },
        { field: 'worker', header: 'worker' },
        { field: 'jobSAPCode', header: 'code', type: 'code', toolTipField: 'jobSAPDescr' },
        { field: 'day0', header: 'sunday', type: 'weekDay', noFilter: true, toolTipField: 'jobSAPDescr' },
        { field: 'day1', header: 'monday', type: 'weekDay', noFilter: true, toolTipField: 'jobSAPDescr' },
        { field: 'day2', header: 'tuesday', type: 'weekDay', noFilter: true, toolTipField: 'jobSAPDescr' },
        { field: 'day3', header: 'wednesday', type: 'weekDay', noFilter: true, toolTipField: 'jobSAPDescr' },
        { field: 'day4', header: 'thursday', type: 'weekDay', noFilter: true, toolTipField: 'jobSAPDescr' },
        { field: 'day5', header: 'friday', type: 'weekDay', noFilter: true, toolTipField: 'jobSAPDescr' },
        { field: 'day6', header: 'saturday', type: 'weekDay', noFilter: true, toolTipField: 'jobSAPDescr' },
    ];

    public transportCols: TableColumns[] = [
        { field: 'jobClassDescr', header: 'description' },
        { field: 'worker', header: 'worker' },
        { field: 'code', header: 'code', type: 'code', toolTipField: 'description' },
        { field: 'day0', header: 'sunday', type: 'weekDay', noFilter: true },
        { field: 'day1', header: 'monday', type: 'weekDay', noFilter: true },
        { field: 'day2', header: 'tuesday', type: 'weekDay', noFilter: true },
        { field: 'day3', header: 'wednesday', type: 'weekDay', noFilter: true },
        { field: 'day4', header: 'thursday', type: 'weekDay', noFilter: true },
        { field: 'day5', header: 'friday', type: 'weekDay', noFilter: true },
        { field: 'day6', header: 'saturday', type: 'weekDay', noFilter: true },
    ];

    public mealCols: TableColumns[] = [
        { field: 'jobClassDescr', header: 'description' },
        { field: 'worker', header: 'worker' },
        { field: 'code', header: 'code', type: 'code', toolTipField: 'description' },
        { field: 'day0', header: 'sunday', type: 'weekDay', noFilter: true },
        { field: 'day1', header: 'monday', type: 'weekDay', noFilter: true },
        { field: 'day2', header: 'tuesday', type: 'weekDay', noFilter: true },
        { field: 'day3', header: 'wednesday', type: 'weekDay', noFilter: true },
        { field: 'day4', header: 'thursday', type: 'weekDay', noFilter: true },
        { field: 'day5', header: 'friday', type: 'weekDay', noFilter: true },
        { field: 'day6', header: 'saturday', type: 'weekDay', noFilter: true },
    ];

    public equipmentCols: TableColumns[] = [
        { field: 'number', header: 'number' },
        { field: 'description', header: 'description' },
        { field: 'code', header: 'code', type: 'code', toolTipField: 'description' },
        { field: 'day0', header: 'sunday', type: 'weekDay', noFilter: true, toolTipField: 'description' },
        { field: 'day1', header: 'monday', type: 'weekDay', noFilter: true, toolTipField: 'description' },
        { field: 'day2', header: 'tuesday', type: 'weekDay', noFilter: true, toolTipField: 'description' },
        { field: 'day3', header: 'wednesday', type: 'weekDay', noFilter: true, toolTipField: 'description' },
        { field: 'day4', header: 'thursday', type: 'weekDay', noFilter: true, toolTipField: 'description' },
        { field: 'day5', header: 'friday', type: 'weekDay', noFilter: true, toolTipField: 'description' },
        { field: 'day6', header: 'saturday', type: 'weekDay', noFilter: true, toolTipField: 'description' },
    ];

    public workUnitCols: TableColumns[] = [
        { field: 'day', header: 'day', noFilter: true, small: true },
        { field: 'month', header: 'month', noFilter: true, small: true },
        { field: 'number', header: 'order', noFilter: true },
        { field: 'accountingEntry', header: 'accountingEntry', noFilter: true },
        { field: 'chargeNumber', header: 'posting', noFilter: true },
        { field: 'outageNumber', header: 'outageNumber', noFilter: true },
        { field: 'postElect', header: 'linePost', noFilter: true },
        { field: 'line', header: 'line', noFilter: true },
        { field: 'code', header: 'code', noFilter: true, toolTipField: 'description' },
        { field: 'detail', header: 'detail', noFilter: true },
        { field: 'quantity', header: 'quantity', noFilter: true, columnTotal: true },
        { field: 'hours', header: 'hours', noFilter: true, columnTotal: true },
    ];

    public monthMapping = {
        1: { text: 'janvier' },
        2: { text: 'février' },
        3: { text: 'mars' },
        4: { text: 'avril' },
        5: { text: 'mai' },
        6: { text: 'juin' },
        7: { text: 'Juillet' },
        8: { text: 'août' },
        9: { text: 'septembre' },
        10: { text: 'octobre' },
        11: { text: 'novembre' },
        12: { text: 'décembre' },

    };

    constructor(
        public dialog: MatDialog,
        private readonly store: Store,
    ) { }

    public ngOnInit() {
        this.store.pipe(select(getRouterData)).subscribe((data: any) => {
            this.pathChoice = data && data.pathChoice;
        });
    }

    public manageError(error: string) {
        if (!this.timesheetError.includes(error)) {
            this.timesheetError.push(error);
        }
    }

    public approveTimesheetReport(timesheetReport: TimesheetReport): void {
        if (this.pathChoice === AlternativePath.ent) {
            this.store.dispatch(new ApproveTimesheetReport(timesheetReport.id, this.pathChoice));
        } else {
            const siteName = timesheetReport.site || 'undefined';
            this.store.dispatch(new ApproveTimesheetReportTech(timesheetReport.id, siteName, this.pathChoice));
        }
    }

    public refuseTimesheetReport(timesheetReport: TimesheetReport): void {
        const commentDialog = this.dialog.open(CommentFormModalComponent, {
            maxWidth: '550px',
            width: '550px',
            data: {
                isHq: this.pathChoice === AlternativePath.hq,
                weekdaysStatus: timesheetReport.weekdaysStatus,
                maxLength: 1000
            } as CommentFormModalData,
        });

        commentDialog.afterClosed().subscribe((commentObj: { rejectedDays: string[], comment: string } | string) => {
            if (commentObj) {
                if (this.pathChoice === AlternativePath.ent) {
                    if (typeof commentObj !== 'string') {
                        this.store.dispatch(new RefuseTimesheetReport(timesheetReport.id, commentObj.comment, commentObj.rejectedDays, this.pathChoice));
                    }
                } else {
                    const siteName = timesheetReport.site || 'undefined';

                    if (typeof commentObj === 'string') {
                        this.store.dispatch(new RefuseTimesheetReportTech(timesheetReport.id, commentObj, siteName, this.pathChoice));
                    }
                }
            }
        });
    }

    public backToReport() {
        this.store.dispatch(new Back());
    }

    public isEnt(): boolean {
        return this.pathChoice === AlternativePath.ent;
    }

    public isHq(): boolean {
        return this.pathChoice === AlternativePath.hq;
    }

    public onRemarkSaved(remark: string, reportId: number): void {
        this.store.dispatch(new RemarkTimesheetReport(reportId, this.pathChoice, remark));
    }

    public disableApproveButton(timesheetReport: TimesheetReport): boolean {
        const status = timesheetReport.status;

        if (this.pathChoice === AlternativePath.ent) {
            return (timesheetReport.errors && timesheetReport.errors.length > 0) ||
                (status !== null && status !== TimesheetReportStatus.créé && status !== TimesheetReportStatus.modifié
                    && status !== TimesheetReportStatus.refuséParEnt && status !== TimesheetReportStatus.refuséParHQ);
        } else {
            return ((timesheetReport.errors && timesheetReport.errors.length > 0) ||
                (status !== null && status !== TimesheetReportStatus.approuvéParEnt && status !== TimesheetReportStatus.refuséParHQ));
        }
    }

    public disableRefuseButton(timesheetReport: TimesheetReport): boolean {
        const status = timesheetReport.status;
        // Dans le cas de rapport de fdt ent, on disable si le rapport est associé à un rapport de facturation
        // ou le statut est Approuvé par HQ
        if (this.pathChoice === AlternativePath.ent) {
            return (timesheetReport.invoiceReportId !== null || (status !== null && status === TimesheetReportStatus.approuvéParHQ));
            // Dans le cas de rapport de fdt hq, on disable si le rapport est associé à un rapport de facuration ou si le statut
            // n'est Approuvé par HQ, Approuvé par Ent ou Refusé par HQ
        } else {
            return (timesheetReport.invoiceReportId !== null || (status !== null &&
                ![TimesheetReportStatus.approuvéParHQ, TimesheetReportStatus.approuvéParEnt, TimesheetReportStatus.refuséParHQ].includes(status)));
        }
    }
}
