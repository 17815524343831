import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { JobCode } from '../../../shared/models/database/job-code.model';

/**
 * Types
 */
export enum JobCodeActionType {
    LOAD_JOB_CODES = '[JobCode] Load JobCodes',
    LOAD_JOB_CODES_SUCCESS = '[JobCode] Load JobCodes Success',
    LOAD_JOB_CODES_FAIL = '[JobCode] Load JobCodes Fail',
    LOAD_JOB_CODES_NO_RESULTS = '[JobCode] Load JobCodes No Result',

    CREATE_JOB_CODE = '[JobCode] Create JobCode',
    CREATE_JOB_CODE_SUCCESS = '[JobCode] Create JobCode Success',
    CREATE_JOB_CODE_FAIL = '[JobCode] Create JobCode Fail',

    UPDATE_ONE_JOB_CODE = '[JobCode] Update One JobCode',
    UPDATE_ONE_JOB_CODE_SUCCESS = '[JobCode] Update One JobCode Success',
    UPDATE_ONE_JOB_CODE_FAIL = '[JobCode] Update One JobCode Fail',
}

/**
 * ACTIONS
 */
/**
 * CREATE
 */
export class CreateJobCode implements Action {
    readonly type = JobCodeActionType.CREATE_JOB_CODE;
    constructor(public jobCode: JobCode, public path: AlternativePath) { }
}

export class CreateJobCodeSuccess implements Action {
    readonly type = JobCodeActionType.CREATE_JOB_CODE_SUCCESS;
    constructor(public jobCode: JobCode) { }
}

export class CreateJobCodeFail implements Action {
    readonly type = JobCodeActionType.CREATE_JOB_CODE_FAIL;
    constructor(public error: any) { }
}

/**
 * LOAD
 */
export class LoadJobCodes implements Action {
    readonly type = JobCodeActionType.LOAD_JOB_CODES;
    constructor() { }
}

export class LoadJobCodesNoResult implements Action {
    readonly type = JobCodeActionType.LOAD_JOB_CODES_NO_RESULTS;
}

export class LoadJobCodesSuccess implements Action {
    readonly type = JobCodeActionType.LOAD_JOB_CODES_SUCCESS;
    constructor(public jobCodes: JobCode[]) { }
}

export class LoadJobCodesFail implements Action {
    readonly type = JobCodeActionType.LOAD_JOB_CODES_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneJobCode implements Action {
    readonly type = JobCodeActionType.UPDATE_ONE_JOB_CODE;
    constructor(public jobCode: JobCode, public path: AlternativePath) { }
}

export class UpdateOneJobCodeSuccess implements Action {
    readonly type = JobCodeActionType.UPDATE_ONE_JOB_CODE_SUCCESS;
    constructor(public jobCode: JobCode) { }
}

export class UpdateOneJobCodeFail implements Action {
    readonly type = JobCodeActionType.UPDATE_ONE_JOB_CODE_FAIL;
    constructor(public error: any) { }
}

export type JobCodeAction =
    | CreateJobCode
    | CreateJobCodeSuccess
    | CreateJobCodeFail
    | LoadJobCodes
    | LoadJobCodesNoResult
    | LoadJobCodesSuccess
    | LoadJobCodesFail
    | UpdateOneJobCode
    | UpdateOneJobCodeSuccess
    | UpdateOneJobCodeFail;
