import { createSelector } from '@ngrx/store';

import { BillingState, getBillingState } from '../reducers';
import { billingReportPreviewAdapter, BillingReportPreviewState } from '../reducers/billing-report-preview.reducer';

const {
    selectAll
} = billingReportPreviewAdapter.getSelectors();

export const getBillingReportPreviewState = createSelector(
    getBillingState,
    (state: BillingState) => state.billingReportPreview,
);

export const getBillingReportPreviewLoading = createSelector(
    getBillingReportPreviewState,
    (state: BillingReportPreviewState) => state.loading,
);

export const getBillingReportPreviewLoaded = createSelector(
    getBillingReportPreviewState,
    (state: BillingReportPreviewState) => state.loaded,
);

export const getBillingReportPreview = createSelector(
    getBillingReportPreviewState,
    selectAll
);
