import { Component } from '@angular/core';

@Component({
    selector: 'app-reports',
    template: `<router-outlet></router-outlet>`,
    styleUrls: ['./reports.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'reportsComponent',
    }
})

export class ReportsComponent { }
