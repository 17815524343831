import { Component, Output, EventEmitter, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
    selector: 'app-generic-detail-page-header',
    templateUrl: './generic-detail-page-header.component.html',
    styleUrls: ['./generic-detail-page-header.component.scss'],
})
export class GenericDetailPageHeaderComponent implements AfterViewInit {
    @Input() public pageLogo = '';
    @Input() public pageTitle = 'Changer le title';
    @Input() public subtitle = '';
    @Input() public buttonLogo = 'fas fa-chevron-left';
    @Input() public buttonTitle = 'RETOUR';
    @Input() public hideButton = false;

    @Output() public back: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('button') button: ElementRef<HTMLElement>;

    constructor(private _focusMonitor: FocusMonitor) { }

    ngAfterViewInit() {
        this._focusMonitor.stopMonitoring(this.button);
    }

    public backCLick(): void {
        this.back.emit(true);
    }
}
