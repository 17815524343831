import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';

import { TimesheetState } from '../../../../store/reducers';
import { CreateJobClass } from '../../../../store/actions';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';

@Component({
    selector: 'app-job-class-form',
    templateUrl: './job-class-form.component.html',
    styleUrls: ['./job-class-form.component.scss'],
})

export class JobClassFormComponent implements OnChanges {
    public jobClassForm: FormGroup;
    public translatePrefix = 'timesheet.order.tab.accordionTitle.team.createForm';
    public tabIndex = 0;

    @Input() public otherCancelIcon = '';
    @Input() public freezeTabIndex = false;
    @Input() public pathChoice: AlternativePath;

    @Output() public create: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        formBuilder: FormBuilder,
        private readonly store: Store<TimesheetState>,
    ) {
        this.jobClassForm = formBuilder.group({
            id: null,
            code: ['', Validators.required],
            description: ['', Validators.required],
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.freezeTabIndex && changes.freezeTabIndex.currentValue !== undefined && changes.freezeTabIndex.currentValue !== null) {
            this.tabIndex = changes.freezeTabIndex.currentValue ? -1 : 0;
        }
    }

    public cancelForm(): void {
        this.tabIndex = !this.freezeTabIndex ? -1 : 0;
        this.jobClassForm.reset();
        this.cancel.emit(true);
    }

    public createJobClass(): void {
        const { valid } = this.jobClassForm;

        const value = {
            ...this.jobClassForm.value
        };

        if (valid) {
            const { id, ...cleanJobClass } = value;
            this.store.dispatch(new CreateJobClass(cleanJobClass, this.pathChoice));

            this.tabIndex = !this.freezeTabIndex ? -1 : 0;
            this.create.emit(true);
            this.jobClassForm.reset();
        }
    }
}
