import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DemandesClientOperationData } from './demande-client-operation-data.model';
import { OPERATION_COLS } from './demande-client-operation.column';

@Component({
    selector: 'app-demandes-client-operation',
    templateUrl: './demande-client-operation.component.html',
    styleUrls: ['./demande-client-operation.component.scss'],
})
export class DemandesClientOperationComponent {
    public currentDemande: any | undefined;
    public commonTranslatePrefix: string = '';
    public operationsCols = OPERATION_COLS;

    constructor(
        public dialogRef: MatDialogRef<DemandesClientOperationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DemandesClientOperationData
    ) {
        this.currentDemande = data.currentDemande;
        this.commonTranslatePrefix = data.commonTranslatePrefix;
    }
}
