<app-element-loader *ngIf="tableCodesUniteLoading$ | async"
    [size]="loaderLogoSize"
    [displayLabel]="true"
    [labelFontSize]="20"></app-element-loader>

<p-table #codeUniteDataTable [columns]="columns" [value]="$any(tableCodesUnite$ | async)" [paginator]="true" [rows]="10" editMode="row"
    dataKey="id"[sortField]="sortField" [sortOrder]="sortOrder" [rowHover]="true" [autoLayout]="true"
    currentPageReportTemplate="Affiche de {first} à {last} sur {totalRecords} items" [showCurrentPageReport]="true"
    (onFilter)="restructureObjects($event)" [rowsPerPageOptions]="[10,25,50,100,200]">

    <ng-template pTemplate="caption">
        <div class="table__header">
            <div class="header__add">
                <button pButton icon="{{addLogo}}" class="ui-button-raised" (click)="addUniteOfWork()"
                    [label]="translatePrefix + 'buttons.add' | translate"></button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <div class="header__shape">
                    <span [ngStyle]="col.width && col.width !== '' ? { 'width': col.width } : { 'width': 'unset' } ">
                        {{ translatePrefix + 'columns.' +col.header | translate }}
                    </span>

                    <p-sortIcon class="shape__sort" [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                        [attr.aria-label-desc]="'common.sortDesc' | translate"
                        [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
                </div>
            </th>
            <th class="sticky-column">{{ 'common.actions' | translate }}</th>
        </tr>
        <tr>
            <th *ngFor="let col of columns" [ngSwitch]="true" [ngStyle]="{'vertical-align': 'middle'}">
                <div *ngSwitchCase="col.type === 'dropdown'" [ngStyle]="col.width && col.width !== '' ? { 'width': col.width } : { 'width': 'fit-content' } ">
                    <div *ngIf="col.field === 'statut'">
                        <p-dropdown appendTo="body" [options]="filterStatuses" autoWidth="false" [style]="{'width':'100%'}"
                            [ngModel]="selectedStatus" (onChange)="columnFilter($event.value, 'statut', 'equals')"
                            styleClass="p-column-filter" [placeholder]="this.translatePrefix + 'statuses.placeholder' | translate"
                            [showClear]="true">
                            <ng-template let-option pTemplate="item">
                                <span>{{option.label}}</span>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div *ngIf="col.field === 'type_cycle'">
                        <p-dropdown appendTo="body" [options]="typeCycles" autoWidth="false" [style]="{'width':'100%'}"
                            [ngModel]="selectedCycleType" (onChange)="columnFilter($event.value, 'type_cycle', 'equals')"
                            styleClass="p-column-filter" [placeholder]="this.translatePrefix + 'typeCycle.placeholder' | translate"
                            [showClear]="true">
                            <ng-template let-option pTemplate="item">
                                <span>{{option.label}}</span>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div *ngSwitchCase="col.field === 'modifie_le'" style="width: 105px;">
                    <p-calendar
                        [(ngModel)]="dateFiltersModifieLe"
                        appendTo="body"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="translatePrefix + 'date-filter' | translate"
                        (onClearClick)="columnFilter($event, col.field, 'dateRangeFilterModifieLe')"
                        (onSelect)="columnFilter($event, col.field, 'dateRangeFilterModifieLe')">
                    </p-calendar>
                </div>
                <div *ngSwitchCase="col.field === 'cree_le'" style="width: 105px;">
                    <p-calendar
                        [(ngModel)]="dateFiltersCreeLe"
                        appendTo="body"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="translatePrefix + 'date-filter' | translate"
                        (onClearClick)="columnFilter($event, col.field, 'dateRangeFilterCreeLe')"
                        (onSelect)="columnFilter($event, col.field, 'dateRangeFilterCreeLe')">
                    </p-calendar>
                </div>
                <div *ngSwitchCase="col.type === 'checkbox'" class="default">
                    <mat-checkbox #filterCheckbob
                        class="default__checkbox"
                        [ngClass]="filterCheckbob.checked ? 'matCheck' : 'matUncheck'"
                        [indeterminate]="false"
                        (change)="checkboxFilter($event.checked, col.field, 'equals')">
                    </mat-checkbox>
                </div>
                <div *ngSwitchDefault>
                    <input pInputText type="text" styleClass="p-column-filter"
                        (input)="inputFilterColumn($event, col.field, 'contains')"
                        [placeholder]="'common.filter' | translate"
                        [attr.aria-placeholder]="'common.filter' | translate">
                </div>
            </th>
            <th class="sticky-column">
                <div class="sticky-column__container"></div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length+1">
                <h3>
                    {{translatePrefix + 'emptyMessage' | translate}}
                </h3>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns" let-editing="editing">
        <tr [pEditableRow]="rowData">
            <td *ngFor="let col of columns; trackBy: trackByColumnField" class="bodyTD">
                <ng-container [ngSwitch]="true">
                    <ng-container *ngSwitchCase="col.type === 'string'">
                        <p-cellEditor *ngIf="col.isEditable === undefined || col.isEditable">
                            <ng-template pTemplate="input">
                                <ng-container *ngIf="col.type === 'string' && col.field === 'nature_comptable'">
                                    <input pInputText
                                        type="number"
                                        min="1"
                                        [ngModel]="rowData[col.field]"
                                        (ngModelChange)="saveInput($event, rowData, col.field)">
                                        <small *ngIf="isInputNatureComptableErrror" id="nature_comptable-id-error" class="p-invalid">{{translatePrefix + 'error.inputNatureComptable' | translate}}</small>
                                </ng-container>
                                <ng-container *ngIf="col.type === 'string' && col.field !== 'nature_comptable'">
                                    <input pInputText
                                        type="text"
                                        [ngModel]="rowData[col.field]"
                                        (ngModelChange)="saveInput($event, rowData, col.field)">
                                </ng-container>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData[col.field]}}
                            </ng-template>
                        </p-cellEditor>
                        <ng-container *ngIf="col.isEditable !== undefined && !col.isEditable">
                            {{rowData[col.field]}}
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="col.type === 'dropdown'">
                        <p-cellEditor *ngIf="col.isEditable === undefined || col.isEditable">
                            <ng-template pTemplate="input">
                                <ng-container *ngIf="col.field === 'type_cycle'">
                                    <p-dropdown appendTo="body" [options]="typeCycles" autoWidth="false" [style]="{'width':'165px'}"
                                        [ngModel]="rowData[col.field]" (onChange)="saveDropdown($event.value, rowData, col.field)"
                                        [placeholder]="this.translatePrefix + 'typeCycle.placeholder' | translate">
                                        <ng-template let-item pTemplate="selectedItem">
                                            <span style="vertical-align:middle">{{item.title}}</span>
                                        </ng-template>
                                        <ng-template let-option pTemplate="item">
                                            <span>{{option.label}}</span>
                                        </ng-template>
                                    </p-dropdown>
                                </ng-container>
                                <ng-container *ngIf="col.field === 'statut'">
                                    <p-dropdown appendTo="body" #editedDropdown [options]="statuses" autoWidth="false" [style]="{'width':'100%'}"
                                        [selectId]="'dropdown-status-' + rowData.id" [ngModel]="rowData[col.field]"
                                        (onChange)="saveDropdownStatus($event.value, rowData, col.field)"
                                        [placeholder]="this.translatePrefix + 'statuses.placeholder' | translate">
                                        <ng-template let-option pTemplate="item">
                                            <span>{{option.label}}</span>
                                        </ng-template>
                                    </p-dropdown>
                                </ng-container>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData[col.field]}}
                            </ng-template>
                        </p-cellEditor>
                    </ng-container>
                    <ng-container *ngSwitchCase="col.type === 'date'">
                        {{ rowData[col.field] && !rowData[col.field].includes('1900') ? (rowData[col.field] | date: col.format) : '' }}
                    </ng-container>
                    <ng-container *ngSwitchCase="col.type === 'checkbox'">
                        <div class="bodyTD__cellEditorContainer">
                            <p-cellEditor class="cellEditorContainer__cellEditor">
                                <ng-template pTemplate="input">
                                    <mat-checkbox #editeCheckbox
                                        id="checkbox-{{col.field}}-{{rowData.id}}"
                                        class="centerContent"
                                        [ngModel]="rowData[col.field]"
                                        [ngClass]="rowData[col.field] ? 'matCheck' : 'matUncheck'"
                                        [indeterminate]="false"
                                        (change)="saveCheckbox($event, rowData, col.field)">
                                    </mat-checkbox>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <mat-checkbox
                                        [(ngModel)]="rowData[col.field]"
                                        class="centerContent"
                                        [ngClass]="rowData[col.field] ? 'matCheck' : 'matUncheck'"
                                        [indeterminate]="false"
                                        [disabled]="true">
                                    </mat-checkbox>
                                </ng-template>
                            </p-cellEditor>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <div class="bodyTD__hCenterText">
                            {{rowData[col.field]}}
                        </div>
                    </ng-container>
                </ng-container>
            </td>
            <td class="sticky-column">
                <div class="sticky-column__container">
                    <app-generic-other-menu *ngIf="!editing"
                        class="container__center"
                        [editOnly]="true"
                        [addborder]="true"
                        (edit)="onRowEditInit(rowData,  $event)">
                    </app-generic-other-menu>
                    <app-generic-other-menu *ngIf="editing"
                        class="container__left"
                        [mySingleButtonOnly]="true"
                        [singleButtonIcon]="cancelEdit"
                        [singleButtonType]="'button'"
                        [addborder]="true"
                        [isRedButton]="true"
                        (singleButtonPressed)="onRowEditCancel(rowData, $event)">
                    </app-generic-other-menu>
                    <app-generic-other-menu *ngIf="editing"
                        class="container__right"
                        [mySingleButtonOnly]="true"
                        [singleButtonIcon]="checkLogo"
                        [singleButtonType]="'button'"
                        [addborder]="true"
                        [disabledButton]="disabledCheckButton"
                        (singleButtonPressed)="onRowEditSave(rowData, $event)">
                    </app-generic-other-menu>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
