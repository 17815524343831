import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { OpenIdAuthGuard } from '@ngxhq/security';

import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { GlobalErrorComponent } from './core/errors';
import { AlternativePath } from './shared/models/atlernative-path.model';
import { PageSelector } from './shared/models/page-selector.model';
import { LoadPageGuard } from './guards/page-selector.guard';
import { RoleGuard } from './core/guards/role.guard';
import { UserRole } from './shared/models/user-roles.model';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'accueil',
    },
    {
        path: 'accueil',
        component: HomeComponent,
        canActivate: [OpenIdAuthGuard],
    },


    /**
     *  Lazy loaded modules
     *
     *  Pour les modules qui ont plusieurs pages telque rapport et facturation, le RouteGuard "LoadPageGuard"
     *  vérifie si l'un des "PageSelector" est active. si oui on active le lien. Mais il faut également faire
     *  cette vérification dans les PATH des enfants. (Voir "timesheet-report-routing.module.ts")
     */
    {
        path: 'facturation',
        data: {
            pageSelector: [PageSelector.billingEnt, PageSelector.billingHQ],
            roles: [UserRole.support, UserRole.pilote, UserRole.entrepreneur, UserRole.techinter, UserRole.planificateur, UserRole.cgc]
        },
        loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
        canActivate: [OpenIdAuthGuard, LoadPageGuard, RoleGuard]
    },
    {
        path: 'rapports',
        data: {
            pageSelector: [PageSelector.reportEnt, PageSelector.reportHQ, PageSelector.progressReport],
            roles: [UserRole.support, UserRole.pilote, UserRole.chefgroupe, UserRole.entrepreneur,
            UserRole.techinter, UserRole.tech, UserRole.planificateur, UserRole.cgc]
        },
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [OpenIdAuthGuard, LoadPageGuard, RoleGuard]
    },
    {
        path: 'temps',
        data: {
            pathChoice: AlternativePath.ent,
            pageSelector: [PageSelector.timesheet],
            roles: [UserRole.support, UserRole.pilote, UserRole.chefequipe, UserRole.chefgroupe, UserRole.entrepreneur],
        },
        loadChildren: () => import('./timesheets/timesheets.module').then(m => m.TimesheetsModule),
        canActivate: [OpenIdAuthGuard, LoadPageGuard, RoleGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'demandeClient',
        data: {
            pageSelector: [PageSelector.clientRequest],
            roles: [UserRole.pilote, UserRole.techinter, UserRole.tech, UserRole.planificateur],
        },
        loadChildren: () => import('./demandes-client/demandes-client.module').then(m => m.DemandesClientModule),
        canActivate: [OpenIdAuthGuard, LoadPageGuard, RoleGuard]
    },
    {
        path: 'demandesProjet',
        data: {
            pageSelector: [PageSelector.projectRequest],
            roles: [UserRole.pilote, UserRole.techinter, UserRole.tech, UserRole.planificateur],
        },
        loadChildren: () => import('./demandes-projet/demandes-projet.module').then(m => m.DemandesProjetModule),
        canActivate: [OpenIdAuthGuard, LoadPageGuard, RoleGuard]
    },
    {
        path: 'specialMeasures',
        data: {
            pageSelector: [PageSelector.specialMeasures],
            roles: [UserRole.pilote, UserRole.techinter, UserRole.tech, UserRole.planificateur],
        },
        loadChildren: () => import('./special-measures/special-measures.module').then(m => m.SpecialMeasuresModule),
        canActivate: [OpenIdAuthGuard, LoadPageGuard, RoleGuard]
    },
    {
        path: 'pilotages',
        data: {
            pageSelector: [PageSelector.pilotage],
            roles: [UserRole.pilote],
        },
        loadChildren: () => import('./pilotages/pilotages.module').then(m => m.PilotagesModule),
        canActivate: [OpenIdAuthGuard, LoadPageGuard, RoleGuard]
    },

    // Logout
    { path: 'deconnexion', component: LogoutComponent },

    // Misc
    { path: 'erreur', component: GlobalErrorComponent, data: { layout: 'fullscreen' } },
    { path: 'erreur/http/:httpCode', component: GlobalErrorComponent, data: { layout: 'fullscreen' } },

    // Default
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
        RouterModule,
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload',
        })
    ],
    exports: [
        RouterModule,
    ],
    declarations: [],
    providers: [],
})
export class AppRoutingModule { }
