<!-- <div class="header" [class]="orderStatus" [class.refused]="displayRefuse"> -->
<div class="header" [class]="status" [class.isLock]="isLock">
    <i class="{{ headerLogo }} header__logo"></i>
    <div class="header__title">
        {{ headerText | translate }}
    </div>
    <app-info *ngIf="displayBubbleTask" class="header__info"
        [status]="rawStatus"
        [logo]="'fas fa-clipboard-check'"
        [number]="taskValue"
        [isLock]="isLock"></app-info>
    <!--
        NE PAS EFFACER
     -->
    <!-- <app-info *ngIf="displayBubbleTime" class="header__hour" [logo]="'far fa-clock'" [number]="timeValue"></app-info> -->
</div>
