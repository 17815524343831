import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { CommonUiModule } from '@ngxhq/common-ui';
import { ThemeHqCommonUiSupportModule } from '@ngxhq/theme-ngxhq';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { BillingRoutingModule } from './billing-routing.module';
import { billingComponents } from './components';
import { BILLING_REDUCER_TOKEN, getBillingReducers } from './store';
import { billingStoreEffects } from './store/effects';
import { billingVendorsModules } from './billing-vendors-modules';
import { ReportsModule } from '../reports/reports.module';
import { ConfirmationService } from 'primeng/api';
import { billingReportGuards } from './guards';

@NgModule({
    imports: [
        CommonModule,
        CommonUiModule,
        SharedModule,
        ThemeHqCommonUiSupportModule,
        TranslateModule,
        ReactiveFormsModule,
        ReportsModule,

        // Vendors
        ...billingVendorsModules,

        // Routing
        BillingRoutingModule,

        // Store
        StoreModule.forFeature('billing', BILLING_REDUCER_TOKEN),
        EffectsModule.forFeature(billingStoreEffects),
    ],
    declarations: [
        ...billingComponents,
    ],
    exports: [
        ...billingComponents,
    ],
    providers: [
        ...billingReportGuards,
        {
            provide: BILLING_REDUCER_TOKEN,
            useFactory: getBillingReducers,
        },
        ConfirmationService
    ]
})
export class BillingModule {
}
