import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { TableColumns } from '../../../../../shared/models/table-columns.model';
import { profilFormationColumns } from './profil-formation-utilisateur-modal.column';
import { FormationUtilisateur, UtilisateurRequest } from '../../../../models/profil-formation-utilisateur.model';
import { LoadProfilFormationUtilisateur } from '../../../../store/actions';
import { PilotagesState } from '../../../../store/reducers';
import { getAllFormationsUtilisateur, getProfilFormationUtilisateurLoading } from '../../../../store/selectors';
import { ProfilFormationUtilisateurModalData } from './profil-formation-utilisateur-modal-data.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-profil-formation-utilisateur-modal',
    templateUrl: './profil-formation-utilisateur-modal.component.html',
    styleUrls: ['./profil-formation-utilisateur-modal.component.scss']
})
export class ProfilFormationUtilisateurModalComponent implements OnInit {
    public profilFormationUtilisateurLoading$: Observable<boolean> = this.store.pipe(
        select(getProfilFormationUtilisateurLoading),
    );

    public profilFormationUtilisateur$: Observable<FormationUtilisateur[] | null> = this.store.pipe(
        select(getAllFormationsUtilisateur),
        distinctUntilChanged(),
    );

    public title: string;
    public informationsUtilisateursFormation: UtilisateurRequest[];
    public translatePrefix: string;
    public logoString: string | null;

    public tableData: FormationUtilisateur[];
    public profilFormationColumns: TableColumns[] = profilFormationColumns;
    public sortOrder = 1;

    constructor(
        public dialogRef: MatDialogRef<ProfilFormationUtilisateurModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProfilFormationUtilisateurModalData,
        private readonly store: Store<PilotagesState>,
        private translateService: TranslateService,
    ) {
        this.title = this.data.title;
        this.informationsUtilisateursFormation = this.data.utilisateurs;
        this.translatePrefix = this.data.translatePrefix;
        this.logoString = this.data.logoString || null;
        this.setTitle(this.data.utilisateurs);
    }

    public ngOnInit(): void {
        this.store.dispatch(new LoadProfilFormationUtilisateur(this.informationsUtilisateursFormation, AlternativePath.hq));
    }

    public setTitle(utilisateurRequests: UtilisateurRequest[] = []): void {
        if (utilisateurRequests.length === 1) {
            this.title = `${this.translateService.instant(this.translatePrefix + '.titleOneUser')} ${utilisateurRequests[0].prenom} ${utilisateurRequests[0].nom}`;
        } else if (utilisateurRequests.length > 1) {
            this.title = this.translateService.instant(this.translatePrefix + '.titleManyUser');
        }
    }
}
