import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import moment, { Moment } from 'moment';
import { FilterService } from 'primeng/api';
import { Table } from 'primeng/table';
import { TableColumns } from '../../../../../shared/models/table-columns.model';
import { FormationUtilisateur } from '../../../../models/profil-formation-utilisateur.model';

@Component({
    selector: 'app-profil-formation-utilisateur-table',
    templateUrl: './profil-formation-utilisateur-table.component.html',
    styleUrls: ['./profil-formation-utilisateur-table.component.scss']
})
export class ProfilFormationUtilisateurTableComponent implements OnInit, OnChanges {
    @Input() public translatePrefix: string;
    @Input() public sortField: string;
    @Input() public sortOrder: number;
    @Input() public data: FormationUtilisateur[] = [];
    @Input() public columns: TableColumns[];

    @ViewChild('profilFormationDataTable') private profilFormationDataTable: Table;

    public tableData: FormationUtilisateur[];
    public formationTypes: any[];

    public dateFilters: Moment[];

    constructor(
        private filterService: FilterService,
    ) { }

    public ngOnInit(): void {
        // Added for MB-ANGULAR-11
        this.filterService.register('dateRangeFilterModifieLe', (value: any): boolean => {
            return this.filtrerDates(value);
        });

        // // Remove for MB-ANGULAR-11
        // FilterUtils['dateRangeFilter'] = (value: any): boolean => {
        //     return this.filtrerDates(value);
        // };
    }
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.data && changes.data.currentValue) {
            this.formationTypes = this.genererTypesFormations();
            this.tableData = [...changes.data.currentValue];
        }
    }

    public genererTypesFormations(): any[] {
        const formations: any[] = [];
        this.data.forEach((element: FormationUtilisateur) => {
            formations.push({
                label: element.typeActivite,
                value: element.typeActivite
            });
        });

        return formations.filter((value, index, element) =>
            element.findIndex(t =>
                (t.label === value.label && t.value === value.value)
            ) === index
        );
    }

    public filtrerDates(value: any): boolean {
        if (this.dateFilters) {
            const tableValue = moment(value);
            const firstFilterDate = moment(this.dateFilters[0]);
            const secondFilterDate = moment(this.dateFilters[1]);

            if (tableValue.isValid() && firstFilterDate.isValid()) {
                if (secondFilterDate.isValid()) {
                    return tableValue.isSameOrAfter(firstFilterDate, 'day') && tableValue.isSameOrBefore(secondFilterDate, 'day');
                } else {
                    return tableValue.isSame(firstFilterDate, 'day');
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public inputFilterColumn(value: Event, field: string, filterMatchMode?: string): void {
        this.profilFormationDataTable.filter((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains');
    }

    public inputGlobalFilter(value: Event, filterMatchMode?: string): void {
        this.profilFormationDataTable.filterGlobal((value.target as HTMLInputElement).value, filterMatchMode || 'contains');
    }
}
