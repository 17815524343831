import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmationService, SelectItem } from 'primeng/api';
import { MatDialog } from '@angular/material/dialog';

import { AlternativePath } from '../../../../shared/models/atlernative-path.model';
import { Back, Go } from '../../../../core/store/actions';
import { BilledItem, SelectedBilledItem } from '../../../../shared/models/database/billed-item.model';
import { BillingCompany } from '../../../../shared/models/database/billing-company.model';
import { BillingItem } from '../../../../shared/models/database/billing-item.model';
import { BillingReport, BillingReportStatus, NewBillingReport, UpdatedBillingReport } from '../../../../shared/models/database/billing-report.model';
import { BillingState } from '../../../store/reducers';
import { BillingTimesheet } from '../../../../shared/models/database/billing-timesheet.model';
import { CommentFormModalComponent } from '../../../../shared/components/comment-form-modal/comment-form-modal.component';
import { FormMode } from '../../../../shared/models/form-mode.model';
import {
    getBillingCompany,
    getBillingCompanyLoading,
    getDistinctBillingOrdersSelectOptions,
    getBillingOrdersLoading,
    getAccountingEntrySelectOptions,
    getCurrentBillingReport,
    getBillingReportsLoading,
    getBillingTimesheetsByOrderAndAccountingEntry,
    getBillingReportsSaving
} from '../../../store/selectors';
import {
    getActiveBillingItemsByCode,
    getAllBillingItems,
    getBillingItemsLoading,
    getBillingItemsSelectOptions,
} from '../../../../ogiv-core/store/selectors';
import {
    BillingReportExecutionAction,
    CreateBillingReport,
    DeleteBillingReport,
    ExecuteActionToBillingReport,
    LoadBillingCompany,
    LoadBillingOrders,
    LoadBillingTimesheets,
    UpdateOneBillingReport,
    BillingReportActionType,
    UpdateOneBillingReportSuccess,
} from '../../../store/actions';
import { LoadBillingItems } from '../../../../ogiv-core/store/actions';
import { OgivHelpers } from '../../../../shared/helpers/ogiv.helper';
import { TableColumns } from '../../../../shared/models/table-columns.model';
import { TimesheetReportStatus } from '../../../../shared/models/database/timesheet-report-status.enum';
import { trackByColumnField } from '../../../../shared/helpers/track-by.helper';
import { UserRole } from '../../../../shared/models/user-roles.model';
import { Actions, ofType } from '@ngrx/effects';
import { CommentFormModalData } from '../../../../shared/components/comment-form-modal/comment-form-modal-data.model';
import moment from 'moment';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-billing-report-form',
    templateUrl: './billing-report-form.component.html',
    styleUrls: ['./billing-report-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingReportFormComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public translatePrefix: string;
    @Input() public editAllowedRoles: UserRole[] = [];
    @Input() public userRoles: UserRole[];
    @Input() public formMode: FormMode;
    @Input() public pathChoice: AlternativePath;
    @Input() public backPressed = false;

    @Output() public resetBackButton: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('billingTimesheetsDatatable') billingTimesheetsDatatable: Table;

    public trackByColumnField: (_: number, item: TableColumns) => string = trackByColumnField;
    public translateCommonPrefix = 'billing.common.reportForm';
    private readonly quantityPattern = '^[0-9]*(\.[0-9]{0,2})?(\,[0-9]{0,2})?$';
    private subscriptions: Subscription[] = [];

    public billingItemsOptions$: Observable<SelectItem[]>;

    public billingReport$: Observable<BillingReport | null> = this.store.pipe(
        select(getCurrentBillingReport),
        distinctUntilChanged(),
    );
    public isBillingReportLoading$: Observable<boolean> = this.store.pipe(
        select(getBillingReportsLoading),
    );
    public isBillingReportSaving$: Observable<boolean> = this.store.pipe(
        select(getBillingReportsSaving),
    );
    public billingCompany$: Observable<BillingCompany[] | null> = this.store.pipe(
        select(getBillingCompany),
    );
    public isBillingCompanyLoading$: Observable<boolean> = this.store.pipe(
        select(getBillingCompanyLoading),
    );
    // Les commandes
    public billingOrdersOptions$: Observable<SelectItem[]> = this.store.pipe(
        select(getDistinctBillingOrdersSelectOptions),
    );
    public areBillingOrdersLoading$: Observable<boolean> = this.store.pipe(
        select(getBillingOrdersLoading),
    );
    // Les postes
    public accountingEntryOptions$: Observable<SelectItem[]>;
    // Les feuilles de temps
    public billingTimesheets$: Observable<BillingTimesheet[]> = this.store.pipe(
        select(getBillingTimesheetsByOrderAndAccountingEntry({ formMode: null, selectedOrder: '', selectedAccountingEntry: '' })),
    );

    public areBillingItemsOptionsLoading$: Observable<boolean> = this.store.pipe(
        select(getBillingItemsLoading),
    );
    public billingItems$: Observable<BillingItem[]> = this.store.pipe(
        select(getAllBillingItems),
    );

    public cols: TableColumns[] = [
        { field: 'billingType', header: 'billingType' },
        { field: 'timesheetReportNumber', header: 'reportId' },
        { field: 'status', header: 'status', type: 'tag' },
    ];

    public billingItems: BillingItem[];
    public currentBillingReport: BillingReport;
    public selectedBillingTimesheets: BillingTimesheet[];
    public billingReportForm: FormGroup;
    public waitForUpdateThenGo = false;

    public cancelFormConfig = {
        message: this.translateService.instant(this.translateCommonPrefix + '.cancelDialog' + '.message'),
        header: this.translateService.instant(this.translateCommonPrefix + '.cancelDialog' + '.title'),
        icon: 'pi pi-question-circle',
        acceptLabel: this.translateService.instant('common.confirm'),
        rejectLabel: this.translateService.instant('common.cancel'),
    };

    constructor(
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private readonly store: Store<BillingState>,
        private confirmationService: ConfirmationService,
        private translateService: TranslateService,
        private actions$: Actions
    ) {
        this.billingReportForm = this.createBillingReportForm();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.backPressed && changes.backPressed.currentValue) {
            this.backToReport();
        }
    }

    private createBillingReportForm(): FormGroup {
        return this.formBuilder.group({
            company: [{ value: '', disabled: true }, []],
            invoiceNumber: ['', []],
            order: [null, [Validators.required]],
            accountingEntry: [null, [Validators.required]],
            billingTimesheets: [[], []],
            items: this.formBuilder.array([], []),
        });
    }

    public get isRoleDisabled(): boolean {
        return !!this.editAllowedRoles.length &&
            this.userRoles.every((role: UserRole) => !this.editAllowedRoles.includes(role));
    }

    public get isAllowed(): boolean {
        return !((this.isUpdatingBillingReport && this.isHQ) ||
            this.isUpdatingBillingReport && this.isValidatedByHq(this.currentBillingReport));
    }

    public get isFormReadOnly(): boolean {
        return this.formMode === FormMode.display || this.isRoleDisabled;
    }

    public get itemsArray(): FormArray {
        return this.billingReportForm.controls.items as FormArray;
    }

    public get isCreatingBillingReport(): boolean {
        return this.formMode === FormMode.add;
    }

    public get isHQ(): boolean {
        return this.pathChoice === AlternativePath.hq;
    }

    public get canUserValidate(): boolean {
        return this.userRoles.includes(UserRole.cgc) || this.userRoles.includes(UserRole.pilote);
    }

    public get isUpdatingBillingReport(): boolean {
        return this.formMode === FormMode.edit;
    }

    public get isApproveButtonDisabled(): boolean {
        return this.isBillingReportExported() ||
            this.isValidatedByHq(this.currentBillingReport) ||
            !this.canUserValidate ||
            !this.isHQ;
    }

    public get isRefuseButtonDisabled(): boolean {
        return this.isBillingReportExported() ||
            this.currentBillingReport.validate === 0 ||
            !this.canUserValidate ||
            !this.isHQ;
    }

    private isBillingReportExported(): boolean {
        return this.currentBillingReport.status !== BillingReportStatus.exported;
    }

    public isValidatedByHq(billingReport: BillingReport): boolean {
        return billingReport && billingReport.validate === 1;
    }

    public isBillingTimesheetApproved(billingTimesheet: BillingTimesheet): boolean {
        return billingTimesheet.status === TimesheetReportStatus.approuvéParHQ;
    }

    public ngOnInit(): void {
        if (!this.isFormReadOnly) {
            this.loadBillingOrders();
            this.loadBillingItems();
        }

        if (this.isCreatingBillingReport) {
            this.loadBillingCompany();
            this.updateBillingCompany();
            this.addNewItem();
        }

        this.subscriptions.push(
            this.updateCurrentBillingReport(),
            this.updateBillingTimesheet(),
            this.updateBillingItems(),
            this.handleOrderValueChanges(),
            this.handleAccountingEntryValueChanges(),
        );

        // Les articles
        this.billingItemsOptions$ = this.store.pipe(
            select(getBillingItemsSelectOptions(
                this.currentBillingReport
                    ? { date: this.currentBillingReport.createdDate }
                    : { date: moment().toDate() }
            ))
        );

        this.subscriptions.push(
            this.actions$.pipe(ofType(BillingReportActionType.UPDATE_ONE_BILLING_REPORT_SUCCESS)).subscribe((billingReportSuccess: UpdateOneBillingReportSuccess) => {
                if (this.waitForUpdateThenGo) {
                    const idString = billingReportSuccess && billingReportSuccess.billingReport && billingReportSuccess.billingReport.id.toString();
                    this.store.dispatch(new Go(
                        { path: ['facturation', 'rapports', this.pathChoice, 'preview', idString] }
                    ));
                }
            })
        );

        this.handleItemsSubscription();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private loadBillingOrders(): void {
        this.store.dispatch(new LoadBillingOrders(this.pathChoice));
    }

    private loadBillingItems(): void {
        this.store.dispatch(new LoadBillingItems(this.pathChoice));
    }

    private loadBillingCompany(): void {
        this.store.dispatch(new LoadBillingCompany(this.pathChoice));
    }

    public updateBillingCompany(): Subscription {
        return this.billingCompany$.pipe(
            tap((billingCompany: BillingCompany[] | null) => {
                if (billingCompany && billingCompany.length > 0) {
                    this.billingReportForm.controls.company.setValue(billingCompany[0].company);
                }
            })
        ).subscribe();
    }

    public addNewItem(): void {
        this.addItem(null, null, null, null, null, null);
    }

    public addItem(code: string | null, description: string | null, quantity: string | null, unit: string | null,
        chargeNumber: string | null, detail: string | null): void {
        this.itemsArray.push(this.createItem(code, description, quantity, unit, chargeNumber, detail));
        this.subscriptions.push(this.subscriptionForItems(this.itemsArray.length - 1));
    }

    public createItem(code: string | null, description: string | null, quantity: string | null, unit: string | null,
        chargeNumber: string | null, detail: string | null): FormGroup {
        return this.formBuilder.group({
            code: [code, [Validators.required]],
            description: [`(${code}) ${description}`, []],
            quantity: [quantity, [Validators.required, Validators.min(0.01), Validators.pattern(this.quantityPattern)]],
            unit: unit,
            chargeNumber: [chargeNumber, [Validators.required]],
            detail: [detail]
        });
    }

    public removeItem(index: number): void {
        this.itemsArray.removeAt(index);
    }

    public updateCurrentBillingReport(): Subscription {
        return this.billingReport$.pipe(
            tap((billingReport: BillingReport | null) => {
                this.loadBillingTimesheets(billingReport?.id ?? 0);
                if (billingReport) {
                    this.currentBillingReport = billingReport;
                    this.updateBillingReportForm(billingReport);
                    this.updateFormDisabledAttribute();
                }
            })
        ).subscribe();
    }

    public loadBillingTimesheets(billingReportId: number): void {
        this.store.dispatch(new LoadBillingTimesheets(billingReportId, this.pathChoice));
    }

    public updateBillingReportForm(billingReport: BillingReport): void {
        this.getAccountingEntrySelectOptions(billingReport.order);
        this.getBillingTimesheetsByOrderAndAccountingEntry(billingReport.order, billingReport.accountingEntry);

        this.billingReportForm.patchValue({
            company: billingReport.company,
            invoiceNumber: billingReport.invoiceNumber,
            order: billingReport.order,
            accountingEntry: billingReport.accountingEntry,
            items: billingReport.billedItems ? billingReport.billedItems : [],
            timesheetReportIds: billingReport.timesheetReportIds ? billingReport.timesheetReportIds : []
        });
        this.setItemsArray(billingReport);
    }

    public updateFormDisabledAttribute(): void {
        if (this.isFormReadOnly) {
            Object.keys(this.billingReportForm.controls)
                .filter((controlName: string) => controlName !== 'items')
                .forEach((controlName: string) => this.billingReportForm.controls[controlName].disable());

            (this.itemsArray).controls.forEach((control: AbstractControl) => control.disable());
        }
    }

    private getAccountingEntrySelectOptions(order: string): void {
        this.accountingEntryOptions$ = this.store.pipe(
            select(getAccountingEntrySelectOptions({ selectedOrder: order }))
        );
    }

    public getBillingTimesheetsByOrderAndAccountingEntry(selectedOrder: string, selectedAccountingEntry: string): void {
        this.billingTimesheets$ = this.store.pipe(
            select(getBillingTimesheetsByOrderAndAccountingEntry({
                formMode: this.formMode,
                selectedOrder: selectedOrder,
                selectedAccountingEntry: selectedAccountingEntry
            }))
        );
    }

    public setItemsArray(billingReport: BillingReport): void {
        this.resetItemsArray();
        if (billingReport.billedItems) {
            billingReport.billedItems.forEach((item: BilledItem) => this.addItem(item.code, item.description, item.quantity, item.unit, item.chargeNumber, item.detail));
        }
    }

    private resetItemsArray(): void {
        this.billingReportForm.removeControl('items');
        this.billingReportForm.addControl('items', this.formBuilder.array([], []));
    }

    public updateBillingTimesheet(): Subscription {
        return this.billingTimesheets$.pipe(
            tap((billingTimesheets: BillingTimesheet[]) => {
                if (billingTimesheets) {
                    this.selectedBillingTimesheets = [];
                    if (this.currentBillingReport && this.currentBillingReport.timesheetReportIds) {
                        billingTimesheets.forEach((timesheet: BillingTimesheet) => {
                            if (this.currentBillingReport.timesheetReportIds?.find((id: number) => id === timesheet.id)) {
                                this.selectedBillingTimesheets.push(timesheet);
                            }
                        });
                        this.billingReportForm.controls.billingTimesheets.setValue(this.selectedBillingTimesheets);
                    }
                }
            })
        ).subscribe();
    }

    public updateBillingItems(): Subscription {
        return this.billingItems$.pipe(
            tap((billingItems: BillingItem[]) => {
                if (billingItems) {
                    this.billingItems = billingItems;
                } else {
                    this.billingItems = [];
                }

                this.handleItemsSubscription();
            })
        ).subscribe();
    }

    private handleItemsSubscription() {
        this.itemsArray.controls.forEach((_: any, index: any) => {
            this.subscriptions.push(this.subscriptionForItems(index));
        });
    }

    public subscriptionForItems(index: number): Subscription {
        return this.itemsArray.controls[index]['controls'].code.valueChanges.pipe(
            switchMap((selectedItemCode: string) => {
                return this.store.pipe(
                    select(getActiveBillingItemsByCode({ date: '', code: selectedItemCode })),
                    tap((foundItem: BillingItem | null) => {
                        if (foundItem !== null) {
                            this.itemsArray.controls[index]['controls'].unit.setValue(foundItem.unit);
                            this.itemsArray.controls[index].updateValueAndValidity();
                        } else {
                            this.itemsArray.controls[index]['controls'].unit.setValue('');
                            this.itemsArray.controls[index].updateValueAndValidity();
                        }
                    }),
                );
            })
        ).subscribe();
    }

    private handleAccountingEntryValueChanges(): Subscription {
        return this.billingReportForm.controls.accountingEntry.valueChanges.pipe(
            tap((selectedAccountingEntry: string) => {
                const selectedOrder: string = this.billingReportForm.controls.order.value ?? '';
                this.getBillingTimesheetsByOrderAndAccountingEntry(selectedOrder, selectedAccountingEntry);
                this.resetBillingTimesheets();
            })
        ).subscribe();
    }

    private resetBillingTimesheets() {
        this.billingReportForm.controls.billingTimesheets.setValue([]);
        this.billingReportForm.controls.billingTimesheets.updateValueAndValidity();
        this.selectedBillingTimesheets = [];
    }

    private handleOrderValueChanges(): Subscription {
        return this.billingReportForm.controls.order.valueChanges.pipe(
            tap((selectedOrder: string) => {
                const selectedAccountingEntry = this.billingReportForm.controls.accountingEntry.value ?? '';
                this.getAccountingEntrySelectOptions(selectedOrder);
                this.getBillingTimesheetsByOrderAndAccountingEntry(selectedOrder, selectedAccountingEntry);
                this.resetBillingTimesheets();
            })
        ).subscribe();
    }

    public onRowSelect({ data }: any = {}): void {
        if (!data) {
            return;
        }

        const currentBillingTimesheets: BillingTimesheet[] = this.billingReportForm.controls.billingTimesheets.value;
        const newBillingTimesheets: BillingTimesheet[] = OgivHelpers.removeDuplicates(
            (timesheet: BillingTimesheet) => timesheet.id,
            [...currentBillingTimesheets, data]
        );

        this.billingReportForm.controls.billingTimesheets.setValue(newBillingTimesheets);
        this.billingReportForm.controls.billingTimesheets.markAsTouched();
        this.billingReportForm.controls.billingTimesheets.updateValueAndValidity();
    }

    public onRowUnselect({ data }: any = {}): void {
        if (!data) {
            return;
        }

        const newBillingTimesheets: BillingTimesheet[] = (this.billingReportForm.controls.billingTimesheets.value || [])
            .filter((billingTimesheet: BillingTimesheet) => billingTimesheet.id !== data.id);

        this.billingReportForm.controls.billingTimesheets.setValue(newBillingTimesheets);
        this.billingReportForm.controls.billingTimesheets.markAsTouched();
        this.billingReportForm.controls.billingTimesheets.updateValueAndValidity();
    }

    public timesheetCheckboxClicked(): void {
        this.resetItemsValidators();
    }

    public disableAddItem(): boolean {
        const incompletItem = this.isBillingReportHasIncompleteItem();
        const emptyItem = this.isBillingReportHasEmptyItem();
        return incompletItem || emptyItem;
    }

    public onSubmit(): void {
        if (this.isBillingReportHasIncompleteItem()) {
            this.setItemsValidators();
            this.displayInvalidFormDialog();
        } else if (!this.isBillingReportFormValid()) {
            this.displayInvalidFormDialog();
        } else if (this.isCreatingBillingReport) {
            this.createBillingReport();
        } else {
            this.updateBillingReport();
        }
    }

    private isBillingReportHasIncompleteItem(): boolean {
        return (this.itemsArray.value.findIndex((item: any) => {
            return item.code === null && (item.quantity !== null || item.chargeNumber !== null);
        }) !== -1)
            || (this.itemsArray.value.findIndex((item: any) => {
                return item.code !== null && (
                    item.quantity === null || item.quantity <= 0 || item.quantity === ''
                    || item.chargeNumber === null || item.chargeNumber === ''
                );
            }) !== -1);
    }

    private isBillingReportFormValid(): boolean {
        return (this.billingReportForm.valid || this.billingReportForm.disabled) && (this.isBillingReportFormHasItems() || this.isBillingReportFormHasTimesheets());
    }

    private isBillingReportFormHasItems(): boolean {
        return this.itemsArray.value && (this.itemsArray.value.length > 0) && !this.isBillingReportHasOnlyEmptyItem();
    }

    private isBillingReportHasEmptyItem(): boolean {
        return this.itemsArray.value.findIndex((item: any) => {
            return item.code === null && (item.quantity === null || item.quantity <= 0 || item.quantity === '');
        }) !== -1;
    }

    private isBillingReportHasOnlyEmptyItem(): boolean {
        return this.itemsArray.value.length === 1 &&
            this.itemsArray.value.findIndex((item: any) => item.code === null && (item.quantity === null || item.quantity <= 0 || item.quantity === '')) !== -1;
    }

    private isBillingReportFormHasTimesheets(): boolean {
        return this.billingReportForm.controls.billingTimesheets.value && this.billingReportForm.controls.billingTimesheets.value.length > 0;
    }

    private displayInvalidFormDialog() {
        this.confirmationService.confirm({
            message: this.translateService.instant(this.translateCommonPrefix + '.validationDialog' + '.message'),
            header: this.translateService.instant(this.translateCommonPrefix + '.validationDialog' + '.title'),
            icon: 'pi pi-times-circle',
            acceptLabel: this.translateService.instant('common.ok'),
            rejectVisible: false,
            accept: () => { },
        });
    }

    private setItemsValidators(): void {
        this.itemsArray.value.forEach((_item: any, index: number) => {
            (this.itemsArray.controls[index] as FormGroup).controls.quantity
                .setValidators([Validators.required, Validators.min(0.01), Validators.pattern(this.quantityPattern)]);
            (this.itemsArray.controls[index] as FormGroup).controls.quantity.markAsTouched();
            (this.itemsArray.controls[index] as FormGroup).controls.quantity.updateValueAndValidity();

            (this.itemsArray.controls[index] as FormGroup).controls.code.setValidators([Validators.required]);
            (this.itemsArray.controls[index] as FormGroup).controls.code.markAsTouched();
            (this.itemsArray.controls[index] as FormGroup).controls.code.updateValueAndValidity();

            (this.itemsArray.controls[index] as FormGroup).controls.chargeNumber.setValidators([Validators.required]);
            (this.itemsArray.controls[index] as FormGroup).controls.chargeNumber.markAsTouched();
            (this.itemsArray.controls[index] as FormGroup).controls.chargeNumber.updateValueAndValidity();
        });
    }

    private resetItemsValidators(): void {
        this.itemsArray.value.forEach((_item: any, index: number) => {
            (this.itemsArray.controls[index] as FormGroup).controls.chargeNumber.clearValidators();
            (this.itemsArray.controls[index] as FormGroup).controls.chargeNumber.updateValueAndValidity();

            (this.itemsArray.controls[index] as FormGroup).controls.quantity.clearValidators();
            (this.itemsArray.controls[index] as FormGroup).controls.quantity.updateValueAndValidity();

            (this.itemsArray.controls[index] as FormGroup).controls.code.clearValidators();
            (this.itemsArray.controls[index] as FormGroup).controls.code.updateValueAndValidity();
        });
    }

    public createBillingReport(): void {
        const billingReport: NewBillingReport = this.mapNewBillingReportForm();
        this.store.dispatch(new CreateBillingReport(billingReport, this.pathChoice));
        this.store.dispatch(new Go({ path: ['facturation', 'rapports', this.pathChoice] }));
    }

    private mapNewBillingReportForm(): NewBillingReport {
        const billingReportFormValue = Object.assign({}, this.billingReportForm.value);
        const formValue: NewBillingReport = {
            invoiceNumber: billingReportFormValue.invoiceNumber,
            order: billingReportFormValue.order,
            accountingEntry: billingReportFormValue.accountingEntry,
            billedItems: this.mapBillingItems(billingReportFormValue.items),
            timesheetReportIds: this.mapBillingTimesheets(billingReportFormValue.billingTimesheets)
        };
        return formValue;
    }

    private mapBillingItems(items: any[]): SelectedBilledItem[] {
        const itemsWithInfos: SelectedBilledItem[] = [];
        items.forEach((item: any) => {
            if (item.code !== null && item.quantity > 0 && item.chargeNumber !== null) {
                itemsWithInfos.push({
                    code: item.code,
                    description: this.getBillingItemDescription(item),
                    unit: this.getBillingItemUnit(item),
                    quantity: item.quantity,
                    chargeNumber: item.chargeNumber,
                    detail: item.detail
                });
            }
        });

        return itemsWithInfos;
    }

    private getBillingItemDescription(item: any): string {
        const foundBillingItem = this.billingItems.find((billingItem) => billingItem.code === item.code);
        return (foundBillingItem) ? foundBillingItem.description : '';
    }

    private getBillingItemUnit(item: any): string {
        const foundBillingItem = this.billingItems.find((billingItem) => billingItem.code === item.code);
        return (foundBillingItem) ? foundBillingItem.unit : '';
    }

    private mapBillingTimesheets(timesheets: BillingTimesheet[]): number[] {
        const timsheetIds: number[] = [];
        timesheets.forEach((timesheet: BillingTimesheet) => {
            timsheetIds.push(timesheet.id);
        });

        return timsheetIds;
    }

    public updateBillingReport(): void {
        const billingReport: UpdatedBillingReport = this.mapUpdateBillingReportForm();
        this.store.dispatch(new UpdateOneBillingReport(billingReport, billingReport.id, this.pathChoice));
    }

    private mapUpdateBillingReportForm(): UpdatedBillingReport {
        const billingReportFormValue = Object.assign({}, this.billingReportForm.value);
        const formValue: UpdatedBillingReport = {
            id: this.currentBillingReport.id,
            invoiceNumber: billingReportFormValue.invoiceNumber,
            order: billingReportFormValue.order,
            accountingEntry: billingReportFormValue.accountingEntry,
            billedItems: this.mapBillingItems(billingReportFormValue.items),
            timesheetReportIds: this.mapBillingTimesheets(billingReportFormValue.billingTimesheets)
        };
        return formValue;
    }

    public validBillingReport(): void {
        this.store.dispatch(new ExecuteActionToBillingReport(this.currentBillingReport.id, '', BillingReportExecutionAction.VALIDATE, this.pathChoice, true));
    }

    public refuseBillingReport(): void {
        const commentDialog = this.dialog.open(CommentFormModalComponent, {
            maxWidth: '550px',
            width: '550px',
            data: {
                isHq: this.isHQ,
                weekdaysStatus: null,
                maxLength: 1000
            } as CommentFormModalData,
        });

        commentDialog.afterClosed().subscribe((refusalComment: string) => {
            if (refusalComment) {
                this.store.dispatch(
                    new ExecuteActionToBillingReport(this.currentBillingReport.id, refusalComment, BillingReportExecutionAction.REFUSE, this.pathChoice, true)
                );
            }
        });

    }

    public previewForm(): void {
        if (!this.isBillingReportFormValid()) {
            this.displayInvalidFormDialog();
            this.setItemsValidators();
        } else {
            this.previewBillingReport();
        }
    }

    public sapEntriesReportForm(): void {
        this.store.dispatch(new Go(
            { path: ['facturation', 'rapports', this.pathChoice, 'sap-entries-report', this.currentBillingReport.id.toString()] }
        ));
    }

    private previewBillingReport(): void {
        if (this.billingReportForm.touched) {
            this.waitForUpdateThenGo = true;
            const billingReport: UpdatedBillingReport = this.mapUpdateBillingReportForm();
            this.store.dispatch(new UpdateOneBillingReport(billingReport, billingReport.id, this.pathChoice));
        } else {
            this.store.dispatch(new Go(
                { path: ['facturation', 'rapports', this.pathChoice, 'preview', this.currentBillingReport.id.toString()] }
            ));
        }
    }

    public deleteForm(): void {
        if (this.currentBillingReport) {
            this.confirmationService.confirm({
                message: this.translateService.instant(this.translateCommonPrefix + '.deleteDialog' + '.message'),
                header: this.translateService.instant(this.translateCommonPrefix + '.deleteDialog' + '.title'),
                icon: 'pi pi-question-circle',
                acceptLabel: this.translateService.instant('common.confirm'),
                rejectLabel: this.translateService.instant('common.cancel'),
                accept: () => {
                    this.store.dispatch(new DeleteBillingReport(this.currentBillingReport.id, this.pathChoice));
                    this.store.dispatch(new Go({ path: ['facturation', 'rapports', this.pathChoice] }));
                }
            });
        }
    }

    public cancelForm(): void {
        if (this.billingReportForm.touched) {
            this.confirmationService.confirm(
                {
                    ...this.cancelFormConfig,
                    accept: () => {
                        this.store.dispatch(new Go({ path: ['facturation', 'rapports', this.pathChoice] }));
                    }
                }
            );
            // this.confirmationService.confirm({
            //     message: this.translateService.instant(this.translateCommonPrefix + '.cancelDialog' + '.message'),
            //     header: this.translateService.instant(this.translateCommonPrefix + '.cancelDialog' + '.title'),
            //     icon: 'pi pi-question-circle',
            //     acceptLabel: this.translateService.instant('common.confirm'),
            //     rejectLabel: this.translateService.instant('common.cancel'),
            //     accept: () => {
            //         this.store.dispatch(new Go({ path: ['facturation', 'rapports', this.pathChoice] }));
            //     }
            // });
        } else {
            this.store.dispatch(new Go({ path: ['facturation', 'rapports', this.pathChoice] }));
        }
    }

    public backToReport(): void {
        if (this.billingReportForm.touched) {
            this.confirmationService.confirm(
                {
                    ...this.cancelFormConfig,
                    accept: () => {
                        this.store.dispatch(new Back());
                    },
                    reject: () => {
                        this.resetBackButton.emit();
                    }
                }
            );
        } else {
            this.store.dispatch(new Back());
        }
    }

    public inputFilterColumn(value: Event, field: string, match: string = 'contains'): void {
        this.billingTimesheetsDatatable.filter((value.target as HTMLInputElement).value, field, match);
    }
}
