import { Action } from '@ngrx/store';
import { DemandeClient } from '../../../shared/models/database/demande-client.model';

/**
 * Types
 */
export enum DemandesClientEcartsActionTypes {
    DEMANDES_CLIENT_ECARTS_LOAD = '[DemandesClient] Ecarts Load',
    DEMANDES_CLIENT_ECARTS_LOAD_SUCCESS = '[DemandesClient] Ecarts Load Success',
    DEMANDES_CLIENT_ECARTS_LOAD_FAIL = '[DemandesClient] Ecarts Load Fail',
}

/**
 * ACTIONS
 */
export class DemandesClientEcartsLoad implements Action {
    readonly type = DemandesClientEcartsActionTypes.DEMANDES_CLIENT_ECARTS_LOAD;
    constructor() { }
}

export class DemandesClientEcartsLoadSuccess implements Action {
    readonly type = DemandesClientEcartsActionTypes.DEMANDES_CLIENT_ECARTS_LOAD_SUCCESS;
    constructor(public demandesClient: DemandeClient[]) { }
}

export class DemandesClientEcartsLoadFail implements Action {
    readonly type = DemandesClientEcartsActionTypes.DEMANDES_CLIENT_ECARTS_LOAD_FAIL;
    constructor(public error: any) { }
}

export type DemandesClientEcartsAction =
    | DemandesClientEcartsLoad
    | DemandesClientEcartsLoadSuccess
    | DemandesClientEcartsLoadFail;
