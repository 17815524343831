<app-element-loader *ngIf="etatSynchronisationsLoading$ | async"
    [size]="loaderLogoSize"
    [displayLabel]="true"
    [labelFontSize]="20"></app-element-loader>
<p-table #etatFormationDataTable [columns]="columns" [value]="$any(etatSynchronisations$ | async)" [paginator]="true" [rows]="10"
    [sortField]="sortField" [sortOrder]="sortOrder" [autoLayout]="true" [rowHover]="true"
    [rowsPerPageOptions]="[10,25,50,100,200]">

    <ng-template pTemplate="caption">
        <div class="table__header">
            <div class="search">
                <i class="fas fa-search search__icon"></i>
                <input type="text" class="search__input" pInputText size="30"
                    [placeholder]="'common.search' | translate" [attr.aria-placeholder]="'common.search' | translate"
                    (input)="inputGlobalFilter($event, 'contains')">
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <div class="p-d-flex">
                    {{ translatePrefix + '.' + col.header | translate }}

                    <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                        [attr.aria-label-desc]="'common.sortDesc' | translate"
                        [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
                </div>
            </th>
        </tr>
        <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field" class="">
                <div *ngSwitchCase="'type'">
                    <p-dropdown appendTo="body" [options]="types" autoWidth="false" [style]="{'width':'100%'}"
                        (onChange)="etatFormationDataTable.filter($event.value, 'type', 'equals')"
                        styleClass="p-column-filter" [placeholder]="'pilotages.synchroType-dropdown.placeholder' | translate"
                        [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span>{{option.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div *ngSwitchCase="'creationDate'" style="width: 175px;">
                    <p-calendar
                        [(ngModel)]="dateFiltersSynchro"
                        appendTo="body"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="'pilotages.general.date-filter' | translate"
                        (onClearClick)="etatFormationDataTable.filter($event, col.field, 'dateRangeFilterSynchro')"
                        (onSelect)="etatFormationDataTable.filter($event, col.field, 'dateRangeFilterSynchro')">
                    </p-calendar>
                </div>
                <div *ngSwitchCase="'lastSyncDate'" style="width: 175px;">
                    <p-calendar
                        [(ngModel)]="dateFiltersReplica"
                        appendTo="body"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="'pilotages.general.date-filter' | translate"
                        (onClearClick)="etatFormationDataTable.filter($event, col.field, 'dateRangeFilterReplica')"
                        (onSelect)="etatFormationDataTable.filter($event, col.field, 'dateRangeFilterReplica')">
                    </p-calendar>
                </div>
                <div *ngSwitchDefault>
                    <input pInputText *ngSwitchDefault type="text" styleClass="p-column-filter"
                        (input)="inputFilterColumn($event, col.field, 'contains')"
                        [placeholder]="'common.filter' | translate"
                        [attr.aria-placeholder]="'common.filter' | translate">
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length+1">
                <h3>
                    {{translatePrefix + '.empty' | translate}}
                </h3>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr (click)="callFunction(rowData)" class="p-selectable-row" (mouseenter)="rowOver(rowIndex)" (mouseleave)="rowOver(null)">
            <td *ngFor="let col of columns">
                <ng-container *ngIf="col.field === 'creationDate' || col.field === 'lastSyncDate'; else default">
                    {{ rowData[col.field] | date:'dd-MM-yyyy h:mm:ss a' }}
                </ng-container>
                <ng-template #default>
                    {{ rowData[col.field] }}
                </ng-template>
            </td>
        </tr>
    </ng-template>
</p-table>
