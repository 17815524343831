import { Component, Input } from '@angular/core';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';

import { OrderGroup } from '../../../../../shared/models/database/order-group.model';
import { Order } from '../../../../../shared/models/database/order.model';
import { PostLineGroup } from '../../../../../shared/models/database/post-line-group.model';
import { PostLine } from '../../../../../shared/models/database/post-line.model';
import { Unit } from '../../../../../shared/models/database/unit.model';
import { UserRole } from '../../../../../shared/models/user-roles.model';

@Component({
    selector: 'app-tab-view',
    templateUrl: './tab-view.component.html',
    styleUrls: [
        './tab-view.component.scss',
        '../tab-panel-header/tab-panel-header.component.scss',
    ]
})
export class TabViewComponent {
    @Input() public timesheetDateId = '';
    @Input() public orderGroup: OrderGroup;
    @Input() public prefixTranslate = '';
    @Input() public editAllowedRoles: UserRole[] = [];
    @Input() public pathChoice: AlternativePath;

    private isSelectedSetted = false;

    constructor() { }

    public setAsSelected(): boolean {
        if (!this.isSelectedSetted) {
            this.isSelectedSetted = !this.isSelectedSetted;
            return this.isSelectedSetted;
        }
        return this.isSelectedSetted;
    }

    public isOrderByUnit(): boolean {
        return this.orderGroup && this.orderGroup.unitly !== null;
    }

    public isOrderByHour(): boolean {
        return this.orderGroup && this.orderGroup.hourly !== null;
    }

    public isOrderByOutage(): boolean {
        return this.orderGroup && this.orderGroup.outage !== null;
    }

    public getOrderUnitDone(order: Order | null): number {
        let number = 0;

        if (order != null && order.postLineGroups && order.postLineGroups !== null) {
            const flatPostline: any[] = [];
            order.postLineGroups.forEach((myPostLinesGroup: PostLineGroup) => {
                if (myPostLinesGroup.postLines && myPostLinesGroup.postLines !== null && myPostLinesGroup.postLines.length > 0) {
                    myPostLinesGroup.postLines.forEach((postLine: PostLine) => {
                        flatPostline.push(postLine);
                    });
                }
            });
            if (flatPostline.length > 0) {
                const flatUnits: Unit[] = [];
                flatPostline.forEach((postLine: PostLine) => {
                    if (postLine.units && postLine.units !== null && postLine.units.length > 0) {
                        postLine.units.forEach((unit: Unit) => {
                            flatUnits.push(unit);
                        });
                    }
                });
                if (flatUnits.length > 0) {
                    number = flatUnits.map((unit: Unit) => unit.quantity).reduce((acc: number, ele: number) => acc + ele, number);
                }
            }
        }

        return number;
    }
}
