import { createSelector } from '@ngrx/store';

import moment from 'moment';

import { ReportsState, getReportsState } from '../reducers';
import { timesheetReportsSummaryTechAdapter, TimesheetReportsSummaryTechState } from '../reducers/timesheet-reports-summary-tech.reducer';
import { TimesheetReportSummary } from '../../../shared/models/database/timesheet-report-summary.model';

const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = timesheetReportsSummaryTechAdapter.getSelectors();

/**
 * General TimesheetReport State selector
 */

export const getTimesheetReportsSummaryTechState = createSelector(
    getReportsState,
    (state: ReportsState) => {
        return state.timesheetReportsSummaryTech;
    },
);

export const getTimesheetReportsSummaryTechLoading = createSelector(
    getTimesheetReportsSummaryTechState,
    (state: TimesheetReportsSummaryTechState) => state.loading,
);

export const getTimesheetReportsSummaryTechLoaded = createSelector(
    getTimesheetReportsSummaryTechState,
    (state: TimesheetReportsSummaryTechState) => state.loaded,
);

export const shouldLoadTimesheetReportsSummaryTech = createSelector(
    getTimesheetReportsSummaryTechLoading,
    getTimesheetReportsSummaryTechLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllTimesheetReportsSummaryTech = createSelector(
    getTimesheetReportsSummaryTechState,
    selectAll,
);

export const getTimesheetReportsSummaryTechEntities = createSelector(
    getTimesheetReportsSummaryTechState,
    selectEntities,
);

export const getTimesheetReportsSummaryTechIds = createSelector(
    getTimesheetReportsSummaryTechState,
    selectIds,
);

export const getTimesheetReportsSummaryTechCount = createSelector(
    getTimesheetReportsSummaryTechState,
    selectTotal,
);

export const getTimesheetReportsSummaryTechByWeek = ({ selectedDate }: { selectedDate?: string | Date } = {}) => createSelector(
    getAllTimesheetReportsSummaryTech,
    (reports: TimesheetReportSummary[]) => {
        if (!selectedDate) {
            return reports;
        }

        return reports.filter((report: TimesheetReportSummary) => {
            const isSame = moment(report.week).isSame(moment(selectedDate), 'week');
            return isSame;
        });
    }
);
