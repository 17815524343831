import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { Equipment } from '../../../shared/models/database/equipment.model';
import { EquipmentActionTypes, EquipmentsAction } from '../actions/equipments.action';

export interface EquipmentState extends EntityState<Equipment> {
    loading: boolean;
    loaded: boolean;
}

export const equipmentAdapter: EntityAdapter<Equipment> = createEntityAdapter<Equipment>({
    selectId: (equipment: Equipment) => equipment.id,
});

export const equipmentInitialState = equipmentAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function equipmentReducer(
    state: EquipmentState = equipmentInitialState,
    action: EquipmentsAction,
): EquipmentState {
    switch (action.type) {
        case EquipmentActionTypes.DELETE_ONE_EQUIPMENT:
        case EquipmentActionTypes.CREATE_EQUIPMENT:
        case EquipmentActionTypes.UPDATE_ONE_EQUIPMENT: {
            return {
                ...state,
                loading: true,
            };
        }

        case EquipmentActionTypes.CREATE_EQUIPMENT_SUCCESS: {
            // return equipmentAdapter.addOne(action.equipment, {
            return {
                ...state,
                loading: false,
            };
        }

        case EquipmentActionTypes.UPDATE_ONE_EQUIPMENT_SUCCESS: {
            // return equipmentAdapter.upsertOne(action.equipment, {
            return {
                ...state,
                loading: false,
            };
        }

        case EquipmentActionTypes.DELETE_ONE_EQUIPMENT_FAIL:
        case EquipmentActionTypes.CREATE_EQUIPMENT_FAIL:
        case EquipmentActionTypes.UPDATE_ONE_EQUIPMENT_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case EquipmentActionTypes.LOAD_EQUIPMENTS: {
            return {
                ...state,
                loading: true,
            };
        }

        case EquipmentActionTypes.LOAD_EQUIPMENTS_SUCCESS: {
            return equipmentAdapter.setAll(action.equipments, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case EquipmentActionTypes.LOAD_EQUIPMENTS_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case EquipmentActionTypes.LOAD_EQUIPMENTS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        case EquipmentActionTypes.DELETE_ONE_EQUIPMENT_SUCCESS: {
            // return equipmentAdapter.removeOne(action.equipment.id, {
            return {
                ...state,
                loading: false,
            };
        }

        default:
            return state;
    }
}
