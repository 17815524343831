<p-table #profilFormationDataTable [columns]="columns" [value]="tableData" [paginator]="true" [rows]="10"
    [sortField]="sortField" [sortOrder]="sortOrder" [autoLayout]="true" [rowsPerPageOptions]="[10,25,50,100,200]">

    <ng-template pTemplate="caption">
        <div class="table__header">
            <div class="search">
                <i class="fas fa-search search__icon"></i>
                <input type="text" class="search__input" pInputText size="30"
                    [placeholder]="'common.search' | translate" [attr.aria-placeholder]="'common.search' | translate"
                    (input)="inputGlobalFilter($event, 'contains')">
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <div class="p-d-flex">
                    {{ translatePrefix + '.' + col.header | translate }}

                    <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                        [attr.aria-label-desc]="'common.sortDesc' | translate"
                        [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
                </div>
            </th>
        </tr>
        <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <div *ngSwitchCase="'TypeActivite'">
                    <p-dropdown [appendTo]="profilFormationDataTable" [options]="formationTypes" autoWidth="false" [style]="{'width':'fit-content'}"
                        (onChange)="profilFormationDataTable.filter($event.value, 'TypeActivite', 'equals')"
                        styleClass="p-column-filter-sm"
                        [placeholder]="'pilotages.type-activite-dropdown.placeholder' | translate" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span>{{option.label}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div *ngSwitchCase="'DateFormationAchevee'" style="width: 175px;">
                    <p-calendar
                        [(ngModel)]="dateFilters"
                        [appendTo]="profilFormationDataTable"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="'pilotages.general.date-filter' | translate"
                        (onClearClick)="profilFormationDataTable.filter($event, col.field, 'dateRangeFilter')"
                        (onSelect)="profilFormationDataTable.filter($event, col.field, 'dateRangeFilter')">
                    </p-calendar>
                </div>
                <div *ngSwitchDefault>
                    <input pInputText *ngSwitchDefault type="text" styleClass="p-column-filter"
                        (input)="inputFilterColumn($event, col.field, 'contains')"
                        [placeholder]="'common.filter' | translate"
                        [attr.aria-placeholder]="'common.filter' | translate">
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length+1">
                <h3>
                    {{translatePrefix + '.empty' | translate}}
                </h3>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
                <ng-container *ngIf="col.field === 'DateFormationAchevee'; else default">
                    {{ rowData[col.field] | date:'dd-MM-yyyy' }}
                </ng-container>
                <ng-template #default>
                    {{ rowData[col.field] }}
                </ng-template>
            </td>
        </tr>
    </ng-template>
</p-table>
