import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { UtilisateurInterne } from '../../../../pilotages/models/utilisateur-interne.model';
import {
    getAllUtilisateursInternes,
    getUtilisateursInternesCipsOptions,
    getUtilisateursInternesLoading
} from '../../../../pilotages/store/selectors';
import { TypeEngagementEnum, MoyenEnum, DemandeClient, JustificationInfo } from '../../../../shared/models/database/demande-client.model';
import { LoadUtilisateursInternes } from '../../../../pilotages/store/actions';
import { AlternativePath } from '../../../../shared/models/atlernative-path.model';
import { DemandesClientUtils } from '../../../../shared/models/demande-client-utils.model';
import { LoadHolidays } from '../../../store/actions/demandes-client-utils.actions';
import { getDemandesClientUtils } from '../../../store/selectors/demandes-client-utils.selectors';
import moment from 'moment';

@Component({
    selector: 'app-demandes-client-engagement-form',
    templateUrl: './demandes-client-engagement-form.component.html',
    styleUrls: ['./demandes-client-engagement-form.component.scss']
})
export class DemandesClientEngagementFormComponent implements OnInit, OnChanges {
    @Input() public parentFormIsEditing: boolean;
    @Input() public demandesClient: DemandeClient[];
    @Input() public currentUser: UtilisateurInterne | undefined;
    @Output() engagementValidityChange = new EventEmitter<boolean>();
    @Output() newEngagementClickChange = new EventEmitter<boolean>();
    @Output() engagementChange = new EventEmitter<any>();


    public engagementForm: FormGroup;
    public isNouvelEngagementClicked: boolean;
    public calendarDateFormat = 'yy/mm/dd';
    public typeEngagementSelectOptions: SelectItem[];
    public moyenSelectOptions: SelectItem[];
    public justificationSaisie = '';
    public remarqueSaisie = '';
    public originalValue: any;

    public commonTranslatePrefix = 'demandes-client.common.';
    public translatePrefix = 'demandes-client.engagementForm.';
    private nbJOursOuvrablesPriseEnCharge = 20;
    private nbJOursOuvrablesTravaux = 40;
    private nbJOursOuvrablesTravauxBsCnGaNo = 50;
    private demandesClientUtils: DemandesClientUtils;
    private sitesBsCnGaNo = ['BS', 'CN', 'GA', 'NO'];
    private justificationInfos: JustificationInfo[];


    constructor(
        private formBuilder: FormBuilder,
        private translateService: TranslateService,
        private readonly store: Store,
    ) {
        this.engagementForm = this.formBuilder.group({
            dateEngagement: [{ value: '', disabled: true }, []],
            typeEngagement: [{ value: '', disabled: true }, []],
            moyen: [{ value: '', disabled: true }, []],
            prisPar: [{ value: '', disabled: true }, []],
            justification: [{ value: '', disabled: true }, []]
        });
    }

    public isUtilisateursInternesLoading$: Observable<boolean> = this.store.pipe(
        select(getUtilisateursInternesLoading),
    );

    public utilisateursInternes$: Observable<UtilisateurInterne[] | null> = this.store.pipe(
        select(getAllUtilisateursInternes),
        distinctUntilChanged()
    );

    public prisParSelectOptions$: Observable<SelectItem[] | null> = this.store.pipe(
        select(getUtilisateursInternesCipsOptions),
        distinctUntilChanged()
    );

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.demandesClient && changes.demandesClient.currentValue !== undefined && changes.demandesClient.currentValue !== null) {
            this.demandesClient = changes.demandesClient.currentValue;
            this.setEngagementInitialValues();
        }
        if (changes && changes.parentFormIsEditing && changes.parentFormIsEditing.currentValue !== null && changes.parentFormIsEditing.currentValue !== undefined) {
            this.parentFormIsEditing = changes.parentFormIsEditing.currentValue;
            if (this.parentFormIsEditing) {
                this.onEdit();
            } else if (!changes.parentFormIsEditing.firstChange) {
                this.annulerEngagementModifications();
            }
        }
        if (changes && changes.currentUser && changes.currentUser.currentValue !== null && changes.currentUser.currentValue !== undefined) {
            this.currentUser = changes.currentUser.currentValue;
            this.prisPar?.setValue(this.currentUser?.cip);
            this.prisPar?.markAsTouched();
        }
    }

    ngOnInit(): void {
        this.isNouvelEngagementClicked = false;
        this.loadUtilisateursInternes();
        this.populateAllSelectOptions();
        this.loadHolidays();
    }

    public updateJustification(): void {
        const datePriseEnCharge = this.getDateEngagement(this.demandesClientUtils.joursFeries, this.nbJOursOuvrablesPriseEnCharge);
        const sitesDemandesClientBsCnGaNo = this.demandesClient.filter(x => this.sitesBsCnGaNo.includes(x.site));
        const sitesDemandesClientAutres = this.demandesClient.filter(x => !this.sitesBsCnGaNo.includes(x.site));

        let dateCompletion = '';
        this.justificationInfos = [];

        let dateCompletionSitesDemandesClientBsCnGaNo;
        if (sitesDemandesClientBsCnGaNo.length > 0) {
            dateCompletionSitesDemandesClientBsCnGaNo = this.getDateEngagement(this.demandesClientUtils.joursFeries, this.nbJOursOuvrablesTravauxBsCnGaNo);
            const justificationInfo = new JustificationInfo;
            justificationInfo.numerosOrdre = sitesDemandesClientBsCnGaNo.map(x => x.numeroOrdre);
            justificationInfo.justification = this.translateService.instant(this.translatePrefix + 'justificationEngagement',
                { datePriseEnCharge: datePriseEnCharge, dateCompletion: dateCompletionSitesDemandesClientBsCnGaNo });
            this.justificationInfos.push(justificationInfo);

            if (sitesDemandesClientAutres.length === 0) {
                dateCompletion = dateCompletionSitesDemandesClientBsCnGaNo;
            }
        }
        let dateCompletionSitesDemandesClientAutres;
        if (sitesDemandesClientAutres.length > 0) {
            dateCompletionSitesDemandesClientAutres = this.getDateEngagement(this.demandesClientUtils.joursFeries, this.nbJOursOuvrablesTravaux);
            const justificationInfo = new JustificationInfo;
            justificationInfo.numerosOrdre = sitesDemandesClientAutres.map(x => x.numeroOrdre);
            justificationInfo.justification = this.translateService.instant(this.translatePrefix + 'justificationEngagement',
                {
                    datePriseEnCharge: datePriseEnCharge,
                    dateCompletion: this.getDateEngagement(this.demandesClientUtils.joursFeries, this.nbJOursOuvrablesTravaux)
                });
            this.justificationInfos.push(justificationInfo);

            if (sitesDemandesClientBsCnGaNo.length === 0) {
                dateCompletion = dateCompletionSitesDemandesClientAutres;
            }
        }

        if (sitesDemandesClientBsCnGaNo.length > 0 && sitesDemandesClientAutres.length > 0) {
            dateCompletion = `[${dateCompletionSitesDemandesClientAutres} ou ${dateCompletionSitesDemandesClientBsCnGaNo} pour les sites BS, CN, GA et NO]`;
        }

        this.justification.setValue(this.translateService.instant(this.translatePrefix + 'justificationEngagement',
            { datePriseEnCharge: datePriseEnCharge, dateCompletion: dateCompletion }));
        this.justification?.markAsTouched();
        if (this.demandesClient.length === 1) {
            this.justification?.enable();
        }
    }

    public getDateEngagement(holidays: string[], nbBusinessDays: number, startDate = moment()): string {
        let resultDate = startDate;  //Date d'aujourd'hui
        let addedDays = 0;
        while (addedDays < nbBusinessDays) {
            const nextDay = resultDate.add(1, 'days');
            // Si journée entre lundi et vendredi et non fériée
            if ((nextDay.day() > 0 && nextDay.day() < 6) && !holidays.includes(nextDay.format('YYYY-MM-DD'))) {
                addedDays++;
            }
            resultDate = nextDay;
        }

        return moment(resultDate).format('YYYY-MM-DD');
    }

    public holidaysSelection$: Observable<DemandesClientUtils[] | null> = this.store.pipe(
        select(getDemandesClientUtils),
        distinctUntilChanged(),
        tap((demandesClientUtils: DemandesClientUtils[]) => {
            this.demandesClientUtils = demandesClientUtils[0];
        })
    );

    private loadHolidays(): void {
        //On utilise le plus grand nombre de jours ouvrables
        const maxNumberOfBusinessDays = Math.max(...[this.nbJOursOuvrablesPriseEnCharge, this.nbJOursOuvrablesTravaux, this.nbJOursOuvrablesTravauxBsCnGaNo]);

        this.holidaysSelection$.subscribe();

        if (this.demandesClientUtils === undefined || this.demandesClientUtils === null || this.demandesClientUtils.dateDemande !== moment().format('YYYYMMDD')) {
            this.store.dispatch(new LoadHolidays(maxNumberOfBusinessDays));
        }
    }

    private loadUtilisateursInternes(): void {
        this.store.dispatch(new LoadUtilisateursInternes(AlternativePath.hq));
    }

    private populateAllSelectOptions(): void {
        this.moyenSelectOptions = this.createEnumSelectOptions(MoyenEnum, `${this.commonTranslatePrefix}MoyenEnum.`);
        this.typeEngagementSelectOptions = this.createEnumSelectOptions(TypeEngagementEnum, `${this.commonTranslatePrefix}TypeEngagementEnum.`);
        const indexA1 = this.typeEngagementSelectOptions.findIndex((typeEngagement: SelectItem) => typeEngagement.value === TypeEngagementEnum.A1);
        this.typeEngagementSelectOptions[indexA1].disabled = true;
    }

    private createEnumSelectOptions(enumeration: any, translatePrefixe: Readonly<string> = ''): SelectItem[] {
        return Object.values(enumeration).map((value: any) => {
            return {
                label: translatePrefixe.length > 0 ? this.translateService.instant(translatePrefixe + value) : value,
                value: value,
            } as SelectItem;
        });
    }

    public onEdit(): void {
        this.copieOriginalValue();
        this.isNouvelEngagementClicked = false;
        this.emitNewEngagementClickChange();
    }

    private copieOriginalValue(): void {
        this.originalValue = this.engagementForm.value;
    }

    public get justification(): AbstractControl {
        return this.engagementForm.controls.justification;
    }

    public get dateEngagement(): AbstractControl {
        return this.engagementForm.controls.dateEngagement;
    }

    public get moyen(): AbstractControl {
        return this.engagementForm.controls.moyen;
    }

    public get prisPar(): AbstractControl {
        return this.engagementForm.controls.prisPar;
    }

    public get typeEngagement(): AbstractControl {
        return this.engagementForm.controls.typeEngagement;
    }

    public nouvelEngagement(): void {
        this.isNouvelEngagementClicked = true;
        this.setEngagementDefaultValues();
        this.addEngagementValidators();
        this.updateEngagementValidity();
        this.onChange();
        this.emitNewEngagementClickChange();
    }

    public annulerEngagementModifications(): void {
        this.isNouvelEngagementClicked = false;
        this.revertEngagementToOriginal();
        this.removeEngagementValidators();
        this.emitNewEngagementClickChange();
    }

    private revertEngagementToOriginal(): void {
        this.engagementForm.controls.dateEngagement.setValue(this.originalValue.dateEngagement);
        this.engagementForm.controls.dateEngagement.markAsUntouched();
        this.engagementForm.controls.dateEngagement.disable();

        this.engagementForm.controls.typeEngagement.setValue(this.originalValue.typeEngagement);
        this.engagementForm.controls.typeEngagement.markAsUntouched();
        this.engagementForm.controls.typeEngagement.disable();

        this.engagementForm.controls.moyen.setValue(this.originalValue.moyen);
        this.engagementForm.controls.moyen.markAsUntouched();
        this.engagementForm.controls.moyen.disable();

        this.engagementForm.controls.prisPar.setValue(this.originalValue.prisPar);
        this.engagementForm.controls.prisPar.markAsUntouched();
        this.engagementForm.controls.prisPar.disable();

        this.engagementForm.controls.justification.setValue(this.originalValue.justification);
        this.engagementForm.controls.justification.markAsUntouched();
        this.engagementForm.controls.justification.disable();
    }

    private setEngagementInitialValues(): void {
        if (this.demandesClient.length === 1) {
            const demandeClient = this.demandesClient[0];
            this.dateEngagement.setValue(demandeClient.ordre.dateEngagement);
            this.typeEngagement.setValue(demandeClient.ordre.typeEngagement);
            this.moyen.setValue(demandeClient.ordre.moyen);
            this.prisPar.setValue(demandeClient.ordre.prisPar);
            this.justification.setValue(demandeClient.ordre.justification);
        }
    }

    private setEngagementDefaultValues(): void {
        // Valeur par défaut = la date du jour + 56 jours
        this.dateEngagement?.enable();
        this.dateEngagement?.setValue(moment(moment().add(56, 'days')).format('YYYY/MM/DD'));
        this.dateEngagement?.markAsTouched();
        // Valeur par défaut = E1
        this.typeEngagement?.enable();
        this.typeEngagement?.setValue(TypeEngagementEnum.E1);
        this.typeEngagement?.markAsTouched();
        // Valeur par défaut = M02
        this.moyen?.enable();
        this.moyen?.setValue(MoyenEnum.M02);
        this.moyen?.markAsTouched();
        // Valeur par défaut = l'utilisateur connecté
        this.prisPar?.enable();
        if (this.currentUser) {
            this.prisPar?.setValue(this.currentUser.cip);
            this.prisPar?.markAsTouched();
        }
        this.updateJustification();
    }

    private addEngagementValidators(): void {
        this.dateEngagement?.setValidators(Validators.required);
        this.typeEngagement?.setValidators(Validators.required);
        this.moyen?.setValidators(Validators.required);
        this.prisPar?.setValidators(Validators.required);
        this.justification?.setValidators(Validators.required);
    }

    private removeEngagementValidators(): void {
        this.dateEngagement?.clearValidators();
        this.typeEngagement?.clearValidators();
        this.moyen?.clearValidators();
        this.prisPar?.clearValidators();
        this.justification?.clearValidators();
    }

    public clearDate(field: Readonly<string>): void {
        this.engagementForm.controls[field].setValue(null);
        this.engagementForm.controls[field].markAsTouched();
        this.onChange();
    }

    public selectDate(event: Event, field: Readonly<string>): void {
        this.engagementForm.controls[field].setValue(event);
        this.engagementForm.controls[field].markAsTouched();
        this.onChange();
    }

    private updateEngagementValidity(): void {
        this.dateEngagement.updateValueAndValidity();
        this.typeEngagement.updateValueAndValidity();
        this.moyen.updateValueAndValidity();
        this.prisPar.updateValueAndValidity();
        this.justification.updateValueAndValidity();

        this.engagementValidityChange.emit(this.engagementForm.valid);
    }

    public onChange(): void {
        this.updateEngagementValidity();

        if (this.engagementForm.valid) {
            const engagement = Object.assign({}, this.engagementForm.value);
            engagement.justificationInfos = this.justificationInfos;
            if (this.demandesClient.length === 1) {
                engagement.justificationInfos[0].justification = this.justification.value;
            }
            this.engagementChange.emit(engagement);
        }
    }

    private emitNewEngagementClickChange(): void {
        this.newEngagementClickChange.emit(this.isNouvelEngagementClicked);
    }
}

