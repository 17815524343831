<app-generic-module-header
    [moduleLogo]="'fas fa-tools'"
    [moduleTitle]="translatePrefix + '.title' | translate"
    [hideButton]="true"
></app-generic-module-header>

<app-element-loader id="spinner" *ngIf="(isSpecialMeasuresLoading$ | async)" [displayLabel]="true"></app-element-loader>

<div *ngIf="mesPrescriptions !== []" class="tableContainer">
    <div class="action">
        <app-erase-all-filter class="action__left" (clicked)="clearAllFilters()" [disabled]="!filtersApply"></app-erase-all-filter>
        <div class="action__right">
            <button pButton icon="fa fa-download" id="export" class="action__button right" [disabled]="isRowEditing || mesPrescriptions.length === 0"
                    (click)="exportExcelFile()" [label]="translatePrefix + '.buttons.export' | translate">
            </button>
            <button pButton icon="fa fa-sync-alt" id="reload" class="action__button" [disabled]="isRowEditing"
                    (click)="reload()" [label]="translatePrefix + '.buttons.reload' | translate">
            </button>
        </div>
    </div>

    <form [formGroup]="prescriptionForm" class="tableContainer__form">
        <div formArrayName="prescriptionDetails" class="prescriptionDetails">
            <!-- <p-table #mpTable
                [value]="mesPrescriptions"
                dataKey="ID"
                editMode="row"
                [scrollable]="true"
                scrollDirection="vertical"
                scrollHeight="600px"
                [columns]="scrollablePrescriptionCols"
                [sortField]="sortField"
                [sortOrder]="sortOrder"
                [rowHover]="true"
                [rows]="100"
                [virtualScroll]="true"
                [virtualRowHeight]="80"
                > -->
            <p-table #mpTable
                [paginator]="true"
                [rows]="5"
                currentPageReportTemplate="Affiche de {first} à {last} sur {totalRecords} items" [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[5,10,25,50,100,200]"
                [scrollable]="true"
                scrollHeight="100%"
                [value]="mesPrescriptions"
                dataKey="ID"
                editMode="row"
                [columns]="scrollablePrescriptionCols"
                [sortField]="sortField"
                [sortOrder]="sortOrder"
                [rowHover]="true"
                [virtualRowHeight]="50"
                >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns; let i = index" [pSortableColumn]="col.field" pFrozenColumn [frozen]="col.frozen"
                          class="t__header" [class.border]="i === 1" [style]="{ 'width': col.width }">
                            <div class="header__shape">
                                <span class="shape__name">
                                    {{ translatePrefix + '.headers.' + col.header | translate }}
                                </span>

                                <p-sortIcon *ngIf="col.noFilter === undefined || !col.noFilter" class="shape__sort" [field]="col.field"
                                    [attr.aria-label]="'common.sort' | translate"
                                    [attr.aria-label-desc]="'common.sortDesc' | translate"
                                    [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns; let i = index" class="t__header" pFrozenColumn [frozen]="col.frozen"
                          [class.border]="i === 1" [style]="{ 'width': col.width }">
                            <div [ngSwitch]="col.filterType">
                                <p-multiSelect *ngSwitchCase="'multi-select'"
                                    appendTo="body"
                                    defaultLabel="{{'common.filter' | translate}}"
                                    [(ngModel)]="filtersEntries[col.field]"
                                    [ngModelOptions]="{standalone: true}"
                                    [options]="tableFiltersItems[col.field]"
                                    optionLabel="name"
                                    [filter]="false"
                                    [maxSelectedLabels]="1"
                                    [showToggleAll]="true"
                                    [selectedItemsLabel]="'common.selectedItems' | translate"
                                    class="p-column-filter"
                                    (onChange)="onFilterChange($event.value, col.field)">
                                </p-multiSelect>

                                <input pInputText *ngSwitchDefault [disabled]="isRowEditing" type="text"
                                    [(ngModel)]="filtersEntries[col.field]"
                                    [ngModelOptions]="{standalone: true}"
                                    id="id-{{col.field}}" (input)="inputFilterPrescriptionTable($event, col.field)"
                                    [placeholder]="'common.filter' | translate"
                                    [attr.aria-placeholder]="'common.filter' | translate">
                            </div>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-columns="columns" let-prescription let-editing="editing">
                    <tr class="body-row" [pEditableRow]="prescription" [formGroupName]="prescription.ID" style="height:50px">
                        <ng-container *ngFor="let col of columns; let i = index; trackBy: trackByColumnField">
                            <ng-container [ngSwitch]="true">
                                <ng-container *ngSwitchCase="col.field === 'actions' || col.field === 'Numero'">
                                    <td pEditableColumnDisabled pFrozenColumn [frozen]="col.frozen" class="t__body" [class.border]="i === 1" [style]="{ 'width': col.width }">
                                        <ng-container *ngIf="col.field === 'actions'">
                                            <button *ngIf="!editing" [disabled]="isRowEditing" pButton pRipple type="button" pInitEditableRow icon="fas fa-pen" (click)="onRowEditInit(prescription)" class="p-button-rounded p-button-text"></button>
                                            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="fas fa-check" (click)="onRowEditSave(prescription)" class="p-button-rounded p-button-text p-button-success"></button>
                                            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="fas fa-times" (click)="onRowEditCancel(prescription)" class="p-button-rounded p-button-text p-button-danger"></button>
                                        </ng-container>
                                        <ng-container *ngIf="col.field === 'Numero'">
                                            <div (click)="openOGIVPrescription(prescription.Numero)">
                                                <a>{{prescription.Numero}}</a>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <td pEditableColumn  pFrozenColumn [frozen]="col.frozen"
                                      class="t__body" [class.border]="i === 1" [style]="{ 'width': col.width }">
                                        <ng-container [ngSwitch]="col.field">
                                            <!-- <div *ngSwitchCase=""></div>
                                            <div *ngSwitchCase=""></div> -->
                                            <div *ngSwitchCase="['Statut', 'Pole', 'Site', 'Poste', 'Ligne', 'Commande', 'PosteCommande', 'Adresse', 'LCLCL', 'Residu', 'PROVENANCE', 'NumeroImputation', 'MesurePartPar', 'TypeMesurePart', 'JustificationMesurePart', 'CommentaireMesurePart', 'QteCouvreConducteurs', 'QteCCIsolateurFinCourse', 'QteCCIsolateurTete', 'DureeMiseHorsTension', 'Code', 'MesurePartApprouvParEnt', 'MesurePartApprouvParHQ', 'NoteInterne', 'Remarque', 'TypeRemuneration'].includes(col.field) ? col.field : ''">
                                                {{prescription[col.field]}}
                                            </div>
                                            <div *ngSwitchCase="'TYPE_RESEAU'">
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <p-dropdown *ngIf="editing" [options]="$any(reseauxOptions$ | async)" [formControlName]="col.field" [style]="{'width':'100%'}"
                                                                    [placeholder]="translatePrefix + '.selectReseau' | translate" [filter]="true" appendTo="body"
                                                                    (onChange)="reseauxValueChanges($event.value, prescription)">
                                                        </p-dropdown>
                                                        <span *ngIf="!editing">{{prescription[col.field]}}</span>
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{prescription[col.field]}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </div>
                                            <div *ngSwitchCase="'DESCR_NACELLE_CHENILLE'">
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <p-dropdown *ngIf="editing" [options]="$any( nacellesOptions$ | async)" [formControlName]="col.field" [style]="{'width':'100%'}"
                                                                    [placeholder]="translatePrefix + '.selectNacelle' | translate" [filter]="true" appendTo="body"
                                                                    (onChange)="nacellesValueChanges($event.value, prescription)">
                                                        </p-dropdown>
                                                        <span *ngIf="!editing">{{prescription[col.field]}}</span>
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{prescription[col.field]}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </div>
                                            <div *ngSwitchCase="'MesurePartLe'">
                                                {{prescription[col.field] | date: col.format }}
                                            </div>
                                            <div *ngSwitchCase="['DATE_PRCH_VISITE', 'DATE_REMISE', 'DATE_PLANIF', 'DATE_COMPLETE'].includes(col.field) ? col.field : ''">
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <p-calendar *ngIf="editing" [formControlName]="col.field" dateFormat="yy-mm-dd"
                                                                    [placeholder]="translatePrefix + '.selectDate' | translate"
                                                                    appendTo="body"
                                                                    [showButtonBar]="true"
                                                                    (onClearClick)="clearDate($event, col.field)"
                                                                    (onSelect)="selectDate($event, col.field)">
                                                        </p-calendar>
                                                        <span *ngIf="!editing">{{prescription[col.field] | date: col.format }}</span>
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{prescription[col.field] | date: col.format }}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </div>
                                            <div *ngSwitchDefault>
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <input *ngIf="editing" pInputText type="text" [formControlName]="col.field">
                                                        <span *ngIf="!editing">{{prescription[col.field]}}</span>
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{prescription[col.field]}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
                    <tr style="border-bottom: 1px solid #c8c8c8;">
                        <td [attr.colspan]="2" pFrozenColumn [frozen]="true" style="flex: unset; border-bottom: unset;">
                            <h3>{{getEmptyMessage()}}</h3>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </form>
</div>

<p-dialog [header]="headerDialog" [(visible)]="showNacelleDialog || showReseauDialog" [modal]="true">
    <ng-template pTemplate="body">
        <form [formGroup]="newItemForm" (ngSubmit)="onSubmit()">
            <input type="text" pInputText class="new-item-input" formControlName="name" [placeholder]="placeholderDialog" autofocus>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="submit" pButton icon="pi pi-check" (click)="onSubmit()" [disabled]="!newItemInDropDown || newItemInDropDown.trim() === ''" [label]="translatePrefix + '.dialog.confirm' | translate"></button>
        <button type="button" pButton icon="pi pi-times" (click)="cancelDialog()" [label]="translatePrefix + '.dialog.cancel' | translate"></button>
    </ng-template>
</p-dialog>
