import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
    LoadTimesheetReportsSummaryTech,
    LoadTimesheetReportsSummaryTechFail,
    LoadTimesheetReportsSummaryTechNoresult,
    LoadTimesheetReportsSummaryTechSuccess,
    TimesheetReportSummaryTechActionTypes,
} from '../actions/timesheet-reports-summary-tech.action';
import { ApiService } from '../../../ogiv-core/services';
import { TimesheetReportSummary } from '../../../shared/models/database/timesheet-report-summary.model';

@Injectable()
export class TimesheetReportsSummaryTechEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public loadTimesheetReports$ = createEffect(() => this.actions$.pipe(
        ofType<LoadTimesheetReportsSummaryTech>(TimesheetReportSummaryTechActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_TECH),
        switchMap((action: LoadTimesheetReportsSummaryTech) => {
            const queryString = (action.queryString !== undefined && action.queryString !== null && action.queryString !== '') ? `${action.queryString}` : '';
            return this.apiService.get<TimesheetReportSummary[]>(`${action.path}/rapports/rapports${queryString}`).pipe(
                map((timesheetReports: TimesheetReportSummary[]) => (timesheetReports.length > 0)
                    ? new LoadTimesheetReportsSummaryTechSuccess(
                        timesheetReports.map(timesheetReport => new TimesheetReportSummary(timesheetReport))
                    )
                    : new LoadTimesheetReportsSummaryTechNoresult([])
                ),
                catchError((error: any) => of(new LoadTimesheetReportsSummaryTechFail(error))),
            );
        }),
    ));
}
