<div class="fullheight">
    <form [formGroup]="form">
        <h4 class="fullheight__title">Entrer de nouvelles valeurs</h4>
        <div class="iab__input group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ translatePrefix + '.commande' | translate }}
                </mat-label>
                <input matInput type="text" formControlName="order" />
            </mat-form-field>
        </div>
        <div class="iab__input group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ translatePrefix + '.poste' | translate }}
                </mat-label>
                <input matInput type="text" formControlName="accountingEntry" />
            </mat-form-field>
        </div>
        <div class="iab__input group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ translatePrefix + '.type' | translate }}</mat-label>
                <mat-select formControlName="billingType">
                    <mat-option *ngFor="let type of typesFacturation" [value]="type.value">{{type.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <div class="action-button">
        <button mat-raised-button type="button" class="button__cancel" (click)="onCancel()">{{ translatePrefix + '.buttons.cancel' | translate | uppercase }}</button>
        <button mat-raised-button type="button" class="button__save" (click)="onUpdate()" [disabled]="form.invalid">{{ translatePrefix + '.buttons.save' | translate | uppercase }}</button>
    </div>
</div>
