import { Component, Input } from '@angular/core';
import { getOrderCss } from '../../../../../shared/models/database/order-status.enum';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent {
    public cssClass = '';

    @Input() public isLock = false;
    @Input() public logo = '';
    @Input() public number = 0;
    @Input() public set status(value: string) {
        this.cssClass = getOrderCss(value);
    }

    constructor() { }
}
