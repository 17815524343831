import { Action } from '@ngrx/store';
import { DomaineDeValeur } from '../../../shared/models/database/domaine-valeur.model';

/**
 * TYPES
 */
export enum DomaineDeValeurActionTypes {
    LOAD_DOMAINE_VALEUR = '[DomaineDeValeur] Load Domaine de valeur',
    LOAD_DOMAINE_VALEUR_SUCCESS = '[DomaineDeValeur] Load Domaine de valeur Success',
    LOAD_DOMAINE_VALEUR_FAIL = '[DomaineDeValeur] Load Domaine de valeur Fail',
}

/**
 * ACTIONS
 */
export class LoadDomaineDeValeur implements Action {
    readonly type = DomaineDeValeurActionTypes.LOAD_DOMAINE_VALEUR;
    constructor(public concern: string, public typeDomaineDeValeur: string) { }
}

export class LoadDomaineDeValeurSuccess implements Action {
    readonly type = DomaineDeValeurActionTypes.LOAD_DOMAINE_VALEUR_SUCCESS;
    constructor(public valeurs: DomaineDeValeur[]) { }
}

export class LoadDomaineDeValeurFail implements Action {
    readonly type = DomaineDeValeurActionTypes.LOAD_DOMAINE_VALEUR_FAIL;
    constructor(public error: any) { }
}

export type DomaineDeValeurAction =
    | LoadDomaineDeValeur
    | LoadDomaineDeValeurSuccess
    | LoadDomaineDeValeurFail;
