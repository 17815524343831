import { createFeatureSelector } from '@ngrx/store';
import { demandesClientReducer, DemandesClientState } from './demandes-client.reducers';
import { demandesClientEcartsReducer, DemandesClientEcartsState } from './demandes-client-ecarts.reducers';
import { demandesClientUtilsReducer, DemandesClientUtilsState } from './demandes-client-utils.reducers';

/* eslint-disable */
export interface DemandesClientGlobalState {
    demandesClient: DemandesClientState;
    demandesClientEcarts: DemandesClientEcartsState;
    demandesClientUtils: DemandesClientUtilsState;
}

export const demandesClientStoreReducers: any = {
    demandesClient: demandesClientReducer,
    demandesClientEcarts: demandesClientEcartsReducer,
    demandesClientUtils: demandesClientUtilsReducer,
};

export const getDemandesClientGlobalState = createFeatureSelector<DemandesClientGlobalState>('demandesClient');
