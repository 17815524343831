import { TimesheetReportStatus } from './timesheet-report-status.enum';
import { OrderType } from './order-type.enum';
import { Approval } from './approval.model';
import { TimesheetRemark } from './timesheet-remark.model';

export interface WeekdayStatus {
    isDisabled: boolean;
    isRefused: boolean;
}
export interface WeekdaysStatus {
    day0: WeekdayStatus;
    day1: WeekdayStatus;
    day2: WeekdayStatus;
    day3: WeekdayStatus;
    day4: WeekdayStatus;
    day5: WeekdayStatus;
    day6: WeekdayStatus;
}

export class TimesheetReportSummaryRaw {
    public accountingEntry?: string = undefined;
    public approvalEntBy?: string | null = undefined;
    public approvalEntDate?: string | null = undefined;
    public approvals?: Approval[] | null = undefined;
    public billingType?: OrderType = undefined;
    public companyId?: number = undefined;
    public company?: string = undefined;
    public createdBy?: string = undefined;
    public createdDate?: Date = undefined;
    public errors?: string[] | null = undefined;
    public id?: number = undefined;
    public invoiceReportId?: number | null = undefined;
    public invoiceReportNumber?: string | null = undefined;
    public modifiedBy?: string = undefined;
    public modifiedDate?: Date = undefined;
    public number?: string = undefined;
    public order?: string = undefined;
    public partialStatus?: string | null = undefined;
    public remark?: string = undefined;
    public remarkNCEnt?: string | null = undefined;
    public remarkNCHQ?: string | null = undefined;
    public remarksTimeSheets?: TimesheetRemark[] = undefined;
    public site?: string | null = undefined;
    public status?: TimesheetReportStatus = undefined;
    public teamLeader?: string = undefined;
    public nameTeamLeader?: string = undefined;
    public week?: Date = undefined;
    public weekNumber?: number = undefined;
    public weekdaysStatus?: WeekdaysStatus = undefined;
}

export class TimesheetReportSummary {
    public accountingEntry: string;
    public approvals?: Approval[] | null;
    public approvalEntBy?: string | null;
    public approvalEntDate?: string | null;
    public billingType: OrderType;
    public company: string;
    public companyId: number;
    public createdBy?: string;
    public createdDate?: Date;
    public errors?: string[] | null;
    public id: number;
    public invoiceReportId?: number | null;
    public invoiceReportNumber?: string | null;
    public modifiedBy?: string;
    public modifiedDate?: Date;
    public number: string;
    public order: string;
    public partialStatus: string | null;
    public remark?: string;
    public remarkNCEnt?: string | null;
    public remarkNCHQ?: string | null;
    public remarksTimeSheets: TimesheetRemark[];
    public site?: string | null;
    public status: TimesheetReportStatus;
    public teamLeader: string;
    public nameTeamLeader: string;
    public week: Date;
    public weekNumber: number;
    public weekdaysStatus: WeekdaysStatus;

    constructor(data: TimesheetReportSummaryRaw) {
        Object.keys(data).forEach((key: string) => {
            if (key in new TimesheetReportSummaryRaw) {
                switch (key) {
                    case 'week':
                    case 'createdDate':
                    case 'modifiedDate':
                        const date = data[key];
                        if (date) {
                            this[key] = new Date(date);
                        }
                        break;

                    case 'Order':
                        this[key] = data.order || '';
                        break;

                    case 'billingType':
                        const orderBillingType = data[key];
                        if (orderBillingType) {
                            switch (orderBillingType) {
                                case OrderType.unit:
                                    this[key] = OrderType.unit;
                                    break;
                                case OrderType.hourly:
                                    this[key] = OrderType.hourly;
                                    break;
                                default:
                                    this[key] = OrderType.outage;
                                    break;
                            }
                        }
                        break;

                    default:
                        this[key] = data[key];
                        break;
                }
            }
        });
    }

    public getUniqueId(): number {
        return this.id;
    }
}
