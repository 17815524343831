import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-element-loader',
    templateUrl: './element-loader.component.html',
    styleUrls: ['./element-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementLoaderComponent {
    @Input() size = 75;
    @Input() opacity = 8;
    @Input() displayLabel = false;
    @Input() label = 'Veuillez patienter...';
    @Input() labelFontSize = 16;
    @Input() position = 'absolute';
}
