<div>
    <app-remark-cell *ngIf="report.remark"
        [title]="translationPrefix + '.titleTSReport' | translate"
        [remark]="report.remark"
        [translationPrefix]="translationPrefix"
    ></app-remark-cell>
    <mat-divider *ngIf="report.remark"></mat-divider>

    <app-remark-cell *ngIf="report.validatedComment"
        [title]="validatedRemarkBy"
        [remark]="report.validatedComment"
        [status]="refusedStatus"
        [translationPrefix]="translationPrefix"
    ></app-remark-cell>
    <mat-divider *ngIf="report.validatedComment"></mat-divider>

    <app-remark-cell *ngIf="report.remarkNCEnt"
        [title]="titleEnt"
        [remark]="report.remarkNCEnt"
        [date]="report.approvalEntDate"
        [name]="report.approvalEntBy"
        [status]="report.status"
        [translationPrefix]="translationPrefix"
    ></app-remark-cell>
    <mat-divider *ngIf="report.remarkNCEnt"></mat-divider>

    <ng-container *ngFor="let approval of report.approvals; index as i">
        <app-remark-cell *ngIf="approval.status === 'Refusé' && approval.roleRefused === 'CGC'"
            [title]="titleHQCGC"
            [remark]="approval.remark"
            [siteName]="approval.site"
            [date]="approval.date"
            [name]="approval.user"
            [displaySiteName]="true"
            [status]="approval.status"
            [translationPrefix]="translationPrefix"
        ></app-remark-cell>
        <app-remark-cell *ngIf="approval.status === 'Refusé' && approval.roleRefused !== 'CGC'"
            [title]="titleHQ"
            [remark]="approval.remark"
            [siteName]="approval.site"
            [date]="approval.date"
            [name]="approval.user"
            [displaySiteName]="true"
            [status]="approval.status"
            [translationPrefix]="translationPrefix"
        ></app-remark-cell>
        <mat-divider *ngIf="i < (report.approvals.length - 1)"></mat-divider>
    </ng-container>

    <ng-container *ngFor="let approval of report.approvals; index as i">
        <app-remark-cell *ngIf="approval.status === 'En attente'"
            [title]="titleHQOrigin"
            [remark]="approval.remark"
            [siteName]="approval.site"
            [date]="approval.date"
            [name]="approval.user"
            [displaySiteName]="true"
            [translationPrefix]="translationPrefix"
        ></app-remark-cell>
        <mat-divider *ngIf="i < (report.approvals.length - 1)"></mat-divider>
    </ng-container>
</div>

<app-generic-form-buttons [displayCancelButton]="displayCancelButton" (save)="onClose()"></app-generic-form-buttons>
