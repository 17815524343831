import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { MatDialog } from '@angular/material/dialog';

import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { BillingReport } from '../../../../../shared/models/database/billing-report.model';
import { DeleteBillingReport, LoadBillingReports } from '../../../../store/actions/billing-report.action';
import { FormMode } from '../../../../../shared/models/form-mode.model';
import { getAllBillingReports, getBillingReportsLoading } from '../../../../store/selectors/billing-report.selector';
import { Go } from '../../../../../core/store/actions/router.actions';
import { ModalWindowComponent } from '../../../../../shared/components/modal-window/modal-window.component';
import { ModalWindowData } from '../../../../../shared/components/modal-window/modal-window-data.model';
import { TableColumns } from '../../../../../shared/models/table-columns.model';
import { TableAction, TableActionIconType, TableActionType, TableEventParam } from '../../../../../shared/models/table-action.model';

@Component({
    selector: 'app-contractor-billing-reports-list',
    templateUrl: './contractor-billing-reports-list.component.html',
    styleUrls: ['./contractor-billing-reports-list.component.scss'],
})
export class ContractorBillingReportsListComponent implements OnInit {
    public sortField = 'createdDate';
    public sortOrder = -1;
    public translatePrefix = 'billing.contractor.summary';
    public pathChoice = AlternativePath.ent;

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<any>,
        private translateService: TranslateService,
        private confirmationService: ConfirmationService
    ) { }

    public reports: BillingReport[];
    public cols: TableColumns[] = [
        { field: 'invoiceNumber', header: 'invoiceNumber' },
        { field: 'order', header: 'order' },
        { field: 'accountingEntry', header: 'accountingEntry' },
        { field: 'totalQuantities', header: 'totalQuantities' },
        { field: 'totalHours', header: 'totalHours' },
        { field: 'status', header: 'status', type: 'tag' },
        { field: 'createdDate', header: 'invoiceDate', type: 'date', format: 'yyyy-MM-dd' },
        { field: 'validate', header: 'validate', type: 'yesno' },
        { field: 'validatedComment', header: 'remark', type: 'remark', noFilter: true }
    ];

    public actions: TableAction[] = [
        { code: TableActionType.OPEN, icon: TableActionIconType.SEARCH },
        { code: TableActionType.PREVIEW, icon: TableActionIconType.PREVIEW },
    ];

    public billingReports$: Observable<BillingReport[]> = this.store.pipe(
        select(getAllBillingReports)
    );

    public billingReportsLoading$: Observable<boolean> = this.store.pipe(
        select(getBillingReportsLoading)
    );

    public ngOnInit(): void {
        this.store.dispatch(new LoadBillingReports(this.pathChoice));
    }

    public onTableEvent(event: TableEventParam): void {

        const report = event.rowData as BillingReport;

        switch (event.actionCode) {
            case TableActionType.OPEN: {

                let path = ['facturation', 'rapports', this.pathChoice, report && report.id.toString()];

                if (this.isReportReadOnly(report)) {
                    path = ['facturation', 'rapports', this.pathChoice, FormMode.display, report && report.id.toString()];
                }

                this.store.dispatch(new Go(
                    { path: path }
                ));
                break;
            }
            case TableActionType.CREATE: {
                this.store.dispatch(new Go(
                    { path: ['facturation', 'rapports', this.pathChoice, 'creer'] }
                ));
                break;
            }
            case TableActionType.PREVIEW: {
                this.store.dispatch(new Go(
                    { path: ['facturation', 'rapports', this.pathChoice, 'preview', report && report.id.toString()] }
                ));
                break;
            }
            case TableActionType.DELETE: {
                this.deleteBillingReport(report);
                break;
            }
        }
    }

    public deleteBillingReport(report: BillingReport): void {
        this.confirmationService.confirm({
            message: this.translateService.instant(this.translatePrefix + '.deleteDialog' + '.message'),
            header: this.translateService.instant(this.translatePrefix + '.deleteDialog' + '.title'),
            icon: 'pi pi-question-circle',
            acceptLabel: this.translateService.instant('common.confirm'),
            rejectLabel: this.translateService.instant('common.cancel'),
            accept: () => {
                this.store.dispatch(new DeleteBillingReport(report.id, this.pathChoice));
            },
        });
    }

    public isEnabledActionButton = (actionCode: string, report?: BillingReport): boolean => {
        if (actionCode === TableActionType.DELETE && this.isReportReadOnly(report)) {
            return false;
        }
        return true;
    };

    private isReportReadOnly(report?: BillingReport): boolean {
        return report?.validate === 1;
    }

    public onDisplayRemarks(report: BillingReport): void {
        this.dialog.open(ModalWindowComponent, {
            width: '80%',
            maxWidth: '650px',
            data: {
                icon: 'fas fa-exclamation',
                componentName: 'displayRemark',
                titleKey: 'title',
                translatePrefix: this.translatePrefix + '.remarkPopup',
                report: report as any,
            } as ModalWindowData
        });
    }
}
