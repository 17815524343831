import { TransportCode } from '../../../shared/models/database/transport-code.model';
import { TransportCodeAction, TransportCodeActionType } from '../actions/transport-codes.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface TransportCodesState extends EntityState<TransportCode> {
    loading: boolean;
    loaded: boolean;
}

export const transportCodesAdapter: EntityAdapter<TransportCode> = createEntityAdapter<TransportCode>({
    selectId: (transportCode: TransportCode) => transportCode.id,
});

export const transportCodesInitialState = transportCodesAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function transportCodeReducer(
    state: TransportCodesState = transportCodesInitialState,
    action: TransportCodeAction,
): TransportCodesState {
    switch (action.type) {
        case TransportCodeActionType.CREATE_TRANSPORT_CODE:
        case TransportCodeActionType.UPDATE_ONE_TRANSPORT_CODE:
        case TransportCodeActionType.LOAD_TRANSPORT_CODES: {
            return {
                ...state,
                loading: true,
            };
        }

        case TransportCodeActionType.CREATE_TRANSPORT_CODE_SUCCESS: {
            return transportCodesAdapter.addOne(action.transportCode, {
                ...state,
                loading: false,
            });
        }

        case TransportCodeActionType.UPDATE_ONE_TRANSPORT_CODE_SUCCESS: {
            return transportCodesAdapter.upsertOne(action.transportCode, {
                ...state,
                loading: false,
            });
        }

        case TransportCodeActionType.CREATE_TRANSPORT_CODE_FAIL:
        case TransportCodeActionType.UPDATE_ONE_TRANSPORT_CODE_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case TransportCodeActionType.LOAD_TRANSPORT_CODES_SUCCESS: {
            return transportCodesAdapter.setAll(action.transportCodes, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case TransportCodeActionType.LOAD_TRANSPORT_CODES_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case TransportCodeActionType.LOAD_TRANSPORT_CODES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
