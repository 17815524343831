import { ActionReducer, State } from '@ngrx/store';
import { environment } from '@app/environment';

export function logger(
    reducer: ActionReducer<State<any>>
) {
    return (state: State<any>, action: any) => {
        if (environment.config.logStoreActions) {
            /* eslint-disable-next-line no-restricted-syntax */
            console.log('[Store] State', state);
            /* eslint-disable-next-line no-restricted-syntax */
            console.log('[Store] Action', action);
        }

        return reducer(state, action);
    };
}
