import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { requireControlToBeFilledValidator } from '../../../../../shared/validators/require-control-to-be-filled.validator';
import { OrderType } from '../../../../../shared/models/database/order-type.enum';

@Component({
    selector: 'app-edit-commande-poste-type-form',
    templateUrl: './edit-commande-poste-type-form.component.html',
    styleUrls: ['./edit-commande-poste-type-form.component.scss']
})
export class EditCommandePosteTypeFormComponent {
    public form: FormGroup;

    public typesFacturation = [
        { value: OrderType.hourly, label: OrderType.hourly },
        { value: OrderType.outage, label: OrderType.outage },
        { value: OrderType.unit, label: OrderType.unit },
    ];

    @Input() public translatePrefix: string;

    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public update: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<EditCommandePosteTypeFormComponent>,
    ) {
        this.form = this.formBuilder.group(
            {
                order: [''],
                accountingEntry: [''],
                billingType: [''],
            },
            {
                validators: requireControlToBeFilledValidator()
            });
    }

    public onUpdate(): void {
        if (this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }

    public onCancel(): void {
        this.dialogRef.close();
    }
}
