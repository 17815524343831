import { Action } from '@ngrx/store';

import { AppNotification } from '../models/app-notification.model';

export enum AppNotificationActionTypes {
    ADD_ONE = '[App Notification] Show Notification'
}

export class AddOneAppNotification implements Action {
    readonly type = AppNotificationActionTypes.ADD_ONE;
    public constructor(public payload: AppNotification) { }
}
export type AppNotificationActions = AddOneAppNotification;
