import { EventEmitter } from '@angular/core';
import { AlternativePath } from '../../../../shared/models/atlernative-path.model';

export abstract class BasicPilotageComponent {
    protected abstract readonly moduleName: string;

    public abstract pathChoice: AlternativePath;
    public abstract reloadData: boolean;

    public abstract selectedModule: EventEmitter<string>;
    public abstract reloadedDone: EventEmitter<boolean>;
}
