import { ActivatedRoute } from '@angular/router';
import { /*AfterViewInit,*/ Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FilterService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import moment, { Moment } from 'moment';
import { Workbook, Worksheet } from 'exceljs';

import { AlternativePath } from '../../../../shared/models/atlernative-path.model';
import { AppConfiguration, getLayerValue } from '../../../../shared/config/app-config.model';
import { ExcelHelpers } from '../../../../shared/helpers/excel.helper';
import { getAllPrescriptions, getNacellesSelectOptions, getReseauxSelectOptions, getSpecialMeasuresLoading } from '../../../store/selectors';
import { LoadSpecialMeasures, UpdateOnePrescription } from '../../../store/actions';
import { Prescription, UpdatedPrescription } from '../../../../shared/models/database/special-measures.model';
import { SpecialMeasuresState } from '../../../store/reducers';
import { TableColumns } from '../../../../shared/models/table-columns.model';
import { trackByColumnField } from '../../../../shared/helpers/track-by.helper';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-special-measures-table',
    templateUrl: './special-measures-table.component.html',
    styleUrls: ['./special-measures-table.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'specialMeasuresTableComponent',
    }
})
export class SpecialMeasuresTableComponent implements OnInit, OnDestroy {
    @ViewChild('mpTable') private table: Table;

    private clonedPrescriptions: { [s: string]: Prescription; } = {};
    private pathChoice: AlternativePath;
    private subscriptions: Subscription[] = [];
    private currentPrescriptionID: number;
    private readonly excelWorksheetName = 'prescriptions';

    public readonly translatePrefix = 'specialMeasures';
    public mesPrescriptions: Prescription[] = [];

    // Is loading prescriptions, nacelles and type réseaux options
    public isSpecialMeasuresLoading$: Observable<boolean> = this.store.pipe(
        select(getSpecialMeasuresLoading),
    );
    public prescriptions$: Observable<Prescription[] | null> = this.store.pipe(
        select(getAllPrescriptions)
    );
    public nacellesOptions$: Observable<SelectItem<string>[]> = this.store.pipe(
        select(getNacellesSelectOptions)
    );
    public reseauxOptions$: Observable<SelectItem[]> = this.store.pipe(
        select(getReseauxSelectOptions)
    );

    public tableFiltersItems: any = {};
    public newItemForm: FormGroup;
    public showNacelleDialog: boolean;
    public showReseauDialog: boolean;
    public headerDialog: string;
    public placeholderDialog: string;
    public dateFilters: Moment[];
    public sortOrder = 1;
    public sortField = 'MesurePartLe';
    public isRowEditing = false;
    public prescriptionForm: FormGroup;
    public filtersApply = false;
    public currentFilters: string[] = [];
    public filtersEntries: any = {};
    public selectedPole: string[] = [];
    public selectedSite: string[] = [];

    // public frozenCols: TableColumns[] = [
    //     { field: 'actions', header: 'Actions', width: '120px', noFilter: true },
    //     { field: 'Numero', header: 'Numero', width: '150px' },
    // ];

    public scrollablePrescriptionCols: TableColumns[] = [
        { field: 'actions', header: 'Actions', width: '100px', noFilter: true, frozen: true },
        { field: 'Numero', header: 'Numero', width: '130px', frozen: true },
        { field: 'Statut', header: 'Statut', width: '250px', frozen: false },
        { field: 'Pole', header: 'Pole', width: '120px', filterType: 'multi-select', frozen: false },
        { field: 'Site', header: 'Site', width: '80px', filterType: 'multi-select', frozen: false },
        { field: 'Poste', header: 'Poste', width: '80px', frozen: false },
        { field: 'Ligne', header: 'Ligne', width: '80px', frozen: false },
        { field: 'Commande', header: 'Commande', width: '150px', frozen: false },
        { field: 'PosteCommande', header: 'POSTE_COMMANDE', width: '110px', frozen: false },
        { field: 'Adresse', header: 'Adresse', width: '550px', frozen: false },
        { field: 'NO_CIVIQUE', header: 'NO_CIVIQUE', width: '150px', frozen: false },
        { field: 'RUE', header: 'RUE', width: '200px', frozen: false },
        { field: 'MUNICIPALITE', header: 'MUNICIPALITE', width: '200px', frozen: false },
        { field: 'LCLCL', header: 'LCLCL', width: '100px', frozen: false },
        { field: 'Residu', header: 'Residu', width: '150px', frozen: false },
        { field: 'TYPE_RESEAU', header: 'TYPE_RESEAU', width: '250px', frozen: false },
        { field: 'PROVENANCE', header: 'PROVENANCE', width: '150px', frozen: false },
        { field: 'NumeroImputation', header: 'NumeroImputation', width: '120px', frozen: false },
        { field: 'MesurePartLe', header: 'MesurePartLe', format: 'yyyy-MM', width: '120px', frozen: false },
        { field: 'MesurePartPar', header: 'MesurePartPar', width: '350px', frozen: false },
        { field: 'TypeMesurePart', header: 'TypeMesurePart', width: '350px', frozen: false },
        { field: 'JustificationMesurePart', header: 'JustificationMesurePart', width: '220px', frozen: false },
        { field: 'CommentaireMesurePart', header: 'CommentaireMesurePart', width: '220px', frozen: false },
        { field: 'DESCR_NACELLE_CHENILLE', header: 'DESCR_NACELLE_CHENILLE', width: '250px', frozen: false },
        { field: 'QteCouvreConducteurs', header: 'QteCouvreConducteurs', width: '200px', frozen: false },
        { field: 'QteCCIsolateurFinCourse', header: 'QteCCIsolateurFinCourse', width: '220px', frozen: false },
        { field: 'QteCCIsolateurTete', header: 'QteCCIsolateurTete', width: '250px', frozen: false },
        { field: 'DureeMiseHorsTension', header: 'DureeMiseHorsTension', width: '200px', frozen: false },
        { field: 'Code', header: 'Code', width: '120px', frozen: false },
        { field: 'MesurePartApprouvParEnt', header: 'MesurePartApprouvParEnt', width: '350px', frozen: false },
        { field: 'MesurePartApprouvParHQ', header: 'MesurePartApprouvParHQ', width: '350px', frozen: false },
        { field: 'QTE_UNITE', header: 'QTE_UNITE', width: '100px', frozen: false },
        { field: 'GRUE', header: 'GRUE', width: '100px', frozen: false },
        { field: 'NBR_EQUIPE_ARBO', header: 'NBR_EQUIPE_ARBO', width: '170px', frozen: false },
        { field: 'VISITE_PAR', header: 'VISITE_PAR', width: '300px', frozen: false },
        { field: 'DATE_PRCH_VISITE', header: 'DATE_PRCH_VISITE', format: 'yyyy-MM-dd', width: '180px', frozen: false },
        { field: 'PRIORITE', header: 'PRIORITE', width: '100px', frozen: false },
        { field: 'DATE_REMISE', header: 'DATE_REMISE', format: 'yyyy-MM-dd', width: '220px', frozen: false },
        { field: 'STATUT_REALISATION', header: 'STATUT_REALISATION', width: '200px', frozen: false },
        { field: 'DATE_PLANIF', header: 'DATE_PLANIF', format: 'yyyy-MM-dd', width: '140px', frozen: false },
        { field: 'DATE_COMPLETE', header: 'DATE_COMPLETE', format: 'yyyy-MM-dd', width: '150px', frozen: false },
        { field: 'NoteInterne', header: 'NoteInterne', width: '300px', frozen: false },
        { field: 'Remarque', header: 'Remarque', width: '300px', frozen: false },
        { field: 'REMARQUES_AUTRES', header: 'REMARQUES_AUTRES', width: '300px', frozen: false },
        { field: 'TypeRemuneration', header: 'TypeRemuneration', width: '150px', frozen: false },
    ];

    public trackByColumnField: (_: number, item: TableColumns) => string = trackByColumnField;

    constructor(
        // private breakpointObserver: BreakpointObserver,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private store: Store<SpecialMeasuresState>,
        private translateService: TranslateService,
        private configService: AppConfiguration,
        private datePipe: DatePipe,
        private filterService: FilterService,
    ) {
        this.datePipe = new DatePipe('fr-CA');
        this.prescriptionForm = this.createPrescriptionForm();
        this.newItemForm = this.createNewItemForm();
    }

    private createPrescriptionForm(): FormGroup {
        return this.formBuilder.group({
            // Must add a fake control so we won't get an error (cannot read enabled of undefined) while looping in controls array
            prescriptionDetails: new FormArray([new FormGroup({})]),
        });
    }

    private createNewItemForm(): FormGroup {
        return this.formBuilder.group({
            name: new FormControl(),
        });
    }

    get prescriptionDetails(): FormArray {
        return this.prescriptionForm.get('prescriptionDetails') as FormArray;
    }

    public get newItemInDropDown(): string {
        return this.newItemForm.controls['name'].value;
    }

    ngOnInit(): void {
        this.pathChoice = this.route.snapshot.data['pathChoice'];
        this.loadSpecialMeasures();
        this.initDateFilters();

        this.subscriptions.push(
            this.updatePrescriptions(),
            this.updateNacelles(),
            this.updateReseaux()
        );
    }

    // public ngAfterViewInit(): void {
    //     // this.breakpointObserver
    //     //     .observe([Breakpoints.Small, Breakpoints.XSmall, Breakpoints.Medium])
    //     //     .subscribe((state: BreakpointState) => {
    //     //         if (this.table) {
    //     //             state.matches ? this.table.scrollHeight = '260px' : this.table.scrollHeight = '500px';
    //     //         }
    //     //     });
    // }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private loadSpecialMeasures(): void {
        this.loadTableFiltersFromStorage();
        this.store.dispatch(new LoadSpecialMeasures(this.pathChoice));
    }

    private loadTableFiltersFromStorage(): void {
        this.filtersEntries = JSON.parse(localStorage.getItem('MesureParticulieresTableFilters') || '{}');
        Object.keys(this.filtersEntries).forEach((key) => {
            if (this.filtersEntries[key] !== null && key.toLowerCase().includes('date')) {
                this.filtersEntries[key] = moment.utc(this.filtersEntries[key]).toDate();
            }
        });
    }

    // private partialData(data: Prescription[], quantity: number): Prescription[] {
    //     const myReturn: Prescription[] = [];

    //     if (quantity === 0) {
    //         return myReturn;
    //     } else {
    //         for (let i = 0; i < quantity; i++) {
    //             myReturn.push(data[i]);
    //         }

    //         return myReturn;
    //     }
    // }

    private updatePrescriptions(): Subscription {
        return this.prescriptions$.pipe(
            tap((prescriptions: Prescription[] | null) => {
                if (prescriptions && prescriptions.length > 0) {
                    this.mesPrescriptions = prescriptions.map((prescription: Prescription) => this.changeNullToEmptyString(cloneDeep(new Prescription(prescription))));

                    // this.mesPrescriptions = this.partialData(prescriptions, 100);
                    // this.mesPrescriptions = [...this.mesPrescriptionsToutes];

                    this.mesPrescriptions.forEach((prescription) => {
                        this.prescriptionDetails.controls[prescription.ID] = this.addPrescriptionControls(prescription);
                    });

                    this.createDynamicFilterList(this.mesPrescriptions);
                    this.applyFiltersFromStorage();
                }
            })
        ).subscribe();
    }

    public applyFiltersFromStorage() {
        if (this.table && Object.keys(this.filtersEntries).length > 0) {
            Object.keys(this.filtersEntries).forEach((key) => {
                if (this.filtersEntries[key] !== null && key.toLowerCase().includes('date')) {
                    this.filtersEntries[key] = moment.utc(this.filtersEntries[key]).toDate();
                } else if (this.filtersEntries[key] !== null && (key === 'Pole' || key === 'Site')) {
                    const values = this.filtersEntries[key].map((selectedValue: any) => selectedValue.code);
                    this.filterPrescriptionTable(values, key, 'in');
                } else if (this.filtersEntries[key] !== null) {
                    this.filterPrescriptionTable(this.filtersEntries[key], key);
                }
            });
        }
    }

    private createDynamicFilterList(prescriptions: Prescription[]) {
        prescriptions.forEach((prescription: Prescription) => {
            this.addFilterValueIfNotExist('Pole', prescription.Pole);
            this.addFilterValueIfNotExist('Site', prescription.Site);
        });
        this.sortDinamicFilter();
    }

    public sortDinamicFilter() {
        Object.keys(this.tableFiltersItems).forEach((key) => {
            const temp = this.tableFiltersItems[key].sort((a: { name: string, code: string }, b: { name: string, code: string }) => {
                return (a.name).localeCompare(b.name);
            });

            this.tableFiltersItems[key] = temp;
        });
    }

    private changeNullToEmptyString(prescription: Prescription): Prescription {
        if (prescription && prescription.Site === null) {
            prescription.Site = '';
        }

        if (prescription && prescription.Pole === null) {
            prescription.Pole = '';
        }

        return prescription;
    }

    private addFilterValueIfNotExist(filterName: string, value?: string | null): void {
        if (!this.tableFiltersItems[filterName]) {
            this.tableFiltersItems[filterName] = [];
        }

        const checkedValue = (value === undefined || value === null || value === '') ? '- Vide -' : value;
        const checkedCode = checkedValue === '- Vide -' ? '' : value;

        if (this.tableFiltersItems[filterName].findIndex((filter: any) =>
            filter.code === checkedCode) === -1) {

            this.tableFiltersItems[filterName].push({
                name: checkedValue,
                code: checkedCode,
            });
        }
    }

    public onFilterChange(value: any, fieldName: string): void {
        const field = this.scrollablePrescriptionCols.find((col: TableColumns) => col.field === fieldName);
        if (field) {
            switch (field.filterType) {
                case 'multi-select': {
                    const values = value.map((selectedValue: any) => selectedValue.code);
                    this.filterPrescriptionTable(values, fieldName, 'in');
                } break;
                default: {
                    this.filterPrescriptionTable(value, fieldName);
                }
            }
        }
    }

    private addPrescriptionControls(prescription: Prescription): FormGroup {
        return new FormGroup({
            NO_CIVIQUE: new FormControl(prescription.NO_CIVIQUE),
            RUE: new FormControl(prescription.RUE),
            MUNICIPALITE: new FormControl(prescription.MUNICIPALITE),
            TYPE_RESEAU: new FormControl(prescription.TYPE_RESEAU ? prescription.TYPE_RESEAU : null),
            DESCR_NACELLE_CHENILLE: new FormControl(prescription.DESCR_NACELLE_CHENILLE ? prescription.DESCR_NACELLE_CHENILLE : null),
            QTE_UNITE: new FormControl(prescription.QTE_UNITE),
            GRUE: new FormControl(prescription.GRUE),
            NBR_EQUIPE_ARBO: new FormControl(prescription.NBR_EQUIPE_ARBO),
            VISITE_PAR: new FormControl(prescription.VISITE_PAR),
            DATE_PRCH_VISITE: new FormControl(prescription.DATE_PRCH_VISITE ? moment(prescription.DATE_PRCH_VISITE).toDate() : null),
            PRIORITE: new FormControl(prescription.PRIORITE),
            DATE_REMISE: new FormControl(prescription.DATE_REMISE ? moment(prescription.DATE_REMISE).toDate() : null),
            STATUT_REALISATION: new FormControl(prescription.STATUT_REALISATION),
            DATE_PLANIF: new FormControl(prescription.DATE_PLANIF ? moment(prescription.DATE_PLANIF).toDate() : null),
            DATE_COMPLETE: new FormControl(prescription.DATE_COMPLETE ? moment(prescription.DATE_COMPLETE).toDate() : null),
            REMARQUES_AUTRES: new FormControl(prescription.REMARQUES_AUTRES),
        });
    }

    private initDateFilters(): void {
        this.filterService.register('dateFilter', (): boolean => {
            return true;
        });
        // // Remove for MB-ANGULAR-11
        // FilterUtils['dateFilter'] = (): boolean => {
        //     return true;
        // };
    }

    private updateNacelles(): Subscription {
        return this.nacellesOptions$.pipe(
            tap((nacelles: SelectItem[]) => {
                nacelles.push({
                    label: this.translateService.instant(this.translatePrefix + '.newNacelle'),
                    value: '00000000'
                } as SelectItem<string>);
            })
        ).subscribe();
    }

    private addNewNacelle(): Subscription {
        return this.nacellesOptions$.pipe(
            tap((nacelles: SelectItem[]) => {
                if (this.newItemInDropDown) {
                    nacelles.splice(nacelles.length - 1, 0, {
                        label: this.newItemInDropDown.trim(),
                        value: this.newItemInDropDown.trim()
                    } as SelectItem);
                }
            })
        ).subscribe();
    }

    private updateReseaux(): Subscription {
        return this.reseauxOptions$.pipe(
            tap((reseaux: SelectItem[]) => {
                reseaux.push({
                    label: this.translateService.instant(this.translatePrefix + '.newReseau'),
                    value: '00000000'
                } as SelectItem);
            })
        ).subscribe();
    }

    private addNewReseau(): Subscription {
        return this.reseauxOptions$.pipe(
            tap((reseaux: SelectItem[]) => {
                if (this.newItemInDropDown) {
                    reseaux.splice(reseaux.length - 1, 0, {
                        label: this.newItemInDropDown.trim(),
                        value: this.newItemInDropDown.trim()
                    } as SelectItem);
                }
            })
        ).subscribe();
    }

    public nacellesValueChanges(value: Readonly<string>, prescription: Prescription): void {
        if (value === '00000000') {
            this.currentPrescriptionID = prescription.ID;
            this.showNacelleDialog = true;
            this.headerDialog = this.translateService.instant(this.translatePrefix + '.dialog.titleNacelle');
            this.placeholderDialog = this.translateService.instant(this.translatePrefix + '.dialog.nacelle');
        }
    }

    public reseauxValueChanges(value: Readonly<string>, prescription: Prescription): void {
        if (value === '00000000') {
            this.currentPrescriptionID = prescription.ID;
            this.showReseauDialog = true;
            this.headerDialog = this.translateService.instant(this.translatePrefix + '.dialog.titleReseau');
            this.placeholderDialog = this.translateService.instant(this.translatePrefix + '.dialog.reseau');
        }
    }

    public onSubmit(): void {
        if (this.showNacelleDialog) {
            this.subscriptions.push(this.addNewNacelle());
            this.prescriptionDetails.controls[this.currentPrescriptionID].patchValue({ DESCR_NACELLE_CHENILLE: this.newItemInDropDown.trim() });
        } else if (this.showReseauDialog) {
            this.subscriptions.push(this.addNewReseau());
            this.prescriptionDetails.controls[this.currentPrescriptionID].patchValue({ TYPE_RESEAU: this.newItemInDropDown.trim() });
        }
        this.closeDialog();
    }

    public cancelDialog(): void {
        if (this.showNacelleDialog) {
            const currentPrescriptionControl = this.prescriptionDetails.controls[this.currentPrescriptionID];
            currentPrescriptionControl.patchValue({
                DESCR_NACELLE_CHENILLE: this.clonedPrescriptions[this.currentPrescriptionID].DESCR_NACELLE_CHENILLE ?
                    this.clonedPrescriptions[this.currentPrescriptionID].DESCR_NACELLE_CHENILLE : null
            });
        } else if (this.showReseauDialog) {
            this.prescriptionDetails.controls[this.currentPrescriptionID]
                .patchValue({
                    TYPE_RESEAU: this.clonedPrescriptions[this.currentPrescriptionID].TYPE_RESEAU ?
                        this.clonedPrescriptions[this.currentPrescriptionID].TYPE_RESEAU : null
                });
        }
        this.closeDialog();
    }

    private closeDialog(): void {
        this.newItemForm.controls['name'].reset();
        this.newItemForm.controls['name'].updateValueAndValidity();
        this.showNacelleDialog = false;
        this.showReseauDialog = false;
    }

    public onRowEditInit(prescription: Prescription): void {
        this.isRowEditing = true;
        this.table.editingRowKeys = { [prescription.ID]: true };
        this.clonedPrescriptions[prescription.ID] = { ...prescription };
    }

    public onRowEditSave(prescription: Prescription): void {
        delete this.clonedPrescriptions[prescription.ID];
        this.store.dispatch(new UpdateOnePrescription(this.mapEditedPrescription(prescription), this.pathChoice));
        this.isRowEditing = false;
    }

    private mapEditedPrescription(prescription: Prescription): UpdatedPrescription {
        const editedPrescription: UpdatedPrescription = Object.assign({}, this.prescriptionDetails.controls[prescription.ID].value);

        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        editedPrescription.NO_CIVIQUE = editedPrescription.NO_CIVIQUE ? editedPrescription.NO_CIVIQUE!.trim() : '';
        editedPrescription.RUE = editedPrescription.RUE ? editedPrescription.RUE!.trim() : '';
        editedPrescription.MUNICIPALITE = editedPrescription.MUNICIPALITE ? editedPrescription.MUNICIPALITE!.trim() : '';
        editedPrescription.QTE_UNITE = editedPrescription.QTE_UNITE ? editedPrescription.QTE_UNITE!.trim() : '';
        editedPrescription.GRUE = editedPrescription.GRUE ? editedPrescription.GRUE!.trim() : '';
        editedPrescription.NBR_EQUIPE_ARBO = editedPrescription.NBR_EQUIPE_ARBO ? editedPrescription.NBR_EQUIPE_ARBO!.trim() : '';
        editedPrescription.VISITE_PAR = editedPrescription.VISITE_PAR ? editedPrescription.VISITE_PAR!.trim() : '';
        editedPrescription.PRIORITE = editedPrescription.PRIORITE ? editedPrescription.PRIORITE!.trim() : '';
        editedPrescription.STATUT_REALISATION = editedPrescription.STATUT_REALISATION ? editedPrescription.STATUT_REALISATION!.trim() : '';
        editedPrescription.REMARQUES_AUTRES = editedPrescription.REMARQUES_AUTRES ? editedPrescription.REMARQUES_AUTRES!.trim() : '';
        editedPrescription.DESCR_NACELLE_CHENILLE = editedPrescription.DESCR_NACELLE_CHENILLE ? editedPrescription.DESCR_NACELLE_CHENILLE : '';
        editedPrescription.TYPE_RESEAU = editedPrescription.TYPE_RESEAU ? editedPrescription.TYPE_RESEAU : '';
        /* eslint-enable @typescript-eslint/no-non-null-assertion */

        editedPrescription.ID = prescription.ID;
        editedPrescription.MODIFIE_LE = prescription.MODIFIE_LE;

        return editedPrescription;
    }

    public onRowEditCancel(editedPrescription: Prescription): void {
        this.resetPrescription(this.clonedPrescriptions[editedPrescription.ID]);
        this.isRowEditing = false;
    }

    private resetPrescription(prescription: Prescription): void {
        this.prescriptionDetails.controls[prescription.ID].reset({
            NO_CIVIQUE: prescription.NO_CIVIQUE,
            RUE: prescription.RUE,
            MUNICIPALITE: prescription.MUNICIPALITE,
            TYPE_RESEAU: prescription.TYPE_RESEAU ? prescription.TYPE_RESEAU : null,
            DESCR_NACELLE_CHENILLE: prescription.DESCR_NACELLE_CHENILLE ? prescription.DESCR_NACELLE_CHENILLE : null,
            QTE_UNITE: prescription.QTE_UNITE,
            GRUE: prescription.GRUE,
            NBR_EQUIPE_ARBO: prescription.NBR_EQUIPE_ARBO,
            VISITE_PAR: prescription.VISITE_PAR,
            DATE_PRCH_VISITE: prescription.DATE_PRCH_VISITE ? moment(prescription.DATE_PRCH_VISITE).toDate() : null,
            PRIORITE: prescription.PRIORITE,
            DATE_REMISE: prescription.DATE_REMISE ? moment(prescription.DATE_REMISE).toDate() : null,
            STATUT_REALISATION: prescription.STATUT_REALISATION,
            DATE_PLANIF: prescription.DATE_PLANIF ? moment(prescription.DATE_PLANIF).toDate() : null,
            DATE_COMPLETE: prescription.DATE_COMPLETE ? moment(prescription.DATE_COMPLETE).toDate() : null,
            REMARQUES_AUTRES: prescription.REMARQUES_AUTRES,
        });
    }

    public reload(): void {
        this.loadSpecialMeasures();
        this.loadTableFiltersFromStorage();
    }

    public exportExcelFile(): void {
        if (this.table) {
            const columnsWidth = [
                25, 35, 15, 15, 15, 15, // A-F
                60, 20, 30, 30, 20, 30, // G-L
                30, 30, 30, 25, 50, 50, // M-R
                50, 50, 30, 30, 45, 45, // S-X
                30, 30, 50, 50, 20, 20, // Y-AD
                40, 50, 20, 20, 40, 30, // AE-AJ
                30, 35, 50, 50, 30 // AK-AO
            ];

            const workbook = new Workbook();
            const worksheet: Worksheet = ExcelHelpers.addNewWorksheet(workbook, this.excelWorksheetName, columnsWidth);

            this.addWorksheetHeaders(worksheet);
            this.addWorksheetData(worksheet);
            this.formatNumberCells(worksheet);

            ExcelHelpers.save(workbook, this.excelWorksheetName + '_' + new Date().getTime());
        }
    }

    private addWorksheetHeaders(worksheet: Worksheet): void {
        const headers: string[] = [];
        headers.push(this.translateService.instant(this.translatePrefix + '.headers.Numero'));
        this.scrollablePrescriptionCols.forEach((col) => headers.push(this.translateService.instant(this.translatePrefix + '.headers.' + col.header)));
        worksheet.addRow(headers);

        for (let colNumber = 1; colNumber < this.scrollablePrescriptionCols.length + 2; colNumber++) {
            worksheet.getCell(1, colNumber).font = { bold: true };
        }
    }

    private addWorksheetData(worksheet: Worksheet): void {
        this.table.value.forEach((data: Prescription) => {
            const values: any[] = [];
            values.push(data.numero);
            this.scrollablePrescriptionCols.forEach((col) => {
                values.push(col.format ? this.datePipe.transform(data[col.header], col.format) : data[col.header]);
            });
            worksheet.addRow(values);
        });
    }

    private formatNumberCells(worksheet: Worksheet): void {
        const LAST_ROW = this.table.value.length + 1;
        ExcelHelpers.setCellsNumberFormat(worksheet, 'V1:X' + LAST_ROW.toString(), '0');
    }

    public inputFilterPrescriptionTable(value: Event, field: string, filterMatchMode?: string): void {
        this.filterPrescriptionTable((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains');
    }

    public filterPrescriptionTable(value: any, field: Readonly<string>, matchMode?: string): void {
        this.table.filter(value, field, matchMode || 'contains');
        this.eraseFilterButtonStatus(value, field);
        localStorage.setItem('MesureParticulieresTableFilters', JSON.stringify(this.filtersEntries));
    }

    public clearDate(event: Event, field: Readonly<string>): void {
        this.table.filter(event, field, 'dateFilter');
    }

    public selectDate(event: Event, field: Readonly<string>): void {
        this.table.filter(event, field, 'dateFilter');
    }

    public openOGIVPrescription(id: Readonly<string>): void {
        const layerName = getLayerValue(this.configService.wabConfig, 'IOG_PRESCRIPTION');

        const url = `${this.configService.wabConfig.url}?query=${layerName},numero,${id}&level=18`;
        window.open(url, 'OgivWeb');
    }

    public getEmptyMessage(): string {
        return this.translateService.instant(this.translatePrefix + '.noData');
    }

    public eraseFilterButtonStatus(value: string, field: string): void {
        const index = this.currentFilters.findIndex((activeFilter: string) => activeFilter === field);
        if (index === -1 && (value !== '' || Array.isArray(value) && value.length > 0)) {
            this.currentFilters.push(field);
        } else if (index !== -1 && (value === '' || Array.isArray(value) && value.length === 0)) {
            this.currentFilters.splice(this.currentFilters.findIndex((activeFilter: string) => activeFilter === field), 1);
        }

        this.filtersApply = this.currentFilters.length > 0;
    }

    public clearAllFilters(): void {
        // this.frozenCols.forEach((col: TableColumns) => {
        //     if ((<HTMLInputElement>document.getElementById(`id-${col.field}`))) {
        //         (<HTMLInputElement>document.getElementById(`id-${col.field}`)).value = '';
        //     }
        //     this.filterPrescriptionTable('', col.field);
        // });

        Object.keys(this.filtersEntries).forEach((key) => {
            const field = this.scrollablePrescriptionCols.find((col: TableColumns) => col.field === key);
            if (field) {
                let value: any;
                switch (field.filterType) {
                    case 'multi-select': value = []; break;
                    default: value = ''; break;
                }
                this.filtersEntries[key] = value;
                this.filterPrescriptionTable(value, key);
            }
        });

        this.filtersEntries = {};
        localStorage.setItem('MesureParticulieresTableFilters', JSON.stringify(this.filtersEntries));
    }
}
