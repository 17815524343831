import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import localefrCa from '@angular/common/locales/fr-CA';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { CommonUiModule } from '@ngxhq/common-ui';
import { ThemeHqCommonUiSupportModule } from '@ngxhq/theme-ngxhq';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '../shared/shared.module';
import { SpecialMeasuresRoutingModule } from './special-measures-routing.module';
import { specialMeasuresVendorsModules } from './special-measures-vendors-modules';
import { SPECIAL_MEASURES_TOKEN, getSpecialMeasuresReducers } from './store';
import { specialMeasuresStoreEffects } from './store/effects';
import { specialMeasuresComponents } from '.';

registerLocaleData(localefrCa);

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CommonUiModule,
        SharedModule,
        ThemeHqCommonUiSupportModule,
        TranslateModule,
        ReactiveFormsModule,
        // Vendors
        ...specialMeasuresVendorsModules,
        // Routing
        SpecialMeasuresRoutingModule,
        // Store
        StoreModule.forFeature('specialMeasures', SPECIAL_MEASURES_TOKEN),
        EffectsModule.forFeature(specialMeasuresStoreEffects),
    ],
    declarations: [
        ...specialMeasuresComponents,
    ],
    providers: [
        {
            provide: SPECIAL_MEASURES_TOKEN,
            useFactory: getSpecialMeasuresReducers,
        },
        DatePipe
    ]
})
export class SpecialMeasuresModule { }
