<span class="close">
    <button mat-icon-button class="close__button" (click)="closeRemarque()">
        <i class="far fa-times-circle"></i>
    </button>
</span>
<div mat-dialog-title class="dialog__title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h2>{{ 'demandes-client.list.remarque-titre' | translate }} {{ currentDemande?.ordre?.numeroOrdre }}</h2>
</div>
<div mat-dialog-content class="content">
    <div #currentRemarquesDiv class="static">
        <span *ngIf="currentRemarque !== ''" [innerHTML]="currentRemarque" class="static__text"></span>
        <span *ngIf="currentRemarque === ''" > {{ 'demandes-client.list.empty-remarque' | translate }} </span>
    </div>

    <div class="remarque-add" *ngIf="isEditing">
        <h2>{{ 'demandes-client.form.addRemark' | translate }}</h2>
        <p *ngIf="headerNouvelleRemarque">{{ headerNouvelleRemarque }}</p>
        <textarea [(ngModel)]="texteNouvelleRemarque" [rows]="10" (ngModelChange)="onRemarqueChanged($event)"></textarea>
        <section class="actions">
            <div class="actions__spacer"></div>
            <button pButton icon="fa fa-plus" class="actions__button right" [label]="'common.add' | translate"
                [disabled]="!hasValue" (click)="addNouvelleRemarque()">
            </button>
            <button pButton icon="fa fa-trash" class="actions__button right" [label]="'common.delete' | translate"
                [disabled]="!hasValue" (click)="deleteNouvelleRemarque()">
            </button>
        </section>
    </div>
</div>
