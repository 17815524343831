import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Error } from '../../../../../shared/models/database/error.model';

@Component({
    selector: 'app-recursive-error',
    templateUrl: './recursive-error.component.html',
    styleUrls: ['./recursive-error.component.scss']
})
export class RecursiveErrorComponent implements OnChanges {
    public paddingLeft = 0;
    public typeErrorList: Error[] = [];
    public typeStringList: string[] = [];

    @Input() public errorList: Error[] = [];
    @Input() public indent = 1;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.errorList && changes.errorList.currentValue) {
            changes.errorList.currentValue.forEach((listItem: Error) => {
                if (listItem.list.length > 0) {
                    if (typeof listItem.list[0] === 'string') {
                        this.typeStringList = listItem.list as string[];
                    } else {
                        this.typeErrorList = listItem.list as Error[];
                    }
                }
            });
        }
    }
}
