import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import moment from 'moment';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ApiService } from '../../../ogiv-core/services';
import { DemandesClientUtils } from '../../../shared/models/demande-client-utils.model';
import { LoadHolidays, LoadHolidaysSuccess, LoadHolidaysFail, DemandesClientUtilsActionTypes } from '../actions/demandes-client-utils.actions';

@Injectable()
export class DemandesClientUtilsEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }
    public getJoursFeries$ = createEffect(() => this.actions$.pipe(
        ofType<LoadHolidays>(DemandesClientUtilsActionTypes.LOAD_HOLIDAYS),
        switchMap((action: LoadHolidays) => {
            const todayYYYYMMDD = moment().format('YYYYMMDD');
            return this.apiService.get<DemandesClientUtils>(
                `hq/demandes/client/joursferies?date_debut=${todayYYYYMMDD}&nb_jours_ouvrables=${action.nbJoursOuvrables}`
            ).pipe(
                map((demandeClientUtils: DemandesClientUtils) => {
                    return new LoadHolidaysSuccess(demandeClientUtils);
                }),
                catchError((error: any) => of(new LoadHolidaysFail(error))),
            );
        }),
    ));
}
