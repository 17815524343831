import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { TimesheetState, getTimesheetState } from '../reducers';
import { vehiclesAdapter, VehiclesState } from '../reducers/vehicles.reducer';
import { Vehicle, VehicleStatus } from '../../../shared/models/database/vehicle.model';

const {
    selectAll,
    selectEntities,
    selectIds,
} = vehiclesAdapter.getSelectors();

export const getVehiclesState = createSelector(
    getTimesheetState,
    (state: TimesheetState) => state.vehicles,
);

export const getVehiclesLoading = createSelector(
    getVehiclesState,
    (state: VehiclesState) => state.loading,
);

export const getVehiclesLoaded = createSelector(
    getVehiclesState,
    (state: VehiclesState) => state.loaded,
);

export const shouldLoadVehicles = createSelector(
    getVehiclesLoading,
    getVehiclesLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllVehicles = createSelector(
    getVehiclesState,
    selectAll
);

export const getVehiclesEntities = createSelector(
    getVehiclesState,
    selectEntities,
);

export const getVehiclesIds = createSelector(
    getVehiclesState,
    selectIds,
);

export const getVehicleById = (prop: any) => createSelector(
    getVehiclesEntities,
    (entities: Dictionary<Vehicle>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllVehiclesSorted = createSelector(
    getAllVehicles,
    (vehicles: Vehicle[]): Vehicle[] => {
        const temporaire = [...vehicles];
        temporaire.sort((a: Vehicle, b: Vehicle): number => {
            return (a.number.toLocaleLowerCase()).localeCompare(b.number.toLocaleLowerCase());
        });
        return temporaire;
    }
);

export const getActiveVehicle = createSelector(
    getAllVehiclesSorted,
    (vehicles: Vehicle[]) => vehicles.filter(vehicle => vehicle.status.toLocaleLowerCase() === VehicleStatus.active.toLocaleLowerCase()),
);

export const getActiveVehicleOptions = (prop: { addEmptyOption?: boolean, addCreateOption?: boolean } = {}) => createSelector(
    getAllVehiclesSorted,
    (vehicles: Vehicle[]): Vehicle[] => {
        const filteredVehicles = vehicles.filter(vehicle => (vehicle.status.toLocaleLowerCase() === VehicleStatus.active.toLocaleLowerCase()));

        const vehiclesOptions = filteredVehicles.map((vehicle: Vehicle) => {
            return {
                id: vehicle.id,
                number: vehicle.number,
                description: vehicle.description,
                companyId: vehicle.companyId,
                status: vehicle.status,
            } as Vehicle;
        });

        if (prop.addEmptyOption) {
            vehiclesOptions.unshift({
                description: 'Sélectionner un équipement',
                id: 0,
                number: '',
                companyId: 0,
                status: VehicleStatus.active
            } as Vehicle);
        }

        if (prop.addCreateOption) {
            vehiclesOptions.push({
                description: 'Nouvel équipement',
                id: null,
                number: 'createVehicle',
                companyId: 0,
                status: VehicleStatus.active,
            } as any);
        }

        return vehiclesOptions;
    }
);

export const getActiveVehicleByNumber = (props: { number: string }) => createSelector(
    getAllVehiclesSorted,
    (vehicles: Vehicle[]): (Vehicle | null) => {
        const found = vehicles.filter(vehicle => vehicle.status.toLocaleLowerCase() === VehicleStatus.active.toLocaleLowerCase() && vehicle.number === props.number);
        return found[0] || null;
    }
);

export const getActiveVehicleSearchOptions = (props: { addEmptyOption?: boolean, addCreateOption?: boolean, text: string } = { text: '' }) => createSelector(
    getAllVehiclesSorted,
    (vehicles: Vehicle[]): Vehicle[] => {
        const filteredVehicles = vehicles.filter(vehicle => {
            return vehicle.status.toLocaleLowerCase() === VehicleStatus.active.toLocaleLowerCase() && (
                vehicle.number.toUpperCase().includes(props.text.toUpperCase()) || vehicle.description.toUpperCase().includes(props.text.toUpperCase())
            );
        });

        const vehiclesOptions = filteredVehicles.map((vehicle: Vehicle) => {
            return {
                id: vehicle.id,
                number: vehicle.number,
                description: vehicle.description,
                companyId: vehicle.companyId,
                status: vehicle.status,
            } as Vehicle;
        });

        if (props.addEmptyOption) {
            vehiclesOptions.unshift({
                description: 'Sélectionner un équipement',
                id: 0,
                number: '',
                companyId: 0,
                status: VehicleStatus.active
            } as Vehicle);
        }

        if (props.addCreateOption) {
            vehiclesOptions.push({
                description: 'Nouvel équipement',
                id: null,
                number: 'createVehicle',
                companyId: 0,
                status: VehicleStatus.active,
            } as any);
        }

        return vehiclesOptions;
    }
);
