import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { TimesheetState, getTimesheetState } from '../reducers';
import { Unit } from '../../../shared/models/database/unit.model';
import { unitAdapter, UnitState } from '../reducers/units.reducer';

const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = unitAdapter.getSelectors();

export const getUnitsState = createSelector(
    getTimesheetState,
    (state: TimesheetState) => state.units,
);

export const getUnitsLoading = createSelector(
    getUnitsState,
    (state: UnitState) => state.loading,
);

export const getUnitsLoaded = createSelector(
    getUnitsState,
    (state: UnitState) => state.loaded,
);

export const shouldLoadUnits = createSelector(
    getUnitsLoaded,
    getUnitsLoading,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllUnits = createSelector(
    getUnitsState,
    selectAll,
);

export const getUnitsEntities = createSelector(
    getUnitsState,
    selectEntities,
);

export const getUnitsIds = createSelector(
    getUnitsState,
    selectIds,
);

export const getUnitById = (props: any) => createSelector(
    getUnitsEntities,
    (entities: Dictionary<Unit>) => {
        return entities && entities[props.entityId] || null;
    }
);

export const getUnitsCount = createSelector(
    getUnitsState,
    selectTotal
);
