import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { DemandesClientGlobalState, getDemandesClientGlobalState } from '../reducers';
import { DemandesClientState, demandesClientAdapter } from '../reducers/demandes-client.reducers';
import { DemandeClient } from '../../../shared/models/database/demande-client.model';

const {
    selectAll,
    selectEntities,
} = demandesClientAdapter.getSelectors();

/**
 * General State selector
 */

export const getDemandesClientState = createSelector(
    getDemandesClientGlobalState,
    (state: DemandesClientGlobalState) => state.demandesClient,
);

export const getDemandesClientLoading = createSelector(
    getDemandesClientState,
    (state: DemandesClientState) => state.loading,
);

export const getDemandesClientLoaded = createSelector(
    getDemandesClientState,
    (state: DemandesClientState) => state.loaded,
);

export const getDemandesClientLoadingOne = createSelector(
    getDemandesClientState,
    (state: DemandesClientState) => state.loadingOne,
);

export const getDemandesClientLoadedOne = createSelector(
    getDemandesClientState,
    (state: DemandesClientState) => state.loadedOne,
);

export const getDemandesClientBatchUpdatesProcessing = createSelector(
    getDemandesClientState,
    (state: DemandesClientState) => state.batchUpdateProcessing,
);

export const getDemandesClientBatchUpdatesResults = createSelector(
    getDemandesClientState,
    (state: DemandesClientState) => state.batchUpdateResult,
);

export const getDemandesClientUpdateResult = createSelector(
    getDemandesClientState,
    (state: DemandesClientState) => state.updateResult,
);

export const getAllDemandesClient = createSelector(
    getDemandesClientState,
    selectAll,
);

export const getDemandesClientEntities = createSelector(
    getDemandesClientState,
    selectEntities,
);

export const getDemandeClientById = (props: any) => createSelector(
    getDemandesClientEntities,
    (entities: Dictionary<DemandeClient>) => {
        return entities && entities[props.entityId] || null;
    }
);
