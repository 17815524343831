import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CommonUiModule } from '@ngxhq/common-ui';
import { ThemeHqCommonUiSupportModule } from '@ngxhq/theme-ngxhq';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { PilotagesModule } from '../pilotages/pilotages.module';
import { TimesheetsRoutingModule } from './timesheets-routing.module';
import { TimesheetsComponent } from './components/timesheets.component';
import { timesheetStoreEffects } from './store/effects';
import { getTimesheetReducers, TIMESHEET_REDUCER_TOKEN } from './store';

@NgModule({
    imports: [
        // CommonModule,
        CommonUiModule,
        RouterModule,
        SharedModule,

        ThemeHqCommonUiSupportModule,
        TranslateModule,
        PilotagesModule,

        // Router
        TimesheetsRoutingModule,

        // Store
        StoreModule.forFeature('timesheet', TIMESHEET_REDUCER_TOKEN),
        EffectsModule.forFeature(timesheetStoreEffects),
    ],
    declarations: [
        TimesheetsComponent,
    ],
    providers: [
        {
            provide: TIMESHEET_REDUCER_TOKEN,
            useFactory: getTimesheetReducers,
        },
    ],
})
export class TimesheetsModule {
}
