import { Component, Input } from '@angular/core';
import { UserRole } from '../../models/user-roles.model';

@Component({
    selector: 'app-role-based-content',
    templateUrl: './role-based-content.component.html',
})

export class RoleBasedContentComponent {
    @Input() public allowedRoles: UserRole[];

    public isRoleBased(): boolean {
        return !!this.allowedRoles && this.allowedRoles.length > 0;
    }
}
