import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../../ogiv-core/services';
import {
    LoadProgressReportsGlobal,
    LoadProgressReportsGlobalFail,
    LoadProgressReportsGlobalNoResult,
    LoadProgressReportsGlobalSuccess,
    ProgressReportsGlobalActionType
} from '../actions/progress-reports-global.actions';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification } from '../../../core/store/models/app-notification.model';
import { ProgressReportGlobalWrapper } from '../../../shared/models/database/progress-report-global-wrapper.model';
@Injectable()
export class ProgressReportGlobalEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }


    public loadProgressReportsGlobal$ = createEffect(() => this.actions$.pipe(
        ofType<LoadProgressReportsGlobal>(ProgressReportsGlobalActionType.LOAD_PROGRESS_REPORTS_GLOBAL),
        switchMap((action: LoadProgressReportsGlobal) => {
            const queryString = (action.queryString !== undefined && action.queryString !== null && action.queryString !== '') ? `${action.queryString}` : '';
            return this.apiService.get<ProgressReportGlobalWrapper>(`${action.path}/rapports/avancement/global${queryString}`).pipe(
                map((globalReportWrapper: ProgressReportGlobalWrapper) =>
                    (globalReportWrapper && globalReportWrapper.report_data && globalReportWrapper.report_data.length > 0)
                        ? new LoadProgressReportsGlobalSuccess(globalReportWrapper)
                        : new LoadProgressReportsGlobalNoResult()
                ),
                catchError((error: any) => of(new LoadProgressReportsGlobalFail(error))),
            );
        }),
    ));


    public loadProgressReportsGlobalFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadProgressReportsGlobalFail>(ProgressReportsGlobalActionType.LOAD_PROGRESS_REPORTS_GLOBAL_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'progressReport.globalReport.load.error',
            }))
        ]),
    ));
}
