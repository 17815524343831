import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from 'primeng/api';
import { Table } from 'primeng/table';
import moment from 'moment';

import { PilotageFormModalComponent } from '../../../modals/pilotage-form-modal/pilotage-form-modal.component';
import { BasicPilotageComponent } from '../../basic-pilotage-component';
import { TableColumns } from '../../../../../../shared/models/table-columns.model';
import { AlternativePath } from '../../../../../../shared/models/atlernative-path.model';
import { TimesheetBasicSaving } from '../../../../../models/timesheet-basic-saving.model';
import { editTimesheetChargeNumberColumns } from './edit-timesheet-charge-number.column';
import { getAllTimesheetChargeNumber, getTimesheetChargeNumberLoading } from '../../../../../store/selectors';
import { PilotageFormModalData, PilotageFormModalDataFormName } from '../../../modals/pilotage-form-modal/pilotage-form-modal-data.model';
import { LoadTimesheetsChargeNumber, UpdateOneTimesheetChargeNumber } from '../../../../../store/actions/timesheet-charge-number.action';
import { TimesheetChargeNumber, TimesheetChargeNumberSavingValues, TimesheetChargeNumberSavingFilters } from '../../../../../models/timesheet-charge-number.model';

@Component({
    selector: 'app-edit-timesheet-charge-number',
    templateUrl: './edit-timesheet-charge-number.component.html',
    styleUrls: ['./edit-timesheet-charge-number.component.scss']
})
export class EditTimesheetChargeNumberComponent extends BasicPilotageComponent implements OnDestroy, OnChanges, OnInit {
    protected moduleName = 'editTimesheetChargeNumber';
    public loaderLogoSize = 75;
    public columns: TableColumns[] = editTimesheetChargeNumberColumns;

    public form: FormGroup;
    private subscriptions: Subscription[] = [];
    public filteredOptions: { [name: string]: string[] }[] = [];
    public disabledDateMax = true;
    public isGeneralFormInvalid = true;

    public translatePrefix = 'pilotages.editTimesheet.chargeNumber';
    public translatePrefixFiltre = this.translatePrefix + '.fieldset.filtres';
    public translatePrefixTable = this.translatePrefix + '.table';

    public timesheetsChargeNumber$: Observable<TimesheetChargeNumber[] | null> = this.store.pipe(
        select(getAllTimesheetChargeNumber),
        distinctUntilChanged(),
        tap((timesheetChargeNumbers: TimesheetChargeNumber[]) => {
            this.reloadedDone.emit(true);
            this.filtersDropdown(timesheetChargeNumbers);
        }),
    );

    public isTimesheetChargeNumberLoading$: Observable<boolean> = this.store.pipe(
        select(getTimesheetChargeNumberLoading),
    );

    protected filtersNames: string[] = ['teamLeader', 'order', 'accountingEntry', 'billingType', 'chargeNumber', 'outageNumber', 'postElect', 'line', 'code'];
    protected changeValues: { searchControl: string, searchValue: string }[] = [
        { searchControl: '$teamLeaderSearch', searchValue: 'teamLeader' },
        { searchControl: '$orderSearch', searchValue: 'order' },
        { searchControl: '$accountingEntrySearch', searchValue: 'accountingEntry' },
        { searchControl: '$billingTypeSearch', searchValue: 'billingType' },
        { searchControl: '$chargeNumberSearch', searchValue: 'chargeNumber' },
        { searchControl: '$outageNumberSearch', searchValue: 'outageNumber' },
        { searchControl: '$postElectSearch', searchValue: 'postElect' },
        { searchControl: '$lineSearch', searchValue: 'line' },
        { searchControl: '$codeSearch', searchValue: 'code' }
    ];

    protected basicNewValue: TimesheetChargeNumberSavingValues = {
        chargeNumber: null,
    };

    protected filtersBasicValues: TimesheetChargeNumberSavingFilters = {
        teamLeader: '',
        order: '',
        accountingEntry: '',
        billingType: '',
        chargeNumber: '',
        outageNumber: '',
        postElect: '',
        line: '',
        code: '',
        dateMin: '',
        dateMax: '',
    };

    private searchBasicValues = {
        $teamLeaderSearch: '',
        $orderSearch: '',
        $accountingEntrySearch: '',
        $billingTypeSearch: '',
        $chargeNumberSearch: '',
        $outageNumberSearch: '',
        $postElectSearch: '',
        $lineSearch: '',
        $codeSearch: '',
        $timesheetDateFromSearch: '',
        $timesheetDateToSearch: '',
    };

    protected basicValues = {
        ...this.searchBasicValues,
        ...this.filtersBasicValues
    };

    protected saveValue: TimesheetBasicSaving<TimesheetChargeNumberSavingValues, TimesheetChargeNumberSavingFilters> = {
        updateValues: { ...this.basicNewValue },
        filter: { ...this.filtersBasicValues },
    };

    @Input() public pathChoice: AlternativePath;
    @Input() public reloadData: boolean;

    @Output() public selectedModule: EventEmitter<string> = new EventEmitter<string>();
    @Output() public reloadedDone: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('table') table: Table;

    public constructor(
        private store: Store,
        private formBuilder: FormBuilder,
        protected translateService: TranslateService,
        public dialog: MatDialog,
        private filterService: FilterService,
    ) {
        super();

        // Added for MB-ANGULAR-11
        this.filterService.register('searchDate', (value: any): boolean => {
            return this.customFilterDates(value);
        });

        // Remove for MB-ANGULAR-11
        // FilterUtils['searchDate'] = (value: string): boolean => {
        //     return this.customFilterDates(value);
        // };

        this.form = this.createTimesheetChargeNumberForm();
    }

    /************************/
    /**  General Funtions  **/
    /************************/

    protected loadAllValues(): void {
        this.store.dispatch(new LoadTimesheetsChargeNumber());
    }

    protected initValues(): void {
        this.initSubscription();
    }

    public isResetFilterDisable(): boolean {
        if (this.table) {
            return Object.keys(this.table.filters).length === 0;
        }

        return true;
    }

    public isFormInvalid(): boolean {
        if (this.table) {
            if (Object.keys(this.table.filters).length > 0 && this.table.filteredValue) {
                let isInvalid = false;
                for (const value of this.table.filteredValue) {
                    if (value.billingReportId !== null && value.billingReportId !== '') {
                        isInvalid = true;
                        break;
                    }
                }

                return isInvalid || this.form.invalid || this.table.filteredValue.length === 0;
            }
        }

        return true;
    }

    private createTimesheetChargeNumberForm(): FormGroup {
        return this.formBuilder.group({
            $teamLeaderSearch: ['', [Validators.required]],
            $orderSearch: ['', [Validators.required]],
            $accountingEntrySearch: ['', [Validators.required]],
            $billingTypeSearch: ['', [Validators.required]],
            $chargeNumberSearch: ['', [Validators.required]],
            $outageNumberSearch: [''],
            $postElectSearch: [''],
            $lineSearch: [''],
            $codeSearch: [''],
            $timesheetDateFromSearch: [''],
            $timesheetDateToSearch: [{ value: '', disabled: true }],
            teamLeader: ['', [Validators.required]],
            order: ['', [Validators.required]],
            accountingEntry: ['', [Validators.required]],
            billingType: ['', [Validators.required]],
            chargeNumber: ['', [Validators.required]],
            outageNumber: '',
            postElect: [''],
            line: [''],
            code: [''],
            dateMin: [''],
            dateMax: [''],
        });
    }

    /************************/
    /**  Other Funtions  **/
    /************************/

    public openChangenumber(): void {
        this.openForm(PilotageFormModalDataFormName.editTimesheetChargeNumber);
    }

    protected formateNewValues(value: string): TimesheetChargeNumberSavingValues {
        const newValue: TimesheetChargeNumberSavingValues = this.basicNewValue;
        newValue.chargeNumber = value;
        return newValue;
    }

    protected sendUpdate(): void {
        this.store.dispatch(new UpdateOneTimesheetChargeNumber(this.saveValue));
    }

    protected resetInitialValues(): void {
        this.basicNewValue = {
            chargeNumber: null,
        };

        this.filtersBasicValues = {
            teamLeader: null,
            order: null,
            accountingEntry: null,
            billingType: null,
            chargeNumber: null,
            outageNumber: null,
            postElect: null,
            line: null,
            code: null,
            dateMin: null,
            dateMax: null,
        };

        this.saveValue = {
            updateValues: { ...this.basicNewValue },
            filter: { ...this.filtersBasicValues },
        };
    }

    /************************/
    /** Angular life cycle **/
    /************************/

    public ngOnInit(): void {
        this.selectedModule.emit(this.moduleName);
        this.loadAllValues();
        this.initValues();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.reloadData && changes.reloadData.currentValue) {
            this.loadAllValues();
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    /*************************/
    /**  Dropdown Funtions  **/
    /*************************/

    private fillSimpleDropdown(filteredValues: TimesheetChargeNumber[], field: string): string[] {
        const filteredOptions: string[] = [];
        filteredValues.forEach((filteredValue: TimesheetChargeNumber) => {
            const tempValues = filteredValue[field] === null ? '' : filteredValue[field];
            if (!filteredOptions.includes(tempValues)) {
                filteredOptions.push(tempValues);
            }
        });

        return this.sortDropdown(filteredOptions);
    }

    public filtersDropdown(filteredValue: TimesheetChargeNumber[]): void {
        this.filtersNames.forEach((field: string) => {
            if (filteredValue.length > 0) {
                this.filteredOptions[field] = this.fillSimpleDropdown(filteredValue, field);
            } else {
                this.filteredOptions[field] = [];
            }
        });

        this.isGeneralFormInvalid = this.isFormInvalid();
    }

    /************************/
    /**  Filters Funtions  **/
    /************************/

    /** Calendar functions **/

    public clearfilterDate(fieldName: string, searchFieldName: string): void {
        this.form.controls[searchFieldName].setValue(null);
        this.form.controls[fieldName].setValue(null);

        if (fieldName === 'dateMin') {
            this.evaluateDateMax();
        }

        this.table.filter('', 'date', 'contains');
    }

    protected evaluateDateMax(): void {
        if (this.form.controls.dateMin.value !== '' && this.form.controls.dateMin.value !== null) {
            this.disabledDateMax = false;
        } else {
            this.disabledDateMax = true;
        }
    }

    public filterDate(event: any, fieldName: string, searchFieldName: string): void {
        this.form.controls[searchFieldName].setValue(moment(event, moment.ISO_8601).format('yyyy-MM-DD'));
        this.form.controls[fieldName].setValue(moment(event, moment.ISO_8601).format('yyyy-MM-DDT00:00:00'));

        if (fieldName === 'dateMin') {
            this.evaluateDateMax();
        }

        this.table.filter(event, 'date', 'searchDate');
    }

    public customFilterDates(value: any): boolean {
        if (value) {
            const tableValue = moment(value, moment.ISO_8601, true);
            const firstFilterDate = moment(this.form.controls['dateMin'].value, moment.ISO_8601, true);
            const secondFilterDate = moment(this.form.controls['dateMax'].value, moment.ISO_8601, true);

            if (tableValue.isValid() && firstFilterDate.isValid()) {
                if (secondFilterDate.isValid()) {
                    return tableValue.isSameOrAfter(firstFilterDate, 'day') && tableValue.isSameOrBefore(secondFilterDate, 'day');
                } else {
                    return tableValue.isSame(firstFilterDate, 'day');
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    /** Others filters functions **/
    private sortDropdown(values: string[]): string[] {
        const temporaire = [...values];
        temporaire.sort((a: string, b: string): number => {
            return (a.toLocaleLowerCase()).localeCompare(b.toLocaleLowerCase());
        });
        return temporaire;
    }

    protected searchInTable(searchValue: string, fieldToSearchIn: string, reset: boolean = false): void {
        if (this.table) {
            if (this.table.filters[fieldToSearchIn]) {
                delete this.table.filters[fieldToSearchIn];
            }
            const list = Object.keys(this.table.filters).length === 0 || reset ?
                this.table.value : this.table.filteredValue;

            const valuesFound: string[] = [];

            list.forEach((timesheetCommandePosteType: TimesheetChargeNumber) => {
                if (timesheetCommandePosteType[fieldToSearchIn] !== null) {
                    if (timesheetCommandePosteType[fieldToSearchIn].toLowerCase() === (searchValue === null ? '' : searchValue.toLowerCase())) {
                        if (!valuesFound.includes(timesheetCommandePosteType[fieldToSearchIn])) {
                            valuesFound.push(timesheetCommandePosteType[fieldToSearchIn]);
                        }
                    }
                }
            });

            this.filteredOptions[fieldToSearchIn] = this.sortDropdown(valuesFound);
        }
    }

    public resetFitlers(): void {
        this.table.reset();
        this.form.reset(this.basicValues);
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.evaluateDateMax();
    }

    public filters(value: string | null, field: string, match: string = 'equals'): void {
        if (this.table) {
            const formatValue = value === null ? '' : value;

            this.form.controls[field].setValue(formatValue);
            this.form.updateValueAndValidity();
            this.table.filter(formatValue, field, match);
        }
    }

    /************************/
    /**  General Funtions  **/
    /************************/

    protected initSubscription(): void {
        this.changeValues.forEach((search: { searchControl: string, searchValue: string }) => {
            this.subscriptions.push(this.addSubscriptions(search.searchControl, search.searchValue));
        });
    }

    public addSubscriptions(searchControl: string, valueControl: string): Subscription {
        return this.form.controls[searchControl].valueChanges.pipe(
            tap((searchValue: string) => {
                const reset = (searchValue === null ? 0 : searchValue.length) < (this.form.controls[valueControl].value).length;
                this.searchInTable(searchValue, valueControl, reset);
                this.filters(searchValue, valueControl);
                this.form.updateValueAndValidity();
            })
        ).subscribe();
    }

    /************************/
    /**  Other Funtions  **/
    /************************/
    protected openForm(formName: string): void {
        const dialogRef = this.dialog.open(PilotageFormModalComponent, {
            width: '560px',
            data: {
                title: this.translateService.instant(this.translatePrefix + '.form.title'),
                translatePrefix: this.translatePrefix + '.form',
                logoString: 'far fa-calendar-alt',
                formName: formName
            } as PilotageFormModalData
        });

        dialogRef.afterClosed().subscribe((result: string) => {
            if (result) {
                this.update(result);
            }
        });
    }

    protected formateFilterValues(): TimesheetChargeNumberSavingFilters {
        const filterValues: TimesheetChargeNumberSavingFilters = { ...this.filtersBasicValues };
        const values = this.form.value;

        (Object.keys(values)).forEach((key: string) => {
            if (!key.includes('$')) {
                filterValues[key] = values[key] === '' ? null : values[key];
            }
        });

        return filterValues;
    }

    protected update(newValues: string): void {
        if (this.form.valid) {
            this.saveValue.updateValues = this.formateNewValues(newValues);
            this.saveValue.filter = this.formateFilterValues();
            this.sendUpdate();
            this.resetInitialValues();
        }
    }
}
