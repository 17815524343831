<app-generic-accordion [title]="'timesheet.order.tab.accordionTitle.team.title' | translate"
    [totalQuantity]="totalTeamMate" [icon]="'fas fa-users'" [bubbleIcon]="'fas fa-user'"
    [totalTime]="totalTeamMateHours" [canAddEditTime]="canAddEditTime" [isLock]="isLock">
    <app-generic-other-menu actions *hasRole="editAllowedRoles" [addOnly]="true" [isLock]="isLock" [addborder]="true" (add)="addTeam($event)"></app-generic-other-menu>
    <app-team-mate-list itemsList [pathChoice]="pathChoice" [editAllowedRoles]="editAllowedRoles" [canAddEditTime]="canAddEditTime" [isLock]="isLock" [workerList]="workerList" [orderId]="orderId"
        [timesheetDateId]="timesheetDateId">
    </app-team-mate-list>
</app-generic-accordion>

<app-generic-accordion [title]="'timesheet.order.tab.accordionTitle.equipment.title' | translate"
    [totalQuantity]="totalEquipment" [icon]="'fas fa-toolbox'" [bubbleIcon]="'fas fa-wrench'"
    [totalTime]="totalEquipmentHours" [canAddEditTime]="canAddEditTime" [isLock]="isLock">
    <app-generic-other-menu actions *hasRole="editAllowedRoles" [addOnly]="true" [isLock]="isLock" [addborder]="true" (add)="addEquipment($event)"></app-generic-other-menu>
    <app-list-equipment itemsList [pathChoice]="pathChoice" [editAllowedRoles]="editAllowedRoles" [canAddEditTime]="canAddEditTime" [isLock]="isLock" [equipmentList]="equipmentList" [orderId]="orderId"
        [timesheetDateId]="timesheetDateId">
    </app-list-equipment>
</app-generic-accordion>

<app-generic-accordion [title]="'timesheet.order.tab.accordionTitle.unitOfWorkDone.title' | translate"
    [totalTime]="totalUnitOfWorkHours" [totalQuantity]="totalUnitOfWork" [icon]="'fas fa-clipboard-check'"
    [canAddEditTime]="canAddEditTime" [bubbleIcon]="'fas fa-clipboard-list'" [isLock]="isLock">
    <app-generic-other-menu actions *hasRole="editAllowedRoles" [addOnly]="true" [isLock]="isLock" [addborder]="true" (add)="addPostLine($event)"></app-generic-other-menu>
    <ng-container itemsList *ngIf="postLineGroupsList.length > 0">
        <app-element-loader *ngIf="postLinesLoading$ | async" [size]="50" ></app-element-loader>
        <ng-container *ngFor="let postLineGroup of postLineGroupsList">
            <app-work-done-charge-number-accordion [pathChoice]="pathChoice" [editAllowedRoles]="editAllowedRoles" [postLineGroup]="postLineGroup" [requiredSubtitle]="requiredSubtitle"
                [canAddEditTime]="canAddEditTime" [isLock]="isLock" [orderId]="orderId" [timesheetDateId]="timesheetDateId"
                [orderType]="orderType">
            </app-work-done-charge-number-accordion>
        </ng-container>
    </ng-container>
</app-generic-accordion>

<app-note [remark]="remark" [isLock]="isLock" (update)="updateRemark($event)" [maxCharactersLength]="1000"></app-note>
