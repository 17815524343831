export class UtilisateurInterne {
    id: number;
    nom: string;
    prenom: string;
    cip: string;
    upn: string;
    id_agol: string;
    pole: string;
    site: string;
    numero_vehicule: string;
    numero_tablette: string;
    numero_cellulaire: string;
    numero_ordinateur: string;
    numero_ecran: string;
    horaire: Horaire;
    statut: Statut;
    cree_le: string;
    modifie_par: string;
    modifie_le: string;
    carte_essence: string;
    telephone_residence: string;
    cadena: string;
    carte_acces: string;
    carte_sim_tablette: string;
    carte_sim_cellulaire: string;
    bureau: string;
    adresse_bureau: string;
    courriel: string;
    telephone_externe: string;
    telephone_interne: string;
    fax: string;
    groupe: string;
    matricule: string;
    roles: string;
    titre_emploi: string;
}

export enum Horaire {
    aucun = 'Aucun',
    lundi = 'Lundi',
    mardi = 'Mardi',
    mercredi = 'Mercredi',
    jeudi = 'Jeudi',
    vendredi = 'Vendredi',
}

export enum Statut {
    active = 'Actif',
    inactive = 'Inactif',
    error = 'En erreur',
    incomplet = 'Incomplet',
    redo = 'En reprise',
}
