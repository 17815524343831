import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import moment from 'moment';

import { Timesheet } from '../../../../../shared/models/database/timesheet.model';
import { TimesheetState } from '../../../../store/reducers';
import { LoadTimesheetById, LoadTimesheetByIdAndImpersonnification, SubmitTimesheet } from '../../../../store/actions/timesheets.action';
import { getCurrentTimesheet } from '../../../../store/selectors';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import { ModalData, ModalEntityType, ModalType } from '../generic-modal/generic-modal-data.model';
import { UserRole } from '../../../../../shared/models/user-roles.model';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { OrderGroup } from '../../../../../shared/models/database/order-group.model';
import { CurrentUser, UserService } from '../../../../../core/services/user.service';
import { UIService } from '../../../../../ogiv-core/services';

@Component({
    selector: 'app-timesheet-status-bar',
    templateUrl: './timesheet-status-bar.component.html',
    styleUrls: ['./timesheet-status-bar.component.scss']
})
export class TimesheeStatusBarComponent implements OnChanges {
    public currentTimesheet: Timesheet;
    public currentStatus: string;
    public currentTimesheetDateId?: string;
    public currentTimesheetStatus = '';
    public translatePrefix = 'timesheet.general.status';
    public isDisplayingLegend = false;
    public isDisplayingSubmit = false;
    public displayButtonText = true;
    public hideButtonTextValue = 855;
    public sideBarExtended = true;
    public documentWidth = document.documentElement.clientWidth;

    @Input() public numberOfGroup = 0;
    @Input() public orderGroups: OrderGroup[] | null;
    @Input() public pathChoice: AlternativePath;
    @Input() public editAllowedRoles: UserRole[] = [];

    public timesheet$: Observable<Timesheet | null> = this.store.pipe(
        select(getCurrentTimesheet),
        tap((timesheet: Timesheet) => {
            if (timesheet) {
                this.currentTimesheet = timesheet;
                this.currentTimesheetDateId = moment(timesheet.date).format('YYYYMMDD');
                this.currentTimesheetStatus = this.nonEmptyTimesheeStatus(timesheet.isSubmit);
                this.currentStatus = this.assignTimesheetStatus(timesheet.isSubmit, timesheet.orderGroups);
                this.isDisplayingSubmit = this.shouldDisplaySubmit(timesheet.orderGroups);
            }
        }),
    );

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<TimesheetState>,
        private userService: UserService,
        private uiService: UIService,
    ) {
        if (document.documentElement.clientWidth <= 510) {
            this.sideBarExtended = false;
            this.documentWidth = document.documentElement.clientWidth;
        } else {
            this.sideBarExtended = true;
            this.documentWidth = document.documentElement.clientWidth;
        }

        this.uiService.menuIsExpended$.pipe(
            tap((isExpended: any) => {
                this.setUpSidePanel(isExpended);
            })
        ).subscribe();

        this.uiService.documentWidth$.pipe(
            tap((width: number) => {
                this.setUpWitdh(width);
            })
        ).subscribe();

        this.finalSetup();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.orderGroups && changes.orderGroups.currentValue) {
            this.isDisplayingLegend = this.shouldDisplayLegend(changes.orderGroups.currentValue);
        }
    }

    public setUpSidePanel(isExpended: boolean): void {
        this.sideBarExtended = isExpended;
        this.finalSetup();
    }

    public setUpWitdh(width: number): void {
        this.documentWidth = width;
        this.finalSetup();
    }

    public finalSetup(): void {
        if (this.sideBarExtended) {
            if (this.documentWidth > 855) {
                this.displayButtonText = true;
            } else if (this.documentWidth <= 855) {
                this.displayButtonText = false;
            }
        } else {
            if (this.documentWidth <= 660) {
                this.displayButtonText = false;
            } else if (this.documentWidth > 660) {
                this.displayButtonText = true;
            }
        }
    }

    public nonEmptyTimesheeStatus(isSubmit: boolean): string {
        return isSubmit ? 'Soumise' : 'A_soumettre';
    }

    public assignTimesheetStatus(isSubmit: boolean, orderGroups: OrderGroup[] | undefined): string {
        return (orderGroups === undefined || orderGroups.length === 0) ? 'Empty' : this.nonEmptyTimesheeStatus(isSubmit);
    }

    public shouldDisplaySubmit(orderGroups: OrderGroup[] | undefined): boolean {
        if ((orderGroups !== undefined && orderGroups.length > 0) && !this.currentTimesheet.isSubmit) {
            return true;
        }

        return false;
    }

    public shouldDisplayLegend(orderGroups: OrderGroup[]): boolean {
        let display = false;
        if (orderGroups && orderGroups.length > 0) {
            orderGroups.forEach((orderGroup: OrderGroup) => {
                if ((orderGroup.unitly !== null && orderGroup.unitly.timesheetReportId !== null) ||
                    (orderGroup.hourly !== null && orderGroup.hourly.timesheetReportId !== null) ||
                    (orderGroup.outage !== null && orderGroup.outage.timesheetReportId !== null)) {
                    display = true;
                }
            });
        }

        return display;
    }

    public refresh(): void {
        if (this.currentTimesheetDateId) {
            if (this.userService.currentUser.isImpersonnified && this.userService.currentUser.idAgol) {
                this.store.dispatch(new LoadTimesheetByIdAndImpersonnification(this.currentTimesheetDateId, this.pathChoice, this.userService.currentUser.idAgol));
            } else {
                this.store.dispatch(new LoadTimesheetById(this.currentTimesheetDateId, this.pathChoice));
            }
        }
    }

    public submitRecord(): void {
        const timesheetDialog = this.dialog.open(GenericModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                formType: ModalType.submit,
                title: 'timesheet.general.status.submitForm.title',
                message: 'timesheet.general.status.submitForm.message',
                entityType: ModalEntityType.timesheet,
                entity: this.currentTimesheet,
            } as ModalData
        });

        timesheetDialog.afterClosed().subscribe((result: boolean) => {
            if (this.currentTimesheetDateId && result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }
                this.store.dispatch(new SubmitTimesheet(this.currentTimesheetDateId, this.pathChoice, imperson));
            }
        });
    }

    public displayLegend(): void {
        this.dialog.open(GenericModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                formType: ModalType.info,
                title: 'timesheet.general.status.legendForm.title',
                message: 'timesheet.general.status.legendForm.message',
                entityType: ModalEntityType.infoOrderStatus,
            } as ModalData
        });
    }
}
