import { EntrepreneursEffects } from './entrepreneurs.effect';
import { UtilisateursInternesEffects } from './utilisateurs-internes.effect';
import { UtilisateursExternesEffects } from './utilisateurs-externes.effect';
import { ProfilFormationUtilisateurEffects } from './profil-formation-utilisateur.effect';
import { EtatSynchronisationsEffects } from './etat-synchronisations.effect';
import { GestionCodesSAPEffects } from './gestion-codes-sap.effect';
import { TableCodesUniteEffects } from './table-code-unite.effect';
import { UtilisateursExternesTeamLeasersEffects } from './utilisateurs-externes-team-leaders.effect';
import { TimesheetChargeNumberEffects } from './timesheet-charge-number.effect';
import { TimesheetCommandePosteTypeEffects } from './timesheet-commande-poste-type.effect';

export const pilotagesStoreEffects = [
    EntrepreneursEffects,
    UtilisateursInternesEffects,
    UtilisateursExternesEffects,
    ProfilFormationUtilisateurEffects,
    EtatSynchronisationsEffects,
    GestionCodesSAPEffects,
    TableCodesUniteEffects,
    UtilisateursExternesTeamLeasersEffects,
    TimesheetChargeNumberEffects,
    TimesheetCommandePosteTypeEffects,
];
