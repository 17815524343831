import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { BillingReport } from '../../../shared/models/database/billing-report.model';
import { BillingReportAction, BillingReportActionType } from '../actions/billing-report.action';

export interface BillingReportsState extends EntityState<BillingReport> {
    loading: boolean;
    loaded: boolean;
    saving: boolean;
}

export const billingReportsAdapter: EntityAdapter<BillingReport> = createEntityAdapter<BillingReport>({
    selectId: (billingReport: BillingReport) => billingReport.id,
});

export const billingReportsInitialState = billingReportsAdapter.getInitialState({
    loading: false,
    loaded: false,
    saving: false
});

export function billingReportReducer(
    state: BillingReportsState = billingReportsInitialState,
    action: BillingReportAction,
): BillingReportsState {
    switch (action.type) {
        case BillingReportActionType.CREATE_BILLING_REPORT:
        case BillingReportActionType.DELETE_BILLING_REPORT:
        case BillingReportActionType.EXECUTE_ACTION_TO_BILLING_REPORT:
        case BillingReportActionType.LOAD_BILLING_REPORT_BY_ID:
        case BillingReportActionType.LOAD_BILLING_REPORTS:
        case BillingReportActionType.UPDATE_ONE_BILLING_REPORT: {
            return {
                ...state,
                saving: true,
                loading: true,
            };
        }

        case BillingReportActionType.CREATE_BILLING_REPORT_SUCCESS: {
            return billingReportsAdapter.addOne(action.billingReport, {
                ...state,
                saving: false,
                loading: false,
            });
        }

        case BillingReportActionType.LOAD_BILLING_REPORTS_SUCCESS: {
            return billingReportsAdapter.setAll(action.billingReports, {
                ...state,
                loading: false,
                saving: false,
                loaded: true,
            });
        }

        case BillingReportActionType.EXECUTE_ACTION_TO_BILLING_REPORT_SUCCESS:
        case BillingReportActionType.UPDATE_ONE_BILLING_REPORT_SUCCESS:
        case BillingReportActionType.LOAD_BILLING_REPORT_BY_ID_SUCCESS: {
            return billingReportsAdapter.upsertOne(action.billingReport, {
                ...state,
                loading: false,
                saving: false,
                loaded: true,
            });
        }

        case BillingReportActionType.LOAD_BILLING_REPORT_BY_ID_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                saving: false,
                loaded: true,
            };
        }

        case BillingReportActionType.CREATE_BILLING_REPORT_FAIL:
        case BillingReportActionType.EXECUTE_ACTION_TO_BILLING_REPORT_FAIL:
        case BillingReportActionType.LOAD_BILLING_REPORT_BY_ID_FAIL:
        case BillingReportActionType.DELETE_BILLING_REPORT_FAIL:
        case BillingReportActionType.LOAD_BILLING_REPORT_BY_ID_FAIL:
        case BillingReportActionType.LOAD_BILLING_REPORTS_FAIL:
        case BillingReportActionType.UPDATE_ONE_BILLING_REPORT_FAIL: {
            return {
                ...state,
                loading: false,
                saving: false,
                loaded: false,
            };
        }

        case BillingReportActionType.DELETE_BILLING_REPORT_SUCCESS: {
            return billingReportsAdapter.removeOne(action.billingReportId, {
                ...state,
                loading: false,
                saving: false,
                loaded: true,
            });
        }

        default:
            return state;
    }
}
