import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { UtilisateurExterne } from '../../models/utilisateur-externe.model';
import { VerificationFDT } from '../../models/verification-fdt.model';

export enum UtilisateurExterneActionType {
    LOAD_UTILISATEURS_EXTERNES = '[UtilisateurExterne] Load Utilisateurs Externes',
    LOAD_UTILISATEURS_EXTERNES_SUCCESS = '[UtilisateurExterne] Load Utilisateurs Externes Success',
    LOAD_UTILISATEURS_EXTERNES_FAIL = '[UtilisateurExterne] Load Utilisateurs Externes Fail',
    LOAD_UTILISATEURS_EXTERNES_NO_RESULTS = '[UtilisateurExterne] Load Utilisateurs Externes No Result',

    CREATE_UTILISATEUR_EXTERNE = '[UtilisateurExterne] Create an Utilisateur Externe',
    CREATE_UTILISATEUR_EXTERNE_SUCCESS = '[UtilisateurExterne] Create an Utilisateur Externe Success',
    CREATE_UTILISATEUR_EXTERNE_FAIL = '[UtilisateurExterne] Create an Utilisateur Externe Fail',

    UPDATE_ONE_UTILISATEUR_EXTERNE = '[UtilisateurExterne] Update One Utilisateur Externe',
    UPDATE_ONE_UTILISATEUR_EXTERNE_SUCCESS = '[UtilisateurExterne] Update One Utilisateur Externe Success',
    UPDATE_ONE_UTILISATEUR_EXTERNE_FAIL = '[UtilisateurExterne] Update One Utilisateur Externe Fail',

    REASSIGN_ENTREPRISE = '[UtilisateurExterne] Reassign Entreprise',
    REASSIGN_ENTREPRISE_SUCCESS = '[UtilisateurExterne] Reassign Entreprise Success',
    REASSIGN_ENTREPRISE_FAIL = '[UtilisateurExterne] Reassign Entreprise Fail',

    FINALIZE_USER_CREATION = '[UtilisateurExterne] Finalize User Creation',
    FINALIZE_USER_CREATION_SUCCESS = '[UtilisateurExterne] Finalize User Creation Success',
    FINALIZE_USER_CREATION_FAIL = '[UtilisateurExterne] Finalize User Creation Fail',

    VERIFY_FDT_INCOMPLETES = '[UtilisateurExterne] Verify Feuilles de Temps Incompletes',
    VERIFY_FDT_INCOMPLETES_SUCCESS = '[UtilisateurExterne] Verify Feuilles de Temps Incompletes Success',
    VERIFY_FDT_INCOMPLETES_FAIL = '[UtilisateurExterne] Verify Feuilles de Temps Incompletes Fail',

    DELETE_ONE_UTILISATEUR_EXTERNE = '[UtilisateurExterne] Delete one utilisateur externe',
    DELETE_ONE_UTILISATEUR_EXTERNE_SUCCESS = '[UtilisateurExterne] Delete one utilisateur externe Success',
    DELETE_ONE_UTILISATEUR_EXTERNE_FAIL = '[UtilisateurExterne] Delete one utilisateur externe Fail',
}

/**
 *  ACTIONS
 */

/**
 *  LOAD UtilisateurExterne
 */

export class LoadUtilisateursExternes implements Action {
    readonly type = UtilisateurExterneActionType.LOAD_UTILISATEURS_EXTERNES;
    constructor(public path: AlternativePath) { }
}

export class LoadUtilisateursExternesNoResult implements Action {
    readonly type = UtilisateurExterneActionType.LOAD_UTILISATEURS_EXTERNES_NO_RESULTS;
}

export class LoadUtilisateursExternesSuccess implements Action {
    readonly type = UtilisateurExterneActionType.LOAD_UTILISATEURS_EXTERNES_SUCCESS;
    constructor(public utilisateursExternes: UtilisateurExterne[]) { }
}

export class LoadUtilisateursExternesFail implements Action {
    readonly type = UtilisateurExterneActionType.LOAD_UTILISATEURS_EXTERNES_FAIL;
    constructor(public error: any) { }
}

/**
 * CREATE UtilisateurExterne
 */
export class CreateOneUtilisateurExterne implements Action {
    readonly type = UtilisateurExterneActionType.CREATE_UTILISATEUR_EXTERNE;
    constructor(public utilisateurExterne: UtilisateurExterne, public path: AlternativePath) { }
}

export class CreateUtilisateurExterneSuccess implements Action {
    readonly type = UtilisateurExterneActionType.CREATE_UTILISATEUR_EXTERNE_SUCCESS;
    constructor(public utilisateurExterne: UtilisateurExterne) { }
}
export class CreateUtilisateurExterneFail implements Action {
    readonly type = UtilisateurExterneActionType.CREATE_UTILISATEUR_EXTERNE_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE UtilisateurExterne
 */
export class UpdateOneUtilisateurExterne implements Action {
    readonly type = UtilisateurExterneActionType.UPDATE_ONE_UTILISATEUR_EXTERNE;
    constructor(public utilisateurExterne: UtilisateurExterne, public path: AlternativePath) { }
}

export class UpdateOneUtilisateurExterneSuccess implements Action {
    readonly type = UtilisateurExterneActionType.UPDATE_ONE_UTILISATEUR_EXTERNE_SUCCESS;
    constructor(public utilisateurExterne: UtilisateurExterne) { }
}
export class UpdateOneUtilisateurExterneFail implements Action {
    readonly type = UtilisateurExterneActionType.UPDATE_ONE_UTILISATEUR_EXTERNE_FAIL;
    constructor(public error: any) { }
}

/**
 * DELETE UtilisateurExterne
 */
export class DeleteOneUtilisateurExterne implements Action {
    readonly type = UtilisateurExterneActionType.DELETE_ONE_UTILISATEUR_EXTERNE;
    constructor(public utilisateurExterneId: number, public path: AlternativePath) { }
}

export class DeleteOneUtilisateurExterneSuccess implements Action {
    readonly type = UtilisateurExterneActionType.DELETE_ONE_UTILISATEUR_EXTERNE_SUCCESS;
    constructor(public deletedId: number) { }
}

export class DeleteOneUtilisateurExterneFail implements Action {
    readonly type = UtilisateurExterneActionType.DELETE_ONE_UTILISATEUR_EXTERNE_FAIL;
    constructor(public error: any) { }
}

/**
 * REASSIGN ENTREPRISE
 */
export class ReassignEntreprise implements Action {
    readonly type = UtilisateurExterneActionType.REASSIGN_ENTREPRISE;
    constructor(public utilisateurExterne: UtilisateurExterne, public path: AlternativePath) { }
}

export class ReassignEntrepriseSuccess implements Action {
    readonly type = UtilisateurExterneActionType.REASSIGN_ENTREPRISE_SUCCESS;
    constructor(public utilisateursExternes: UtilisateurExterne[]) { }
}
export class ReassignEntrepriseFail implements Action {
    readonly type = UtilisateurExterneActionType.REASSIGN_ENTREPRISE_FAIL;
    constructor(public error: any) { }
}

/**
 * FINALIZE USER CREATION
 */
export class FinalizeUserCreation implements Action {
    readonly type = UtilisateurExterneActionType.FINALIZE_USER_CREATION;
    constructor(public utilisateurExterne: UtilisateurExterne, public path: AlternativePath) { }
}

export class FinalizeUserCreationSuccess implements Action {
    readonly type = UtilisateurExterneActionType.FINALIZE_USER_CREATION_SUCCESS;
    constructor(public utilisateurExterne: UtilisateurExterne) { }
}
export class FinalizeUserCreationFail implements Action {
    readonly type = UtilisateurExterneActionType.FINALIZE_USER_CREATION_FAIL;
    constructor(public error: any) { }
}

/**
 * VERIFIER FDT INCOMPLETES
 */
export class VerifierFDTIncompletes implements Action {
    readonly type = UtilisateurExterneActionType.VERIFY_FDT_INCOMPLETES;
    constructor(public utilisateurExterneID: number, public path: AlternativePath) { }
}

export class VerifierFDTIncompletesSuccess implements Action {
    readonly type = UtilisateurExterneActionType.VERIFY_FDT_INCOMPLETES_SUCCESS;
    constructor(public fdtIncompletes: VerificationFDT) { }
}
export class VerifierFDTIncompletesFail implements Action {
    readonly type = UtilisateurExterneActionType.VERIFY_FDT_INCOMPLETES_FAIL;
    constructor(public error: any) { }
}

export type UtilisateurExterneAction =
    | LoadUtilisateursExternes
    | LoadUtilisateursExternesNoResult
    | LoadUtilisateursExternesSuccess
    | LoadUtilisateursExternesFail
    | CreateOneUtilisateurExterne
    | CreateUtilisateurExterneSuccess
    | CreateUtilisateurExterneFail
    | UpdateOneUtilisateurExterne
    | UpdateOneUtilisateurExterneSuccess
    | UpdateOneUtilisateurExterneFail
    | DeleteOneUtilisateurExterne
    | DeleteOneUtilisateurExterneSuccess
    | DeleteOneUtilisateurExterneFail
    | ReassignEntreprise
    | ReassignEntrepriseSuccess
    | ReassignEntrepriseFail
    | VerifierFDTIncompletes
    | VerifierFDTIncompletesSuccess
    | VerifierFDTIncompletesFail
    | FinalizeUserCreation
    | FinalizeUserCreationSuccess
    | FinalizeUserCreationFail;
