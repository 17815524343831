<mat-nav-list class="navigation">
    <div class="navigation__list-container">
        <nav mat-list-item class="list-container__itemNav" aria-label="accueil" [routerLink]="['accueil']" [routerLinkActive]="'active-link'">
            <i class="fas fa-home itemNav__icon"></i>
            <div *ngIf="displayTitle" class="itemNav__text"> Accueil </div>
        </nav>

        <ng-container *ngIf="isConnected && pageTimesheet">
            <nav mat-list-item class="list-container__itemNav" aria-label="feuille de temps" *hasRole="roleFDT" [routerLink]="['temps']" [routerLinkActive]="'active-link'">
                <i class="far fa-calendar-alt itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> Feuille de temps </div>
            </nav>
        </ng-container>
        <ng-container *ngIf="isConnected && pageReportEnt">
            <nav mat-list-item class="list-container__itemNav" aria-label="Rapports de feuille de temps" *hasRole="roleRapportFDT" [routerLink]="['rapports', 'timesheet', 'ent']" [routerLinkActive]="'active-link'">
                <i class="far fa-file-alt itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> Rapports feuilles de temps </div>
            </nav>
        </ng-container>
        <ng-container *ngIf="isConnected && pageReportHQ">
            <nav mat-list-item class="list-container__itemNav" aria-label="rapports de feuille de temps HQ" *hasRole="roleRapportFDTHQ" [routerLink]="['rapports', 'timesheet', 'hq']" [routerLinkActive]="'active-link'">
                <i class="far fa-file-alt itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> Rapports feuilles de temps (HQ) </div>
            </nav>
        </ng-container>
        <ng-container *ngIf="isConnected && pageProgressReport">
            <nav mat-list-item class="list-container__itemNav" aria-label="Rapports d'avancement" *hasRole="roleRapportsAvancement" [routerLink]="['rapports', 'avancement']" [routerLinkActive]="'active-link'">
                <i class="fa-solid fa-chart-column itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> Rapports d’avancement </div>
            </nav>
        </ng-container>
        <ng-container *ngIf="isConnected && pageBillingEnt">
            <nav mat-list-item class="list-container__itemNav" aria-label="Rapports de facturation" *hasRole="roleFacturation" [routerLink]="['facturation', 'rapports', 'ent']" [routerLinkActive]="'active-link'">
                <i class="fas fa-dollar-sign itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> Rapports de facturation </div>
            </nav>
        </ng-container>
        <ng-container *ngIf="isConnected && pageBillingHQ">
            <nav mat-list-item class="list-container__itemNav" aria-label="Rapport de facturation HQ" *hasRole="roleFacturationHQ" [routerLink]="['facturation', 'rapports', 'hq']" [routerLinkActive]="'active-link'">
                <i class="fas fa-dollar-sign itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> Rapports de facturation (HQ) </div>
            </nav>
        </ng-container>
        <ng-container *ngIf="isConnected && pageClientRequest">
            <nav mat-list-item class="list-container__itemNav" aria-label="Demandes clients" *hasRole="roleClientRequests" [routerLink]="['demandeClient', 'hq']" [routerLinkActive]="'active-link'">
                <i class="fa-solid fa-question itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> Demandes clients </div>
            </nav>
        </ng-container>
        <ng-container *ngIf="isConnected && pageProjectRequest">
            <nav mat-list-item class="list-container__itemNav" aria-label="Demandes projets" *hasRole="roleProjectRequests" [routerLink]="['demandesProjet', 'hq']" [routerLinkActive]="'active-link'">
                <i class="fas fa-tasks itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> Demandes projets </div>
            </nav>
        </ng-container>
        <ng-container *ngIf="isConnected && pageMesuresParticulieres">
            <nav mat-list-item class="list-container__itemNav" aria-label="Mesures particulières" *hasRole="roleSpecialMeasures" [routerLink]="['specialMeasures', 'hq']" [routerLinkActive]="'active-link'">
                <i class="fas fa-tools itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> Mesures particulières </div>
            </nav>
        </ng-container>

        <ng-container *ngIf="isConnected && pagePilotage">
            <nav mat-list-item class="list-container__itemNav" aria-label="Pilotage" *hasRole="rolePilotage" [routerLink]="['pilotages', 'hq']" [routerLinkActive]="'active-link'">
                <i class="fas fa-cogs itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> Pilotage </div>
            </nav>
        </ng-container>

        <ng-container *ngIf="isConnected && pageOgivEnt">
            <nav mat-list-item class="list-container__itemNav" aria-label="OGIV - Entrepreneur" *hasRole="roleOGIVEntrepreneur" (click)="externalInternPath('entrepreneur')">
                <i class="fas fa-tree itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> OGIV - Entrepreneur </div>
            </nav>
        </ng-container>

        <ng-container *ngIf="isConnected && pageOgivHQ">
            <nav mat-list-item class="list-container__itemNav" aria-label="OGIV - Technicien" *hasRole="roleOGIVTechnicien" (click)="externalInternPath('technicien')">
                <i class="fas fa-globe-americas itemNav__icon"></i>
                <div *ngIf="displayTitle" class="itemNav__text"> OGIV - Technicien </div>
            </nav>
        </ng-container>
    </div>
 </mat-nav-list>
