import { Order } from './order.model';
import { Error } from './error.model';

export class OrderGroupRawData {
    public number: string;
    public accountingEntry: string;
    public unitly: Order | null;
    public hourly: Order | null;
    public outage: Order | null;
}
export class OrderGroup {
    public number: string;
    public accountingEntry: string;
    public unitly: Order | null;
    public hourly: Order | null;
    public outage: Order | null;

    constructor(data: OrderGroupRawData) {
        Object.keys(data).forEach((key: string) => {
            this[key] = data[key];
        });
    }

    public validate(validateChildren: boolean = false): Error | null {
        let errors: Error | null = null;
        const errorOrders: Error[] = [];

        if (this.unitly === null && this.hourly === null && this.outage === null) {
            errors = {
                name: 'Orders',
                list: ['il est obligatoire d\'avoir une commande de type "Unitaire", "Horaire" ou "Panne"'],
                last: true,
            };
            return errors;
        }

        if (validateChildren) {
            if (this.unitly !== null) {
                const err = new Order(this.unitly).validate(validateChildren);
                if (err !== null) {
                    errorOrders.push(err);
                }
            }
            if (this.hourly !== null) {
                const err = new Order(this.hourly).validate(validateChildren);
                if (err !== null) {
                    errorOrders.push(err);
                }
            }
            if (this.outage !== null) {
                const err = new Order(this.outage).validate(validateChildren);
                if (err !== null) {
                    errorOrders.push(err);
                }
            }
        }

        if (errorOrders.length > 0) {
            errors = { name: `Commande #${this.number}`, list: errorOrders, last: false };
        }

        return errors;
    }
}
