import moment from 'moment';
import { UtilisateurInterne } from '../../pilotages/models/utilisateur-interne.model';

export class DemandesClientHelper {
    private static dateFormatRemarque = 'YYYY/MM/DD hh:mm:ss';

    public static extractDerniereRemarque(remarque: string): string {
        // Les remarques sont habituellement une grande chaine de caractères formée comme suit :
        // * [Date] [Nom et code utilisateur] [telephone] [titre] * rem. [type de remarque]
        // [texte de la remarque]

        let position = 0;

        // Prend la dernière marque de date : Ex * 2021-06-06
        const indexDernierTagDate = remarque.lastIndexOf('* 2');
        if (indexDernierTagDate !== -1) {
            position = indexDernierTagDate;
        }
        return remarque.substring(position);
    }

    public static extractTextRemarque(remarque: Readonly<string>): string {
        let position = 0;

        // Prend la tag de type de remarque qui suit
        const indexTagTypeRemarque = remarque.indexOf('* Rem');
        if (indexTagTypeRemarque !== -1) {
            position = indexTagTypeRemarque;
            // avance jusqu'au saut de ligne
            const indexFinDeLigne = remarque.indexOf('\n', indexTagTypeRemarque);
            if (indexFinDeLigne !== -1) {
                position = indexFinDeLigne + 1;
            }
        } else {
            if (remarque.lastIndexOf('* 2') !== -1) {
                const indexDoubleFinDeLigne = remarque.indexOf('\n\n');
                const indexFinDeLigne = remarque.indexOf('\n');

                if (indexDoubleFinDeLigne !== -1 && indexDoubleFinDeLigne === indexFinDeLigne) {
                    position = indexDoubleFinDeLigne + 2;
                } else {
                    if (indexFinDeLigne !== -1) {
                        position = indexFinDeLigne + 1;
                    }
                }
            }
        }
        return remarque.substring(position);
    }

    public static formatRemarqueForSAP(remarque: Readonly<string>): string {

        const averageLineLength = 70;
        const regexMatcher = new RegExp('.{1,' + averageLineLength + '}(\\s|$)', 'g');

        const lignes = remarque.split('\n');
        const lignesFormatees: string[] = [];

        lignes.forEach((ligne: string) => {

            if (ligne.length > averageLineLength) {

                const cutLines = ligne.match(regexMatcher);
                if (cutLines) {
                    cutLines.forEach((cutLine: string) => {
                        lignesFormatees.push(cutLine);
                    });
                }
            } else {
                lignesFormatees.push(ligne);
            }
        });
        return '\n' + lignesFormatees.join('\n');
    }

    public static getRemarqueAbregee(remarque: Readonly<string>): string {
        return remarque.substring(0, 45).split('\n').join('&nbsp;');
    }

    public static initNouvelleRemarque(currentUser: UtilisateurInterne | undefined): string {
        let tagRemarque = `* ${moment().format(this.dateFormatRemarque)}`;

        if (currentUser) {
            if (currentUser.prenom !== null) {
                tagRemarque += ` ${currentUser.prenom} ${currentUser.nom}`;
            }

            if (currentUser.cip !== null) {
                tagRemarque += ` (${currentUser.cip})`;
            }

            if (currentUser.telephone_interne !== null) {
                tagRemarque += ` Tel. ${currentUser.telephone_interne} `;
            }

            if (currentUser.titre_emploi !== null) {
                tagRemarque += currentUser.titre_emploi;
            }
        }
        return tagRemarque + '\n\n';
    }
}
