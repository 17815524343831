import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { PilotagesState } from '../../../store/reducers';
import { getRouterData } from '../../../../core/store/selectors';
import { AlternativePath } from '../../../../shared/models/atlernative-path.model';

@Component({
    selector: 'app-pilotages-basic',
    templateUrl: './pilotages-basic.component.html',
    styleUrls: ['./pilotages-basic.component.scss'],
})

export class PilotagesBasicComponent implements OnInit {
    public selectedIndex: number | null = null;
    public loaderLogoSize = 75;

    public sortField = 'Nom';
    public sortOrder = 1;
    public translatePrefixEntrepreneurs = 'pilotages.entrepreneurs';
    public translatePrefixUtilisateurs = 'pilotages.utilisateurs';
    public translatePrefixUtilisateursSynchro = 'pilotages.utilisateurs-synchronisation';
    public translatePrefixGestionCodesSAP = 'pilotages.gestionCodesSAP';

    public pathChoice: AlternativePath = AlternativePath.hq;
    public reloadData = false;
    public currentView = '';

    constructor(
        private readonly store: Store<PilotagesState>,
    ) { }

    public ngOnInit(): void {
        this.store.pipe(select(getRouterData)).subscribe((data: any) => {
            this.pathChoice = data && data.pathChoice || AlternativePath.hq;
        });

        this.selectedIndex = null;
        this.reload();
    }

    public reload(): void {
        this.reloadData = true;
    }

    public reloadedData(): void {
        this.reloadData = false;
    }

    public setCurrentView(view: string): void {
        this.currentView = view;
    }
}
