import { BillingCompanyEffects } from './billing-company.effect';
import { BillingOrdersEffects } from './billing-order.effect';
import { BillingReportsEffects } from './billing-report.effect';
import { BillingReportPreviewEffects } from './billing-report-preview.effect';
import { BillingSapEntriesEffects } from './billing-sap-entries.effect';
import { BillingTimesheetsEffects } from './billing-timesheet.effect';

export const billingStoreEffects = [
    BillingCompanyEffects,
    BillingOrdersEffects,
    BillingReportsEffects,
    BillingReportPreviewEffects,
    BillingSapEntriesEffects,
    BillingTimesheetsEffects,
];
