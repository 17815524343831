export interface SpecialMeasures {
    PRESCRIPTION_DATA: PrescriptionData;
    NACELLES: string[];
    RESEAUX: string[];
}

export class PrescriptionData {
    NOM_COUCHE: string;
    PRESCRIPTIONS: Prescription[];
}

export class PrescriptionRawData {
    ID: number;
    globalID: string;
    OBJECTID: number;
    numero: string;
    statut: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Pole: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Site: string | null;
    poste: string | null;
    ligne: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Commande: string | null;
    adresse: string | null;
    LCLCL: string | null;
    residu: string | null;
    numeroImputation: string;
    mesurePartLe: Date | null;
    mesurePartPar: string | null;
    typeMesurePart: string | null;
    commentaireMesurePart: string | null;
    justificationMesurePart: string;
    qteCouvreConducteurs: number | null;
    qteCCIsolateurFinCourse: number | null;
    qteCCIsolateurTete: number | null;
    dureeMiseHorsTension: number | null;
    code: string | null;
    mesurePartApprouvParEnt: string | null;
    mesurePartApprouvParHQ: string | null;
    remarque: string | null;
    NO_CIVIQUE: string | null;
    RUE: string | null;
    MUNICIPALITE: string | null;
    TYPE_RESEAU: string | null;
    DESCR_NACELLE_CHENILLE: string | null;
    QTE_UNITE: string | null;
    GRUE: string | null;
    NBR_EQUIPE_ARBO: string | null;
    VISITE_PAR: string | null;
    DATE_PRCH_VISITE: Date | null;
    PRIORITE: string | null;
    DATE_REMISE: Date | null;
    STATUT_REALISATION: string | null;
    DATE_PLANIF: Date | null;
    DATE_COMPLETE: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    NoteInterne: string | null;
    REMARQUES_AUTRES: string | null;
    MODIFIE_PAR: string | null;
    MODIFIE_LE: Date | null;
    PROVENANCE: string | null;
    typeRemuneration: string | null;
}

export class Prescription {
    ID: number;
    globalID: string;
    OBJECTID: number;
    numero: string;
    statut: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Pole: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Site: string | null;
    poste: string | null;
    ligne: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Commande: string | null;
    PosteCommande: string | null;
    adresse: string | null;
    LCLCL: string | null;
    residu: string | null;
    numeroImputation: string;
    mesurePartLe: Date | null;
    mesurePartPar: string | null;
    typeMesurePart: string | null;
    commentaireMesurePart: string | null;
    justificationMesurePart: string;
    qteCouvreConducteurs: number | null;
    qteCCIsolateurFinCourse: number | null;
    qteCCIsolateurTete: number | null;
    dureeMiseHorsTension: number | null;
    code: string | null;
    mesurePartApprouvParEnt: string | null;
    mesurePartApprouvParHQ: string | null;
    remarque: string | null;
    NO_CIVIQUE: string | null;
    RUE: string | null;
    MUNICIPALITE: string | null;
    TYPE_RESEAU: string | null;
    DESCR_NACELLE_CHENILLE: string | null;
    QTE_UNITE: string | null;
    GRUE: string | null;
    NBR_EQUIPE_ARBO: string | null;
    VISITE_PAR: string | null;
    DATE_PRCH_VISITE: Date | null;
    PRIORITE: string | null;
    DATE_REMISE: Date | null;
    STATUT_REALISATION: string | null;
    DATE_PLANIF: Date | null;
    DATE_COMPLETE: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    NoteInterne: string | null;
    REMARQUES_AUTRES: string | null;
    MODIFIE_PAR: string | null;
    MODIFIE_LE: Date | null;
    PROVENANCE: string | null;
    typeRemuneration: string | null;

    constructor(data: PrescriptionRawData) {
        Object.keys(data).forEach((key: string) => {
            this[key] = data[key];
        });
    }
}

export class UpdatedPrescription {
    ID: number;
    NO_CIVIQUE: string | null;
    RUE: string | null;
    MUNICIPALITE: string | null;
    TYPE_RESEAU: string | null;
    DESCR_NACELLE_CHENILLE: string | null;
    QTE_UNITE: string | null;
    GRUE: string | null;
    NBR_EQUIPE_ARBO: string | null;
    VISITE_PAR: string | null;
    DATE_PRCH_VISITE: Date | null;
    PRIORITE: string | null;
    DATE_REMISE: Date | null;
    STATUT_REALISATION: string | null;
    DATE_PLANIF: Date | null;
    DATE_COMPLETE: Date | null;
    REMARQUES_AUTRES: string | null;
    MODIFIE_LE: Date | null;
}
