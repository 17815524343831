<div class="fullheight">
    <form [formGroup]="utilisateurInterneForm" (ngSubmit)="onSubmit()">
        <div class="group__field">
            <div class="field__row">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.prenom' | translate }}</mat-label>
                    <input matInput type="text" formControlName="prenom" />
                </mat-form-field>
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.nom' | translate }}</mat-label>
                    <input matInput type="text" formControlName="nom" />
                </mat-form-field>
            </div>
            <div class="field__row">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.pole' | translate }}</mat-label>
                    <input matInput type="text" formControlName="pole" />
                </mat-form-field>
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.site' | translate }}</mat-label>
                    <input matInput type="text" formControlName="site" />
                </mat-form-field>
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.cadena' | translate }}
                    </mat-label>
                    <input matInput type="text" formControlName="cadena" />
                </mat-form-field>
            </div>
            <div class="field__row">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.numero_vehicule' | translate }}
                    </mat-label>
                    <input matInput type="text" formControlName="numero_vehicule" />
                </mat-form-field>
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.numero_tablette' | translate }}
                    </mat-label>
                    <input matInput type="text" formControlName="numero_tablette" />
                </mat-form-field>
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.numero_cellulaire' | translate }}
                    </mat-label>
                    <input [title]="translatePrefix + '.tooltip.telephonePattern' | translate"
                        formControlName="numero_cellulaire"
                        matInput type="text"
                        [patterns]="pattern"
                        [specialCharacters]="['#',':', '(', ')', '-', ' ']"
                        mask="(000) 000-0000||(000) 000-0000    #: 0 ||(000) 000-0000    #: 00 ||(000) 000-0000    #: 000 ||(000) 000-0000    #: 0000 ||(000) 000-0000    #: 00000 ||(000) 000-0000    #: 000000" />
                    <mat-error *ngIf="utilisateurInterneForm.controls.numero_cellulaire.invalid">
                        {{ this.translatePrefix + '.errors' + '.telephonePattern' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="field__row">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.numero_ordinateur' | translate }}
                    </mat-label>
                    <input matInput type="text" formControlName="numero_ordinateur" />
                </mat-form-field>
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.numero_ecran' | translate }}
                    </mat-label>
                    <input matInput type="text" formControlName="numero_ecran" />
                </mat-form-field>
            </div>
            <div class="field__row">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.horaire' | translate }}
                    </mat-label>
                    <mat-select formControlName="horaire" name="horaire">
                        <mat-option *ngFor="let horaire of horaires" [value]="horaire.value">
                            {{horaire.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.courriel' | translate }}
                    </mat-label>
                    <input matInput type="text" formControlName="courriel" />
                    <mat-error *ngIf="utilisateurInterneForm.controls.courriel.invalid">
                        {{ this.translatePrefix + '.errors' + '.email' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="field__row">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.telephone_residence' | translate }}</mat-label>
                    <input [title]="translatePrefix + '.tooltip.telephonePattern' | translate"
                        formControlName="telephone_residence"
                        matInput type="text"
                        [patterns]="pattern"
                        [specialCharacters]="['#',':', '(', ')', '-', ' ']"
                        mask="(000) 000-0000||(000) 000-0000    #: 0 ||(000) 000-0000    #: 00 ||(000) 000-0000    #: 000 ||(000) 000-0000    #: 0000 ||(000) 000-0000    #: 00000 ||(000) 000-0000    #: 000000" />
                    <mat-error *ngIf="utilisateurInterneForm.controls.telephone_residence.invalid">
                        {{ this.translatePrefix + '.errors' + '.telephonePattern' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.telephone_interne' | translate }}</mat-label>
                    <input [title]="translatePrefix + '.tooltip.telephonePattern' | translate"
                        matInput type="text"
                        formControlName="telephone_interne"
                        [patterns]="pattern"
                        [specialCharacters]="['#',':', '(', ')', '-', ' ']"
                        mask="(000) 000-0000||(000) 000-0000    #: 0 ||(000) 000-0000    #: 00 ||(000) 000-0000    #: 000 ||(000) 000-0000    #: 0000 ||(000) 000-0000    #: 00000 ||(000) 000-0000    #: 000000" />
                    <mat-error *ngIf="utilisateurInterneForm.controls.telephone_interne.invalid">
                        {{ this.translatePrefix + '.errors' + '.telephonePattern' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="field__row">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.telephone_externe' | translate }}</mat-label>
                    <input [title]="translatePrefix + '.tooltip.telephonePattern' | translate"
                        matInput type="text"
                        formControlName="telephone_externe"
                        [patterns]="pattern"
                        [specialCharacters]="['#',':', '(', ')', '-', ' ']"
                        mask="(000) 000-0000||(000) 000-0000    #: 0 ||(000) 000-0000    #: 00 ||(000) 000-0000    #: 000 ||(000) 000-0000    #: 0000 ||(000) 000-0000    #: 00000 ||(000) 000-0000    #: 000000" />
                    <mat-error *ngIf="utilisateurInterneForm.controls.telephone_externe.invalid">
                        {{ this.translatePrefix + '.errors' + '.telephonePattern' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.fax' | translate }}
                    </mat-label>
                    <input [title]="translatePrefix + '.tooltip.telephonePattern' | translate"
                        formControlName="fax"
                        matInput type="text"
                        [patterns]="pattern"
                        [specialCharacters]="['#',':', '(', ')', '-', ' ']"
                        mask="(000) 000-0000||(000) 000-0000    #: 0 ||(000) 000-0000    #: 00 ||(000) 000-0000    #: 000 ||(000) 000-0000    #: 0000 ||(000) 000-0000    #: 00000 ||(000) 000-0000    #: 000000" />
                    <mat-error *ngIf="utilisateurInterneForm.controls.fax.invalid">
                        {{ this.translatePrefix + '.errors' + '.telephonePattern' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="field__row">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.carte_acces' | translate }}</mat-label>
                    <input matInput type="text" formControlName="carte_acces" />
                </mat-form-field>
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.carte_essence' | translate }}</mat-label>
                    <input matInput type="text" formControlName="carte_essence" />
                </mat-form-field>
            </div>
            <div class="field__row">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.carte_sim_tablette' | translate }}</mat-label>
                    <input matInput type="text" formControlName="carte_sim_tablette" />
                </mat-form-field>
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.carte_sim_cellulaire' | translate }}</mat-label>
                    <input matInput type="text" formControlName="carte_sim_cellulaire" />
                </mat-form-field>
            </div>

            <div class="field__row">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.bureau' | translate }}
                    </mat-label>
                    <input matInput type="text" formControlName="bureau" />
                </mat-form-field>
            </div>
        </div>

        <app-generic-form-buttons
            [disabled]="utilisateurInterneForm.invalid"
            [saveTooltipLabel]="'Confirmer'"
            [cancelTooltipLabel]="'Cancel'"
            (cancel)="cancelForm()">
        </app-generic-form-buttons>
    </form>

</div>
