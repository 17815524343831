import { createSelector } from '@ngrx/store';
import { PilotagesState, getPilotagesState } from '../reducers';
import { ProfilFormationUtilisateurState } from '../reducers/profil-formation-utilisateur.reducer';

export const getProfilFormationUtilisateurState = createSelector(
    getPilotagesState,
    (state: PilotagesState) => state.profilFormationUtilisateur,
);

export const getProfilFormationUtilisateurLoading = createSelector(
    getProfilFormationUtilisateurState,
    (state: ProfilFormationUtilisateurState) => state.loading,
);

export const getProfilFormationUtilisateurLoaded = createSelector(
    getProfilFormationUtilisateurState,
    (state: ProfilFormationUtilisateurState) => state.loaded,
);

export const getAllFormationsUtilisateur = createSelector(
    getProfilFormationUtilisateurState,
    (state: ProfilFormationUtilisateurState) => state.formationCounts,
);

export const shouldLoadProfilFormationUtilisateur = createSelector(
    getProfilFormationUtilisateurLoading,
    getProfilFormationUtilisateurLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);
