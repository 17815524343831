import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { Prescription } from '../../../shared/models/database/special-measures.model';
import { SpecialMeasuresAction, SpecialMeasuresActionType } from '../actions/special-measures.actions';

export interface PrescriptionsState extends EntityState<Prescription> {
    loading: boolean;
    loaded: boolean;
    nacelles: string[];
    reseaux: string[];
}

export const prescriptionsAdapter: EntityAdapter<Prescription> = createEntityAdapter<Prescription>({
    selectId: (prescription: Prescription) => prescription.ID,
});

export const prescriptionsInitialState = prescriptionsAdapter.getInitialState({
    loading: false,
    loaded: false,
    nacelles: [],
    reseaux: [],
});

export function specialMeasuresReducer(
    state: PrescriptionsState = prescriptionsInitialState,
    action: SpecialMeasuresAction,
): PrescriptionsState {
    switch (action.type) {
        case SpecialMeasuresActionType.LOAD_SPECIAL_MEASURES:
        case SpecialMeasuresActionType.UPDATE_ONE_PRESCRIPTION: {
            return {
                ...state,
                loading: true,
            };
        }

        case SpecialMeasuresActionType.LOAD_SPECIAL_MEASURES_SUCCESS: {
            return prescriptionsAdapter.setAll(action.specialMeasures.PRESCRIPTION_DATA.PRESCRIPTIONS, {
                ...state,
                nacelles: action.specialMeasures.NACELLES,
                reseaux: action.specialMeasures.RESEAUX,
                loading: false,
                loaded: true,
            });
        }

        case SpecialMeasuresActionType.UPDATE_ONE_PRESCRIPTION_SUCCESS: {
            return prescriptionsAdapter.upsertOne(action.specialMeasures.PRESCRIPTION_DATA.PRESCRIPTIONS[0], {
                ...state,
                nacelles: action.specialMeasures.NACELLES,
                reseaux: action.specialMeasures.RESEAUX,
                loading: false,
                loaded: true,
            });
        }

        case SpecialMeasuresActionType.LOAD_SPECIAL_MEASURES_FAIL: {
            return {
                ...state,
                nacelles: [],
                reseaux: [],
                loading: false,
                loaded: false,
            };
        }

        case SpecialMeasuresActionType.UPDATE_ONE_PRESCRIPTION_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
