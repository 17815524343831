import { TableColumns } from '../../../../../shared/models/table-columns.model';

export const entrepreneursColumns: TableColumns[] = [
    { field: 'indicator', header: 'indicator', type: 'shortTag', width: '28px', filterType: 'noFilter' },
    { field: 'id', header: 'Id', filterType: 'text' },
    { field: 'nom', header: 'Nom', filterType: 'text' },
    { field: 'abreviation', header: 'Abrv', filterType: 'text' },
    { field: 'adresse', header: 'Address', filterType: 'text' },
    { field: 'responsable', header: 'Responsible', filterType: 'text' },
    { field: 'telephone', header: 'Phone', filterType: 'text' },
    { field: 'statut', header: 'Status', type: 'tag', filterType: 'select' },
];
