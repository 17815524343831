import { AddBillingReportComponent } from './reports/add-billing-report/add-billing-report.component';
import { BillingComponent } from './billing.component';
import { BillingReportsComponent } from './reports/billing-reports.component';
import { BillingReportFormComponent } from './reports/billing-report-form/billing-report-form.component';
import { BillingReportTableComponent } from './reports/billing-report-table/billing-report-table.component';
import { ContractorBillingReportsListComponent } from './reports/list/contractor/contractor-billing-reports-list.component';
import { CGCBillingReportsListComponent } from './reports/list/cgc/cgc-billing-reports-list.component';
import { DisplayBillingReportComponent } from './reports/display-billing-report/display-billing-report.component';
import { EditBillingReportComponent } from './reports/edit-billing-report/edit-billing-report.component';
import { PreviewBillingReportComponent } from './reports/preview-billing-report/preview-billing-report.component';
import { SapEntriesReportComponent } from './reports/sap-entries-report/sap-entries-report.component';

export const billingComponents = [
    /**
     * Router outlets
     */
    BillingComponent,
    BillingReportsComponent,

    /**
     * Reports
     */
    AddBillingReportComponent,
    BillingReportFormComponent,
    BillingReportTableComponent,
    ContractorBillingReportsListComponent,
    CGCBillingReportsListComponent,
    DisplayBillingReportComponent,
    EditBillingReportComponent,
    PreviewBillingReportComponent,
    SapEntriesReportComponent
];

export * from './billing.component';
export * from './reports/billing-reports.component';
export * from './reports/list/contractor/contractor-billing-reports-list.component';
export * from './reports/list/cgc/cgc-billing-reports-list.component';
export * from './reports/add-billing-report/add-billing-report.component';
export * from './reports/display-billing-report/display-billing-report.component';
export * from './reports/edit-billing-report/edit-billing-report.component';
export * from './reports/billing-report-form/billing-report-form.component';
export * from './reports/billing-report-table/billing-report-table.component';
export * from './reports/preview-billing-report/preview-billing-report.component';
export * from './reports/sap-entries-report/sap-entries-report.component';
