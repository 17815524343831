import { TableColumns } from '../../../../../../shared/models/table-columns.model';

export const editTimesheetCommandePosteTypeColumns: TableColumns[] = [
    { field: 'teamLeader', header: 'teamLeader', width: '100px' },
    { field: 'date', header: 'date', type: 'date', width: '100px' },
    { field: 'order', header: 'order' },
    { field: 'accountingEntry', header: 'accountingEntry' },
    { field: 'billingType', header: 'billingType' },
    { field: 'timesheetReportStatus', header: 'timesheetReportStatus' },
    { field: 'timesheetReportNumber', header: 'timesheetReportNumber' },
    { field: 'billingReportStatus', header: 'billingReportStatus' },
    { field: 'billingReportNumber', header: 'billingReportNumber' },
];
