import { Component, Input } from '@angular/core';

import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { OrderGroup } from '../../../../../shared/models/database/order-group.model';
import { Timesheet } from '../../../../../shared/models/database/timesheet.model';
import { UserRole } from '../../../../../shared/models/user-roles.model';

@Component({
    selector: 'app-order-section',
    templateUrl: './order-section.component.html',
    styleUrls: ['./order-section.component.scss']
})
export class OrderSectionComponent {
    @Input() public timesheet: Timesheet;
    @Input() public pathChoice: AlternativePath;
    @Input() public editAllowedRoles: UserRole[] = [];

    public orderGroupTracking(_: number, { accountingEntry, number }: OrderGroup): string {
        return `${accountingEntry}_${number}`;
    }

    public displayOrders(timesheet: Timesheet): boolean {
        return (timesheet.orderGroups && timesheet.orderGroups.length > 0) ? true : false;
    }
}
