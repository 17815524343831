import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { OgivCoreState, getOgivCoreState } from '../reducers';
import { unitCodesAdapter, UnitCodesState } from '../reducers/unit-codes.reducer';
import { UnitCode, UnitCodeStatus } from '../../../shared/models/database/unit-code.model';
import { SelectItem } from 'primeng/api';
import moment from 'moment';

const {
    selectAll,
    selectEntities,
    selectIds,
} = unitCodesAdapter.getSelectors();

export const getUnitCodesState = createSelector(
    getOgivCoreState,
    (state: OgivCoreState) => state.unitCodes,
);

export const getUnitCodesLoading = createSelector(
    getUnitCodesState,
    (state: UnitCodesState) => state.loading,
);

export const getUnitCodesLoaded = createSelector(
    getUnitCodesState,
    (state: UnitCodesState) => state.loaded,
);

export const shouldLoadUnitCodes = createSelector(
    getUnitCodesLoading,
    getUnitCodesLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllUnitCodes = createSelector(
    getUnitCodesState,
    selectAll
);

export const getUnitCodesEntities = createSelector(
    getUnitCodesState,
    selectEntities,
);

export const getUnitCodesIds = createSelector(
    getUnitCodesState,
    selectIds,
);

export const getUnitCodeById = (prop: { entityId: number }) => createSelector(
    getUnitCodesEntities,
    (entities: Dictionary<UnitCode>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllUnitCodesSorted = createSelector(
    getAllUnitCodes,
    (unitCodes: UnitCode[]): UnitCode[] => {
        const tempoUnitCodes = [...unitCodes];
        tempoUnitCodes.sort((a: UnitCode, b: UnitCode): number => {
            return (a.code.toLocaleLowerCase()).localeCompare(b.code.toLocaleLowerCase());
        });
        return tempoUnitCodes;
    }
);

export const getActiveUnitCodes = (props: { date: string } = { date: '' }) => createSelector(
    getAllUnitCodesSorted,
    (unitCodes: UnitCode[]): UnitCode[] => {
        const activeUnitCodes = getAllActiveUnitCodes(unitCodes, props.date);
        return activeUnitCodes;
    }
);

export const doesSelectedUnitCodeIsActive = (props: { code: string } = { code: '' }) => createSelector(
    getActiveUnitCodes(),
    (unitCodes: UnitCode[]): boolean => {
        const found = (unitCodes.filter((unitCode: UnitCode) => unitCode.code === props.code));
        return found.length > 0;
    }
);

export const getActiveUnitCodesOptions = (props: { date: string } = { date: '' }) => createSelector(
    getAllUnitCodesSorted,
    (unitCodes: UnitCode[]): SelectItem[] => {
        const unitCodesOptions = getAllActiveUnitCodes(unitCodes, props.date);
        return unitCodesOptions.map((unitCode: UnitCode) => {
            return {
                label: `(${unitCode.code}) ${unitCode.description}`,
                value: unitCode.code,
            } as SelectItem;
        });
    }
);

export const getActiveUnitCodesByCode = (props: { date: string, code: string } = { date: '', code: '' }) => createSelector(
    getAllUnitCodesSorted,
    (unitCodes: UnitCode[]): UnitCode | null => {
        const activeUnitCodes = getAllActiveUnitCodes(unitCodes, props.date);
        const filteredUnitCode = activeUnitCodes.filter((unitCode: UnitCode) => unitCode.code === props.code);
        return (filteredUnitCode.length === 0 || filteredUnitCode.length > 1) ? null : filteredUnitCode[0];
    }
);

export const getUnitBySearchOptions = (props: { text: string, date: string } = { text: '', date: '' }) => createSelector(
    getAllUnitCodesSorted,
    (unitCodes: UnitCode[]): SelectItem[] => {
        const activeUnitCodes = getAllActiveUnitCodes(unitCodes, props.date);
        const unitCodesOptions = activeUnitCodes.filter(unitCode => {
            return unitCode.code.toUpperCase().includes(props.text.toUpperCase())
                || unitCode.description.toUpperCase().includes(props.text.toUpperCase());
        });

        return unitCodesOptions.map((unitCode: UnitCode) => {
            return {
                label: `(${unitCode.code}) ${unitCode.description}`,
                value: unitCode.code,
            } as SelectItem;
        });
    }
);

function getAllActiveUnitCodes(unitCodes: UnitCode[], date: string): UnitCode[] {
    let activeUnitCodes;
    if (date === undefined || date === '') {
        activeUnitCodes = unitCodes.filter(unitCode => unitCode.status.toLocaleLowerCase() === UnitCodeStatus.active.toLocaleLowerCase());
    } else {
        activeUnitCodes = unitCodes.filter(unitCode => unitCode.status.toLocaleLowerCase() === UnitCodeStatus.active.toLocaleLowerCase()
            || moment(unitCode.date_inactif || '1900-01-01').format('YYYYMMDD') > date);
    }
    return activeUnitCodes;
}
