import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { TableCodeUnite } from '../../models/table-code-unite.model';
import { TableCodesUniteAction, TableCodesUniteActionType } from '../actions';

export interface TableCodeUniteState extends EntityState<TableCodeUnite> {
    loading: boolean;
    loaded: boolean;
}

export const tableCodeUniteAdapter: EntityAdapter<TableCodeUnite> = createEntityAdapter<TableCodeUnite>({
    selectId: (tableCodeUnite: TableCodeUnite) => tableCodeUnite.id,
});

export const tableCodeUniteInitialState = tableCodeUniteAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function tableCodeUniteReducer(
    state: TableCodeUniteState = tableCodeUniteInitialState,
    action: TableCodesUniteAction,
): TableCodeUniteState {
    switch (action.type) {
        case TableCodesUniteActionType.UPDATE_ONE_TABLE_CODES_UNITE:
        case TableCodesUniteActionType.CREATE_ONE_TABLE_CODES_UNITE:
        case TableCodesUniteActionType.LOAD_TABLE_CODES_UNITE: {
            return {
                ...state,
                loading: true,
            };
        }
        case TableCodesUniteActionType.UPDATE_ONE_TABLE_CODES_UNITE_SUCCESS: {
            return tableCodeUniteAdapter.upsertOne(action.tableCodeUnite, {
                ...state,
                loading: false,
            });
        }

        case TableCodesUniteActionType.UPDATE_ALL_TABLE_CODES_UNITE_SUCCESS:
        case TableCodesUniteActionType.CREATE_ONE_TABLE_CODES_UNITE_SUCCESS:
        case TableCodesUniteActionType.LOAD_TABLE_CODES_UNITE_SUCCESS: {
            return tableCodeUniteAdapter.setAll(action.tableCodeUnites, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case TableCodesUniteActionType.LOAD_TABLE_CODES_UNITE_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case TableCodesUniteActionType.UPDATE_ONE_TABLE_CODES_UNITE_FAIL:
        case TableCodesUniteActionType.CREATE_ONE_TABLE_CODES_UNITE_FAIL:
        case TableCodesUniteActionType.LOAD_TABLE_CODES_UNITE_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
