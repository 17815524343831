<div mat-dialog-title class="dialog__title">
    <div class="title__text">
        <i class="fa fa-comment-alt title__logo"></i>
        <span>
            {{ 'shared.commentForm.title' | translate }}
        </span>
    </div>
</div>

<mat-dialog-content>
    <form [formGroup]="commentForm" id="comment-form" class="comment-form">
        <div *ngIf="displayWeekDays" class="comment-form__container" formGroupName="checkBoxGroup">
            <label class="container__instructions">{{'shared.commentForm.instruction-weekdays' | translate}}<span class="required">*</span></label>
            <div class="container__weekdays">
                <div class="weekdays__day" *ngFor="let day of weekDays; let i = index">
                    <label class="day__label" [class.label-disabled]="this.commentForm.controls['checkBoxGroup']['controls']['day'+i].disabled">
                        {{'shared.commentForm.weekdays.' + day + '.l' | translate}}
                    </label>
                    <mat-checkbox formControlName="{{'day'+i}}" class="day__checkbox"></mat-checkbox>
                </div>
            </div>
        </div>
        <label *ngIf="displayWeekDays" class="comment-form__instructions">{{'shared.commentForm.instruction-comment' | translate}}<span class="required">*</span></label>
        <div class="group__field" [class.paddingTop]="!displayWeekDays">
            <div class="field__component">
                <textarea #comment class="component__textarea" rows="5" cols="30" pInputTextarea [autoResize]="true"
                    [placeholder]="'shared.commentForm.comment-placeholder' | translate"
                    formControlName="comment" maxlength="{{maxLength}}">
                </textarea>
            </div>
            <div *ngIf="maxLength !== undefined && maxLength !== null" class="field__hint">
                <mat-hint [align]="'end'"> {{comment.value.length}} / {{maxLength}}</mat-hint>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="button">
        <div>
            <button pButton
                type="button"
                icon="fa fa-times"
                class="p-button-secondary"
                [label]="'shared.commentForm.cancel' | translate"
                [disabled]="false"
                (click)="onClose()">
            </button>
        </div>

        <div>
            <button pButton icon="fa fa-check" (click)="onSubmit()" class="p-button-primary"
                [disabled]="commentForm.invalid" [label]="'shared.commentForm.send' | translate"></button>
        </div>
    </div>
</mat-dialog-actions>
