import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { AddOneAppNotification, AppNotificationActionTypes } from '../actions/app-notifications.actions';

@Injectable()
export class AppNotificationEffects {
    constructor(
        private actions$: Actions,
        private toastService: ToastrService,
        private translateService: TranslateService
    ) { }

    public appNotification$ = createEffect(() => this.actions$.pipe(
        ofType<AddOneAppNotification>(AppNotificationActionTypes.ADD_ONE),
        tap(
            ({
                payload: { summary, detail, severity: type, arguments: args }
            }: AddOneAppNotification) => {
                this.toastService[type](
                    this.translateService.instant(detail || '', args),
                    this.translateService.instant(summary, args),
                    {
                        progressBar: true,
                        closeButton: true
                    }
                );
            }
        ),
    ),
        { dispatch: false });
}
