import { Component, EventEmitter, Input, Output } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-modal-question',
    templateUrl: './modal-question.component.html',
    styleUrls: ['./modal-question.component.scss'],
})
export class ModalQuestionComponent {
    @Input() public message: string;
    @Input() public displayDeleteButton?: boolean;

    @Output() public save: EventEmitter<string> = new EventEmitter();
    @Output() public cancel: EventEmitter<string> = new EventEmitter();

    constructor() { }

    public onSave(): void {
        this.save.emit('save');
    }

    public onCancel(): void {
        this.cancel.emit('cancel');
    }
}
