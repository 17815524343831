import { Alignment, Cell, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';

export class ExcelHelpers {

    public static addNewWorksheet(workbook: Workbook, worksheetName: string, columnsWidth: number[]): Worksheet {
        const worksheet = workbook.addWorksheet(worksheetName);

        worksheet.columns = columnsWidth.map(w => {
            return { width: w };
        });

        return worksheet;
    }

    public static selectRange(worksheet: Worksheet, range: string): Cell[] {
        const [startCell, endCell] = range.split(':', 2);

        const startColumn = startCell.replace(/[^a-z]/gi, '');
        // Note : This code do not support over 'Z' Column to find Column Number
        const startColumnNumber = startColumn.charCodeAt(0) - 'A'.charCodeAt(0) + 1;
        const startRow = parseInt(startCell.replace(startColumn, ''), 10);

        const endColumn = endCell.replace(/[^a-z]/gi, '');
        // Note : This code do not support over 'Z' Column to find Column Number
        const endColumnNumber = endColumn.charCodeAt(0) - 'A'.charCodeAt(0) + 1;
        const endRow = parseInt(endCell.replace(endColumn, ''), 10);

        const cells: Cell[] = [];
        for (let y = startRow; y <= endRow; y++) {
            const row = worksheet.getRow(y);

            for (let x = startColumnNumber; x <= endColumnNumber; x++) {
                cells.push(row.getCell(x));
            }
        }

        return cells;
    }

    public static setCellsBorder(worksheet: Worksheet, rangeCells: string): void {
        this.selectRange(worksheet, rangeCells).forEach(cell => {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });
    }

    public static setCellsBold(worksheet: Worksheet, range: string): void {
        this.selectRange(worksheet, range).forEach(cell => {
            cell.font = { bold: true };
        });
    }

    public static setCellsBackGroudColor(worksheet: Worksheet, range: string, color: string): void {
        this.selectRange(worksheet, range).forEach(cell => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: color }
            };
        });
    }

    public static setCellsNumberFormat(worksheet: Worksheet, range: string, numberFormat: string): void {
        this.selectRange(worksheet, range).forEach(cell => {
            cell.numFmt = numberFormat;
        });
    }

    public static setCellsAlignment(worksheet: Worksheet, range: string, alignment: Partial<Alignment>): void {
        this.selectRange(worksheet, range).forEach(cell => {
            cell.alignment = alignment;
        });
    }

    public static groupRows(worksheet: Worksheet, startingRow: number, endingRow: number, firstGroupingColumnLetter: string, lastGroupingColumnLetter: string): void {
        let previousValue: any;
        let firstGroupRow = 0;
        for (let i = startingRow; i <= endingRow; i++) {
            const cellValue = worksheet.getCell(firstGroupingColumnLetter + i.toString()).value?.toString();

            if (firstGroupRow === 0) {
                firstGroupRow = i;
                previousValue = cellValue;
            }
            if (previousValue !== cellValue) {
                if (firstGroupRow !== (i - 1)) {
                    worksheet.mergeCells(firstGroupingColumnLetter + firstGroupRow.toString() + ':' + firstGroupingColumnLetter + (i - 1).toString());
                    if (firstGroupingColumnLetter.charCodeAt(0) + 1 <= lastGroupingColumnLetter.charCodeAt(0)) {
                        this.groupRows(worksheet, firstGroupRow, i - 1, String.fromCharCode(firstGroupingColumnLetter.charCodeAt(0) + 1), lastGroupingColumnLetter);
                    }
                }
                firstGroupRow = i;
                previousValue = cellValue;
            }
        }
        if (firstGroupRow !== endingRow) {
            worksheet.mergeCells(firstGroupingColumnLetter + firstGroupRow.toString() + ':' + firstGroupingColumnLetter + endingRow.toString());
            if (firstGroupingColumnLetter.charCodeAt(0) + 1 <= lastGroupingColumnLetter.charCodeAt(0)) {
                this.groupRows(worksheet, firstGroupRow, endingRow, String.fromCharCode(firstGroupingColumnLetter.charCodeAt(0) + 1), lastGroupingColumnLetter);
            }
        }
    }

    public static save(workbook: Workbook, filename: string): void {
        workbook.xlsx.writeBuffer()
            .then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, `${filename}.xlsx`);
            })
            .catch(err => { throw err; });
    }

}
