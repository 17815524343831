import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Store, select } from '@ngrx/store';

import { TimesheetState } from '../../../../store/reducers';
import { UpdateOneOrder } from '../../../../../ogiv-core/store/actions';
import { CreateWorker, CreateEquipment, CreatePostLine } from '../../../../store/actions';

import { GenericFormModalComponent } from '../generic-form-modal/generic-form-modal.component';
import { FormModalData } from '../generic-form-modal/generic-form-modal-data.model';
import { Equipment } from '../../../../../shared/models/database/equipment.model';
import { Worker } from '../../../../../shared/models/database/worker.model';
import { Order, OrderRawData } from '../../../../../shared/models/database/order.model';
import { OrderType } from '../../../../../shared/models/database/order-type.enum';
import { PostLineGroup } from '../../../../../shared/models/database/post-line-group.model';
import { PostLine } from '../../../../../shared/models/database/post-line.model';
import { Unit } from '../../../../../shared/models/database/unit.model';
import { Observable } from 'rxjs';
import { getEquipmentsLoading, getPostLinesLoading, getWorkersLoading } from '../../../../store/selectors';
import { UserRole } from '../../../../../shared/models/user-roles.model';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { CurrentUser, UserService } from '../../../../../core/services/user.service';

@Component({
    selector: 'app-tab-content',
    templateUrl: './tab-content.component.html',
    styleUrls: ['./tab-content.component.scss'],
})
export class TabContentComponent implements OnChanges {
    public isLock = false;
    public orderId: number;
    public orderType: OrderType;
    public totalTeamMate = 0;
    public totalTeamMateHours = 0;
    public workerList: Worker[] = [];
    public totalEquipment = 0;
    public totalEquipmentHours = 0;
    public equipmentList: Equipment[] = [];
    public totalUnitOfWork = 0;
    public totalUnitOfWorkHours = 0;
    public postLineGroupsList: PostLineGroup[] = [];
    public remark = '';

    @Input() public order: Order;
    @Input() public requiredSubtitle = false;
    @Input() public canAddEditTime = true;
    @Input() public timesheetDateId = '';
    @Input() public editAllowedRoles: UserRole[] = [];
    @Input() public pathChoice: AlternativePath;

    public workersLoading$: Observable<boolean> = this.store.pipe(
        select(getWorkersLoading),
    );

    public equipmentsLoading$: Observable<boolean> = this.store.pipe(
        select(getEquipmentsLoading),
    );

    public postLinesLoading$: Observable<boolean> = this.store.pipe(
        select(getPostLinesLoading),
    );

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<TimesheetState>,
        private userService: UserService,
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.order && changes.order.currentValue) {
            const order = changes.order.currentValue;
            this.orderId = order.id;
            this.orderType = order.billingType;

            if (order.isLock) {
                this.isLock = true;
            }

            if (order && order.workers) {
                this.totalTeamMate = order.workers.length;
                this.totalTeamMateHours = order.workers.reduce((acc: number, worker: Worker) => acc + (worker.hour || 0), 0);
                this.workerList = order.workers;
            }

            if (order && order.equipments) {
                this.totalEquipment = order.equipments.length;
                this.totalEquipmentHours = order.equipments.reduce((acc: number, equipment: Equipment) => acc + (equipment.hour || 0), 0);
                this.equipmentList = order.equipments;
            }

            if (order && order.postLineGroups) {
                this.totalUnitOfWork = order.postLineGroups.length;
                this.postLineGroupsList = order.postLineGroups;
                this.totalUnitOfWorkHours = this.calculHoursOfWorkDone(order.postLineGroups);
            }

            this.remark = (order && order.remark) ? order.remark : '';
        }
    }

    public updateRemark(remark: string): void {
        const { equipments, workers, postLineGroups, ...cleanOrder } = this.order;
        const order = {
            ...cleanOrder,
            // timesheetReportId: 1, // TODO : remove the hardcoded value 1
            remark: remark,
        } as OrderRawData;
        let imperson: CurrentUser | undefined;
        if (this.userService.currentUser.isImpersonnified) {
            imperson = this.userService.currentUser;
        }
        this.store.dispatch(new UpdateOneOrder(new Order(order), this.timesheetDateId, this.pathChoice, imperson));
    }

    public calculHoursOfWorkDone(postLineGroups: PostLineGroup[]): number {
        let number = 0;
        postLineGroups.forEach((postlineGroup: PostLineGroup) => {
            if (postlineGroup.postLines && postlineGroup.postLines.length > 0) {
                postlineGroup.postLines.forEach((postLine: PostLine) => {
                    if (postLine && postLine.units && postLine.units.length > 0) {
                        postLine.units.forEach((unit: Unit) => {
                            number += unit.hour;
                        });
                    }
                });
            }
        });
        return number;
    }

    public addTeam(event: any): void {
        event.stopPropagation();
        const addTeamDialog = this.dialog.open(GenericFormModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                icon: 'fas fa-plus',
                formName: 'teamMate',
                translatePrefix: 'timesheet.order.tab.accordionTitle.team.form',
                canAddEditTime: this.canAddEditTime,
                timesheetDateId: this.timesheetDateId,
                parentId: this.orderId,
                pathChoice: this.pathChoice,
            } as FormModalData,
        });

        addTeamDialog.afterClosed().subscribe((result: Worker) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }
                this.store.dispatch(new CreateWorker(result as Worker, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }

    public addEquipment(event: any): void {
        event.stopPropagation();
        const addEquipmentDialog = this.dialog.open(GenericFormModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                icon: 'fas fa-plus',
                formName: 'equipment',
                translatePrefix: 'timesheet.order.tab.accordionTitle.equipment.form',
                canAddEditTime: this.canAddEditTime,
                timesheetDateId: this.timesheetDateId,
                parentId: this.orderId,
                pathChoice: this.pathChoice,
            } as FormModalData,
        });

        addEquipmentDialog.afterClosed().subscribe((result: Equipment) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }
                this.store.dispatch(new CreateEquipment(result as Equipment, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }

    public addPostLine(event: any): void {
        event.stopPropagation();
        const addPostLineDialog = this.dialog.open(GenericFormModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                icon: 'fas fa-plus',
                formName: 'postLine',
                translatePrefix: 'timesheet.order.tab.accordionTitle.unitOfWorkDone.postLineForm',
                parentId: this.orderId,
                specificDetail: {
                    orderType: this.orderType,
                },
                pathChoice: this.pathChoice,
            } as FormModalData,
        });

        addPostLineDialog.afterClosed().subscribe((result: PostLine) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }
                this.store.dispatch(new CreatePostLine(result, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }
}
