<!-- <app-element-loader *ngIf="timesheetReportLoading$ | async" [size]="100"></app-element-loader> -->
<div >
    <app-element-loader *ngIf="timesheetReportLoading$ | async" [size]="100" [displayLabel]="true" [position]="'fixed'"></app-element-loader>

    <div class="timesheet-report" *ngIf="timesheetReport$ | async as timesheetReport">
        <app-generic-detail-page-header
            [pageLogo]="'far fa-file-alt'"
            [pageTitle]="'timesheet.reports.detail.' + timesheetReport.billingType + '.title' | translate"
            (back)="backToReport()">
        </app-generic-detail-page-header>

        <div class="timesheet-report__header">
            <div class="header__col">
                <p>
                    {{ 'timesheet.reports.detail.reportNumber' | translate }}:
                    <strong>{{ timesheetReport.number }}</strong>
                </p>
                <p>
                    {{ 'timesheet.reports.detail.order' | translate }}:
                    <strong>{{ timesheetReport.order }}</strong>
                </p>
                <p>
                    {{ 'timesheet.reports.detail.accountingEntry' | translate }}:
                    <strong>{{ timesheetReport.accountingEntry }}</strong>
                </p>
                <p *ngIf="isHq()">
                    {{ 'timesheet.reports.detail.site' | translate }}:
                    <strong>{{ timesheetReport.site === null ? '' : timesheetReport.site }}</strong>
                </p>
            </div>
            <div class="header__col">
                <p>
                    {{ 'timesheet.reports.detail.contractor' | translate }}:
                    <strong>{{ timesheetReport.company }}</strong>
                </p>
                <p>
                    {{ 'timesheet.reports.detail.week' | translate }}:
                    <strong>{{ timesheetReport.week | date:'dd-MM-yyyy' }}</strong>
                </p>
                <p>
                    <app-tag [status]="timesheetReport.status">
                        {{ 'timesheet.reports.status.' + timesheetReport.status | translate }}
                    </app-tag>
                </p>
            </div>
        </div>

        <app-error-table *ngIf="timesheetError.length > 0" [errors]="timesheetError"></app-error-table>

        <div class="timesheet-report__actions">
            <button pButton icon="fa fa-check" (click)="approveTimesheetReport(timesheetReport)"
                class="p-button-rounded p-button-success button__approve" [label]="translatePrefix + '.approve' | translate"
                [disabled]="disableApproveButton(timesheetReport)"></button>

            <button pButton icon="fa fa-times" (click)="refuseTimesheetReport(timesheetReport)"
                [disabled]="disableRefuseButton(timesheetReport)"
                class="p-button-rounded p-button-danger button__refuse" [label]="translatePrefix + '.refuse' | translate"></button>
        </div>

        <div class="timesheet-report__team">
            <h3><i class="fas fa-users logo"></i>{{ 'timesheet.reports.detail.' + timesheetReport.billingType + '.team.title' | translate }}</h3>

            <app-timesheet-report-part-table
                [translatePrefix]="translatePrefix + '.' + timesheetReport.billingType + teamTranslatePrefix"
                [cols]="teamCols"
                [reportPartData]="timesheetReport.team!"
                [showTotal]="true"
                [billingType]="timesheetReport.billingType"
                [hideColumnCode]=true>
            </app-timesheet-report-part-table>
        </div>

        <div class="timesheet-report__transport">
            <h3><i class="fas fa-car logo"></i>{{ 'timesheet.reports.detail.' + timesheetReport.billingType + '.transport.title' | translate }}</h3>

            <app-timesheet-report-part-table
                [translatePrefix]="translatePrefix + '.' + timesheetReport.billingType + transportTranslatePrefix"
                [cols]="transportCols"
                [reportPartData]="timesheetReport.transport!"
                [alignTotalColumn]="true"
                [showTotal]="false"
                [billingType]="timesheetReport.billingType">
            </app-timesheet-report-part-table>
        </div>

        <div class="timesheet-report__meal">
            <h3><i class="fas fa-bread-slice logo logo__meal"></i>{{ 'timesheet.reports.detail.' + timesheetReport.billingType + '.meal.title' | translate }}</h3>

            <app-timesheet-report-part-table
                [translatePrefix]="translatePrefix + '.' + timesheetReport.billingType + mealTranslatePrefix"
                [cols]="mealCols"
                [reportPartData]="timesheetReport.meal!"
                [alignTotalColumn]="true"
                [showTotal]="false"
                [billingType]="timesheetReport.billingType">
            </app-timesheet-report-part-table>
        </div>

        <div class="timesheet-report__equipment">
            <h3><i class="fas fa-toolbox logo"></i>{{ 'timesheet.reports.detail.' + timesheetReport.billingType + '.equipment.title' | translate }}</h3>

            <app-timesheet-report-part-table
                [translatePrefix]="translatePrefix + '.' + timesheetReport.billingType + equipmentTranslatePrefix"
                [cols]="equipmentCols"
                [reportPartData]="timesheetReport.equipment!"
                [showTotal]="true"
                [billingType]="timesheetReport.billingType"
                [hideColumnCode]=true>
            </app-timesheet-report-part-table>
        </div>

        <div class="timesheet-report__work-unit">
            <h3><i class="fas fa-clipboard-check logo"></i>{{ 'timesheet.reports.detail.' + timesheetReport.billingType + '.workUnit.title' | translate }}</h3>

            <app-timesheet-report-part-table
                [translatePrefix]="translatePrefix + '.' + timesheetReport.billingType + workUnitTranslatePrefix"
                [cols]="workUnitCols"
                [reportPartData]="timesheetReport.workUnit!"
                [multipleColumnTotals]="true"
                [alignTotalColumn]="false"
                [showTotal]="false"
                [disableSort]="true"
                [billingType]="timesheetReport.billingType">
            </app-timesheet-report-part-table>
        </div>

        <div class="timesheet-report__timesheetRemark">
            <app-timesheet-remark [remarks]="timesheetReport.remarksTimeSheets">
            </app-timesheet-remark>
        </div>

        <div class="timesheet-report__remark">
            <app-text-area-crud
                [fontLogo]="'fas fa-exclamation'"
                [title]="'timesheet.reports.detail.remark.title'"
                [value]="timesheetReport.remark || ''"
                (save)="onRemarkSaved($event, timesheetReport.id)"
                [expliciteDisable]="pathChoice !== undefined && pathChoice !== null && pathChoice === 'hq'"
                [disableByStatus]="disableApproveButton(timesheetReport) && disableRefuseButton(timesheetReport)">
            </app-text-area-crud>
        </div>

        <div class="timesheet-report__footer">
            <button pButton icon="fa fa-check" (click)="approveTimesheetReport(timesheetReport)"
                class="p-button-rounded p-button-success button__approve" [label]="translatePrefix + '.approve' | translate"
                [disabled]="disableApproveButton(timesheetReport)"></button>

            <button pButton icon="fa fa-times" (click)="refuseTimesheetReport(timesheetReport)"
                [disabled]="disableRefuseButton(timesheetReport)"
                class="p-button-rounded p-button-danger button__refuse" [label]="translatePrefix + '.refuse' | translate"></button>
        </div>
    </div>
</div>
