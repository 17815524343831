import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { Arborist } from '../../../shared/models/database/arborist.model';

/**
 * Types
 */
export enum ArboristActionType {
    LOAD_ARBORISTS = '[Arborist] Load Arborists',
    LOAD_ARBORISTS_SUCCESS = '[Arborist] Load Arborists Success',
    LOAD_ARBORISTS_FAIL = '[Arborist] Load Arborists Fail',
    LOAD_ARBORISTS_NO_RESULTS = '[Arborist] Load Arborists No Result',

    CREATE_ARBORIST = '[Arborist] Create Arborist',
    CREATE_ARBORIST_SUCCESS = '[Arborist] Create Arborist Success',
    CREATE_ARBORIST_FAIL = '[Arborist] Create Arborist Fail',

    UPDATE_ONE_ARBORIST = '[Arborist] Update One Arborist',
    UPDATE_ONE_ARBORIST_SUCCESS = '[Arborist] Update One Arborist Success',
    UPDATE_ONE_ARBORIST_FAIL = '[Arborist] Update One Arborist Fail',
}

/**
 * ACTIONS
 */
/**
 * CREATE
 */
export class CreateArborist implements Action {
    readonly type = ArboristActionType.CREATE_ARBORIST;
    constructor(public arborist: Arborist, public path: AlternativePath) { }
}

export class CreateArboristSuccess implements Action {
    readonly type = ArboristActionType.CREATE_ARBORIST_SUCCESS;
    constructor(public arborist: Arborist) { }
}

export class CreateArboristFail implements Action {
    readonly type = ArboristActionType.CREATE_ARBORIST_FAIL;
    constructor(public error: any) { }
}

/**
 * LOAD
 */
export class LoadArborists implements Action {
    readonly type = ArboristActionType.LOAD_ARBORISTS;
    constructor(public path: AlternativePath) { }
}

export class LoadArboristsNoResult implements Action {
    readonly type = ArboristActionType.LOAD_ARBORISTS_NO_RESULTS;
}

export class LoadArboristsSuccess implements Action {
    readonly type = ArboristActionType.LOAD_ARBORISTS_SUCCESS;
    constructor(public arborists: Arborist[]) { }
}

export class LoadArboristsFail implements Action {
    readonly type = ArboristActionType.LOAD_ARBORISTS_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneArborist implements Action {
    readonly type = ArboristActionType.UPDATE_ONE_ARBORIST;
    constructor(public arborist: Arborist, public path: AlternativePath) { }
}

export class UpdateOneArboristSuccess implements Action {
    readonly type = ArboristActionType.UPDATE_ONE_ARBORIST_SUCCESS;
    constructor(public arborist: Arborist) { }
}

export class UpdateOneArboristFail implements Action {
    readonly type = ArboristActionType.UPDATE_ONE_ARBORIST_FAIL;
    constructor(public error: any) { }
}

export type ArboristAction =
    | CreateArborist
    | CreateArboristSuccess
    | CreateArboristFail
    | LoadArborists
    | LoadArboristsNoResult
    | LoadArboristsSuccess
    | LoadArboristsFail
    | UpdateOneArborist
    | UpdateOneArboristSuccess
    | UpdateOneArboristFail;
