import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import moment from 'moment';

import { Order } from '../../../../../shared/models/database/order.model';
import { Timesheet } from '../../../../../shared/models/database/timesheet.model';
import { FormModalData } from '../generic-form-modal/generic-form-modal-data.model';
import { GenericFormModalComponent } from '../generic-form-modal/generic-form-modal.component';
import { getCurrentTimesheet, /*getTimesheetsLoading, */getTimesheetsLoaded } from '../../../../store/selectors';
import { CreateOrder } from '../../../../../ogiv-core/store/actions/orders.action';
import { UserRole } from '../../../../../shared/models/user-roles.model';
import { getRouterData } from '../../../../../core/store/selectors';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { OrderGroup } from '../../../../../shared/models/database/order-group.model';
import { CurrentUser, UserService } from '../../../../../core/services/user.service';

@Component({
    selector: 'app-timesheet-details',
    templateUrl: './timesheet-details.component.html',
    styleUrls: ['./timesheet-details.component.scss']
})
export class TimesheetDetailsComponent implements OnInit {
    private currentTimesheetId: number;
    private currentTimesheetDateId: string;

    public currentTimesheetOrderGroup: OrderGroup[] | undefined;
    public editAllowedRoles: UserRole[] = [UserRole.chefequipe, UserRole.chefgroupe, UserRole.entrepreneur, UserRole.support, UserRole.pilote];
    public pathChoice = AlternativePath.ent;

    public timesheet$: Observable<Timesheet | null> = this.store.pipe(
        select(getCurrentTimesheet),
        tap((timesheet: Timesheet) => {
            if (timesheet) {
                this.currentTimesheetId = timesheet.id;
                this.currentTimesheetDateId = moment(timesheet.date).format('YYYYMMDD');
                this.currentTimesheetOrderGroup = timesheet.orderGroups;
            }
        }),
    );
    // public timesheetLoading$: Observable<boolean> = this.store.pipe(
    //     select(getTimesheetsLoading),
    // );
    public timesheetLoaded$: Observable<boolean> = this.store.pipe(
        select(getTimesheetsLoaded),
    );

    constructor(
        public dialog: MatDialog,
        private readonly store: Store,
        private userService: UserService,
    ) { }

    public ngOnInit() {
        this.store.pipe(select(getRouterData)).subscribe((data: any) => {
            this.pathChoice = data && data.pathChoice || AlternativePath.ent;
        });
    }

    public displayOrders(timesheet: Timesheet): boolean {
        return (timesheet.orderGroups && timesheet.orderGroups.length > 0) ? true : false;
    }

    public createOrder(): void {
        const createOrderDialog = this.dialog.open(GenericFormModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                icon: 'fas fa-plus',
                formName: 'order',
                translatePrefix: 'timesheet.order.form',
                parentId: this.currentTimesheetId,
                pathChoice: this.pathChoice,
            } as FormModalData,
        });

        createOrderDialog.afterClosed().subscribe((result: Order) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }

                this.store.dispatch(new CreateOrder(result, this.currentTimesheetDateId, this.pathChoice, imperson));
            }
        });
    }

    public itContainOrders(): boolean {
        return this.currentTimesheetOrderGroup !== undefined && this.currentTimesheetOrderGroup.length > 0;
    }
}
