<app-element-loader *ngIf="workersLoading$ | async" [size]="50"></app-element-loader>
<mat-list>
    <mat-list-item *ngFor="let worker of workerList; trackBy:jsTeamMateTrackById; let teamMateIndex = index">
        <div class="user">
            <div class="user__info" (click)="editRecord(worker)" (mouseover)="mouseOver(teamMateIndex)" (mouseout)="mouseOut()" [class.hover]="teamMateIndex === teamMateOverIndex">
                <i class="fas fa-user info__logo" [class.isLock]="isLock"></i>
                <div class="info__info">
                    <div class="info__teamMate">
                        <div class="teamMate__name" [class.isLock]="isLock">({{worker.jobClassCode}}) {{worker.firstName}} {{worker.lastName}}</div>
                        <div class="teamMate__description" [class.isLock]="isLock" *ngIf="(worker.jobSAPCode !== null && worker.jobSAPCode !== '') ||
                        (worker.jobSAPDescr !== null && worker.jobSAPDescr !== '')">({{worker.jobSAPCode}}) {{worker.jobSAPDescr}}</div>
                    </div>
                    <div class="info__more" [class.isLock]="isLock">
                        <div *ngIf="!isEmptyTransport(worker)" class="more__transport">
                            <div class="transport__logo"[class.isLock]="isLock">
                                <i class="fas fa-car"></i>
                            </div>
                            <div [class.isLock]="isLock">
                                {{worker.transportationQuantity}}
                            </div>
                        </div>
                        <div *ngIf="!isEmptyMeal(worker)" class="more__lunch">
                            <div class="lunch__logo" [class.isLock]="isLock">
                                <i class="fas fa-bread-slice"></i>
                            </div>
                            <div [class.isLock]="isLock">
                                {{worker.mealQuantity}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="canAddEditTime" class="info__time">
                        <div class="time__logo" [class.isLock]="isLock">
                            <i class="far fa-clock"></i>
                        </div>
                        <div class="time__hour" [class.isLock]="isLock">
                            {{worker.hour}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="user__action" *hasRole="editAllowedRoles">
                <div class="action__menu">
                    <app-generic-other-menu class="menu__other"
                        [editOnly]="teamMateIndex === teamMateOverIndex"
                        [hoverButtonOrMenu]="teamMateIndex === teamMateOverIndex"
                        [addborder]="true"
                        [displayMenu]="teamMateIndex !== teamMateOverIndex"
                        [hideItems]="['add']"
                        [isLock]="isLock"
                        (edit)="editRecord(worker)"
                        (delete)="deleteRecord(worker)">
                    </app-generic-other-menu>
                </div>
            </div>
        </div>
    </mat-list-item>
</mat-list>
