import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { TimesheetChargeNumber } from '../../models/timesheet-charge-number.model';
import { TimesheetChargeNumberAction, TimesheetsChargeNumberActionType } from '../actions';

export interface TimesheetChargeNumberState extends EntityState<TimesheetChargeNumber> {
    loading: boolean;
    loaded: boolean;
}

export const timesheetChargeNumberAdapter: EntityAdapter<TimesheetChargeNumber> = createEntityAdapter<TimesheetChargeNumber>({
    selectId: (timesheetChargeNumber: TimesheetChargeNumber) => JSON.stringify(timesheetChargeNumber)
});

export const timesheetChargeNumberInitialState: TimesheetChargeNumberState = timesheetChargeNumberAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function timesheetChargeNumberReducer(
    state: TimesheetChargeNumberState = timesheetChargeNumberInitialState,
    action: TimesheetChargeNumberAction,
): TimesheetChargeNumberState {
    switch (action.type) {
        case TimesheetsChargeNumberActionType.UPDATE_ONE_TIMESHEETS_CHARGE_NUMBER:
        case TimesheetsChargeNumberActionType.LOAD_TIMESHEETS_CHARGE_NUMBER: {
            return {
                ...state,
                loading: true,
            };
        }
        case TimesheetsChargeNumberActionType.UPDATE_ONE_TIMESHEETS_CHARGE_NUMBER_SUCCESS: {
            return timesheetChargeNumberAdapter.upsertOne(action.timesheetChargeNumber, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case TimesheetsChargeNumberActionType.UPDATE_ONE_TIMESHEETS_CHARGE_NUMBER_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case TimesheetsChargeNumberActionType.UPDATE_ALL_TIMESHEETS_CHARGE_NUMBER_SUCCESS:
        case TimesheetsChargeNumberActionType.LOAD_TIMESHEETS_CHARGE_NUMBER_SUCCESS: {
            return timesheetChargeNumberAdapter.setAll(action.timesheetsChargeNumber, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case TimesheetsChargeNumberActionType.LOAD_TIMESHEETS_CHARGE_NUMBER_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
