import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Store, select } from '@ngrx/store';

import { FormModalData } from '../generic-form-modal/generic-form-modal-data.model';
import { GenericFormModalComponent } from '../generic-form-modal/generic-form-modal.component';
import { ModalData, ModalType, ModalEntityType } from '../generic-modal/generic-modal-data.model';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import { Equipment } from '../../../../../shared/models/database/equipment.model';
import { UpdateOneEquipment, DeleteOneEquipment } from '../../../../store/actions';
import { TimesheetState } from '../../../../store/reducers';
import { Observable } from 'rxjs';
import { getEquipmentsLoading } from '../../../../store/selectors';
import { UserRole } from '../../../../../shared/models/user-roles.model';
import { getUserRoles } from '../../../../../core/store/selectors';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { CurrentUser, UserService } from '../../../../../core/services/user.service';

@Component({
    selector: 'app-list-equipment',
    templateUrl: './equipment-list.component.html',
    styleUrls: ['./equipment-list.component.scss']
})
export class EquipmentListComponent {
    public currentUserRoles: UserRole[] = [];
    public equipmentOverIndex: number | null = null;

    @Input() public equipmentList: Equipment[] = [];
    @Input() public canAddEditTime = true;
    @Input() public orderId: number | null = null;
    @Input() public timesheetDateId = '';
    @Input() public isLock = false;
    @Input() public editAllowedRoles: UserRole[] = [];
    @Input() public pathChoice: AlternativePath;

    public equipmentsLoading$: Observable<boolean> = this.store.pipe(
        select(getEquipmentsLoading),
    );

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<TimesheetState>,
        private userService: UserService,
    ) {
        this.store.pipe(
            select(getUserRoles),
        ).subscribe((roles: UserRole[]) => {
            this.currentUserRoles = roles;
        });
    }

    public equipmentTrackById(_: number, equipment: Equipment): number {
        return equipment.id;
    }

    public isRoleDisabled(): boolean {
        return !!this.editAllowedRoles.length &&
            this.currentUserRoles.every(role => !this.editAllowedRoles.includes(role));
    }

    public editRecord(equipment: Equipment): void {
        if (!this.isLock && !this.isRoleDisabled()) {
            const equipmentDialog = this.dialog.open(GenericFormModalComponent, {
                width: '80%',
                maxWidth: '450px',
                data: {
                    icon: 'fas fa-pen',
                    entity: equipment,
                    titleKey: 'description',
                    formName: 'equipment',
                    canAddEditTime: this.canAddEditTime,
                    timesheetDateId: this.timesheetDateId,
                    parentId: this.orderId,
                    pathChoice: this.pathChoice,
                } as FormModalData
            });

            equipmentDialog.afterClosed().subscribe((result: Equipment) => {
                if (result) {
                    let imperson: CurrentUser | undefined;
                    if (this.userService.currentUser.isImpersonnified) {
                        imperson = this.userService.currentUser;
                    }

                    this.store.dispatch(new UpdateOneEquipment(result as Equipment, this.timesheetDateId, this.pathChoice, imperson));
                }
            });
        }
    }

    public deleteRecord(equipment: Equipment): void {
        const deleteEquipDialog = this.dialog.open(GenericModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                formType: ModalType.delete,
                title: 'timesheet.order.tab.accordionTitle.equipment.deleteForm.title',
                message: 'timesheet.order.tab.accordionTitle.equipment.deleteForm.message',
                entityType: ModalEntityType.equipment,
                entity: equipment,
                canAddEditTime: this.canAddEditTime,
            } as ModalData
        });

        deleteEquipDialog.afterClosed().subscribe((result: boolean) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }

                this.store.dispatch(new DeleteOneEquipment(equipment, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }

    public mouseOver(index: number): void {
        this.equipmentOverIndex = index;
    }

    public mouseOut(): void {
        this.equipmentOverIndex = null;
    }
}
