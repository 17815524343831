import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { SelectItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadBillingOrders } from '../../../../../billing/store/actions/billing-order.action';
import { BillingState } from '../../../../../billing/store/reducers';
import {
    getBillingOrdersLoading,
    getDistinctBillingOrdersSelectOptions,
    getAccountingEntrySelectOptions
} from '../../../../../billing/store/selectors/billing-order.selector';
import { ProgressReportGlobal } from '../../../../../shared/models/database/progress-report-global.model';
import { LoadProgressReportsGlobal } from '../../../../store/actions/progress-reports-global.actions';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { ReportsState } from '../../../../store/reducers';
import { getAllProgressReportsGlobal, getProgressReportsGlobalDateOfData } from '../../../../store/selectors';
import moment from 'moment';

@Component({
    selector: 'app-progress-reports-global',
    templateUrl: './progress-reports-global.component.html',
    styleUrls: ['./progress-reports-global.component.scss']
})
export class ProgressReportsGlobalComponent implements OnInit, OnDestroy {
    @Input() public pathChoice: AlternativePath;

    public progressReportsGlobal$: Observable<ProgressReportGlobal[]> = this.store.pipe(
        select(getAllProgressReportsGlobal)
    );
    public dateOfData$: Observable<Date | null> = this.store.pipe(
        select(getProgressReportsGlobalDateOfData)
    );
    public areBillingOrdersLoading$: Observable<boolean> = this.store.pipe(
        select(getBillingOrdersLoading),
    );
    public billingOrdersOptions$: Observable<SelectItem[]> = this.store.pipe(
        select(getDistinctBillingOrdersSelectOptions),
    );

    public progressReportsGlobal: ProgressReportGlobal[];
    public dateOfData: string;
    public totalPrescriptionCount: number;
    public accountingEntryOptions$: Observable<SelectItem[]>;
    public globalReportForm: FormGroup;

    private subscriptions = new Subscription;

    constructor(
        public translateService: TranslateService,
        protected readonly store: Store<[ReportsState, BillingState]>,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(this.watchProgressReportsGlobal());
        this.subscriptions.add(this.watchDateOfData());

        this.loadBillingOrders();
        this.addEmptyOptionToDropdown(this.billingOrdersOptions$, 'progressReport.general.dropdownSelectAnOrder');
        this.createGlobalReportForm();

        this.subscriptions.add(this.handleOrderValueChanges());
        this.subscriptions.add(this.handleAccountingEntryValueChanges());
    }

    private watchProgressReportsGlobal(): Subscription {
        return this.progressReportsGlobal$.pipe(
            tap((reports: ProgressReportGlobal[]) => {
                if (reports) {
                    reports.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
                    this.progressReportsGlobal = reports;
                    this.setTotalPrescriptionCount();
                }
            })
        ).subscribe();
    }

    private watchDateOfData(): Subscription {
        return this.dateOfData$.pipe(
            tap((date: Date | null) => {
                this.dateOfData = date ? moment(date).format('YYYY-MM-DD hh:mm A') : '';
            })
        ).subscribe();
    }

    private setTotalPrescriptionCount(): void {
        if (this.progressReportsGlobal && this.progressReportsGlobal.length > 0) {
            this.totalPrescriptionCount = this.progressReportsGlobal.reduce((n, { prescriptionCount }) => n + prescriptionCount, 0);
        } else {
            this.totalPrescriptionCount = 0;
        }
    }

    private loadBillingOrders(): void {
        this.store.dispatch(new LoadBillingOrders(this.pathChoice));
    }

    private addEmptyOptionToDropdown(options: Observable<SelectItem[]>, localisationPath: string): void {
        if (localisationPath === '') {
            return;
        }

        this.subscriptions.add(
            options.subscribe((option: SelectItem[]) => {
                option.unshift({
                    label: this.translateService.instant(localisationPath),
                    value: null
                } as SelectItem);
            }));
    }

    private createGlobalReportForm(): void {
        this.globalReportForm = new FormGroup({
            order: new FormControl(''),
            accountingEntry: new FormControl(''),
        });
    }

    private getAccountingEntrySelectOptions(order: string): void {
        this.accountingEntryOptions$ = this.store.pipe(
            select(getAccountingEntrySelectOptions({ selectedOrder: order }))
        );
        this.addEmptyOptionToDropdown(this.accountingEntryOptions$, 'progressReport.general.dropdownSelectAnAccountingEntry');
        this.globalReportForm.controls.accountingEntry.setValue(this.accountingEntryOptions$[0]);
    }

    private handleOrderValueChanges(): Subscription {
        return this.globalReportForm.controls.order.valueChanges.pipe(
            tap((selectedOrder: string) => {
                this.getAccountingEntrySelectOptions(selectedOrder);
                const selectedAccountingEntry: string = this.globalReportForm.controls.accountingEntry.value ?? '';
                this.getGlobalReportByOrderAndAccountingEntry(selectedOrder, selectedAccountingEntry);
            })
        ).subscribe();
    }

    private handleAccountingEntryValueChanges(): Subscription {
        return this.globalReportForm.controls.accountingEntry.valueChanges.pipe(
            tap((selectedAccountingEntry: string) => {
                const selectedOrder: string = this.globalReportForm.controls.order.value ?? '';
                this.getGlobalReportByOrderAndAccountingEntry(selectedOrder, selectedAccountingEntry);
            })
        ).subscribe();
    }

    private getGlobalReportByOrderAndAccountingEntry(selectedOrder: string, selectedAccountingEntry: string): void {
        const orderHasNoValue = selectedOrder === '' || selectedOrder === null || selectedOrder === undefined;
        const accountingEntryHasNoValue = selectedAccountingEntry === '' || selectedAccountingEntry === null || selectedAccountingEntry === undefined;

        if (selectedOrder && accountingEntryHasNoValue) {
            this.store.dispatch(new LoadProgressReportsGlobal(`?commande=${selectedOrder}`, this.pathChoice));
        } else if (selectedOrder && selectedAccountingEntry) {
            this.store.dispatch(new LoadProgressReportsGlobal(`?commande=${selectedOrder}&posteCommande=${selectedAccountingEntry}`, this.pathChoice));
        } else if (orderHasNoValue && accountingEntryHasNoValue) {
            this.store.dispatch(new LoadProgressReportsGlobal('', this.pathChoice));
        }
    }

    public erase(): void {
        this.globalReportForm.controls.order.setValue(null);
        this.globalReportForm.controls.accountingEntry.setValue(null);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
