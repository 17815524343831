import { Equipment } from '../../../shared/models/database/equipment.model';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { CurrentUser } from '../../../core/services/user.service';
import { Action } from '@ngrx/store';

/**
 * Types
 */

export enum EquipmentActionTypes {
    LOAD_EQUIPMENTS = '[Equipment] Load Equipments',
    LOAD_EQUIPMENTS_SUCCESS = '[Equipment] Load Equipments Success',
    LOAD_EQUIPMENTS_NO_RESULTS = '[Equipment] Load Equipments No Results',
    LOAD_EQUIPMENTS_FAIL = '[Equipment] Load Equipments Fail',

    CREATE_EQUIPMENT = '[Equipment] Create Equipment',
    CREATE_EQUIPMENT_SUCCESS = '[Equipment] Create Equipment Success',
    CREATE_EQUIPMENT_FAIL = '[Equipment] Create Equipment Fail',

    UPDATE_ONE_EQUIPMENT = '[Equipment] Update One Equipment',
    UPDATE_ONE_EQUIPMENT_FAIL = '[Equipment] Update One Equipment Fail',
    UPDATE_ONE_EQUIPMENT_SUCCESS = '[Equipment] Update One Equipment Success',

    DELETE_ONE_EQUIPMENT = '[Equipment] Delete One Equipment',
    DELETE_ONE_EQUIPMENT_FAIL = '[Equipment] Delete One Equipment Fail',
    DELETE_ONE_EQUIPMENT_SUCCESS = '[Equipment] Delete One Equipment Success',
}

/**
 * ACTIONS
 */

/**
 * CREATE
 */
export class CreateEquipment implements Action {
    readonly type = EquipmentActionTypes.CREATE_EQUIPMENT;
    constructor(public equipment: Equipment, public timesheetDateId: string, public path: AlternativePath, public imperson?: CurrentUser) { }
}

export class CreateEquipmentSuccess implements Action {
    readonly type = EquipmentActionTypes.CREATE_EQUIPMENT_SUCCESS;
    // constructor(public equipment: Equipment) { }
}

export class CreateEquipmentFail implements Action {
    readonly type = EquipmentActionTypes.CREATE_EQUIPMENT_FAIL;
    constructor(public error: any) { }
}

/**
 * LOAD
 */
export class LoadEquipments implements Action {
    readonly type = EquipmentActionTypes.LOAD_EQUIPMENTS;
    constructor(public path: AlternativePath) { }
}

export class LoadEquipmentsNoresult implements Action {
    readonly type = EquipmentActionTypes.LOAD_EQUIPMENTS_NO_RESULTS;
}

export class LoadEquipmentsSuccess implements Action {
    readonly type = EquipmentActionTypes.LOAD_EQUIPMENTS_SUCCESS;
    constructor(public equipments: Equipment[]) { }
}

export class LoadEquipmentsFail implements Action {
    readonly type = EquipmentActionTypes.LOAD_EQUIPMENTS_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneEquipment implements Action {
    readonly type = EquipmentActionTypes.UPDATE_ONE_EQUIPMENT;
    constructor(public equipment: Equipment, public timesheetDateId: string, public path: AlternativePath, public imperson?: CurrentUser) { }
}

export class UpdateOneEquipmentSuccess implements Action {
    readonly type = EquipmentActionTypes.UPDATE_ONE_EQUIPMENT_SUCCESS;
    //  constructor(public equipment: Equipment) { }
}

export class UpdateOneEquipmentFail implements Action {
    readonly type = EquipmentActionTypes.UPDATE_ONE_EQUIPMENT_FAIL;
    constructor(public error: any) { }
}

/**
 * DELETE
 */
export class DeleteOneEquipment implements Action {
    readonly type = EquipmentActionTypes.DELETE_ONE_EQUIPMENT;
    constructor(public equipment: Equipment, public timesheetDateId: string, public path: AlternativePath, public imperson?: CurrentUser) { }
}

export class DeleteOneEquipmentSuccess implements Action {
    readonly type = EquipmentActionTypes.DELETE_ONE_EQUIPMENT_SUCCESS;
    // constructor(public equipment: Equipment) { }
}

export class DeleteOneEquipmentFail implements Action {
    readonly type = EquipmentActionTypes.DELETE_ONE_EQUIPMENT_FAIL;
    constructor(public error: any) { }
}

export type EquipmentsAction =
    | CreateEquipment
    | CreateEquipmentSuccess
    | CreateEquipmentFail
    | LoadEquipments
    | LoadEquipmentsNoresult
    | LoadEquipmentsSuccess
    | LoadEquipmentsFail
    | UpdateOneEquipment
    | UpdateOneEquipmentSuccess
    | UpdateOneEquipmentFail
    | DeleteOneEquipment
    | DeleteOneEquipmentSuccess
    | DeleteOneEquipmentFail;
