import { Action } from '@ngrx/store';
import { DemandesClientUtils } from '../../../shared/models/demande-client-utils.model';

export enum DemandesClientUtilsActionTypes {
    LOAD_HOLIDAYS = '[DemandesClientUtils] Load holidays',
    LOAD_HOLIDAYS_FAIL = '[DemandesClientUtils] Load holidays Fail',
    LOAD_HOLIDAYS_SUCCESS = '[DemandesClientUtils] Load holidays Success',
}


export class LoadHolidays implements Action {
    readonly type = DemandesClientUtilsActionTypes.LOAD_HOLIDAYS;
    constructor(public nbJoursOuvrables: number) { }
}

export class LoadHolidaysSuccess implements Action {
    readonly type = DemandesClientUtilsActionTypes.LOAD_HOLIDAYS_SUCCESS;
    constructor(public demandeClientUtils: DemandesClientUtils) { }
}

export class LoadHolidaysFail implements Action {
    readonly type = DemandesClientUtilsActionTypes.LOAD_HOLIDAYS_FAIL;
    constructor(public error: any) { }
}

export type DemandesClientUtilsAction =
    | LoadHolidays
    | LoadHolidaysSuccess
    | LoadHolidaysFail;
