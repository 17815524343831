import { TimesheetChargeNumber, TimesheetChargeNumberSavingFilters, TimesheetChargeNumberSavingValues } from '../../models/timesheet-charge-number.model';
import { TimesheetBasicSaving } from '../../models/timesheet-basic-saving.model';
import { Action } from '@ngrx/store';

/**
 * Types
 */
export enum TimesheetsChargeNumberActionType {
    LOAD_TIMESHEETS_CHARGE_NUMBER = '[TimesheetsChargeNumber] Load',
    LOAD_TIMESHEETS_CHARGE_NUMBER_SUCCESS = '[TimesheetsChargeNumber] Load Success',
    LOAD_TIMESHEETS_CHARGE_NUMBER_FAIL = '[TimesheetsChargeNumber] Load Fail',

    UPDATE_ONE_TIMESHEETS_CHARGE_NUMBER = '[TimesheetsChargeNumber] Update One',
    UPDATE_ONE_TIMESHEETS_CHARGE_NUMBER_SUCCESS = '[TimesheetsChargeNumber] Update One Success',
    UPDATE_ONE_TIMESHEETS_CHARGE_NUMBER_FAIL = '[TimesheetsChargeNumber] Update One Fail',
    UPDATE_ALL_TIMESHEETS_CHARGE_NUMBER_SUCCESS = '[TimesheetsChargeNumber] Update All Success',
}

/**
 *  ACTIONS
 */

/**
 * LOAD
 */

export class LoadTimesheetsChargeNumber implements Action {
    readonly type = TimesheetsChargeNumberActionType.LOAD_TIMESHEETS_CHARGE_NUMBER;
    constructor() { }
}

export class LoadTimesheetsChargeNumberSuccess implements Action {
    readonly type = TimesheetsChargeNumberActionType.LOAD_TIMESHEETS_CHARGE_NUMBER_SUCCESS;
    constructor(public timesheetsChargeNumber: TimesheetChargeNumber[]) { }
}

export class LoadTimesheetsChargeNumberFail implements Action {
    readonly type = TimesheetsChargeNumberActionType.LOAD_TIMESHEETS_CHARGE_NUMBER_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneTimesheetChargeNumber implements Action {
    readonly type = TimesheetsChargeNumberActionType.UPDATE_ONE_TIMESHEETS_CHARGE_NUMBER;
    constructor(public valueToSave: TimesheetBasicSaving<TimesheetChargeNumberSavingValues, TimesheetChargeNumberSavingFilters>) { }
}

export class UpdateOneTimesheetChargeNumberSuccess implements Action {
    readonly type = TimesheetsChargeNumberActionType.UPDATE_ONE_TIMESHEETS_CHARGE_NUMBER_SUCCESS;
    constructor(public timesheetChargeNumber: TimesheetChargeNumber) { }
}

export class UpdateAllTimesheetChargeNumberSuccess implements Action {
    readonly type = TimesheetsChargeNumberActionType.UPDATE_ALL_TIMESHEETS_CHARGE_NUMBER_SUCCESS;
    constructor(public timesheetsChargeNumber: TimesheetChargeNumber[]) { }
}

export class UpdateOneTimesheetChargeNumberFail implements Action {
    readonly type = TimesheetsChargeNumberActionType.UPDATE_ONE_TIMESHEETS_CHARGE_NUMBER_FAIL;
    constructor(public error: any) { }
}

export type TimesheetChargeNumberAction =
    | LoadTimesheetsChargeNumber
    | LoadTimesheetsChargeNumberSuccess
    | LoadTimesheetsChargeNumberFail
    | UpdateOneTimesheetChargeNumber
    | UpdateOneTimesheetChargeNumberSuccess
    | UpdateAllTimesheetChargeNumberSuccess
    | UpdateOneTimesheetChargeNumberFail;
