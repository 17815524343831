export interface EquipmentCode {
    id: number;
    code: string;           // max-length 255
    description: string;    // max-length 255
    status: EquipmentCodeStatus;
    date_inactif?: Date;
    createdBy?: string;       // max-length 255
    createdDate?: Date;
    modifiedBy?: string;       // max-length 255
    modifiedDate?: Date;
}

export enum EquipmentCodeStatus {
    active = 'Actif',
    inactive = 'Inactif',
}
