import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../core/guards/role.guard';
import { LoadPageGuard } from '../guards';
import { PageSelector } from '../shared/models/page-selector.model';
import { UserRole } from '../shared/models/user-roles.model';

const routes: Routes = [
    {
        path: '',
        data: {
            pageSelector: [PageSelector.timesheet],
            roles: [UserRole.support, UserRole.pilote, UserRole.chefequipe, UserRole.chefgroupe, UserRole.entrepreneur],
        },
        loadChildren: () => import('./modules/timesheet/timesheet.module').then(m => m.TimesheetModule),
        canActivate: [LoadPageGuard, RoleGuard]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TimesheetsRoutingModule {
}
