import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CommonUiModule } from '@ngxhq/common-ui';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import { MainMenuComponent } from './main-menu/main-menu.component';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { MatListModule } from '@angular/material/list';

@NgModule({
    imports: [
        CommonModule,
        CommonUiModule,
        SharedModule,
        ButtonModule,
        MatListModule,
        RouterModule,

        // Translate
        TranslateModule,
    ],
    exports: [
        MainMenuComponent,
        HeaderComponent,
    ],
    declarations: [
        MainMenuComponent,
        HeaderComponent,
    ],
    providers: [
    ],
})
export class ShellModule {
}
