import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PostLine } from '../../../../../shared/models/database/post-line.model';
import { OrderType } from '../../../../../shared/models/database/order-type.enum';

@Component({
    selector: 'app-post-line-form',
    templateUrl: './post-line-form.component.html',
    styleUrls: ['./post-line-form.component.scss']
})
export class PostLineFormComponent implements OnChanges {
    public postLineForm: FormGroup;
    public powerOutageNumberDisplay = false;

    @Input() public orderId: number | null;
    @Input() public translatePrefix: string;
    @Input() public specificDetail: {} | null;
    @Input() public postLine: PostLine;

    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public create: EventEmitter<PostLine> = new EventEmitter<PostLine>();
    @Output() public update: EventEmitter<PostLine> = new EventEmitter<PostLine>();

    constructor(
        formBuilder: FormBuilder,
    ) {
        this.postLineForm = formBuilder.group({
            id: [],
            orderId: ['', [Validators.required]],
            chargeNumber: ['', [Validators.required]],
            powerOutageNumber: [''],
            postElect: ['', [Validators.required]],
            line: ['', [Validators.required]],
            fromInventory: [false],
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.orderId && changes.orderId.currentValue) {
            this.postLineForm.controls.orderId.setValue(changes.orderId.currentValue);
        }

        if (changes && changes.postLine && changes.postLine.currentValue) {
            this.postLineForm.reset(changes.postLine.currentValue);
        }

        if (changes && changes.specificDetail && changes.specificDetail.currentValue) {
            if (changes.specificDetail.currentValue.chargeNumber) {
                this.postLineForm.controls.chargeNumber.setValue(changes.specificDetail.currentValue.chargeNumber);
                this.postLineForm.controls.chargeNumber.disable({ onlySelf: true });
            }

            if (changes.specificDetail.currentValue.powerOutageNumber) {
                this.postLineForm.controls.powerOutageNumber.setValue(changes.specificDetail.currentValue.powerOutageNumber);
                this.postLineForm.controls.powerOutageNumber.disable({ onlySelf: true });
            }

            if (changes.specificDetail.currentValue.orderType && changes.specificDetail.currentValue.orderType === OrderType.outage) {
                this.postLineForm.controls.powerOutageNumber.setValidators(Validators.required);
                this.powerOutageNumberDisplay = true;
            }
        }

        this.postLineForm.updateValueAndValidity();
    }

    public cancelForm(): void {
        this.cancel.emit(true);
    }

    public onSubmit(): void {
        const { valid } = this.postLineForm;

        if (valid) {
            const value = {
                ...this.postLineForm.getRawValue(),
            };

            if (this.postLine && this.postLine.id) {
                this.update.emit(value);
            } else {
                const { id, ...cleanValue } = value;
                this.create.emit(cleanValue);
            }
        }
    }
}
