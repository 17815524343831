import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';

import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { FieldsetModule } from 'primeng/fieldset';

export const timesheetsVendorsModules = [
    // Angular CDK
    DragDropModule,
    TextFieldModule,

    // Angular Material
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatTreeModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatCheckboxModule,
    MatFormFieldModule,

    // PrimeNG
    ToastModule,
    CalendarModule,
    AccordionModule,
    TabViewModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    TableModule,
    TooltipModule,
    FieldsetModule,
];
