<app-element-loader id="spinner" *ngIf="(isDemandeClientUpdating$ | async) || (isUtilisateursInternesLoading$ | async)">
</app-element-loader>

<div class="p-field p-grid close-button-div">
    <div class="p-col-11"></div>
    <button pButton icon="fas fa-times" class="close-button p-col-1" (click)="onExit()"></button>
</div>

<mat-tab-group [animationDuration]="'0'">
    <mat-tab [label]="translatePrefix + 'details' | translate">
        <h3 *ngIf="isEditing">{{ translatePrefix + 'editTitle' | translate }}</h3>
        <h3 *ngIf="!isEditing">{{ translatePrefix + 'displayTitle' | translate }}</h3>
        <div class="action">
            <div class="action__actionContainer">
                <div class="actionContainer__button" *ngIf="this.demandeClient.ordre.pieceJointeFlag">
                    <button pButton icon="fas fa-file"
                        [label]="commonTranslatePrefix + 'PieceJointe' | translate" type="button"
                        (click)="openSapPiecesJointes()" class="button__wide right">
                    </button>
                </div>
                <div class="actionContainer__button">
                    <button pButton icon="fas fa-globe" [label]="commonTranslatePrefix + 'WAB' | translate" type="button"
                        (click)="openWAB()" class="action__wide">
                    </button>
                </div>
                <div class="actionContainer__button small">
                    <app-generic-other-menu *ngIf="!isEditing"
                        [editOnly]="true"
                        [addborder]="true"
                        (edit)="onEdit()">
                    </app-generic-other-menu>
                    <div class="small__editions">
                        <app-generic-other-menu *ngIf="isEditing"
                            class="action__button save"
                            [mySingleButtonOnly]="true"
                            [singleButtonIcon]="'fas fa-check'"
                            [singleButtonType]="'submit'"
                            [addborder]="true"
                            (singleButtonPressed)="onSubmit()">
                        </app-generic-other-menu>
                        <app-generic-other-menu *ngIf="isEditing"
                            class="action__button"
                            [mySingleButtonOnly]="true"
                            [singleButtonIcon]="'fas fa-times'"
                            [singleButtonType]="'button'"
                            [addborder]="true"
                            [isRedButton]="true"
                            (singleButtonPressed)="onCancel()">
                        </app-generic-other-menu>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="utilisateursInternes$ | async">
            <form [formGroup]="demandeClientForm">
                <div class="p-field p-grid">
                    <p-panel class="p-col-6 p-lg-3">
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'NumeroOrdre' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.numeroOrdre}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'Poste' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.poste}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'Ligne' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.ligne}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'StatutSysteme' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.statutSysteme}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label for="ordre" class="p-col-4">{{ commonTranslatePrefix + 'Designation' | translate
                                }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.designation}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label for="ordre" class="p-col-4">{{ commonTranslatePrefix + 'DateDebutPlan' | translate
                                }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.dateDebutPlan | date: dateFormat}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label for="ordre" class="p-col-4">{{ commonTranslatePrefix + 'Adresse' | translate
                                }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.adresse}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label for="ordre" class="p-col-4">{{ commonTranslatePrefix + 'Rue' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.rue}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label for="ordre" class="p-col-4">{{ commonTranslatePrefix + 'Ville' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.ville}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label for="ordre" class="p-col-4">{{ commonTranslatePrefix + 'CodePostal' | translate
                                }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.codePostal}}
                            </div>
                        </div>
                    </p-panel>
                    <p-panel class="p-col-6 p-lg-3">
                        <div class="p-field p-grid update-field" (click)="showRemarque()">
                            <label class="p-col-4 clickable">{{ commonTranslatePrefix + 'Remarque' | translate
                                }}&nbsp;&nbsp;<span class="p-badge p-tag-rounded"><i
                                        class="fas fa-sticky-note"></i></span></label>
                            <div class="p-col-8 display-field clickable">
                                <span [innerHTML]="remarqueAbregee"></span>
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ translatePrefix + 'responsableOperation' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.operationCourante?.matricule}} -
                                {{demandeClient.ordre.operationCourante?.nomResponsable}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ translatePrefix + 'posteTravailOperation' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.operationCourante?.workCenter}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ translatePrefix + 'clefReferenceOperation' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.operationCourante?.clefReference}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ translatePrefix + 'descriptionOperation' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.operationCourante?.designation}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ translatePrefix + 'dateLancementOperation' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.operationCourante?.dateStatutLance | date: dateFormat}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'Priorite' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.priorite}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'Lot' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.lot}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'IdentificationTechnique' | translate
                                }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.identificationTechnique}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ translatePrefix + 'numeroPartenaire' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.numeroClient}}
                            </div>
                        </div>
                        <div class="separator"></div>
                    </p-panel>
                    <p-panel class="p-col-6 p-lg-3">
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'NomClient' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.nomClient}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ translatePrefix + 'numeroTelephoneClient' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.telephoneClient}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ translatePrefix + 'contact' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.personneContact}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ translatePrefix + 'langueContact' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.langueContact}}
                            </div>
                        </div>
                        <app-demandes-client-engagement-form
                            [parentFormIsEditing]="isEditing"
                            [demandesClient]="[demandeClient]"
                            [currentUser]="currentUser"
                            (engagementChange)="onEngagementChange($event)"
                            (engagementValidityChange)="onEngagementValidityChange($event)">
                        </app-demandes-client-engagement-form>
                    </p-panel>
                    <p-panel class="p-col-6 p-lg-3">
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'TypeOrdre' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.typeOrdre}}
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'DivLocal' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.ordre.division}}
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="p-field p-grid ogiv-field">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'Pole' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.pole}}
                            </div>
                        </div>
                        <div class="p-field p-grid ogiv-field">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'Site' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.site}}
                            </div>
                        </div>
                        <div class="p-field p-grid ogiv-field">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'StatutOGIV' | translate }}</label>
                            <div class="p-col-8">
                                <p-dropdown *ngIf="statutOGIVSelectOptions" [options]="statutOGIVSelectOptions"
                                    [filter]="true" placeholder="{{ translatePrefix + 'selectStatutOGIV' | translate }}"
                                    appendTo="body" formControlName="statutOGIV" [disabled]="!isEditing"
                                    [ngClass]="{'p-invalid': statutOGIV.errors?.statutError }"
                                    aria-describedby="statutOGIV-help">
                                </p-dropdown>
                                <small id="statutOGIV-help" class="p-invalid" *ngIf="statutOGIV.errors?.statutError">
                                    {{ translatePrefix + 'errors.statutOGIV' | translate }}
                                </small>
                            </div>
                        </div>
                        <div class="p-field p-grid ogiv-field">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'AssigneOGIV' | translate }}</label>
                            <div class="p-col-8">
                                <p-dropdown *ngIf="assigneOGIVSelectOptions" [options]="assigneOGIVSelectOptions"
                                    [filter]="true" placeholder="{{ translatePrefix + 'selectAssigneOGIV' | translate }}"
                                    appendTo="body" formControlName="assigneOGIV" [disabled]="!isEditing">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="p-field p-grid ogiv-field">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'Notes' | translate }}</label>
                            <div class="p-col-8">
                                <p-dropdown *ngIf="isNotesLoaded" [options]="$any(noteSelectOptions$ | async)"
                                    [disabled]="!isEditing"
                                    placeholder="{{ translatePrefix + 'selectNotes' | translate }}"
                                    formControlName="note" [filter]="true" appendTo="body"
                                    (onChange)="noteValueChanges($event.value)">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="p-field p-grid ogiv-field">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'GroupeEcart' | translate }}</label>
                            <div class="p-col-8 display-field">
                                {{demandeClient.groupeEcart}}
                            </div>
                        </div>
                        <div class="p-field p-grid ogiv-field">
                            <label class="p-col-4">{{ commonTranslatePrefix + 'EcartEngagement' | translate }}</label>
                            <div class="p-col-8">
                                {{demandeClient.ecartEngagement}}
                            </div>
                        </div>
                        <div class="p-field p-grid ogiv-field">
                            <label class="p-col-4">{{ translatePrefix + 'contactTechnicien' | translate }}</label>
                            <div class="p-col-8">
                                <input type="text" pInputText formControlName="contactTechnicien"
                                    [attr.disabled]="!isEditing ? true : null" />
                            </div>
                        </div>
                    </p-panel>
                </div>
            </form>
        </div>

        <p-dialog [header]="translatePrefix + 'addNoteDialog.title' | translate" [(visible)]="showNoteDialog"
            [modal]="true" [responsive]="true">
            <ng-template pTemplate="body">
                <form [formGroup]="newNoteForm" (ngSubmit)="onSubmitNewNote()">
                    <input type="text" pInputText class="new-item-input" formControlName="name"
                        [placeholder]="translatePrefix + 'addNoteDialog.note' | translate" autofocus>
                </form>
            </ng-template>
            <ng-template pTemplate="footer">
                <button type="submit" pButton icon="pi pi-check" (click)="onSubmitNewNote()"
                    [disabled]="!newNoteInDropDown || newNoteInDropDown.trim() === ''"
                    [label]="translatePrefix + 'addNoteDialog.confirm' | translate"></button>
                <button type="button" pButton icon="pi pi-times" (click)="cancelNoteDialog()"
                    [label]="translatePrefix + 'addNoteDialog.cancel' | translate"></button>
            </ng-template>
        </p-dialog>

        <p-dialog [header]="translatePrefix + 'errorDialog.title' | translate" [(visible)]="showErrorDialog"
            [modal]="true" [responsive]="true">
            <ng-template pTemplate="body">
                <ul *ngIf="failedUpdates.length > 0">
                    <ng-template ngFor let-failedUpdate [ngForOf]="failedUpdates" let-i="index">
                        <li class="failed-update-message">{{failedUpdate.message}}</li>
                    </ng-template>
                </ul>
            </ng-template>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-times" (click)="closeErrorDialog()"
                    [label]="translatePrefix + 'errorDialog.close' | translate"></button>
            </ng-template>
        </p-dialog>
    </mat-tab>
    <mat-tab [label]="translatePrefix + 'operation' | translate" *ngIf="lockedOperations">
        <h3>{{ 'demandes-client.list.operations-titre' | translate }} {{ demandeClient.ordre.numeroOrdre }}</h3>
        <div class="action">
            <div class="action__spacer"></div>
            <button pButton [disabled]="isLockedOrder" [label]="translatePrefix + 'IW32' | translate" type="button"
                (click)="displayWarningIW32Dialog()">
            </button>
        </div>
        <p-table #operationsDatatable dataKey="numeroOperation" [value]="demandeClient.ordre.operations"
            [columns]="operationsCols">
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                            [attr.aria-label-desc]="'common.sortDesc' | translate"
                            [attr.aria-label-asc]="'common.sortAsc' | translate">
                        </p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-operation let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns">
                        <ng-container [ngSwitch]="col.type">
                            <ng-container *ngSwitchCase="'date'">
                                {{ operation[col.field] | date: col.format }}
                            </ng-container>
                            <ng-container *ngSwitchCase="'action'">
                                <div class="operation__lock">
                                    <div *ngIf="isLockedOperation(operation)" class="lock__icon">
                                        <i class="fas fa-lock icon__logo"
                                            [pTooltip]="translatePrefix + 'lockTooltip' | translate" tooltipPosition="top"></i>
                                    </div>
                                    <button pButton [disabled]="!isOperationIW32Enabled(operation)"
                                        [label]="translatePrefix + 'IW41' | translate" type="button"
                                        (click)="displayWarningIW41Dialog(operation)">
                                    </button>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{operation[col.field]}}
                            </ng-container>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </mat-tab>
</mat-tab-group>

<p-confirmDialog></p-confirmDialog>
