import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { Unit } from '../../../shared/models/database/unit.model';
import { UnitActionTypes, UnitsAction } from '../actions/units.action';

export interface UnitState extends EntityState<Unit> {
    loading: boolean;
    loaded: boolean;
}

export const unitAdapter: EntityAdapter<Unit> = createEntityAdapter<Unit>({
    selectId: (unit: Unit) => unit.id,
});

export const unitInitialState = unitAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function unitReducer(
    state: UnitState = unitInitialState,
    action: UnitsAction,
): UnitState {
    switch (action.type) {
        case UnitActionTypes.DELETE_ONE_UNIT:
        case UnitActionTypes.CREATE_UNIT:
        case UnitActionTypes.UPDATE_ONE_UNIT: {
            return {
                ...state,
                loading: true,
            };
        }

        case UnitActionTypes.CREATE_UNIT_SUCCESS: {
            // return unitAdapter.addOne(action.unit, {
            return {
                ...state,
                loading: false,
            };
        }

        case UnitActionTypes.UPDATE_ONE_UNIT_SUCCESS: {
            // return unitAdapter.upsertOne(action.unit, {
            return {
                ...state,
                loading: false,
            };
        }

        case UnitActionTypes.DELETE_ONE_UNIT_FAIL:
        case UnitActionTypes.CREATE_UNIT_FAIL:
        case UnitActionTypes.UPDATE_ONE_UNIT_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case UnitActionTypes.LOAD_UNITS: {
            return {
                ...state,
                loading: true,
            };
        }

        case UnitActionTypes.LOAD_UNITS_SUCCESS: {
            return unitAdapter.setAll(action.units, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case UnitActionTypes.LOAD_UNITS_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case UnitActionTypes.LOAD_UNITS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        case UnitActionTypes.DELETE_ONE_UNIT_SUCCESS: {
            // return unitAdapter.removeOne(action.unit.id, {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        default:
            return state;
    }
}
