import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { TimesheetState, getTimesheetState } from '../reducers';
import { arboristsAdapter, ArboristsState } from '../reducers/arborists.reducer';
import { Arborist, ArboristStatus } from '../../../shared/models/database/arborist.model';
import { SelectItem } from 'primeng/api';

const {
    selectAll,
    selectEntities,
    selectIds,
} = arboristsAdapter.getSelectors();

export const getArboristsState = createSelector(
    getTimesheetState,
    (state: TimesheetState) => state.arborists,
);

export const getArboristsLoading = createSelector(
    getArboristsState,
    (state: ArboristsState) => state.loading,
);

export const getArboristsLoaded = createSelector(
    getArboristsState,
    (state: ArboristsState) => state.loaded,
);

export const shouldLoadArborists = createSelector(
    getArboristsLoading,
    getArboristsLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllArborists = createSelector(
    getArboristsState,
    selectAll
);

export const getArboristsEntities = createSelector(
    getArboristsState,
    selectEntities,
);

export const getArboristsIds = createSelector(
    getArboristsState,
    selectIds,
);

export const getArboristById = (prop: { entityId: number }) => createSelector(
    getArboristsEntities,
    (entities: Dictionary<Arborist>): Arborist | null => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllArboristsSorted = createSelector(
    getAllArborists,
    (arborists: Arborist[]): Arborist[] => {
        const temporaire = [...arborists];
        temporaire.sort((a: Arborist, b: Arborist): number => {
            return (a.lastName.toLocaleLowerCase()).localeCompare(b.lastName.toLocaleLowerCase());
        });
        return temporaire;
    }
);

export const getArboristExistById = (props: { id: number }) => createSelector(
    getAllArboristsSorted,
    (arborists: Arborist[]): boolean => (arborists.filter((arborist: Arborist) => arborist.id === props.id)).length > 0
);

export const getArboristByCompanyId = (props: { companyId?: number } = {}) => createSelector(
    getAllArboristsSorted,
    (arborists: Arborist[]): Arborist[] => arborists.filter(arborist => arborist.companyId === props.companyId),
);

export const getActiveArboristByCompanyId = (props: { companyId?: number, addEmptyOption?: boolean } = {}) => createSelector(
    getAllArboristsSorted,
    (arborists: Arborist[]): Arborist[] => {
        const arboristsOptions = arborists.filter((arborist) =>
            arborist.status.toLocaleLowerCase() === ArboristStatus.active.toLocaleLowerCase() && arborist.companyId === props.companyId);

        if (props.addEmptyOption) {
            arboristsOptions.unshift({
                id: 0,
                cip: 'none',
                companyId: 0,
                firstName: 'Sélectionner un travailleur',
                lastName: ' ',
                status: ArboristStatus.active
            } as Arborist);
        }

        return arboristsOptions;
    },
);

export const getArboristByCIP = (props: { cip?: string } = {}) => createSelector(
    getAllArboristsSorted,
    (arborists: Arborist[]): Arborist | null => {
        const filteredArborist = arborists.filter((arborist: Arborist) => arborist.cip === props.cip);
        return (filteredArborist.length === 0 || filteredArborist.length > 1) ? null : filteredArborist[0];
    }
);

export const getActiveArboristOptions = (props: { addEmptyOption?: boolean } = {}) => createSelector(
    getAllArboristsSorted,
    (arborists: Arborist[]): SelectItem<number>[] => {
        const filteredArborists = arborists.filter((arborist: Arborist) => arborist.status.toLocaleLowerCase() === ArboristStatus.active.toLocaleLowerCase());
        const arboristsOptions = filteredArborists.map((arborist: Arborist) => {
            return {
                label: arborist.lastName + ', ' + arborist.firstName,
                value: arborist.id
            } as SelectItem<number>;
        });

        if (props.addEmptyOption) {
            arboristsOptions.unshift({
                label: 'Sélectionner un travailleur',
                value: 0,
            } as SelectItem<number>);
        }

        return arboristsOptions;
    }
);
