import { OpenIdClaims, OpenIdUserInfo } from '@ngxhq/security';

import { OgivHelpers } from '../../../shared/helpers/ogiv.helper';
import { UserRole } from '../../../shared/models/user-roles.model';
import { UserActions, UserActionTypes } from '../actions/user.actions';

export interface UserState {
    roles: UserRole[];
    user: Partial<OpenIdClaims & OpenIdUserInfo & {
        roles: UserRole[];
    }>;
}

const localStorageRoleString = window.localStorage.getItem('userRoles') || null;
const localStorageUserRoles = localStorageRoleString && localStorageRoleString.split(',') || [];

export const userInitialState = {
    roles: localStorageUserRoles.map((userRole: string) => UserRole[userRole as keyof UserRole]) || [],
    user: {},
};

export function userReducer(
    state: UserState = userInitialState,
    action: UserActions,
) {
    switch (action.type) {
        case UserActionTypes.SET_ROLES: {
            let newRoles = [...state.roles];

            for (const actionRole of action.roles) {
                // when role is already in the current state roles, we remove the role
                if (state.roles.includes(actionRole)) {
                    newRoles = newRoles.filter(role => role !== actionRole);
                } else {
                    newRoles = [...newRoles, actionRole];
                }
            }

            newRoles = OgivHelpers.removeDuplicates(
                role => role,
                newRoles,
            ).filter(Boolean);

            window.localStorage.setItem(
                'userRoles',
                newRoles.join(',')
            );

            return {
                ...state,
                roles: newRoles,
            };
        }

        case UserActionTypes.GET_USER_FROM_CLAIMS: {
            if (!action.claims) {
                return { ...state };
            }

            // const claimsRoles: UserRole[] = (action.claims.roles as string[]).map(
            //     roleName => UserRole[roleName.toLowerCase() as keyof UserRole]
            // );

            let claimsRoles: UserRole[] = [];
            if (action.claims.roles) {
                claimsRoles = (action.claims.roles as string[]).map(
                    roleName => UserRole[roleName.toLowerCase() as keyof UserRole]
                );
            }

            const newRoles = OgivHelpers.removeDuplicates(
                role => role,
                [...claimsRoles, ...state.roles],
            ).filter(Boolean);

            const user = {
                ...action.claims,
                ...action.userInfo,
                roles: newRoles,
            };

            window.localStorage.setItem('userId', user.id);
            window.localStorage.setItem('userEmail', user.email);
            window.localStorage.setItem(
                'userRoles',
                newRoles.join(','),
            );

            return {
                ...state,
                user,
                roles: newRoles,
            };
        }

        default:
            return state;
    }
}
