import { Action } from '@ngrx/store';
import { CurrentUser } from '../../../core/services/user.service';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { PostLine } from '../../../shared/models/database/post-line.model';

/**
 * Types
 */

export enum PostLineActionTypes {
    LOAD_POST_LINES = '[PostLine] Load PostLines',
    LOAD_POST_LINES_SUCCESS = '[PostLine] Load PostLines Success',
    LOAD_POST_LINES_NO_RESULTS = '[PostLine] Load PostLines No Results',
    LOAD_POST_LINES_FAIL = '[PostLine] Load PostLines Fail',

    CREATE_POST_LINE = '[PostLine] Create PostLine',
    CREATE_POST_LINE_SUCCESS = '[PostLine] Create PostLine Success',
    CREATE_POST_LINE_FAIL = '[PostLine] Create PostLine Fail',

    UPDATE_ONE_POST_LINE = '[PostLine] Update One PostLine',
    UPDATE_ONE_POST_LINE_FAIL = '[PostLine] Update One PostLine Fail',
    UPDATE_ONE_POST_LINE_SUCCESS = '[PostLine] Update One PostLine Success',

    DELETE_ONE_POST_LINE = '[PostLine] Delete One PostLine',
    DELETE_ONE_POST_LINE_FAIL = '[PostLine] Delete One PostLine Fail',
    DELETE_ONE_POST_LINE_SUCCESS = '[PostLine] Delete One PostLine Success',
}

/**
 * ACTIONS
 */

/**
 * CREATE
 */
export class CreatePostLine implements Action {
    readonly type = PostLineActionTypes.CREATE_POST_LINE;
    constructor(public postLine: PostLine, public timesheetDateId: string, public path: AlternativePath, public imperson?: CurrentUser) { }
}

export class CreatePostLineSuccess implements Action {
    readonly type = PostLineActionTypes.CREATE_POST_LINE_SUCCESS;
    // constructor(public postLine: PostLine) { }
}

export class CreatePostLineFail implements Action {
    readonly type = PostLineActionTypes.CREATE_POST_LINE_FAIL;
    constructor(public error: any) { }
}

/**
 * LOAD
 */
export class LoadPostLines implements Action {
    readonly type = PostLineActionTypes.LOAD_POST_LINES;
    constructor(public path: AlternativePath) { }
}

export class LoadPostLinesNoresult implements Action {
    readonly type = PostLineActionTypes.LOAD_POST_LINES_NO_RESULTS;
}

export class LoadPostLinesSuccess implements Action {
    readonly type = PostLineActionTypes.LOAD_POST_LINES_SUCCESS;
    constructor(public postLines: PostLine[]) { }
}

export class LoadPostLinesFail implements Action {
    readonly type = PostLineActionTypes.LOAD_POST_LINES_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOnePostLine implements Action {
    readonly type = PostLineActionTypes.UPDATE_ONE_POST_LINE;
    constructor(public postLine: PostLine, public timesheetDateId: string, public path: AlternativePath, public imperson?: CurrentUser) { }
}

export class UpdateOnePostLineSuccess implements Action {
    readonly type = PostLineActionTypes.UPDATE_ONE_POST_LINE_SUCCESS;
    // constructor(public postLine: PostLine) { }
}

export class UpdateOnePostLineFail implements Action {
    readonly type = PostLineActionTypes.UPDATE_ONE_POST_LINE_FAIL;
    constructor(public error: any) { }
}

/**
 * DELETE
 */
export class DeleteOnePostLine implements Action {
    readonly type = PostLineActionTypes.DELETE_ONE_POST_LINE;
    constructor(public postLine: PostLine, public timesheetDateId: string, public path: AlternativePath, public imperson?: CurrentUser) { }
}

export class DeleteOnePostLineSuccess implements Action {
    readonly type = PostLineActionTypes.DELETE_ONE_POST_LINE_SUCCESS;
    // constructor(public postLine: PostLine) { }
}

export class DeleteOnePostLineFail implements Action {
    readonly type = PostLineActionTypes.DELETE_ONE_POST_LINE_FAIL;
    constructor(public error: any) { }
}

export type PostLinesAction =
    | CreatePostLine
    | CreatePostLineSuccess
    | CreatePostLineFail
    | LoadPostLines
    | LoadPostLinesNoresult
    | LoadPostLinesSuccess
    | LoadPostLinesFail
    | UpdateOnePostLine
    | UpdateOnePostLineSuccess
    | UpdateOnePostLineFail
    | DeleteOnePostLine
    | DeleteOnePostLineSuccess
    | DeleteOnePostLineFail;
