import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// import moment from 'moment';
import { Timesheet } from '../../../shared/models/database/timesheet.model';

import { TimesheetsAction, TimesheetsActionTypes } from '../actions/timesheets.action';

export interface TimesheetsState extends EntityState<Timesheet> {
    loading: boolean;
    loaded: boolean;
}

// export const timesheetsAdapter: EntityAdapter<Timesheet> = createEntityAdapter<Timesheet>({
//     selectId: (timesheet: Timesheet) => (timesheet.getDateId && timesheet.getDateId()) || moment(timesheet.date).format('YYYYMMDD') || timesheet.id.toString(),
// });
export const timesheetsAdapter: EntityAdapter<Timesheet> = createEntityAdapter<Timesheet>({
    selectId: (timesheet: Timesheet) => {
        const timesheetInstance = new Timesheet(timesheet);
        return timesheetInstance.getDateId();
    }
});

export const timesheetsInitialState = timesheetsAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function timesheetsReducer(
    state: TimesheetsState = timesheetsInitialState,
    action: TimesheetsAction
): TimesheetsState {
    switch (action.type) {
        case TimesheetsActionTypes.CREATE_TIMESHEET:
        case TimesheetsActionTypes.APPROVE_TIMESHEET:
        case TimesheetsActionTypes.SUBMIT_TIMESHEET:
        case TimesheetsActionTypes.DELETE_TIMESHEET:
        case TimesheetsActionTypes.LOAD_TIMESHEET_BY_ID_AND_IMPERSONNIFICATION:
        case TimesheetsActionTypes.LOAD_TIMESHEET_BY_ID: {
            return {
                ...state,
                loading: true,
            };
        }

        case TimesheetsActionTypes.CREATE_TIMESHEET_SUCCESS:
        case TimesheetsActionTypes.APPROVE_TIMESHEET_SUCCESS:
        case TimesheetsActionTypes.SUBMIT_TIMESHEET_SUCCESS:
        case TimesheetsActionTypes.LOAD_TIMESHEET_SUCCESS: {
            return timesheetsAdapter.upsertOne(action.timesheet, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case TimesheetsActionTypes.DELETE_TIMESHEET_SUCCESS: {
            return timesheetsAdapter.removeOne(action.timesheetDateId, {
                ...state,
                loading: false,
            });
        }

        case TimesheetsActionTypes.LOAD_TIMESHEET_NO_RESULT: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case TimesheetsActionTypes.LOAD_TIMESHEET_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        case TimesheetsActionTypes.SUBMIT_TIMESHEET_FAIL:
        case TimesheetsActionTypes.CREATE_TIMESHEET_FAIL:
        case TimesheetsActionTypes.DELETE_TIMESHEET_FAIL:
        case TimesheetsActionTypes.APPROVE_TIMESHEET_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        default:
            return state;
    }
}
