import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TimesheetComponent } from './components/timesheet.component';
import { TimesheetDetailsComponent } from './components/timesheet-details/timesheet-details.component';
import { LoadTimesheetGuard } from './guards/load-timesheet.guard';
import { PageSelector } from '../../../shared/models/page-selector.model';
import { UserRole } from '../../../shared/models/user-roles.model';
import { LoadPageGuard } from '../../../guards';
import { RoleGuard } from '../../../core/guards/role.guard';

const routes: Routes = [
    {
        path: '',
        data: {
            pageSelector: [PageSelector.timesheet],
            roles: [UserRole.support, UserRole.pilote, UserRole.chefequipe, UserRole.chefgroupe, UserRole.entrepreneur],
        },
        component: TimesheetComponent,
        canActivate: [LoadPageGuard, RoleGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: ':timesheetId',
                data: {
                    roles: [UserRole.support, UserRole.pilote, UserRole.chefequipe, UserRole.chefgroupe, UserRole.entrepreneur],
                },
                component: TimesheetDetailsComponent,
                canActivate: [LoadTimesheetGuard, RoleGuard],
                runGuardsAndResolvers: 'always',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TimesheetRoutingModule {
}
