import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Equipment } from '../../../../../shared/models/database/equipment.model';
import { OrderGroup } from '../../../../../shared/models/database/order-group.model';

import { Order, OrderTypeMappingFromDisplay } from '../../../../../shared/models/database/order.model';
import { OrderType } from '../../../../../shared/models/database/order-type.enum';
import { PostLineGroup } from '../../../../../shared/models/database/post-line-group.model';
import { Worker } from '../../../../../shared/models/database/worker.model';

@Component({
    selector: 'app-order-delete-form',
    templateUrl: './order-delete-form.component.html',
    styleUrls: ['./order-delete-form.component.scss']
})
export class OrderDeleteFormComponent implements OnChanges {
    public orderDeleteForm: FormGroup;

    public unitlyIsDeletable = false;
    public hourlyIsDeletable = false;
    public outageIsDeletable = false;

    public unitlyValue = OrderType.unit;
    public hourlyValue = OrderType.hourly;
    public outageValue = OrderType.outage;

    @Input() public timesheetId: number | null;
    @Input() public translatePrefix: string;
    @Input() public orderGroup: OrderGroup;

    @Output() public save: EventEmitter<Order> = new EventEmitter<Order>();
    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        formBuilder: FormBuilder,
    ) {
        this.orderDeleteForm = formBuilder.group({
            number: ['', [Validators.required]],
            accountingEntry: ['', [Validators.required]],
            billingType: ['', [Validators.required]],
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.orderGroup && changes.orderGroup.currentValue) {
            this.orderDeleteForm.controls.number.setValue(changes.orderGroup.currentValue.number);
            this.orderDeleteForm.controls.number.disable({ onlySelf: true });

            this.orderDeleteForm.controls.accountingEntry.setValue(changes.orderGroup.currentValue.accountingEntry);
            this.orderDeleteForm.controls.accountingEntry.disable({ onlySelf: true });

            this.unitlyIsDeletable = this.orderTypeDeletable(changes.orderGroup.currentValue.unitly);
            this.hourlyIsDeletable = this.orderTypeDeletable(changes.orderGroup.currentValue.hourly);
            this.outageIsDeletable = this.orderTypeDeletable(changes.orderGroup.currentValue.outage);
        }
    }

    public workerIsDeletable(workers?: Worker[] | null): boolean {
        return !workers || (workers && workers.length === 0);
    }
    public equipmentIsDeletable(equipments?: Equipment[] | null): boolean {
        return !equipments || (equipments && equipments.length === 0);
    }
    public workDoneIsDeletable(postLineGroup?: PostLineGroup[] | null): boolean {
        return !postLineGroup || (postLineGroup && postLineGroup.length === 0);
    }
    public orderTypeDeletable(order: Order): boolean {
        if (order) {
            return this.workerIsDeletable(order.workers) && this.equipmentIsDeletable(order.equipments) && this.workDoneIsDeletable(order.postLineGroups);
        }

        return false;
    }

    public displayEmptyType(): boolean {
        return !this.unitlyIsDeletable && !this.hourlyIsDeletable && !this.outageIsDeletable;
    }

    public cancelForm() {
        this.cancel.emit(true);
    }

    public onSubmit() {
        const { valid } = this.orderDeleteForm;

        const orderToDelete = this.orderGroup[OrderTypeMappingFromDisplay[this.orderDeleteForm.controls.billingType.value]];

        if (valid) {
            this.save.emit(orderToDelete);
        } else {
            this.cancelForm();
        }
    }
}
