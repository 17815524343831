import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProgressReportSpecific } from '../../../shared/models/database/progress-report-specific.model';
import { ProgressReportsSpecificAction, ProgressReportsSpecificActionType } from '../actions';

export interface ProgressReportsSpecificState extends EntityState<ProgressReportSpecific> {
    loading: boolean;
    loaded: boolean;
    dateOfData: Date | null;
}

export const progressReportsSpecificAdapter: EntityAdapter<ProgressReportSpecific> = createEntityAdapter<ProgressReportSpecific>({
    selectId: (progressReportSpecific: ProgressReportSpecific) => progressReportSpecific.objectId,
});

export const progressReportsSpecificState = progressReportsSpecificAdapter.getInitialState({
    loading: false,
    loaded: false,
    dateOfData: null
});

export function progressReportsSpecificReducer(
    state: ProgressReportsSpecificState = progressReportsSpecificState,
    action: ProgressReportsSpecificAction,
): ProgressReportsSpecificState {
    switch (action.type) {
        case ProgressReportsSpecificActionType.LOAD_PROGRESS_REPORTS_SPECIFIC: {
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        }

        case ProgressReportsSpecificActionType.LOAD_PROGRESS_REPORTS_SPECIFIC_SUCCESS: {
            return progressReportsSpecificAdapter.setAll(action.specificReportWrapper.report_data, {
                ...state,
                loading: false,
                loaded: true,
                dateOfData: action.specificReportWrapper.date_of_data
            });
        }

        case ProgressReportsSpecificActionType.LOAD_PROGRESS_REPORTS_SPECIFIC_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case ProgressReportsSpecificActionType.LOAD_PROGRESS_REPORTS_SPECIFIC_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
