import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { map, tap, take, filter, switchMap } from 'rxjs/operators';
import moment from 'moment';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { DateHelpers } from '../../../../shared/helpers/date.helper';
import { Go } from '../../../../core/store/actions/router.actions';
import { getRouterParams } from '../../../../core/store/selectors/router.selectors';
import { UserRole } from '../../../../shared/models/user-roles.model';
import { AlternativePath } from '../../../../shared/models/atlernative-path.model';
import { getUserRoles } from '../../../../core/store/selectors';
import { OpenIdClaims, OpenIdUserInfo } from '@ngxhq/security';
import { LoadUtilisateursExternesTeamLeaderList } from '../../../../pilotages/store/actions';
import {
    getUtilisateursExternesTeamLeadersOptions,
    getUtilisateursExternesTeamLeaderByidAgol,
} from '../../../../pilotages/store/selectors/utilisateurs-externes-team-leaders.selector';
import { UtilisateurExterne } from '../../../../pilotages/models/utilisateur-externe.model';
import { CurrentUser, UserService } from '../../../../core/services/user.service';
import { UtilisateurExterneTeamLeaderSelectItem } from '../../../../pilotages/models/utilisateur-externe-team-leader-select-item.model';
import { getTimesheetsLoading } from '../../../store/selectors';

@Component({
    templateUrl: './timesheet.component.html',
    styleUrls: ['./timesheet.component.scss'],
})
export class TimesheetComponent implements OnInit, OnDestroy {
    public readonly rolesAllowToImpersonnify = [UserRole.chefgroupe, UserRole.entrepreneur, UserRole.support, UserRole.pilote];
    public isAllowToImpersonnify = false;
    public calendar: Date = DateHelpers.getUTCDate().toDate();
    public isEmptyCalendar = false;
    public isDateFetchedFromRouter = false;
    public dateSelectForm: FormGroup;
    public timesheetForm: FormGroup;
    public subscriptions: Subscription[] = [];
    public currentTimesheetId = '';
    public hasTouchScreen = false;
    public datePlaceholder = '';
    public pathChoice: string;
    public selected = '';
    public userInfo: OpenIdUserInfo | undefined;
    public claims: OpenIdClaims | undefined;
    public selectedCurrentUser: CurrentUser;

    public teamLeaderOption$: Observable<UtilisateurExterneTeamLeaderSelectItem[]> = this.store.pipe(
        select(getUtilisateursExternesTeamLeadersOptions),
    );

    public userRoles$: Observable<UserRole[]>;
    public timesheetLoading$: Observable<boolean> = this.store.pipe(
        select(getTimesheetsLoading),
    );

    @Input() routingPath: string = AlternativePath.ent;

    constructor(
        private readonly store: Store,
        private formBuilder: FormBuilder,
        public activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private userService: UserService,
    ) {
        this.selectedCurrentUser = this.userService.currentUser;

        this.timesheetForm = this.formBuilder.group({
            impersonnification: [''],
            date: [''],
        });

        this.translate.get('timesheet.datePlaceholder').subscribe((translatedString: string) => this.datePlaceholder = translatedString);
        this.subscriptions.push(
            this.store.pipe(
                select(getUserRoles),
            ).subscribe((userRoles: UserRole[]) => {
                this.canImpersonnify(userRoles);
                if (userService.connectedUser && userService.connectedUser.idAgol) {
                    if (this.isAllowToImpersonnify && userService.connectedUser.idAgol !== '') {
                        this.selected = userService.connectedUser.idAgol;
                        this.timesheetForm.controls.impersonnification.setValue(this.selected);
                        this.timesheetForm.updateValueAndValidity();
                    }
                }
            })
        );

        if ('maxTouchPoints' in navigator) {
            this.hasTouchScreen = navigator.maxTouchPoints > 0;
        }
    }

    public canImpersonnify(userRoles: UserRole[]): void {
        this.isAllowToImpersonnify = userRoles.some((userRole: UserRole) => this.rolesAllowToImpersonnify.includes(userRole));
        if (this.isAllowToImpersonnify) {
            this.store.dispatch(new LoadUtilisateursExternesTeamLeaderList());
        } else {
            this.selectedCurrentUser.isImpersonnified = false;
            this.userService.currentUser = this.selectedCurrentUser;
        }
    }

    public ngOnInit(): void {
        this.activatedRoute.data.subscribe(data => {
            this.pathChoice = data.pathChoise;
        });

        this.subscriptions.push(
            this.store.pipe(
                select(getRouterParams),
                take(1),
                map((params: Params) => {
                    return params.timesheetId || '';
                }),
                tap((timesheetId: string) => {
                    const timesheetDate = !timesheetId ? '' : moment(timesheetId).toDate();

                    this.currentTimesheetId = timesheetId;
                    this.timesheetForm.controls.date.setValue(timesheetDate);
                })
            ).subscribe(),

            this.timesheetForm.controls.impersonnification.valueChanges.pipe(
                filter((selectedTeamLeaderIdAgol: string) => !!selectedTeamLeaderIdAgol),
                switchMap((selectedTeamLeaderIdAgol: string) => {
                    return this.store.pipe(
                        select(getUtilisateursExternesTeamLeaderByidAgol({ idAgol: selectedTeamLeaderIdAgol })),
                        tap((foundEmployee: UtilisateurExterne | null) => {
                            if (foundEmployee !== null) {
                                const isImpersonnified = ![this.userService.connectedUser.idAgol?.toLowerCase(),
                                this.userService.connectedUser.idAgol?.toLowerCase() + '_prod'].includes(foundEmployee?.id_agol.toLowerCase());
                                const myCurrentUser = {
                                    firstName: foundEmployee.prenom,
                                    lastName: foundEmployee.nom,
                                    idAgol: foundEmployee.id_agol,
                                    isImpersonnified: isImpersonnified,
                                };

                                this.userService.currentUser = myCurrentUser;
                                this.selectedCurrentUser = myCurrentUser;

                                this.updateDestination(this.currentTimesheetId, foundEmployee.id_agol);
                                this.selected = foundEmployee.id_agol;
                            } else {
                                this.selectedCurrentUser.isImpersonnified = false;
                                this.selectedCurrentUser = this.userService.currentUser;
                            }

                            this.timesheetForm.updateValueAndValidity();
                        }),
                    );
                })
            ).subscribe(),

            this.timesheetForm.controls.date.valueChanges.pipe(
                map((date: Date) => date && moment(date).format('YYYYMMDD') || ''),
                tap((date: string) => {
                    this.updateDestination(date, this.selected);
                    this.currentTimesheetId = date;
                })
            ).subscribe(),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    public updateDestination(myDate: string, idAgol?: string): void {
        let date = '';

        if (myDate === undefined && this.currentTimesheetId !== '') {
            date = this.currentTimesheetId;
        } else if (myDate) {
            date = myDate;
        }

        // We had a route and remove the date (clear date from datepicker)
        if (this.currentTimesheetId && !date) {
            this.store.dispatch(new Go({
                path: ['/temps'],
            }));
        }

        if (date && (this.currentTimesheetId !== date) || (idAgol && idAgol !== this.selected)) {
            this.store.dispatch(new Go({
                path: ['/temps', date],
            }));
        }
    }
}
