import { Data } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RouterStateUrl } from '../models';
import { PageSelector } from '../../../shared/models/page-selector.model';

export const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const getRouterStateUrl = createSelector(
    getRouterState,
    (routerState: RouterReducerState<RouterStateUrl>) => routerState && routerState.state || {} as RouterStateUrl,
);

export const getRouterUrl = createSelector(
    getRouterStateUrl,
    (state: RouterStateUrl) => state && state.url
);

export const getRouterUrlArray = createSelector(
    getRouterUrl,
    (url: string) => url && url.split('/').filter(Boolean) || []
);

export const getRouterParams = createSelector(
    getRouterStateUrl,
    (state: RouterStateUrl) => state && state.params || {}
);

export const getRouterQueryParams = createSelector(
    getRouterStateUrl,
    (state: RouterStateUrl) => state && state.queryParams || {}
);

export const getRouterData = createSelector(
    getRouterStateUrl,
    (state: RouterStateUrl) => state && state.data || {}
);

export const shouldLoadGivenPage = createSelector(
    getRouterData,
    (data: Data) => {
        let total = 0;
        let quantity = 0;
        if (data && data.pageSelector !== undefined) {
            total = data.pageSelector.length;
            data.pageSelector.forEach((page: string) => {
                if (page in PageSelector) {
                    quantity++;
                }
            });
            return total === quantity;
        }
        return false;
    }
);

export const getPageNameToLoad = createSelector(
    getRouterData,
    (data: Data) => {
        const arrayName: string[] = [];
        if (data && data.pageSelector !== undefined) {
            data.pageSelector.forEach((page: string) => {
                if (page in PageSelector) {
                    arrayName.push(page);
                }
            });
        }
        return arrayName;
    }
);
