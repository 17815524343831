import { Action } from '@ngrx/store';
import { JobClass } from '../../../shared/models/database/job-class.model';

/**
 * Types
 */

export enum JobClassesActionTypes {
    LOAD_JOB_CLASSES = '[JobClass] Load Job Classes',
    LOAD_JOB_CLASSES_SUCCESS = '[JobClass] Load Job Classes Success',
    LOAD_JOB_CLASSES_NO_RESULTS = '[JobClass] Load Job Classes No Results',
    LOAD_JOB_CLASSES_FAIL = '[JobClass] Load Job Classes Fail',

    CREATE_JOB_CLASS = '[JobClass] Create Job Class',
    CREATE_JOB_CLASS_SUCCESS = '[JobClass] Create Job Class Success',
    CREATE_JOB_CLASS_FAIL = '[JobClass] Create Job Class Fail',

    UPDATE_ONE_JOB_CLASS = '[JobClass] Update One Job Class',
    UPDATE_ONE_JOB_CLASS_FAIL = '[JobClass] Update One Job Class Fail',
    UPDATE_ONE_JOB_CLASS_SUCCESS = '[JobClass] Update One Job Class Success',

    DELETE_ONE_JOB_CLASS = '[JobClass] Delete One Job Class',
    DELETE_ONE_JOB_CLASS_FAIL = '[JobClass] Delete One Job Class Fail',
    DELETE_ONE_JOB_CLASS_SUCCESS = '[JobClass] Delete One Job Class Success',
}

/**
 * ACTIONS
 */

export class CreateJobClass implements Action {
    readonly type = JobClassesActionTypes.CREATE_JOB_CLASS;
    constructor(public jobClass: JobClass, public path: string) { }
}

export class CreateJobClassSuccess implements Action {
    readonly type = JobClassesActionTypes.CREATE_JOB_CLASS_SUCCESS;
    constructor(public jobClasses: JobClass[]) { }
}

export class CreateJobClassFail implements Action {
    readonly type = JobClassesActionTypes.CREATE_JOB_CLASS_FAIL;
    constructor(public error: any) { }
}

export class LoadJobClasses implements Action {
    readonly type = JobClassesActionTypes.LOAD_JOB_CLASSES;
    constructor(public path: string) { }
}

export class LoadJobClassesNoResult implements Action {
    readonly type = JobClassesActionTypes.LOAD_JOB_CLASSES_NO_RESULTS;
}

export class LoadJobClassesSuccess implements Action {
    readonly type = JobClassesActionTypes.LOAD_JOB_CLASSES_SUCCESS;
    constructor(public jobClasses: JobClass[]) { }
}

export class LoadJobClassesFail implements Action {
    readonly type = JobClassesActionTypes.LOAD_JOB_CLASSES_FAIL;
    constructor(public error: any) { }
}

export class UpdateOneJobClass implements Action {
    readonly type = JobClassesActionTypes.UPDATE_ONE_JOB_CLASS;
    constructor(public jobClass: JobClass, public path: string) { }
}

export class UpdateOneJobClassSuccess implements Action {
    readonly type = JobClassesActionTypes.UPDATE_ONE_JOB_CLASS_SUCCESS;
    constructor(public jobClass: JobClass) { }
}

export class UpdateOneJobClassFail implements Action {
    readonly type = JobClassesActionTypes.UPDATE_ONE_JOB_CLASS_FAIL;
    constructor(public error: any) { }
}

export class DeleteOneJobClass implements Action {
    readonly type = JobClassesActionTypes.DELETE_ONE_JOB_CLASS;
    constructor(public jobClass: JobClass, public path: string) { }
}

export class DeleteOneJobClassSuccess implements Action {
    readonly type = JobClassesActionTypes.DELETE_ONE_JOB_CLASS_SUCCESS;
}

export class DeleteOneJobClassFail implements Action {
    readonly type = JobClassesActionTypes.DELETE_ONE_JOB_CLASS_FAIL;
    constructor(public error: any) { }
}

export type JobClassesAction =
    | CreateJobClass
    | CreateJobClassSuccess
    | CreateJobClassFail
    | LoadJobClasses
    | LoadJobClassesNoResult
    | LoadJobClassesSuccess
    | LoadJobClassesFail
    | UpdateOneJobClass
    | UpdateOneJobClassSuccess
    | UpdateOneJobClassFail
    | DeleteOneJobClass
    | DeleteOneJobClassSuccess
    | DeleteOneJobClassFail;
