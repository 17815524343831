import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilisateurInterne } from '../../../../models/utilisateur-interne.model';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-maj-utilisateur-interne-form',
    templateUrl: './maj-utilisateur-interne-form.component.html',
    styleUrls: ['./maj-utilisateur-interne-form.component.scss']
})
export class MajUtilisateurInterneFormComponent implements OnChanges {
    public utilisateurInterneForm: FormGroup;
    public pattern = { 0: { pattern: new RegExp('\\d'), symbol: '#' } };

    public horaires: any[] = [
        { value: 'Aucun', viewValue: 'Aucun' },
        { value: 'Lundi', viewValue: 'Lundi' },
        { value: 'Mardi', viewValue: 'Mardi' },
        { value: 'Mercredi', viewValue: 'Mercredi' },
        { value: 'Jeudi', viewValue: 'Jeudi' },
        { value: 'Vendredi', viewValue: 'Vendredi' }
    ];

    @Input() public utilisateurInterne: UtilisateurInterne;
    @Input() public translatePrefix: string;

    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public update: EventEmitter<UtilisateurInterne> = new EventEmitter<UtilisateurInterne>();

    constructor(
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MajUtilisateurInterneFormComponent>,
        public translateService: TranslateService
    ) {
        this.utilisateurInterneForm = this.formBuilder.group({
            nom: [{ value: '', disabled: true }],
            prenom: [{ value: '', disabled: true }],
            pole: [''],
            site: [''],
            numero_vehicule: [''],
            numero_tablette: [''],
            numero_cellulaire: [''],
            numero_ordinateur: [''],
            numero_ecran: [''],
            horaire: [''],
            carte_essence: [''],
            telephone_residence: [''],
            cadena: [''],
            carte_acces: [''],
            carte_sim_tablette: [''],
            carte_sim_cellulaire: [''],
            bureau: [''],
            courriel: ['', Validators.email],
            telephone_externe: [''],
            telephone_interne: [''],
            fax: [''],
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.utilisateurInterne && changes.utilisateurInterne.currentValue) {
            this.utilisateurInterneForm.reset(changes.utilisateurInterne.currentValue);
        }
    }

    public cancelForm(): void {
        this.cancel.emit(true);
    }

    private removeSpaceFromString(value: string): string {
        return value.replace(/\s+/g, '');
    }

    private removeSpaceAndSave(name: string) {
        const foundController = this.utilisateurInterneForm.controls[name];
        if (foundController && foundController.value !== null && foundController.value !== '') {
            this.utilisateurInterneForm.controls[name].setValue(this.removeSpaceFromString(foundController.value));
        }
    }

    private formatPhonesNumber(): void {
        this.removeSpaceAndSave('numero_cellulaire');
        this.removeSpaceAndSave('telephone_residence');
        this.removeSpaceAndSave('telephone_externe');
        this.removeSpaceAndSave('telephone_interne');
        this.removeSpaceAndSave('fax');
    }

    public onSubmit(): void {
        this.formatPhonesNumber();
        const { valid, touched } = this.utilisateurInterneForm;

        const value = {
            ...this.utilisateurInterne,
            ...this.utilisateurInterneForm.value,
        };

        if (valid && touched) {
            this.update.emit(value);
        }
    }

}
