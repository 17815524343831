import { Vehicle } from '../../../shared/models/database/vehicle.model';
import { VehicleAction, VehicleActionType } from '../actions/vehicles.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface VehiclesState extends EntityState<Vehicle> {
    loading: boolean;
    loaded: boolean;
}

export const vehiclesAdapter: EntityAdapter<Vehicle> = createEntityAdapter<Vehicle>({
    selectId: (vehicle: Vehicle) => vehicle.id,
});

export const vehiclesInitialState = vehiclesAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function vehicleReducer(
    state: VehiclesState = vehiclesInitialState,
    action: VehicleAction,
): VehiclesState {
    switch (action.type) {
        case VehicleActionType.CREATE_VEHICLE:
        case VehicleActionType.UPDATE_ONE_VEHICLE:
        case VehicleActionType.LOAD_VEHICLES: {
            return {
                ...state,
                loading: true,
            };
        }

        case VehicleActionType.CREATE_VEHICLE_SUCCESS: {
            return vehiclesAdapter.setAll(action.vehicles, {
                ...state,
                loading: false,
            });
        }

        case VehicleActionType.UPDATE_ONE_VEHICLE_SUCCESS: {
            return vehiclesAdapter.upsertOne(action.vehicle, {
                ...state,
                loading: false,
            });
        }

        case VehicleActionType.CREATE_VEHICLE_FAIL:
        case VehicleActionType.UPDATE_ONE_VEHICLE_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case VehicleActionType.LOAD_VEHICLES_SUCCESS: {
            return vehiclesAdapter.setAll(action.vehicles, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case VehicleActionType.LOAD_VEHICLES_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case VehicleActionType.LOAD_VEHICLES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
