import { createSelector } from '@ngrx/store';

import { SelectItem } from 'primeng/api';

import { getSpecialMeasuresState, SpecialMeasuresState } from '../reducers';
import { prescriptionsAdapter, PrescriptionsState } from '../reducers/special-measures.reducers';

const {
    selectAll,
    selectEntities,
    selectIds,
} = prescriptionsAdapter.getSelectors();

export const getPresciptionsState = createSelector(
    getSpecialMeasuresState,
    (state: SpecialMeasuresState) => state.prescriptions,
);

export const getSpecialMeasuresLoading = createSelector(
    getPresciptionsState,
    (state: PrescriptionsState) => state.loading,
);

export const getSpecialMeasuresLoaded = createSelector(
    getPresciptionsState,
    (state: PrescriptionsState) => state.loaded,
);

export const getAllPrescriptions = createSelector(
    getPresciptionsState,
    selectAll
);

export const getPrescriptionsEntities = createSelector(
    getPresciptionsState,
    selectEntities,
);

export const getPrescriptionsIds = createSelector(
    getPresciptionsState,
    selectIds,
);

export const getNacelles = createSelector(
    getPresciptionsState,
    (state: PrescriptionsState) => state.nacelles,
);

export const getReseaux = createSelector(
    getPresciptionsState,
    (state: PrescriptionsState) => state.reseaux,
);

export const getNacellesSelectOptions = createSelector(
    getNacelles,
    (nacelles: string[]): SelectItem[] => {
        const nacellesSelectItems = nacelles.map((nacelle: string) => {
            return {
                label: nacelle,
                value: nacelle
            } as SelectItem<string>;
        });
        return nacellesSelectItems;
    }
);

export const getReseauxSelectOptions = createSelector(
    getReseaux,
    (reseaux: string[]): SelectItem[] => {
        const reseauxSelectItems = reseaux.map((reseau: string) => {
            return {
                label: reseau,
                value: reseau
            } as SelectItem;
        });
        return reseauxSelectItems;
    }
);
