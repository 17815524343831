import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../ogiv-core/services';
import {
    LoadEntrepreneurs,
    EntrepreneurActionType,
    LoadEntrepreneursSuccess,
    LoadEntrepreneursFail,
    LoadEntrepreneursNoResult,
    UpdateOneEntrepreneur,
    UpdateOneEntrepreneurSuccess,
    UpdateOneEntrepreneurFail,
    DeactivateOneEntrepreneur,
    DeactivateOneEntrepreneurSuccess,
    DeactivateOneEntrepreneurFail,
    ReactivateOneEntrepreneur,
    ReactivateOneEntrepreneurSuccess,
    ReactivateOneEntrepreneurFail
} from '../actions';
import { Entrepreneur } from '../../models/entrepreneur.model';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class EntrepreneursEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService
    ) { }

    public loadEntrepreneurs$ = createEffect(() => this.action$.pipe(
        ofType<LoadEntrepreneurs>(EntrepreneurActionType.LOAD_ENTREPRENEURS),
        switchMap((action: LoadEntrepreneurs) => {
            return this.apiService.get<Entrepreneur[]>(`${action.path}/pilotage/entrepreneurs`).pipe(
                map((returnedEntrepreneur: Entrepreneur[]) =>
                    (returnedEntrepreneur.length > 0)
                        ? new LoadEntrepreneursSuccess(returnedEntrepreneur)
                        : new LoadEntrepreneursNoResult()
                ),
                catchError((error: any) => of(new LoadEntrepreneursFail(error))),
            );

        }),
    ));

    /************************/
    /*******  Update  *******/
    /************************/
    public updateOneEntrepreneur$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneEntrepreneur>(EntrepreneurActionType.UPDATE_ONE_ENTREPRENEUR),
        mergeMap((action: UpdateOneEntrepreneur) => {
            return this.apiService.put<Entrepreneur, Entrepreneur>(`hq/pilotage/entrepreneurs/${action.entrepreneur.id}`, action.entrepreneur).pipe(
                map((updatedEntrepreneur: Entrepreneur) =>
                    new UpdateOneEntrepreneurSuccess(updatedEntrepreneur)
                ),
                catchError((error: any) => of(new UpdateOneEntrepreneurFail(error))),
            );
        })
    ));

    public updateOneEntrepreneurSuccess$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneEntrepreneurSuccess>(EntrepreneurActionType.UPDATE_ONE_ENTREPRENEUR_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'pilotages.notifications.entrepreneur.updateOne.success',
            }))
        ]
        ),
    ));

    public updateOneEntrepreneurFail$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneEntrepreneurFail>(EntrepreneurActionType.UPDATE_ONE_ENTREPRENEUR_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.entrepreneur.updateOne.error',
            }))
        ]
        ),
    ));

    /****************************/
    /*******  Deactivate  *******/
    /****************************/
    public deactivateOneEntrepreneur$ = createEffect(() => this.action$.pipe(
        ofType<DeactivateOneEntrepreneur>(EntrepreneurActionType.DEACTIVATE_ONE_ENTREPRENEUR),
        mergeMap((action: DeactivateOneEntrepreneur) => {
            return this.apiService.put<Entrepreneur, Entrepreneur>(
                `${action.path}/pilotage/entrepreneurs/${action.entrepreneur.id}/actions/desactiver`, action.entrepreneur).pipe(
                    map((deactivatedEntrepreneur: Entrepreneur) =>
                        new DeactivateOneEntrepreneurSuccess(deactivatedEntrepreneur)
                    ),
                    catchError((error: any) => of(new DeactivateOneEntrepreneurFail(error))),
                );
        })
    ));

    public deactivateOneEntrepreneurSuccess$ = createEffect(() => this.action$.pipe(
        ofType<DeactivateOneEntrepreneurSuccess>(EntrepreneurActionType.DEACTIVATE_ONE_ENTREPRENEUR_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'pilotages.notifications.entrepreneur.deactivate.success',
            }))
        ]
        ),
    ));

    public deactivateOneEntrepreneurFail$ = createEffect(() => this.action$.pipe(
        ofType<DeactivateOneEntrepreneurFail>(EntrepreneurActionType.DEACTIVATE_ONE_ENTREPRENEUR_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.entrepreneur.deactivate.error',
            }))
        ]
        ),
    ));

    /****************************/
    /*******  Reactivate  *******/
    /****************************/
    public reactivateOneEntrepreneur$ = createEffect(() => this.action$.pipe(
        ofType<ReactivateOneEntrepreneur>(EntrepreneurActionType.REACTIVATE_ONE_ENTREPRENEUR),
        mergeMap((action: ReactivateOneEntrepreneur) => {
            return this.apiService.put<Entrepreneur, Entrepreneur>(
                `${action.path}/pilotage/entrepreneurs/${action.entrepreneur.id}/actions/activer`, action.entrepreneur).pipe(
                    map((reactivatedEntrepreneur: Entrepreneur) =>
                        new ReactivateOneEntrepreneurSuccess(reactivatedEntrepreneur)
                    ),
                    catchError((error: any) => of(new ReactivateOneEntrepreneurFail(error))),
                );
        })
    ));

    public reactivateOneEntrepreneurSuccess$ = createEffect(() => this.action$.pipe(
        ofType<ReactivateOneEntrepreneurSuccess>(EntrepreneurActionType.REACTIVATE_ONE_ENTREPRENEUR_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'pilotages.notifications.entrepreneur.reactivate.success',
            }))
        ]
        ),
    ));

    public reactivateOneEntrepreneurFail$ = createEffect(() => this.action$.pipe(
        ofType<ReactivateOneEntrepreneurFail>(EntrepreneurActionType.REACTIVATE_ONE_ENTREPRENEUR_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.entrepreneur.reactivate.error',
            }))
        ]
        ),
    ));
}
