import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { AddOneAppNotification } from '../../../core/store/actions';
import { ApiService } from '../../../ogiv-core/services';
import {
    DemandesClientSearch,
    DemandesClientActionTypes,
    DemandesClientBatchUpdates,
    DemandesClientBatchUpdatesSuccess,
    DemandesClientBatchUpdatesFail,
    DemandesClientBatchUpdatesFailResult,
    DemandesClientSearchSuccess,
    DemandesClientSearchFail,
    LoadDemandeClientById,
    LoadDemandeClientByIdSuccess,
    LoadDemandeClientByIdFail,
    UpdateOneDemandeClient,
    UpdateOneDemandeClientSuccess,
    UpdateOneDemandeClientFail,
    UpdateOneDemandeClientFailWithResult,
}
    from '../actions/demandes-client.actions';
import { DemandeClient, DemandeClientRawData, UpdateDemandeClientResult, UpdateDemandeClientResultRawData } from '../../../shared/models/database/demande-client.model';
import { DemandesClientBatchUpdatesResults } from '../../../shared/models/demande-client-batch-updates-results';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class DemandesClientEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public demandesClientSearch$ = createEffect(() => this.actions$.pipe(
        ofType<DemandesClientSearch>(DemandesClientActionTypes.DEMANDES_CLIENT_SEARCH),
        switchMap((action: DemandesClientSearch) => {
            const urlList = [
                'hq/demandes/client/list?territoire=LAV,MAT',
                'hq/demandes/client/list?territoire=SEI,ORL',
                'hq/demandes/client/list?territoire=LAV,MAT,SEI,ORL&operateur=NOT_IN'
            ];
            const results: { [name: string]: Observable<DemandeClientRawData[]> } = {};
            urlList.forEach((_, index) => {
                results[index] = this.apiService.post<any, any>(_, action.demandesClientFilter).pipe(
                    map((demandesClient: DemandeClientRawData[]) => {
                        return demandesClient;
                    }));
            });

            return forkJoin(
                results
            ).pipe(
                map((res: { [name: string]: DemandeClientRawData[] }) => {
                    const demandesClient: DemandeClientRawData[] = [];

                    return demandesClient.concat(...Object.values(res));
                }),

                map((demandesClient: DemandeClientRawData[]) => {
                    return new DemandesClientSearchSuccess(demandesClient.map((demande) => new DemandeClient(demande)));
                }),
                catchError((error: any) => of(new DemandesClientSearchFail(error)))
            );
        }),
    ));

    public demandesClientSearchFail$ = createEffect(() => this.actions$.pipe(
        ofType<DemandesClientSearchFail>(DemandesClientActionTypes.DEMANDES_CLIENT_SEARCH_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'demandes-client.notifications.load.error',
            }))
        ]),
    ));

    public loadDemandeClientById$ = createEffect(() => this.actions$.pipe(
        ofType<LoadDemandeClientById>(DemandesClientActionTypes.LOAD_DEMANDE_CLIENT_BY_ID),
        switchMap((action: LoadDemandeClientById) => {
            return this.apiService.post<any, any>(`hq/demandes/client/list?orderNumber=${action.demandeClientNumeroOrdre}`, null).pipe(
                map((demandeClient: DemandeClientRawData) => {
                    return new LoadDemandeClientByIdSuccess(new DemandeClient(demandeClient));
                }),
                catchError((error: any) => of(new LoadDemandeClientByIdFail(error))),
            );
        }),
    ));

    public loadDemandeClientByIdFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadDemandeClientByIdFail>(DemandesClientActionTypes.LOAD_DEMANDE_CLIENT_BY_ID_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'demandes-client.notifications.loadById.error',
            }))
        ]),
    ));

    public demandesClientBatchUpdates$ = createEffect(() => this.actions$.pipe(
        ofType<DemandesClientBatchUpdates>(DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES),
        switchMap((action: DemandesClientBatchUpdates) => {
            return this.apiService.patch<any, any>('hq/demandes/client', action.updateDemandeClient).pipe(
                map((results: DemandesClientBatchUpdatesResults) => {
                    return new DemandesClientBatchUpdatesSuccess(action.updateDemandeClient, results);
                }),
                catchError((error: any) => {
                    if (error.error.failedCount && error.error.failedCount >= 1) {
                        return of(new DemandesClientBatchUpdatesFailResult(action.updateDemandeClient, error.error as DemandesClientBatchUpdatesResults));
                    } else {
                        return of(new DemandesClientBatchUpdatesFail(error));
                    }
                }),
            );
        }),
    ));

    public demandesClientBatchUpdatesSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<DemandesClientBatchUpdatesSuccess>(DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'demandes-client.notifications.batch-updates.success',
            }))
        ]),
    ));

    public demandesClientBatchUpdatesFail$ = createEffect(() => this.actions$.pipe(
        ofType<DemandesClientBatchUpdatesFail>(DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'demandes-client.notifications.batch-updates.error',
            }))
        ]),
    ));

    public updateOneDemandeClient$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneDemandeClient>(DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT),
        mergeMap((action: UpdateOneDemandeClient) => {
            return this.apiService.put<any, any>(`hq/demandes/client/${action.numeroOrdre}`, action.demandeClient).pipe(
                map((updateDemandeClientResult: UpdateDemandeClientResultRawData) => {
                    return new UpdateOneDemandeClientSuccess(new UpdateDemandeClientResult(updateDemandeClientResult));
                }),
                catchError((error: any) => {
                    if (error.error.failedCount && error.error.failedCount >= 1) {
                        const updateDemandeClientResult = new UpdateDemandeClientResult(error.error);
                        return of(new UpdateOneDemandeClientFailWithResult(updateDemandeClientResult));
                    } else {
                        return of(new UpdateOneDemandeClientFail(error));
                    }
                }),
            );
        }),
    ));

    public updateOneDemandeClientSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneDemandeClientSuccess>(DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'demandes-client.notifications.update.success',
            }))
        ]
        ),
    ));

    public updateOneDemandeClientFail$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneDemandeClientFail>(DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'demandes-client.notifications.update.error',
            }))
        ]),
    ));
}
