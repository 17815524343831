import { createFeatureSelector } from '@ngrx/store';

import { billingCompanyReducer, BillingCompanyState } from './billing-company.reducer';
import { billingReportReducer, BillingReportsState } from '../reducers/billing-report.reducer';
import { billingReportPreviewReducer, BillingReportPreviewState } from '../reducers/billing-report-preview.reducer';
import { billingOrderReducer, BillingOrdersState } from './billing-order.reducer';
import { billingSapEntriesReducer, BillingSapEntriesState } from './billing-sap-entries.reducer';
import { billingTimesheetReducer, BillingTimesheetsState } from './billing-timesheet.reducer';

export interface BillingState {
    billingCompany: BillingCompanyState;
    billingOrders: BillingOrdersState;
    billingReports: BillingReportsState;
    billingReportPreview: BillingReportPreviewState;
    billingSapEntries: BillingSapEntriesState;
    billingTimesheets: BillingTimesheetsState;
}

export const billingStoreReducers: any = {
    billingCompany: billingCompanyReducer,
    billingOrders: billingOrderReducer,
    billingReports: billingReportReducer,
    billingReportPreview: billingReportPreviewReducer,
    billingSapEntries: billingSapEntriesReducer,
    billingTimesheets: billingTimesheetReducer
};

export const getBillingState = createFeatureSelector<BillingState>('billing');
