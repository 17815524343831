import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DemandesClientUtils } from '../../../shared/models/demande-client-utils.model';
import { DemandesClientUtilsAction, DemandesClientUtilsActionTypes } from '../actions/demandes-client-utils.actions';

export interface DemandesClientUtilsState extends EntityState<DemandesClientUtils> { }

export const demandesClientUtilsAdapter: EntityAdapter<DemandesClientUtils> = createEntityAdapter<DemandesClientUtils>({
    selectId: (demandeClientUtils: DemandesClientUtils) => demandeClientUtils.dateDemande,
});

export const demandesClientUtilsInitialState = demandesClientUtilsAdapter.getInitialState({});

export function demandesClientUtilsReducer(
    state: DemandesClientUtilsState = demandesClientUtilsInitialState,
    action: DemandesClientUtilsAction
): DemandesClientUtilsState {
    switch (action.type) {
        case DemandesClientUtilsActionTypes.LOAD_HOLIDAYS_SUCCESS: {
            return demandesClientUtilsAdapter.setAll([action.demandeClientUtils], {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        default:
            return state;
    }
}
