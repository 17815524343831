import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UIService {
    private menuIsExpended: boolean;
    private menuIsExpendedSubject: Subject<boolean> = new Subject<boolean>();
    public menuIsExpended$: Observable<boolean> = this.menuIsExpendedSubject;

    private documentWidth: number;
    private documentWidthSubject: Subject<number> = new Subject<number>();
    public documentWidth$: Observable<number> = this.documentWidthSubject;

    constructor(@Optional() @SkipSelf() parentModule?: UIService) {
        if (parentModule) {
            throw new Error(
                'UI Service is already loaded.');
        }

        fromEvent(window, 'resize').subscribe((event: any) => {
            this.setDocumentWidth(event.target.innerWidth);
        });

        this.menuIsExpendedSubject.subscribe((value) => {
            this.menuIsExpended = value;
        });

        this.documentWidthSubject.subscribe((value) => {
            this.documentWidth = value;
        });
    }

    public setMenuIsExpended(value: boolean): void {
        this.menuIsExpendedSubject.next(value);
    }

    public getMenuIsExpended(): boolean {
        return this.menuIsExpended;
    }

    public setDocumentWidth(value: number): void {
        this.documentWidthSubject.next(value);
    }

    public getDocumentWidth(): number {
        return this.documentWidth;
    }
}
