import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../core/guards/role.guard';
import { LoadPageGuard } from '../guards';
import { PageSelector } from '../shared/models/page-selector.model';
import { UserRole } from '../shared/models/user-roles.model';

const routes: Routes = [
    {
        path: 'timesheet',
        data: {
            pageSelector: [PageSelector.reportEnt, PageSelector.reportHQ, PageSelector.progressReport],
            roles: [UserRole.chefgroupe, UserRole.entrepreneur, UserRole.support, UserRole.pilote, UserRole.techinter,
            UserRole.tech, UserRole.planificateur, UserRole.cgc]
        },
        loadChildren: () => import('./modules/timesheet-report/timesheet-report.module').then(m => m.TimesheetReportsModule),
        canActivate: [LoadPageGuard, RoleGuard]
    },
    {
        path: 'avancement',
        data: {
            pageSelector: [PageSelector.progressReport],
            roles: [UserRole.support, UserRole.pilote, UserRole.chefgroupe, UserRole.entrepreneur]
        },
        loadChildren: () => import('./modules/progress-reports/progress-reports.module').then(m => m.ProgressReportsModule),
        canActivate: [LoadPageGuard, RoleGuard]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ReportsRoutingModule { }
