<div mat-dialog-title>
    <div class="title__container">
        <h3 class="text">
            {{ 'demandes-client.list.operations-titre' | translate }} {{ currentDemande?.ordre?.numeroOrdre }}
        </h3>
        <!-- <button pButton [mat-dialog-close]="true" icon="fas fa-times" class="close-button"></button> -->
        <button mat-mini-fab color="primary" [mat-dialog-close]="true" class="button__close">
            <i class="fas fa-times"></i>
          </button>
        <!-- <button mat-button [mat-dialog-close]="true">Delete</button> -->
    </div>
</div>
<div mat-dialog-content>
    <p-table #operationsDatatable dataKey="numeroOperation" [value]="currentDemande?.ordre?.operations || []"
    [columns]="operationsCols">
    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns" [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{commonTranslatePrefix + col.header  | translate}}
                <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                    [attr.aria-label-desc]="'common.sortDesc' | translate"
                    [attr.aria-label-asc]="'common.sortAsc' | translate">
                </p-sortIcon>

            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-operation let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
                <ng-container [ngSwitch]="col.type">
                    <ng-container *ngSwitchCase="'date'">
                        {{ operation[col.field] | date: col.format }}
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{operation[col.field]}}
                    </ng-container>
                </ng-container>

            </td>
        </tr>
    </ng-template>
</p-table>
</div>

<!-- <p-overlayPanel #overlayPanelOperations [showCloseIcon]="true" [style]="{width: '1050px'}" (onHide)="closeOverlayPanel()">
    <ng-template pTemplate>
        <h2>{{ 'demandes-client.list.operations-titre' | translate }} {{ currentDemande?.ordre?.numeroOrdre }} </h2>
        <p-table #operationsDatatable dataKey="numeroOperation" [value]="currentDemande?.ordre?.operations || []"
            [columns]="operationsCols">
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                            [attr.aria-label-desc]="'common.sortDesc' | translate"
                            [attr.aria-label-asc]="'common.sortAsc' | translate">
                        </p-sortIcon>

                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-operation let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns">
                        <ng-container [ngSwitch]="col.type">
                            <ng-container *ngSwitchCase="'date'">
                                {{ operation[col.field] | date: col.format }}
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                                {{operation[col.field]}}
                            </ng-container>
                        </ng-container>

                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel> -->
