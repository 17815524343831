<app-element-loader *ngIf="(isTimesheetChargeNumberLoading$ | async)"
    [size]="loaderLogoSize"
    [displayLabel]="true"
    [labelFontSize]="20">
</app-element-loader>

<p-table #table [columns]="columns" [value]="$any(timesheetsChargeNumber$ | async)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    (onFilter)="filtersDropdown($event.filteredValue)">
    <ng-template pTemplate="caption">
        <div class="formDiv">
            <form [formGroup]="form" id="timesheet-charge-number-form" class="report-form">
                <p-fieldset [legend]="translatePrefixFiltre + '.legend' | translate" styleClass="fieldset">
                    <div class="fieldset">
                        <div class="fitler__section">
                            <div class="fieldset__section">
                                <div class="fieldset__field">
                                    <mat-form-field appearance="standard" class="form__field" [hideRequiredMarker]="true">
                                        <mat-label>{{ translatePrefixFiltre + '.teamLeader' | translate }}<span class="required"> *</span></mat-label>
                                        <input type="text" [placeholder]="translatePrefixFiltre + '.teamLeader' | translate"
                                            aria-label="Chef d'équipe" matInput formControlName="$teamLeaderSearch"
                                            [matAutocomplete]="autoTeamLeader">
                                        <mat-autocomplete #autoTeamLeader="matAutocomplete" (optionSelected)="filters($event.option.value, 'teamLeader')">
                                            <mat-option [value]="filteredTeamLeader" *ngFor="let filteredTeamLeader of filteredOptions['teamLeader']">
                                                {{ filteredTeamLeader }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="fieldset__field">
                                    <mat-form-field appearance="standard" class="form__field" [hideRequiredMarker]="true">
                                        <mat-label>{{ translatePrefixFiltre + '.order' | translate }}<span class="required"> *</span></mat-label>
                                        <input type="text" [placeholder]="translatePrefixFiltre + '.order' | translate"
                                            aria-label="Commande" matInput formControlName="$orderSearch"
                                            [matAutocomplete]="autoOrder">
                                        <mat-autocomplete #autoOrder="matAutocomplete" (optionSelected)="filters($event.option.value, 'order')">
                                            <mat-option [value]="filteredOrder" *ngFor="let filteredOrder of filteredOptions['order']">
                                                {{ filteredOrder }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="fieldset__field">
                                    <mat-form-field appearance="standard" class="form__field" [hideRequiredMarker]="true">
                                        <mat-label>{{ translatePrefixFiltre + '.posteCommande' | translate }}<span class="required"> *</span></mat-label>
                                        <input type="text" [placeholder]="translatePrefixFiltre + '.posteCommande' | translate"
                                            aria-label="Poste de commande" matInput formControlName="$accountingEntrySearch"
                                            [matAutocomplete]="autoPosteCommande">
                                        <mat-autocomplete #autoPosteCommande="matAutocomplete" (optionSelected)="filters($event.option.value, 'accountingEntry')">
                                            <mat-option [value]="postOption" *ngFor="let postOption of filteredOptions['accountingEntry']">
                                                {{ postOption }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="fieldset__field dropdown">
                                    <mat-form-field appearance="standard" class="form__field" [hideRequiredMarker]="true">
                                        <mat-label>{{ translatePrefixFiltre + '.billingType' | translate }}<span class="required"> *</span></mat-label>
                                        <input type="text" [placeholder]="translatePrefixFiltre + '.billingType' | translate"
                                            aria-label="Type de rémunération" matInput formControlName="$billingTypeSearch"
                                            [matAutocomplete]="autoBillingType">
                                        <mat-autocomplete #autoBillingType="matAutocomplete" (optionSelected)="filters($event.option.value, 'billingType')">
                                            <mat-option [value]="billingType" *ngFor="let billingType of filteredOptions['billingType']">
                                                {{ billingType }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="fieldset__field">
                                    <mat-form-field appearance="standard" class="form__field" [hideRequiredMarker]="true">
                                        <mat-label>{{ translatePrefixFiltre + '.chargeNumber' | translate }}<span class="required"> *</span></mat-label>
                                        <input type="text" [placeholder]="translatePrefixFiltre + '.chargeNumber' | translate"
                                            aria-label="Numéro d'imputation" matInput formControlName="$chargeNumberSearch"
                                            [matAutocomplete]="autoChargeNumber">
                                        <mat-autocomplete #autoChargeNumber="matAutocomplete" (optionSelected)="filters($event.option.value, 'chargeNumber')">
                                            <mat-option [value]="filteredChargeNumber" *ngFor="let filteredChargeNumber of filteredOptions['chargeNumber']">
                                                {{ filteredChargeNumber }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="fieldset__section">
                                <div class="fieldset__field field-date">
                                    <div class="date">
                                            <mat-label class="date__title">{{ translatePrefixFiltre + '.date' | translate }}</mat-label>
                                            <div class="date__container">
                                                <div class="container__box">
                                                    <div class="box__text">Du:</div>
                                                    <div class="box__calender">
                                                        <p-calendar
                                                            formControlName="$timesheetDateFromSearch"
                                                            appendTo="body"
                                                            selectionMode="single"
                                                            [readonlyInput]="true"
                                                            dateFormat="yy-mm-dd"
                                                            [showButtonBar]="true"
                                                            [placeholder]="'pilotages.general.date-filter' | translate"
                                                            (onClearClick)="clearfilterDate('dateMin', '$timesheetDateFromSearch')"
                                                            (onSelect)="filterDate($event, 'dateMin', '$timesheetDateFromSearch')">
                                                        </p-calendar>
                                                    </div>
                                                </div>
                                                <div class="container__box" [class.disabled]="disabledDateMax">
                                                    <div class="box__text">Au:</div>
                                                    <div class="box__calender">
                                                        <p-calendar
                                                            formControlName="$timesheetDateToSearch"
                                                            appendTo="body"
                                                            selectionMode="single"
                                                            [readonlyInput]="true"
                                                            [disabled]="disabledDateMax"
                                                            dateFormat="yy-mm-dd"
                                                            [showButtonBar]="true"
                                                            [placeholder]="'pilotages.general.date-filter' | translate"
                                                            (onClearClick)="clearfilterDate('dateMax', '$timesheetDateToSearch')"
                                                            (onSelect)="filterDate($event, 'dateMax', '$timesheetDateToSearch')">
                                                        </p-calendar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>


                                <div class="fieldset__field">
                                    <mat-form-field appearance="standard" class="form__field" [hideRequiredMarker]="true">
                                        <mat-label>{{ translatePrefixFiltre + '.outageNumber' | translate }}</mat-label>
                                        <input type="text" [placeholder]="translatePrefixFiltre + '.outageNumber' | translate"
                                            aria-label="Numéro de panne" matInput formControlName="$outageNumberSearch"
                                            [matAutocomplete]="autoOutageNumber">
                                        <mat-autocomplete #autoOutageNumber="matAutocomplete" (optionSelected)="filters($event.option.value, 'outageNumber')">
                                            <mat-option [value]="filteredOutageNumber" *ngFor="let filteredOutageNumber of filteredOptions['outageNumber']">
                                                {{ filteredOutageNumber }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="fieldset__field">
                                    <mat-form-field appearance="standard" class="form__field" [hideRequiredMarker]="true">
                                        <mat-label>{{ translatePrefixFiltre + '.postElect' | translate }}</mat-label>
                                        <input type="text" [placeholder]="translatePrefixFiltre + '.postElect' | translate"
                                            aria-label="Poste" matInput formControlName="$postElectSearch"
                                            [matAutocomplete]="autoPostElect">
                                        <mat-autocomplete #autoPostElect="matAutocomplete" (optionSelected)="filters($event.option.value, 'postElect')">
                                            <mat-option [value]="filteredPostElect" *ngFor="let filteredPostElect of filteredOptions['postElect']">
                                                {{ filteredPostElect }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="fieldset__field">
                                    <mat-form-field appearance="standard" class="form__field" [hideRequiredMarker]="true">
                                        <mat-label>{{ translatePrefixFiltre + '.line' | translate }}</mat-label>
                                        <input type="text" [placeholder]="translatePrefixFiltre + '.line' | translate"
                                            aria-label="Ligne" matInput formControlName="$lineSearch"
                                            [matAutocomplete]="autoLine">
                                        <mat-autocomplete #autoLine="matAutocomplete" (optionSelected)="filters($event.option.value, 'line')">
                                            <mat-option [value]="filteredLine" *ngFor="let filteredLine of filteredOptions['line']">
                                                {{ filteredLine }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="fieldset__field">
                                    <mat-form-field appearance="standard" class="form__field" [hideRequiredMarker]="true">
                                        <mat-label>{{ translatePrefixFiltre + '.code' | translate }}</mat-label>
                                        <input type="text" [placeholder]="translatePrefixFiltre + '.code' | translate"
                                            aria-label="Code" matInput formControlName="$codeSearch"
                                            [matAutocomplete]="autoCode">
                                        <mat-autocomplete #autoCode="matAutocomplete" (optionSelected)="filters($event.option.value, 'code')">
                                            <mat-option [value]="filteredCode" *ngFor="let filteredCode of filteredOptions['code']">
                                                {{ filteredCode }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="action__section">
                            <button mat-stroked-button type="button" class="actions__button right" (click)="resetFitlers()" [disabled]="isResetFilterDisable()">{{ translatePrefixFiltre + '.resetButton' | translate | uppercase }}</button>
                        </div>
                    </div>
                </p-fieldset>
            </form>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns; let i = index">
                <th *ngIf="i < 10" rowspan="2" [ngStyle]="{'width': col.width ? col.width : 'unset'}">
                    <div class="p-d-flex">
                        {{ translatePrefixTable + '.headers.' + col.header | translate }}
                    </div>
                </th>
            </ng-container>
            <th colspan="2" class="sup_cell" [ngStyle]="{'width': '270px'}">{{ translatePrefixTable + '.headers.timesheetReport' | translate }}</th>
            <th colspan="2" class="sup_cell" [ngStyle]="{'width': '230px'}">{{ translatePrefixTable + '.headers.billingReport' | translate }}</th>
        </tr>
        <tr>
            <ng-container *ngFor="let col of columns; let i = index">
                <th *ngIf="i >= 10">
                    <div class="p-d-flex">
                        {{ translatePrefixTable + '.headers.' + col.header | translate }}
                    </div>
                </th>
            </ng-container>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length">
                <h3>
                    {{translatePrefixTable + '.empty' | translate}}
                </h3>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [class.haveFacturation]="rowData.billingReportId !== null && rowData.billingReportId !== ''">
            <ng-container *ngFor="let col of columns">
                <ng-container [ngSwitch]="col.type">
                    <td *ngSwitchCase="'date'" pTooltip="{{ rowData[col.field] | date:'yyyy-MM-dd' }}" class="data-cell">
                        {{ rowData[col.field] | date:'yyyy-MM-dd' }}
                    </td>
                    <td *ngSwitchDefault pTooltip="{{ rowData[col.field] }}" class="data-cell">
                        {{ rowData[col.field] }}
                    </td>
                </ng-container>
            </ng-container>
        </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
        <tr>
            <td [attr.colspan]="columns.length" >
                <div class="footer">
                    <button mat-raised-button type="button" class="footer__button" (click)="openChangenumber()" [disabled]="isGeneralFormInvalid">{{ translatePrefixTable + '.changeChargeNumber' | translate | uppercase }}</button>
                </div>
            </td>
        </tr>
    </ng-template>

</p-table>
