import { TableColumns } from '../../../../../shared/models/table-columns.model';

export const utilisateursExternesColumns: TableColumns[] = [
    { field: 'indicator', header: 'indicator', type: 'shortTag', width: '28px' },
    { field: 'id', header: 'id' },
    { field: 'nom', header: 'nom' },
    { field: 'prenom', header: 'prenom' },
    { field: 'cip', header: 'cip' },
    { field: 'upn', header: 'upn' },
    { field: 'id_agol', header: 'id_agol' },
    { field: 'pole', header: 'pole' },
    { field: 'site', header: 'site' },
    { field: 'numero_vehicule', header: 'numero_vehicule' },
    { field: 'numero_tablette', header: 'numero_tablette' },
    { field: 'numero_cellulaire', header: 'numero_cellulaire' },
    { field: 'horaire', header: 'horaire' },
    { field: 'habilitation', header: 'habilitation' },
    { field: 'telephone', header: 'telephone' },
    { field: 'statut', header: 'statut', type: 'tag', width: '150px' },
    { field: 'cree_le', header: 'cree_le' },
    { field: 'modifie_par', header: 'modifie_par' },
    { field: 'modifie_le', header: 'modifie_le' },
    { field: 'adresse_personnelle', header: 'adresse_personnelle' },
    { field: 'telephone_residence', header: 'telephone_residence' },
    { field: 'titre_emploi', header: 'titre_emploi' },
    { field: 'roles', header: 'roles' },
    { field: 'nom_entreprise', header: 'nom_entreprise' },
    { field: 'matricule', header: 'matricule' },
    { field: 'date_fin', header: 'date_fin' },
];
