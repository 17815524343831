
<div class="demandeClientListContainer">
    <p-table #demandeClientDatatable
        [value]="currentData"
        [paginator]="true"
        [scrollable]="true"
        scrollHeight="100%"
        [columns]="cols"
        dataKey="numeroOrdre"
        [sortField]="sortField"
        [sortOrder]="sortOrder"
        [rowHover]="true"
        [(selection)]="selectedDemandes"
        [rows]="5"
        [globalFilterFields]="globalFilterFields"
        currentPageReportTemplate="Affiche de {first} à {last} sur {totalRecords} items" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[5,10,25,50,100,200]"
        >
     <ng-template pTemplate="caption">
            <div class="table__header">
                <div class="header__container">
                    <div class="container__search">
                        <i class="fas fa-search search__icon"></i>
                        <input type="text" class="search__input" pInputText size="30"
                            #globalFilter (input)="inputGlobalFilter($event)"
                            [placeholder]="'common.search' | translate" [attr.aria-placeholder]="'common.search' | translate">
                    </div>
                    <div class="container__clearFilter">
                        <app-erase-all-filter (clicked)="clearAllFilters()" [disabled]="!filtersApply"></app-erase-all-filter>
                    </div>
                </div>
                <div>
                    <div class="action">
                        <div class="action__spacer"></div>
                        <button pButton icon="fa fa-cogs"
                            [disabled]="selectedDemandes.length === 0 || selectedDemandes.length > 100"
                            class="action__button"
                            [label]="translatePrefix + 'boutons.soumettre-en-lot' | translate:{ value: selectedDemandes.length }"
                            (click)="openBatchUpdatesForm()">
                        </button>
                        <button pButton icon="fa fa-sync-alt" id="reload" class="action__button"
                            [label]="translatePrefix + 'boutons.reload' | translate" (click)="reload()">
                        </button>
                    </div>
                    <h6 class="selected-demande-warning" *ngIf="selectedDemandes.length > 100"> {{ translatePrefix +
                        'batch-update-max' | translate }}</h6>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns let-frozen="frozen">
            <tr>
                <td [attr.colspan]="5" pFrozenColumn [frozen]="true">
                    <h3>{{frozen ? getEmptyMessage() : getEmptyMessage()}}</h3>
                </td>
                <td [attr.colspan]="36">
                    <h3>{{columns.length}}</h3>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr class="headerRow">
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" pFrozenColumn [frozen]="col.frozen"
                    class="t__header" [class.border]="col.field === 'ordre.numeroOrdre'" [style]="{ 'min-width': col.width }">
                    <div class="header__container">
                        <ng-container *ngIf="col.field === 'checkbox' else baseHeaderTemplate">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </ng-container>

                        <ng-template #baseHeaderTemplate>
                            <span class="container__title">
                                {{col.header}}
                            </span>
                            <p-sortIcon *ngIf="col.filterType && col.filterType !== 'noFilter'" [field]="col.field"
                                [attr.aria-label]="'common.sort' | translate"
                                [attr.aria-label-desc]="'common.sortDesc' | translate"
                                [attr.aria-label-asc]="'common.sortAsc' | translate">
                            </p-sortIcon>
                        </ng-template>
                    </div>
                </th>
            </tr>
            <tr class="filterRow">
                <th *ngFor="let col of columns" class="t__header" pFrozenColumn [frozen]="col.frozen"
                [class.border]="col.field === 'ordre.numeroOrdre'" [style]="{ 'min-width': col.width }">
                    <ng-container [ngSwitch]="col.filterType">
                        <p-multiSelect *ngSwitchCase="'multi-select'" [(ngModel)]="filtersEntries[col.field]"
                            [filter]="false" [options]="tableFiltersItems[col.field]" optionLabel="name"
                            (onChange)="onFilterChange($event.value, col.field)" appendTo="body" [maxSelectedLabels]="1"
                            [selectedItemsLabel]="'common.selectedItems' | translate" class="p-column-filter fullWidth"
                            [showToggleAll]="true">
                        </p-multiSelect>

                        <input pInputText *ngSwitchCase="'text'" type="text" [(ngModel)]="filtersEntries[col.field]"
                            id="id-{{col.field}}"
                            [placeholder]="'common.filter' | translate" class="p-column-filter"
                            (input)="onFilterChange($event, col.field)"
                            [attr.aria-placeholder]="'common.filter' | translate"
                            [pTooltip]="translatePrefix + 'filterTooltip' | translate">

                        <p-calendar *ngSwitchCase="'calendar'" appendTo="body"
                            id="id-calendar-{{col.field}}"
                            (input)="onFilterChange($event, col.field)"
                            (onSelect)="onFilterChange($event, col.field)" [(ngModel)]="filtersEntries[col.field]"
                            (onClearClick)="onFilterChange('', col.field)" [showButtonBar]="true" [showIcon]="false"
                            styleClass="p-column-filter" [placeholder]="'common.filter' | translate"
                            [dateFormat]="calendarDateFormat" [readonlyInput]="false" class="p-column-filter">
                        </p-calendar>
                    </ng-container>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-demandeClient let-columns="columns">
            <tr class="p-selectable-row bodyRow" (dblclick)="openDemandeClient(demandeClient)" style="height:70px">
                <td *ngFor="let col of columns" [ngSwitch]="col.field.toLowerCase()" pFrozenColumn [frozen]="col.frozen"
                class="t__body" [class.border]="col.field === 'ordre.numeroOrdre'" [style]="{ 'min-width': col.width }">
                    <div class="body__container">
                        <ng-container *ngIf="col.field === 'checkbox' else baseBodyTemplate">
                            <p-tableCheckbox [value]="demandeClient"></p-tableCheckbox>
                        </ng-container>

                        <ng-template #baseBodyTemplate>
                            <div *ngSwitchCase="'ordre.numeroOrdre'.toLowerCase()" (click)="openDemandeClient(demandeClient)">
                                <a>{{getNestedValue(demandeClient, col.field)}}</a>
                            </div>

                            <ng-container *ngSwitchCase="'ordre.typeEngagement'.toLowerCase()">
                                <span
                                    *ngIf="demandeClient.ordre.typeEngagement !== null && demandeClient.ordre.typeEngagement !== ''">
                                    {{ commonTranslatePrefix + 'TypeEngagementEnum.' + getNestedValue(demandeClient, col.field)
                                    | translate }}
                                </span>
                            </ng-container>

                            <ng-container *ngSwitchCase="'ordre.remarqueAbregee'.toLowerCase()">
                                <span [innerHTML]="getNestedValue(demandeClient, col.field)"
                                    (click)="showRemarque(demandeClient.ordre.numeroOrdre)"></span>
                            </ng-container>

                            <ng-container *ngSwitchCase="'ordre.pieceJointeFlag'.toLowerCase()">
                                <p-button *ngIf="getNestedValue(demandeClient, col.field)" icon="fa fa-file"
                                    [label]="'demandes-client.common.PieceJointe' | translate" size="small" type="primary"
                                    (onClick)="openSapPiecesJointes(demandeClient.ordre.numeroOrdre)"></p-button>
                            </ng-container>

                            <p-button *ngSwitchCase="'wAB'.toLowerCase()" icon="fa fa-globe"
                                [label]="'demandes-client.common.WAB' | translate" size="small" type="primary"
                                (onClick)="openWAB(demandeClient.ordre.numeroOrdre)"></p-button>

                            <ng-container *ngSwitchCase="'ordre.nombreOperations'.toLowerCase()">
                                <span class="p-badge container__badge" *ngIf="getNestedValue(demandeClient, col.field) > 1"
                                    (click)="callOperations(demandeClient.ordre.numeroOrdre, $event)">{{
                                    getNestedValue(demandeClient,
                                    col.field) }}</span>
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                                <ng-container [ngSwitch]="col.type">
                                    <ng-container *ngSwitchCase="'date'">
                                        {{ getNestedValue(demandeClient, col.field) | date: col.format }}
                                    </ng-container>

                                    <ng-container *ngSwitchDefault>
                                        {{getNestedValue(demandeClient, col.field)}}
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <app-element-loader id="spinner" *ngIf="(isDemandesClientLoading$ | async) || (isDomaineDeValeurLoading$ | async)">
    </app-element-loader>

</div>
