import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalData, ModalType, ModalEntityType } from './generic-modal-data.model';

@Component({
    selector: 'app-generic-form-modal',
    templateUrl: './generic-modal.component.html',
    styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent {
    public title = '';
    public message = '';
    public formType: ModalType;
    public entity: any;
    public entityType: ModalEntityType | null;
    public canAddEditTime: boolean;
    public displayOutageNumber = false;
    public disabled = false;

    constructor(
        public dialogRef: MatDialogRef<GenericModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ModalData,
    ) {
        this.title = data.title;
        this.message = data.message;
        this.formType = data.formType;
        this.entity = data.entity !== undefined ? data.entity : {};
        this.entityType = data.entityType !== undefined ? data.entityType : null;
        this.canAddEditTime = data.canAddEditTime !== undefined ? data.canAddEditTime : true;
        this.displayOutageNumber = data.displayOutageNumber !== undefined ? data.displayOutageNumber : false;
    }

    public formTypeIsDelete(): boolean {
        return this.formType === ModalType.delete;
    }

    public formTypeIsSubmit(): boolean {
        return this.formType === ModalType.submit;
    }

    public formTypeIsInfo(): boolean {
        return this.formType === ModalType.info;
    }

    public entityTypeIsInfoStatus(): boolean {
        return this.entityType === ModalEntityType.infoOrderStatus;
    }

    public entityTypeIsTimesheet(): boolean {
        return this.entityType === ModalEntityType.timesheet;
    }

    public onClose(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.dialogRef.close(true);
    }

    public disableButton(value: boolean): void {
        this.disabled = value;
    }

    public hideButtons(): boolean {
        return (!this.entityTypeIsTimesheet() && !this.entityTypeIsInfoStatus());
    }
}
