import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
    ApproveTimesheetReport,
    ApproveTimesheetReportFail,
    ApproveTimesheetReportSuccess,
    ApproveTimesheetReportTech,
    ApproveTimesheetReportTechFail,
    ApproveTimesheetReportTechSuccess,
    LoadTimesheetReportById,
    LoadTimesheetReportByIdAndSiteName,
    LoadTimesheetReportByIdAndSiteNameFail,
    LoadTimesheetReportByIdAndSiteNameSuccess,
    LoadTimesheetReportByIdFail,
    LoadTimesheetReportByIdSuccess,
    RefuseTimesheetReport,
    RefuseTimesheetReportFail,
    RefuseTimesheetReportSuccess,
    RefuseTimesheetReportTech,
    RefuseTimesheetReportTechFail,
    RefuseTimesheetReportTechSuccess,
    RemarkTimesheetReport,
    RemarkTimesheetReportFail,
    RemarkTimesheetReportSuccess,
    TimesheetReportActionTypes,
} from '../actions/timesheet-reports.action';
import { ApiService } from '../../../ogiv-core/services';
import { TimesheetReport } from '../../../shared/models/database/timesheet-report.model';
import { AddOneAppNotification } from '../../../core/store/actions';
import { SuccessNotification, ErrorNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class TimesheetReportsEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    /************************************************/
    /******** General timesheet report by ent ********/
    /************************************************/
    public remarkTimesheetReport$ = createEffect(() => this.actions$.pipe(
        ofType<RemarkTimesheetReport>(TimesheetReportActionTypes.REMARK_TIMESHEET_REPORT),
        mergeMap((action: RemarkTimesheetReport) => {
            return this.apiService.put<TimesheetReport, any>(`${action.path}/rapports/rapport/${action.timesheetReportId}/remark`, {
                remark: action.remark
            }).pipe(
                map((timesheetReport: TimesheetReport) =>
                    new RemarkTimesheetReportSuccess(new TimesheetReport(timesheetReport))
                ),
                catchError((error: any) => of(new RemarkTimesheetReportFail(error))),
            );
        }),
    ));

    /**********************************************/
    /******** Load timesheet report by ent ********/
    /**********************************************/
    public loadOneTimesheetReport$ = createEffect(() => this.actions$.pipe(
        ofType<LoadTimesheetReportById>(TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID),
        switchMap((action: LoadTimesheetReportById) => {
            return this.apiService.get<TimesheetReport>(`${action.path}/rapports/rapport/${action.timesheetReportId}`).pipe(
                map((timesheetReport: TimesheetReport) =>
                    new LoadTimesheetReportByIdSuccess(new TimesheetReport(timesheetReport))
                ),
                catchError((error: any) => of(new LoadTimesheetReportByIdFail(error))),
            );
        }),
    ));

    /*************************************************/
    /******** Approve timesheet report by ent ********/
    /*************************************************/
    public approveTimesheetReport$ = createEffect(() => this.actions$.pipe(
        ofType<ApproveTimesheetReport>(TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT),
        switchMap((action: ApproveTimesheetReport) => {
            return this.apiService.put<TimesheetReport, any>(`${action.path}/rapports/rapport/${action.timesheetReportId}/approve`, {
                id: action.timesheetReportId
            }).pipe(
                map((timesheetReport: TimesheetReport) =>
                    new ApproveTimesheetReportSuccess(new TimesheetReport(timesheetReport))
                ),
                catchError((error: any) => of(new ApproveTimesheetReportFail(error))),
            );
        }),
    ));

    public approveTimesheetReportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<ApproveTimesheetReportSuccess>(TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_SUCCESS),
        mergeMap(
            () => [
                new AddOneAppNotification(new SuccessNotification({
                    summary: 'notifications.success',
                    detail: 'timesheet.reports.approve.success',
                }))
            ]
        ),
    ));

    public approveTimesheetReportFail$ = createEffect(() => this.actions$.pipe(
        ofType<ApproveTimesheetReportFail>(TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_FAIL),
        mergeMap(
            () => [
                new AddOneAppNotification(new ErrorNotification({
                    summary: 'notifications.error',
                    detail: 'timesheet.reports.approve.error',
                }))
            ]
        ),
    ));

    /************************************************/
    /******** Refuse timesheet report by ent ********/
    /************************************************/
    public refuseTimesheetReport$ = createEffect(() => this.actions$.pipe(
        ofType<RefuseTimesheetReport>(TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT),
        switchMap((action: RefuseTimesheetReport) => {
            return this.apiService.put<TimesheetReport, any>(`${action.path}/rapports/rapport/${action.timesheetReportId}/refuse`, {
                id: action.timesheetReportId,
                comment: action.comment,
                rejectedDays: action.rejectedDays,
            }).pipe(
                map((timesheetReport: TimesheetReport) =>
                    new RefuseTimesheetReportSuccess(new TimesheetReport(timesheetReport))
                ),
                catchError((error: any) => of(new RefuseTimesheetReportFail(error))),
            );
        }),
    ));

    public refuseTimesheetReportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<RefuseTimesheetReportSuccess>(TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_SUCCESS),
        mergeMap(
            () => [
                new AddOneAppNotification(new SuccessNotification({
                    summary: 'notifications.success',
                    detail: 'timesheet.reports.refuse.success',
                }))
            ]
        ),
    ));

    public refuseTimesheetReportFail$ = createEffect(() => this.actions$.pipe(
        ofType<RefuseTimesheetReportFail>(TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_FAIL),
        mergeMap(
            () => [
                new AddOneAppNotification(new ErrorNotification({
                    summary: 'notifications.error',
                    detail: 'timesheet.reports.refuse.error',
                }))
            ]
        ),
    ));

    /*****************************************************/
    /******** Load timesheet report by Technicien ********/
    /*****************************************************/
    public loadOneTimesheetReportIdAndSiteName$ = createEffect(() => this.actions$.pipe(
        ofType<LoadTimesheetReportByIdAndSiteName>(TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID_AND_SITE_NAME),
        switchMap((action: LoadTimesheetReportByIdAndSiteName) => {
            return this.apiService.get<TimesheetReport>(`${action.path}/rapports/rapport/${action.timesheetReportId}/${action.siteName}`).pipe(
                map((timesheetReport: TimesheetReport) =>
                    new LoadTimesheetReportByIdAndSiteNameSuccess(new TimesheetReport(timesheetReport))
                ),
                catchError((error: any) => of(new LoadTimesheetReportByIdAndSiteNameFail(error))),
            );
        }),
    ));

    /********************************************************/
    /******** Approve timesheet report by technicien ********/
    /********************************************************/
    public approveTimesheetReportTech$ = createEffect(() => this.actions$.pipe(
        ofType<ApproveTimesheetReportTech>(TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_TECH),
        mergeMap((action: ApproveTimesheetReportTech) => {
            return this.apiService.put<TimesheetReport, any>(`${action.path}/rapports/rapport/${action.timesheetReportId}/${action.site}/approve`, {}).pipe(
                map((timesheetReport: TimesheetReport) =>
                    new ApproveTimesheetReportTechSuccess(new TimesheetReport(timesheetReport))
                ),
                catchError((error: any) => of(new ApproveTimesheetReportTechFail(error))),
            );
        }),
    ));

    public approveTimesheetReportTechSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<ApproveTimesheetReportTechSuccess>(TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_TECH_SUCCESS),
        mergeMap(
            () => [
                new AddOneAppNotification(new SuccessNotification({
                    summary: 'notifications.success',
                    detail: 'timesheet.reports.approve.success',
                }))
            ]
        ),
    ));

    public approveTimesheetReportTechFail$ = createEffect(() => this.actions$.pipe(
        ofType<ApproveTimesheetReportTechFail>(TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_TECH_FAIL),
        mergeMap(
            () => [
                new AddOneAppNotification(new ErrorNotification({
                    summary: 'notifications.error',
                    detail: 'timesheet.reports.approve.error',
                }))
            ]
        ),
    ));

    /*******************************************************/
    /******** Refuse timesheet report by technicien ********/
    /*******************************************************/
    public refuseTimesheetReportTech$ = createEffect(() => this.actions$.pipe(
        ofType<RefuseTimesheetReportTech>(TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_TECH),
        mergeMap((action: RefuseTimesheetReportTech) => {
            return this.apiService.put<TimesheetReport, any>(`${action.path}/rapports/rapport/${action.timesheetReportId}/${action.site}/refuse`, {
                id: action.timesheetReportId,
                comment: action.comment,
            }).pipe(
                map((timesheetReport: TimesheetReport) =>
                    new RefuseTimesheetReportTechSuccess(new TimesheetReport(timesheetReport))
                ),
                catchError((error: any) => of(new RefuseTimesheetReportTechFail(error))),
            );
        }),
    ));

    public refuseTimesheetReportTechSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<RefuseTimesheetReportTechSuccess>(TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_TECH_SUCCESS),
        mergeMap(
            () => [
                new AddOneAppNotification(new SuccessNotification({
                    summary: 'notifications.success',
                    detail: 'timesheet.reports.refuse.success',
                }))
            ]
        ),
    ));

    public refuseTimesheetReportTechFail$ = createEffect(() => this.actions$.pipe(
        ofType<RefuseTimesheetReportTechFail>(TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_TECH_FAIL),
        mergeMap(
            () => [
                new AddOneAppNotification(new ErrorNotification({
                    summary: 'notifications.error',
                    detail: 'timesheet.reports.refuse.error',
                }))
            ]
        ),
    ));
}
