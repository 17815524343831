export interface UnitCode {
    id: number;
    code: string;           // max-length 255
    description: string;    // max-length 255
    status: UnitCodeStatus;
    detail_requis: boolean | null;
    date_inactif?: Date;
    createdBy: string;       // max-length 255
    createdDate: Date;
    modifiedBy: string;       // max-length 255
    modifiedDate: Date;
}

export enum UnitCodeStatus {
    active = 'Actif',
    inactive = 'Inactif',
}
