import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonUiModule } from '@ngxhq/common-ui';
import { ThemeHqCommonUiSupportModule } from '@ngxhq/theme-ngxhq';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../../../shared/shared.module';
import { progressReportsComponents } from './components';
import { reportsVendorsModules } from '../../reports-vendors-modules';
import { ProgressReportsRoutingModule } from './progress-reports-routing.module';

@NgModule({
    imports: [
        CommonModule,
        CommonUiModule,
        SharedModule,
        ThemeHqCommonUiSupportModule,
        TranslateModule,

        // Vendors
        ...reportsVendorsModules,

        // Router
        ProgressReportsRoutingModule,
    ],
    declarations: [
        ...progressReportsComponents,
    ],
})
export class ProgressReportsModule {
}
