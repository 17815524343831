import { Component } from '@angular/core';

@Component({
    selector: 'app-demandes-client-basic',
    templateUrl: './demandes-client-basic.component.html',
    styleUrls: ['./demandes-client-basic.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'demandesClientBasic'
    }
})
export class DemandesClientBasicComponent {
    public translatePrefix = 'demandes-client.basic.';
}
