import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { ReportsState, reportsStoreReducers } from './reducers';

export const REPORTS_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ReportsState>>('Reports reducers');

export function getReportsReducers(): ActionReducerMap<ReportsState> {
    return reportsStoreReducers;
}
