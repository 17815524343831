<app-generic-module-header
    [moduleLogo]="'far fa-file-alt'"
    [moduleTitle]="'timesheet.reports.groupLeader.summary.title' | translate"
    [buttonTitle]="'timesheet.reports.groupLeader.summary.buttons.reload' | translate | uppercase"
    (buttonClick)="reload()"
></app-generic-module-header>

<app-report-filters
    [statuses]="statuses"
    [searchColumn]="cols"
    [columnTranslatePrefix]="'timesheet.reports.summary.'"
    [localStorageName]="localStorageName"
    (filter)="applyFilter($event)"
    ></app-report-filters>

<h3 class="reportTitle">
    {{ 'timesheet.reports.groupLeader.summary.filteredValues' | translate }}
</h3>

<app-timesheet-report-table *ngLet="timesheetRepportSummary$ | async as timesheetRepportSummary" [translatePrefix]="translatePrefix"
    [coreTranslatePrefix]="coreTranslatePrefix" [cols]="cols" [reports]="timesheetRepportSummary" [sortField]="sortField"
    [sortOrder]="sortOrder" (goToReport)="onGoToReport($event)" (displayRemark)="onDisplayRemarks($event)">
</app-timesheet-report-table>

<app-element-loader *ngIf="timesheetRepportSummaryLoading$ | async" [size]="100" [displayLabel]="true"></app-element-loader>
