import { Action } from '@ngrx/store';

import { DemandesClientBatchUpdatesResults } from '../../../shared/models/demande-client-batch-updates-results';
import { DemandeClient, UpdateDemandeClient, UpdateDemandeClientResult } from '../../../shared/models/database/demande-client.model';

/**
 * Types
 */
export enum DemandesClientActionTypes {
    DEMANDES_CLIENT_SEARCH = '[DemandesClient] Search',
    DEMANDES_CLIENT_SEARCH_SUCCESS = '[DemandesClient] Search Success',
    DEMANDES_CLIENT_SEARCH_FAIL = '[DemandesClient] Search Fail',
    DEMANDES_CLIENT_BATCH_UPDATES = '[DemandesClient] Batch Updates',
    DEMANDES_CLIENT_BATCH_UPDATES_SUCCESS = '[DemandesClient] Batch Updates Success',
    DEMANDES_CLIENT_BATCH_UPDATES_FAIL = '[DemandesClient] Batch Updates Fail',
    DEMANDES_CLIENT_BATCH_UPDATES_FAIL_RESULT = '[DemandesClient] Batch Updates Fail With Result',
    DEMANDES_CLIENT_BATCH_UPDATES_CLEAR_RESULTS = '[DemandesClient] Batch Updates Clear Results',

    LOAD_DEMANDE_CLIENT_BY_ID = '[DemandesClient] Load Demande Client By ID',
    LOAD_DEMANDE_CLIENT_BY_ID_FAIL = '[DemandesClient] Load Demande Client By ID Fail',
    LOAD_DEMANDE_CLIENT_BY_ID_SUCCESS = '[DemandesClient] Load Demande Client By ID Success',

    UPDATE_DEMANDE_CLIENT = '[DemandesClient] Update One Demande Client',
    UPDATE_DEMANDE_CLIENT_FAIL = '[DemandesClient] Update One Demande Client Fail',
    UPDATE_DEMANDE_CLIENT_FAIL_WITH_RESULT = '[DemandesClient] Update One Demande Client Fail With Result',
    UPDATE_DEMANDE_CLIENT_SUCCESS = '[DemandesClient] Update One Demande Client Success',
    UPDATE_DEMANDE_CLIENT_CLEAR_FAIL_RESULT = '[DemandesClient] Update One Demande Client Clear Fail Result',
}

/**
 * LOAD
 */

export class DemandesClientSearch implements Action {
    readonly type = DemandesClientActionTypes.DEMANDES_CLIENT_SEARCH;
    constructor(public demandesClientFilter: any[]) { }
}

export class DemandesClientSearchSuccess implements Action {
    readonly type = DemandesClientActionTypes.DEMANDES_CLIENT_SEARCH_SUCCESS;
    constructor(public demandesClient: DemandeClient[]) { }
}

export class DemandesClientSearchFail implements Action {
    readonly type = DemandesClientActionTypes.DEMANDES_CLIENT_SEARCH_FAIL;
    constructor(public error: any) { }
}

export class LoadDemandeClientById implements Action {
    readonly type = DemandesClientActionTypes.LOAD_DEMANDE_CLIENT_BY_ID;
    constructor(public demandeClientNumeroOrdre: string) { }
}

export class LoadDemandeClientByIdSuccess implements Action {
    readonly type = DemandesClientActionTypes.LOAD_DEMANDE_CLIENT_BY_ID_SUCCESS;
    constructor(public demandeClient: DemandeClient) { }
}

export class LoadDemandeClientByIdFail implements Action {
    readonly type = DemandesClientActionTypes.LOAD_DEMANDE_CLIENT_BY_ID_FAIL;
    constructor(public error: any) { }
}

/**
 * BATCH UPDATE
 */
export class DemandesClientBatchUpdates implements Action {
    readonly type = DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES;
    constructor(public updateDemandeClient: UpdateDemandeClient) { }
}

export class DemandesClientBatchUpdatesSuccess implements Action {
    readonly type = DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES_SUCCESS;
    constructor(public batchUpdatesParameters: UpdateDemandeClient, public results: DemandesClientBatchUpdatesResults) { }
}

export class DemandesClientBatchUpdatesFail implements Action {
    readonly type = DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES_FAIL;
    constructor(public error: any) { }
}

export class DemandesClientBatchUpdatesFailResult implements Action {
    readonly type = DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES_FAIL_RESULT;
    constructor(public batchUpdatesParameters: UpdateDemandeClient, public results: DemandesClientBatchUpdatesResults) { }
}

export class DemandesClientBatchUpdatesClearResults implements Action {
    readonly type = DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES_CLEAR_RESULTS;
    constructor() { }
}

/**
 * UPDATE
 */
export class UpdateOneDemandeClient implements Action {
    readonly type = DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT;
    constructor(public demandeClient: UpdateDemandeClient, public numeroOrdre: string) { }
}

export class UpdateOneDemandeClientSuccess implements Action {
    readonly type = DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT_SUCCESS;
    constructor(public updateDemandeClientResult: UpdateDemandeClientResult) { }
}

export class UpdateOneDemandeClientFail implements Action {
    readonly type = DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT_FAIL;
    constructor(public error: any) { }
}

export class UpdateOneDemandeClientFailWithResult implements Action {
    readonly type = DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT_FAIL_WITH_RESULT;
    constructor(public updateDemandeClientResult: UpdateDemandeClientResult) { }
}

export class UpdateOneDemandeClientClearFailResult implements Action {
    readonly type = DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT_CLEAR_FAIL_RESULT;
    constructor() { }
}

export type DemandesClientAction =
    | DemandesClientSearch
    | DemandesClientSearchSuccess
    | DemandesClientSearchFail
    | LoadDemandeClientById
    | LoadDemandeClientByIdSuccess
    | LoadDemandeClientByIdFail
    | DemandesClientBatchUpdates
    | DemandesClientBatchUpdatesSuccess
    | DemandesClientBatchUpdatesFail
    | DemandesClientBatchUpdatesFailResult
    | DemandesClientBatchUpdatesClearResults
    | UpdateOneDemandeClient
    | UpdateOneDemandeClientSuccess
    | UpdateOneDemandeClientFail
    | UpdateOneDemandeClientFailWithResult
    | UpdateOneDemandeClientClearFailResult;
