import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { UserRole } from '../../../shared/models/user-roles.model';
import { RoleGuard } from '../../../core/guards/role.guard';
import { LoadPageGuard } from '../../../guards/page-selector.guard';
import { PageSelector } from '../../../shared/models/page-selector.model';
import { ReportsComponent } from '../../components/reports.component';
import { ProgressReportsListComponent } from './components/progress-reports-list/progress-reports-list.component';

/**
 * Voir dans les data PageSelector est requis si on met le "LoadPageGuard"
*/
const routes: Routes = [
    {
        path: '',
        data: {
            pathChoice: AlternativePath.ent,
            pageSelector: [PageSelector.progressReport],
            roles: [UserRole.support, UserRole.pilote, UserRole.chefgroupe, UserRole.entrepreneur]
        },
        component: ReportsComponent,
        canActivate: [RoleGuard, LoadPageGuard],
        children: [
            {
                path: '',
                component: ProgressReportsListComponent,
            }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProgressReportsRoutingModule {
}
