import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OrderStatus, getOrderCss } from '../../../../../shared/models/database/order-status.enum';

@Component({
    selector: 'app-tab-panel-header',
    templateUrl: './tab-panel-header.component.html',
    styleUrls: ['./tab-panel-header.component.scss']
})
export class TabPanelHeaderComponent implements OnChanges {
    public status = '';
    public rawStatus = '';

    @Input() public headerLogo = '';
    @Input() public headerText = '';

    @Input() public orderStatus: string | null;
    @Input() public isLock = false;

    @Input() public taskValue = 0;
    @Input() public displayBubbleTask = true;

    @Input() public timeValue = 0;
    @Input() public displayBubbleTime = true;

    public constructor() { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.orderStatus && changes.orderStatus.currentValue) {
            this.rawStatus = changes.orderStatus.currentValue;
            this.status = getOrderCss(changes.orderStatus.currentValue);
        }
    }

    public isOrderRefused(value: string): boolean {
        return value === OrderStatus.refused;
    }

}
