<div class="header">
    <h1 class="header__title">
        <i class="{{ moduleLogo }} space" ></i>
        <span>
            {{ moduleTitle }}
        </span>
    </h1>

    <div *ngIf="!hideButton" class="header__action">
        <div class="action__buttons">
            <button #button mat-raised-button type="button" class="buttons__button" (click)="reload()">
                <i class="{{ buttonLogo }} button__logo"></i>
                <span>
                    {{ buttonTitle }}
                </span>
            </button>
        </div>
    </div>
</div>
