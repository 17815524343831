import { TableColumns } from '../../../../../shared/models/table-columns.model';

export const gestionCodeSAPColumns: TableColumns[] = [
    { field: 'fiche_service', header: 'ficheService', type: 'string', isEditable: false },
    { field: 'service', header: 'service', type: 'string', isEditable: false },
    { field: 'code', header: 'code', type: 'string', isEditable: false },
    { field: 'emploi', header: 'emploi' },
    { field: 'ratio', header: 'emploiRatio', type: 'number', width: '101px', isDynamic: true, dependOnField: 'emploi' },
    { field: 'transport', header: 'transport' },
    { field: 'repas', header: 'repas' },
    { field: 'equipement', header: 'equipement' },
    { field: 'unite_travail', header: 'uniteTravail' },
    { field: 'article', header: 'article' },
    { field: 'article_unite', header: 'articleUnite', type: 'string', width: '101px', isDynamic: true, dependOnField: 'article' },
    { field: 'article_nature_comptable', header: 'articleNatureComptable', type: 'number', width: '101px', isDynamic: true, dependOnField: 'article' },
    { field: 'cree_le', header: 'creeLe', type: 'date', format: 'dd-MM-yyyy', filterMatchMode: 'date' },
    { field: 'modifie_le', header: 'modifieLe', type: 'date', format: 'dd-MM-yyyy', filterMatchMode: 'date' },
    { field: 'modifie_par', header: 'modifiePar', type: 'string', isEditable: false },
    { field: 'statut', header: 'statut', type: 'statut' },
];
