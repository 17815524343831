import { Action } from '@ngrx/store';

import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { BillingReport, NewBillingReport, UpdatedBillingReport } from '../../../shared/models/database/billing-report.model';

/**
 * Types
 */
export enum BillingReportActionType {
    LOAD_BILLING_REPORTS = '[Billing Report] Load Billing Reports',
    LOAD_BILLING_REPORTS_FAIL = '[Billing Report] Load Billing Reports Fail',
    LOAD_BILLING_REPORTS_SUCCESS = '[Billing Report] Load Billing Reports Success',

    LOAD_BILLING_REPORT_BY_ID = '[Billing Report] Load Billing Report BY ID',
    LOAD_BILLING_REPORT_BY_ID_FAIL = '[Billing Report] Load Billing Report BY ID Fail',
    LOAD_BILLING_REPORT_BY_ID_NO_RESULTS = '[Billing Report] Load Billing Report BY ID No Result',
    LOAD_BILLING_REPORT_BY_ID_SUCCESS = '[Billing Report] Load Billing Report BY ID Success',

    CREATE_BILLING_REPORT = '[Billing Report] Create Billing Report',
    CREATE_BILLING_REPORT_FAIL = '[Billing Report] Create Billing Report Fail',
    CREATE_BILLING_REPORT_SUCCESS = '[Billing Report] Create Billing Report Success',

    UPDATE_ONE_BILLING_REPORT = '[Billing Report] Update One Billing Report',
    UPDATE_ONE_BILLING_REPORT_FAIL = '[Billing Report] Update One Billing Report Fail',
    UPDATE_ONE_BILLING_REPORT_SUCCESS = '[Billing Report] Update One Billing Report Success',

    DELETE_BILLING_REPORT = '[Billing Report] Delete Billing Report',
    DELETE_BILLING_REPORT_FAIL = '[Billing Report] Delete Billing Report Fail',
    DELETE_BILLING_REPORT_SUCCESS = '[Billing Report] Delete Billing Report Success',

    EXECUTE_ACTION_TO_BILLING_REPORT = '[Billing Report] Execute Action to Billing Report',
    EXECUTE_ACTION_TO_BILLING_REPORT_FAIL = '[Billing Report] Execute Action to Billing Report Fail',
    EXECUTE_ACTION_TO_BILLING_REPORT_SUCCESS = '[Billing Report] Execute Action to Billing Report Success',
}

export enum BillingReportExecutionAction {
    VALIDATE = 'approve',
    REFUSE = 'refuse',
    SET_EXPORTED = 'export'
}

/**
 * LOAD
 */
export class LoadBillingReports implements Action {
    readonly type = BillingReportActionType.LOAD_BILLING_REPORTS;
    constructor(public path: AlternativePath) { }
}

export class LoadBillingReportsSuccess implements Action {
    readonly type = BillingReportActionType.LOAD_BILLING_REPORTS_SUCCESS;
    constructor(public billingReports: BillingReport[]) { }
}

export class LoadBillingReportsFail implements Action {
    readonly type = BillingReportActionType.LOAD_BILLING_REPORTS_FAIL;
    constructor(public error: any) { }
}

export class LoadBillingReportById implements Action {
    readonly type = BillingReportActionType.LOAD_BILLING_REPORT_BY_ID;
    constructor(public billingReportId: number, public path: AlternativePath) { }
}

export class LoadBillingReportByIdSuccess implements Action {
    readonly type = BillingReportActionType.LOAD_BILLING_REPORT_BY_ID_SUCCESS;
    constructor(public billingReport: BillingReport) { }
}

export class LoadBillingReportByIdFail implements Action {
    readonly type = BillingReportActionType.LOAD_BILLING_REPORT_BY_ID_FAIL;
    constructor(public error: any) { }
}

export class LoadBillingReportByIdNoResult implements Action {
    readonly type = BillingReportActionType.LOAD_BILLING_REPORT_BY_ID_NO_RESULTS;
}

/**
 * CREATE
 */
export class CreateBillingReport implements Action {
    readonly type = BillingReportActionType.CREATE_BILLING_REPORT;
    constructor(public billingReport: NewBillingReport, public path: AlternativePath) { }
}

export class CreateBillingReportSuccess implements Action {
    readonly type = BillingReportActionType.CREATE_BILLING_REPORT_SUCCESS;
    constructor(public billingReport: BillingReport) { }
}

export class CreateBillingReportFail implements Action {
    readonly type = BillingReportActionType.CREATE_BILLING_REPORT_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneBillingReport implements Action {
    readonly type = BillingReportActionType.UPDATE_ONE_BILLING_REPORT;
    constructor(public billingReport: UpdatedBillingReport, public billingReportId: number, public path: string) { }
}

export class UpdateOneBillingReportSuccess implements Action {
    readonly type = BillingReportActionType.UPDATE_ONE_BILLING_REPORT_SUCCESS;
    constructor(public billingReport: BillingReport) { }
}

export class UpdateOneBillingReportFail implements Action {
    readonly type = BillingReportActionType.UPDATE_ONE_BILLING_REPORT_FAIL;
    constructor(public error: any) { }
}

/**
 * DELETE
 */
export class DeleteBillingReport implements Action {
    readonly type = BillingReportActionType.DELETE_BILLING_REPORT;
    constructor(public billingReportId: number, public path: AlternativePath) { }
}

export class DeleteBillingReportSuccess implements Action {
    readonly type = BillingReportActionType.DELETE_BILLING_REPORT_SUCCESS;
    constructor(public billingReportId: number) { }
}

export class DeleteBillingReportFail implements Action {
    readonly type = BillingReportActionType.DELETE_BILLING_REPORT_FAIL;
    constructor(public error: any) { }
}

/**
 * Execute Action
 */
export class ExecuteActionToBillingReport implements Action {
    readonly type = BillingReportActionType.EXECUTE_ACTION_TO_BILLING_REPORT;
    constructor(public billingReportId: number, public comment: string, public action: string, public pathChoice: AlternativePath, public showNotification: boolean) { }
}

export class ExecuteActionToBillingReportSuccess implements Action {
    readonly type = BillingReportActionType.EXECUTE_ACTION_TO_BILLING_REPORT_SUCCESS;
    constructor(public billingReport: BillingReport, public action: string, public showNotification: boolean) { }
}

export class ExecuteActionToBillingReportFail implements Action {
    readonly type = BillingReportActionType.EXECUTE_ACTION_TO_BILLING_REPORT_FAIL;
    constructor(public error: any, public action: string) { }
}

export type BillingReportAction =
    | LoadBillingReports
    | LoadBillingReportsSuccess
    | LoadBillingReportsFail
    | LoadBillingReportById
    | LoadBillingReportByIdNoResult
    | LoadBillingReportByIdSuccess
    | LoadBillingReportByIdFail
    | CreateBillingReport
    | CreateBillingReportSuccess
    | CreateBillingReportFail
    | UpdateOneBillingReport
    | UpdateOneBillingReportSuccess
    | UpdateOneBillingReportFail
    | DeleteBillingReport
    | DeleteBillingReportSuccess
    | DeleteBillingReportFail
    | ExecuteActionToBillingReport
    | ExecuteActionToBillingReportSuccess
    | ExecuteActionToBillingReportFail;
