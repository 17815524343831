import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { SelectItem, TreeNode } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProgressReportSpecific } from '../../../../../shared/models/database/progress-report-specific.model';
import { getAllProgressReportsSpecific, getProgressReportsSpecificDateOfData } from '../../../../store/selectors/progress-reports-specific.selector';
import { LoadProgressReportsSpecific } from '../../../../store/actions/progress-reports-specific.actions';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { ProgressReportGlobal } from '../../../../../shared/models/database/progress-report-global.model';
import { ProgressReportSpecificExpanded } from '../../../../../shared/models/progress-report-specific-expanded.model';
import { ProgressReportSpecificSavedFilters } from '../../../../../shared/models/progress-report-specific-saved-filters.model';
import { ReportsState } from '../../../../store/reducers';
import { getAllProgressReportsGlobal } from '../../../../store/selectors';
import moment from 'moment';
import { TreeTable } from 'primeng/treetable';

@Component({
    selector: 'app-progress-reports-specific',
    templateUrl: './progress-reports-specific.component.html',
    styleUrls: ['./progress-reports-specific.component.scss']
})
export class ProgressReportsSpecificComponent implements OnInit, OnDestroy {
    @Input() public pathChoice: AlternativePath;
    @ViewChild('treetable') treetable: TreeTable;

    public progressReportsGlobal$: Observable<ProgressReportGlobal[]> = this.store.pipe(
        select(getAllProgressReportsGlobal)
    );
    public dateOfData$: Observable<Date | null> = this.store.pipe(
        select(getProgressReportsSpecificDateOfData)
    );
    public progressReportsSpecific$: Observable<ProgressReportSpecific[]> = this.store.pipe(
        select(getAllProgressReportsSpecific)
    );

    public progressReportsGlobal: ProgressReportGlobal[];
    public dateOfData: string;
    public progressReportsSpecific: ProgressReportSpecific[];
    public treenodes: TreeNode[] = [];
    public treetableColumns: any[];
    public specificReportForm: FormGroup;

    // Dropdowns used to filter the treetable
    public billingTypeItems: SelectItem[] = [];
    public siteItems: SelectItem[] = [];
    public orderItems: SelectItem[] = [];
    public accountingEntryItems: SelectItem[] = [];
    public executedByItems: SelectItem[] = [];
    public assignedByItems: SelectItem[] = [];
    public weekItems: SelectItem[] = [];

    private subscriptions = new Subscription;
    private progressReportsSpecificFiltered: ProgressReportSpecific[];
    private expandedNodes: ProgressReportSpecificExpanded[] = [];
    private localStorageName = 'progress_reports_specific_saved_filters';
    private savedFilters: ProgressReportSpecificSavedFilters;
    private dropdownFirstString = this.translateService.instant('progressReport.specificReport.dropdownSelectAStatus');

    constructor(
        public translateService: TranslateService,
        protected readonly store: Store<ReportsState>,
    ) {
        this.createSpecificReportForm();
    }

    ngOnInit(): void {
        this.loadProgressReportsSpecific();
        this.watchProgressReportsGlobal();
        this.watchDateOfData();
        this.watchProgressReportSpecific();
    }

    private createSpecificReportForm(): void {
        this.specificReportForm = new FormGroup({
            status: new FormControl(''),
            billingType: new FormControl([]),
            site: new FormControl([]),
            order: new FormControl([]),
            accountingEntry: new FormControl([]),
            executedBy: new FormControl([]),
            assignedBy: new FormControl([]),
            week: new FormControl([]),
        });
    }

    private loadProgressReportsSpecific(): void {
        this.store.dispatch(new LoadProgressReportsSpecific('', this.pathChoice));
    }

    private watchProgressReportsGlobal(): void {
        this.subscriptions.add(this.progressReportsGlobal$.pipe(
            tap((reports: ProgressReportGlobal[]) => {
                if (reports) {
                    this.progressReportsGlobal = reports;
                    this.addFirstItemStatutDropdown();
                    this.setStatusValue();
                }
            })
        ).subscribe());
    }

    private watchDateOfData(): void {
        this.subscriptions.add(this.dateOfData$.pipe(
            tap((date: Date | null) => {
                this.dateOfData = date ? moment(date).format('YYYY-MM-DD hh:mm A') : '';
            })
        ).subscribe());
    }

    private addFirstItemStatutDropdown(): void {
        const newEmptyElement: ProgressReportGlobal = ({
            name: this.dropdownFirstString,
            prescriptionCount: 0,
            sortOrder: 0
        });
        this.progressReportsGlobal = [newEmptyElement, ...this.progressReportsGlobal];
    }

    private setStatusValue(): void {
        this.specificReportForm.controls.status.setValue(this.progressReportsGlobal[0]);
    }

    private watchProgressReportSpecific(): void {
        this.subscriptions.add(this.progressReportsSpecific$.pipe(
            tap((reports: ProgressReportSpecific[]) => {
                if (reports) {
                    // Move "null" elements at the end of the array to display them last in the treetable.
                    const nullReports = reports.filter(x => x.postElect == null);
                    reports = reports.filter(x => x.postElect !== null);
                    reports.push(...nullReports);

                    this.progressReportsSpecific = reports;

                    this.setTreetableColumns();
                    this.populateDropdowns();
                    this.createTreeNodes(this.progressReportsSpecific);
                    this.reloadSavedFilters();
                }
            })
        ).subscribe());
    }

    private setTreetableColumns(): void {
        this.treetableColumns = [
            { field: 'name', header: this.translateService.instant('progressReport.specificReport.searchByText') },
        ];
    }

    private populateDropdowns(): void {
        // billingType
        const uniqueBillingTypes = this.progressReportsSpecific.map(item => item.billingType)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.billingTypeItems = this.getSelectItemsFromArray(uniqueBillingTypes);

        // site
        const uniqueSites = this.progressReportsSpecific.map(item => item.site)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.siteItems = this.getSelectItemsFromArray(uniqueSites);

        // order
        const uniqueOrders = this.progressReportsSpecific.map(item => item.order)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.orderItems = this.getSelectItemsFromArray(uniqueOrders);

        // accountingEntry
        const uniqueAccountingEntries = this.progressReportsSpecific.map(item => item.accountingEntry)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.accountingEntryItems = this.getSelectItemsFromArray(uniqueAccountingEntries);

        // executedBy
        const uniqueExecutedBy = this.progressReportsSpecific.map(item => item.executedBy)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.executedByItems = this.getSelectItemsFromArray(uniqueExecutedBy);

        // assignedBy
        const uniqueAssignedBy = this.progressReportsSpecific.map(item => item.assignedBy)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.assignedByItems = this.getSelectItemsFromArray(uniqueAssignedBy);

        // week
        const uniqueWeeks = this.progressReportsSpecific.map(item => item.week)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.weekItems = this.getSelectItemsFromArray(uniqueWeeks, true);
    }

    private createTreeNodes(report: ProgressReportSpecific[]): void {
        this.saveExpandedItems();

        this.treenodes = [];

        const dataGroupedByPostElect = this.groupBy(report, 'postElect');
        for (const postElectIndex of Object.keys(dataGroupedByPostElect)) {
            const dataGroupedByLine = this.groupBy(dataGroupedByPostElect[postElectIndex], 'line');
            const childrenLineNodes: TreeNode[] = [];

            for (const lineIndex of Object.keys(dataGroupedByLine)) {
                const dataGroupedByWorkType = this.groupBy(dataGroupedByLine[lineIndex], 'workType');
                const childrenWorkTypeNodes: TreeNode[] = [];

                for (const workTypeIndex of Object.keys(dataGroupedByWorkType)) {
                    const dataGroupedByCodeToDisplay = this.groupBy(dataGroupedByWorkType[workTypeIndex], 'codeToDisplay');
                    const childrenCodeToDisplayNodes: TreeNode[] = [];

                    for (const codeToDisplayIndex of Object.keys(dataGroupedByCodeToDisplay)) {
                        const dataGroupedByWeek = this.groupBy(dataGroupedByCodeToDisplay[codeToDisplayIndex], 'week');
                        const childrenWeekNodes: TreeNode[] = [];

                        this.createWeekTreeNode(dataGroupedByWeek, childrenWeekNodes);

                        childrenCodeToDisplayNodes.push({
                            expanded: this.expandedNodes.some(
                                x => x.postElect === postElectIndex
                                    && x.line === lineIndex
                                    && x.workType === workTypeIndex
                                    && x.codeToDisplay === codeToDisplayIndex),
                            data: { name: codeToDisplayIndex, prescriptionCount: dataGroupedByCodeToDisplay[codeToDisplayIndex][0].totalDisplayedCode },
                            children: childrenWeekNodes
                        });
                    }

                    childrenWorkTypeNodes.push({
                        expanded: this.expandedNodes.some(
                            x => x.postElect === postElectIndex
                                && x.line === lineIndex
                                && x.workType === workTypeIndex
                                && x.codeToDisplay === undefined),
                        data: { name: workTypeIndex, prescriptionCount: dataGroupedByWorkType[workTypeIndex][0].totalWorkType },
                        children: childrenCodeToDisplayNodes
                    });
                }

                childrenLineNodes.push({
                    expanded: this.expandedNodes.some(
                        x => x.postElect === postElectIndex
                            && x.line === lineIndex
                            && x.workType === undefined
                            && x.codeToDisplay === undefined),
                    data: { name: lineIndex, prescriptionCount: dataGroupedByLine[lineIndex][0].totalLine },
                    children: childrenWorkTypeNodes
                });
            }

            const postElectNodes: TreeNode = {
                expanded: this.expandedNodes.some(
                    x => x.postElect === postElectIndex
                        && x.line === undefined
                        && x.workType === undefined
                        && x.codeToDisplay === undefined),
                data: { name: postElectIndex, prescriptionCount: dataGroupedByPostElect[postElectIndex][0].totalPosteElect },
                children: childrenLineNodes
            };

            this.treenodes.push(postElectNodes);
        }
    }

    private createWeekTreeNode(dataGroupedByWeek: any, childrenWeekNodes: TreeNode<any>[]): void {
        for (const weekIndex of Object.keys(dataGroupedByWeek)) {
            childrenWeekNodes.push({
                data: {
                    name: weekIndex,
                    prescriptionCount: dataGroupedByWeek[weekIndex][0].totalWeek,
                    enterpriseAbbreviation: dataGroupedByWeek[weekIndex][0].enterpriseAbbreviation,
                    objectId: dataGroupedByWeek[weekIndex][0].objectId,
                    order: dataGroupedByWeek[weekIndex][0].order,
                    accountingEntry: dataGroupedByWeek[weekIndex][0].accountingEntry,
                    workType: dataGroupedByWeek[weekIndex][0].workType,
                    code: dataGroupedByWeek[weekIndex][0].code,
                    editedCode: dataGroupedByWeek[weekIndex][0].editedCode,
                    codeToDisplay: dataGroupedByWeek[weekIndex][0].codeToDisplay,
                    status: dataGroupedByWeek[weekIndex][0].status,
                    billingType: dataGroupedByWeek[weekIndex][0].billingType,
                    site: dataGroupedByWeek[weekIndex][0].site,
                    postElect: dataGroupedByWeek[weekIndex][0].postElect,
                    line: dataGroupedByWeek[weekIndex][0].line,
                    executedBy: dataGroupedByWeek[weekIndex][0].executedBy,
                    assignedBy: dataGroupedByWeek[weekIndex][0].assignedBy,
                    executionDate: dataGroupedByWeek[weekIndex][0].executionDate,
                    week: dataGroupedByWeek[weekIndex][0].week,
                    totalPosteElect: dataGroupedByWeek[weekIndex][0].totalPosteElect,
                    totalLine: dataGroupedByWeek[weekIndex][0].totalLine,
                    totalWorkType: dataGroupedByWeek[weekIndex][0].totalWorkType,
                    totalDisplayedCode: dataGroupedByWeek[weekIndex][0].totalDisplayedCode,
                    totalWeek: dataGroupedByWeek[weekIndex][0].totalWeek
                },
            });
        }
    }

    private saveExpandedItems(): void {
        /* eslint-disable  */

        this.treenodes.forEach(postElect => {
            let expandedNode: ProgressReportSpecificExpanded = ({
                postElect: postElect.data.name
            });

            this.addOrRemovePostElectNode(postElect, expandedNode);

            if (postElect.children && postElect.children.length > 0) {
                postElect.children.forEach(line => {
                    expandedNode = ({
                        postElect: postElect.data.name,
                        line: line.data.name,
                    });

                    this.addOrRemoveLineNode(line, expandedNode);

                    if (line.children && line.children.length > 0) {
                        line.children.forEach(workType => {
                            expandedNode = ({
                                postElect: postElect.data.name,
                                line: line.data.name,
                                workType: workType.data.name,
                            });

                            this.addOrRemoveWorkTypeNode(workType, expandedNode);

                            if (workType.children && workType.children.length > 0) {
                                workType.children.forEach(codeToDisplay => {
                                    expandedNode = ({
                                        postElect: postElect.data.name,
                                        line: line.data.name,
                                        workType: workType.data.name,
                                        codeToDisplay: codeToDisplay.data.name
                                    });

                                    this.addOrRemoveCodeToDisplayNode(codeToDisplay, expandedNode);
                                });
                            }
                        });
                    }
                });
            }
        });
        /* eslint-enable  */
    }

    /**
     * Add a treetable node if it is expanded or
     * remove it from the expanded list if it is no longer expanded.
     */
    private addOrRemovePostElectNode(postElect: TreeNode<any>, expandedNode: ProgressReportSpecificExpanded): void {
        if (postElect.expanded === true) {
            if (!this.expandedNodes.some(x => x.postElect === expandedNode.postElect
                && x.line === undefined && x.workType === undefined && x.codeToDisplay === undefined)) {
                this.expandedNodes.push(expandedNode);
            }
        } else {

            const nodeIndex = this.expandedNodes.findIndex(node => node.postElect === expandedNode.postElect
                && node.line === undefined && node.workType === undefined && node.codeToDisplay === undefined);
            if (nodeIndex !== -1) {
                this.expandedNodes.splice(nodeIndex, 1);
            }
        }
    }

    /**
     * Add a treetable node if it is expanded or
     * remove it from the expanded list if it is no longer expanded.
     */
    private addOrRemoveLineNode(line: TreeNode<any>, expandedNode: ProgressReportSpecificExpanded): void {
        if (line.expanded === true) {
            if (!this.expandedNodes.some(node => node.postElect === expandedNode.postElect
                && node.line === expandedNode.line && node.workType === undefined && node.codeToDisplay === undefined)) {
                this.expandedNodes.push(expandedNode);
            }
        } else {
            const nodeIndex = this.expandedNodes.findIndex(node => node.postElect === expandedNode.postElect
                && node.line === expandedNode.line && node.workType === undefined && node.codeToDisplay === undefined);
            if (nodeIndex !== -1) {
                this.expandedNodes.splice(nodeIndex, 1);
            }
        }
    }

    /**
     * Add a treetable node if it is expanded or
     * remove it from the expanded list if it is no longer expanded.
     */
    private addOrRemoveWorkTypeNode(workType: TreeNode<any>, expandedNode: ProgressReportSpecificExpanded): void {
        if (workType.expanded === true) {
            if (!this.expandedNodes.some(node => node.postElect === expandedNode.postElect
                && node.line === expandedNode.line && node.workType === expandedNode.workType && node.codeToDisplay === undefined)) {
                this.expandedNodes.push(expandedNode);
            }
        } else {
            const nodeIndex = this.expandedNodes.findIndex(node => node.postElect === expandedNode.postElect
                && node.line === expandedNode.line && node.workType === expandedNode.workType && node.codeToDisplay === undefined);
            if (nodeIndex !== -1) {
                this.expandedNodes.splice(nodeIndex, 1);
            }
        }
    }

    /**
     * Add a treetable node if it is expanded or
     * remove it from the expanded list if it is no longer expanded.
     */
    private addOrRemoveCodeToDisplayNode(codeToDisplay: TreeNode<any>, expandedNode: ProgressReportSpecificExpanded): void {
        if (codeToDisplay.expanded === true) {
            if (!this.expandedNodes.some(node => node.postElect === expandedNode.postElect
                && node.line === expandedNode.line && node.workType === expandedNode.workType
                && node.codeToDisplay === expandedNode.codeToDisplay)) {
                this.expandedNodes.push(expandedNode);
            }
        } else {
            const nodeIndex = this.expandedNodes.findIndex(node => node.postElect === expandedNode.postElect
                && node.line === expandedNode.line && node.workType === expandedNode.workType
                && node.codeToDisplay === expandedNode.codeToDisplay);
            if (nodeIndex !== -1) {
                this.expandedNodes.splice(nodeIndex, 1);
            }
        }
    }

    private groupBy(objectArray: Array<any>, property: string): any {
        return objectArray.reduce((accumulator: Array<any>, obj: any) => {
            const key = obj[property];
            if (!accumulator[key]) {
                accumulator[key] = [];
            }
            accumulator[key].push(obj);
            return accumulator;
        }, {});
    }

    private getSelectItemsFromArray(array: any, sortReverse: boolean = false): SelectItem[] {
        const selectItems: SelectItem[] = [];
        for (let index = 0; index < array.length; index++) {
            const nullText = array[index] === null ? 'null' : array[index].toString();

            const newItem: SelectItem = {
                value: nullText,
                label: nullText
            };
            selectItems.push(newItem);
        }
        selectItems.sort((a, b) => a.value.localeCompare(b.value));

        if (sortReverse) {
            selectItems.reverse();
        }

        // Move "null" at the end.
        const nullIndex = selectItems.findIndex(x => x.value === 'null');
        if (nullIndex > -1) {
            selectItems.push(selectItems.splice(nullIndex, 1)[0]);
        }

        return selectItems;
    }

    public onChangeDropdownFilterData(): void {
        const hasFilters = this.hasFilters();

        // Note: il y a une validation additionnelle qui doit être faite sur chaque dropdown sur les null vs la valeur du dropdown qui est une string 'null'.
        // (dans le form, chaque form control est un array de valeur qui correspond à un dropdown).
        // La validation normale (exemple: this.specificReportForm.controls.billingType.value.includes(element.billingType))
        // ne suffit pas lorsque l'utilisateur coche la valeur 'null', car le type string du dropdown et le type null de l'array d'élément sont différents.

        if (hasFilters) {
            this.progressReportsSpecificFiltered = this.progressReportsSpecific.filter((element) => {
                return (
                    (element.status !== undefined && this.specificReportForm.controls.status.value.name !== undefined
                        // eslint-disable-next-line
                        && (this.specificReportForm.controls.status.value.name.trim() === this.dropdownFirstString
                            || this.specificReportForm.controls.status.value.name.trim() === element.status))
                    && (element.billingType !== undefined && (this.specificReportForm.controls.billingType.value.length === 0
                        || this.specificReportForm.controls.billingType.value.includes(element.billingType)
                        || (this.specificReportForm.controls.billingType.value.includes('null') && element.billingType === null))) /*Voir Note*/
                    && (element.site !== undefined && (this.specificReportForm.controls.site.value.length === 0
                        || this.specificReportForm.controls.site.value.includes(element.site)
                        || (this.specificReportForm.controls.site.value.includes('null') && element.site === null)))
                    && (element.order !== undefined && (this.specificReportForm.controls.order.value.length === 0
                        || this.specificReportForm.controls.order.value.includes(element.order)
                        || (this.specificReportForm.controls.order.value.includes('null') && element.order === null)))
                    && (element.accountingEntry !== undefined && (this.specificReportForm.controls.accountingEntry.value.length === 0
                        || this.specificReportForm.controls.accountingEntry.value.includes(element.accountingEntry)
                        || (this.specificReportForm.controls.accountingEntry.value.includes('null') && element.accountingEntry === null)))
                    && (element.executedBy !== undefined && (this.specificReportForm.controls.executedBy.value.length === 0
                        || this.specificReportForm.controls.executedBy.value.includes(element.executedBy)
                        || (this.specificReportForm.controls.executedBy.value.includes('null') && element.executedBy === null)))
                    && (element.assignedBy !== undefined && (this.specificReportForm.controls.assignedBy.value.length === 0
                        || this.specificReportForm.controls.assignedBy.value.includes(element.assignedBy)
                        || (this.specificReportForm.controls.assignedBy.value.includes('null') && element.assignedBy === null)))
                    && (element.week !== undefined && (this.specificReportForm.controls.week.value.length === 0
                        || (this.specificReportForm.controls.week.value.includes(element.week)
                            || (this.specificReportForm.controls.week.value.includes('null') && element.week === null)))
                    ));
            });
            this.createTreeNodes(this.progressReportsSpecificFiltered);
        } else {
            this.createTreeNodes(this.progressReportsSpecific);
        }

        this.saveToLocalStorage(hasFilters);
    }

    /**
     *
     * @returns True if there is at least one filter on the page.
     * False if there are no filters (all filters are empty or have a text item like "Select a value..").
     * There is currently a total of 8 possible dropdown filters: status, billingType, site, order, accountingEntry, executedBy, assignedBy and week.
     */
    private hasFilters(): boolean {
        let arraysHasFilters = false;
        let singleValueHasFilter = false;
        let hasFilters = false;

        Object.keys(this.specificReportForm.controls).forEach(key => {
            if (this.specificReportForm.controls[key].value.length > 0) {
                arraysHasFilters = true;
                return;
            }
        });

        if (this.specificReportForm.controls.status.value.name !== undefined) {
            singleValueHasFilter = this.specificReportForm.controls.status.value.name.trim() !== this.dropdownFirstString;
        }

        hasFilters = arraysHasFilters || singleValueHasFilter;
        return hasFilters;
    }

    private saveToLocalStorage(hasFilters: boolean): void {
        this.savedFilters = new ProgressReportSpecificSavedFilters({
            status: this.specificReportForm.controls.status.value,
            billingType: this.specificReportForm.controls.billingType.value,
            site: this.specificReportForm.controls.site.value,
            order: this.specificReportForm.controls.order.value,
            accountingEntry: this.specificReportForm.controls.accountingEntry.value,
            executedBy: this.specificReportForm.controls.executedBy.value,
            assignedBy: this.specificReportForm.controls.assignedBy.value,
            week: this.specificReportForm.controls.week.value,
            haveFilters: hasFilters
        });

        localStorage.setItem(this.localStorageName, JSON.stringify(this.savedFilters));
    }

    private getFilters(localStorageName: string): ProgressReportSpecificSavedFilters {
        const savedFilters = new ProgressReportSpecificSavedFilters();
        savedFilters.fetchFilterFromLocalStorage(localStorageName);
        return savedFilters;
    }

    private reloadSavedFilters(): void {
        const savedFilters = this.getFilters(this.localStorageName);

        if (savedFilters.haveFilters) {
            if (savedFilters.status) {
                this.specificReportForm.controls.status.setValue(savedFilters.status);
            }

            if (savedFilters.billingType) {
                this.specificReportForm.controls.billingType.setValue(savedFilters.billingType);
            }

            if (savedFilters.site) {
                this.specificReportForm.controls.site.setValue(savedFilters.site);
            }

            if (savedFilters.order) {
                this.specificReportForm.controls.order.setValue(savedFilters.order);
            }

            if (savedFilters.accountingEntry) {
                this.specificReportForm.controls.accountingEntry.setValue(savedFilters.accountingEntry);
            }

            if (savedFilters.executedBy) {
                this.specificReportForm.controls.executedBy.setValue(savedFilters.executedBy);
            }

            if (savedFilters.assignedBy) {
                this.specificReportForm.controls.assignedBy.setValue(savedFilters.assignedBy);
            }

            if (savedFilters.week) {
                this.specificReportForm.controls.week.setValue(savedFilters.week);
            }

            this.onChangeDropdownFilterData();
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public inputFilterColumn(value: Event, field: string, filterMatchMode?: string): void {
        this.treetable.filter((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains');
    }
}
