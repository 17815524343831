import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Store, select } from '@ngrx/store';

import { FormModalData } from '../generic-form-modal/generic-form-modal-data.model';
import { GenericFormModalComponent } from '../generic-form-modal/generic-form-modal.component';
import { ModalData, ModalEntityType, ModalType } from '../generic-modal/generic-modal-data.model';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import { Worker } from '../../../../../shared/models/database/worker.model';
import { UpdateOneWorker, DeleteOneWorker } from '../../../../store/actions';
import { TimesheetState } from '../../../../store/reducers';
import { Observable } from 'rxjs';
import { getWorkersLoading } from '../../../../store/selectors';
import { UserRole } from '../../../../../shared/models/user-roles.model';
import { getUserRoles } from '../../../../../core/store/selectors';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { CurrentUser, UserService } from '../../../../../core/services/user.service';

@Component({
    selector: 'app-team-mate-list',
    templateUrl: './team-mate-list.component.html',
    styleUrls: ['./team-mate-list.component.scss']
})
export class TeamMateListComponent {
    public currentUserRoles: UserRole[] = [];
    public teamMateOverIndex: number | null = null;

    @Input() public workerList: Worker[];
    @Input() public canAddEditTime = true;
    @Input() public orderId: number;
    @Input() public timesheetDateId = '';
    @Input() public isLock = false;
    @Input() public editAllowedRoles: UserRole[] = [];
    @Input() public pathChoice: AlternativePath;

    public workersLoading$: Observable<boolean> = this.store.pipe(
        select(getWorkersLoading),
    );

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<TimesheetState>,
        private userService: UserService,
    ) {
        this.store.pipe(
            select(getUserRoles),
        ).subscribe((roles: UserRole[]) => {
            this.currentUserRoles = roles;
        });
    }

    public jsTeamMateTrackById(_: number, { id }: Worker): number {
        return id;
    }

    public isEmptyTransport(worker: Worker): boolean {
        return worker.transportationQuantity === 0;
    }

    public isEmptyMeal(worker: Worker): boolean {

        return worker.mealQuantity === 0;
    }

    public isRoleDisabled(): boolean {
        return !!this.editAllowedRoles.length &&
            this.currentUserRoles.every(role => !this.editAllowedRoles.includes(role));
    }

    public editRecord(worker: Worker): void {
        if (!this.isLock && !this.isRoleDisabled()) {
            const teamMateDialog = this.dialog.open(GenericFormModalComponent, {
                width: '95%',
                maxWidth: '450px',
                data: {
                    icon: 'fas fa-pen',
                    formName: 'teamMate',
                    titleKey: 'firstName',
                    entity: worker,
                    canAddEditTime: this.canAddEditTime,
                    timesheetDateId: this.timesheetDateId,
                    parentId: this.orderId,
                    pathChoice: this.pathChoice,
                    translatePrefix: '',
                    specificDetail: {
                        idEmploye: worker.idEmploye
                    },
                } as FormModalData
            });

            teamMateDialog.afterClosed().subscribe((result: Worker) => {
                if (result) {
                    let imperson: CurrentUser | undefined;
                    if (this.userService.currentUser.isImpersonnified) {
                        imperson = this.userService.currentUser;
                    }

                    this.store.dispatch(new UpdateOneWorker(result as Worker, this.timesheetDateId, this.pathChoice, imperson));
                }
            });
        }
    }

    public deleteRecord(worker: Worker): void {
        const deleteEquipDialog = this.dialog.open(GenericModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                formType: ModalType.delete,
                title: 'timesheet.order.tab.accordionTitle.team.deleteForm.title',
                message: 'timesheet.order.tab.accordionTitle.team.deleteForm.message',
                entityType: ModalEntityType.worker,
                entity: worker,
                canAddEditTime: this.canAddEditTime,
            } as ModalData
        });

        deleteEquipDialog.afterClosed().subscribe((result: boolean) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }

                this.store.dispatch(new DeleteOneWorker(worker, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }

    public mouseOver(index: number): void {
        this.teamMateOverIndex = index;
    }

    public mouseOut(): void {
        this.teamMateOverIndex = null;
    }
}
