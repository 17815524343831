import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

/* Store imports */
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ogivCoreEffects } from './store/effects';
import { getOgivCoreReducers, OGIV_CORE_REDUCER_TOKEN } from './store';
import { throwIfAlreadyLoaded } from '../core/module-import.guard';
import { ogivCoreServices } from './services';

@NgModule({
    imports: [
        CommonModule,

        // Store
        StoreModule.forFeature('ogivCore', OGIV_CORE_REDUCER_TOKEN),
        EffectsModule.forFeature(ogivCoreEffects),
    ],
    exports: [
        CommonModule,
    ],
    providers: [
        ...ogivCoreServices,
        {
            provide: OGIV_CORE_REDUCER_TOKEN,
            useFactory: getOgivCoreReducers,
        },
    ]
})
export class OgivCoreModule {
    constructor(@Optional() @SkipSelf() parentModule: OgivCoreModule) {
        throwIfAlreadyLoaded(parentModule, 'OgivCoreModule');
    }
}
