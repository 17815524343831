<app-element-loader *ngIf="entrepreneursLoading$ | async"
    [size]="loaderLogoSize"
    [displayLabel]="true"
    [labelFontSize]="20"></app-element-loader>
<p-table #entrepreneursDataTable [columns]="columns" [value]="$any(entrepreneurs$ | async)" [paginator]="true" [rows]="10"
    [sortField]="sortField" [sortOrder]="sortOrder" [rowHover]="true" [autoLayout]="true"
    styleClass="p-datatable-striped"
    currentPageReportTemplate="Affiche de {first} à {last} sur {totalRecords} items" [showCurrentPageReport]="true"
    [globalFilterFields]="globalFilterArray" [rowsPerPageOptions]="[10,25,50,100,200]">

    <ng-template pTemplate="caption">
        <div class="table__header">
            <div class="header__container">
                <div class="container__search">
                    <i class="fas fa-search search__icon"></i>
                    <input type="text" class="search__input" pInputText size="30" id="id-global-filter"
                        #globalFilterObj [placeholder]="'common.search' | translate" [attr.aria-placeholder]="'common.search' | translate"
                        (input)="inputGlobalFilter($event, 'contains')">
                </div>
                <app-erase-all-filter class="container__reset" (clicked)="clearAllFilters()" [disabled]="!filtersApply"></app-erase-all-filter>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
                <th *ngIf="col.type === 'shortTag'">
                </th>
                <th *ngIf="col.type !== 'shortTag'" [pSortableColumn]="col.field">
                    <div class="p-d-flex">
                        {{ translatePrefix + '.' + col.header | translate }}

                        <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                            [attr.aria-label-desc]="'common.sortDesc' | translate"
                            [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
                    </div>
                </th>
            </ng-container>
            <th class="sticky-column">{{ 'common.actions' | translate }}</th>
        </tr>
        <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <div *ngSwitchCase="'indicator'">
                </div>
                <div *ngSwitchCase="'statut'">
                    <p-dropdown #ddStatus appendTo="body" [options]="statuses" autoWidth="false" [style]="{'width':'fit-content'}"
                        (onChange)="filterColumn($event.value, 'statut', 'equals')"
                        styleClass="p-column-filter" [placeholder]="'pilotages.status-dropdown.placeholder' | translate"
                        [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span>{{option.label}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div *ngSwitchDefault>
                    <input pInputText *ngSwitchDefault type="text" styleClass="p-column-filter"
                        (input)="inputFilterColumn($event, col.field, 'contains')"
                        [placeholder]="'common.filter' | translate"
                        id="id-{{col.field}}"
                        [attr.aria-placeholder]="'common.filter' | translate">
                </div>
            </th>
            <th class="sticky-column"></th>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length+1">
                <h3>
                    {{translatePrefix + '.empty' | translate}}
                </h3>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr (click)="callFunction(rowData)" class="p-selectable-row" (mouseenter)="rowOver(rowIndex)" (mouseleave)="rowOver(null)">
            <td *ngFor="let col of columns; trackBy: trackByColumnField">
                <ng-container [ngSwitch]="true">
                    <ng-container *ngSwitchCase="col.type === 'tag'">
                        <app-tag [status]="rowData[col.field]" [capitalize]="false">
                            {{  translatePrefix + '.' + rowData[col.field] | translate}}
                        </app-tag>
                    </ng-container>
                    <ng-container *ngSwitchCase="col.type === 'shortTag'">
                        <div class="indicatorContainer">
                            <app-tag [isIndicator]="true" [status]="rowData['statut']"></app-tag>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="col.field === 'telephone'">
                        <label>
                            {{ rowData[col.field] | mask: "(000) 000-0000 ,000000" }}
                        </label>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{ rowData[col.field] }}
                    </ng-container>
                </ng-container>
            </td>
            <td class="sticky-column">
                <div class="sticky-column__container" (mouseover)="rowOver(null)" (mouseout)="rowOver(rowIndex)">
                    <ng-container *ngFor="let col of columns">
                        <app-generic-other-menu *ngIf="rowData[col.field] === 'Actif'"
                            [mySingleButtonOnly]="true"
                            [addborder]="true"
                            [singleButtonIcon]="deactivateLogo"
                            [isRedButton]="true"
                            [toolTipLabel]="translatePrefix + '.deactivate' | translate"
                            (singleButtonPressed)="onDeactivateEntrepreneur(rowData)">
                        </app-generic-other-menu>
                        <app-generic-other-menu *ngIf="rowData[col.field] === 'Inactif'"
                            [mySingleButtonOnly]="true"
                            [addborder]="true"
                            [singleButtonIcon]="reactivateLogo"
                            [toolTipLabel]="translatePrefix + '.reactivate' | translate"
                            (singleButtonPressed)="onReactivateEntrepreneur(rowData)">
                        </app-generic-other-menu>
                        <app-generic-other-menu *ngIf="col.field === 'statut'"
                            [editOnly]="true"
                            [addborder]="true"
                            [hoverButtonOrMenu]="rowIndex === buttonOverIndex"
                            [toolTipLabel]="translatePrefix + '.modify' | translate"
                            (edit)="showModifyEntrepreneur(rowData)">
                        </app-generic-other-menu>
                    </ng-container>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
