import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { BillingOrder } from '../../../shared/models/database/billing-order.model';
import { BillingOrderActionTypes, BillingOrdersAction } from '../actions';

export interface BillingOrdersState extends EntityState<BillingOrder> {
    loading: boolean;
    loaded: boolean;
}

export const billingOrdersAdapter: EntityAdapter<BillingOrder> = createEntityAdapter<BillingOrder>({
    selectId: (billingOrder: BillingOrder) => billingOrder.id,
});

export const billingOrdersInitialState = billingOrdersAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function billingOrderReducer(
    state: BillingOrdersState = billingOrdersInitialState,
    action: BillingOrdersAction,
): BillingOrdersState {
    switch (action.type) {
        case BillingOrderActionTypes.LOAD_BILLING_ORDERS: {
            return {
                ...state,
                loading: true,
            };
        }
        case BillingOrderActionTypes.LOAD_BILLING_ORDERS_SUCCESS: {
            return billingOrdersAdapter.setAll(action.billingOrders, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case BillingOrderActionTypes.LOAD_BILLING_ORDERS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
