import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { MealCode } from '../../../shared/models/database/meal-code.model';

/**
 * Types
 */
export enum MealCodeActionType {
    LOAD_MEAL_CODES = '[MealCode] Load MealCodes',
    LOAD_MEAL_CODES_SUCCESS = '[MealCode] Load MealCodes Success',
    LOAD_MEAL_CODES_FAIL = '[MealCode] Load MealCodes Fail',
    LOAD_MEAL_CODES_NO_RESULTS = '[MealCode] Load MealCodes No Result',

    CREATE_MEAL_CODE = '[MealCode] Create MealCode',
    CREATE_MEAL_CODE_SUCCESS = '[MealCode] Create MealCode Success',
    CREATE_MEAL_CODE_FAIL = '[MealCode] Create MealCode Fail',

    UPDATE_ONE_MEAL_CODE = '[MealCode] Update One MealCode',
    UPDATE_ONE_MEAL_CODE_SUCCESS = '[MealCode] Update One MealCode Success',
    UPDATE_ONE_MEAL_CODE_FAIL = '[MealCode] Update One MealCode Fail',
}

/**
 * ACTIONS
 */
/**
 * CREATE
 */
export class CreateMealCode implements Action {
    readonly type = MealCodeActionType.CREATE_MEAL_CODE;
    constructor(public mealCode: MealCode, public path: AlternativePath) { }
}

export class CreateMealCodeSuccess implements Action {
    readonly type = MealCodeActionType.CREATE_MEAL_CODE_SUCCESS;
    constructor(public mealCode: MealCode) { }
}

export class CreateMealCodeFail implements Action {
    readonly type = MealCodeActionType.CREATE_MEAL_CODE_FAIL;
    constructor(public error: any) { }
}

/**
 * LOAD
 */
export class LoadMealCodes implements Action {
    readonly type = MealCodeActionType.LOAD_MEAL_CODES;
    constructor() { }
}

export class LoadMealCodesNoResult implements Action {
    readonly type = MealCodeActionType.LOAD_MEAL_CODES_NO_RESULTS;
}

export class LoadMealCodesSuccess implements Action {
    readonly type = MealCodeActionType.LOAD_MEAL_CODES_SUCCESS;
    constructor(public mealCodes: MealCode[]) { }
}

export class LoadMealCodesFail implements Action {
    readonly type = MealCodeActionType.LOAD_MEAL_CODES_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneMealCode implements Action {
    readonly type = MealCodeActionType.UPDATE_ONE_MEAL_CODE;
    constructor(public mealCode: MealCode, public path: AlternativePath) { }
}

export class UpdateOneMealCodeSuccess implements Action {
    readonly type = MealCodeActionType.UPDATE_ONE_MEAL_CODE_SUCCESS;
    constructor(public mealCode: MealCode) { }
}

export class UpdateOneMealCodeFail implements Action {
    readonly type = MealCodeActionType.UPDATE_ONE_MEAL_CODE_FAIL;
    constructor(public error: any) { }
}

export type MealCodeAction =
    | CreateMealCode
    | CreateMealCodeSuccess
    | CreateMealCodeFail
    | LoadMealCodes
    | LoadMealCodesNoResult
    | LoadMealCodesSuccess
    | LoadMealCodesFail
    | UpdateOneMealCode
    | UpdateOneMealCodeSuccess
    | UpdateOneMealCodeFail;
