import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';

import { FormModalData } from './generic-form-modal-data.model';

@Component({
    selector: 'app-generic-form-modal',
    templateUrl: './generic-form-modal.component.html',
    styleUrls: ['./generic-form-modal.component.scss'],
})
export class GenericFormModalComponent {
    public icon: string;
    public formName: string;

    public translatePrefix: string;
    public titleKey: string;

    public entity: any | null;
    public canAddEditTime: boolean;
    public timesheetDateId: string;

    public isLeftVisible = true;
    public isReloadData = false;

    public customTitle: string | null = null;
    public deleteTitleKey = '';
    public parentId: number | null;
    public specificDetail: {};

    public freezeTabIndex = true;
    public pathChoice: AlternativePath;

    constructor(
        public dialogRef: MatDialogRef<GenericFormModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FormModalData,
        private translate: TranslateService,
    ) {
        this.icon = data.icon;
        this.formName = data.formName;
        this.translatePrefix = data.translatePrefix || '';
        this.titleKey = data.titleKey || '';
        this.entity = data.entity || null;
        this.canAddEditTime = data.canAddEditTime !== undefined && data.canAddEditTime !== null ? data.canAddEditTime : true;
        this.timesheetDateId = data.timesheetDateId || '';
        this.parentId = data.parentId || null;
        this.specificDetail = data.specificDetail || {};
        this.pathChoice = data.pathChoice;
        this.deleteTitleKey = data.deleteTitleKey || '';
    }

    public isTranslatePrefixEmpty(): boolean {
        return this.translatePrefix === '';
    }

    public onClose(): void {
        this.dialogRef.close();
    }

    public onCreate(event: any): void {
        this.dialogRef.close(event);
    }

    public onUpdate(event: any): void {
        this.dialogRef.close(event);
    }

    public getEditTitleSuffix(): string {
        return this.titleKey && this.entity[this.titleKey]
            ? `${this.entity[this.titleKey]}`
            : '';
    }

    public switchToCreate(textToTranslate?: string): void {
        this.freezeTabIndex = false;
        if (textToTranslate) {
            // this.translate.get('timesheet.order.tab.accordionTitle.equipment.createForm.title').subscribe(
            //     (translatedString: string) => this.customTitle = translatedString
            // );
            this.translate.get(textToTranslate).subscribe((translatedString: string) => this.customTitle = translatedString);
        }
        this.isLeftVisible = !this.isLeftVisible;
        this.isReloadData = false;
    }

    public slidePanel(): void {
        this.freezeTabIndex = true;
        this.customTitle = null;
        this.isLeftVisible = !this.isLeftVisible;
        this.isReloadData = true;
    }
}
