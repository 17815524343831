import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { TransportCode } from '../../../shared/models/database/transport-code.model';

/**
 * Types
 */
export enum TransportCodeActionType {
    LOAD_TRANSPORT_CODES = '[TransportCode] Load TransportCodes',
    LOAD_TRANSPORT_CODES_SUCCESS = '[TransportCode] Load TransportCodes Success',
    LOAD_TRANSPORT_CODES_FAIL = '[TransportCode] Load TransportCodes Fail',
    LOAD_TRANSPORT_CODES_NO_RESULTS = '[TransportCode] Load TransportCodes No Result',

    CREATE_TRANSPORT_CODE = '[TransportCode] Create TransportCode',
    CREATE_TRANSPORT_CODE_SUCCESS = '[TransportCode] Create TransportCode Success',
    CREATE_TRANSPORT_CODE_FAIL = '[TransportCode] Create TransportCode Fail',

    UPDATE_ONE_TRANSPORT_CODE = '[TransportCode] Update One TransportCode',
    UPDATE_ONE_TRANSPORT_CODE_SUCCESS = '[TransportCode] Update One TransportCode Success',
    UPDATE_ONE_TRANSPORT_CODE_FAIL = '[TransportCode] Update One TransportCode Fail',
}

/**
 * ACTIONS
 */
/**
 * CREATE
 */
export class CreateTransportCode implements Action {
    readonly type = TransportCodeActionType.CREATE_TRANSPORT_CODE;
    constructor(public transportCode: TransportCode, public path: AlternativePath) { }
}

export class CreateTransportCodeSuccess implements Action {
    readonly type = TransportCodeActionType.CREATE_TRANSPORT_CODE_SUCCESS;
    constructor(public transportCode: TransportCode) { }
}

export class CreateTransportCodeFail implements Action {
    readonly type = TransportCodeActionType.CREATE_TRANSPORT_CODE_FAIL;
    constructor(public error: any) { }
}

/**
 * LOAD
 */
export class LoadTransportCodes implements Action {
    readonly type = TransportCodeActionType.LOAD_TRANSPORT_CODES;
}

export class LoadTransportCodesNoResult implements Action {
    readonly type = TransportCodeActionType.LOAD_TRANSPORT_CODES_NO_RESULTS;
}

export class LoadTransportCodesSuccess implements Action {
    readonly type = TransportCodeActionType.LOAD_TRANSPORT_CODES_SUCCESS;
    constructor(public transportCodes: TransportCode[]) { }
}

export class LoadTransportCodesFail implements Action {
    readonly type = TransportCodeActionType.LOAD_TRANSPORT_CODES_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneTransportCode implements Action {
    readonly type = TransportCodeActionType.UPDATE_ONE_TRANSPORT_CODE;
    constructor(public transportCode: TransportCode, public path: AlternativePath) { }
}

export class UpdateOneTransportCodeSuccess implements Action {
    readonly type = TransportCodeActionType.UPDATE_ONE_TRANSPORT_CODE_SUCCESS;
    constructor(public transportCode: TransportCode) { }
}

export class UpdateOneTransportCodeFail implements Action {
    readonly type = TransportCodeActionType.UPDATE_ONE_TRANSPORT_CODE_FAIL;
    constructor(public error: any) { }
}

export type TransportCodeAction =
    | CreateTransportCode
    | CreateTransportCodeSuccess
    | CreateTransportCodeFail
    | LoadTransportCodes
    | LoadTransportCodesNoResult
    | LoadTransportCodesSuccess
    | LoadTransportCodesFail
    | UpdateOneTransportCode
    | UpdateOneTransportCodeSuccess
    | UpdateOneTransportCodeFail;
