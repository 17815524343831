import { Arborist } from '../../../shared/models/database/arborist.model';
import { ArboristAction, ArboristActionType } from '../actions/arborists.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface ArboristsState extends EntityState<Arborist> {
    loading: boolean;
    loaded: boolean;
}

export const arboristsAdapter: EntityAdapter<Arborist> = createEntityAdapter<Arborist>({
    selectId: (arborist: Arborist) => arborist.id,
});

export const arboristsInitialState = arboristsAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function arboristReducer(
    state: ArboristsState = arboristsInitialState,
    action: ArboristAction,
): ArboristsState {
    switch (action.type) {
        case ArboristActionType.CREATE_ARBORIST:
        case ArboristActionType.UPDATE_ONE_ARBORIST:
        case ArboristActionType.LOAD_ARBORISTS: {
            return {
                ...state,
                loading: true,
            };
        }

        case ArboristActionType.CREATE_ARBORIST_SUCCESS: {
            return arboristsAdapter.addOne(action.arborist, {
                ...state,
                loading: false,
            });
        }

        case ArboristActionType.UPDATE_ONE_ARBORIST_SUCCESS: {
            return arboristsAdapter.upsertOne(action.arborist, {
                ...state,
                loading: false,
            });
        }

        case ArboristActionType.CREATE_ARBORIST_FAIL:
        case ArboristActionType.UPDATE_ONE_ARBORIST_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case ArboristActionType.LOAD_ARBORISTS_SUCCESS: {
            return arboristsAdapter.setAll(action.arborists, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case ArboristActionType.LOAD_ARBORISTS_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case ArboristActionType.LOAD_ARBORISTS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
