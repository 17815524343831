<div *ngFor="let error of errorList; let i=index" class="card" [class.first]="indent === 1" [class.second]="indent === 2" [class.third]="indent === 3">
     <div class="card__title" [class.first]="indent === 1" [class.second]="indent === 2" [class.third]="indent !== 1 && indent !== 2" [ngStyle]="{'padding-left': (indent*5)+'px'}">
        {{ error.name }}
     </div>
     <app-recursive-error *ngIf="!error.last" [errorList]="typeErrorList" [indent]="indent+1"></app-recursive-error>
     <ng-container *ngIf="error.last">
        <ul *ngFor="let err of typeStringList" class="card__list">
            <li class="list__desc">
                {{ err }}
            </li>
        </ul>
     </ng-container>
</div>
