import { Error } from './error.model';
import { OrderType } from './order-type.enum';
import { Unit } from './unit.model';

export class PostLineRawData {
    public id: number;
    public orderId: number;
    public powerOutageNumber?: string | null;
    public postElect: string;
    public line: string;
    public chargeNumber: string;
    public fromInventory: boolean;
    public units?: Unit[];
}
export class PostLine {
    public id: number;
    public orderId: number;
    public powerOutageNumber?: string | null;
    public postElect: string;
    public line: string;
    public chargeNumber: string;
    public fromInventory: boolean;
    public units?: Unit[];

    constructor(data: PostLineRawData) {
        Object.keys(data).forEach((key: string) => {
            this[key] = data[key];
        });
    }

    public validate(validateChildren: boolean = false, orderType: OrderType): Error | null {
        let errors: Error | null = null;
        let postLineErrorList: Error[] = [];

        if (this.chargeNumber === null || this.chargeNumber === undefined || this.chargeNumber === '') {
            errors = { name: '', list: ['doit contenir un numéro de charge'], last: true };
            postLineErrorList.push(errors);
        }

        if (orderType === OrderType.outage && (this.powerOutageNumber === null || this.powerOutageNumber === undefined || this.powerOutageNumber === '')) {
            errors = { name: '', list: ['doit contenir un numéro de panne'], last: true };
            postLineErrorList.push(errors);
        }

        if (validateChildren) {
            const unitErrors = this.validateChildren(orderType);

            if (unitErrors !== null) {
                postLineErrorList = postLineErrorList.concat(unitErrors.list as Error[]);
            }
        }

        if (postLineErrorList.length > 0) {
            errors = { name: `${this.postElect}-${this.line}`, list: postLineErrorList, last: false };
        }

        return errors;
    }

    private validateChildren(orderType: OrderType): Error | null {
        let errors: Error | null = null;
        const errorUnits: Error[] = [];

        this.units?.forEach((unit: Unit) => {
            const err = new Unit(unit).validate(orderType);
            if (err !== null) {
                errorUnits.push(err);
            }
        });

        if (errorUnits.length > 0) {
            errors = { name: `${this.postElect}-${this.line}`, list: errorUnits, last: false };
        }

        return errors;
    }
}
