import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { BillingSapEntry } from '../../../shared/models/database/billing-sap-entry.model';
import { BillingSapEntriesAction, BillingSapEntriesActionType } from '../actions/billing-sap-entries.action';

export interface BillingSapEntriesState extends EntityState<BillingSapEntry> {
    loading: boolean;
    loaded: boolean;
}

export const billingSapEntriesAdapter: EntityAdapter<BillingSapEntry> = createEntityAdapter<BillingSapEntry>({
    selectId: (billingSapEntriesRow: BillingSapEntry) => billingSapEntriesRow.id
});

export const billingSapEntriesInitialState = billingSapEntriesAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function billingSapEntriesReducer(
    state: BillingSapEntriesState = billingSapEntriesInitialState,
    action: BillingSapEntriesAction,
): BillingSapEntriesState {
    switch (action.type) {
        case BillingSapEntriesActionType.LOAD_BILLING_SAP_ENTRIES: {
            return {
                ...state,
                loading: true,
            };
        }

        case BillingSapEntriesActionType.LOAD_BILLING_SAP_ENTRIES_SUCCESS: {
            return billingSapEntriesAdapter.setAll(action.billingSapEntries, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case BillingSapEntriesActionType.LOAD_BILLING_SAP_ENTRIES_FAIL: {
            return billingSapEntriesAdapter.setAll([], {
                ...state,
                loading: false,
                loaded: false,
            });
        }

        default:
            return state;
    }
}
