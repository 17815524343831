import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { Entrepreneur } from '../../../../models/entrepreneur.model';
import { UtilisateurExterne } from '../../../../models/utilisateur-externe.model';
import { LoadEntrepreneurs } from '../../../../store/actions';
import { PilotagesState } from '../../../../store/reducers';
import { getEntrepreneursActifsSorted, getEntrepreneurActifByAbreviation } from '../../../../store/selectors';

interface DropdownItem {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-reassign-entreprise-form',
    templateUrl: './reassign-entreprise-form.component.html',
    styleUrls: ['./reassign-entreprise-form.component.scss']
})
export class ReassignEntrepriseFormComponent implements OnChanges, OnInit, OnDestroy {
    public utilisateurEntrepriseForm: FormGroup;

    public entrepreneurs: Entrepreneur[];
    public dropdownItems$: Observable<DropdownItem[]>;
    public originalAbreviationValue: string;
    public isSaveable = false;
    private finalValue: UtilisateurExterne;
    private subscriptions: Subscription[] = [];

    @Input() public utilisateurExterne: UtilisateurExterne;
    @Input() public translatePrefix: string;

    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public update: EventEmitter<UtilisateurExterne> = new EventEmitter<UtilisateurExterne>();

    constructor(
        public formBuilder: FormBuilder,
        private readonly store: Store<PilotagesState>,
        public dialogRef: MatDialogRef<ReassignEntrepriseFormComponent>,
        public translateService: TranslateService
    ) {
        this.utilisateurEntrepriseForm = this.formBuilder.group({
            abreviation_entreprise: ['', [Validators.required]]
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.utilisateurExterne && changes.utilisateurExterne.currentValue) {
            this.utilisateurEntrepriseForm.reset(changes.utilisateurExterne.currentValue);
            this.originalAbreviationValue = this.utilisateurEntrepriseForm.controls.abreviation_entreprise.value;
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public ngOnInit(): void {
        this.utilisateurEntrepriseForm.reset(this.utilisateurExterne);

        this.store.dispatch(new LoadEntrepreneurs(AlternativePath.hq));

        this.dropdownItems$ = this.store.pipe(
            select(getEntrepreneursActifsSorted),
            map((entrepreneur: Entrepreneur[]) => {
                return this.setDropdownItems(entrepreneur as Entrepreneur[]);
            })
        );

        this.subscriptions.push(
            this.utilisateurEntrepriseForm.controls.abreviation_entreprise.valueChanges.pipe(
                switchMap((entrepreneurAbr: string) => {
                    return this.store.pipe(
                        select(getEntrepreneurActifByAbreviation({ abbr: entrepreneurAbr })),
                        tap((entrepreneurFound: Entrepreneur | null) => {
                            if (entrepreneurFound !== null) {
                                if (entrepreneurFound.abreviation !== this.originalAbreviationValue) {
                                    this.isSaveable = true;
                                    this.finalValue = {
                                        ...this.utilisateurExterne,
                                        abreviation_entreprise: entrepreneurAbr,
                                        id_entreprise: entrepreneurFound.id,
                                        nom_entreprise: entrepreneurFound.nom,
                                    };
                                } else {
                                    this.isSaveable = false;
                                }
                                this.utilisateurEntrepriseForm.updateValueAndValidity();
                            } else {
                                this.utilisateurEntrepriseForm.controls.abreviation_entreprise.setValue('');
                            }
                        }),
                    );
                })
            ).subscribe(),
        );
    }

    public setDropdownItems(entrepreneurs: Entrepreneur[]): DropdownItem[] {
        const myDropdownItems: DropdownItem[] = [];

        entrepreneurs.forEach((entrepreneur) => {
            myDropdownItems.push({
                value: entrepreneur.abreviation,
                viewValue: entrepreneur.nom,
            } as DropdownItem);
        });

        return myDropdownItems;
    }

    public onUpdate(): void {
        const { valid, touched } = this.utilisateurEntrepriseForm;
        // const attribut = this.utilisateurEntrepriseForm.controls.abreviation_entreprise.value;
        const adresse = JSON.stringify(this.utilisateurExterne.adresse_personnelle).replace(/\"\"/g, 'null');
        const value = {
            ...this.finalValue,
            // ...this.utilisateurExterne,
            // abreviation_entreprise: attribut,
            // id_entreprise: entreprise.Id,
            // nom_entreprise: entreprise.Nom,
            adresse_personnelle: adresse
        };
        if (valid && touched) {
            this.dialogRef.close(value);
        } else {
            this.dialogRef.close();
        }
    }

    public onCancel(): void {
        this.dialogRef.close();
    }
}
