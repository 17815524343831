<div mat-dialog-title class="dialog__title">
    <i *ngIf="icon" class="{{ icon }} title__logo" [class.title__logo-delete]="deleteTitleKey !== ''"></i>

    <div class="title__text">
        <span *ngIf="deleteTitleKey !== ''" class="title__text-delete">
            {{ translatePrefix + '.' + deleteTitleKey | translate }}
        </span>
        <span *ngIf="customTitle; else standardTitle">
            {{ customTitle }}
        </span>
        <ng-template #standardTitle>
            <span *ngIf="!entity; else editTitle">
                <span *ngIf="!isTranslatePrefixEmpty() && deleteTitleKey === ''">
                    {{ translatePrefix + '.addTitle' | translate }}
                </span>
            </span>

            <ng-template #editTitle>
                <span *ngIf="!isTranslatePrefixEmpty() && deleteTitleKey === ''">
                    {{ translatePrefix + '.editTitle' | translate }}
                </span>
                {{ getEditTitleSuffix() }}
            </ng-template>
        </ng-template>
    </div>
</div>
<div mat-dialog-content>
    <ng-container [ngSwitch]="formName">
        <ng-container *ngSwitchCase="'teamMate'">
            <app-generic-slide-panel [activePanel]="isLeftVisible ? 'left' : 'right'">
                <app-team-mate-form leftPanel
                    (create)="onCreate($event)"
                    (update)="onUpdate($event)"
                    (cancel)="onClose()"
                    (isCreateMode)="switchToCreate($event)"
                    [pathChoice]="pathChoice"
                    [worker]="entity"
                    [canAddEditTime]="canAddEditTime"
                    [timesheetDateId]="timesheetDateId"
                    [orderId]="parentId"
                    [freezeTabIndex]="!freezeTabIndex"
                    [reloadJobClassData]="isReloadData"
                    [specificDetail]="specificDetail">
                </app-team-mate-form>
                <div rightPanel>
                    <app-job-class-form
                        (cancel)="slidePanel()"
                        (create)="slidePanel()"
                        [pathChoice]="pathChoice"
                        [otherCancelIcon]="'fas fa-arrow-left'"
                        [freezeTabIndex]="freezeTabIndex"></app-job-class-form>
                </div>
            </app-generic-slide-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'equipment'">
            <app-generic-slide-panel [activePanel]="isLeftVisible ? 'left' : 'right'">
                <app-equipment-form leftPanel
                    (create)="onCreate($event)"
                    (update)="onUpdate($event)"
                    (cancel)="onClose()"
                    (isCreateMode)="switchToCreate($event)"
                    [pathChoice]="pathChoice"
                    [equipment]="entity"
                    [canAddEditTime]="canAddEditTime"
                    [timesheetDateId]="timesheetDateId"
                    [reloadVehicleData]="isReloadData"
                    [orderId]="parentId"
                    [freezeTabIndex]="!freezeTabIndex">
                </app-equipment-form>
                <div rightPanel>
                    <app-vehicle-form
                        (cancel)="slidePanel()"
                        (create)="slidePanel()"
                        [pathChoice]="pathChoice"
                        [otherCancelIcon]="'fas fa-arrow-left'"
                        [freezeTabIndex]="freezeTabIndex">
                    </app-vehicle-form>
                </div>
            </app-generic-slide-panel>
        </ng-container>

        <app-work-done-form *ngSwitchCase="'workDone'"
            (create)="onCreate($event)"
            (update)="onUpdate($event)"
            (cancel)="onClose()"
            [pathChoice]="pathChoice"
            [unit]="entity"
            [canAddEditTime]="canAddEditTime"
            [timesheetDateId]="timesheetDateId"
            [postLineId]="parentId"
            [specificDetail]="specificDetail">
        </app-work-done-form>

        <app-order-form *ngSwitchCase="'order'"
            (create)="onCreate($event)"
            (cancel)="onClose()"
            [timesheetId]="parentId"
            [translatePrefix]="translatePrefix"
            [specificDetail]="specificDetail">
        </app-order-form>

        <app-order-delete-form *ngSwitchCase="'orderDelete'"
            (save)="onUpdate($event)"
            (cancel)="onClose()"
            [timesheetId]="parentId"
            [translatePrefix]="translatePrefix"
            [orderGroup]="entity"></app-order-delete-form>

        <app-post-line-form *ngSwitchCase="'postLine'"
            (cancel)="onClose()"
            (create)="onCreate($event)"
            (update)="onUpdate($event)"
            [orderId]="parentId"
            [translatePrefix]="translatePrefix"
            [specificDetail]="specificDetail"
            [postLine]="entity">
        </app-post-line-form>
    </ng-container>
</div>
