/** ------------------------------------------------------ **/
/** |                                                    | **/
/** |          Attention:                                | **/
/** |    / \     On désative la convention de nomage     | **/
/** |   / ! \    car le nom des statuts proviennent      | **/
/** |  /_____\   du backend. (eslint-disable ...)        | **/
/** |                                                    | **/
/** ------------------------------------------------------ **/


export const tagStatusMap = {
    /* eslint-disable @typescript-eslint/naming-convention*/
    exported: {
        type: 'warning',
    },
    closed: {
        type: 'success',
    },
    created: {
        type: 'ongoing',
    },
    attested: {
        type: 'ongoing',
    },
    submitted: {
        type: 'ongoing',
    },
    sentToVendorAccount: {
        type: 'ongoing',
    },
    refused: {
        type: 'error',
    },
    approvedByContractor: {
        type: 'ongoing',
    },
    approvedByHQ: {
        type: 'success',
    },

    ////////////////////////////////////////////////////////////////
    /////////////////  Officiel TimeSheet Status  //////////////////
    ////////////////////////////////////////////////////////////////
    Créée: {
        type: 'ongoing',
    },
    Créé: {
        type: 'ongoing',
    },
    Soumise: {
        type: 'warning',
    },
    A_soumettre: {
        type: 'ongoing',
    },
    ////////////////////////////////////////////////////////////////
    ///////////////////  Officiel Report Status  ///////////////////
    ////////////////////////////////////////////////////////////////
    Crée: { // TODO : changer le texte pour Créé
        type: 'ongoing',
    },
    Modifié: {
        type: 'warning',
    },
    'Refusé par Ent': {
        type: 'error',
    },
    'Apprové par ent': {
        type: 'success',
    },
    'Refusé par HQ': {
        type: 'error',
    },
    'Approuvé par HQ': {
        type: 'success',
    },
    'Approuvé par Ent': {
        type: 'success',
    },
    Facturé: {
        type: 'success',
    },
    Refusé: {
        type: 'error',
    },
    'Approuvé': {
        type: 'success',
    },
    'En attente': {
        type: 'ongoing'
    },
    ////////////////////////////////////////////////////////////////
    ///////////////////  Officiel Pilotage  ////////////////////////
    ////////////////////////////////////////////////////////////////
    'En attente de création': {
        type: 'ongoing'
    },
    'En attente de désactivation': {
        type: 'ongoing'
    },
    'En attente de suppression': {
        type: 'ongoing'
    },
    'En attente de réassignation': {
        type: 'ongoing'
    },
    'En attente de finalisation': {
        type: 'ongoing'
    },
    'En réassignation': {
        type: 'warning'
    },
    'Actif': {
        type: 'success'
    },
    'Inactif': {
        type: 'error'
    },
    'En erreur': {
        type: 'error'
    },
    'Incomplet': {
        type: 'warning'
    },
    'En reprise': {
        type: 'warning'
    },
    ////////////////////////////////////////////////////////////////
    ///////////////////  Officiel Status Rapport de facturation ////
    ////////////////////////////////////////////////////////////////
    'BillingReport.Modifié': {
        type: 'success',
    },
    'BillingReport.Exporté': {
        type: 'warning',
    },
    'BillingReport.Créé': {
        type: 'ongoing',
    },
};
