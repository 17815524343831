import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';

import { RouterStateSerializer } from '@ngrx/router-store';

import { RouterStateUrl } from '../models';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
    // serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    //     let route = routerState.root;
    //     let params = {};

    //     while (route.firstChild) {
    //         route = route.firstChild;
    //         params = { ...params, ...route.params };
    //     }

    //     const { url, root: { queryParams } } = routerState;

    //     return { url, params, queryParams };
    // }
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;
        let params = {};
        let routeData = {};

        while (route.firstChild) {
            route = route.firstChild;
            params = { ...params, ...route.params };
            routeData = { ...routeData, ...route.data };
        }

        const { url, root: { queryParams, data: rootData } } = routerState;
        routeData = { ...routeData, ...rootData };

        return { url, params, queryParams, data: routeData };
    }
}
