import { ApiService } from './api.service';
import { UIService } from './ui.service';

export const ogivCoreServices = [
    ApiService,
    UIService,
];

export * from './api.service';
export * from './ui.service';
