import { TableColumns } from '../../../../../shared/models/table-columns.model';

export const utilisateursSynchronisationColumns: TableColumns[] = [
    { field: 'type', header: 'type' },
    { field: 'replicaOwner', header: 'replicaOwner' },
    { field: 'replicaID', header: 'replicaID' },
    { field: 'creationDate', header: 'creationDate' },
    { field: 'lastSyncDate', header: 'lastSyncDate' },
    { field: 'view', header: 'view' },
];
