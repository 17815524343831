import { Injectable } from '@angular/core';

import { catchError, switchMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { AddOneAppNotification } from '../../../core/store/actions';
import { ApiService } from '../../../ogiv-core/services/api.service';
import { ErrorNotification } from '../../../core/store/models/app-notification.model';
import { BillingItem } from '../../../shared/models/database/billing-item.model';
import {
    LoadBillingItems,
    BillingItemActionType,
    LoadBillingItemsSuccess,
    LoadBillingItemsFail,
    LoadSapBillingItems,
} from '../actions/billing-item.action';

@Injectable()
export class BillingItemsEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public loadBillingItems$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingItems>(BillingItemActionType.LOAD_BILLING_ITEMS),
        switchMap((action: LoadBillingItems) => {
            return this.apiService.get<BillingItem[]>(`${action.path}/rapports/rapportsfacturation/articlesfacturation`).pipe(
                map((returnedBillingItems: BillingItem[]) =>
                    new LoadBillingItemsSuccess(returnedBillingItems)
                ),
                catchError((error: any) => of(new LoadBillingItemsFail(error))),
            );
        }),
    ));

    public loadBillingItemsFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingItemsFail>(BillingItemActionType.LOAD_BILLING_ITEMS_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'billing.notifications.loadItems.error',
            }))
        ]),
    ));

    public loadSapBillingItems$ = createEffect(() => this.actions$.pipe(
        ofType<LoadSapBillingItems>(BillingItemActionType.LOAD_SAP_BILLING_ITEMS),
        switchMap(() => {
            return this.apiService.get<BillingItem[]>(`sap/data/articles`).pipe(
                map((returnedBillingItems: BillingItem[]) =>
                    new LoadBillingItemsSuccess(returnedBillingItems)
                ),
                catchError((error: any) => of(new LoadBillingItemsFail(error))),
            );
        }),
    ));
}
