import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { OgivCoreState, getOgivCoreState } from '../reducers';
import { equipmentCodesAdapter, EquipmentCodesState } from '../reducers/equipment-codes.reducer';
import { EquipmentCode, EquipmentCodeStatus } from '../../../shared/models/database/equipment-code.model';
import { SelectItem } from 'primeng/api';
import moment from 'moment';

const {
    selectAll,
    selectEntities,
    selectIds,
} = equipmentCodesAdapter.getSelectors();

export const getEquipmentCodesState = createSelector(
    getOgivCoreState,
    (state: OgivCoreState) => state.equipmentCodes,
);

export const getEquipmentCodesLoading = createSelector(
    getEquipmentCodesState,
    (state: EquipmentCodesState) => state.loading,
);

export const getEquipmentCodesLoaded = createSelector(
    getEquipmentCodesState,
    (state: EquipmentCodesState) => state.loaded,
);

export const shouldLoadEquipmentCodes = createSelector(
    getEquipmentCodesLoading,
    getEquipmentCodesLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllEquipmentCodes = createSelector(
    getEquipmentCodesState,
    selectAll
);

export const getEquipmentCodesEntities = createSelector(
    getEquipmentCodesState,
    selectEntities,
);

export const getEquipmentCodesIds = createSelector(
    getEquipmentCodesState,
    selectIds,
);

export const getEquipmentCodeById = (prop: any) => createSelector(
    getEquipmentCodesEntities,
    (entities: Dictionary<EquipmentCode>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllEquipmentCodesSorted = createSelector(
    getAllEquipmentCodes,
    (equipmentCode: EquipmentCode[]): EquipmentCode[] => {
        const temporaire = [...equipmentCode];
        temporaire.sort((a: EquipmentCode, b: EquipmentCode): number => {
            return (a.code.toLocaleLowerCase()).localeCompare(b.code.toLocaleLowerCase());
        });
        return temporaire;
    }
);

export const getActiveEquipmentCodes = (props: { date: string } = { date: '' }) => createSelector(
    getAllEquipmentCodesSorted,
    (equipmentCodes: EquipmentCode[]): EquipmentCode[] => {
        const activeEquipmentCodes = getAllActiveEquipmentCodes(equipmentCodes, props.date);
        return activeEquipmentCodes;
    }
);

export const doesSelectedEquipmentCodeIsActive = (props: { code: string } = { code: '' }) => createSelector(
    getActiveEquipmentCodes(),
    (equipmentCodes: EquipmentCode[]): boolean => {
        const found = (equipmentCodes.filter((equipmentCode: EquipmentCode) => equipmentCode.code === props.code));
        return found.length > 0;
    }
);

export const getEquipmentCodeDescriptionSelectOptions = (props: any = {}) => createSelector(
    getAllEquipmentCodesSorted,
    (equipmentCode: EquipmentCode[]): SelectItem[] => {
        const equipmentCodeOptions = equipmentCode.map((ec: EquipmentCode) => {
            return {
                label: ec.description,
                value: ec.id
            } as SelectItem;
        });

        if (props.addEmptyOption) {
            equipmentCodeOptions.unshift({ label: 'Sélectionner un code d\'équipement', value: null } as SelectItem);
        }

        return equipmentCodeOptions;
    }
);

export const getEquipmentCodeCodeSelectOptions = (props: any = {}) => createSelector(
    getAllEquipmentCodesSorted,
    (equipmentCode: EquipmentCode[]): SelectItem[] => {
        const equipmentCodeOptions = equipmentCode.map((ec: EquipmentCode) => {
            return {
                label: ec.code,
                value: ec.id
            } as SelectItem;
        });

        if (props.addEmptyOption) {
            equipmentCodeOptions.unshift({ label: 'Sélectionner un code d\'équipement', value: null } as SelectItem);
        }

        return equipmentCodeOptions;
    }
);

export const getEquipmentCodeObjectOptions = (props: { addEmptyOption?: boolean, date?: string } = {}) => createSelector(
    getAllEquipmentCodesSorted,
    (equipmentCode: EquipmentCode[]) => {
        const activeEquipmentCodes = getAllActiveEquipmentCodes(equipmentCode, props.date);
        const equipmentCodeOptions = activeEquipmentCodes.map((ec: EquipmentCode) => {
            return {
                id: ec.id,
                code: ec.code,
                description: ec.description,
                status: ec.status,
                date_inactif: ec.date_inactif
            } as EquipmentCode;
        });

        if (props.addEmptyOption) {
            equipmentCodeOptions.unshift({
                id: 0,
                code: '',
                description: 'Sélectionner un code d\'équipement',
                status: EquipmentCodeStatus.active,
                date_inactif: undefined
            } as EquipmentCode);
        }

        return equipmentCodeOptions;
    }
);

export const getEquipmentCodeByCode = (props: { code?: string, date?: string } = {}) => createSelector(
    getAllEquipmentCodesSorted,
    (equipmentCodes: EquipmentCode[]): EquipmentCode | null => {
        const activeEquipmentCodes = getAllActiveEquipmentCodes(equipmentCodes, props.date);
        const filteredEquipmentCodes = activeEquipmentCodes.filter((equipmentCode: EquipmentCode) =>
            equipmentCode.code === props.code);

        return filteredEquipmentCodes.length === 1 ? filteredEquipmentCodes[0] : null;
    }
);

export const getEquipmentCodeSearchOptions = (props: { addEmptyOption?: boolean, text: string, date?: string } = { text: '' }) => createSelector(
    getAllEquipmentCodesSorted,
    (equipmentCodes: EquipmentCode[]) => {
        const activeEquipmentCodes = getAllActiveEquipmentCodes(equipmentCodes, props.date);
        const filteredEquipmentCode = activeEquipmentCodes.filter(equipmentCode => {
            return (equipmentCode.code.toUpperCase().includes(props.text.toUpperCase()) || equipmentCode.description.toUpperCase().includes(props.text.toUpperCase())
            );
        });
        const equipmentCodeOptions = filteredEquipmentCode.map((ec: EquipmentCode) => {
            return {
                id: ec.id,
                code: ec.code,
                date_inactif: ec.date_inactif,
                description: ec.description,
                status: ec.status,
            } as EquipmentCode;
        });

        if (props.addEmptyOption) {
            equipmentCodeOptions.unshift({
                id: 0,
                code: '',
                description: 'Sélectionner un code d\'équipement',
                status: EquipmentCodeStatus.active,
            } as EquipmentCode);
        }

        return equipmentCodeOptions;
    }
);

function getAllActiveEquipmentCodes(codes: EquipmentCode[], date?: string): EquipmentCode[] {
    let activeCodes;
    if (date === undefined || date === '') {
        activeCodes = codes.filter(code => code.status.toLocaleLowerCase() === EquipmentCodeStatus.active.toLocaleLowerCase());
    } else {
        activeCodes = codes.filter(code => code.status.toLocaleLowerCase() === EquipmentCodeStatus.active.toLocaleLowerCase()
            || moment(code.date_inactif || '1900-01-01').format('YYYYMMDD') > date);
    }
    return activeCodes;
}
