<app-element-loader *ngIf="unitsLoading$ | async" [size]="50"></app-element-loader>
<mat-list>
    <mat-list-item class="listItem" *ngFor="let unit of units; trackBy: workTrackById; let workDoneIndex = index" >
        <div class="listItem__work">
            <div class="work__info" (click)="editRecord(unit)" (mouseover)="mouseOver(workDoneIndex)" (mouseout)="mouseOut()" [class.hover]="workDoneIndex === workDoneOverIndex">
                <i class="fas fa-list-ol info__logo" [class.isLock]="isLock" ></i>
                <div class="info__info">
                    <div class="info__work">
                        <div class="work__container" [class.isLock]="isLock">
                            <div class="container__title left">
                                {{'timesheet.order.tab.accordionTitle.unitOfWorkDone.list.code' | translate}}: {{unit.code}}
                            </div>
                            <div class="container__title right">
                                {{'timesheet.order.tab.accordionTitle.unitOfWorkDone.list.quantity_abrv' | translate}}:
                                {{unit.quantity}}
                            </div>
                        </div>
                        <div class="work__description" [class.isLock]="isLock">{{unit.description | addLineBreaksSlashes}}</div>

                    </div>
                    <div *ngIf="canAddEditTime" class="info__time">
                        <div class="time__logo" [class.isLock]="isLock">
                            <i class="far fa-clock"></i>
                        </div>
                        <div class="time__hour" [class.isLock]="isLock">
                            {{unit.hour}}
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *hasRole="editAllowedRoles">
                <div *ngIf="analyseDisplayMenu(unit)" class="work__action">
                    <div class="action__menu">
                        <app-generic-other-menu class="action__menu"
                            [editOnly]="workDoneIndex === workDoneOverIndex"
                            [hoverButtonOrMenu]="workDoneIndex === workDoneOverIndex"
                            [addborder]="true"
                            [isLock]="isLock"
                            [displayMenu]="!((workDoneIndex === workDoneOverIndex) && displayItemMenu)"
                            [hideItems]="hideMenuItems"
                            (edit)="editRecord(unit)"
                            (delete)="deleteRecord(unit)" >
                        </app-generic-other-menu>
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-list-item>
</mat-list>
