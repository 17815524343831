import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { TimesheetReport } from '../../../shared/models/database/timesheet-report.model';
import { TimesheetReportsAction, TimesheetReportActionTypes } from '../actions/timesheet-reports.action';

export interface TimesheetReportsState extends EntityState<TimesheetReport> {
    loading: boolean;
    loaded: boolean;
}

export const timesheetReportsAdapter: EntityAdapter<TimesheetReport> = createEntityAdapter<TimesheetReport>({
    selectId: (timesheet: TimesheetReport) => {
        const timesheeReporttInstance = new TimesheetReport(timesheet);
        return timesheeReporttInstance.getUniqueId();
    }
});

export const timesheetReportsInitialState = timesheetReportsAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function timesheetReportsReducer(
    state: TimesheetReportsState = timesheetReportsInitialState,
    action: TimesheetReportsAction
): TimesheetReportsState {
    switch (action.type) {
        case TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID_AND_SITE_NAME:
        case TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID:
        case TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT:
        case TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT:
        case TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_TECH:
        case TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_TECH:
        case TimesheetReportActionTypes.REMARK_TIMESHEET_REPORT: {
            return {
                ...state,
                loading: true,
            };
        }

        case TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_TECH_SUCCESS:
        case TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_TECH_SUCCESS:
        case TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_SUCCESS:
        case TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_SUCCESS:
        case TimesheetReportActionTypes.REMARK_TIMESHEET_REPORT_SUCCESS:
        case TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID_AND_SITE_NAME_SUCCESS:
        case TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID_SUCCESS: {
            return timesheetReportsAdapter.upsertOne(action.timesheetReport, {
                ...state,
                loading: false,
            });
        }

        case TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_FAIL:
        case TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_FAIL:
        case TimesheetReportActionTypes.APPROVE_TIMESHEET_REPORT_TECH_FAIL:
        case TimesheetReportActionTypes.REFUSE_TIMESHEET_REPORT_TECH_FAIL:
        case TimesheetReportActionTypes.REMARK_TIMESHEET_REPORT_FAIL:
        case TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID_AND_SITE_NAME_FAIL:
        case TimesheetReportActionTypes.LOAD_TIMESHEET_REPORT_BY_ID_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
