import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { SelectItem } from 'primeng/api';

import { billingOrdersAdapter, BillingOrdersState } from '../reducers/billing-order.reducer';
import { BillingState, getBillingState } from '../reducers';
import { BillingOrder } from '../../../shared/models/database/billing-order.model';

const {
    selectAll,
    selectEntities,
    selectIds,
} = billingOrdersAdapter.getSelectors();

export const getBillingOrdersState = createSelector(
    getBillingState,
    (state: BillingState) => state.billingOrders,
);

export const getBillingOrdersLoading = createSelector(
    getBillingOrdersState,
    (state: BillingOrdersState) => state.loading,
);

export const getBillingOrdersLoaded = createSelector(
    getBillingOrdersState,
    (state: BillingOrdersState) => state.loaded,
);

export const shouldLoadBillingOrders = createSelector(
    getBillingOrdersLoading,
    getBillingOrdersLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllBillingOrders = createSelector(
    getBillingOrdersState,
    selectAll
);

export const getBillingOrdersEntities = createSelector(
    getBillingOrdersState,
    selectEntities,
);

export const getBillingOrdersIds = createSelector(
    getBillingOrdersState,
    selectIds,
);

export const getBillingOrderById = (prop: any) => createSelector(
    getBillingOrdersEntities,
    (entities: Dictionary<BillingOrder>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllBillingOrdersSorted = createSelector(
    getAllBillingOrders,
    (billingOrders: BillingOrder[]): BillingOrder[] => {
        const temporaire = [...billingOrders];
        temporaire.sort((a: BillingOrder, b: BillingOrder): number => {
            return (a.order.toLocaleLowerCase()).localeCompare(b.order.toLocaleLowerCase());
        });
        return temporaire;
    }
);

export const getDistinctBillingOrdersSelectOptions = createSelector(
    getAllBillingOrdersSorted,
    (billingOrders: BillingOrder[]): SelectItem[] => {
        const distinctBillingOrders: BillingOrder[] = [];
        billingOrders.forEach((billingOrder: BillingOrder) => {
            if (!distinctBillingOrders.find((distinctOrder) => distinctOrder.order === billingOrder.order)) {
                distinctBillingOrders.push(billingOrder);
            }
        });
        const distinctBillingOrdersOptions = distinctBillingOrders.map((billingOrder: BillingOrder) => {
            return {
                label: billingOrder.order,
                value: billingOrder.order
            } as SelectItem;
        });

        return distinctBillingOrdersOptions;
    }
);

export const getAccountingEntrySelectOptions = (props: { selectedOrder?: string } = { selectedOrder: '' }) => createSelector(
    getAllBillingOrdersSorted,
    (billingOrders: BillingOrder[]): SelectItem[] => {
        const { selectedOrder } = props;
        let orderAccountingEntry: BillingOrder[] = [];
        if (selectedOrder && selectedOrder.length > 0) {
            orderAccountingEntry = billingOrders.filter((billingOrder: BillingOrder) => billingOrder.order === selectedOrder);
        }

        const temporaireAccountingEntry = [...orderAccountingEntry];
        temporaireAccountingEntry.sort((a: BillingOrder, b: BillingOrder): number => {
            return (a.accountingEntry.toLocaleLowerCase()).localeCompare(b.accountingEntry.toLocaleLowerCase());
        });

        const accountingEntryOptions = temporaireAccountingEntry.map((billingOrder: BillingOrder) => {
            return {
                label: billingOrder.accountingEntry,
                value: billingOrder.accountingEntry
            } as SelectItem;
        });

        return accountingEntryOptions;
    }
);
