import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UtilisateurExterne } from '../../models/utilisateur-externe.model';
import {
    UtilisateurExterneAction,
    UtilisateurExterneActionType
} from '../actions';

export interface UtilisateursExternesState extends EntityState<UtilisateurExterne> {
    loading: boolean;
    loaded: boolean;
}

export const utilisateursExternesAdapter: EntityAdapter<UtilisateurExterne> = createEntityAdapter<UtilisateurExterne>({
    selectId: (utilisateurExterne: UtilisateurExterne) => utilisateurExterne.id,
});

export const utilisateursExternesInitialState = utilisateursExternesAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function utilisateurExterneReducer(
    state: UtilisateursExternesState = utilisateursExternesInitialState,
    action: UtilisateurExterneAction,
): UtilisateursExternesState {
    switch (action.type) {
        case UtilisateurExterneActionType.VERIFY_FDT_INCOMPLETES:
        case UtilisateurExterneActionType.REASSIGN_ENTREPRISE:
        case UtilisateurExterneActionType.FINALIZE_USER_CREATION:
        case UtilisateurExterneActionType.UPDATE_ONE_UTILISATEUR_EXTERNE:
        case UtilisateurExterneActionType.CREATE_UTILISATEUR_EXTERNE:
        case UtilisateurExterneActionType.DELETE_ONE_UTILISATEUR_EXTERNE:
        case UtilisateurExterneActionType.LOAD_UTILISATEURS_EXTERNES: {
            return {
                ...state,
                loading: true,
            };
        }

        case UtilisateurExterneActionType.VERIFY_FDT_INCOMPLETES_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }

        case UtilisateurExterneActionType.REASSIGN_ENTREPRISE_SUCCESS: {
            return utilisateursExternesAdapter.upsertMany(action.utilisateursExternes, {
                ...state,
                loading: false,
            });
        }

        case UtilisateurExterneActionType.FINALIZE_USER_CREATION_SUCCESS:
        case UtilisateurExterneActionType.CREATE_UTILISATEUR_EXTERNE_SUCCESS:
        case UtilisateurExterneActionType.UPDATE_ONE_UTILISATEUR_EXTERNE_SUCCESS: {
            return utilisateursExternesAdapter.upsertOne(action.utilisateurExterne, {
                ...state,
                loading: false,
            });
        }

        case UtilisateurExterneActionType.VERIFY_FDT_INCOMPLETES_FAIL:
        case UtilisateurExterneActionType.REASSIGN_ENTREPRISE_FAIL:
        case UtilisateurExterneActionType.FINALIZE_USER_CREATION_FAIL:
        case UtilisateurExterneActionType.DELETE_ONE_UTILISATEUR_EXTERNE_FAIL:
        case UtilisateurExterneActionType.UPDATE_ONE_UTILISATEUR_EXTERNE_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case UtilisateurExterneActionType.DELETE_ONE_UTILISATEUR_EXTERNE_SUCCESS: {
            return utilisateursExternesAdapter.removeOne(action.deletedId, {
                ...state,
                loading: false,
                loaded: true
            });
        }

        case UtilisateurExterneActionType.LOAD_UTILISATEURS_EXTERNES_SUCCESS: {
            return utilisateursExternesAdapter.setAll(action.utilisateursExternes, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case UtilisateurExterneActionType.LOAD_UTILISATEURS_EXTERNES_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case UtilisateurExterneActionType.LOAD_UTILISATEURS_EXTERNES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;

    }
}
