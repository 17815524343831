import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { SpecialMeasuresState, specialMeasuresStoreReducers } from './reducers';

export const SPECIAL_MEASURES_TOKEN = new InjectionToken<ActionReducerMap<SpecialMeasuresState>>('SpecialMeasures reducers');

export function getSpecialMeasuresReducers(): ActionReducerMap<SpecialMeasuresState> {
    return specialMeasuresStoreReducers;
}
