import { Component, Output, EventEmitter, Input, OnDestroy, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { Subscription, Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { SelectItem } from 'primeng/api';

import { Worker } from '../../../../../shared/models/database/worker.model';
import { Arborist } from '../../../../../shared/models/database/arborist.model';
import { JobCode } from '../../../../../shared/models/database/job-code.model';
import { LoadArborists, LoadJobClasses } from '../../../../store/actions';
import { LoadTransportCodes, LoadMealCodes, LoadJobCodes } from '../../../../../ogiv-core/store/actions';
import { JobClass } from '../../../../../shared/models/database/job-class.model';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import {
    getActiveArboristOptions,
    getJobClassById,
    getActiveJobClassesOptions,
    getArboristById,
    getArboristExistById
} from '../../../../store/selectors';
import {
    getJobCodeByCodeAndDescription,
    getActiveTransportCodesOptions,
    getActiveMealCodesOptions,
    getActiveJobCodesFullObjectOptions,
    getActiveJobCodesSearchFullObjectOptions
} from '../../../../../ogiv-core/store/selectors';

@Component({
    selector: 'app-team-mate-form',
    templateUrl: './team-mate-form.component.html',
    styleUrls: ['./team-mate-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamMateFormComponent implements OnInit, OnDestroy, OnChanges {
    public prefix = 'timesheet.order.tab.accordionTitle.team.form';
    public tabIndex = 0;
    public profileFrom: FormGroup;
    public isTransportAllow = false;
    public isMealAllow = false;
    public isEmployeeFoundAndDisplayDropDown = true;
    public currentJobSAPCode: JobCode | null;

    private subscriptions: Subscription[] = [];

    @Input() public worker: Worker | null;
    @Input() public orderId: number | null;
    @Input() public canAddEditTime = true;
    @Input() public timesheetDateId: string;
    @Input() public reloadJobClassData: boolean;
    @Input() public freezeTabIndex = false;
    @Input() public pathChoice: AlternativePath;
    @Input() public specificDetail: {} | null;

    @Output() public create: EventEmitter<Worker> = new EventEmitter<Worker>();
    @Output() public update: EventEmitter<Worker> = new EventEmitter<Worker>();
    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public isCreateMode: EventEmitter<string> = new EventEmitter<string>();

    public jobListOption$: Observable<SelectItem[]>;
    public jobClassListOption$: Observable<SelectItem[]>;
    public transportCodeOptions$: Observable<SelectItem[]>;
    public mealCodeOptions$: Observable<SelectItem[]>;

    public employeeListOption$: Observable<SelectItem<number>[]> = this.store.pipe(
        select(getActiveArboristOptions({ addEmptyOption: true })),
    );

    constructor(
        formBuilder: FormBuilder,
        private readonly store: Store
    ) {
        this.jobClassListOption$ = this.store.pipe(
            select(getActiveJobClassesOptions({ addEmptyOption: true, addCreateOption: true }))
        );

        this.profileFrom = formBuilder.group({
            employeeDropdown: [0],
            jobDropdown: [''],
            jobClassDropdown: [''],
            id: [null],
            orderId: [null],
            idEmploye: ['', [Validators.required]],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            jobSAPId: [null],
            jobSAPCode: [''],
            jobSAPDescr: [''],
            jobClassId: [null, [Validators.required]],
            jobClassCode: ['', [Validators.required]],
            jobClassDescr: ['', [Validators.required]],
            hour: [0],
            mealCode: [''],
            mealQuantity: [0],
            transportationCode: [''],
            transportationQuantity: [0],
            searchJob: [''],
        });
    }

    public ngOnInit(): void {
        this.store.dispatch(new LoadTransportCodes());
        this.store.dispatch(new LoadArborists(this.pathChoice));
        this.store.dispatch(new LoadJobCodes());
        this.store.dispatch(new LoadMealCodes());
        this.store.dispatch(new LoadJobClasses(this.pathChoice));

        this.jobListOption$ = this.store.pipe(
            select(getActiveJobCodesFullObjectOptions({ addEmptyOption: true, date: this.timesheetDateId }))
        );

        this.transportCodeOptions$ = this.store.pipe(
            select(getActiveTransportCodesOptions({ date: this.timesheetDateId }))
        );

        this.mealCodeOptions$ = this.store.pipe(
            select(getActiveMealCodesOptions({ date: this.timesheetDateId }))
        );

        this.subscriptions.push(
            this.profileFrom.controls.employeeDropdown.valueChanges.pipe(
                tap((selectedEmployeeId: number) => {
                    if (selectedEmployeeId === 0) {
                        this.profileFrom.controls.firstName.setValue('');
                        this.profileFrom.controls.lastName.setValue('');
                        this.profileFrom.controls.idEmploye.setValue(0);
                        this.profileFrom.updateValueAndValidity();
                    }
                }),
                filter((selectedEmployeeId: number) => !!selectedEmployeeId),
                switchMap((selectedEmployeeId: number) => {
                    return this.store.pipe(
                        select(getArboristById({ entityId: selectedEmployeeId })),
                        tap((foundEmployee: Arborist | null) => {
                            if (foundEmployee !== null) {
                                this.profileFrom.controls.firstName.setValue(foundEmployee.firstName);
                                this.profileFrom.controls.lastName.setValue(foundEmployee.lastName);
                                this.profileFrom.controls.idEmploye.setValue(foundEmployee.id);
                                this.profileFrom.updateValueAndValidity();
                            } else {
                                this.profileFrom.controls.employeeDropdown.setValue(0);
                                if (selectedEmployeeId !== 0) {
                                    this.isEmployeeFoundAndDisplayDropDown = false;
                                }
                            }
                        }),
                    );
                })
            ).subscribe(),

            this.profileFrom.controls.jobDropdown.valueChanges.pipe(
                tap((selectedJobSAPCode: JobCode) => {
                    if (selectedJobSAPCode.code === '') {
                        this.profileFrom.controls.jobSAPId.setValue(0);
                        this.profileFrom.controls.jobSAPCode.setValue('');
                        this.profileFrom.controls.jobSAPDescr.setValue('');
                        this.profileFrom.updateValueAndValidity();
                    }
                }),
                switchMap((selectedJobSAPCode: JobCode) => {
                    return this.store.pipe(
                        select(getJobCodeByCodeAndDescription({
                            code: selectedJobSAPCode.code,
                            description: selectedJobSAPCode.description,
                            // eslint-disable-next-line
                            date: this.timesheetDateId
                        })),
                        tap((foundJobSAPCode: JobCode | null) => {
                            if (foundJobSAPCode !== null) {
                                this.profileFrom.controls.jobSAPId.setValue(foundJobSAPCode.id);
                                this.profileFrom.controls.jobSAPCode.setValue(foundJobSAPCode.code);
                                this.profileFrom.controls.jobSAPDescr.setValue(foundJobSAPCode.description);
                                this.profileFrom.updateValueAndValidity();
                            } else {
                                this.profileFrom.controls.jobSAPId.setValue(0);
                                this.profileFrom.controls.jobSAPCode.setValue('');
                                this.profileFrom.controls.jobSAPDescr.setValue('');
                                this.profileFrom.updateValueAndValidity();
                            }
                        }),
                    );
                })
            ).subscribe(),

            this.profileFrom.controls.searchJob.valueChanges.pipe(
                tap((textTosearch: string) => {
                    this.jobListOption$ = this.store.pipe(
                        select(getActiveJobCodesSearchFullObjectOptions({ addEmptyOption: true, text: textTosearch, date: this.timesheetDateId })),
                    );
                }),
            ).subscribe(),

            this.profileFrom.controls.jobClassDropdown.valueChanges.pipe(
                tap((selectedJobClassId: number) => {
                    if (selectedJobClassId === 0) {
                        this.profileFrom.controls.jobClassId.setValue(0);
                        this.profileFrom.controls.jobClassCode.setValue('');
                        this.profileFrom.controls.jobClassDescr.setValue('');
                        this.profileFrom.updateValueAndValidity();
                    } else if (selectedJobClassId === null) {
                        this.createNewJobClass();
                    }
                }),
                filter((selectedJobClassId: any) => selectedJobClassId !== null && selectedJobClassId !== 0 && selectedJobClassId !== ''),
                switchMap((selectedJobClassId: number) => {
                    return this.store.pipe(
                        select(getJobClassById({ entityId: selectedJobClassId })),
                        tap((foundJobClass: JobClass | null) => {
                            if (foundJobClass !== null) {
                                this.profileFrom.controls.jobClassId.setValue(foundJobClass.id);
                                this.profileFrom.controls.jobClassCode.setValue(foundJobClass.code);
                                this.profileFrom.controls.jobClassDescr.setValue(foundJobClass.description);
                                this.profileFrom.updateValueAndValidity();
                            } else {
                                this.profileFrom.controls.jobClassDropdown.setValue(null);
                            }
                        }),
                    );
                })
            ).subscribe(),
        );
    }

    public createNewJobClass(): void {
        this.tabIndex = -1;
        this.isCreateMode.emit('timesheet.order.tab.accordionTitle.team.createForm.title');
    }

    public displayDropDown(idEmploye: number): void {
        this.subscriptions.push(
            this.store.select(getArboristExistById({ id: idEmploye })).subscribe((userExist: boolean) => {
                if (userExist) {
                    this.profileFrom.controls.employeeDropdown.setValue(idEmploye);
                } else {
                    this.isEmployeeFoundAndDisplayDropDown = false;
                }
            })
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.worker && changes.worker.currentValue) {
            const worker = changes.worker.currentValue;
            this.setWorkerValues(worker);

            this.subscriptions.push(
                this.store.pipe(
                    select(getJobCodeByCodeAndDescription({ code: worker.jobSAPCode, description: worker.jobSAPDescr, date: this.timesheetDateId })),
                ).subscribe(data => this.profileFrom.controls.jobDropdown.setValue(data))
            );
        }

        if (changes && changes.specificDetail && changes.specificDetail.currentValue) {
            if (changes.specificDetail.currentValue.idEmploye) {
                this.displayDropDown(changes.worker.currentValue.idEmploye);
            }
        }

        if (changes && changes.orderId && changes.orderId.currentValue) {
            this.profileFrom.controls.orderId.setValue(changes.orderId.currentValue);
        }

        if (changes && changes.canAddEditTime) {
            if (changes.canAddEditTime.currentValue) {
                this.profileFrom.controls.hour.setValidators([Validators.required, Validators.min(0.25), Validators.max(24)]);
                this.profileFrom.controls.hour.updateValueAndValidity();
                this.profileFrom.controls.jobSAPCode.setValidators([Validators.required]);
                this.profileFrom.controls.jobSAPCode.updateValueAndValidity();
                this.profileFrom.controls.jobSAPDescr.setValidators([Validators.required]);
                this.profileFrom.controls.jobSAPDescr.updateValueAndValidity();
            } else {
                this.profileFrom.controls.hour.clearValidators();
                this.profileFrom.controls.hour.updateValueAndValidity();
                this.profileFrom.controls.jobSAPCode.updateValueAndValidity();
                this.profileFrom.controls.jobSAPDescr.updateValueAndValidity();
            }
        }

        if (changes && changes.reloadJobClassData && changes.reloadJobClassData.currentValue) {
            this.store.dispatch(new LoadJobClasses(this.pathChoice));
            this.profileFrom.controls.jobClassDropdown.setValue('');
        }

        if (changes && changes.freezeTabIndex && changes.freezeTabIndex.currentValue !== undefined && changes.freezeTabIndex.currentValue !== null) {
            this.tabIndex = changes.freezeTabIndex.currentValue ? -1 : 0;
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public clearTransportationProperties(): void {
        this.profileFrom.controls.transportationQuantity.clearValidators();
        this.profileFrom.controls.transportationQuantity.setValue(0);
        this.profileFrom.controls.transportationQuantity.updateValueAndValidity();

        this.profileFrom.controls.transportationCode.clearValidators();
        this.profileFrom.controls.transportationCode.setValue(null);
        this.profileFrom.controls.transportationCode.updateValueAndValidity();
    }

    public setTransportationProperties(): void {
        this.profileFrom.controls.transportationQuantity.setValidators([Validators.required, Validators.min(1)]);
        this.profileFrom.controls.transportationQuantity.updateValueAndValidity();

        this.profileFrom.controls.transportationCode.setValidators(Validators.required);
        this.profileFrom.controls.transportationCode.updateValueAndValidity();
    }

    public toggleCar(slideToggle: MatSlideToggleChange): void {
        if (!slideToggle.checked) {
            this.clearTransportationProperties();
        } else {
            this.setTransportationProperties();
        }
    }

    public clearMealProperties(): void {
        this.profileFrom.controls.mealQuantity.clearValidators();
        this.profileFrom.controls.mealQuantity.setValue(0);
        this.profileFrom.controls.mealQuantity.updateValueAndValidity();

        this.profileFrom.controls.mealCode.clearValidators();
        this.profileFrom.controls.mealCode.setValue(null);
        this.profileFrom.controls.mealCode.updateValueAndValidity();
    }

    public setMealProperties(): void {
        this.profileFrom.controls.mealQuantity.setValidators([Validators.required, Validators.min(1)]);
        this.profileFrom.controls.mealQuantity.updateValueAndValidity();

        this.profileFrom.controls.mealCode.setValidators(Validators.required);
        this.profileFrom.controls.mealCode.updateValueAndValidity();
    }

    public toggleMeal(slideToggle: MatSlideToggleChange): void {
        if (!slideToggle.checked) {
            this.clearMealProperties();
        } else {
            this.setMealProperties();
        }
    }

    public onSubmit(): void {
        if (this.worker !== null && this.worker.id) {
            this.updateEntity();
        } else {
            this.createEntity();
        }
    }

    public createEntity(): void {
        const { valid } = this.profileFrom;
        if (valid) {
            this.tabIndex = 0;
            const { employeeDropdown, jobDropdown, jobClassDropdown, searchJob, ...cleanedContent } = this.profileFrom.value;
            this.create.emit(cleanedContent);
        }
    }

    public updateEntity(): void {
        const { valid, touched } = this.profileFrom;

        const value = {
            ...this.worker,
            ...this.profileFrom.value,
        };

        if (valid && touched) {
            this.tabIndex = 0;
            const { employeeDropdown, jobDropdown, jobClassDropdown, searchJob, ...cleanedContent } = value;
            this.update.emit(cleanedContent);
        }
    }

    public cancelForm(): void {
        this.tabIndex = 0;
        this.cancel.emit(true);
    }

    private setWorkerValues(worker: Worker): void {
        this.isTransportAllow = worker.transportationQuantity > 0;
        this.isMealAllow = worker.mealQuantity > 0;

        this.profileFrom.reset(worker);
        // this.profileFrom.controls.jobDropdown.setValue(this.currentJobSAPCode);
        this.profileFrom.controls.jobClassDropdown.setValue(worker.jobClassId);

        if (worker.transportationCode && worker.transportationCode !== '') {
            this.profileFrom.controls.transportationCode.setValue(worker.transportationCode);
        }

        if (worker.mealCode && worker.mealCode !== '') {
            this.profileFrom.controls.mealCode.setValue(worker.mealCode);
        }

        this.isTransportAllow ? this.setTransportationProperties() : this.clearTransportationProperties();
        this.isMealAllow ? this.setMealProperties() : this.clearMealProperties();
    }
}
