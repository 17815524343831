import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { GestionCodesSAP } from '../../models/gestion-code-sap.model';

/**
 * Types
 */
export enum GestionCodesSAPActionType {
    LOAD_GESTION_CODES_SAP = '[GestionCodesSAP] Load GestionCodesSAP',
    LOAD_GESTION_CODES_SAP_SUCCESS = '[GestionCodesSAP] Load GestionCodesSAP Success',
    LOAD_GESTION_CODES_SAP_FAIL = '[GestionCodesSAP] Load GestionCodesSAP Fail',
    LOAD_GESTION_CODES_SAP_NO_RESULTS = '[GestionCodesSAP] Load GestionCodesSAP No Result',

    UPDATE_ONE_GESTION_CODES_SAP = '[GestionCodesSAP] Update One GestionCodesSAP',
    UPDATE_ONE_GESTION_CODES_SAP_SUCCESS = '[GestionCodesSAP] Update One GestionCodesSAP Success',
    UPDATE_ALL_GESTION_CODES_SAP_SUCCESS = '[GestionCodesSAP] Update ALL GestionCodesSAP Success',
    UPDATE_ONE_GESTION_CODES_SAP_FAIL = '[GestionCodesSAP] Update One GestionCodesSAP Fail',

    DEACTIVATE_ONE_GESTION_CODES_SAP = '[GestionCodesSAP] Deactivate One GestionCodesSAP',
    DEACTIVATE_ONE_GESTION_CODES_SAP_SUCCESS = '[GestionCodesSAP] Deactivate One GestionCodesSAP Success',
    DEACTIVATE_ONE_GESTION_CODES_SAP_FAIL = '[GestionCodesSAP] Deactivate One GestionCodesSAP Fail',
}

/**
 *  ACTIONS
 */

/**
 * LOAD
 */

export class LoadGestionCodesSAP implements Action {
    readonly type = GestionCodesSAPActionType.LOAD_GESTION_CODES_SAP;
    constructor(public path: AlternativePath) { }
}

export class LoadGestionCodesSAPNoResult implements Action {
    readonly type = GestionCodesSAPActionType.LOAD_GESTION_CODES_SAP_NO_RESULTS;
}

export class LoadGestionCodesSAPSuccess implements Action {
    readonly type = GestionCodesSAPActionType.LOAD_GESTION_CODES_SAP_SUCCESS;
    constructor(public gestionCodesSAP: GestionCodesSAP[]) { }
}

export class LoadGestionCodesSAPFail implements Action {
    readonly type = GestionCodesSAPActionType.LOAD_GESTION_CODES_SAP_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneGestionCodeSAP implements Action {
    readonly type = GestionCodesSAPActionType.UPDATE_ONE_GESTION_CODES_SAP;
    constructor(public gestionCodeSAP: GestionCodesSAP, public path: AlternativePath) { }
}

export class UpdateOneGestionCodeSAPSuccess implements Action {
    readonly type = GestionCodesSAPActionType.UPDATE_ONE_GESTION_CODES_SAP_SUCCESS;
    constructor(public gestionCodeSAP: GestionCodesSAP) { }
}

export class UpdateAllGestionCodeSAPSuccess implements Action {
    readonly type = GestionCodesSAPActionType.UPDATE_ALL_GESTION_CODES_SAP_SUCCESS;
    constructor(public gestionCodesSAP: GestionCodesSAP[]) { }
}

export class UpdateOneGestionCodeSAPFail implements Action {
    readonly type = GestionCodesSAPActionType.UPDATE_ONE_GESTION_CODES_SAP_FAIL;
    constructor(public error: any) { }
}

export type GestionCodesSAPAction =
    | LoadGestionCodesSAP
    | LoadGestionCodesSAPNoResult
    | LoadGestionCodesSAPSuccess
    | LoadGestionCodesSAPFail
    | UpdateOneGestionCodeSAP
    | UpdateOneGestionCodeSAPSuccess
    | UpdateAllGestionCodeSAPSuccess
    | UpdateOneGestionCodeSAPFail;
