import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalData } from './confirm-modal-data.model';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
    public title: string;
    public message: string;
    public subMessage: string | null = null;
    public question: string | null = null;
    public translatePrefix: string;
    public logoString: string | null;
    public isForDelete: boolean;
    public displayDeleteButton = true;
    public logoFontSize: number;
    public isSubMessageBold = false;

    constructor(
        public dialogRef: MatDialogRef<ConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmModalData
    ) {
        this.title = this.data.title;
        this.message = this.data.message;
        this.subMessage = this.data.subMessage || null;
        this.question = this.data.question || null;
        this.translatePrefix = this.data.translatePrefix;
        this.logoString = this.data.logoString || null;
        this.isForDelete = this.data.isForDelete || false;
        this.displayDeleteButton = this.data.displayDeleteButton !== undefined ? this.data.displayDeleteButton : true;
        this.logoFontSize = this.data.logoFontSize !== undefined ? this.data.logoFontSize : 20;
        this.isSubMessageBold = this.data.isSubMessageBold || false;
    }

    public close(value: any): void {
        this.dialogRef.close(value);
    }

    public confirm(): void {
        this.dialogRef.close(true);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
