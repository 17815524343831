<app-element-loader *ngIf="gestionCodesSAPLoading$ | async"
    [size]="loaderLogoSize"
    [displayLabel]="true"
    [labelFontSize]="20"></app-element-loader>
<p-table #gestionCodeSAPDataTable [columns]="columns" [value]="$any(gestionCodesSAP$ | async)" [paginator]="true" [rows]="10" editMode="row"
    dataKey="id"[sortField]="sortField" [sortOrder]="sortOrder" [rowHover]="true" [autoLayout]="true"
    currentPageReportTemplate="Affiche de {first} à {last} sur {totalRecords} items" [showCurrentPageReport]="true"
    (onFilter)="restructureObjects($event)" [rowsPerPageOptions]="[10,25,50,100,200]">

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <div class="p-d-flex header__shape">
                    <span [ngStyle]="col.width && col.width !== '' ? { 'width': col.width } : { 'width': 'unset' } ">
                        {{ translatePrefix + '.columns.' + col.header | translate }}
                    </span>

                    <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                        [attr.aria-label-desc]="'common.sortDesc' | translate"
                        [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
                </div>
            </th>
            <th class="sticky-column">{{ 'common.actions' | translate }}</th>
        </tr>
        <tr>
            <th *ngFor="let col of columns" [ngSwitch]="true" [ngStyle]="{'vertical-align': 'middle'}">
                <div *ngSwitchCase="col.field === 'statut'" style="width: 'fit-content';">
                    <p-dropdown appendTo="body" [options]="statuses" autoWidth="false" [style]="{'width':'100%'}"
                        [(ngModel)]="selectedStatus" (onChange)="columnFilter($event.value, 'statut', 'equals')"
                        styleClass="p-column-filter" [placeholder]="'pilotages.status-dropdown.placeholder' | translate"
                        [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span>{{option.label}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div *ngSwitchCase="col.field === 'ratio' || col.field === 'article_nature_comptable' || shouldDisplayInputFilter(col)">
                    <input pInputText type="text" styleClass="p-column-filter"
                        (input)="inputFilterColumn($event, col.field, 'contains')"
                        [placeholder]="'common.filter' | translate"
                        [attr.aria-placeholder]="'common.filter' | translate">
                </div>
                <div *ngSwitchCase="col.field === 'modifie_le'" style="width: 130px;">
                    <p-calendar
                        [(ngModel)]="dateFiltersModifieLe"
                        appendTo="body"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="'pilotages.general.date-filter' | translate"
                        (onClearClick)="columnFilter($event, col.field, 'dateRangeFilterModifieLe')"
                        (onSelect)="columnFilter($event, col.field, 'dateRangeFilterModifieLe')">
                    </p-calendar>
                </div>
                <div *ngSwitchCase="col.field === 'cree_le'" style="width: 130px;">
                    <p-calendar
                        [(ngModel)]="dateFiltersCreeLe"
                        appendTo="body"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="'pilotages.general.date-filter' | translate"
                        (onClearClick)="columnFilter($event, col.field, 'dateRangeFilterCreeLe')"
                        (onSelect)="columnFilter($event, col.field, 'dateRangeFilterCreeLe')">
                    </p-calendar>
                </div>
                <div *ngSwitchDefault class="default">
                    <mat-checkbox #filterCheckbob
                        class="default__checkbox"
                        [ngClass]="filterCheckbob.checked ? 'matCheck' : 'matUncheck'"
                        [indeterminate]="false"
                        (change)="checkboxFilter($event.checked, col.field, 'equals')">
                    </mat-checkbox>
                </div>
            </th>
            <th class="sticky-column">
                <div class="sticky-column__container"></div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length+1">
                <h3>
                    {{translatePrefix + '.empty' | translate}}
                </h3>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns" let-editing="editing">
        <tr [pEditableRow]="rowData" class="p-selectable-row">
            <ng-container *ngFor="let col of columns; trackBy: trackByColumnField">
                <td class="bodyTD" [class.grouped_article]="getCssClass('article', col, rowData)"
                    [class.grouped_emploi]="getCssClass('emploi', col,  rowData)">
                    <ng-container [ngSwitch]="true">
                        <ng-container *ngSwitchCase="col.type === 'string' || col.type === 'number'">
                            <div class="bodyTD__cellEditorContainer">
                                <div *ngIf="divStatusForDependencies['div-' + col.field + '-' + rowData.id]">
                                    <p-cellEditor *ngIf="col.isEditable === undefined" class="cellEditorContainer__cellEditor">
                                        <ng-template pTemplate="input">
                                            <ng-container *ngIf="col.type === 'string'">
                                                <input matInput
                                                    [ngStyle]="col.width && col.width !== '' ? { 'width': col.width } : { 'width': 'unset' } "
                                                    type="text"
                                                    id="input-{{col.field}}-{{rowData.id}}"
                                                    [placeholder]="translatePrefix + '.dynamicField.' + col.field + '.placeholder' | translate"
                                                    [ngModel]="rowData[col.field]"
                                                    (ngModelChange)="saveInput($event, rowData, col.field)"
                                                    [disabled]="disableInputButton[col.field]">
                                                <small *ngIf="uiErrors[col.field]" id="username2-help" class="p-invalid">{{translatePrefix + '.dynamicField.' + col.field + '.error' | translate}}</small>
                                            </ng-container>
                                            <ng-container *ngIf="col.type === 'number'">
                                                <input matInput
                                                    type="number"
                                                    [min]="getMinNumber(col.field)"
                                                    [ngStyle]="col.width && col.width !== '' ? { 'width': col.width } : { 'width': 'unset' } "
                                                    [placeholder]="translatePrefix + '.dynamicField.' + col.field + '.placeholder' | translate"
                                                    [ngModel]="rowData[col.field]"
                                                    id="input-{{col.field}}-{{rowData.id}}"
                                                    (ngModelChange)="saveInput($event, rowData, col.field)"
                                                    [disabled]="disableInputButton[col.field]"/>
                                                <small *ngIf="uiErrors[col.field]" id="username2-help" class="p-invalid">{{translatePrefix + '.dynamicField.' + col.field +'.error' | translate}}</small>
                                            </ng-container>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div class="bodyTD__hCenterText">
                                                <span>
                                                    {{rowData[col.field]}}
                                                </span>
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </div>
                            </div>
                            <ng-container *ngIf="col.isEditable !== undefined && !col.isEditable">
                                <div class="bodyTD__hCenterText">
                                    {{rowData[col.field]}}
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="col.type === 'statut'">
                            <div class="bodyTD__hCenterText">
                                {{rowData[col.field]}}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="col.type === 'date'">
                            <div class="bodyTD__hCenterText">
                                {{ rowData[col.field] | date: col.format }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div class="bodyTD__cellEditorContainer">
                                <p-cellEditor class="cellEditorContainer__cellEditor">
                                    <ng-template pTemplate="input">
                                        <mat-checkbox #editeCheckbox
                                            id="checkbox-{{col.field}}-{{rowData.id}}"
                                            [ngClass]="rowData[col.field] ? 'matCheck' : 'matUncheck'"
                                            [ngModel]="rowData[col.field]"
                                            [indeterminate]="false"
                                            [disabled]="disableCheckboxInEditMode(col.field, rowData)"
                                            (change)="saveCheckbox($event, rowData, col.field)">
                                        </mat-checkbox>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <mat-checkbox
                                            [ngClass]="rowData[col.field] ? 'matCheck' : 'matUncheck'"
                                            [(ngModel)]="rowData[col.field]"
                                            [indeterminate]="false"
                                            [disabled]="true">
                                        </mat-checkbox>
                                    </ng-template>
                                </p-cellEditor>
                            </div>
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>
            <td class="sticky-column">
                <div class="sticky-column__container">
                    <app-generic-other-menu *ngIf="!editing"
                        class="container__center"
                        [editOnly]="true"
                        [addborder]="true"
                        (edit)="onRowEditInit(rowData, $event)">
                    </app-generic-other-menu>
                    <app-generic-other-menu *ngIf="editing"
                        class="container__left"
                        [mySingleButtonOnly]="true"
                        [singleButtonIcon]="cancelEdit"
                        [singleButtonType]="'button'"
                        [addborder]="true"
                        [isRedButton]="true"
                        (singleButtonPressed)="onRowEditCancel(rowData, $event)">
                    </app-generic-other-menu>
                    <app-generic-other-menu *ngIf="editing"
                        class="container__right"
                        [mySingleButtonOnly]="true"
                        [singleButtonIcon]="checkLogo"
                        [singleButtonType]="'button'"
                        [addborder]="true"
                        [disabledButton]="disabledCheckButton"
                        (singleButtonPressed)="onRowEditSave(rowData, $event)">
                    </app-generic-other-menu>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
