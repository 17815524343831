import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Go } from '../../../../../../../core/store/actions';
import { LoadTimesheetReportsSummaryTech } from '../../../../../../store/actions';
import { TableColumns } from '../../../../../../../shared/models/table-columns.model';
import { AlternativePath } from '../../../../../../../shared/models/atlernative-path.model';
import { StatusReportFilters } from '../../../../../../../shared/models/status-report-filters.model';
import { TimesheetReportPartialStatus, TimesheetReportStatus } from '../../../../../../../shared/models/database/timesheet-report-status.enum';
import { TimesheetReportSummary } from '../../../../../../../shared/models/database/timesheet-report-summary.model';
import { ReportFilters, ReportFiltersRaw } from '../../../../../../../shared/models/report-filters.model';
import { RoleTimesheetReporstList } from '../role-timesheet-report-list';
import { getAllTimesheetReportsSummaryTech, getTimesheetReportsSummaryTechLoading } from '../../../../../../store/selectors';

@Component({
    selector: 'app-technician-timesheet-reports-list',
    templateUrl: './technician-timesheet-reports-list.component.html',
    styleUrls: ['./technician-timesheet-reports-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechnicianTimesheetReportsListComponent extends RoleTimesheetReporstList implements OnInit {
    public localStorageName = 'Technicien';
    public translatePrefix = this.coreTranslatePrefix + '.technician.summary';
    public statuses: StatusReportFilters[] = [
        { name: TimesheetReportStatus.refuséParHQ, checked: false, display: true },
        { name: TimesheetReportStatus.approuvéParEnt, checked: true, display: true },
        { name: TimesheetReportStatus.approuvéParHQ, checked: false, display: true },
        { name: TimesheetReportStatus.facturé, checked: false, display: true },
    ];

    public partialsStatuses: StatusReportFilters[] = [
        { name: TimesheetReportPartialStatus.Refusé, checked: false, display: true },
        { name: TimesheetReportPartialStatus.Approuvé, checked: false, display: true },
        { name: TimesheetReportPartialStatus['En attente'], checked: false, display: true },
    ];

    public cols: TableColumns[] = [
        { field: 'site', header: 'site', globalFilter: true, filterType: 'multi-select', width: '100px' },
        { field: 'company', header: 'contractor', globalFilter: true },
        { field: 'week', header: 'week', type: 'date', format: 'dd-MM-yyyy', filterMatchMode: 'date' },
        { field: 'number', header: 'reportId', noFilter: false, globalFilter: true },
        { field: 'nameTeamLeader', header: 'teamLeader', globalFilter: true },
        { field: 'order', header: 'order', globalFilter: true },
        { field: 'accountingEntry', header: 'accountingEntry', globalFilter: true },
        { field: 'billingType', header: 'type', globalFilter: true },
        { field: 'status', header: 'status', type: 'tag' },
        { field: 'partialStatus', header: 'partialStatus', type: 'tag' },
        { field: 'remark', header: 'remark', noFilter: true, type: 'remark', isSortable: false },
        { field: 'invoiceReportNumber', header: 'invoiceReportNumber', noFilter: false, globalFilter: true },
    ];

    public timesheetRepportSummary$: Observable<TimesheetReportSummary[]> = this.store.pipe(
        select(getAllTimesheetReportsSummaryTech)
    );

    public timesheetRepportSummaryLoading$: Observable<boolean> = this.store.pipe(
        select(getTimesheetReportsSummaryTechLoading)
    );

    @Input() public pathChoice: AlternativePath;

    constructor(
        protected dialog: MatDialog,
        protected readonly store: Store,
    ) {
        super(dialog, store);
    }

    public ngOnInit(): void {
        this.reload();
    }

    public reload(): void {
        const reportFilter = this.getFilter(this.localStorageName);

        if (reportFilter.haveFilters) {
            this.store.dispatch(new LoadTimesheetReportsSummaryTech(reportFilter.convertToQueryString(), this.pathChoice));
        } else {
            this.store.dispatch(new LoadTimesheetReportsSummaryTech('', this.pathChoice));
        }
    }

    public onGoToReport(report?: TimesheetReportSummary): void {
        if (!report || !report.id) {
            return;
        }

        this.store.dispatch(new Go(
            {
                path: [
                    'rapports',
                    'timesheet',
                    this.pathChoice,
                    report && report.id.toString(),
                    report && report.site ? report.site : ''
                ]
            }
        ));
    }

    public applyFilter(event: ReportFiltersRaw): void {
        this.store.dispatch(new LoadTimesheetReportsSummaryTech(new ReportFilters(event).convertToQueryString(), this.pathChoice));
    }
}
