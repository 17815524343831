import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { BillingState, billingStoreReducers } from './reducers';

export const BILLING_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<BillingState>>('Billing reducers');

export function getBillingReducers(): ActionReducerMap<BillingState> {
    return billingStoreReducers;
}
