import { RouterEffects } from './router.effects';
import { AppNotificationEffects } from './app-notifications.effects';
import { DomaineDeValeurEffects } from './domaine-valeur.effects';

export const coreEffects: any[] = [
    RouterEffects,
    AppNotificationEffects,
    DomaineDeValeurEffects
];

export * from './router.effects';
export * from './app-notifications.effects';
export * from './domaine-valeur.effects';
