import { OrdersEffects } from './orders.effect';
import { TransportCodesEffects } from './transport-codes.effect';
import { MealCodesEffects } from './meal-codes.effect';
import { EquipmentCodesEffects } from './equipment-codes.effect';
import { JobCodesEffects } from './job-codes.effect';
import { UnitCodesEffects } from './unit-codes.effect';
import { BillingItemsEffects } from './billing-item.effect';

export const ogivCoreEffects: any[] = [
    OrdersEffects,
    TransportCodesEffects,
    MealCodesEffects,
    EquipmentCodesEffects,
    JobCodesEffects,
    UnitCodesEffects,
    BillingItemsEffects,
];

export * from './orders.effect';
export * from './transport-codes.effect';
export * from './meal-codes.effect';
export * from './equipment-codes.effect';
export * from './job-codes.effect';
export * from './unit-codes.effect';
export * from './billing-item.effect';
