export enum TimesheetReportStatus {
    créé = 'Créé',
    modifié = 'Modifié',
    refuséParEnt = 'Refusé par Ent',
    approuvéParEnt = 'Approuvé par Ent',
    refuséParHQ = 'Refusé par HQ',
    approuvéParHQ = 'Approuvé par HQ',
    facturé = 'Facturé',
}

/** ------------------------------------------------------ **/
/** |                                                    | **/
/** |          Attention:                                | **/
/** |    / \     On désative la convention de nomage     | **/
/** |   / ! \    car le nom des statuts proviennent      | **/
/** |  /_____\   du backend. (eslint-disable ...)        | **/
/** |                                                    | **/
/** ------------------------------------------------------ **/

/* eslint-disable @typescript-eslint/naming-convention*/
export enum TimesheetReportPartialStatus {
    'En attente' = 'En attente',
    Approuvé = 'Approuvé',
    Refusé = 'Refusé'
}
