import { cloneDeep } from 'lodash';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';

import { DemandesClientAction, DemandesClientActionTypes } from '../actions/demandes-client.actions';
import { DemandeClient, DemandeClientOGIV, DemandeClientRawData, UpdateDemandeClientResult } from '../../../shared/models/database/demande-client.model';
import { DemandesClientBatchUpdatesResults } from '../../../shared/models/demande-client-batch-updates-results';

export interface DemandesClientState extends EntityState<DemandeClient> {
    loading: boolean;
    loaded: boolean;
    loadingOne: boolean;
    loadedOne: boolean;
    batchUpdateProcessing: boolean;
    batchUpdateResult: DemandesClientBatchUpdatesResults | null;
    updateResult: UpdateDemandeClientResult | null;
}

export const demandesClientAdapter: EntityAdapter<DemandeClient> = createEntityAdapter<DemandeClient>({
    selectId: (demandeClient: DemandeClient) => demandeClient.numeroOrdre,
});

export const demandesClientInitialState = demandesClientAdapter.getInitialState({
    loading: false,
    loaded: false,
    loadingOne: false,
    loadedOne: false,
    batchUpdateProcessing: false,
    batchUpdateResult: null,
    updateResult: null
});

export function demandesClientReducer(
    state: DemandesClientState = demandesClientInitialState,
    action: DemandesClientAction
): DemandesClientState {
    switch (action.type) {
        case DemandesClientActionTypes.DEMANDES_CLIENT_SEARCH:
        case DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT: {
            return {
                ...state,
                loading: true,
            };
        }

        case DemandesClientActionTypes.LOAD_DEMANDE_CLIENT_BY_ID: {
            return {
                ...state,
                loadingOne: true,
            };
        }

        case DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES: {
            return {
                ...state,
                batchUpdateProcessing: true,
            };
        }

        case DemandesClientActionTypes.DEMANDES_CLIENT_SEARCH_SUCCESS: {
            return demandesClientAdapter.setAll(action.demandesClient, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case DemandesClientActionTypes.LOAD_DEMANDE_CLIENT_BY_ID_SUCCESS: {
            return demandesClientAdapter.upsertOne(action.demandeClient, {
                ...state,
                loadingOne: false,
                loadedOne: true,
            });
        }

        case DemandesClientActionTypes.LOAD_DEMANDE_CLIENT_BY_ID_FAIL: {
            return {
                ...state,
                loadingOne: false,
                loadedOne: false,
            };
        }

        case DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT_SUCCESS: {
            return demandesClientAdapter.upsertOne(action.updateDemandeClientResult.demandesClientsOGIV[0], {
                ...state,
                loading: false,
                loaded: true,
                updateResult: null
            });
        }

        case DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES_FAIL:
        case DemandesClientActionTypes.DEMANDES_CLIENT_SEARCH_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
                updateResult: null,
                batchUpdateProcessing: false,
                batchUpdateResult: null,
            };
        }

        case DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
                updateResult: null
            };
        }

        case DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES_CLEAR_RESULTS: {
            return {
                ...state,
                batchUpdateResult: null,
            };
        }

        case DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES_FAIL_RESULT:
        case DemandesClientActionTypes.DEMANDES_CLIENT_BATCH_UPDATES_SUCCESS: {

            const updates: Update<DemandeClient>[] = action.results.demandesClientsOGIV.map((demandeClientOGIV: DemandeClientOGIV) => {

                const tempDemandeClientOGIV = cloneDeep(demandeClientOGIV);
                delete tempDemandeClientOGIV['Ordre'];

                return {
                    id: tempDemandeClientOGIV.numeroOrdre,
                    changes: new DemandeClient({ ...state.entities[demandeClientOGIV.numeroOrdre], ...tempDemandeClientOGIV } as DemandeClientRawData)
                };
            });

            return demandesClientAdapter.updateMany(updates, {
                ...state,
                batchUpdateProcessing: false,
                batchUpdateResult: action.results,
            });
        }

        case DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT_CLEAR_FAIL_RESULT: {
            return {
                ...state,
                loading: false,
                updateResult: null
            };
        }

        case DemandesClientActionTypes.UPDATE_DEMANDE_CLIENT_FAIL_WITH_RESULT: {
            return demandesClientAdapter.upsertOne(action.updateDemandeClientResult.demandesClientsOGIV[0], {
                ...state,
                loading: false,
                loaded: true,
                updateResult: (action.updateDemandeClientResult)
            });
        }

        default:
            return state;
    }
}
