<app-generic-detail-page-header
    [pageLogo]="'fas fa-dollar-sign'"
    [pageTitle]="translatePrefix + '.title' | translate"
    (back)="backPressed = true">
</app-generic-detail-page-header>

<app-billing-report-form [editAllowedRoles]="[userRole.support, userRole.pilote, userRole.chefgroupe, userRole.entrepreneur]" [userRoles]="$any(currentUserRoles$ | async)"
    [formMode]="formMode" [translatePrefix]="translatePrefix" [pathChoice]="pathChoice" [backPressed]="backPressed"
    (resetBackButton)="backPressed = false" >
</app-billing-report-form>
