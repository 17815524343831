import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RoleGuard } from '../core/guards/role.guard';
import { LoadPageGuard } from '../guards';
import { PageSelector } from '../shared/models/page-selector.model';
import { UserRole } from '../shared/models/user-roles.model';

import {
    AddBillingReportComponent,
    BillingComponent,
    BillingReportsComponent,
    ContractorBillingReportsListComponent,
    CGCBillingReportsListComponent,
    DisplayBillingReportComponent,
    EditBillingReportComponent,
    SapEntriesReportComponent
} from './components';
import { LoadBillingReportGuard } from './guards';
import { PreviewBillingReportComponent } from './components/reports/preview-billing-report/preview-billing-report.component';
import { AlternativePath } from '../shared/models/atlernative-path.model';

const routes: Routes = [
    {
        path: '',
        component: BillingComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'rapports',
            },
            {
                path: 'rapports',
                component: BillingReportsComponent,
                children: [
                    {
                        path: 'hq',
                        children: [
                            {
                                path: '',
                                component: CGCBillingReportsListComponent,
                                data: {
                                    roles: [UserRole.pilote, UserRole.techinter, UserRole.planificateur, UserRole.cgc],
                                    pageSelector: [PageSelector.billingHQ],
                                    pathChoice: AlternativePath.hq,
                                },
                                canActivate: [RoleGuard, LoadPageGuard],
                            },
                            {
                                path: 'display/:billingReportId',
                                component: DisplayBillingReportComponent,
                                data: {
                                    roles: [UserRole.pilote, UserRole.techinter, UserRole.planificateur, UserRole.cgc],
                                    pathChoice: AlternativePath.hq,
                                },
                                canActivate: [RoleGuard, LoadBillingReportGuard],
                            },
                            {
                                path: 'preview/:billingReportId',
                                component: PreviewBillingReportComponent,
                                data: {
                                    roles: [UserRole.pilote, UserRole.techinter, UserRole.planificateur, UserRole.cgc],
                                    pathChoice: AlternativePath.hq,
                                },
                                canActivate: [RoleGuard, LoadBillingReportGuard],
                            },
                            {
                                path: 'sap-entries-report/:billingReportId',
                                component: SapEntriesReportComponent,
                                data: {
                                    roles: [UserRole.pilote, UserRole.techinter, UserRole.planificateur, UserRole.cgc],
                                    pathChoice: AlternativePath.hq,
                                },
                                canActivate: [RoleGuard, LoadBillingReportGuard],
                            },
                        ]
                    },
                    {
                        path: 'ent',
                        children: [
                            {
                                path: '',
                                component: ContractorBillingReportsListComponent,
                                data: {
                                    roles: [UserRole.support, UserRole.pilote, UserRole.entrepreneur],
                                    pageSelector: [PageSelector.billingEnt],
                                    pathChoice: AlternativePath.ent,
                                },
                                canActivate: [RoleGuard, LoadPageGuard],
                            },
                            {
                                path: 'creer',
                                component: AddBillingReportComponent,
                                data: {
                                    roles: [UserRole.support, UserRole.pilote, UserRole.entrepreneur],
                                    pathChoice: AlternativePath.ent,
                                },
                                canActivate: [RoleGuard],
                            },
                            {
                                path: ':billingReportId',
                                component: EditBillingReportComponent,
                                data: {
                                    roles: [UserRole.support, UserRole.pilote, UserRole.entrepreneur],
                                    pathChoice: AlternativePath.ent,
                                },
                                canActivate: [RoleGuard, LoadBillingReportGuard],
                            },
                            {
                                path: 'display/:billingReportId',
                                component: DisplayBillingReportComponent,
                                data: {
                                    roles: [UserRole.support, UserRole.pilote, UserRole.entrepreneur],
                                    pathChoice: AlternativePath.ent,
                                },
                                canActivate: [RoleGuard, LoadBillingReportGuard],
                            },
                            {
                                path: 'preview/:billingReportId',
                                component: PreviewBillingReportComponent,
                                data: {
                                    roles: [UserRole.support, UserRole.pilote, UserRole.entrepreneur],
                                    pathChoice: AlternativePath.ent,
                                },
                                canActivate: [RoleGuard, LoadBillingReportGuard],
                            },
                        ]
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class BillingRoutingModule { }
