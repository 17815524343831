import { Action } from '@ngrx/store';
import { UtilisateurExterne } from '../../models/utilisateur-externe.model';

export enum UtilisateurExterneTeamLeaderActionType {
    LOAD_UTILISATEURS_EXTERNES_TEAMLEADER_LIST = '[UtilisateurExterne] Load Utilisateurs Externes team leader list',
    LOAD_UTILISATEURS_EXTERNES_TEAMLEADER_LIST_SUCCESS = '[UtilisateurExterne] Load Utilisateurs Externes team leader list success',
    LOAD_UTILISATEURS_EXTERNES_TEAMLEADER_LIST_FAIL = '[UtilisateurExterne] Load Utilisateurs Externes team leader list fail',
}

/**
 *  LOAD UtilisateurExterne Team Leaders
 */

export class LoadUtilisateursExternesTeamLeaderList implements Action {
    readonly type = UtilisateurExterneTeamLeaderActionType.LOAD_UTILISATEURS_EXTERNES_TEAMLEADER_LIST;
    constructor() { }
}

export class LoadUtilisateursExternesTeamLeaderListSuccess implements Action {
    readonly type = UtilisateurExterneTeamLeaderActionType.LOAD_UTILISATEURS_EXTERNES_TEAMLEADER_LIST_SUCCESS;
    constructor(public utilisateursExternesTeamLeader: UtilisateurExterne[]) { }
}

export class LoadUtilisateursExternesTeamLeaderListFail implements Action {
    readonly type = UtilisateurExterneTeamLeaderActionType.LOAD_UTILISATEURS_EXTERNES_TEAMLEADER_LIST_FAIL;
    constructor(public error: any) { }
}

export type UtilisateurExterneTeamLeaderAction =
    | LoadUtilisateursExternesTeamLeaderList
    | LoadUtilisateursExternesTeamLeaderListSuccess
    | LoadUtilisateursExternesTeamLeaderListFail;
