import { createSelector } from '@ngrx/store';
import { getReportsState, ReportsState } from '../reducers';
import { progressReportsGlobalAdapter, ProgressReportsGlobalState } from '../reducers/progress-reports-global.reducer';

const {
    selectAll,
} = progressReportsGlobalAdapter.getSelectors();

export const getProgressReportsGlobalState = createSelector(
    getReportsState,
    (state: ReportsState) => state.progressReportsGlobal,
);

export const getProgressReportsGlobalLoading = createSelector(
    getProgressReportsGlobalState,
    (state: ProgressReportsGlobalState) => state.loading,
);

export const getAllProgressReportsGlobal = createSelector(
    getProgressReportsGlobalState,
    selectAll
);

export const getProgressReportsGlobalDateOfData = createSelector(
    getProgressReportsGlobalState,
    (state: ProgressReportsGlobalState) => state.dateOfData,
);
