export interface PilotageFormModalData {
    title: string;
    translatePrefix: string;
    formName: string;
    logoString?: string;
    message?: string;
    entity?: any;
    entityArray?: any;
    isAddMode?: boolean;
}

export enum PilotageFormModalDataFormName {
    utilisateurExterne = 'utilisateurExterne',
    utilisateurInterne = 'utilisateurInterne',
    entrepreneur = 'entrepreneur',
    reassignEntreprise = 'reassignEntreprise',
    tableCodesUnite = 'tableCodesUnite',
    editTimesheetChargeNumber = 'editTimesheetChargeNumber',
    editTimesheetCommandePosteType = 'editTimesheetCommandePosteType',
}
