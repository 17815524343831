import { Action } from '@ngrx/store';

export enum NoOperationActionsTypes {
    NO_OPERATION_ACTION = '[App] No Operation Action',
}

export class NoOperationAction implements Action {
    readonly type = NoOperationActionsTypes.NO_OPERATION_ACTION;
}

export type NoOperationActions = NoOperationAction;
