import { Action } from '@ngrx/store';

import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { SpecialMeasures, UpdatedPrescription } from '../../../shared/models/database/special-measures.model';

export enum SpecialMeasuresActionType {
    LOAD_SPECIAL_MEASURES = '[Special Measures] Load Special Measures',
    LOAD_SPECIAL_MEASURES_SUCCESS = '[Special Measures] Load Special Measures Success',
    LOAD_SPECIAL_MEASURES_FAIL = '[Special Measures] Load Special Measures Fail',

    UPDATE_ONE_PRESCRIPTION = '[Special Measures] Update One Prescription',
    UPDATE_ONE_PRESCRIPTION_FAIL = '[Special Measures] Update One Prescription Fail',
    UPDATE_ONE_PRESCRIPTION_SUCCESS = '[Special Measures] Update One Prescription Success',
}

/**
 * LOAD
 */
export class LoadSpecialMeasures implements Action {
    readonly type = SpecialMeasuresActionType.LOAD_SPECIAL_MEASURES;
    constructor(public path: AlternativePath) { }
}

export class LoadSpecialMeasuresSuccess implements Action {
    readonly type = SpecialMeasuresActionType.LOAD_SPECIAL_MEASURES_SUCCESS;
    constructor(public specialMeasures: SpecialMeasures) { }
}

export class LoadSpecialMeasuresFail implements Action {
    readonly type = SpecialMeasuresActionType.LOAD_SPECIAL_MEASURES_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOnePrescription implements Action {
    readonly type = SpecialMeasuresActionType.UPDATE_ONE_PRESCRIPTION;
    constructor(public prescription: UpdatedPrescription, public path: string) { }
}

export class UpdateOnePrescriptionSuccess implements Action {
    readonly type = SpecialMeasuresActionType.UPDATE_ONE_PRESCRIPTION_SUCCESS;
    constructor(public specialMeasures: SpecialMeasures) { }
}

export class UpdateOnePrescriptionFail implements Action {
    readonly type = SpecialMeasuresActionType.UPDATE_ONE_PRESCRIPTION_FAIL;
    constructor(public error: any) { }
}

export type SpecialMeasuresAction =
    | LoadSpecialMeasures
    | LoadSpecialMeasuresSuccess
    | LoadSpecialMeasuresFail
    | UpdateOnePrescription
    | UpdateOnePrescriptionSuccess
    | UpdateOnePrescriptionFail;
