import { Error } from './error.model';
import { OrderType } from './order-type.enum';

export class WorkertRawData {
    public id: number;
    public orderId: number;
    public firstName: string;
    public lastName: string;
    public jobSAPId: number;
    public jobSAPCode: string;
    public jobSAPDescr: string;
    public jobClassId: number;
    public jobClassCode: string;
    public jobClassDescr: string;
    public transportationCode: string;
    public transportationQuantity: number;
    public mealCode: string;
    public mealQuantity: number;
    public hour: number;
    public idEmploye: number;
}

export class Worker {
    public id: number;
    public idEmploye = 0;
    public orderId: number;
    public firstName: string;
    public lastName: string;
    public jobSAPId: number;
    public jobSAPCode: string;
    public jobSAPDescr: string;
    public jobClassId: number;
    public jobClassCode: string;
    public jobClassDescr: string;
    public transportationCode: string;
    public transportationQuantity: number;
    public mealCode: string;
    public mealQuantity: number;
    public hour: number;

    constructor(data: WorkertRawData) {
        Object.keys(data).forEach((key: string) => {
            this[key] = data[key];
        });
    }

    public validate(orderType: OrderType): Error | null {
        let errors: Error | null = null;
        const workersError: string[] = [];

        if (orderType !== OrderType.unit && (this.jobSAPCode === '' || this.jobSAPDescr === '')) {
            workersError.push('doit contenir un code d\'emploi');
        }

        if (this.jobClassCode === '' || this.jobClassDescr === '') {
            workersError.push('doit contenir une classe d\'emploi');
        }

        if ((orderType === OrderType.hourly || orderType === OrderType.outage) && this.hour === 0) {
            workersError.push('doit contenir des heures');
        }

        if (this.mealQuantity > 0 && (this.mealCode === '' || this.mealCode == null)) {
            workersError.push('doit contenir un code pour le repas');
        }

        if (this.mealQuantity === 0 && (this.mealCode !== '' && this.mealCode !== null)) {
            workersError.push('doit contenir une quantité pour le repas');
        }

        if (this.transportationQuantity > 0 && (this.transportationCode === '' || this.transportationCode == null)) {
            workersError.push('doit contenir un code pour le transport');
        }

        if (this.transportationQuantity === 0 && (this.transportationCode !== '' && this.transportationCode !== null)) {
            workersError.push('doit contenir une quantité pour le transport');
        }

        if (workersError.length > 0) {
            errors = { name: `${this.firstName} ${this.lastName}`, list: workersError, last: true };
        }

        return errors;
    }
}
