<div>
    <div class="title">
        <h3>
            <i class="far fa-calendar-alt title__logo"></i>
            <span lass="title__text">
                {{ translatePrefix + '.title' | translate }}
            </span>
        </h3>
    </div>
    <div *ngIf="remarks.length > 0" class="remarks">
        <div *ngFor="let remark of remarks" class="remarks__container">
            <div class="container__remark">
                <div>{{ getDate(remark.date)}}</div>
                <div>{{ remark.comment }}</div>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>
    <div *ngIf="remarks.length === 0">
        {{ translatePrefix + '.noTimesheetRemark' | translate }}
    </div>
</div>
