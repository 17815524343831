import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { TimesheetState, getTimesheetState } from '../reducers';
import { jobClassesAdapter, JobClassesState } from '../reducers/job-classes.reducer';
import { JobClass } from '../../../shared/models/database/job-class.model';
import { SelectItem } from 'primeng/api';

const {
    selectAll,
    selectEntities,
    selectIds,
} = jobClassesAdapter.getSelectors();

export const getJobClassesState = createSelector(
    getTimesheetState,
    (state: TimesheetState) => state.jobClasses,
);

export const getJobClassesLoading = createSelector(
    getJobClassesState,
    (state: JobClassesState) => state.loading,
);

export const getJobClassesLoaded = createSelector(
    getJobClassesState,
    (state: JobClassesState) => state.loaded,
);

export const shouldLoadJobClasses = createSelector(
    getJobClassesLoading,
    getJobClassesLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllJobClasses = createSelector(
    getJobClassesState,
    selectAll
);

export const getJobClassesEntities = createSelector(
    getJobClassesState,
    selectEntities,
);

export const getJobClassesIds = createSelector(
    getJobClassesState,
    selectIds,
);

export const getJobClassById = (prop: any) => createSelector(
    getJobClassesEntities,
    (entities: Dictionary<JobClass>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllJobClassesSorted = createSelector(
    getAllJobClasses,
    (jobClasses: JobClass[]): JobClass[] => {
        const temporaire = [...jobClasses];
        temporaire.sort((a: JobClass, b: JobClass): number => {
            return (a.code.toLocaleLowerCase()).localeCompare(b.code.toLocaleLowerCase());
        });
        return temporaire;
    }
);

export const getJobClassByCode = ({ code = '' }: { code?: string } = {}) => createSelector(
    getAllJobClassesSorted,
    (jobClasses: JobClass[]): JobClass | null => {
        const foundJobClass = jobClasses.filter((jobClass: JobClass) => jobClass.code === code);
        return foundJobClass.length === 1 ? foundJobClass[0] : null;
    }
);

export const getActiveJobClassesOptions = (props: { addEmptyOption?: boolean, addCreateOption?: boolean } = { addEmptyOption: false }) => createSelector(
    getAllJobClassesSorted,
    (jobClasses: JobClass[]): SelectItem[] => {
        const jobClassesOptions = jobClasses.map((jobClass: JobClass) => {
            return {
                label: `(${jobClass.code}) ${jobClass.description}`,
                value: jobClass.id,
            } as SelectItem;
        });

        if (props.addEmptyOption) {
            jobClassesOptions.unshift({
                label: 'Sélectionner une classe d\'emploi',
                value: 0,
            } as SelectItem);
        }

        if (props.addCreateOption) {
            jobClassesOptions.push({
                label: 'Nouvelle classe d\'emploi',
                value: null,
            } as SelectItem);
        }

        return jobClassesOptions;
    }
);
