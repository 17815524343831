import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { PilotagesState, pilotagesStoreReducers } from './reducers';

export const PILOTAGES_TOKEN = new InjectionToken<ActionReducerMap<PilotagesState>>('Pilotages reducers');

export function getPilotagesReducers(): ActionReducerMap<PilotagesState> {
    return pilotagesStoreReducers;
}
