<div class="note">
    <ng-container *ngIf="!isEditMode">
        <div class="note__title" [class.isLock]="isLock" (click)="toggleEditMode()">
            <i class="fas fa-exclamation title__logo" [class.isLock]="isLock"></i>
            <div class="title__text" >
                Remarque
            </div>

            <div *ngIf="!isLock" class="title__actions">
                <app-generic-other-menu *ngIf="isNoteEmpty()"
                    [addOnly]="true"
                    [iconFontSize]="18"
                    (add)="toggleEditMode()">
                </app-generic-other-menu>
                <app-generic-other-menu *ngIf="!isNoteEmpty()"
                    [editOnly]="true"
                    [iconFontSize]="18"
                    (edit)="toggleEditMode()">
                </app-generic-other-menu>
            </div>
        </div>

        <div *ngIf="!isNoteEmpty()" class="note__content"  (click)="toggleEditMode()">
            <div class="content__field">
                <div class="field__component clickable" [class.isLock]="isLock">
                    <div class="component__position" [class.isLock]="isLock">
                        <p class="position__displayNote">{{ this.noteForm.controls.note.value }}</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isEditMode">
        <div class="note__title" (click)="toggleEditMode()">
            <i class="fas fa-exclamation title__logo"></i>
            <div class="title__text">Remarque</div>
        </div>

        <div class="note__content">
            <form [formGroup]="noteForm" (ngSubmit)="onSubmit()">
                <div class="content__field">
                    <div class="field__component">
                        <div class="component__container">
                            <textarea #note class="container__textarea" rows="5" cols="30" pInputTextarea [autoResize]="true"
                                maxlength="{{maxCharactersLength}}" formControlName="note">
                            </textarea>
                            <div class="container__hint">
                                <mat-hint [align]="'end'"> {{note.value.length}} / {{maxCharactersLength}}</mat-hint>
                            </div>
                        </div>

                        <div class="component__bcontainer">
                            <div class="bcontainer__button">
                                <app-generic-other-menu
                                    [mySingleButtonOnly]="true"
                                    [singleButtonIcon]="'fas fa-times'"
                                    [iconFontSize]="22"
                                    (singleButtonPressed)="resetForm()">
                                </app-generic-other-menu>
                                <app-generic-other-menu
                                    [mySingleButtonOnly]="true"
                                    [singleButtonIcon]="'far fa-save'"
                                    [iconFontSize]="22"
                                    [iconFontWeight]="400"
                                    [singleButtonType]="'submit'">
                                </app-generic-other-menu>
                                <app-generic-other-menu
                                    [deleteOnly]="true"
                                    [iconFontSize]="22"
                                    [iconFontWeight]="400"
                                    [iconFontColor]="'red'"
                                    (delete)="deleteComment()">
                                </app-generic-other-menu>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
</div>
