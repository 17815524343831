import { Error } from './error.model';
import { OrderType } from './order-type.enum';

export class UnitRawData {
    public id: number;
    public postLineId: number;
    public code: string;
    public description: string;
    public quantity: number;
    public hour: number;
    public fromInventory: boolean;
    public detail?: string | null;
}
export class Unit {
    public id: number;
    public postLineId: number;
    public code: string;
    public description: string;
    public quantity: number;
    public hour: number;
    public fromInventory: boolean;
    public detail?: string | null;

    constructor(data: UnitRawData) {
        Object.keys(data).forEach((key: string) => {
            this[key] = data[key];
        });
    }

    public validate(orderType: OrderType): Error | null {
        let errors: Error | null = null;
        const unitError: string[] = [];

        if ((orderType === OrderType.hourly || orderType === OrderType.outage) && this.hour === 0) {
            unitError.push('doit contenir des heures');
        }

        if (this.quantity === 0) {
            unitError.push('doit contenir une quantité');
        }

        if (this.description === '') {
            unitError.push('doit contenir une description');
        }

        if (unitError.length > 0) {
            errors = { name: this.code, list: unitError, last: true };
        }

        return errors;
    }
}
