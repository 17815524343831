<div class="form">
    <form [formGroup]="postLineForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="standard" class="formField" [hideRequiredMarker]="true">
            <mat-label>{{ translatePrefix + '.chargeNumber' | translate }}<span class="formField__required">*</span></mat-label>
            <input matInput type="text" formControlName="chargeNumber" autocomplete="off">
        </mat-form-field>

        <mat-form-field *ngIf="powerOutageNumberDisplay" appearance="standard" class="formField" [hideRequiredMarker]="true">
            <mat-label>{{ translatePrefix + '.powerOutageNumber' | translate }}<span class="formField__required">*</span></mat-label>
            <input matInput type="text" formControlName="powerOutageNumber" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="standard" class="formField" [hideRequiredMarker]="true">
            <mat-label>{{ translatePrefix + '.postElect' | translate }}<span class="formField__required">*</span></mat-label>
            <input matInput type="text" formControlName="postElect" autocomplete="off" oninput="this.value = this.value.toUpperCase()">
        </mat-form-field>

        <mat-form-field appearance="standard" class="formField" [hideRequiredMarker]="true">
            <mat-label>{{ translatePrefix + '.line' | translate }}<span class="formField__required">*</span></mat-label>
            <input matInput type="text" formControlName="line" autocomplete="off" oninput="this.value = this.value.toUpperCase()">
        </mat-form-field>

        <app-generic-form-buttons [disabled]="postLineForm.invalid" (cancel)="cancelForm()"></app-generic-form-buttons>
    </form>
</div>
