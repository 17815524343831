import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { UserRole } from '../../shared/models/user-roles.model';
import { Go } from '../store/actions';
import { CoreState } from '../store/reducers';
import { getUserRoles } from '../store/selectors';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(
        private readonly store: Store<CoreState>,
    ) { }

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.store.pipe(
            select(getUserRoles),
            map((userRoles: UserRole[]) => {
                const roles: string[] = route.data && route.data.roles || [];

                return !roles.length
                    || userRoles.some(role => roles.includes(role));
            }),
            tap((isAllowed: boolean) => {
                if (!isAllowed) {
                    this.store.dispatch(new Go({ path: ['/'] }));
                }
            }),
            map((isAllowed: boolean) => isAllowed),
        );
    }
}
