import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { PilotagesState, getPilotagesState } from '../reducers';
import { timesheetCommandePosteTypeAdapter, TimesheetCommandePosteTypeState } from '../reducers/timesheet-commande-poste-type.reducer';
import { TimesheetCommandePosteType } from '../../models/timesheet-commande-poste-type.model';

const {
    selectAll,
    selectEntities,
    selectIds,
} = timesheetCommandePosteTypeAdapter.getSelectors();

export const getTimesheetCommandePosteTypeState = createSelector(
    getPilotagesState,
    (state: PilotagesState) => state.timesheetCommandePosteType,
);

export const getTimesheetCommandePosteTypeLoading = createSelector(
    getTimesheetCommandePosteTypeState,
    (state: TimesheetCommandePosteTypeState) => state.loading,
);

export const getTimesheetCommandePosteTypeLoaded = createSelector(
    getTimesheetCommandePosteTypeState,
    (state: TimesheetCommandePosteTypeState) => state.loaded,
);

export const shouldLoadTimesheetCommandePosteType = createSelector(
    getTimesheetCommandePosteTypeLoading,
    getTimesheetCommandePosteTypeLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllTimesheetCommandePosteType = createSelector(
    getTimesheetCommandePosteTypeState,
    selectAll
);

export const getAllFetchedTimesheetCommandePosteType = createSelector(
    getAllTimesheetCommandePosteType,
    (timesheetsCommandePosteType: TimesheetCommandePosteType[]) =>
        timesheetsCommandePosteType.map((timesheetCommandePosteType: TimesheetCommandePosteType) => timesheetCommandePosteType),
);

export const getTimesheetCommandePosteTypeEntities = createSelector(
    getTimesheetCommandePosteTypeState,
    selectEntities
);

export const getTimesheetCommandePosteTypeIds = createSelector(
    getTimesheetCommandePosteTypeState,
    selectIds,
);

export const getTimesheetCommandePosteTypeById = (prop: any) => createSelector(
    getTimesheetCommandePosteTypeEntities,
    (entities: Dictionary<TimesheetCommandePosteType>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllTimesheetCommandePosteTypeSorted = createSelector(
    getAllTimesheetCommandePosteType,
    (timesheetCommandePosteTypes: TimesheetCommandePosteType[]): TimesheetCommandePosteType[] => {
        const temporaire = [...timesheetCommandePosteTypes];
        temporaire.sort((a: TimesheetCommandePosteType, b: TimesheetCommandePosteType): number => {
            return (a.teamLeader.toLocaleLowerCase()).localeCompare(b.teamLeader.toLocaleLowerCase());
        });
        return temporaire;
    }
);

export const getTimesheetsCommandePosteTypeTeamLeaders = (props: { searchValue: string } = { searchValue: '' }) => createSelector(
    getAllTimesheetCommandePosteTypeSorted,
    (timesheetsCommandePosteType: TimesheetCommandePosteType[]): string[] => {
        const teamLeaders: string[] = [];
        timesheetsCommandePosteType.forEach((timesheet: TimesheetCommandePosteType) => {
            if (props.searchValue === '') {
                if (!teamLeaders.includes(timesheet.teamLeader)) {
                    teamLeaders.push(timesheet.teamLeader);
                }
            } else {
                if (timesheet.teamLeader !== null) {
                    if (timesheet.teamLeader.toLocaleLowerCase().includes(props.searchValue.toLocaleLowerCase())
                        && !teamLeaders.includes(timesheet.teamLeader)) {
                        teamLeaders.push(timesheet.teamLeader);
                    }
                }
            }
        });
        return teamLeaders;
    }
);
