import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiService } from '../../../ogiv-core/services/api.service';
import {
    VehicleActionType,
    LoadVehicles,
    LoadVehiclesSuccess,
    LoadVehiclesNoResult,
    LoadVehiclesFail,
    CreateVehicle,
    CreateVehicleFail,
    CreateVehicleSuccess,
} from '../actions/vehicles.action';
import { Vehicle } from '../../../shared/models/database/vehicle.model';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class VehiclesEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService,
    ) { }

    /*************************/
    /*******  LOADING  *******/
    /*************************/
    public loadVehicles$ = createEffect(() => this.action$.pipe(
        ofType<LoadVehicles>(VehicleActionType.LOAD_VEHICLES),
        mergeMap((action: LoadVehicles) => {
            return this.apiService.get<Vehicle[]>(`${action.path}/fdt/data/vehicles`).pipe(
                map((returnedVehicles: Vehicle[]) =>
                    (returnedVehicles.length > 0)
                        ? new LoadVehiclesSuccess(returnedVehicles)
                        : new LoadVehiclesNoResult()
                ),
                catchError((error: any) => of(new LoadVehiclesFail(error))),
            );
        }),
    ));

    /************************/
    /*******  CREATE  *******/
    /************************/
    public createVehicle$ = createEffect(() => this.action$.pipe(
        ofType<CreateVehicle>(VehicleActionType.CREATE_VEHICLE),
        mergeMap((action: CreateVehicle) => {
            return this.apiService.post<Vehicle[], Vehicle>(`${action.path}/fdt/data/vehicle`, action.vehicle).pipe(
                map((returnedVehicles: Vehicle[]) => new CreateVehicleSuccess(returnedVehicles)),
                catchError((error: any) => of(new CreateVehicleFail(error))),
            );
        }),
    ));

    public createVehicleSuccess$ = createEffect(() => this.action$.pipe(
        ofType<CreateVehicleSuccess>(VehicleActionType.CREATE_VEHICLE_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.vehicle.create.success',
            }))
        ]
        ),
    ));

    public createVehicleFail$ = createEffect(() => this.action$.pipe(
        ofType<CreateVehicleFail>(VehicleActionType.CREATE_VEHICLE_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.vehicle.create.error',
            }))
        ]),
    ));
}
