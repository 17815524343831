import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { TimesheetReportSummary } from '../../../shared/models/database/timesheet-report-summary.model';
import { TimesheetReportsSummaryTechAction, TimesheetReportSummaryTechActionTypes } from '../actions/timesheet-reports-summary-tech.action';

export interface TimesheetReportsSummaryTechState extends EntityState<TimesheetReportSummary> {
    loading: boolean;
    loaded: boolean;
}

export const timesheetReportsSummaryTechAdapter: EntityAdapter<TimesheetReportSummary> = createEntityAdapter<TimesheetReportSummary>({
    selectId: (timesheetReport: TimesheetReportSummary) => {
        const timesheeReporttInstance = new TimesheetReportSummary(timesheetReport);
        return `${timesheeReporttInstance.id}_${timesheeReporttInstance.site || 'undefined'}`;
    }
});

export const timesheetReportsSummaryTechInitialState = timesheetReportsSummaryTechAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function timesheetReportsSummaryTechReducer(
    state: TimesheetReportsSummaryTechState = timesheetReportsSummaryTechInitialState,
    action: TimesheetReportsSummaryTechAction
): TimesheetReportsSummaryTechState {
    switch (action.type) {
        case TimesheetReportSummaryTechActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_TECH: {
            return {
                ...state,
                loading: true,
            };
        }

        case TimesheetReportSummaryTechActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_TECH_SUCCESS: {
            return timesheetReportsSummaryTechAdapter.setAll(action.timesheetReports, {
                ...state,
                loading: false,
                loaded: true,
            });
        }
        case TimesheetReportSummaryTechActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_TECH_NO_RESULTS: {
            return timesheetReportsSummaryTechAdapter.setAll(action.timesheetReports, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case TimesheetReportSummaryTechActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_TECH_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
