import { NavigationExtras } from '@angular/router';

import { Action } from '@ngrx/store';

export enum RouterActionsTypes {
    GO = '[Router] Go',
    GO_RELOAD = '[Router] Go Reload',
    BACK = '[Router] Back',
    FORWARD = '[Router] Forward',
}

export class Go implements Action {
    readonly type = RouterActionsTypes.GO;

    constructor(
        public payload: {
            path: string[];
            query?: object;
            extras?: NavigationExtras;
        }
    ) { }
}

export class GoReload implements Action {
    readonly type = RouterActionsTypes.GO_RELOAD;

    constructor(
        public payload: {
            path: string[];
            query?: object;
            extras?: NavigationExtras;
        }
    ) { }
}

export class Back implements Action {
    readonly type = RouterActionsTypes.BACK;
}

export class Forward implements Action {
    readonly type = RouterActionsTypes.FORWARD;
}

export type RouterActions =
    | Go
    | GoReload
    | Forward
    | Back;
