import { TimesheetReportsEffects } from './timesheet-reports.effect';
import { TimesheetReportsSummaryEffects } from './timesheet-reports-summary.effect';
import { TimesheetReportsSummaryTechEffects } from './timesheet-reports-summary-tech.effect';
import { ProgressReportGlobalEffects } from './progress-reports-global.effect';
import { ProgressReportSpecificEffects } from './progress-reports-specific.effect';

export const reportsStoreEffects = [
    TimesheetReportsEffects,
    TimesheetReportsSummaryEffects,
    TimesheetReportsSummaryTechEffects,
    ProgressReportGlobalEffects,
    ProgressReportSpecificEffects
];
