<mat-accordion class="example-headers-align" [togglePosition]="'before'">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header [collapsedHeight]="getHeaderHeight()" [expandedHeight]="getHeaderHeight()">
            <div class="accordion__header">
                <div class="header__title">
                    <i *ngIf="!isEmptyIcon()" class="{{icon}} title__logo" [class.isLock]="isLock"></i>
                    <div class="title__title" [class.isLock]="isLock">
                        <div class="title__main">
                            <div class="main__text">{{ title }}</div>
                        </div>
                        <div *ngIf="requiredSubtitle" class="title__sub">
                            <div class="sub__text">{{ subtitle }}</div>
                        </div>
                    </div>
                </div>
                <app-info [class.header__time--hide]="isTimeHidden()"
                    [logo]="'far fa-clock'"
                    [number]="totalTime"
                    [isLock]="isLock">
                </app-info>
                <app-info [class.header__count--hide]="isExtraInformationHidden()"
                    [logo]="bubbleIcon"
                    [number]="totalQuantity"
                    [isLock]="isLock">
                </app-info>
                <div class="header__add" *ngIf="canAddOrEdit" [class.header__add--hide]="isAddButtonHidden()">
                    <div class="add__ngContent">
                        <ng-content select="[actions]"></ng-content>
                    </div>
                </div>
            </div>
        </mat-expansion-panel-header>
        <ng-content select="[itemsList]"></ng-content>
    </mat-expansion-panel>
</mat-accordion>
