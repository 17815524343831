<p-table #billingReportsDatatable [columns]="cols" [value]="reports" [paginator]="true" [rows]="10"
[sortField]="sortField" [sortOrder]="sortOrder" [rowHover]="true" [autoLayout]="true"
currentPageReportTemplate="Affiche de {first} à {last} sur {totalRecords} items" [showCurrentPageReport]="true"
[rowsPerPageOptions]="[10,25,50,100,200]">
    <ng-template pTemplate="caption">
        <div class="table__header">
            <div class="header__container">
                <div class="container__search">
                    <i class="fas fa-search search__icon"></i>
                    <input type="text" class="search__input" pInputText size="30" #globalFilterObj
                        [placeholder]="'common.search' | translate" [attr.aria-placeholder]="'common.search' | translate"
                        (input)="inputGlobalFilter($event, 'contains')">
                </div>
                <div class="container__clearFilter">
                    <app-erase-all-filter (clicked)="clearAllFilters()" [disabled]="!filtersApply"></app-erase-all-filter>
                </div>
            </div>
            <div class="header__add" *ngIf="showAddButton()">
                <button pButton icon="fa fa-plus" (click)="onTableEvent('create')" class="ui-button-raised add__button"
                    [label]="translatePrefix + '.create' | translate"></button>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th [attr.colspan]="firstColSpan" class="spacer-column"></th>

            <th colspan="2" class="grouped-header">
                {{ translateCommonPrefix + '.unitOfWorkDone' | translate }}
            </th>
            <th [attr.colspan]="lastColSpan" class="spacer-column"></th>
            <th class="spacer-column" [ngStyle]="{ 'width': actionColWidth }"></th>
        </tr>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <div class="p-d-flex header__shape">
                    <span [ngStyle]="col.width && col.width !== '' ? { 'width': col.width } : { 'width': 'unset' } ">
                        {{ translateCommonPrefix + '.' + col.header | translate }}
                    </span>

                    <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                        [attr.aria-label-desc]="'common.sortDesc' | translate"
                        [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
                </div>
            </th>
            <th class="sticky-column">
                {{ 'common.actions' | translate }}
            </th>
        </tr>
        <tr>
            <th *ngFor="let col of columns">
                <input *ngIf="!col.noFilter" pInputText type="text"
                    id='id-{{col.field}}' (input)="inputFilterColumn($event, col.field, 'contains', col)"
                    [placeholder]="'common.filter' | translate" [attr.aria-placeholder]="'common.filter' | translate">

            </th>
            <th class="sticky-column"></th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr class="p-selectable-row" (click)="onTableEvent('open', rowData)" (mouseenter)="rowOver(rowIndex)" (mouseleave)="rowOver(null)">
            <td *ngFor="let col of columns; trackBy: trackByColumnField">
                <div>
                    <ng-container [ngSwitch]="true">
                        <ng-container *ngSwitchCase="col.type === 'yesno'">
                            {{ formatYesNo(rowData[col.field]) }}
                        </ng-container>
                        <ng-container *ngSwitchCase="col.type === 'tag'">
                            <app-tag [status]="'BillingReport.' + rowData[col.field]">
                                {{ translateCommonPrefix + '.' + rowData[col.field] | translate }}
                            </app-tag>
                        </ng-container>
                        <ng-container *ngSwitchCase="col.type === 'date'">
                            {{ rowData[col.field] | date: col.format }}
                        </ng-container>
                        <ng-container *ngSwitchCase="col.type === 'remark'">
                            <ng-container *ngIf="hasRemark(rowData)">
                                <div class="remarkcontainer">
                                    <button pButton pRipple type="button"
                                        class="p-button-text p-button-primary remarkcontainer__button"
                                        icon="fas fa-exclamation" [pTooltip]="translateCommonPrefix + '.open' | translate"
                                        tooltipPosition="top" (click)="remarkClicked(rowData, $event)"></button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="col.field === 'validatedBy'">
                            <div style="overflow-x: clip" *ngIf="rowData[col.field] !== null && rowData[col.field] !== ''"
                                [pTooltip]="rowData[col.field]"
                                tooltipStyleClass="toolTipWidth"
                                tooltipPosition="top">
                                {{ rowData[col.field] }}
                            </div>
                            <div *ngIf="rowData[col.field] === null || rowData[col.field] === ''">
                                {{ rowData[col.field] }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{ rowData[col.field] !== null ?  rowData[col.field] : ' '}}
                        </ng-container>
                    </ng-container>
                </div>
            </td>

            <td class="sticky-column">
                <div class="sticky-column__container" (mouseover)="rowOver(null)" (mouseout)="rowOver(rowIndex)">
                    <button pButton pRipple *ngFor="let action of actions;" icon="fa {{ action.icon }}"
                        class="p-button-rounded p-button-text" [class.p-button-rounded-hq]="pathChoice === 'hq'"
                        [class.hover]="action.icon === searchIcon && rowIndex === buttonOverIndex"
                        [disabled]="!isEnabledActionButton(action.code, rowData)"
                        (click)="onTableEvent(action.code, rowData)"
                        [pTooltip]="(action.labelTranslateKey ? action.labelTranslateKey : 'common.' + action.code) | translate"
                        [tooltipPosition]="'top'">
                    </button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
