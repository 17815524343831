/**
 * Types
 */
import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { ProgressReportSpecificWrapperRawData } from '../../../shared/models/database/progress-report-specific-wrapper.model';

export enum ProgressReportsSpecificActionType {
    LOAD_PROGRESS_REPORTS_SPECIFIC = '[ProgressReportsSpecific] Load Progress Reports Specific',
    LOAD_PROGRESS_REPORTS_SPECIFIC_SUCCESS = '[ProgressReportsSpecific] Load Progress Reports Specific Success',
    LOAD_PROGRESS_REPORTS_SPECIFIC_FAIL = '[ProgressReportsSpecific] Load Progress Reports Specific Fail',
    LOAD_PROGRESS_REPORTS_SPECIFIC_NO_RESULTS = '[ProgressReportsSpecific] Load Progress Reports Specific No Result'
}

/**
 *  ACTIONS
 */
/**
 * LOAD
 */
export class LoadProgressReportsSpecific implements Action {
    readonly type = ProgressReportsSpecificActionType.LOAD_PROGRESS_REPORTS_SPECIFIC;
    constructor(public queryString: string, public path: AlternativePath) { }
}

export class LoadProgressReportsSpecificSuccess implements Action {
    readonly type = ProgressReportsSpecificActionType.LOAD_PROGRESS_REPORTS_SPECIFIC_SUCCESS;
    constructor(public specificReportWrapper: ProgressReportSpecificWrapperRawData) { }
}

export class LoadProgressReportsSpecificFail implements Action {
    readonly type = ProgressReportsSpecificActionType.LOAD_PROGRESS_REPORTS_SPECIFIC_FAIL;
    constructor(public error: any) { }
}

export class LoadProgressReportsSpecificNoResult implements Action {
    readonly type = ProgressReportsSpecificActionType.LOAD_PROGRESS_REPORTS_SPECIFIC_NO_RESULTS;
}

export type ProgressReportsSpecificAction =
    | LoadProgressReportsSpecific
    | LoadProgressReportsSpecificSuccess
    | LoadProgressReportsSpecificFail
    | LoadProgressReportsSpecificNoResult;
