export class UtilisateurExterne {
    id: number;
    nom: string;
    prenom: string;
    carte_acces: string;
    cip: string;
    upn: string;
    id_agol: string;
    id_entreprise: number;
    nom_entreprise: string;
    abreviation_entreprise: string;
    matricule: string;
    titre_emploi: string;
    pole: string;
    site: string;
    numero_vehicule: string;
    numero_tablette: string;
    numero_cellulaire: string;
    horaire: Horaire;
    habilitation: Habilitation;
    telephone: string;
    statut: Statut;
    cree_le: string;
    modifie_par: string;
    modifie_le: string;
    adresse_personnelle: {
        numero_civique: string;
        rue: string;
        ville: string;
        code_postal: string;
    } | string;
    telephone_residence: string;
    roles: string;
    date_fin: string | null;
}

export enum Horaire {
    aucun = 'Aucun',
    lundi = 'Lundi',
    mardi = 'Mardi',
    mercredi = 'Mercredi',
    jeudi = 'Jeudi',
    vendredi = 'Vendredi',
}

export enum Habilitation {
    informed = 'Informé',
    initiated = 'Initié',
    allowed = 'Habilité',
    authorized = 'Autorisé D.25.12',
    unknown = 'Inconnu'
}

export enum Statut {
    active = 'Actif',
    inactive = 'Inactif',
    error = 'En erreur',
    incomplet = 'Incomplet',
    redo = 'En reprise',
    inReassignation = 'En réassignation',
    awaitingCreation = 'En attente de création',
}
