export interface TransportCode {
    id: number;
    code: string;           // max-length 255
    description: string;    // max-length 255
    status: TransportCodeStatus;
    date_inactif?: Date;
    createdBy: string;       // max-length 255
    createdDate: Date;
    modifiedBy: string;       // max-length 255
    modifiedDate: Date;
}

export enum TransportCodeStatus {
    active = 'Actif',
    inactive = 'Inactif',
}
