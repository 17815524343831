import _ from 'lodash';

export class OgivHelpers {
    /**
     * Removes nil fields in objet recursively
     *
     * @param obj The object you want to clean
     * @example
     *  const obj = { foo: { bar: 'Baz', stephen: null, empty: {} } };
     *   deepRemoveNilFields(obj) // '{ foo: { bar: 'Baz'}}'
     */
    public static deepRemoveNilFields = (obj: any): any => {
        return _(obj)
            .pickBy(_.isPlainObject) // get only objects
            .mapValues(OgivHelpers.deepRemoveNilFields) // call only for values as objects
            .assign(_.omitBy(obj, _.isPlainObject)) // save back result that is not object
            .omitBy(_.isNil) // remove null and undefined from object
            .omitBy((o) => typeof o === 'string' ? Object.values(o).length === 0
                : (typeof o === 'number' ? o < 0
                    : (typeof o === 'object' ? Object.entries(o).length === 0 && o.constructor === Object : false)))
            .value(); // get value
    };

    /**
     * Flattens a nested object into a one level object
     *
     * @param object The object to be flattened
     * @return The resulting flat object
     */
    public static flattenObject = (object: any) => {
        return Object.assign(
            {},
            ...function _flatten(o): any {
                return [].concat(...Object.keys(o)
                    .map(k =>
                        (typeof o[k] === 'object' && !Array.isArray(o[k])) ?
                            _flatten(o[k]) :
                            ({ [k]: o[k] })
                    )
                );
            }(object)
        );
    };

    /**
     * checks whether an object is empty or not
     *
     * @param object object to extract values from
     * @returns boolean
     */
    public static isEmptyObject = (obj: any): boolean => {
        if (typeof obj === 'object' && Object.prototype.toString.call(obj) === '[object Object]') {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    return false;
                }
            }

            return true;
        }

        return false;
    };

    public static removeDuplicates = <T>(predicate: (item: T) => any, arr: T[]): T[] => {
        const set: Set<any> = new Set();

        return arr.filter((item: T) => {
            const key = predicate(item);
            const isNew = !key || !set.has(key);

            if (isNew) {
                set.add(key);
            }

            return isNew;
        });
    };

    /**
     * Generate a v4 UUID
     */
    /* eslint-disable no-bitwise */
    /* eslint-disable one-var */
    public static uuidv4 = (): string => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c): string {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };

    /**
     * Returns a boolean if a given object key string matches WeekReport pattern
     * @example isWeekReportDay('day0') === true
     * @example isWeekReportDay('day6') === true
     * @example isWeekReportDay('day7') === false
     * @example isWeekReportDay('') === false
     */

    public static isWeekReportDay = (key: string): boolean => {
        const regex = new RegExp(/^day[0-6]$/);

        return regex.test(key);
    };
}
