import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SelectItem } from 'primeng/api';

import { DomaineDeValeur } from '../../../shared/models/database/domaine-valeur.model';
import { domaineDeValeurAdapter, DomaineDeValeurState } from '../reducers/domaine-valeur.reducers';

const {
    selectAll,
    selectEntities,
    selectIds,
} = domaineDeValeurAdapter.getSelectors();

export const getDomaineDeValeurState = createFeatureSelector<DomaineDeValeurState>('domaineDeValeur');

export const getDomaineDeValeurLoading = createSelector(
    getDomaineDeValeurState,
    (state: DomaineDeValeurState) => state.loading,
);

export const getDomaineDeValeurLoaded = createSelector(
    getDomaineDeValeurState,
    (state: DomaineDeValeurState) => state.loaded,
);

export const getAllDomaineDeValeur = createSelector(
    getDomaineDeValeurState,
    selectAll
);

export const getDomaineDeValeurEntities = createSelector(
    getDomaineDeValeurState,
    selectEntities,
);

export const getDomaineDeValeurIds = createSelector(
    getDomaineDeValeurState,
    selectIds,
);

export const getNoteDeVisiteOptions = createSelector(
    getAllDomaineDeValeur,
    (valeurs: DomaineDeValeur[]): SelectItem[] => {
        const noteDeVisiteSelectItems = valeurs
            .filter((domaineDeValeur: DomaineDeValeur) => domaineDeValeur.typeDomaineValeur === 'NoteDeVisite')
            .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1)
            .map((domaineDeValeur: DomaineDeValeur) => {
                return {
                    label: domaineDeValeur.value,
                    value: domaineDeValeur.value
                } as SelectItem;
            });
        return noteDeVisiteSelectItems;
    }
);
