import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../ogiv-core/services';
import {
    TableCodesUniteActionType,
    LoadTableCodesUnite,
    LoadTableCodesUniteSuccess,
    LoadTableCodesUniteFail,
    LoadTableCodesUniteNoResult,
    CreateOneTableCodeUnite,
    CreateOneTableCodeUniteSuccess,
    CreateOneTableCodeUniteFail,
    UpdateOneTableCodeUnite,
    UpdateAllTableCodeUniteSuccess,
    UpdateOneTableCodeUniteFail,
} from '../actions';
import { TableCodeUnite } from '../../models/table-code-unite.model';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class TableCodesUniteEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService
    ) { }

    /*******************************************************************************/
    /********************************    Loading    ********************************/
    /*******************************************************************************/
    public loadTableCodesUnite$ = createEffect(() => this.action$.pipe(
        ofType<LoadTableCodesUnite>(TableCodesUniteActionType.LOAD_TABLE_CODES_UNITE),
        switchMap((action: LoadTableCodesUnite) => {
            return this.apiService.get<TableCodeUnite[]>(
                `${action.path}/pilotage/table/code-unite`).pipe(
                    map((returnedEntrepreneur: TableCodeUnite[]) =>
                        (returnedEntrepreneur.length > 0)
                            ? new LoadTableCodesUniteSuccess(returnedEntrepreneur)
                            : new LoadTableCodesUniteNoResult()
                    ),
                    catchError((error: any) => of(new LoadTableCodesUniteFail(error))),
                );

        }),
    ));

    /******************************************************************************/
    /********************************    Update    ********************************/
    /******************************************************************************/
    public updatedTableCodeUnite$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneTableCodeUnite>(TableCodesUniteActionType.UPDATE_ONE_TABLE_CODES_UNITE),
        mergeMap((action: UpdateOneTableCodeUnite) => {
            return this.apiService.put<TableCodeUnite[], TableCodeUnite>(
                `${action.path}/pilotage/table/code-unite/${action.tableCodeUnite.id}`, action.tableCodeUnite).pipe(
                    map((updatedTableCodeUnite: TableCodeUnite[]) =>
                        new UpdateAllTableCodeUniteSuccess(updatedTableCodeUnite)
                    ),
                    catchError((error: any) => of(new UpdateOneTableCodeUniteFail(error))),
                );
        })
    ));

    public updatedTableCodeUniteSuccess$ = createEffect(() => this.action$.pipe(
        ofType<UpdateAllTableCodeUniteSuccess>(TableCodesUniteActionType.UPDATE_ALL_TABLE_CODES_UNITE_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'pilotages.notifications.tableCodesUnit.update.success',
            }))
        ]
        ),
    ));

    public updatedTableCodeUniteFail$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneTableCodeUniteFail>(TableCodesUniteActionType.UPDATE_ONE_TABLE_CODES_UNITE_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.tableCodesUnit.update.error',
            }))
        ]),
    ));

    /******************************************************************************/
    /********************************    Create    ********************************/
    /******************************************************************************/
    public createTableCodeUnite$ = createEffect(() => this.action$.pipe(
        ofType<CreateOneTableCodeUnite>(TableCodesUniteActionType.CREATE_ONE_TABLE_CODES_UNITE),
        mergeMap((action: CreateOneTableCodeUnite) => {
            return this.apiService.post<TableCodeUnite[], TableCodeUnite>(
                `${action.path}/pilotage/table/code-unite`, action.tableCodeUnite).pipe(
                    map((createdTableCodeUnite: TableCodeUnite[]) =>
                        new CreateOneTableCodeUniteSuccess(createdTableCodeUnite)
                    ),
                    catchError((error: any) => of(new CreateOneTableCodeUniteFail(error))),
                );
        })
    ));

    public createTableCodeUniteSuccess$ = createEffect(() => this.action$.pipe(
        ofType<CreateOneTableCodeUniteSuccess>(TableCodesUniteActionType.CREATE_ONE_TABLE_CODES_UNITE_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'pilotages.notifications.tableCodesUnit.create.success',
            }))
        ]
        ),
    ));

    public createTableCodeUniteFail$ = createEffect(() => this.action$.pipe(
        ofType<CreateOneTableCodeUniteFail>(TableCodesUniteActionType.CREATE_ONE_TABLE_CODES_UNITE_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.tableCodesUnit.create.error',
            }))
        ]),
    ));
}
