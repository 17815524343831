<div class="fullheight">
    <form [formGroup]="chargeNumberForm">
        <h4 class="fullheight__title">Entrer un nouveau numéro d'imputation</h4>
        <div class="iab__input group__field">
            <mat-form-field appearance="standard" class="field__formField" [hideRequiredMarker]="true">
                <mat-label>{{ translatePrefix + '.chargeNumber' | translate }}<span class="required">*</span>
                </mat-label>
                <input matInput type="text" formControlName="chargeNumber" />
            </mat-form-field>
        </div>
    </form>
    <div class="action-button">
        <button mat-raised-button type="button" class="button__cancel" (click)="onCancel()">{{ translatePrefix + '.buttons.cancel' | translate | uppercase }}</button>
        <button mat-raised-button type="button" class="button__save" (click)="onUpdate()" [disabled]="chargeNumberForm.invalid">{{ translatePrefix + '.buttons.save' | translate | uppercase }}</button>
    </div>
</div>
