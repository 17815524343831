import { Component } from '@angular/core';

@Component({
    selector: 'app-demandes-client',
    template: `<router-outlet></router-outlet>`,
    styleUrls: ['./demandes-client.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'demandesClientComponent',
    }
})
export class DemandesClientComponent { }
