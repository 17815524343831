import { Component, Input, OnChanges, OnInit, SimpleChanges, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import moment, { Moment } from 'moment';
import { FilterService } from 'primeng/api';

import { LoadUtilisateursInternes, UpdateOneUtilisateurInterne } from '../../../../store/actions';
import { getAllUtilisateursInternes, getUtilisateursInternesLoading } from '../../../../store/selectors';
import { PilotagesState } from '../../../../store/reducers';
import { TableColumns } from '../../../../../shared/models/table-columns.model';
import { GenericOtherMenuItemComponent } from '../../../../../shared/components/generic-other-menu/generic-other-menu-item.model';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { UtilisateurInterne, Statut, Horaire } from '../../../../models/utilisateur-interne.model';
import { UtilisateurRequest } from '../../../../models/profil-formation-utilisateur.model';
import { PilotageFormModalData, PilotageFormModalDataFormName } from '../../modals/pilotage-form-modal/pilotage-form-modal-data.model';
import { ProfilFormationUtilisateurModalComponent } from '../../modals/profil-formation-utilisateur-modal/profil-formation-utilisateur-modal.component';
import { ProfilFormationUtilisateurModalData } from '../../modals/profil-formation-utilisateur-modal/profil-formation-utilisateur-modal-data.model';
import { PilotageFormModalComponent } from '../../modals/pilotage-form-modal/pilotage-form-modal.component';
import { utilisateursInternesColumns } from './utilisateurs-internes-table.column';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-utilisateurs-internes-table',
    templateUrl: './utilisateurs-internes-table.component.html',
    styleUrls: ['./utilisateurs-internes-table.component.scss'],
})
export class UtilisateursInternesTableComponent implements OnInit, OnChanges {
    public columns: TableColumns[] = utilisateursInternesColumns;
    public loaderLogoSize = 75;
    public menuButtonInformation = 'informations';
    public menuButtonFormation = 'formations';
    public updateUserItems: GenericOtherMenuItemComponent[];
    public statuses: any[];
    public scheduleStatuses: any[];
    public selectedUser: UtilisateurInterne;

    public selectedUsersFormation: UtilisateurRequest[];
    public defautSelectedUsersFormation: UtilisateurRequest[];
    public selectedUserFormation: UtilisateurRequest;

    public dateFiltersCreeLe: Moment[];
    public dateFiltersModifieLe: Moment[];

    public nbUtilisateurs: {
        value: number;
    };

    @Input() public translatePrefix: string;
    @Input() public sortField: string;
    @Input() public sortOrder: number;
    @Input() public data: UtilisateurInterne[];
    @Input() public pathChoice = AlternativePath.hq;
    @Input() public reloadData = false;

    @Output() public selectedModule: EventEmitter<string> = new EventEmitter();
    @Output() public reloadedDone: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('utilisateursInternesDataTable') private utilisateursInternesDataTable: Table;

    constructor(
        public dialog: MatDialog,
        private translateService: TranslateService,
        private readonly store: Store<PilotagesState>,
        private filterService: FilterService,
    ) {
        this.filterService.register('dateRangeFilterCreeLe', (value: any, filter: any): boolean => {
            return this.customFilter(value, filter);
        });
    }

    public utilisateursInternesLoading$: Observable<boolean> = this.store.pipe(
        select(getUtilisateursInternesLoading),
    );

    public utilisateursInternes$: Observable<UtilisateurInterne[] | null> = this.store.pipe(
        select(getAllUtilisateursInternes),
        distinctUntilChanged(),
        tap((utilisateursInternes: UtilisateurInterne[]) => {
            this.reloadedDone.emit(true);
            this.prepareData(utilisateursInternes);
        }),
    );

    public prepareData(utilisateurInterne: UtilisateurInterne[]): void {
        this.defautSelectedUsersFormation = this.utilisateurToUtilisateurRequest(utilisateurInterne);
        this.selectedUsersFormation = this.defautSelectedUsersFormation;
        this.nbUtilisateurs = {
            value: utilisateurInterne.length
        };
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.reloadData && changes.reloadData.currentValue) {
            this.store.dispatch(new LoadUtilisateursInternes(this.pathChoice));
        }
    }

    public ngOnInit(): void {
        this.store.dispatch(new LoadUtilisateursInternes(this.pathChoice));
        this.selectedModule.emit('InternalUsers');

        this.filterService.register('dateRangeFilterModifieLe', (value: any): boolean => {
            return this.filtrerDates(value, this.dateFiltersModifieLe);
        });

        this.filterService.register('dateRangeFilterCreeLe', (value: any): boolean => {
            return this.filtrerDates(value, this.dateFiltersCreeLe);
        });

        this.updateUserItems = [{
            label: this.translateService.instant(this.translatePrefix + '.menu-labels' + '.mettreAJourInfoSupplementaires'),
            icon: 'fa fa-user-edit',
            clickValue: this.menuButtonInformation,
        },
        {
            label: this.translateService.instant(this.translatePrefix + '.menu-labels' + '.afficherProfilFormationUtilisateur'),
            icon: 'fa fa-id-badge',
            clickValue: this.menuButtonFormation,
        }];

        this.statuses = [
            { label: this.translateService.instant('pilotages.general.status.Active'), value: Statut.active },
            { label: this.translateService.instant('pilotages.general.status.Inactive'), value: Statut.inactive },
            { label: this.translateService.instant('pilotages.general.status.Incomplet'), value: Statut.incomplet },
            { label: this.translateService.instant('pilotages.general.status.Redo'), value: Statut.redo },
            { label: this.translateService.instant('pilotages.general.status.Error'), value: Statut.error },
        ];

        this.scheduleStatuses = [
            { label: this.translateService.instant('pilotages.general.horaire.Aucun'), value: Horaire.aucun },
            { label: this.translateService.instant('pilotages.general.horaire.Lundi'), value: Horaire.lundi },
            { label: this.translateService.instant('pilotages.general.horaire.Mardi'), value: Horaire.mardi },
            { label: this.translateService.instant('pilotages.general.horaire.Mercredi'), value: Horaire.mercredi },
            { label: this.translateService.instant('pilotages.general.horaire.Jeudi'), value: Horaire.jeudi },
            { label: this.translateService.instant('pilotages.general.horaire.Vendredi'), value: Horaire.vendredi }
        ];
    }

    public customFilter(value: string, filter: any): boolean {
        if (filter === undefined || filter === null || filter.value === null) {
            return true;
        }

        if (filter.value === Horaire.aucun) {
            if (value === undefined || value === null || value === Horaire.aucun || value.trim() === '') {
                return true;
            } else {
                return false;
            }
        } else {
            if (value === undefined || value === null) {
                return false;
            }
        }

        return value.toString() === filter.value.toString();
    }

    public filtrerDates(value: any, dateFilters: Moment[]): boolean {
        if (dateFilters) {
            const tableValue = moment(value);
            const firstFilterDate = moment(dateFilters[0]);
            const secondFilterDate = moment(dateFilters[1]);

            if (tableValue.isValid() && firstFilterDate.isValid()) {
                if (secondFilterDate.isValid()) {
                    return tableValue.isSameOrAfter(firstFilterDate, 'day') && tableValue.isSameOrBefore(secondFilterDate, 'day');
                } else {
                    return tableValue.isSame(firstFilterDate, 'day');
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public showMajUtilisateurInterne(): void {
        const dialogRef = this.dialog.open(PilotageFormModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.majUtilisateurInterneForm' + '.title'),
                entity: this.selectedUser,
                translatePrefix: this.translatePrefix + '.majUtilisateurInterneForm',
                logoString: 'fa fa-user-edit',
                formName: PilotageFormModalDataFormName.utilisateurInterne
            } as PilotageFormModalData,
            minWidth: 'fit-content',
            width: '50%'
        });

        dialogRef.afterClosed().subscribe((result: UtilisateurInterne) => {
            if (result) {
                this.store.dispatch(new UpdateOneUtilisateurInterne(result as UtilisateurInterne, AlternativePath.hq));
            }
        });
    }

    public showProfilFormationUtilisateur(): void {
        this.dialog.open(ProfilFormationUtilisateurModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.profilFormationUtilisateurModal' + '.title'),
                utilisateurs: [this.selectedUserFormation],
                translatePrefix: this.translatePrefix + '.profilFormationUtilisateurModal',
                logoString: 'fa fa-id-badge'
            } as ProfilFormationUtilisateurModalData,
            minWidth: 'fit-content',
            width: '20%'
        });
    }

    public setValues(rowdata: UtilisateurInterne): void {
        this.selectedUser = rowdata;
        this.selectedUserFormation = {
            cii: rowdata.cip ? rowdata.cip.toUpperCase() : rowdata.cip,
            nom: rowdata.nom,
            prenom: rowdata.prenom
        };
    }

    public saveFilteredValues(dt: any): void {
        if (dt.filteredValue) {
            if (dt.filteredValue.length > 0) {
                this.selectedUsersFormation = this.utilisateurToUtilisateurRequest(dt.filteredValue);
            } else {
                this.selectedUsersFormation = [];
            }
        } else {
            this.selectedUsersFormation = this.defautSelectedUsersFormation;
        }
        this.nbUtilisateurs = {
            value: this.selectedUsersFormation.length
        };
    }

    public utilisateurToUtilisateurRequest(utilisateursList: UtilisateurInterne[]): UtilisateurRequest[] {
        return utilisateursList.map(function (a: UtilisateurInterne) {
            if (a.cip) {
                return {
                    nom: a.nom,
                    prenom: a.prenom,
                    cii: a.cip.toUpperCase(),
                } as UtilisateurRequest;
            } else {
                return {
                    nom: a.nom,
                    prenom: a.prenom,
                    cii: a.cip,
                } as UtilisateurRequest;
            }
        });
    }

    public showProfilsFormationPlusieursUtilisateurs() {
        this.dialog.open(ProfilFormationUtilisateurModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.profilFormationUtilisateurModal' + '.title'),
                utilisateurs: this.selectedUsersFormation,
                translatePrefix: this.translatePrefix + '.profilFormationUtilisateurModal',
            } as ProfilFormationUtilisateurModalData,
            minWidth: 'fit-content',
            width: '20%',
        });
    }

    public menuItemPressed(value: string, rowdata: UtilisateurInterne): void {
        this.setValues(rowdata);

        if (value === this.menuButtonInformation) {
            this.showMajUtilisateurInterne();
        } else if (value === this.menuButtonFormation) {
            this.showProfilFormationUtilisateur();
        }
    }

    public inputFilterColumn(value: Event, field: string, filterMatchMode?: string): void {
        this.utilisateursInternesDataTable.filter((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains');
    }

    public inputGlobalFilter(value: Event, filterMatchMode?: string): void {
        this.utilisateursInternesDataTable.filterGlobal((value.target as HTMLInputElement).value, filterMatchMode || 'contains');
    }
}
