import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Go } from '../../../../../../../core/store/actions';
import { LoadTimesheetReportsSummary } from '../../../../../../store/actions';
import { getAllTimesheetReportsSummary, getTimesheetReportsSummaryLoading } from '../../../../../../store/selectors';
import { TableColumns } from '../../../../../../../shared/models/table-columns.model';
import { AlternativePath } from '../../../../../../../shared/models/atlernative-path.model';
import { StatusReportFilters } from '../../../../../../../shared/models/status-report-filters.model';
import { TimesheetReportStatus } from '../../../../../../../shared/models/database/timesheet-report-status.enum';
import { TimesheetReportSummary } from '../../../../../../../shared/models/database/timesheet-report-summary.model';
import { ReportFilters, ReportFiltersRaw } from '../../../../../../../shared/models/report-filters.model';
import { RoleTimesheetReporstList } from '../role-timesheet-report-list';

@Component({
    selector: 'app-gl-timesheet-reports-list',
    templateUrl: './gl-timesheet-reports-list.component.html',
    styleUrls: ['./gl-timesheet-reports-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupLeaderTimesheetReportsListComponent extends RoleTimesheetReporstList implements OnInit {
    public localStorageName = 'GroupLeader';
    public translatePrefix = this.coreTranslatePrefix + '.groupLeader.summary';
    public statuses: StatusReportFilters[] = [
        { name: TimesheetReportStatus.créé, checked: true, display: true },
        { name: TimesheetReportStatus.modifié, checked: true, display: true },
        { name: TimesheetReportStatus.refuséParEnt, checked: true, display: true },
        { name: TimesheetReportStatus.refuséParHQ, checked: true, display: true },
        { name: TimesheetReportStatus.approuvéParEnt, checked: false, display: true },
        { name: TimesheetReportStatus.approuvéParHQ, checked: false, display: true },
        { name: TimesheetReportStatus.facturé, checked: false, display: true },
    ];

    public cols: TableColumns[] = [
        { field: 'week', header: 'week', type: 'date', format: 'dd-MM-yyyy', filterMatchMode: 'date' },
        { field: 'number', header: 'reportId', noFilter: false, globalFilter: true },
        { field: 'nameTeamLeader', header: 'teamLeader', globalFilter: true },
        { field: 'order', header: 'order', globalFilter: true },
        { field: 'accountingEntry', header: 'accountingEntry', globalFilter: true },
        { field: 'billingType', header: 'billingType', globalFilter: true },
        { field: 'status', header: 'status', type: 'tag' },
        { field: 'remark', header: 'remark', type: 'remark', noFilter: true },
        { field: 'invoiceReportNumber', header: 'invoiceReportNumber', noFilter: false, globalFilter: true },
    ];

    public timesheetRepportSummary$: Observable<TimesheetReportSummary[]> = this.store.pipe(
        select(getAllTimesheetReportsSummary)
    );

    public timesheetRepportSummaryLoading$: Observable<boolean> = this.store.pipe(
        select(getTimesheetReportsSummaryLoading)
    );

    @Input() public pathChoice: AlternativePath;

    constructor(
        protected dialog: MatDialog,
        protected readonly store: Store,
    ) {
        super(dialog, store);
    }

    public ngOnInit(): void {
        this.reload();
    }

    public reload(): void {
        const reportFilter = this.getFilter(this.localStorageName);

        if (reportFilter.haveFilters) {
            this.store.dispatch(new LoadTimesheetReportsSummary(reportFilter.convertToQueryString(), this.pathChoice));
        } else {
            this.store.dispatch(new LoadTimesheetReportsSummary('', this.pathChoice));
        }
    }

    public onGoToReport(report?: TimesheetReportSummary): void {
        if (!report || !report.id) {
            return;
        }

        this.store.dispatch(new Go(
            { path: ['rapports', 'timesheet', this.pathChoice, report && report.id.toString()] }
        ));
    }

    public applyFilter(event: ReportFiltersRaw): void {
        this.store.dispatch(new LoadTimesheetReportsSummary(new ReportFilters(event).convertToQueryString(), this.pathChoice));
    }
}
