import { Action } from '@ngrx/store';
import { CurrentUser } from '../../../core/services/user.service';
import { Worker } from '../../../shared/models/database/worker.model';

/**
 * Types
 */

export enum WorkerActionTypes {
    LOAD_WORKERS = '[Worker] Load Team Mates',
    LOAD_WORKERS_SUCCESS = '[Worker] Load Team Mates Success',
    LOAD_WORKERS_NO_RESULTS = '[Worker] Load Team Mates No Results',
    LOAD_WORKERS_FAIL = '[Worker] Load Team Mates Fail',

    CREATE_WORKER = '[Worker] Create Team Mates',
    CREATE_WORKER_SUCCESS = '[Worker] Create Team Mates Success',
    CREATE_WORKER_FAIL = '[Worker] Create Team Mates Fail',

    UPDATE_ONE_WORKER = '[Worker] Update One Team Mate',
    UPDATE_ONE_WORKER_FAIL = '[Worker] Update One Team Mate Fail',
    UPDATE_ONE_WORKER_SUCCESS = '[Worker] Update One Team Mate Success',

    DELETE_ONE_WORKER = '[Worker] Delete One Team Mate',
    DELETE_ONE_WORKER_FAIL = '[Worker] Delete One Team Mate Fail',
    DELETE_ONE_WORKER_SUCCESS = '[Worker] Delete One Team Mate Success',
}

/**
 * ACTIONS
 */

export class CreateWorker implements Action {
    readonly type = WorkerActionTypes.CREATE_WORKER;
    constructor(public worker: Worker, public timesheetDateId: string, public path: string, public imperson?: CurrentUser) { }
}

export class CreateWorkerSuccess implements Action {
    readonly type = WorkerActionTypes.CREATE_WORKER_SUCCESS;
    // constructor(public worker: Worker) { }
}

export class CreateWorkerFail implements Action {
    readonly type = WorkerActionTypes.CREATE_WORKER_FAIL;
    constructor(public error: any) { }
}

export class LoadWorkers implements Action {
    readonly type = WorkerActionTypes.LOAD_WORKERS;
    constructor(public path: string) { }
}

export class LoadWorkersNoresult implements Action {
    readonly type = WorkerActionTypes.LOAD_WORKERS_NO_RESULTS;
}

export class LoadWorkersSuccess implements Action {
    readonly type = WorkerActionTypes.LOAD_WORKERS_SUCCESS;
    constructor(public workers: Worker[]) { }
}

export class LoadWorkersFail implements Action {
    readonly type = WorkerActionTypes.LOAD_WORKERS_FAIL;
    constructor(public error: any) { }
}

export class UpdateOneWorker implements Action {
    readonly type = WorkerActionTypes.UPDATE_ONE_WORKER;
    constructor(public worker: Worker, public timesheetDateId: string, public path: string, public imperson?: CurrentUser) { }
}

export class UpdateOneWorkerSuccess implements Action {
    readonly type = WorkerActionTypes.UPDATE_ONE_WORKER_SUCCESS;
    // constructor(public worker: Worker) { }
}

export class UpdateOneWorkerFail implements Action {
    readonly type = WorkerActionTypes.UPDATE_ONE_WORKER_FAIL;
    constructor(public error: any) { }
}

export class DeleteOneWorker implements Action {
    readonly type = WorkerActionTypes.DELETE_ONE_WORKER;
    constructor(public worker: Worker, public timesheetDateId: string, public path: string, public imperson?: CurrentUser) { }
}

export class DeleteOneWorkerSuccess implements Action {
    readonly type = WorkerActionTypes.DELETE_ONE_WORKER_SUCCESS;
    // constructor(public worker: Worker) { }
}

export class DeleteOneWorkerFail implements Action {
    readonly type = WorkerActionTypes.DELETE_ONE_WORKER_FAIL;
    constructor(public error: any) { }
}

export type WorkersAction =
    | CreateWorker
    | CreateWorkerSuccess
    | CreateWorkerFail
    | LoadWorkers
    | LoadWorkersNoresult
    | LoadWorkersSuccess
    | LoadWorkersFail
    | UpdateOneWorker
    | UpdateOneWorkerSuccess
    | UpdateOneWorkerFail
    | DeleteOneWorker
    | DeleteOneWorkerSuccess
    | DeleteOneWorkerFail;
