import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import { UpdateOneEntrepreneur, DeactivateOneEntrepreneur, ReactivateOneEntrepreneur, LoadEntrepreneurs } from '../../../../store/actions';
import { getAllEntrepreneurs, getEntrepreneursLoading } from '../../../../store/selectors';
import { PilotagesState } from '../../../../store/reducers';

import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { PilotageFormModalComponent } from '../../modals/pilotage-form-modal/pilotage-form-modal.component';
import { PilotageFormModalData, PilotageFormModalDataFormName } from '../../modals/pilotage-form-modal/pilotage-form-modal-data.model';
import { TableColumns } from '../../../../../shared/models/table-columns.model';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { trackByColumnField } from '../../../../../shared/helpers/track-by.helper';
import { Entrepreneur, EntrepreneurStatus } from '../../../../models/entrepreneur.model';
import { entrepreneursColumns } from './entrepreneurs-table.column';
import { Dropdown } from 'primeng/dropdown';

@Component({
    selector: 'app-entrepreneurs-table',
    templateUrl: './entrepreneurs-table.component.html',
    styleUrls: ['./entrepreneurs-table.component.scss']
})
export class EntrepreneursTableComponent implements OnInit, OnChanges {
    public buttonOverIndex: number | null = null;
    public trackByColumnField = trackByColumnField;
    public deactivateLogo = 'fas fa-user-slash';
    public reactivateLogo = 'fa fa-redo';
    public editEntrepreneurLogo = 'fas fa-pen';
    public createEntrepreneurLogo = 'fa fa-plus';
    public loaderLogoSize = 75;
    public columns: TableColumns[] = entrepreneursColumns;
    public globalFilterArray: string[] = [];
    public filtersApply = false;
    public currentFilters: string[] = [];

    @Input() public translatePrefix: string;
    @Input() public sortField: string;
    @Input() public sortOrder: number;
    @Input() public entrepreneurs: Entrepreneur[];

    @Input() public pathChoice: AlternativePath;
    @Input() public reloadData = false;

    @Output() public selectedModule: EventEmitter<string> = new EventEmitter();
    @Output() public reloadedDone: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('entrepreneursDataTable') entrepreneursDataTable: Table;
    @ViewChild('ddStatus') dropdown: Dropdown;
    @ViewChild('globalFilterObj') globalFilterObj: ElementRef;

    public statuses: any[] = [
        { label: this.translateService.instant('pilotages.general.status.AwaitingCreation'), value: EntrepreneurStatus.loadingCreation },
        { label: this.translateService.instant('pilotages.general.status.AwaitingDeactivation'), value: EntrepreneurStatus.awaitDelete },
        { label: this.translateService.instant('pilotages.general.status.Active'), value: EntrepreneurStatus.active },
        { label: this.translateService.instant('pilotages.general.status.Inactive'), value: EntrepreneurStatus.inactive },
        { label: this.translateService.instant('pilotages.general.status.Error'), value: EntrepreneurStatus.error },
        { label: this.translateService.instant('pilotages.general.status.Incomplet'), value: EntrepreneurStatus.incomplet },
        { label: this.translateService.instant('pilotages.general.status.all'), value: EntrepreneurStatus.all },
    ];

    public entrepreneursLoading$: Observable<boolean> = this.store.pipe(
        select(getEntrepreneursLoading),
    );

    public entrepreneurs$: Observable<Entrepreneur[] | null> = this.store.pipe(
        select(getAllEntrepreneurs),
        distinctUntilChanged(),
        tap((entrepreneurs: Entrepreneur[]) => {
            this.entrepreneurs = entrepreneurs;
            this.reloadedDone.emit(true);
        }),
    );

    constructor(
        private readonly store: Store<PilotagesState>,
        public dialog: MatDialog,
        private translateService: TranslateService
    ) {
        this.columns.forEach((col: TableColumns) => {
            if (col.filterType !== undefined && col.filterType !== 'noFilter') {
                this.globalFilterArray.push(col.field);
            }
        });
    }

    public ngOnInit(): void {
        this.store.dispatch(new LoadEntrepreneurs(this.pathChoice));
        this.selectedModule.emit('Entrepreneurs');
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.reloadData && changes.reloadData.currentValue) {
            this.store.dispatch(new LoadEntrepreneurs(this.pathChoice));
        }
    }

    public onDeactivateEntrepreneur(entrepreneur: Entrepreneur): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.deactivateDialog' + '.title'),
                message: this.translateService.instant(this.translatePrefix + '.deactivateDialog' + '.message'),
                translatePrefix: this.translatePrefix + '.boutons',
                logoString: this.deactivateLogo,
                isForDelete: true
            } as ConfirmModalComponent,
            width: 'fit-content',
            height: 'fit-content'
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.store.dispatch(new DeactivateOneEntrepreneur(entrepreneur as Entrepreneur, AlternativePath.hq));
            }
        });

    }

    public onReactivateEntrepreneur(entrepreneur: Entrepreneur): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.reactivateDialog' + '.title'),
                message: this.translateService.instant(this.translatePrefix + '.reactivateDialog' + '.message'),
                translatePrefix: this.translatePrefix + '.boutons',
                logoString: this.reactivateLogo
            } as ConfirmModalComponent,
            width: 'fit-content',
            height: 'fit-content'
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.store.dispatch(new ReactivateOneEntrepreneur(entrepreneur as Entrepreneur, AlternativePath.hq));
            }
        });

    }

    public showModifyEntrepreneur(entrepreneur: Entrepreneur): void {
        const dialogRef = this.dialog.open(PilotageFormModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.modifyEntrepreneurForm' + '.title'),
                entity: entrepreneur,
                entityArray: this.entrepreneurs,
                translatePrefix: this.translatePrefix + '.form',
                logoString: this.editEntrepreneurLogo,
                formName: PilotageFormModalDataFormName.entrepreneur
            } as PilotageFormModalData,
            width: '80%',
            maxWidth: '575px'
        });
        dialogRef.afterClosed().subscribe((result: Entrepreneur) => {
            if (result) {
                this.store.dispatch(new UpdateOneEntrepreneur(result as Entrepreneur, AlternativePath.hq));
            }
        });
    }

    public rowOver(rowIndex: number | null): void {
        this.buttonOverIndex = rowIndex;
    }

    public callFunction(entrepreneur: Entrepreneur): void {
        if (this.buttonOverIndex !== null) {
            this.showModifyEntrepreneur(entrepreneur);
        }
    }

    public inputGlobalFilter(value: Event, filterMatchMode?: string): void {
        this.globalFilter((value.target as HTMLInputElement).value, filterMatchMode || 'contains');
    }

    public globalFilter(value: string, match: string = 'contains'): void {
        this.eraseFilterButtonStatus(value, 'globalFilter');
        this.entrepreneursDataTable.filterGlobal(value, match);
    }

    public inputFilterColumn(value: Event, field: string, filterMatchMode?: string): void {
        this.filterColumn((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains');
    }

    public filterColumn(value: string, field: string, match: string = 'contains'): void {
        value = field !== 'statut' || (field === 'statut' && value !== null) ? value : '';
        this.eraseFilterButtonStatus(value, field);
        this.entrepreneursDataTable.filter(value, field, match);
    }

    public eraseFilterButtonStatus(value: string, field: string): void {
        const index = this.currentFilters.findIndex((activeFilter: string) => activeFilter === field);
        if (index === -1 && value !== '') {
            this.currentFilters.push(field);
        } else if (index !== -1 && value === '') {
            this.currentFilters.splice(this.currentFilters.findIndex((activeFilter: string) => activeFilter === field), 1);
        }

        this.filtersApply = this.currentFilters.length > 0;
    }

    public clearAllFilters(): void {
        this.columns.forEach((col: TableColumns) => {
            if (col.filterType !== undefined && col.filterType !== 'noFilter') {
                const element = (<HTMLInputElement>document.getElementById(`id-${col.field}`));
                if (element && col.filterType === 'text') {
                    element.value = '';
                    this.filterColumn('', col.field);
                } else if (col.filterType === 'select') {
                    this.dropdown.clear(null as any);
                    this.eraseFilterButtonStatus('', col.field);
                }
            }
        });

        if (this.globalFilterObj.nativeElement.value !== '') {
            this.globalFilterObj.nativeElement.value = '';
            this.globalFilter('', 'contains');
        }

        this.entrepreneursDataTable.clear();
    }
}
