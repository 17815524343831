import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { getUserRoles } from '../../../../../../core/store/selectors';
import { UserRole } from '../../../../../../shared/models/user-roles.model';
import { ModalWindowData } from '../../../../../../shared/components/modal-window/modal-window-data.model';
import { ModalWindowComponent } from '../../../../../../shared/components/modal-window/modal-window.component';

@Component({
    selector: 'app-text-area-crud',
    templateUrl: './text-area-crud.component.html',
    styleUrls: ['./text-area-crud.component.scss'],
})
export class TeaxtAreaCrudComponent implements OnChanges {
    public currentUserRoles: UserRole[] = [];
    public translatePrefix = 'timesheet.reports.detail.remark';
    public toggle = false;
    public logoAdd = true;
    public isOver = false;
    public sectionClicked = false;
    public disabledDelete = true;
    public remarkForm: FormGroup;
    public editAllowedRoles: UserRole[] = [UserRole.support, UserRole.pilote, UserRole.chefgroupe, UserRole.entrepreneur];
    public disableEdition = true;

    private originalValue: string;

    @Input() title: string;
    @Input() fontLogo: string;
    @Input() value: string;
    @Input() disableByStatus = false;
    @Input() expliciteDisable = false;

    @Output() save: EventEmitter<string> = new EventEmitter<string>();
    @Output() delete: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        public formBuilder: FormBuilder,
        public dialog: MatDialog,
        private readonly store: Store,
    ) {
        this.store.pipe(
            select(getUserRoles),
        ).subscribe((roles: UserRole[]) => {
            this.currentUserRoles = roles;
            this.disableEdition = this.isRoleDisabled() && this.expliciteDisable;
        });

        this.remarkForm = formBuilder.group({
            remark: [this.value, [Validators.required, Validators.minLength(1)]],
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.value && changes.value.currentValue) {
            this.originalValue = changes.value.currentValue;
            this.remarkForm.controls.remark.setValue(changes.value.currentValue);
            this.logoAdd = false;
            this.disabledDelete = false;
        }
    }

    public isRoleDisabled(): boolean {
        return !!this.editAllowedRoles.length &&
            this.currentUserRoles.every(role => !this.editAllowedRoles.includes(role));
    }

    public onSave(event: MouseEvent): void {
        event.stopPropagation();
        this.toggle = !this.toggle;
        this.sectionClicked = false;
        this.mouseOut();
        this.save.emit(this.remarkForm.controls.remark.value);
    }

    public onDelete(event: MouseEvent): void {
        event.stopPropagation();
        const deleteNoteDialog = this.dialog.open(ModalWindowComponent, {
            width: 'fit-content',
            height: 'fit-content',
            data: {
                componentName: 'info',
                icon: 'fas fa-question',
                message: this.translatePrefix + '.deletePopup.message',
                translatePrefix: this.translatePrefix + '.deletePopup',
                titleKey: 'title',
            } as ModalWindowData
        });

        deleteNoteDialog.afterClosed().subscribe((result: string) => {
            if (result && result === 'save') {
                this.toggle = !this.toggle;
                this.sectionClicked = false;
                this.remarkForm.controls.remark.setValue('');
                this.mouseOut();
                this.save.emit(this.remarkForm.controls.remark.value);
            }
        });
    }

    public onCancel(event: MouseEvent): void {
        event.stopPropagation();
        this.toggle = !this.toggle;
        this.sectionClicked = false;
        this.mouseOut();
        this.remarkForm.controls.remark.setValue(this.originalValue);
    }

    public toggleTextArea(cameFrom?: string): void {
        if (!this.disableByStatus && !this.expliciteDisable) {
            if ((!this.sectionClicked && cameFrom && cameFrom === 'section' && !this.disableEdition)) {
                this.sectionClicked = true;
                this.toggle = !this.toggle;
            }
        }
    }

    public mouseOver(): void {
        if (!this.disableByStatus && !this.expliciteDisable) {
            this.isOver = true;
        }
    }

    public mouseOut(): void {
        this.isOver = false;
    }
}
