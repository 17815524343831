import { Action } from '@ngrx/store';
import { TimesheetReportSummary } from '../../../shared/models/database/timesheet-report-summary.model';

/**
 * Types
 */

export enum TimesheetReportSummaryTechActionTypes {
    LOAD_TIMESHEET_REPORTS_SUMMARY_TECH = '[TimesheetReportTech] Load Timesheet Reports Summary Tech',
    LOAD_TIMESHEET_REPORTS_SUMMARY_TECH_SUCCESS = '[TimesheetReportTech] Load Timesheet Reports Summary Tech Success',
    LOAD_TIMESHEET_REPORTS_SUMMARY_TECH_NO_RESULTS = '[TimesheetReportTech] Load Timesheet Reports Summary Tech No Results',
    LOAD_TIMESHEET_REPORTS_SUMMARY_TECH_FAIL = '[TimesheetReportTech] Load Timesheet Reports Summary Tech Fail',
}

/**
 * ACTIONS
 */

export class LoadTimesheetReportsSummaryTech implements Action {
    readonly type = TimesheetReportSummaryTechActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_TECH;
    constructor(public queryString: string, public path: string) { }
}

export class LoadTimesheetReportsSummaryTechNoresult implements Action {
    readonly type = TimesheetReportSummaryTechActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_TECH_NO_RESULTS;
    constructor(public timesheetReports: TimesheetReportSummary[]) { }
}

export class LoadTimesheetReportsSummaryTechSuccess implements Action {
    readonly type = TimesheetReportSummaryTechActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_TECH_SUCCESS;
    constructor(public timesheetReports: TimesheetReportSummary[]) { }
}

export class LoadTimesheetReportsSummaryTechFail implements Action {
    readonly type = TimesheetReportSummaryTechActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_TECH_FAIL;
    constructor(public error: any) { }
}

export type TimesheetReportsSummaryTechAction =
    | LoadTimesheetReportsSummaryTech
    | LoadTimesheetReportsSummaryTechNoresult
    | LoadTimesheetReportsSummaryTechSuccess
    | LoadTimesheetReportsSummaryTechFail;
