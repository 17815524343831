import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { EquipmentCode } from '../../../shared/models/database/equipment-code.model';

/**
 * Types
 */
export enum EquipmentCodeActionType {
    LOAD_EQUIPMENT_CODES = '[EquipmentCode] Load EquipmentCodes',
    LOAD_EQUIPMENT_CODES_SUCCESS = '[EquipmentCode] Load EquipmentCodes Success',
    LOAD_EQUIPMENT_CODES_FAIL = '[EquipmentCode] Load EquipmentCodes Fail',
    LOAD_EQUIPMENT_CODES_NO_RESULTS = '[EquipmentCode] Load EquipmentCodes No Result',

    CREATE_EQUIPMENT_CODE = '[EquipmentCode] Create EquipmentCode',
    CREATE_EQUIPMENT_CODE_SUCCESS = '[EquipmentCode] Create EquipmentCode Success',
    CREATE_EQUIPMENT_CODE_FAIL = '[EquipmentCode] Create EquipmentCode Fail',

    UPDATE_ONE_EQUIPMENT_CODE = '[EquipmentCode] Update One EquipmentCode',
    UPDATE_ONE_EQUIPMENT_CODE_SUCCESS = '[EquipmentCode] Update One EquipmentCode Success',
    UPDATE_ONE_EQUIPMENT_CODE_FAIL = '[EquipmentCode] Update One EquipmentCode Fail',
}

/**
 * ACTIONS
 */
/**
 * CREATE
 */
export class CreateEquipmentCode implements Action {
    readonly type = EquipmentCodeActionType.CREATE_EQUIPMENT_CODE;
    constructor(public equipmentCode: EquipmentCode, public path: AlternativePath) { }
}

export class CreateEquipmentCodeSuccess implements Action {
    readonly type = EquipmentCodeActionType.CREATE_EQUIPMENT_CODE_SUCCESS;
    constructor(public equipmentCode: EquipmentCode) { }
}

export class CreateEquipmentCodeFail implements Action {
    readonly type = EquipmentCodeActionType.CREATE_EQUIPMENT_CODE_FAIL;
    constructor(public error: any) { }
}

/**
 * LOAD
 */
export class LoadEquipmentCodes implements Action {
    readonly type = EquipmentCodeActionType.LOAD_EQUIPMENT_CODES;
    constructor() { }
}

export class LoadEquipmentCodesNoResult implements Action {
    readonly type = EquipmentCodeActionType.LOAD_EQUIPMENT_CODES_NO_RESULTS;
}

export class LoadEquipmentCodesSuccess implements Action {
    readonly type = EquipmentCodeActionType.LOAD_EQUIPMENT_CODES_SUCCESS;
    constructor(public equipmentCodes: EquipmentCode[]) { }
}

export class LoadEquipmentCodesFail implements Action {
    readonly type = EquipmentCodeActionType.LOAD_EQUIPMENT_CODES_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneEquipmentCode implements Action {
    readonly type = EquipmentCodeActionType.UPDATE_ONE_EQUIPMENT_CODE;
    constructor(public equipmentCode: EquipmentCode, public path: AlternativePath) { }
}

export class UpdateOneEquipmentCodeSuccess implements Action {
    readonly type = EquipmentCodeActionType.UPDATE_ONE_EQUIPMENT_CODE_SUCCESS;
    constructor(public equipmentCode: EquipmentCode) { }
}

export class UpdateOneEquipmentCodeFail implements Action {
    readonly type = EquipmentCodeActionType.UPDATE_ONE_EQUIPMENT_CODE_FAIL;
    constructor(public error: any) { }
}

export type EquipmentCodeAction =
    | CreateEquipmentCode
    | CreateEquipmentCodeSuccess
    | CreateEquipmentCodeFail
    | LoadEquipmentCodes
    | LoadEquipmentCodesNoResult
    | LoadEquipmentCodesSuccess
    | LoadEquipmentCodesFail
    | UpdateOneEquipmentCode
    | UpdateOneEquipmentCodeSuccess
    | UpdateOneEquipmentCodeFail;
