import { createSelector } from '@ngrx/store';

import { billingCompanyAdapter, BillingCompanyState } from '../reducers/billing-company.reducer';
import { BillingState, getBillingState } from '../reducers';

const {
    selectAll,
    selectEntities,
    selectIds,
} = billingCompanyAdapter.getSelectors();

export const getBillingCompanyState = createSelector(
    getBillingState,
    (state: BillingState) => state.billingCompany,
);

export const getBillingCompanyLoading = createSelector(
    getBillingCompanyState,
    (state: BillingCompanyState) => state.loading,
);

export const getBillingCompanyLoaded = createSelector(
    getBillingCompanyState,
    (state: BillingCompanyState) => state.loaded,
);

export const shouldLoadBillingCompany = createSelector(
    getBillingCompanyLoading,
    getBillingCompanyLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getBillingCompany = createSelector(
    getBillingCompanyState,
    selectAll
);

export const getBillingCompanyEntities = createSelector(
    getBillingCompanyState,
    selectEntities,
);

export const getBillingCompanyIds = createSelector(
    getBillingCompanyState,
    selectIds,
);
