<app-element-loader id="spinner"
    *ngIf="(isProgressReportsGlobalLoading$ | async) || (isProgressReportsSpecificLoading$ | async)"
    [size]="100"
    [displayLabel]="true">
</app-element-loader>

<!-- <h1>{{ 'progressReport.general.title' | translate }}</h1> -->
<app-generic-module-header
    [moduleLogo]="'far fa-chart-bar'"
    [moduleTitle]="'progressReport.general.title' | translate"
    [hideButton]="true"
></app-generic-module-header>

<p-menubar [model]="menuItems"></p-menubar>

<div [hidden]="!hasGlobalReportTabOpen">
    <app-progress-reports-global
        [pathChoice]="alternativePath"
    ></app-progress-reports-global>
</div>

<div [hidden]="!hasReportByStatusTabOpen">
    <app-progress-reports-specific
        [pathChoice]="alternativePath"
    ></app-progress-reports-specific>
</div>
