<div class="teamUser">
    <form [formGroup]="profileFrom" (ngSubmit)="onSubmit()">
        <div class="group__field" *ngIf="!isEmployeeFoundAndDisplayDropDown">
            <div class="field__staticName">
                <div class="staticName__label">{{ prefix + '.employee' | translate }}</div>
                <div class="staticName__name">
                    <div class="name__container">
                        <mat-label class="container__value">{{ worker && worker.firstName }} {{ worker && worker.lastName }}</mat-label>
                    </div>
                </div>
            </div>
        </div>
        <div class="group__field" *ngIf="isEmployeeFoundAndDisplayDropDown">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ prefix + '.employee' | translate }}<span class="required"> *</span></mat-label>
                <mat-select formControlName="employeeDropdown"  class="dropDownName">
                    <mat-option [ngStyle]="{'font-size.px': '16'}" *ngFor="let employee of employeeListOption$ | async" [value]="employee.value">{{employee.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ prefix + '.jobClass' | translate }}<span class="required"> *</span></mat-label>
                <mat-select formControlName="jobClassDropdown">
                    <mat-option [ngStyle]="{'font-size.px': '14'}" *ngFor="let jobClass of jobClassListOption$ | async" [value]="jobClass.value">{{jobClass.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ prefix + '.codeSAP' | translate }}<span class="required" *ngIf="canAddEditTime"> *</span></mat-label>
                <mat-select formControlName="jobDropdown">
                    <input matInput placeholder="Recherche" formControlName="searchJob" class="searchInput" [ngStyle]="{'height.px': '48', 'padding.px': '0 16', 'margin': '2px 1px 2px 0px', 'border': '3px #1b75bb double', 'width': 'calc(100% - 1px)'}" autocomplete="off"/>
                    <mat-option [ngStyle]="{'font-size.px': '14'}" *ngFor="let job of jobListOption$ | async" [value]="job.value">{{job.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="canAddEditTime" class="group__field">
            <div class="field__row hour">
                <i class="far fa-clock row__logo"></i>
                <mat-form-field appearance="standard" class="field__formField row__input" [hideRequiredMarker]="true">
                    <mat-label>{{ prefix + '.workedHours' | translate }}<span class="required"> *</span></mat-label>
                    <input matInput type="number" [attr.min]="0" [attr.max]="24" formControlName="hour" autocomplete="off">
                </mat-form-field>
            </div>
        </div>

        <div class="group__field">
            <div class="field__row">
                <i class="fas fa-car row__logo"></i>
                <mat-slide-toggle #tgTransport [checked]="isTransportAllow" class="row__compo"
                    (change)="toggleCar($event)">
                    <span *ngIf="tgTransport.checked">{{ prefix + '.transportAllowed' | translate }}</span>
                    <span *ngIf="!tgTransport.checked">{{ prefix + '.transportNotAllowed' | translate }}</span>
                </mat-slide-toggle>
            </div>
        </div>

        <fieldset class="formfield" [class.fieldset--hide]="!tgTransport.checked">
            <legend class="formfield__legend">{{ prefix + '.fraisDeplacement' | translate }}</legend>
            <div class="group__field">
                <mat-form-field floatLabel="auto" class="fullWidth" [hideRequiredMarker]="true">
                    <mat-label>{{ prefix + '.allocationType' | translate }}<span class="required"> *</span></mat-label>
                    <mat-select formControlName="transportationCode">
                        <mat-option class="options" [ngStyle]="{'font-size.px': '14'}" [value]="undefined">{{ prefix + '.allocationType' | translate }}</mat-option>
                        <mat-option [ngStyle]="{'font-size.px': '14'}" *ngFor="let transportCode of transportCodeOptions$ | async" [value]="transportCode.value">
                            {{ transportCode.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="group__field">
                <mat-form-field appearance="standard" class="field__formField row__input" [hideRequiredMarker]="true">
                    <mat-label>{{ prefix + '.quantity' | translate }}<span class="required"> *</span></mat-label>
                    <input matInput type="number" [attr.min]="0" formControlName="transportationQuantity" autocomplete="off">
                </mat-form-field>
            </div>
        </fieldset>

        <div class="group__field">
            <div class="field__row">
                <i class="fas fa-bread-slice row__logoLarge"></i>
                <mat-slide-toggle #tgMeal [checked]="isMealAllow" class="row__compo" (change)="toggleMeal($event)">
                    <span *ngIf="tgMeal.checked">{{ prefix + '.mealAllow' | translate }}</span>
                    <span *ngIf="!tgMeal.checked">{{ prefix + '.mealNotAllow' | translate }}</span>
                </mat-slide-toggle>
            </div>
        </div>

        <fieldset class="formfield" [class.fieldset--hide]="!tgMeal.checked">
            <legend class="formfield__legend">{{ prefix + '.meal' | translate }}</legend>
            <div class="group__field">
                <mat-form-field floatLabel="auto" class="fullWidth" [hideRequiredMarker]="true">
                    <mat-label>{{ prefix + '.mealType' | translate }}<span class="required"> *</span></mat-label>
                    <mat-select formControlName="mealCode">
                        <mat-option class="options" [ngStyle]="{'font-size.px': '14'}" [value]="undefined">{{ prefix + '.mealType' | translate }}</mat-option>
                        <mat-option [ngStyle]="{'font-size.px': '14'}" *ngFor="let mealCode of mealCodeOptions$ | async" [value]="mealCode.value">
                            {{ mealCode.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="group__field">
                <mat-form-field appearance="standard" class="field__formField row__input" [hideRequiredMarker]="true">
                    <mat-label>{{ prefix + '.quantity' | translate }}<span class="required"> *</span></mat-label>
                    <input matInput type="number" [attr.min]="0" formControlName="mealQuantity" autocomplete="off">
                </mat-form-field>
            </div>
        </fieldset>

        <app-generic-form-buttons [disabled]="profileFrom.invalid" (cancel)="cancelForm()"></app-generic-form-buttons>
    </form>
</div>
