import { TableColumns } from '../../../../../shared/models/table-columns.model';

export const tableUniteTravailColumns: TableColumns[] = [
    { field: 'fiche_service', header: 'ficheService', type: 'string', isEditable: false, width: '55px' },
    { field: 'code_sap', header: 'codeSap', type: 'string', isEditable: false, width: '65px' },
    { field: 'code_presc', header: 'codePresc', type: 'string', width: '80px' },
    { field: 'residu', header: 'residu', type: 'string' },
    { field: 'description', header: 'description', type: 'string', isEditable: false },
    // { field: 'type_cycle', header: 'cycle', type: 'dropdown' },
    { field: 'nature_comptable', header: 'nature_comptable', type: 'string', isEditable: true, width: '135px' },
    { field: 'demande_client', header: 'demandeClient', type: 'checkbox' },
    { field: 'ajout_unitaire', header: 'ajoutUnitaire', type: 'checkbox' },
    { field: 'ajout_horaire', header: 'ajoutHoraire', type: 'checkbox' },
    { field: 'detail_requis', header: 'detailRequis', type: 'checkbox' },
    { field: 'statut', header: 'statut', type: 'dropdown', width: '115px' },
    { field: 'cree_le', header: 'creeLe', type: 'date', format: 'dd-MM-yyyy', filterMatchMode: 'date', isEditable: false },
    { field: 'cree_par', header: 'creePar', type: 'string', isEditable: false, width: '60px' },
    { field: 'modifie_le', header: 'modifieLe', type: 'date', format: 'dd-MM-yyyy', filterMatchMode: 'date', isEditable: false },
    { field: 'modifie_par', header: 'modifiePar', type: 'string', isEditable: false, width: '60px' },
];
