import moment from 'moment';
import { ReportFiltersLocalStorage } from './database/report-filters-local-storage.model';
import { StatusReportFilters } from './status-report-filters.model';

export class ReportFiltersRaw {
    week?: string | undefined = undefined;
    status?: string[] | undefined = undefined;
    partialStatus?: string[] | undefined = undefined;
    filter?: { name: string, value: any } | undefined = undefined;
}

export class ReportFilters {
    week?: string;
    status?: string[];
    partialStatus?: string[];
    filter?: { name: string, value: any };
    haveFilters = false;

    constructor(data?: ReportFiltersRaw) {
        if (data) {
            Object.keys(data).forEach((key: string) => {
                if (key in new ReportFiltersRaw) {
                    this[key] = data[key];
                }
            });
        }
    }

    public fetchFilterFromLocalStorage(localStorageName: string) {
        const reportFilterString = localStorage.getItem(`ogic_fac_reportFilters${localStorageName}`);
        const reportFilterJson: ReportFiltersLocalStorage | null = reportFilterString ? JSON.parse(reportFilterString) as ReportFiltersLocalStorage : null;

        if (reportFilterJson) {
            if (reportFilterJson.week) {
                this.week = moment(reportFilterJson.week).format('YYYYMMDD');
                this.haveFilters = true;
            }

            if (reportFilterJson.columnName && reportFilterJson.columnName !== '' &&
                reportFilterJson.searchValue && reportFilterJson.searchValue !== '') {
                this.filter = {
                    name: reportFilterJson.columnName,
                    value: reportFilterJson.searchValue,
                };
                this.haveFilters = true;
            }

            const compliedStatus: string[] = [];
            reportFilterJson.statuses.forEach((status: StatusReportFilters) => {
                if (status.checked) {
                    compliedStatus.push(status.name);
                }
            });

            if (compliedStatus.length > 0) {
                this.status = compliedStatus;
                this.haveFilters = true;
            }

            const compliedPartialStatuses: string[] = [];
            if (reportFilterJson.partialStatuses) {
                reportFilterJson.partialStatuses.forEach((partialStatus: StatusReportFilters) => {
                    if (partialStatus.checked) {
                        compliedPartialStatuses.push(partialStatus.name);
                    }
                });
            }

            if (compliedPartialStatuses.length > 0) {
                this.partialStatus = compliedPartialStatuses;
                this.haveFilters = true;
            }
        } else {
            this.haveFilters = false;
        }
    }

    public convertToQueryString(): string {
        let output = '';
        let first = true;

        if (this.week) {
            if (first) {
                output += '?';
                first = false;
            }
            output += `week=${this.week}`;
        }

        if (this.status && this.status.length > 0) {
            let outputStatus = '';
            this.status.forEach(status => {
                if (outputStatus !== '') {
                    outputStatus += ',';
                }
                outputStatus += `${status}`;
            });

            if (outputStatus !== '') {
                if (first) {
                    output += '?';
                    first = false;
                } else {
                    output += '&';
                }
                output += `status=${outputStatus}`;
            }
        }

        if (this.partialStatus && this.partialStatus.length > 0) {
            let outputPartialStatus = '';
            this.partialStatus.forEach(pStatus => {
                if (outputPartialStatus !== '') {
                    outputPartialStatus += ',';
                }
                outputPartialStatus += `${pStatus}`;
            });

            if (outputPartialStatus !== '') {
                if (first) {
                    output += '?';
                    first = false;
                } else {
                    output += '&';
                }
                output += `partialStatus=${outputPartialStatus}`;
            }
        }

        if (this.filter) {
            if (first) {
                output += '?';
                first = false;
            } else {
                output += '&';
            }

            output += `f_${this.filter.name}=${this.filter.value}`;
        }

        return output;
    }
}
