import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators/*, ValidationErrors, /*, AbstractControl*/ } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { PilotagesState } from '../../../../store/reducers';
import { TableCodeUnite, TableCodeUniteCycleType, TableCodeUniteStatus } from '../../../../models/table-code-unite.model';
import { isTCUFound } from '../../../../store/selectors';
@Component({
    selector: 'app-table-code-unite-form',
    templateUrl: './table-code-unite-form.component.html',
    styleUrls: ['./table-code-unite-form.component.scss']
})
export class TableCodeUniteFormComponent implements OnInit, OnChanges, OnDestroy {
    public tableCodeUniteForm: FormGroup;
    public statuses: any[] = [];
    public typeCycles: any[] = [];
    public formTranslatePrefix: string;
    public ficheServiceCodeSapError = true;
    public myError = 'Le formulaire n\'est pas conforme.';
    public formDisable = true;

    private subscriptions: Subscription[] = [];

    @Input() public translatePrefix: string;

    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public create: EventEmitter<TableCodeUnite> = new EventEmitter<TableCodeUnite>();

    constructor(
        public formBuilder: FormBuilder,
        public translateService: TranslateService,
        private readonly store: Store<PilotagesState>
    ) {
        this.tableCodeUniteForm = this.formBuilder.group({
            id: [''],
            fiche_service: ['', [Validators.required]],
            description: [{ value: '', disabled: this.formDisable }, [Validators.required]],
            residu: [{ value: '', disabled: this.formDisable }],
            code_sap: [{ value: '', disabled: this.formDisable }, [Validators.required]],
            code_presc: [{ value: '', disabled: this.formDisable }],
            type_cycle: [{ value: '', disabled: this.formDisable }],
            demande_client: [{ value: '', disabled: this.formDisable }],
            ajout_unitaire: [{ value: '', disabled: this.formDisable }],
            ajout_horaire: [{ value: '', disabled: this.formDisable }],
            detail_requis: [{ value: false, disabled: this.formDisable }],
            statut: [{ value: '', disabled: this.formDisable }, [Validators.required]],
            travaux_mecanise: [null],
            cree_le: [''],
            cree_par: [''],
            modifie_le: [''],
            modifie_par: [''],
        });
    }

    public getError(field: string): string {
        if (field === 'fiche_service') {
            if (this.tableCodeUniteForm.controls.fiche_service.hasError('required')) {
                return 'Ce champ est obligatoire';
            }
        }

        if (field === 'description') {
            if (this.tableCodeUniteForm.controls.description.hasError('required')) {
                return 'Ce champ est obligatoire';
            }
        }

        if (field === 'code_sap') {
            if (this.tableCodeUniteForm.controls.code_sap.hasError('alreadyExist')) {
                return `Cette combinaison (${this.tableCodeUniteForm.controls.fiche_service.value} et ${this.tableCodeUniteForm.controls.code_sap.value}) existe déjà`;
            }

            if (this.tableCodeUniteForm.controls.code_sap.hasError('required')) {
                return 'Ce champ est obligatoire';
            }
        }

        return this.myError;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.translatePrefix && changes.translatePrefix.currentValue) {
            this.formTranslatePrefix = changes.translatePrefix.currentValue + 'form';
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public ngOnInit(): void {
        this.statuses = [
            { label: this.translatePrefix + 'statuses.active', value: TableCodeUniteStatus.active },
            { label: this.translatePrefix + 'statuses.inactive', value: TableCodeUniteStatus.inactive },
        ];

        this.typeCycles = [
            { label: this.translatePrefix + 'typeCycle.none', value: TableCodeUniteCycleType.none },
            { label: this.translatePrefix + 'typeCycle.dlr', value: TableCodeUniteCycleType.dlr },
            { label: this.translatePrefix + 'typeCycle.dlrCc', value: TableCodeUniteCycleType.dlrCc },
            { label: this.translatePrefix + 'typeCycle.dlu', value: TableCodeUniteCycleType.dlu },
            { label: this.translatePrefix + 'typeCycle.dluCc', value: TableCodeUniteCycleType.dluCc },
        ];

        this.subscriptions.push(
            this.tableCodeUniteForm.controls.fiche_service.valueChanges.pipe(
                tap((residuText: string) => {
                    this.toggleFormEnable(residuText.length === 0);
                })
            ).subscribe(),
            this.tableCodeUniteForm.controls.residu.valueChanges.pipe(
                tap((residuText: string) => this.tableCodeUniteForm.controls.residu.patchValue(residuText.toUpperCase(), { emitEvent: false }))
            ).subscribe(),
            this.tableCodeUniteForm.controls.code_presc.valueChanges.pipe(
                tap((codePrescText: string) => this.tableCodeUniteForm.controls.code_presc.patchValue(codePrescText.toUpperCase(), { emitEvent: false }))
            ).subscribe(),
            this.tableCodeUniteForm.controls.code_sap.valueChanges.pipe(
                tap((codeSapText: string) => {
                    this.tableCodeUniteForm.controls.code_sap.markAsDirty({ onlySelf: true });
                    this.tableCodeUniteForm.controls.code_sap.patchValue(codeSapText.toUpperCase(), { emitEvent: false });
                }),
                map(() => {
                    return ({ codeSap: this.tableCodeUniteForm.controls.code_sap.value, ficheService: this.tableCodeUniteForm.controls.fiche_service.value });
                }),
                switchMap((mappedValue: { codeSap: string, ficheService: string }) => {
                    if (mappedValue.codeSap !== '') {
                        return this.store.pipe(
                            select(isTCUFound({ codeSAP: mappedValue.codeSap, ficheService: mappedValue.ficheService })),
                            tap((found: boolean | null) => {
                                if (found) {
                                    this.tableCodeUniteForm.controls.code_sap.setErrors({ alreadyExist: true });
                                } else {
                                    this.tableCodeUniteForm.controls.code_sap.setErrors(null);
                                }
                                this.tableCodeUniteForm.updateValueAndValidity();
                            }),
                        );
                    } else {
                        this.tableCodeUniteForm.updateValueAndValidity();
                        return of(null);
                    }
                }),
            ).subscribe(),
        );
    }

    public toggleFormEnable(disable: boolean): void {
        if (disable) {
            this.tableCodeUniteForm.controls.description.disable();
            this.tableCodeUniteForm.controls.residu.disable();
            this.tableCodeUniteForm.controls.type_cycle.disable();
            this.tableCodeUniteForm.controls.code_sap.disable();
            this.tableCodeUniteForm.controls.code_presc.disable();
            this.tableCodeUniteForm.controls.demande_client.disable();
            this.tableCodeUniteForm.controls.ajout_unitaire.disable();
            this.tableCodeUniteForm.controls.ajout_horaire.disable();
            this.tableCodeUniteForm.controls.detail_requis.disable();
            this.tableCodeUniteForm.controls.statut.disable();
        } else {
            this.tableCodeUniteForm.controls.description.enable();
            this.tableCodeUniteForm.controls.residu.enable();
            this.tableCodeUniteForm.controls.type_cycle.enable();
            this.tableCodeUniteForm.controls.code_sap.enable();
            this.tableCodeUniteForm.controls.code_presc.enable();
            this.tableCodeUniteForm.controls.demande_client.enable();
            this.tableCodeUniteForm.controls.ajout_unitaire.enable();
            this.tableCodeUniteForm.controls.ajout_horaire.enable();
            this.tableCodeUniteForm.controls.detail_requis.enable();
            this.tableCodeUniteForm.controls.statut.enable();
        }
        this.tableCodeUniteForm.updateValueAndValidity();
    }

    public cancelForm(): void {
        this.cancel.emit(true);
    }

    public onSubmit(): void {
        const { valid } = this.tableCodeUniteForm;

        if (valid) {
            const value = {
                ...this.tableCodeUniteForm.getRawValue(),
            };

            const { id, ...cleanValue } = value;
            this.create.emit(cleanValue);
        }
    }
}
