import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { ApiService } from '../../../ogiv-core/services/api.service';
import {
    JobCodeActionType,
    LoadJobCodes,
    LoadJobCodesSuccess,
    LoadJobCodesFail,
    LoadJobCodesNoResult
} from '../actions/job-codes.action';
import { JobCode } from '../../../shared/models/database/job-code.model';

@Injectable()
export class JobCodesEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService,
    ) { }

    public loadJobCode$ = createEffect(() => this.action$.pipe(
        ofType<LoadJobCodes>(JobCodeActionType.LOAD_JOB_CODES),
        switchMap(() => {
            return this.apiService.get<JobCode[]>('sap/data/workCodes').pipe(
                map((returnedJobCode: JobCode[]) =>
                    (returnedJobCode.length > 0)
                        ? new LoadJobCodesSuccess(returnedJobCode)
                        : new LoadJobCodesNoResult()
                ),
                catchError((error: any) => of(new LoadJobCodesFail(error))),
            );
        }),
    ));
}
