<app-tag *ngIf="timesheet$ | async" [status]="currentTimesheetStatus" [capitalize]="false" [borderRadius]="4">
    <div  class="status">
        <div class="status__text">
            <div>
                {{ translatePrefix + '.statusTitle' | translate }}
            </div>
            <div class="text__status">
                {{ translatePrefix + '.' + currentStatus | translate | lowercase }}
            </div>
        </div>
        <div class="status__buttonContainer">
            <ng-container *hasRole="editAllowedRoles">
                <div *ngIf="isDisplayingSubmit" class="buttonContainer__button">
                    <button mat-flat-button class="button__basic" (click)="submitRecord()">
                        <i class="fas fa-sign-in-alt basic__logo"></i>
                        <span *ngIf="displayButtonText" class="basic__textDisplay">{{ translatePrefix + '.submit' | translate | uppercase }}</span>
                    </button>
                </div>
            </ng-container>
            <ng-container>
                <div *ngIf="isDisplayingLegend" class="buttonContainer__button">
                    <button mat-flat-button class="button__basic"(click)="displayLegend()">
                        <i class="fas fa-magic basic__logo"></i>
                        <span *ngIf="displayButtonText" class="basic__textDisplay">{{ translatePrefix + '.legend' | translate | uppercase }}</span>
                    </button>
                </div>
            </ng-container>
            <div class="buttonContainer__button">
                <button mat-flat-button class="button__basic" (click)="refresh()">
                    <i class="fas fa-sync-alt basic__logo"></i>
                    <!-- <span [ngClass]="documentWidth <= hideButtonTextValue ? 'basic__textHidden' : 'basic__textDisplay'">{{ translatePrefix + '.refresh' | translate | uppercase }}</span> -->
                    <span *ngIf="displayButtonText" class="basic__textDisplay">{{ translatePrefix + '.refresh' | translate | uppercase }}</span>
                </button>
            </div>
        </div>
    </div>
</app-tag>
