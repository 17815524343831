import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { DemandesClientGlobalState, demandesClientStoreReducers } from './reducers';

export const DEMANDES_CLIENT_TOKEN = new InjectionToken<ActionReducerMap<DemandesClientGlobalState>>('Demandes Client reducers');

export function getDemandesClientReducers(): ActionReducerMap<DemandesClientGlobalState> {
    return demandesClientStoreReducers;
}
