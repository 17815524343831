import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngrx/store';

import { PostLine } from '../../../../../shared/models/database/post-line.model';
import { PostLineGroup } from '../../../../../shared/models/database/post-line-group.model';
import { Unit } from '../../../../../shared/models/database/unit.model';
import { TimesheetState } from '../../../../store/reducers';
import { GenericFormModalComponent } from '../generic-form-modal/generic-form-modal.component';
import { FormModalData } from '../generic-form-modal/generic-form-modal-data.model';
import { CreatePostLine } from '../../../../store/actions';
import { OrderType } from '../../../../../shared/models/database/order-type.enum';
import { UserRole } from '../../../../../shared/models/user-roles.model';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { CurrentUser, UserService } from '../../../../../core/services/user.service';

@Component({
    selector: 'app-work-done-charge-number-accordion',
    templateUrl: './work-done-charge-number-accordion.component.html',
    styleUrls: ['./work-done-charge-number-accordion.component.scss']
})
export class WorkDoneChargeNumberAccordionComponent implements OnChanges {
    public displayPostLines: PostLine[] = [];
    public title = '';
    public subTitle: string | null = null;
    public numberPostLines = 0;
    public hoursPostLines = 0;

    @Input() public timesheetDateId: string;
    @Input() public orderId: number;
    @Input() public isLock = false;
    @Input() public postLineGroup: PostLineGroup;
    @Input() public requiredSubtitle = false;
    @Input() public canAddEditTime = true;
    @Input() public orderType: OrderType;
    @Input() public editAllowedRoles: UserRole[] = [];
    @Input() public pathChoice: AlternativePath;

    constructor(
        public dialog: MatDialog,
        private readonly store: Store<TimesheetState>,
        private userService: UserService
    ) { }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.postLineGroup && changes.postLineGroup.currentValue) {

            if (changes.postLineGroup.currentValue.chargeNumber) {
                this.title = changes.postLineGroup.currentValue.chargeNumber;
            }

            this.displayPostLines = this.getDisplayPostLines(changes.postLineGroup.currentValue);
            this.numberPostLines = this.getNumberOfPostLines(changes.postLineGroup.currentValue);
            this.hoursPostLines = this.calculHoursOfWorkDone(changes.postLineGroup.currentValue);

            if (changes.postLineGroup.currentValue.powerOutageNumber) {
                this.subTitle = this.getSubtitle(changes.postLineGroup.currentValue.powerOutageNumber);
            }
        }
    }

    public jsPillarLineTrackById(_: number, postLineGroup: PostLineGroup) {
        return `${postLineGroup.chargeNumber}${(postLineGroup.powerOutageNumber) ? '_' + postLineGroup.powerOutageNumber : ''}`;
    }

    public getSubtitle(value: string): string | null {
        return (value) ? `Panne: ${value}` : null;
    }

    public getDisplayPostLines(postLineGroup: PostLineGroup): PostLine[] {
        return (postLineGroup.postLines && postLineGroup.postLines.length > 0) ? postLineGroup.postLines : [];
    }

    public getNumberOfPostLines(postLineGroup: PostLineGroup): number {
        return (postLineGroup.postLines && postLineGroup.postLines.length > 0) ? postLineGroup.postLines.length : 0;
    }

    public calculHoursOfWorkDone(postLineGroup: PostLineGroup): number {
        let number = 0;
        if (postLineGroup.postLines && postLineGroup.postLines.length > 0) {
            postLineGroup.postLines.forEach((postLine: PostLine) => {
                if (postLine && postLine.units && postLine.units.length > 0) {
                    postLine.units.forEach((unit: Unit) => {
                        number += unit.hour;
                    });
                }
            });
        }
        return number;
    }

    public addPostLine(event: MouseEvent, postLineGroup: PostLineGroup): void {
        event.stopPropagation();
        const addPostLineDialog = this.dialog.open(GenericFormModalComponent, {
            width: '80%',
            maxWidth: '450px',
            data: {
                icon: 'fas fa-plus',
                formName: 'postLine',
                translatePrefix: 'timesheet.order.tab.accordionTitle.unitOfWorkDone.postLineForm',
                parentId: this.orderId,
                specificDetail: {
                    chargeNumber: postLineGroup.chargeNumber,
                    powerOutageNumber: postLineGroup.powerOutageNumber,
                    orderType: this.orderType,
                },
                pathChoice: this.pathChoice,
            } as FormModalData,
        });

        addPostLineDialog.afterClosed().subscribe((result: PostLine) => {
            if (result) {
                let imperson: CurrentUser | undefined;
                if (this.userService.currentUser.isImpersonnified) {
                    imperson = this.userService.currentUser;
                }
                this.store.dispatch(new CreatePostLine(result, this.timesheetDateId, this.pathChoice, imperson));
            }
        });
    }
}
