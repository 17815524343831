import { Entrepreneur } from '../../models/entrepreneur.model';
import { EntrepreneurAction, EntrepreneurActionType } from '../actions/entrepreneurs.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface EntrepreneursState extends EntityState<Entrepreneur> {
    loading: boolean;
    loaded: boolean;
}

export const entrepreneursAdapter: EntityAdapter<Entrepreneur> = createEntityAdapter<Entrepreneur>({
    selectId: (entrepreneur: Entrepreneur) => entrepreneur.id,
});

export const entrepreneursInitialState = entrepreneursAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function entrepreneurReducer(
    state: EntrepreneursState = entrepreneursInitialState,
    action: EntrepreneurAction,
): EntrepreneursState {
    switch (action.type) {
        case EntrepreneurActionType.UPDATE_ONE_ENTREPRENEUR:
        case EntrepreneurActionType.DEACTIVATE_ONE_ENTREPRENEUR:
        case EntrepreneurActionType.REACTIVATE_ONE_ENTREPRENEUR:
        case EntrepreneurActionType.LOAD_ENTREPRENEURS: {
            return {
                ...state,
                loading: true,
            };
        }
        case EntrepreneurActionType.DEACTIVATE_ONE_ENTREPRENEUR_SUCCESS:
        case EntrepreneurActionType.REACTIVATE_ONE_ENTREPRENEUR_SUCCESS:
        case EntrepreneurActionType.UPDATE_ONE_ENTREPRENEUR_SUCCESS: {
            return entrepreneursAdapter.upsertOne(action.entrepreneur, {
                ...state,
                loading: false,
            });
        }

        case EntrepreneurActionType.DEACTIVATE_ONE_ENTREPRENEUR_FAIL:
        case EntrepreneurActionType.REACTIVATE_ONE_ENTREPRENEUR_FAIL:
        case EntrepreneurActionType.UPDATE_ONE_ENTREPRENEUR_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case EntrepreneurActionType.LOAD_ENTREPRENEURS_SUCCESS: {
            return entrepreneursAdapter.setAll(action.entrepreneurs, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case EntrepreneurActionType.LOAD_ENTREPRENEURS_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case EntrepreneurActionType.LOAD_ENTREPRENEURS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
