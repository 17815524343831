import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { SelectItem } from 'primeng/api';

import { ordersAdapter, OrdersState } from '../reducers/orders.reducer';
import { OgivCoreState, getOgivCoreState } from '../reducers';
import { Order } from '../../../shared/models/database/order.model';

const {
    selectAll,
    selectEntities,
    selectIds,
} = ordersAdapter.getSelectors();

/**
 * General order State selector
 */

export const getOrdersState = createSelector(
    getOgivCoreState,
    (state: OgivCoreState) => state.orders,
);

export const getOrdersLoading = createSelector(
    getOrdersState,
    (state: OrdersState) => state.loading,
);

export const getOrdersLoaded = createSelector(
    getOrdersState,
    (state: OrdersState) => state.loaded,
);

export const shouldLoadOrders = createSelector(
    getOrdersLoading,
    getOrdersLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllOrders = createSelector(
    getOrdersState,
    selectAll,
);

export const getOrdersEntities = createSelector(
    getOrdersState,
    selectEntities,
);

export const getOrdersIds = createSelector(
    getOrdersState,
    selectIds,
);

export const getOrdersSelectOptions = (props: any = {}) => createSelector(
    getAllOrders,
    (orders: Order[]): SelectItem[] => {
        const ordersOptions = orders.map((order: Order) => {
            return {
                label: order.number.toString(),
                value: order.id
            } as SelectItem;
        });

        if (props.addEmptyOption) {
            ordersOptions.unshift({ label: 'Selectionnez une commande', value: null } as SelectItem);
        }

        return ordersOptions;
    }
);

export const getOrderById = (props: any) => createSelector(
    getOrdersEntities,
    (entities: Dictionary<Order>) => {
        return entities && entities[props.entityId] || null;
    },
);
