import { Injectable } from '@angular/core';

export interface CurrentUser {
    lastName?: string;
    firstName?: string;
    idAgol?: string;
    isImpersonnified: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    // Connected user should never be changed.
    private _connectedUser: CurrentUser;
    private _currentUser: CurrentUser;

    public set connectedUser(user: CurrentUser) {
        this._connectedUser = user;
        this._currentUser = user;
    }

    public get connectedUser(): CurrentUser {
        return this._connectedUser;
    }

    public set currentUser(user: CurrentUser) {
        this._currentUser = user;
    }

    public get currentUser(): CurrentUser {
        return this._currentUser;
    }

    constructor() {
        this.connectedUser = {
            lastName: '',
            firstName: '',
            idAgol: '',
            isImpersonnified: false
        };
    }

    public emailToIdAgol(email: string): string {
        return email.replace(/@/gi, '_');
    }
}
