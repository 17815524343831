import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import {
    UpdateOneEquipment,
    CreateEquipment,
    EquipmentActionTypes,
    CreateEquipmentFail,
    UpdateOneEquipmentFail,
    DeleteOneEquipment,
    DeleteOneEquipmentFail,
    CreateEquipmentSuccess,
    UpdateOneEquipmentSuccess,
    DeleteOneEquipmentSuccess,
} from '../actions/equipments.action';
import { Equipment } from '../../../shared/models/database/equipment.model';
import { Timesheet } from '../../../shared/models/database/timesheet.model';
import { ApiService } from '../../../ogiv-core/services/api.service';
import { CreateTimesheetSuccess } from '../actions';
import { AddOneAppNotification } from '../../../core/store/actions/app-notifications.actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class EquipmentsEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    /************************/
    /*******  Create  *******/
    /************************/
    public createEquipment$ = createEffect(() => this.actions$.pipe(
        ofType<CreateEquipment>(EquipmentActionTypes.CREATE_EQUIPMENT),
        mergeMap((action: CreateEquipment) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/equipment`;
                } else {
                    url += `${action.timesheetDateId}/equipment`;
                }
            } else {
                url += `${action.timesheetDateId}/equipment`;
            }

            return this.apiService.post<Timesheet, Equipment>(url, action.equipment).pipe(
                mergeMap((createdTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(createdTimesheet)),
                    new CreateEquipmentSuccess(),
                ]),
                catchError((error: any) => of(new CreateEquipmentFail(error))),
            );
        }),
    ));

    public createTimesheetSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<CreateEquipmentSuccess>(EquipmentActionTypes.CREATE_EQUIPMENT_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.equip.create.success',
            }))
        ]
        ),
    ));

    public createEquipmentFail$ = createEffect(() => this.actions$.pipe(
        ofType<CreateEquipmentFail>(EquipmentActionTypes.CREATE_EQUIPMENT_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.equip.create.error',
            }))
        ]
        ),
    ));

    /************************/
    /*******  Update  *******/
    /************************/
    public updateOneEquipment$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneEquipment>(EquipmentActionTypes.UPDATE_ONE_EQUIPMENT),
        mergeMap((action: UpdateOneEquipment) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/equipment`;
                } else {
                    url += `${action.timesheetDateId}/equipment`;
                }
            } else {
                url += `${action.timesheetDateId}/equipment`;
            }

            return this.apiService.put<Timesheet, Equipment>(url, action.equipment).pipe(
                mergeMap((createdTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(createdTimesheet)),
                    new UpdateOneEquipmentSuccess(),
                ]),
                catchError((error: any) => of(new UpdateOneEquipmentFail(error))),
            );
        }),
    ));

    public updateOneEquipmentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneEquipmentSuccess>(EquipmentActionTypes.UPDATE_ONE_EQUIPMENT_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.equip.update.success',
            }))
        ]
        ),
    ));

    public updateOneEquipmentFail$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneEquipmentFail>(EquipmentActionTypes.UPDATE_ONE_EQUIPMENT_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.equip.update.error',
            }))
        ]
        ),
    ));

    /************************/
    /*******  Delete  *******/
    /************************/
    public deleteOneEquipment$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneEquipment>(EquipmentActionTypes.DELETE_ONE_EQUIPMENT),
        mergeMap((action: DeleteOneEquipment) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/equipment/${action.equipment.id}`;
                } else {
                    url += `${action.timesheetDateId}/equipment/${action.equipment.id}`;
                }
            } else {
                url += `${action.timesheetDateId}/equipment/${action.equipment.id}`;
            }

            return this.apiService.delete<Timesheet>(url).pipe(
                mergeMap((createdTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(createdTimesheet)),
                    new DeleteOneEquipmentSuccess(),
                ]),
                catchError((error: any) => of(new DeleteOneEquipmentFail(error))),
            );
        }),
    ));

    public deleteOneEquipmentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneEquipmentSuccess>(EquipmentActionTypes.DELETE_ONE_EQUIPMENT_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.equip.delete.success',
            }))
        ]
        ),
    ));

    public deleteOneEquipmentFail$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneEquipmentFail>(EquipmentActionTypes.DELETE_ONE_EQUIPMENT_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.equip.delete.error',
            }))
        ]
        ),
    ));
}
