<!-- <h1>{{ translatePrefix + '.title' | translate }}</h1> -->
<app-generic-detail-page-header
    [pageLogo]="'fas fa-dollar-sign'"
    [pageTitle]="translatePrefix + '.title' | translate"
    (back)="backPressed = true">
</app-generic-detail-page-header>

<app-billing-report-form [editAllowedRoles]="[]" [userRoles]="$any(currentUserRoles$ | async)"
    [formMode]="formMode" [translatePrefix]="translatePrefix" [pathChoice]="pathChoice" [backPressed]="backPressed">
</app-billing-report-form>
