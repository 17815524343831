import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { Worker } from '../../../shared/models/database/worker.model';
import { TimesheetState, getTimesheetState } from '../reducers';
import { workersAdapter, WorkersState } from '../reducers/team-mates.reducer';

const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = workersAdapter.getSelectors();

/**
 * General Worker State selector
 */

export const getWorkersState = createSelector(
    getTimesheetState,
    (state: TimesheetState) => state.workers,
);

export const getWorkersLoading = createSelector(
    getWorkersState,
    (state: WorkersState) => state.loading,
);

export const getWorkersLoaded = createSelector(
    getWorkersState,
    (state: WorkersState) => state.loaded,
);

export const shouldLoadWorkers = createSelector(
    getWorkersLoading,
    getWorkersLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllWorkers = createSelector(
    getWorkersState,
    selectAll,
);

export const getWorkersEntities = createSelector(
    getWorkersState,
    selectEntities,
);

export const getWorkersIds = createSelector(
    getWorkersState,
    selectIds,
);

export const getWorkerById = (props: any) => createSelector(
    getWorkersEntities,
    (entities: Dictionary<Worker>) => {
        return entities && entities[props.entityId] || null;
    }
);

export const getWorkersCount = createSelector(
    getWorkersState,
    selectTotal,
);

export const getWorkersTotalHours = createSelector(
    getAllWorkers,
    (workers: Worker[]): number =>
        workers.reduce((acc: number, worker: Worker) =>
            acc + (worker.hour || 0), 0)
);
