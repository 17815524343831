import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-modal-remark',
    templateUrl: './modal-remark.component.html',
    styleUrls: ['./modal-remark.component.scss'],
})
export class ModalRemarkComponent implements OnInit {
    public displayCancelButton = false;
    public refusedStatus = '';
    public titleEnt = '';
    public titleHQ = '';
    public titleHQCGC = '';
    public titleHQOrigin = '';
    public titleHQOriginCGC = '';
    public validatedRemarkBy = '';

    @Input() public report: any;
    @Input() public translationPrefix: string;

    @Output() public closed: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private translate: TranslateService,
    ) { }

    public ngOnInit(): void {
        if (this.report && this.report.company) {
            this.titleEnt = `${this.translate.instant(this.translationPrefix + '.refusedBy')} ${this.report.company}`;
        }
        this.refusedStatus = this.translate.instant(this.translationPrefix + '.status');
        this.titleHQCGC = `${this.translate.instant(this.translationPrefix + '.refusedBy')} ${this.translate.instant(this.translationPrefix + '.titleTSRNCHQCGC')}`;
        // eslint-disable-next-line max-len
        this.titleHQOriginCGC = `${this.translate.instant(this.translationPrefix + '.refusedByOrigin')} ${this.translate.instant(this.translationPrefix + '.titleTSRNCHQCGC')}`;
        this.titleHQ = `${this.translate.instant(this.translationPrefix + '.refusedBy')} ${this.translate.instant(this.translationPrefix + '.titleTSRNCHQTech')}`;
        // eslint-disable-next-line max-len
        this.titleHQOrigin = `${this.translate.instant(this.translationPrefix + '.refusedByOrigin')} ${this.translate.instant(this.translationPrefix + '.titleTSRNCHQTech')}`;

        if (this.report && this.report.validatedRole) {
            if (this.report.validatedRole === 'CGC') {
                this.validatedRemarkBy = `${this.translate.instant(this.translationPrefix + '.refusedByCGC')}`;
            } else {
                this.validatedRemarkBy = `${this.translate.instant(this.translationPrefix + '.refusedByTech')}`;
            }
        }
    }

    public onClose(): void {
        this.closed.emit();
    }
}
