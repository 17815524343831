import { Action } from '@ngrx/store';
import { BillingReportPreviewRow } from '../../../shared/models/database/billing-report-preview.model';

/**
 * Types
 */
export enum BillingReportPreviewActionType {
    LOAD_BILLING_REPORT_PREVIEW = '[Billing Report Preview] Load Billing Report preview',
    LOAD_BILLING_REPORT_PREVIEW_FAIL = '[Billing Report Preview] Load Billing Report preview Fail',
    LOAD_BILLING_REPORT_PREVIEW_SUCCESS = '[Billing Report Preview] Load Billing Report preview Success',
}

/**
 * LOAD
 */
export class LoadBillingReportPreview implements Action {
    readonly type = BillingReportPreviewActionType.LOAD_BILLING_REPORT_PREVIEW;
    constructor(public billingReportId: number, public path: string) { }
}

export class LoadBillingReportPreviewSuccess implements Action {
    readonly type = BillingReportPreviewActionType.LOAD_BILLING_REPORT_PREVIEW_SUCCESS;
    constructor(public billingReportPreviewRows: BillingReportPreviewRow[]) { }
}

export class LoadBillingReportPreviewFail implements Action {
    readonly type = BillingReportPreviewActionType.LOAD_BILLING_REPORT_PREVIEW_FAIL;
    constructor(public error: any) { }
}

export type BillingReportPreviewAction =
    | LoadBillingReportPreview
    | LoadBillingReportPreviewSuccess
    | LoadBillingReportPreviewFail;
