import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../ogiv-core/services';
import {
    TimesheetsChargeNumberActionType,
    LoadTimesheetsChargeNumber,
    LoadTimesheetsChargeNumberSuccess,
    LoadTimesheetsChargeNumberFail,
    UpdateOneTimesheetChargeNumber,
    UpdateOneTimesheetChargeNumberFail,
    UpdateAllTimesheetChargeNumberSuccess,
} from '../actions/timesheet-charge-number.action';
import { TimesheetChargeNumber, TimesheetChargeNumberSavingFilters, TimesheetChargeNumberSavingValues } from '../../models/timesheet-charge-number.model';
import { TimesheetBasicSaving } from '../../models/timesheet-basic-saving.model';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class TimesheetChargeNumberEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService
    ) { }

    public loadTimesheetsChargeNumber$ = createEffect(() => this.action$.pipe(
        ofType<LoadTimesheetsChargeNumber>(TimesheetsChargeNumberActionType.LOAD_TIMESHEETS_CHARGE_NUMBER),
        mergeMap(() => {
            return this.apiService.get<TimesheetChargeNumber[]>(`hq/pilotage/timesheets/chargenumber`).pipe(
                map((returnedTimesheetsChargeNumber: TimesheetChargeNumber[]) => {
                    return new LoadTimesheetsChargeNumberSuccess(returnedTimesheetsChargeNumber);
                }
                ),
                catchError((error: any) => of(new LoadTimesheetsChargeNumberFail(error))),
            );

        }),
    ));

    public updatedTimesheetsChargeNumber$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneTimesheetChargeNumber>(TimesheetsChargeNumberActionType.UPDATE_ONE_TIMESHEETS_CHARGE_NUMBER),
        mergeMap((action: UpdateOneTimesheetChargeNumber) => {
            return this.apiService.put<TimesheetChargeNumber[], TimesheetBasicSaving<TimesheetChargeNumberSavingValues, TimesheetChargeNumberSavingFilters>>(
                `hq/pilotage/timesheets/chargenumber`, action.valueToSave).pipe(
                    map((updatedTimesheetChargeNumber: TimesheetChargeNumber[]) => {
                        return new UpdateAllTimesheetChargeNumberSuccess(updatedTimesheetChargeNumber);
                    }
                    ),
                    catchError((error: any) => of(new UpdateOneTimesheetChargeNumberFail(error))),
                );
        })
    ));

    public updateAllTimesheetChargeNumberSuccess$ = createEffect(() => this.action$.pipe(
        ofType<UpdateAllTimesheetChargeNumberSuccess>(TimesheetsChargeNumberActionType.UPDATE_ALL_TIMESHEETS_CHARGE_NUMBER_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'pilotages.notifications.chargenumber.update.success',
            }))
        ]
        ),
    ));

    public updateOneTimesheetsCommandePosteTypeFail$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneTimesheetChargeNumberFail>(TimesheetsChargeNumberActionType.UPDATE_ONE_TIMESHEETS_CHARGE_NUMBER_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.chargenumber.update.error',
            }))
        ]
        ),
    ));
}
