export enum PageSelector {
    timesheet = 'timesheet',
    reportEnt = 'reportEnt',
    reportHQ = 'reportHQ',
    progressReport = 'progressReport',
    billingEnt = 'billingEnt',
    billingHQ = 'billingHQ',
    clientRequest = 'clientRequest',
    projectRequest = 'projectRequest',
    specialMeasures = 'specialMeasures',
    pilotage = 'pilotage',
    ogivEnt = 'ogivEnt',
    ogivTech = 'ogivTech',
}
