import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { units } from '../../../shared/mocks/database/units.mock';
import {
    LoadUnits,
    LoadUnitsSuccess,
    UpdateOneUnit,
    CreateUnit,
    UnitActionTypes,
    CreateUnitFail,
    UpdateOneUnitFail,
    DeleteOneUnit,
    DeleteOneUnitFail,
    CreateUnitSuccess,
    UpdateOneUnitSuccess,
    DeleteOneUnitSuccess,
} from '../actions/units.action';
import { Unit } from '../../../shared/models/database/unit.model';
import { Timesheet } from '../../../shared/models/database/timesheet.model';
import { ApiService } from '../../../ogiv-core/services/api.service';
import { CreateTimesheetSuccess } from '../actions';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class UnitsEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    /*************************/
    /*******  LOADING  *******/
    /*************************/
    public loadUnits$ = createEffect(() => this.actions$.pipe(
        ofType<LoadUnits>(UnitActionTypes.LOAD_UNITS),
        switchMap(() => {
            const mappedUnits = units.map(unit => new Unit(unit));
            return of(new LoadUnitsSuccess(mappedUnits));
        }),
    ));

    /************************/
    /*******  CREATE  *******/
    /************************/
    public createUnit$ = createEffect(() => this.actions$.pipe(
        ofType<CreateUnit>(UnitActionTypes.CREATE_UNIT),
        mergeMap((action: CreateUnit) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/unit`;
                } else {
                    url += `${action.timesheetDateId}/unit`;
                }
            } else {
                url += `${action.timesheetDateId}/unit`;
            }

            return this.apiService.post<Timesheet, Unit>(url, action.unit).pipe(
                mergeMap((createdTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(createdTimesheet)),
                    new CreateUnitSuccess()
                ]),
                catchError((error: any) => of(new CreateUnitFail(error))),
            );
        }),
    ));

    public createUnitSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<CreateUnitSuccess>(UnitActionTypes.CREATE_UNIT_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.unit.create.success',
            }))
        ]
        ),
    ));

    public createUnitFail$ = createEffect(() => this.actions$.pipe(
        ofType<CreateUnitFail>(UnitActionTypes.CREATE_UNIT_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.unit.create.error',
            }))
        ]),
    ));

    /************************/
    /*******  UPDATE  *******/
    /************************/
    public updateOneUnit$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneUnit>(UnitActionTypes.UPDATE_ONE_UNIT),
        mergeMap((action: UpdateOneUnit) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/unit`;
                } else {
                    url += `${action.timesheetDateId}/unit`;
                }
            } else {
                url += `${action.timesheetDateId}/unit`;
            }

            return this.apiService.put<Timesheet, Unit>(url, action.unit).pipe(
                mergeMap((createdTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(createdTimesheet)),
                    new UpdateOneUnitSuccess()
                ]),
                catchError((error: any) => of(new UpdateOneUnitFail(error))),
            );
        }),
    ));

    public updateOneUnitSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneUnitSuccess>(UnitActionTypes.UPDATE_ONE_UNIT_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.unit.update.success',
            }))
        ]
        ),
    ));

    public updateOneUnitFail$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneUnitFail>(UnitActionTypes.UPDATE_ONE_UNIT_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.unit.update.error',
            }))
        ]),
    ));

    /************************/
    /*******  DELETE  *******/
    /************************/
    public deleteOneUnit$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneUnit>(UnitActionTypes.DELETE_ONE_UNIT),
        mergeMap((action: DeleteOneUnit) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/unit/${action.unit.id}`;
                } else {
                    url += `${action.timesheetDateId}/unit/${action.unit.id}`;
                }
            } else {
                url += `${action.timesheetDateId}/unit/${action.unit.id}`;
            }
            return this.apiService.delete<Timesheet>(url).pipe(
                mergeMap((createdTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(createdTimesheet)),
                    new DeleteOneUnitSuccess()
                ]),
                catchError((error: any) => of(new DeleteOneUnitFail(error))),
            );
        }),
    ));

    public deleteOneUnitSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneUnitSuccess>(UnitActionTypes.DELETE_ONE_UNIT_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.unit.delete.success',
            }))
        ]
        ),
    ));

    public deleteOneUnitFail$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOneUnitFail>(UnitActionTypes.DELETE_ONE_UNIT_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.unit.delete.error',
            }))
        ]),
    ));
}
