import {
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
    OnInit
} from '@angular/core';

import { select, Store } from '@ngrx/store';

import { CoreState } from '../../core/store/reducers';
import { getUserRoles } from '../../core/store/selectors';
import { UserRole } from '../models/user-roles.model';

export enum LogicalOperator {
    OR = 'OR',
    AND = 'AND',
}

@Directive({
    selector: '[hasRole]',
})
export class HasRoleDirective implements OnInit {
    private currentUserRoles: UserRole[];
    private permissions: string[] = [];
    private logicalOperator: string = LogicalOperator.OR;
    private isHidden = true;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private readonly store: Store<CoreState>,
    ) { }

    public ngOnInit(): void {
        this.store.pipe(
            select(getUserRoles),
        ).subscribe((roles: UserRole[]) => {
            this.currentUserRoles = roles;
            this.updateView();
        });
    }

    @Input()
    public set hasRole(roles: string[]) {
        this.permissions = roles;
        this.updateView();
    }

    @Input()
    public set hasRoleOperator(permissionOperator: string) {
        this.logicalOperator = permissionOperator;
        this.updateView();
    }

    private updateView(): void {
        if (!this.checkRole()) {
            this.isHidden = true;
            this.viewContainer.clear();

            return;
        }

        if (this.isHidden) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.isHidden = false;
        }
    }

    private checkRole(): boolean {
        let hasRole = false;

        if (this.currentUserRoles && this.currentUserRoles.length > 0) {
            const arrayOperator: 'some' | 'every' = this.logicalOperator === LogicalOperator.OR
                ? 'some'
                : 'every';

            if (this.permissions)
                hasRole = this.currentUserRoles[arrayOperator](userRole => this.permissions.includes(userRole));
        }

        return hasRole;
    }
}
