<div class="header">
    <h1 class="header__title">
        <i class="fas fa-home space" ></i>
        <span>
            OGIV - Tableau de bord
        </span>
    </h1>
</div>

<div *ngIf="displayRoles">
    <h2>Demo, changement de rôle:</h2>

    <p>Rôle courant: <strong>{{ (userRoles$ | async)?.join(',') }}</strong></p>

    <div class="container">
        <div class="container__interneExterne">
            <div class="title">
                <Span class="text">Externe</Span>
            </div>
            <div class="demo__buttons">
                <button pButton (click)="setRole(userRole.chefequipe)" class="ui-button-raised" [label]="'Chef d\'équipe'"></button>
                <button pButton (click)="setRole(userRole.chefgroupe)" class="ui-button-raised" [label]="'Chef de groupe'"></button>
                <button pButton (click)="setRole(userRole.entrepreneur)" class="ui-button-raised" [label]="'Entrepreneur'"></button>
            </div>
        </div>
        <div class="container__interneExterne">
            <div class="title">
                <Span class="text">Interne</Span>
            </div>
            <div class="demo__buttons">
                <button pButton (click)="setRole(userRole.support)" class="ui-button-raised" [label]="'Support'"></button>
                <button pButton (click)="setRole(userRole.pilote)" class="ui-button-raised" [label]="'Pilote'"></button>
                <button pButton (click)="setRole(userRole.techinter)" class="ui-button-raised" [label]="'Technicien Intermédiaire'"></button>
                <button pButton (click)="setRole(userRole.tech)" class="ui-button-raised" [label]="'Technicien'"></button>
                <button pButton (click)="setRole(userRole.planificateur)" class="ui-button-raised" [label]="'Planificateur'"></button>
                <button pButton (click)="setRole(userRole.cgc)" class="ui-button-raised" [label]="'CGC'"></button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!displayRoles">
    Sélectionner un lien dans le panneau de gauche
</div>

<div class="version">Version {{version}}</div>
