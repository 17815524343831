<div>
    <form [formGroup]="jobClassForm" (ngSubmit)="createJobClass()">
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField main" [hideRequiredMarker]="true">
                <mat-label>{{ translatePrefix + '.jobClassCode' | translate }}<span class="required">*</span></mat-label>
                <input matInput type="text" formControlName="code" autocomplete="off" [tabIndex]="tabIndex">
            </mat-form-field>
        </div>
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField" [hideRequiredMarker]="true">
                <mat-label>{{ translatePrefix + '.jobClassDescr' | translate }}<span class="required">*</span></mat-label>
                <input matInput type="text" formControlName="description" autocomplete="off" [tabIndex]="tabIndex">
            </mat-form-field>
        </div>
        <app-generic-form-buttons [disabled]="jobClassForm.invalid" [cancelLogo]="'back'" (cancel)="cancelForm()" [freezeTabIndex]="freezeTabIndex">
        </app-generic-form-buttons>
    </form>
</div>
