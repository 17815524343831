import { EquipmentCode } from '../../../shared/models/database/equipment-code.model';
import { EquipmentCodeAction, EquipmentCodeActionType } from '../actions/equipment-codes.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface EquipmentCodesState extends EntityState<EquipmentCode> {
    loading: boolean;
    loaded: boolean;
}

export const equipmentCodesAdapter: EntityAdapter<EquipmentCode> = createEntityAdapter<EquipmentCode>({
    selectId: (equipmentCode: EquipmentCode) => equipmentCode.id,
});

export const equipmentCodesInitialState = equipmentCodesAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function equipmentCodeReducer(
    state: EquipmentCodesState = equipmentCodesInitialState,
    action: EquipmentCodeAction,
): EquipmentCodesState {
    switch (action.type) {
        case EquipmentCodeActionType.CREATE_EQUIPMENT_CODE:
        case EquipmentCodeActionType.UPDATE_ONE_EQUIPMENT_CODE:
        case EquipmentCodeActionType.LOAD_EQUIPMENT_CODES: {
            return {
                ...state,
                loading: true,
            };
        }

        case EquipmentCodeActionType.CREATE_EQUIPMENT_CODE_SUCCESS: {
            return equipmentCodesAdapter.addOne(action.equipmentCode, {
                ...state,
                loading: false,
            });
        }

        case EquipmentCodeActionType.UPDATE_ONE_EQUIPMENT_CODE_SUCCESS: {
            return equipmentCodesAdapter.upsertOne(action.equipmentCode, {
                ...state,
                loading: false,
            });
        }

        case EquipmentCodeActionType.CREATE_EQUIPMENT_CODE_FAIL:
        case EquipmentCodeActionType.UPDATE_ONE_EQUIPMENT_CODE_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case EquipmentCodeActionType.LOAD_EQUIPMENT_CODES_SUCCESS: {
            return equipmentCodesAdapter.setAll(action.equipmentCodes, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case EquipmentCodeActionType.LOAD_EQUIPMENT_CODES_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case EquipmentCodeActionType.LOAD_EQUIPMENT_CODES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
