import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { OpenIdService, OpenIdUserInfo } from '@ngxhq/security';

import {
    AssigneOGIVEnum, DemandeClient, EngagementDemandeClient, NouvelEngagementInfo, RemarqueInfo, StatutOgivEnum,
    TypeEngagementEnum, UpdateDemandeClient
} from '../../../../shared/models/database/demande-client.model';
import { DemandesClientBatchUpdatesFailedUpdate, DemandesClientBatchUpdatesResults } from '../../../../shared/models/demande-client-batch-updates-results';
import { DemandesClientBatchUpdates, DemandesClientBatchUpdatesClearResults } from '../../../store/actions';
import { getDemandesClientBatchUpdatesProcessing, getDemandesClientBatchUpdatesResults } from '../../../store/selectors';
import { getNoteDeVisiteOptions } from '../../../../core/store/selectors';
import { UtilisateurInterne } from '../../../../pilotages/models/utilisateur-interne.model';
import { getUtilisateursInternesLoading, getAllUtilisateursInternes } from '../../../../pilotages/store/selectors';
import { DemandesClientHelper } from '../../../../shared/helpers/demandes-client.helper';

@Component({
    selector: 'app-demandes-client-batch-updates',
    templateUrl: './demandes-client-batch-updates.component.html',
    styleUrls: ['./demandes-client-batch-updates.component.scss']
})

export class DemandesClientBatchUpdatesComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    public results: DemandesClientBatchUpdatesResults | null;
    public selectedStatutOGIV: StatutOgivEnum | null = null;
    public selectedAssigneOGIV: AssigneOGIVEnum | null = null;
    public selectedNotes: string | null = null;
    public selectedContactTechnicien: string | null = null;
    public assigneOGIVSelectOptions: SelectItem[];
    public statutOGIVSelectOptions: SelectItem[];
    public showResults = false;
    public translatePrefix = 'demandes-client.batchUpdates.';
    public commonTranslatePrefix = 'demandes-client.common.';
    public engagementIsValid: boolean = false;
    public isNewEngagementClicked: boolean = false;
    public engagementDemandeClient: EngagementDemandeClient | null;
    public currentUser: UtilisateurInterne | undefined;
    private userInfo: OpenIdUserInfo | undefined;

    public engagementIsUpdatable(): boolean {
        const notUpdatables = this.demandeClientSelectionnees.filter(x => x.statutOGIV !== StatutOgivEnum.Filtrage || x.ordre.typeEngagement !== TypeEngagementEnum.A1);
        return notUpdatables.length === 0;
    }

    public noteSelectOptions$: Observable<SelectItem[] | null> = this.store.pipe(
        select(getNoteDeVisiteOptions),
        distinctUntilChanged()
    );

    public getDemandesClientBatchUpdatesResults$: Observable<DemandesClientBatchUpdatesResults | null> = this.store.pipe(
        select(getDemandesClientBatchUpdatesResults),
    );

    public getDemandesClientBatchUpdatesProcessing$: Observable<boolean> = this.store.pipe(
        select(getDemandesClientBatchUpdatesProcessing),
    );

    constructor(
        private openIdService: OpenIdService,
        public dialogRef: MatDialogRef<DemandesClientBatchUpdatesComponent>,
        private readonly store: Store,
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public demandeClientSelectionnees: DemandeClient[],
    ) {
        this.openIdService.getUserInfo()
            .then((user) => {
                this.userInfo = user;
            }).catch(err => { throw err; });
    }

    ngOnInit(): void {

        this.store.dispatch(new DemandesClientBatchUpdatesClearResults());
        this.populateAllSelectOptions();

        this.subscriptions.push(
            this.updateCurrentUserInfos(),
            this.updateResults(),
            this.updateNotes(),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    public submit() {
        let nouvelEngagement: NouvelEngagementInfo | null = null;
        const remarqueInfos: RemarqueInfo[] = [];
        if (this.engagementDemandeClient?.moyen || this.engagementDemandeClient?.prisPar || this.engagementDemandeClient?.justificationInfos) {
            nouvelEngagement = {
                moyen: this.engagementDemandeClient.moyen,
                prisPar: this.engagementDemandeClient.prisPar,
                justificationInfos: this.engagementDemandeClient.justificationInfos
            };

            if (this.engagementDemandeClient.justificationInfos !== null) {
                for (const justificationInfo of this.engagementDemandeClient.justificationInfos) {
                    const remarqueInfo: RemarqueInfo = {
                        numerosOrdre: justificationInfo.numerosOrdre,
                        remarque: DemandesClientHelper.formatRemarqueForSAP(DemandesClientHelper.initNouvelleRemarque(this.currentUser) + justificationInfo.justification)
                    };

                    remarqueInfos.push(remarqueInfo);
                }
            }
        }

        const updateDemandeClient: UpdateDemandeClient = {
            globalIds: this.demandeClientSelectionnees.map((demande: DemandeClient) => demande.globalId),
            numerosOrdre: this.demandeClientSelectionnees.map((demande: DemandeClient) => demande.numeroOrdre),
            dateEngagement: this.engagementDemandeClient?.dateEngagement ? new Date(this.engagementDemandeClient.dateEngagement) : null,
            typeEngagement: this.engagementDemandeClient?.typeEngagement ? this.engagementDemandeClient.typeEngagement : null,
            nouvelEngagement: nouvelEngagement,
            statutOGIV: this.selectedStatutOGIV ? this.selectedStatutOGIV : null,
            assigneOGIV: this.selectedAssigneOGIV ? this.selectedAssigneOGIV : null,
            notes: this.selectedNotes ? this.selectedNotes : null,
            contactTechnicien: this.selectedContactTechnicien ? this.selectedContactTechnicien : null,
            remarqueInfos: remarqueInfos
        };

        this.store.dispatch(new DemandesClientBatchUpdates(updateDemandeClient));

    }

    public isUtilisateursInternesLoading$: Observable<boolean> = this.store.pipe(
        select(getUtilisateursInternesLoading),
    );

    public utilisateursInternes$: Observable<UtilisateurInterne[] | null> = this.store.pipe(
        select(getAllUtilisateursInternes),
        distinctUntilChanged()
    );

    private updateCurrentUserInfos(): Subscription {
        return this.utilisateursInternes$.pipe(
            tap((utilisateursInternes: UtilisateurInterne[] | null) => {
                if (utilisateursInternes && utilisateursInternes.length > 0) {
                    if (this.userInfo && this.userInfo.email) {
                        // eslint-disable-next-line
                        this.currentUser =
                            utilisateursInternes.find((utilisateur: UtilisateurInterne) =>
                                utilisateur.courriel?.toLowerCase() === this.userInfo?.email?.toLowerCase() ||
                                utilisateur.upn?.toLowerCase() === this.userInfo?.email?.toLowerCase());
                    }
                }
            })
        ).subscribe();
    }

    public formatErrors(failedUpdate: DemandesClientBatchUpdatesFailedUpdate): string {
        let finalError = '';

        if (failedUpdate.numeroOrdre.toLocaleLowerCase() !== 'none') {
            finalError += failedUpdate.numeroOrdre;
        }

        if (failedUpdate.relatedField) {
            finalError += ` (${failedUpdate.relatedField})`;
        }

        return finalError += `${(finalError.length === 0 ? '' : ' : ')}${failedUpdate.message}`;
    }

    private populateAllSelectOptions() {
        this.assigneOGIVSelectOptions = this.createEnumSelectOptions(AssigneOGIVEnum, '');
        this.statutOGIVSelectOptions = this.createEnumSelectOptions(StatutOgivEnum, '',
            [
                StatutOgivEnum.MesureParticuliereTravaux.toString(),
                StatutOgivEnum.PrescriptionsMultiples.toString()
            ]);
    }

    private updateResults(): Subscription {
        return this.getDemandesClientBatchUpdatesResults$.pipe(
            tap((results: DemandesClientBatchUpdatesResults | null) => {
                this.results = results;
                if (this.results && this.results.failedCount > 0) {
                    this.showResults = true;
                } else if (this.results && this.results.failedCount === 0) {
                    this.onExit();
                }
            })
        ).subscribe();
    }

    private updateNotes(): Subscription {
        return this.noteSelectOptions$.pipe(
            tap((notes: SelectItem[] | null) => {
                if (notes && notes.findIndex((note) => note.value === null) === -1) {
                    notes.unshift({
                        label: this.translateService.instant(this.translatePrefix + 'noModification'),
                        value: null,
                    });
                }
            })
        ).subscribe();
    }

    private createEnumSelectOptions(enumeration: any, translatePrefixe: Readonly<string>, excludedValue: string[] = []): SelectItem[] {

        const selectItems: SelectItem[] = [];
        selectItems.push({
            label: this.translateService.instant(this.translatePrefix + 'noModification'),
            value: null,
        });

        Object.values(enumeration).forEach((value: any) => {
            if (excludedValue.includes(value) === false) {
                selectItems.push({
                    label: translatePrefixe.length > 0 ? this.translateService.instant(translatePrefixe + value) : value,
                    value: value,
                });
            }
        });

        return selectItems;
    }

    public onExit(): void {
        this.dialogRef.close(this.results);
    }

    public onEngagementChange(engagement: EngagementDemandeClient): void {
        this.engagementDemandeClient = engagement;
    }

    public onEngagementValidityChange(isValid: boolean): void {
        this.engagementIsValid = isValid;
    }

    public onNewEngagementClickChange(isNewEngagementClicked: boolean): void {
        this.isNewEngagementClicked = isNewEngagementClicked;
    }
}
