import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getRouterData } from '../../../../../../core/store/selectors';
import { AlternativePath } from '../../../../../../shared/models/atlernative-path.model';
import { UserRole } from '../../../../../../shared/models/user-roles.model';

@Component({
    selector: 'app-timesheet-reports-list-hq',
    templateUrl: './timesheet-reports-list-hq.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetReportsListHqComponent implements OnInit {
    public pathChoice: AlternativePath;
    public userRole: typeof UserRole = UserRole;

    constructor(
        private readonly store: Store,
    ) { }

    public ngOnInit() {
        this.store.pipe(select(getRouterData)).subscribe((data: any) => {
            this.pathChoice = data && data.pathChoice || AlternativePath.hq;
        });
    }
}
