import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { BillingCompany } from '../../../shared/models/database/billing-company.model';
import { BillingCompanyAction, BillingCompanyActionType } from '../actions/billing-company.action';

export interface BillingCompanyState extends EntityState<BillingCompany> {
    loading: boolean;
    loaded: boolean;
}

export const billingCompanyAdapter: EntityAdapter<BillingCompany> = createEntityAdapter<BillingCompany>({
    selectId: (billingCompany: BillingCompany) => billingCompany.companyId,
});

export const billingCompanyInitialState = billingCompanyAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function billingCompanyReducer(
    state: BillingCompanyState = billingCompanyInitialState,
    action: BillingCompanyAction,
): BillingCompanyState {
    switch (action.type) {
        case BillingCompanyActionType.LOAD_BILLING_COMPANY: {
            return {
                ...state,
                loading: true,
            };
        }
        case BillingCompanyActionType.LOAD_BILLING_COMPANY_SUCCESS: {
            return billingCompanyAdapter.setAll(action.billingCompany, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case BillingCompanyActionType.LOAD_BILLING_COMPANY_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
