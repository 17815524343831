import { UnitCode } from '../../../shared/models/database/unit-code.model';
import { UnitCodeAction, UnitCodeActionType } from '../actions/unit-codes.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface UnitCodesState extends EntityState<UnitCode> {
    loading: boolean;
    loaded: boolean;
}

export const unitCodesAdapter: EntityAdapter<UnitCode> = createEntityAdapter<UnitCode>({
    selectId: (unitCode: UnitCode) => unitCode.id,
});

export const unitCodesInitialState = unitCodesAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function unitCodeReducer(
    state: UnitCodesState = unitCodesInitialState,
    action: UnitCodeAction,
): UnitCodesState {
    switch (action.type) {
        case UnitCodeActionType.CREATE_UNIT_CODE:
        case UnitCodeActionType.UPDATE_ONE_UNIT_CODE:
        case UnitCodeActionType.LOAD_UNIT_CODES: {
            return {
                ...state,
                loading: true,
            };
        }

        case UnitCodeActionType.CREATE_UNIT_CODE_SUCCESS: {
            return unitCodesAdapter.addOne(action.unitCode, {
                ...state,
                loading: false,
            });
        }

        case UnitCodeActionType.UPDATE_ONE_UNIT_CODE_SUCCESS: {
            return unitCodesAdapter.upsertOne(action.unitCode, {
                ...state,
                loading: false,
            });
        }

        case UnitCodeActionType.CREATE_UNIT_CODE_FAIL:
        case UnitCodeActionType.UPDATE_ONE_UNIT_CODE_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case UnitCodeActionType.LOAD_UNIT_CODES_SUCCESS: {
            return unitCodesAdapter.setAll(action.unitCodes, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case UnitCodeActionType.LOAD_UNIT_CODES_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case UnitCodeActionType.LOAD_UNIT_CODES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
