<div class="otherMenu" [class.otherMenu__rightPad]="displayMenuSpacer()">
    <button *ngIf="addOnly && !isButtonsLock" mat-icon-button color="primary"
        [class.otherMenu__colored]="addBackground"
        [class.otherMenu__button]="!addBackground"
        [class.otherMenu__bordered]="addborder"
        [class.hover]="hoverButtonOrMenu"
        [class.otherMenu__red]="isRedButton"
        type="{{singleButtonType}}"
        matTooltip="{{toolTipLabel}}"
        [matTooltipPosition]="toolTipPosition"
        [matTooltipDisabled]="toolTipLabel === null"
        [disabled]="disabledButton"
        (click)="addRecord($event)">
        <i class="fas fa-plus" [ngStyle]="{'font-size': iconFontSize+'px', 'font-weight': iconFontWeight, 'color': iconFontColor !== null ? iconFontColor: 'inherit'}"></i>
    </button>
    <button *ngIf="editOnly && !isButtonsLock" mat-icon-button color="primary"
        [class.otherMenu__colored]="addBackground"
        [class.otherMenu__button]="!addBackground"
        [class.otherMenu__bordered]="addborder"
        [class.hover]="hoverButtonOrMenu"
        [class.otherMenu__red]="isRedButton"
        type="{{singleButtonType}}"
        matTooltip="{{toolTipLabel}}"
        [matTooltipPosition]="toolTipPosition"
        [matTooltipDisabled]="toolTipLabel === null"
        [disabled]="disabledButton"
        (click)="editRecord($event)">
        <i class="fas fa-pen" [ngStyle]="{'font-size': iconFontSize+'px', 'font-weight': iconFontWeight, 'color': iconFontColor !== null? iconFontColor: 'inherit'}"></i>
    </button>
    <button *ngIf="deleteOnly && !isButtonsLock" mat-icon-button color="primary"
        [class.otherMenu__colored]="addBackground"
        [class.otherMenu__button]="!addBackground"
        [class.otherMenu__bordered]="addborder"
        [class.hover]="hoverButtonOrMenu"
        [class.otherMenu__red]="isRedButton"
        type="{{singleButtonType}}"
        matTooltip="{{toolTipLabel}}"
        [matTooltipPosition]="toolTipPosition"
        [matTooltipDisabled]="toolTipLabel === null"
        [disabled]="disabledButton"
        (click)="deleteRecord($event)">
        <i class="far fa-trash-alt" [ngStyle]="{'font-size': iconFontSize+'px', 'font-weight': iconFontWeight, 'color': iconFontColor !== null ? iconFontColor: 'inherit'}"></i>
    </button>
    <button *ngIf="mySingleButtonOnly && !isButtonsLock" mat-icon-button color="primary"
        [class.otherMenu__colored]="addBackground"
        [class.otherMenu__button]="!addBackground"
        [class.otherMenu__bordered]="addborder"
        [class.hover]="hoverButtonOrMenu"
        [class.otherMenu__red]="isRedButton"
        type="{{singleButtonType}}"
        matTooltip="{{toolTipLabel}}"
        [matTooltipPosition]="toolTipPosition"
        [matTooltipDisabled]="toolTipLabel === null"
        [disabled]="disabledButton"
        (click)="mySingleButtonPressed($event)">
        <i class="{{ singleButtonIcon }}" [ngStyle]="{'font-size': iconFontSize+'px', 'font-weight': iconFontWeight, 'color': iconFontColor !== null ? iconFontColor: 'inherit'}" ></i>
    </button>
    <ng-container *ngIf="displayMenu && !isButtonsLock">
        <div *ngIf="displayMenuSpacer()" class="otherMenu__spacer"></div>
        <ng-container *ngIf="myOwnMenuListItems.length === 0">
            <button mat-icon-button aria-label="other menu"
                [class.otherMenu__colored]="addBackgroundMenu"
                [class.otherMenu__button]="!addBackgroundMenu"
                [class.otherMenu__bordered]="addborder"
                [class.hover]="hoverButtonOrMenu"
                [class.space]="addSpaceBtw()"
                [class.otherMenu__red]="isRedButton"
                type="{{singleButtonType}}"
                matTooltip="{{toolTipLabel}}"
                [matTooltipPosition]="toolTipPosition"
                [matTooltipDisabled]="toolTipLabel === null"
                [matMenuTriggerFor]="menu"
                [disabled]="disabledButton"
                (click)="clickedDisplayMenu($event)">
                <i class="fas fa-ellipsis-v" [ngStyle]="{'font-size': iconFontSize+'px', 'font-weight': iconFontWeight, 'color': iconFontColor !== null ? iconFontColor: 'inherit'}"></i>
            </button>
            <mat-menu #menu="matMenu" [xPosition]="xposition" [yPosition]="yposition">
                <button *ngIf="displayItem('add')" mat-menu-item class="panel__item" (click)="addRecord($event)">
                    <i class="fas fa-plus otherLogo"></i>
                    <span>{{ 'timesheet.general.buttons.add' | translate }}</span>
                </button>
                <button *ngIf="displayItem('edit')" mat-menu-item class="panel__item" (click)="editRecord($event)">
                    <i class="fas fa-pen otherLogo"></i>
                    <span>{{ 'timesheet.general.buttons.edit' | translate }}</span>
                </button>
                <button *ngIf="displayItem('delete')" mat-menu-item class="panel__item" (click)="deleteRecord($event)">
                    <i class="far fa-trash-alt deleteLogo"></i>
                    <span>{{ 'timesheet.general.buttons.delete' | translate }}</span>
                </button>
            </mat-menu>
        </ng-container>
        <ng-container *ngIf="myOwnMenuListItems.length > 0">
            <button mat-icon-button aria-label="other menu"
                [class.otherMenu__colored]="addBackgroundMenu"
                [class.otherMenu__button]="!addBackgroundMenu"
                [class.otherMenu__bordered]="addborder"
                [class.hover]="hoverButtonOrMenu"
                [class.space]="addSpaceBtw()"
                [class.otherMenu__red]="isRedButton"
                type="{{singleButtonType}}"
                matTooltip="{{toolTipLabel}}"
                [matTooltipPosition]="toolTipPosition"
                [matTooltipDisabled]="toolTipLabel === null"
                [matMenuTriggerFor]="myMenu"
                [disabled]="disabledButton"
                (click)="clickedDisplayMenu($event)">
                <i class="fas fa-ellipsis-v" [ngStyle]="{'font-size': iconFontSize+'px', 'font-weight': iconFontWeight, 'color': iconFontColor !== null ? iconFontColor: 'inherit'}"></i>
            </button>
            <mat-menu #myMenu="matMenu" [xPosition]="xposition" [yPosition]="yposition">
                <button mat-menu-item *ngFor="let item of myOwnMenuListItems"
                    matTooltip="{{ item.toolTipLabel }}"
                    [matTooltipPosition]="item.toolTipPosition || 'above'"
                    [matTooltipDisabled]="item.toolTipLabel === null"
                    [disabled]="item.disabled"
                    (click)="selectMyOwnMenuItem(item.clickValue)">
                    <div class="button__div" [class.red]="item.isRed">
                        <i class="{{ item.icon }} logo" [ngStyle]="{'font-size': iconFontSize+'px', 'font-weight': iconFontWeight}"></i>
                        <span class="text"> {{ item.label }}</span>
                    </div>
                </button>
            </mat-menu>
        </ng-container>
    </ng-container>
</div>
