import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { PilotagesState, getPilotagesState } from '../reducers';
import { gestionCodesSAPAdapter, GestionCodesSAPState } from '../reducers/gestion-codes-sap.reducer';
import { GestionCodesSAP } from '../../models/gestion-code-sap.model';

const {
    selectAll,
    selectEntities,
    selectIds,
} = gestionCodesSAPAdapter.getSelectors();

export const getGestionCodesSAPState = createSelector(
    getPilotagesState,
    (state: PilotagesState) => state.gestionCodesSAP,
);

export const getGestionCodesSAPLoading = createSelector(
    getGestionCodesSAPState,
    (state: GestionCodesSAPState) => state.loading,
);

export const getGestionCodesSAPLoaded = createSelector(
    getGestionCodesSAPState,
    (state: GestionCodesSAPState) => state.loaded,
);

export const shouldLoadGestionCodesSAP = createSelector(
    getGestionCodesSAPLoading,
    getGestionCodesSAPLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllGestionCodesSAP = createSelector(
    getGestionCodesSAPState,
    selectAll
);

export const getAllFetchedGestionCodesSAP = createSelector(
    getAllGestionCodesSAP,
    (gestionCodesSAP: GestionCodesSAP[]) => gestionCodesSAP.map((gestionCodeSAP: GestionCodesSAP) => gestionCodeSAP),
);

export const getGestionCodesSAPEntities = createSelector(
    getGestionCodesSAPState,
    selectEntities
);

export const getGestionCodesSAPIds = createSelector(
    getGestionCodesSAPState,
    selectIds,
);

export const getAllGestionCodesSAPFormated = createSelector(
    getAllGestionCodesSAP,
    (gestionCodesSAP: GestionCodesSAP[]) => {
        const formatedGestionCodesSAP: GestionCodesSAP[] = [];
        gestionCodesSAP.forEach((codeSAP: GestionCodesSAP) => {
            formatedGestionCodesSAP.push({
                ...codeSAP,
                ratio: codeSAP.ratio === 0 ? null : codeSAP.ratio
            });
        });
        return formatedGestionCodesSAP;
    }
);

export const getGestionCodesSAPById = (prop: any) => createSelector(
    getGestionCodesSAPEntities,
    (entities: Dictionary<GestionCodesSAP>) => {
        return entities && entities[prop.entityId] || null;
    }
);
