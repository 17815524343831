import { Company } from './company.model';
import moment from 'moment';
import { OrderGroup } from './order-group.model';
import { Error } from './error.model';

export class TimesheetRawData {
    public id: number | undefined = undefined;
    public companyId: number | undefined = undefined;
    public number: string | undefined = undefined;
    public date: Date | undefined = undefined;
    // public status: string | undefined = undefined;
    public createdBy: string | undefined = undefined;
    public createdDate: Date | undefined = undefined;
    public modifiedBy: string | undefined = undefined;
    public modifiedDate: Date | undefined = undefined;
    public isSubmit: boolean | undefined = undefined;

    public company?: Company | undefined = undefined;
    public orderGroups?: OrderGroup[] | undefined = undefined;
}

export class Timesheet {
    public id: number;
    public companyId: number;
    public number: string;
    public date: Date;
    // public status: string;
    public createdBy: string;
    public createdDate: Date;
    public modifiedBy: string;
    public modifiedDate: Date;
    public isSubmit: boolean;

    public company?: Company;
    public orderGroups?: OrderGroup[];

    constructor(data: TimesheetRawData) {
        Object.keys(data).forEach((key: string) => {
            if (key in new TimesheetRawData) {
                switch (key) {
                    case 'date':
                    case 'createdDate':
                    case 'modifiedDate':
                        const date = data[key];

                        if (date) {
                            this[key] = new Date(date);
                        }
                        break;
                    default:
                        this[key] = data[key];
                }
            }
        });
    }

    public getDateId(): string {
        return moment(this.date).format('YYYYMMDD');
    }

    public validateForSubmit(): Error | null {
        let errors: Error | null = null;
        const errorOrderGroups: Error[] = [];

        if (this.orderGroups === null || this.orderGroups === undefined || this.orderGroups.length === 0) {
            errors = {
                name: 'Feuille de temps',
                list: ['il est impossible de soumettre une feuille de temps qui ne contient aucune commande'],
                last: true,
            };
            return errors;
        } else {
            this.orderGroups.forEach((orderGroup: OrderGroup) => {
                const err = new OrderGroup(orderGroup).validate(true);
                if (err !== null) {
                    errorOrderGroups.push(err);
                }
            });
        }

        if (errorOrderGroups.length > 0) {
            errors = { name: `Feuille de temps: ${this.getDateId()}`, list: errorOrderGroups, last: false };
        }

        return errors;
    }
}
