import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ApiService } from '../../../ogiv-core/services';
import { AddOneAppNotification } from '../../../core/store/actions';
import { DemandeClient, DemandeClientRawData } from '../../../shared/models/database/demande-client.model';
import {
    DemandesClientEcartsLoad,
    DemandesClientEcartsActionTypes,
    DemandesClientEcartsLoadSuccess,
    DemandesClientEcartsLoadFail
} from '../actions/demandes-client-ecarts.actions';
import { ErrorNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class DemandesClientEcartsEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public demandesClientEcarts$ = createEffect(() => this.actions$.pipe(
        ofType<DemandesClientEcartsLoad>(DemandesClientEcartsActionTypes.DEMANDES_CLIENT_ECARTS_LOAD),
        switchMap(() => {
            return this.apiService.post<any, any>('hq/demandes/client/delta', {}).pipe(
                map((demandesClient: DemandeClientRawData[]) => {
                    return new DemandesClientEcartsLoadSuccess(demandesClient.map((demande) => new DemandeClient(demande)));
                }),
                catchError((error: any) => of(new DemandesClientEcartsLoadFail(error))),
            );
        }),
    ));

    public demandesClientEcartsFail$ = createEffect(() => this.actions$.pipe(
        ofType<DemandesClientEcartsLoadFail>(DemandesClientEcartsActionTypes.DEMANDES_CLIENT_ECARTS_LOAD_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'demandes-client.notifications.load-ecarts.error',
            }))
        ]),
    ));
}
