import { createSelector } from '@ngrx/store';
import { SelectItem } from 'primeng/api';

import { getPilotagesState, PilotagesState } from '../reducers';
import { UtilisateurInterne } from '../../models/utilisateur-interne.model';
import { utilisateursInternesAdapter, UtilisateursInternesState } from '../reducers/utilisateurs-internes.reducer';

const {
    selectAll
} = utilisateursInternesAdapter.getSelectors();

export const getUtilisateursInternesState = createSelector(
    getPilotagesState,
    (state: PilotagesState) => state.utilisateursInternes,
);

export const getUtilisateursInternesLoading = createSelector(
    getUtilisateursInternesState,
    (state: UtilisateursInternesState) => state.loading,
);

export const getUtilisateursInternesLoaded = createSelector(
    getUtilisateursInternesState,
    (state: UtilisateursInternesState) => state.loaded,
);

export const shouldLoadUtilisateursInternes = createSelector(
    getUtilisateursInternesLoading,
    getUtilisateursInternesLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllUtilisateursInternes = createSelector(
    getUtilisateursInternesState,
    selectAll
);

export const getUtilisateursInternesCipsOptions = createSelector(
    getAllUtilisateursInternes,
    (utilisateursInternes: UtilisateurInterne[]): SelectItem[] => {
        return utilisateursInternes.map((utilisateurInterne: UtilisateurInterne) => {
            return {
                label: `${utilisateurInterne.cip} - ${utilisateurInterne.prenom} ${utilisateurInterne.nom}`,
                value: utilisateurInterne.cip
            } as SelectItem;
        });
    }
);
