import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { ApiService } from '../../../ogiv-core/services/api.service';
import {
    LoadArborists,
    ArboristActionType,
    LoadArboristsSuccess,
    LoadArboristsFail,
    LoadArboristsNoResult
} from '../actions/arborists.action';
import { Arborist } from '../../../shared/models/database/arborist.model';

@Injectable()
export class ArboristsEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public loadArborists$ = createEffect(() => this.actions$.pipe(
        ofType<LoadArborists>(ArboristActionType.LOAD_ARBORISTS),
        switchMap((action: LoadArborists) => {
            return this.apiService.get<Arborist[]>(`${action.path}/fdt/data/employees`).pipe(
                map((returnedEmployee: Arborist[]) =>
                    (returnedEmployee.length > 0)
                        ? new LoadArboristsSuccess(returnedEmployee)
                        : new LoadArboristsNoResult()
                ),
                catchError((error: any) => of(new LoadArboristsFail(error))),
            );
        }),
    ));
}
