import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { UserRole } from '../../../shared/models/user-roles.model';
import {
    TimesheetReportComponent,
    TimesheetReportsComponent,
    TimesheetReportsListEntComponent,
    TimesheetReportsListHqComponent,
} from './components';
import { LoadTimesheetReportGuard } from './guards';
import { RoleGuard } from '../../../core/guards/role.guard';
import { LoadPageGuard } from '../../../guards/page-selector.guard';
import { PageSelector } from '../../../shared/models/page-selector.model';

/**
 * Voir dans les data PageSelector est requis si on met le "LoadPageGuard"
*/
const routes: Routes = [
    {
        path: 'ent',
        data: {
            pathChoice: AlternativePath.ent,
            pageSelector: [PageSelector.reportEnt],
            roles: [UserRole.support, UserRole.pilote, UserRole.chefgroupe, UserRole.entrepreneur],
        },
        component: TimesheetReportsComponent,
        canActivate: [RoleGuard, LoadPageGuard],
        children: [
            {
                path: '',
                component: TimesheetReportsListEntComponent,
            },
            {
                path: ':timesheetReportId',
                component: TimesheetReportComponent,
                data: {
                    pathChoice: AlternativePath.ent,
                    roles: [UserRole.support, UserRole.pilote, UserRole.chefgroupe, UserRole.entrepreneur],
                },
                canActivate: [RoleGuard, LoadTimesheetReportGuard],
            },
        ],
    },
    {
        path: 'hq',
        data: {
            pathChoice: AlternativePath.hq,
            pageSelector: [PageSelector.reportHQ],
            roles: [UserRole.pilote, UserRole.techinter, UserRole.tech, UserRole.planificateur, UserRole.cgc],
        },
        component: TimesheetReportsComponent,
        canActivate: [RoleGuard, LoadPageGuard],
        children: [
            {
                path: '',
                component: TimesheetReportsListHqComponent,
            },
            {
                path: ':timesheetReportId/:siteName',
                component: TimesheetReportComponent,
                data: {
                    pathChoice: AlternativePath.hq,
                    roles: [UserRole.pilote, UserRole.techinter, UserRole.tech, UserRole.planificateur, UserRole.cgc],
                },
                canActivate: [RoleGuard, LoadTimesheetReportGuard],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TimesheetReportsRoutingModule {
}
