import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../core/guards/role.guard';
import { LoadPageGuard } from '../guards';
import { AlternativePath } from '../shared/models/atlernative-path.model';
import { UserRole } from '../shared/models/user-roles.model';
import { PilotagesComponent } from './components/pilotages.component';
import { PilotagesBasicComponent } from './modules/components/pilotages-basic/pilotages-basic.component';

const routes: Routes = [
    {
        path: 'hq',
        data: {
            pathChoice: AlternativePath.hq,
            roles: [UserRole.pilote],
        },
        component: PilotagesComponent,
        canActivate: [RoleGuard, LoadPageGuard],
        children: [
            {
                path: '',
                component: PilotagesBasicComponent,
            }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PilotagesRoutingModule { }
