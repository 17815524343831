import { createSelector } from '@ngrx/store';
import { getReportsState, ReportsState } from '../reducers';
import { progressReportsSpecificAdapter, ProgressReportsSpecificState } from '../reducers/progress-reports-specific.reducer';

const {
    selectAll,
} = progressReportsSpecificAdapter.getSelectors();

export const getProgressReportsSpecificState = createSelector(
    getReportsState,
    (state: ReportsState) => state.progressReportsSpecific,
);

export const getProgressReportsSpecificLoading = createSelector(
    getProgressReportsSpecificState,
    (state: ProgressReportsSpecificState) => state.loading,
);

export const getAllProgressReportsSpecific = createSelector(
    getProgressReportsSpecificState,
    selectAll
);

export const getProgressReportsSpecificDateOfData = createSelector(
    getProgressReportsSpecificState,
    (state: ProgressReportsSpecificState) => state.dateOfData,
);
