import { Equipment } from './equipment.model';
import { Worker } from './worker.model';
import { PostLineGroup } from './post-line-group.model';
import { Error } from './error.model';
import { OrderStatus } from './order-status.enum';
import { OrderType } from './order-type.enum';
import { PostLine } from './post-line.model';
import { Unit } from './unit.model';

export enum OrderTypeMappingFromDisplay {
    unitaire = 'unitly',
    horaire = 'hourly',
    panne = 'outage',
}

export class OrderRawData {
    public id: number;
    public timesheetId: number;
    public timesheetReportId?: number | null;
    public number: string;
    public accountingEntry: string;
    public billingType: OrderType;
    public remark?: string;
    public status: OrderStatus | null;
    public isLock: boolean;

    public equipments?: Equipment[] | null;
    public workers?: Worker[] | null;
    public postLineGroups?: PostLineGroup[] | null;
}
export class Order {
    public id: number;
    public timesheetId: number;
    public timesheetReportId?: number | null;
    public number: string;
    public accountingEntry: string;
    public billingType: OrderType;
    public remark?: string;
    public status: OrderStatus | null;
    public isLock = false;

    public equipments?: Equipment[] | null;
    public workers?: Worker[] | null;
    public postLineGroups?: PostLineGroup[] | null;

    constructor(data: OrderRawData) {
        Object.keys(data).forEach((key: string) => {
            this[key] = data[key];
        });
    }

    public validate(validateChildren: boolean = false): Error | null {
        let errors: Error | null = null;
        const errorOrders: Error[] = [];

        if (this.billingType === OrderType.hourly || this.billingType === OrderType.outage) {
            // Type HORAIRE ou PANNE: un équipier ou un équipement est requis
            if (((this.workers === null || this.workers === undefined || this.workers.length === 0) &&
                (this.equipments === undefined || this.equipments === null || this.equipments.length === 0))) {
                errorOrders.push({ name: 'Sections Équipe et Équipement', list: ['doivent contenir au moins un équipier ou un équipement'], last: true });
            }
            // S'il y a une équipe, on doit vérifier que le nombre d'heures des équipiers égale le nombre d'heures des unités
            if (this.workers && this.workers.length > 0 && !this.workerHoursEqualUnitHours()) {
                errorOrders.push({
                    name: 'Sections Équipe et Unité de travail réalisée',
                    list: ['le total des heures de l\'équipe doit être égal au total des heures des unités de travail réalisées'],
                    last: true
                });
            }
        }

        if (validateChildren) {
            if (this.workers !== undefined && this.workers !== null && this.workers.length > 0) {
                const workerError = this.validateWorker(this.billingType);
                if (workerError !== null) {
                    errorOrders.push({ name: 'Section Équipe', list: [workerError], last: false });
                }
            }

            if (this.equipments !== undefined && this.equipments !== null && this.equipments.length > 0) {
                const equipmentError = this.validateEquipments(this.billingType);
                if (equipmentError !== null) {
                    errorOrders.push({ name: 'Section Équipement', list: [equipmentError], last: false });
                }
            }

            const postLineError = this.validatePostLineGroup(validateChildren);
            if (postLineError !== null && postLineError.length > 0) {
                errorOrders.push({ name: 'Section Unité de travail réalisée', list: postLineError, last: false });
            }
        }

        if (errorOrders.length > 0) {
            errors = { name: this.capitalizeFirstLetter(this.billingType), list: errorOrders, last: false };
        }

        return errors;
    }

    private capitalizeFirstLetter(word: string): string {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    private validateWorker(orderType: OrderType): Error | null {
        let errors: Error | null = null;
        const errorWorkers: Error[] = [];

        this.workers?.forEach((worker: Worker) => {
            const err = new Worker(worker).validate(orderType);
            if (err !== null) {
                errorWorkers.push(err);
            }
        });

        if (errorWorkers.length > 0) {
            errors = { name: this.number, list: errorWorkers, last: false };
        }

        return errors;
    }

    private validateEquipments(orderType: OrderType): Error | null {
        let errors: Error | null = null;
        const errorEquipments: Error[] = [];

        this.equipments?.forEach((equipment: Equipment) => {
            const err = new Equipment(equipment).validate(orderType);
            if (err !== null) {
                errorEquipments.push(err);
            }
        });

        if (errorEquipments.length > 0) {
            errors = { name: this.number, list: errorEquipments, last: false };
        }

        return errors;
    }

    private validatePostLineGroup(validateChildren: boolean = false): Error[] | null {
        const errorPostLineGroups: Error[] = [];
        let aucuneUnite = true;

        this.postLineGroups?.forEach((postlineGroup: PostLineGroup) => {
            if (postlineGroup.postLines && postlineGroup.postLines.length > 0) {
                postlineGroup.postLines.forEach((postLine: PostLine) => {
                    if (postLine && postLine.units && postLine.units.length > 0) {
                        aucuneUnite = false;
                    }
                });
            }
        });

        // Les unités sont requises pour tous les types
        if (aucuneUnite) {
            errorPostLineGroups.push({ name: '', list: ['doit contenir au moins une "Unité de travail"'], last: true });
        } else if (validateChildren) {
            this.postLineGroups?.forEach((postLineGroup: PostLineGroup) => {
                const err = new PostLineGroup(postLineGroup).validate(validateChildren, this.billingType);
                if (err !== null) {
                    errorPostLineGroups.push(err);
                }
            });
        }

        return errorPostLineGroups;
    }

    private workerHoursEqualUnitHours() {
        let numberOfUnitHours = 0;
        this.postLineGroups?.forEach((postlineGroup: PostLineGroup) => {
            if (postlineGroup.postLines && postlineGroup.postLines.length > 0) {
                postlineGroup.postLines.forEach((postLine: PostLine) => {
                    if (postLine && postLine.units && postLine.units.length > 0) {
                        postLine.units.forEach((unit: Unit) => {
                            numberOfUnitHours += unit.hour;
                        });
                    }
                });
            }
        });
        let numberOfWorkerHours = 0;
        this.workers?.forEach((worker: Worker) => {
            if (worker.hour) {
                numberOfWorkerHours += worker.hour;
            }
        });

        return numberOfUnitHours === numberOfWorkerHours;
    }
}
