import moment from 'moment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Workbook, Worksheet } from 'exceljs';

import { AlternativePath } from '../../../../shared/models/atlernative-path.model';
import { Back } from '../../../../core/store/actions';
import { BillingReportExecutionAction, ExecuteActionToBillingReport } from '../../../store/actions';
import { BillingReportPreviewPageHeader, BillingReportPreviewRawData, BillingReportPreviewRow } from '../../../../shared/models/database/billing-report-preview.model';
import { BillingState } from '../../../store/reducers';
import { BillingReport } from '../../../../shared/models/database/billing-report.model';
import { ExcelHelpers } from '../../../../shared/helpers/excel.helper';
import { getCurrentBillingReport } from '../../../store/selectors';
import { getBillingReportPreview, getBillingReportPreviewLoading } from '../../../store/selectors/billing-report-preview.selector';
import { LoadBillingReportPreview } from '../../../store/actions/billing-report-preview.action';

@Component({
    selector: 'app-preview-billing-report',
    templateUrl: './preview-billing-report.component.html',
    styleUrls: ['./preview-billing-report.component.scss']
})
export class PreviewBillingReportComponent implements OnInit, OnDestroy {
    public pathChoice: string;
    public billingReportId: number;

    rowGroupMetadata: any;
    billingReportPreviewPageHeaders: BillingReportPreviewPageHeader[];
    public billingReportPreviewRows: BillingReportPreviewRow[];
    public workbook: Workbook;
    public formatedWorkSheetName = this.translate.instant('billing.common.result.workSheetName');
    public rawWorkSheetName = this.translate.instant('billing.common.result.rawDataWorkSheetName');

    public isBillingReportPreviewLoading$: Observable<boolean> = this.store.pipe(
        select(getBillingReportPreviewLoading)
    );

    public billingReportPreview$: Observable<BillingReportPreviewRow[]> = this.store.pipe(
        select(getBillingReportPreview)
    );

    constructor(
        private currentRoute: ActivatedRoute,
        private readonly store: Store<[BillingState]>,
        public translate: TranslateService,

    ) { }

    private subscriptions: Subscription[] = [];
    public currentBillingReport: BillingReport;

    public billingReport$: Observable<BillingReport | null> = this.store.pipe(
        select(getCurrentBillingReport),
    );

    ngOnInit(): void {
        this.pathChoice = this.currentRoute.snapshot.data['pathChoice'];
        this.billingReportId = this.currentRoute.snapshot.params['billingReportId'];
        this.billingReportPreviewPageHeaders = [];

        this.loadBillingReportPreview();

        this.subscriptions.push(
            this.updateBillingReportPreview(),
            this.setCurrentBillingReport()
        );
    }

    private updateBillingReportPreview(): Subscription {
        return this.billingReportPreview$.pipe(
            map(
                (billingReportPreviewData: BillingReportPreviewRawData[]) => {
                    if (billingReportPreviewData && billingReportPreviewData.length > 0) {
                        // eslint-disable-next-line
                        this.billingReportPreviewRows = [];
                        billingReportPreviewData.forEach(
                            (data: BillingReportPreviewRawData) => this.billingReportPreviewRows.push(new BillingReportPreviewRow(data))
                        );
                        this.sortBillingReportData();
                        this.setPageHeaderValues();
                        this.updateRowGroupMetaData();
                    }
                }
            )).subscribe();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private setCurrentBillingReport(): Subscription {
        return this.billingReport$.pipe(
            tap((billingReport: BillingReport | null) => {
                if (billingReport) {
                    this.currentBillingReport = billingReport;
                }
            })
        ).subscribe();
    }

    public backToReport(): void {
        this.store.dispatch(new Back());
    }

    private sortBillingReportData(): void {
        this.billingReportPreviewRows.sort((previous, next) =>
            (previous.rfdtWeek ?? '').localeCompare(next.rfdtWeek ?? '') ||
            (previous.rfdtNumber ?? '').localeCompare(next.rfdtNumber ?? '') ||
            (previous.rfdtType ?? '').localeCompare(next.rfdtType ?? '') ||
            (previous.itemDescription ?? '').localeCompare(next.itemDescription ?? '') ||
            (previous.itemCode ?? '').localeCompare(next.itemCode ?? '')
        );
    }

    private setPageHeaderValues(): void {
        const referenceData: BillingReportPreviewRow = this.billingReportPreviewRows[0];
        this.billingReportPreviewPageHeaders = [{
            rowTitle: this.translate.instant('billing.common.summary.company'),
            rowValue: referenceData?.company
        },
        {
            rowTitle: this.translate.instant('billing.common.reportForm.invoiceNumber'),
            rowValue: referenceData?.invoiceNumber
        },
        {
            rowTitle: this.translate.instant('billing.common.result.orderAccountingEntry'),
            rowValue: `${referenceData?.invoiceOrder} / ${referenceData?.invoiceAccountingEntry}`,
        },
        {
            rowTitle: this.translate.instant('billing.common.summary.invoiceDate'),
            rowValue: moment(referenceData?.invoiceDate).format('yyyy-MM-DD')
        }];
    }

    /**
     * This metadata in parallel/background of the p-table is used to determine which rows will be spanned and for how many rows.
     *
     */
    private updateRowGroupMetaData(): void {
        this.rowGroupMetadata = {};
        let currentRowGroupRfdtWeekType = '';
        let currentRowGroupByItem = '';
        if (this.billingReportPreviewRows?.length > 0) {
            for (let i = 0; i < this.billingReportPreviewRows.length; i++) {
                const rowData = this.billingReportPreviewRows[i];
                currentRowGroupRfdtWeekType = rowData.groupByRfdtWeekTypeKey;
                currentRowGroupByItem = rowData.groupByRfdtWeekTypeItemKey;

                if (i === 0) {
                    this.rowGroupMetadata[currentRowGroupRfdtWeekType] = { index: 0, size: 1 };
                    this.rowGroupMetadata[currentRowGroupByItem] = { index: 0, size: 1 };
                } else {
                    const previousRowData = this.billingReportPreviewRows[i - 1];

                    const previousRowGroupRfdtWeekType = previousRowData.groupByRfdtWeekTypeKey;
                    if (currentRowGroupRfdtWeekType === previousRowGroupRfdtWeekType) {
                        this.rowGroupMetadata[currentRowGroupRfdtWeekType].size++;
                    } else {
                        this.rowGroupMetadata[currentRowGroupRfdtWeekType] = { index: i, size: 1 };
                    }

                    const previousRowGroupByItem = previousRowData.groupByRfdtWeekTypeItemKey;
                    if (currentRowGroupByItem === previousRowGroupByItem) {
                        this.rowGroupMetadata[currentRowGroupByItem].size++;
                    } else {
                        this.rowGroupMetadata[currentRowGroupByItem] = { index: i, size: 1 };
                    }
                }
            }
        }
    }

    public onSort(): void {
        this.updateRowGroupMetaData();
    }

    public exportExcel(): void {
        this.workbook = new Workbook();
        this.renderBillingReportFormatedWorksheet(this.workbook);
        this.renderBillingReportRawWorksheet(this.workbook);
        this.downloadWorkbook(this.workbook);

        if (this.pathChoice === AlternativePath.ent) {
            this.store.dispatch(
                new ExecuteActionToBillingReport(this.currentBillingReport.id, '', BillingReportExecutionAction.SET_EXPORTED, AlternativePath.ent, false)
            );
        }
    }

    private renderBillingReportFormatedWorksheet(workbook: Workbook): void {
        const COLUMNS_WIDTH = [40, 25, 25, 30, 10, 15, 15];
        const worksheet = ExcelHelpers.addNewWorksheet(workbook, this.formatedWorkSheetName, COLUMNS_WIDTH);

        this.renderBillingReportInfo(worksheet);
        this.renderBillingReportResultHeader(worksheet);
        this.renderBillingReportResultDetails(worksheet);
    }

    private renderBillingReportRawWorksheet(workbook: Workbook): void {

        const REPORT_HEADER_RANGE = 'A1:K1';
        const COLUMNS_WIDTH = [30, 25, 30, 15, 20, 15, 25, 30, 10, 15, 15];
        const worksheet = ExcelHelpers.addNewWorksheet(workbook, this.rawWorkSheetName, COLUMNS_WIDTH);

        worksheet.autoFilter = REPORT_HEADER_RANGE;
        this.renderBillingReportResultRawDataHeader(worksheet);
        this.renderBillingReportResultRawDataDetail(worksheet);
    }

    private renderBillingReportInfo(worksheet: Worksheet): void {
        const REPORT_INFO_RANGE = 'A1:B4';
        const TITLE_CELL = 'D1';
        const TITLE_RANGE = 'D1:G2';

        worksheet.addRow([this.translate.instant('billing.common.summary.company'), this.currentBillingReport.company]);
        worksheet.addRow([this.translate.instant('billing.common.reportForm.invoiceNumber'), this.currentBillingReport.invoiceNumber]);
        worksheet.addRow([
            this.translate.instant('billing.common.result.orderAccountingEntry'), `${this.currentBillingReport.order} / ${this.currentBillingReport.accountingEntry}`
        ]);
        worksheet.addRow([this.translate.instant('billing.common.summary.invoiceDate'), moment(this.currentBillingReport.createdDate).startOf('day').toDate()]);
        worksheet.getCell('B4').numFmt = 'yyyy-MM-dd';
        worksheet.getCell('B4').alignment = { horizontal: 'left' };

        ExcelHelpers.setCellsBorder(worksheet, REPORT_INFO_RANGE);

        const titleCell = worksheet.getCell(TITLE_CELL);
        titleCell.value = this.translate.instant('billing.common.result.billingReportTitle');
        titleCell.font = { name: 'Calibri', family: 4, size: 20, bold: true };

        worksheet.mergeCells(TITLE_RANGE);
        worksheet.addRow([]);
        worksheet.addRow([]);
    }

    private renderBillingReportResultHeader(worksheet: Worksheet): void {
        const HEADER_RANGE = 'A7:G7';

        worksheet.addRow([
            this.translate.instant('billing.common.result.rfdtNumber'),
            this.translate.instant('billing.common.result.rfdtWeek'),
            this.translate.instant('billing.common.result.rfdtType'),
            this.translate.instant('billing.common.result.itemDescription'),
            this.translate.instant('billing.common.result.itemCode'),
            this.translate.instant('billing.common.result.itemHours'),
            this.translate.instant('billing.common.result.itemQuantites'),
        ]);

        ExcelHelpers.setCellsBold(worksheet, HEADER_RANGE);
        ExcelHelpers.setCellsAlignment(worksheet, HEADER_RANGE, { horizontal: 'center' });
        ExcelHelpers.setCellsBackGroudColor(worksheet, HEADER_RANGE, 'cccccc');
        ExcelHelpers.setCellsBorder(worksheet, HEADER_RANGE);
    }

    private renderBillingReportResultDetails(worksheet: Worksheet): void {
        const FIRST_DETAIL_ROW = 8;
        const LAST_ROW = this.billingReportPreviewRows.length - 1 + FIRST_DETAIL_ROW;
        const DETAIL_RANGE = 'A8:G' + LAST_ROW.toString();
        const NUMBERS_RANGE = 'F8:G' + LAST_ROW.toString();
        const FIRST_GROUPING_COLUMN = 'A';
        const LAST_GROUPING_COLUMN = 'D';

        this.billingReportPreviewRows.forEach(reportPreviewLine => {
            worksheet.addRow([reportPreviewLine.rfdtNumber,
            reportPreviewLine.rfdtWeek,
            reportPreviewLine.rfdtType,
            reportPreviewLine.itemDescription,
            reportPreviewLine.itemCode,
            reportPreviewLine.itemHours,
            reportPreviewLine.itemQuantites,
            ]);
        });

        ExcelHelpers.setCellsBorder(worksheet, DETAIL_RANGE);
        ExcelHelpers.setCellsNumberFormat(worksheet, NUMBERS_RANGE, '0.00');
        ExcelHelpers.setCellsAlignment(worksheet, 'A8:C' + LAST_ROW.toString(), { horizontal: 'center', vertical: 'middle' });
        ExcelHelpers.setCellsAlignment(worksheet, 'D8:D' + LAST_ROW.toString(), { vertical: 'middle' });
        ExcelHelpers.setCellsAlignment(worksheet, 'E8:E' + LAST_ROW.toString(), { horizontal: 'center' });

        ExcelHelpers.groupRows(worksheet, FIRST_DETAIL_ROW, worksheet.rowCount, FIRST_GROUPING_COLUMN, LAST_GROUPING_COLUMN);
    }

    private renderBillingReportResultRawDataHeader(worksheet: Worksheet): void {
        worksheet.addRow([
            this.translate.instant('billing.common.summary.company'),
            this.translate.instant('billing.common.summary.invoiceNumber'),
            this.translate.instant('billing.common.result.orderAccountingEntry'),
            this.translate.instant('billing.common.summary.invoiceDate'),
            this.translate.instant('billing.common.result.rfdtNumber'),
            this.translate.instant('billing.common.result.rfdtWeek'),
            this.translate.instant('billing.common.result.rfdtType'),
            this.translate.instant('billing.common.result.itemDescription'),
            this.translate.instant('billing.common.result.itemCode'),
            this.translate.instant('billing.common.result.itemHours'),
            this.translate.instant('billing.common.result.itemQuantites'),
        ]);
    }

    private renderBillingReportResultRawDataDetail(worksheet: Worksheet): void {
        this.billingReportPreviewRows.forEach(reportPreviewLine => {
            worksheet.addRow([
                reportPreviewLine.company,
                reportPreviewLine.invoiceNumber,
                reportPreviewLine.invoiceOrder + ' / ' + reportPreviewLine.invoiceAccountingEntry,
                moment(reportPreviewLine.invoiceDate).startOf('day').toDate(),
                reportPreviewLine.rfdtNumber,
                reportPreviewLine.rfdtWeek,
                reportPreviewLine.rfdtType,
                reportPreviewLine.itemDescription,
                reportPreviewLine.itemCode,
                reportPreviewLine.itemHours,
                reportPreviewLine.itemQuantites,
            ]);
        });
    }

    private downloadWorkbook(workbook: Workbook): void {
        const filename =
            `${this.translate.instant('billing.common.result.exportFileNamePrefix')}` +
            (this.currentBillingReport.invoiceNumber ? this.currentBillingReport.invoiceNumber.trim() :
                this.currentBillingReport.id.toString().trim()) + '_' + `${new Date().getTime()}`;

        ExcelHelpers.save(workbook, filename);
    }

    private loadBillingReportPreview(): void {
        this.store.dispatch(new LoadBillingReportPreview(this.billingReportId, this.pathChoice));
    }
}
