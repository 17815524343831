import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addLineBreaksSlashes'
})
export class ReplacePipe implements PipeTransform {
    transform(value: string): any {
        // Caractère UTF-8 200B est un espace sans largeur, reconnu par le navigateur pour les sauts de lignes.
        return value.replace(/([-+\.\/])/g, '$1​');
    }
}
