import { Equipment } from './equipment.model';
import { MealCode } from './meal-code.model';
import { TeamMember } from './team-member.model';
import { TimesheetReportSummary, TimesheetReportSummaryRaw } from './timesheet-report-summary.model';
import { TransportCode } from './transport-code.model';
import { WeekReport } from './week-report.model';
import { WorkUnit } from './work-unit.model';

export class TimesheetReportRaw extends TimesheetReportSummaryRaw {
    public remarkHQ?: string = undefined;

    public equipment?: (Equipment & WeekReport)[] = undefined;
    public meal?: (MealCode & WeekReport)[] = undefined;
    public team?: (TeamMember & WeekReport)[] = undefined;
    public transport?: (TransportCode & WeekReport)[] = undefined;
    public workUnit?: WorkUnit[] = undefined;
}

export class TimesheetReport extends TimesheetReportSummary {
    public remarkHQ?: string = undefined;

    public equipment?: (Equipment & WeekReport)[];
    public meal?: (MealCode & WeekReport)[];
    public team?: (TeamMember & WeekReport)[];
    public transport?: (TransportCode & WeekReport)[];
    public workUnit?: WorkUnit[];

    constructor(data: TimesheetReportRaw) {
        super(data);

        Object.keys(data).forEach((key: string) => {
            if (key in new TimesheetReportRaw && !(key in new TimesheetReportSummaryRaw)) {
                switch (key) {
                    default:
                        this[key] = data[key];
                        break;
                }
            }
        });
    }

    public getUniqueId(): number {
        return this.id;
    }
}
