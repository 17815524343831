import { createFeatureSelector } from '@ngrx/store';

import { EntrepreneursState, entrepreneurReducer } from './entrepreneurs.reducer';
import { UtilisateursInternesState, utilisateurInterneReducer } from './utilisateurs-internes.reducer';
import { UtilisateursExternesState, utilisateurExterneReducer } from './utilisateurs-externes.reducer';
import { formationUtilisateurReducer, ProfilFormationUtilisateurState } from './profil-formation-utilisateur.reducer';
import { etatSynchronisationsReducer, EtatSynchronisationsState } from './etat-synchronisations.reducer';
import { GestionCodesSAPState, gestionCodesSAPReducer } from './gestion-codes-sap.reducer';
import { TableCodeUniteState, tableCodeUniteReducer } from './table-code-unite.reducer';
import { utilisateurExterneTeamLeaderReducer, UtilisateursExternesTeamLeadersState } from './utilisateurs-externes-team-leaders.reducer';
import { TimesheetChargeNumberState, timesheetChargeNumberReducer } from './timesheet-charge-number.reducer';
import { TimesheetCommandePosteTypeState, timesheetCommandePosteTypeReducer } from './timesheet-commande-poste-type.reducer';

export interface PilotagesState {
    entrepreneurs: EntrepreneursState;
    utilisateursInternes: UtilisateursInternesState;
    utilisateursExternes: UtilisateursExternesState;
    utilisateursExternesTeamLeader: UtilisateursExternesTeamLeadersState;
    profilFormationUtilisateur: ProfilFormationUtilisateurState;
    utilisateursSynchro: EtatSynchronisationsState;
    gestionCodesSAP: GestionCodesSAPState;
    tableCodeUnite: TableCodeUniteState;
    timesheetChargeNumber: TimesheetChargeNumberState;
    timesheetCommandePosteType: TimesheetCommandePosteTypeState;
}

export const pilotagesStoreReducers: any = {
    entrepreneurs: entrepreneurReducer,
    utilisateursInternes: utilisateurInterneReducer,
    utilisateursExternes: utilisateurExterneReducer,
    utilisateursExternesTeamLeader: utilisateurExterneTeamLeaderReducer,
    profilFormationUtilisateur: formationUtilisateurReducer,
    utilisateursSynchro: etatSynchronisationsReducer,
    gestionCodesSAP: gestionCodesSAPReducer,
    tableCodeUnite: tableCodeUniteReducer,
    timesheetChargeNumber: timesheetChargeNumberReducer,
    timesheetCommandePosteType: timesheetCommandePosteTypeReducer,
};

export const getPilotagesState = createFeatureSelector<PilotagesState>('pilotages');
