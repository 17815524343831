import { Action } from '@ngrx/store';
import { CurrentUser } from '../../../core/services/user.service';
import { Order } from '../../../shared/models/database/order.model';

/**
 * Types
 */

export enum OrderActionTypes {
    LOAD_ORDERS = '[Order] Load Orders',
    LOAD_ORDERS_SUCCESS = '[Order] Load Orders Success',
    LOAD_ORDERS_NO_RESULTS = '[Order] Load Orders No Results',
    LOAD_ORDERS_FAIL = '[Order] Load Orders Fail',

    CREATE_ORDER = '[Order] Create Order',
    CREATE_ORDER_SUCCESS = '[Order] Create Order Success',
    CREATE_ORDER_FAIL = '[Order] Create Order Fail',

    UPDATE_ONE_ORDER = '[Order] Update One Order',
    UPDATE_ONE_ORDER_FAIL = '[Order] Update One Order Fail',
    UPDATE_ONE_ORDER_SUCCESS = '[Order] Update One Order Success',

    DELETE_ONE_ORDER = '[Order] Delete One Order',
    DELETE_ONE_ORDER_FAIL = '[Order] Delete One Order Fail',
    DELETE_ONE_ORDER_SUCCESS = '[Order] Delete One Order Success',
}

/**
 * ACTIONS
 */

/**
 * Loading
 */
export class LoadOrders implements Action {
    readonly type = OrderActionTypes.LOAD_ORDERS;
    constructor(public path: string) { }
}

export class LoadOrdersNoresult implements Action {
    readonly type = OrderActionTypes.LOAD_ORDERS_NO_RESULTS;
}

export class LoadOrdersSuccess implements Action {
    readonly type = OrderActionTypes.LOAD_ORDERS_SUCCESS;
    constructor(public orders: Order[]) { }
}

export class LoadOrdersFail implements Action {
    readonly type = OrderActionTypes.LOAD_ORDERS_FAIL;
    constructor(public error: any) { }
}

/**
 * Create
 */
export class CreateOrder implements Action {
    readonly type = OrderActionTypes.CREATE_ORDER;
    constructor(public order: Order, public timesheetDateId: string, public path: string, public imperson?: CurrentUser) { }
}

export class CreateOrderSuccess implements Action {
    readonly type = OrderActionTypes.CREATE_ORDER_SUCCESS;
    // constructor(public order: Order) { }
}

export class CreateOrderFail implements Action {
    readonly type = OrderActionTypes.CREATE_ORDER_FAIL;
    constructor(public error: any) { }
}

/**
 * Update
 */

export class UpdateOneOrder implements Action {
    readonly type = OrderActionTypes.UPDATE_ONE_ORDER;
    constructor(public order: Order, public timesheetDateId: string, public path: string, public imperson?: CurrentUser) { }
}

export class UpdateOneOrderSuccess implements Action {
    readonly type = OrderActionTypes.UPDATE_ONE_ORDER_SUCCESS;
    // constructor(public order: Order) { }
}

export class UpdateOneOrderFail implements Action {
    readonly type = OrderActionTypes.UPDATE_ONE_ORDER_FAIL;
    constructor(public error: any) { }
}

/**
 * Delete
 */

export class DeleteOneOrder implements Action {
    readonly type = OrderActionTypes.DELETE_ONE_ORDER;
    constructor(public order: Order, public timesheetDateId: string, public path: string, public imperson?: CurrentUser) { }
}

export class DeleteOneOrderSuccess implements Action {
    readonly type = OrderActionTypes.DELETE_ONE_ORDER_SUCCESS;
    // constructor(public order: Order) { }
}

export class DeleteOneOrderFail implements Action {
    readonly type = OrderActionTypes.DELETE_ONE_ORDER_FAIL;
    constructor(public error: any) { }
}

export type OrdersAction =
    | LoadOrders
    | LoadOrdersNoresult
    | LoadOrdersSuccess
    | LoadOrdersFail
    | CreateOrder
    | CreateOrderSuccess
    | CreateOrderFail
    | UpdateOneOrder
    | UpdateOneOrderSuccess
    | UpdateOneOrderFail
    | DeleteOneOrder
    | DeleteOneOrderSuccess
    | DeleteOneOrderFail;
