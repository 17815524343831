import { Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { DoInit, ComponentBase, ComponentFeatures, InheritsBaseLifecycleHooks, SaveCommand } from '@ngxhq/common-ui';
import { OpenIdService, OpenIdUserInfo, AuthentificationState } from '@ngxhq/security';
import { Store, select } from '@ngrx/store';

import { from } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { HqHeaderComponent } from '@ngxhq/theme-ngxhq/';
import { getUserRolesMap } from '../../core/store/selectors';
import { UserService } from '../../core/services/user.service';
import { UIService } from '../../ogiv-core/services/ui.service';

interface UserViewModel {
    fullname: string;
    initials: string;
    profileImage?: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class HeaderComponent extends ComponentBase implements DoInit {
    public imagePath = '../../../assets/images/cocotte_blanche.png';
    public userRoles = '';

    @ViewChild(HqHeaderComponent)
    private header: HqHeaderComponent;

    user: UserViewModel | undefined;
    @Output() expandedChange = new EventEmitter<boolean>();

    @Input()
    set expanded(value: boolean) {
        if (this.header && this.header.expanded !== value) {
            this.header.expanded = value;
        }
        this.expandedChange.emit(value);
        if (value !== undefined && value !== null) {
            this.uiService.setMenuIsExpended(value);
        }
    }

    constructor(
        private openIdService: OpenIdService,
        private readonly store: Store,
        private userService: UserService,
        private uiService: UIService,
    ) {
        super();
    }

    doInit() {
        this.openIdService.status
            .pipe(
                switchMap(x => from(this.mapStatusToViewModel(x))),
                takeUntil(this.destroyed),
            )
            .subscribe(user => {
                this.user = user;
            }, (err: any) => {
                // eslint-disable-next-line no-restricted-syntax
                console.log('HeaderComponent - openIdService status subsription in error.', err);
                if ((typeof err !== 'object') || err.message.indexOf('Missing OpenID configuration') === -1) {
                    throw err;
                }
            });

        this.store.pipe(
            select(getUserRolesMap)
        ).subscribe(userRoles => {
            this.userRoles = '';
            for (let i = 0; i < userRoles.length; i++) {
                this.userRoles += i ? ', ' : '';
                this.userRoles += userRoles[i];
            }
        });
    }

    private async mapStatusToViewModel(x: AuthentificationState): Promise<UserViewModel | undefined> {
        if (x.isAuthenticated) {
            const user = await this.openIdService.getUserInfo();
            if (user) {
                this.userService.connectedUser = {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    idAgol: this.userService.emailToIdAgol(user.email),
                    isImpersonnified: false,
                };
            }
            return this.mapToViewModel(user);
        } else {
            return undefined;
        }
    }

    private mapToViewModel(userInfo: OpenIdUserInfo | undefined): UserViewModel | undefined {
        if (!userInfo) {
            return undefined;
        }
        return {
            fullname: `${userInfo.firstName} ${userInfo.lastName}`.trim(),
            initials: `${userInfo.firstName[0] || ''}${userInfo.lastName[0] || ''}`,
        };
    }

    @SaveCommand()
    async login() {
        await this.openIdService.login('/');
    }

    @SaveCommand()
    async logout() {
        await this.openIdService.logout();
    }
}
