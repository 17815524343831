<div class="timesheet">
    <div class="timesheet__containerDate">
        <form [formGroup]="timesheetForm">
            <div *ngIf="isAllowToImpersonnify" class="containerDate__select">
                <div class="select__title end"> {{ 'timesheet.impersonnify' | translate }} </div>
                <div class="select__dropdown">
                    <mat-form-field appearance="standard" class="field__formField">
                        <mat-select formControlName="impersonnification" [ngModel]="selected">
                            <mat-select-trigger>
                                <div>
                                    <p [ngStyle]="{'margin': '0 0 4px 4px'}" *ngIf="selectedCurrentUser.lastName || selectedCurrentUser.firstName">{{selectedCurrentUser.lastName}}, {{selectedCurrentUser.firstName}}</p>
                                    <p [ngStyle]="{'margin': '0 0 0 0'}">({{selectedCurrentUser.idAgol}})</p>
                                </div>
                            </mat-select-trigger>
                            <mat-option [ngStyle]="{'font-size.px': '14', 'height': 'unset', 'line-height': 'unset'}" *ngFor="let teamLeaderOption of teamLeaderOption$ | async" [value]="teamLeaderOption.value">
                                <div>
                                    <p [ngStyle]="{'margin': '16px 0 4px 4px'}">{{teamLeaderOption.label.nom}}, {{teamLeaderOption.label.prenom}}</p>
                                    <p [ngStyle]="{'margin': '0 0 16px 0'}">({{teamLeaderOption.label.idAgol}})</p>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="containerDate__date">
                <div class="date__title end"> {{ 'timesheet.dateTitle' | translate }} </div>

                <div class="date__calendar" *ngIf="timesheetForm">

                        <p-calendar formControlName="date" dateFormat="dd MM yy" [showIcon]="false" [placeholder]="datePlaceholder"
                            [showButtonBar]="true" [inputStyle]="{'font-size':'16px', 'width': '180px'}" [touchUI]="hasTouchScreen"
                            [readonlyInput]="true" appendTo="body">
                        </p-calendar>

                </div>
            </div>
        </form>
    </div>

    <app-element-loader *ngIf="timesheetLoading$ | async" [displayLabel]="true" [size]="100"></app-element-loader>

    <div class="timesheet__content">
        <router-outlet></router-outlet>
    </div>
</div>
