
<div mat-dialog-title class="dialog__title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <ng-container *ngIf="formTypeIsDelete()">
        <i class="far fa-trash-alt title__logo red"></i>
    </ng-container>

    <ng-container *ngIf="formTypeIsSubmit()">
        <i class="fas fa-sign-in-alt title__logo"></i>
    </ng-container>

    <ng-container *ngIf="entityTypeIsInfoStatus()">
        <i class="fas fa-magic title__logo"></i>
    </ng-container>

    <div class="title__text">
        {{ title | translate }}
    </div>
</div>
<div mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <ng-container [ngSwitch]="entityType">
        <div *ngSwitchCase="'equipment'" autofocus="true">
            <div class="message">
                {{ message | translate }}
            </div>
            <div class="detail__container">
                <div *ngIf="formTypeIsDelete()" class="container__title">
                    {{ 'timesheet.order.tab.accordionTitle.equipment.deleteForm.details' | translate }}
                </div>

                <div class="container__item item__pair">
                    <div class="item__name">
                        Number:
                    </div>
                    <div class="item__desc">
                        {{ entity.number }}
                    </div>
                </div>
                <div *ngIf="canAddEditTime" class="container__item">
                    <div class="item__name">
                        Heures:
                    </div>
                    <div class="item__desc">
                        {{ entity.hour }}h
                    </div>
                </div>
                <div class="container__item  item__pair">
                    <div class="item__name">
                        Code:
                    </div>
                    <div class="item__desc">
                        {{ entity.code }}
                    </div>
                </div>
                <div class="container__item">
                    <div class="item__name">
                        Description:
                    </div>
                    <div class="item__desc">
                        {{ entity.description }}
                    </div>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'worker'">
            <div class="message">
                {{ message | translate }}
            </div>
            <div class="detail__container">
                <div *ngIf="formTypeIsDelete()" class="container__title">
                    {{ 'timesheet.order.tab.accordionTitle.equipment.deleteForm.details' | translate }}
                </div>
                <div class="container__item item__pair">
                    <div class="item__name">
                        Nom complet:
                    </div>
                    <div class="item__desc">
                        {{ entity.firstName }} {{ entity.lastName }}
                    </div>
                </div>
                <div class="container__item  item__pair">
                    <div class="item__name">
                        Classe d'emploi:
                    </div>
                    <div class="item__desc">
                        ({{ entity.jobClassCode }}) {{ entity.jobClassDescr }}
                    </div>
                </div>
                <div class="container__item  item__pair">
                    <div class="item__name">
                        Code de facturation:
                    </div>
                    <div class="item__desc">
                        ({{ entity.jobSAPCode }}) {{ entity.jobSAPDescr }}
                    </div>
                </div>
                <div *ngIf="canAddEditTime" class="container__item">
                    <div class="item__name">
                        Nombre d'heures:
                    </div>
                    <div class="item__desc">
                        {{ entity.hour }} h
                    </div>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'unit'">
            <div class="message">
                {{ message | translate }}
            </div>
            <div class="detail__container">
                <div *ngIf="formTypeIsDelete()" class="container__title">
                    {{ 'timesheet.order.tab.accordionTitle.equipment.deleteForm.details' | translate }}
                </div>
                <div class="container__item item__pair">
                    <div class="item__name">
                        Code:
                    </div>
                    <div class="item__desc">
                        {{ entity.code }}
                    </div>
                </div>
                <div class="container__item  item__pair">
                    <div class="item__name">
                        Quantity:
                    </div>
                    <div class="item__desc">
                        {{ entity.quantity }}
                    </div>
                </div>
                <div class="container__item">
                    <div class="item__name">
                        Descritption:
                    </div>
                    <div class="item__desc">
                        {{ entity.description }}
                    </div>
                </div>
                <div *ngIf="canAddEditTime" class="container__item">
                    <div class="item__name">
                        Nombre d'heures:
                    </div>
                    <div class="item__desc">
                        {{ entity.hour }}h
                    </div>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'postLine'">
            <div class="message">
                {{ message | translate }}
            </div>
            <div class="detail__container">
                <div *ngIf="formTypeIsDelete()" class="container__title">
                    {{ 'timesheet.order.tab.accordionTitle.equipment.deleteForm.details' | translate }}
                </div>
                <div class="container__item  item__pair">
                    <div class="item__name">
                        #Imputation:
                    </div>
                    <div class="item__desc">
                        {{ entity.chargeNumber }}
                    </div>
                </div>
                <div *ngIf="displayOutageNumber" class="container__item item__pair">
                    <div class="item__name">
                        #Panne:
                    </div>
                    <div class="item__desc">
                        {{ entity.powerOutageNumber }}
                    </div>
                </div>
                <div class="container__item">
                    <div class="item__name">
                        Poste:
                    </div>
                    <div class="item__desc">
                        {{ entity.postElect }}
                    </div>
                </div>
                <div class="container__item">
                    <div class="item__name">
                        Ligne:
                    </div>
                    <div class="item__desc">
                        {{ entity.line }}
                    </div>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'timesheet'">
            <app-modal-timesheet-status (disableButton)="disableButton($any($event))" [formType]="formType" [title]="title" [message]="message" [entity]="entity" (closed)="onClose()" (saved)="onConfirm()"></app-modal-timesheet-status>
        </div>
        <div *ngSwitchCase="'infoOrderStatus'">
            <app-modal-info-order-status [message]="message" (closed)="onClose()"></app-modal-info-order-status>
        </div>
        <div *ngSwitchCase="'note'">
            <div class="message">
                {{ message | translate }}
            </div>
            <div class="detail__container">
                <div *ngIf="formTypeIsDelete()" class="container__title">
                    {{ 'timesheet.order.deleteNoteForm.details' | translate }}
                </div>
                <div class="container__item  item__pair">
                    <div class="item__name">
                        {{ 'timesheet.order.deleteNoteForm.remark' | translate }}
                    </div>
                    <div class="item__desc">
                        {{ entity }}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <app-generic-form-buttons *ngIf="hideButtons()" [disabled]="disabled" (cancel)="onClose()" (save)="onConfirm()"></app-generic-form-buttons>
</div>
