import { Injectable } from '@angular/core';

import { catchError, switchMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { AddOneAppNotification } from '../../../core/store/actions';
import { ApiService } from '../../../ogiv-core/services/api.service';
import { BillingTimesheetActionType, LoadBillingTimesheets, LoadBillingTimesheetsFail, LoadBillingTimesheetsSuccess } from '../actions/billing-timesheet.action';
import { BillingTimesheet } from '../../../shared/models/database/billing-timesheet.model';
import { ErrorNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class BillingTimesheetsEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public loadBillingTimesheets$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingTimesheets>(BillingTimesheetActionType.LOAD_BILLING_TIMESHEETS),
        switchMap((action: LoadBillingTimesheets) => {
            return this.apiService.get<BillingTimesheet[]>(`${action.path}/rapports/rapportsfacturation/rfdt/${action.billingReportId}`).pipe(
                map((returnedBillingTimesheets: BillingTimesheet[]) =>
                    new LoadBillingTimesheetsSuccess(returnedBillingTimesheets)
                ),
                catchError((error: any) => of(new LoadBillingTimesheetsFail(error))),
            );
        })
    ));

    public loadBillingTimesheetsFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingTimesheetsFail>(BillingTimesheetActionType.LOAD_BILLING_TIMESHEETS_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'billing.notifications.loadTimesheets.error',
            }))
        ]),
    ));
}
