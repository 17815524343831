export enum BillingItemStatus {
    active = 'Actif',
    inactive = 'Inactif',
}

export class BillingItemRawData {
    id: number;
    code: string;
    description: string;
    unit: string;
    status: string;
    date_inactif?: Date;
    detail?: string | null;
}

export class BillingItem {
    id: number;
    code: string;
    description: string;
    unit: string;
    status: BillingItemStatus;
    date_inactif?: Date;
    detail: string | null = null;

    constructor(data: BillingItemRawData) {
        Object.keys(data).forEach((key: string) => {
            this[key] = data[key];
        });
    }
}
