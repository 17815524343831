import { Component } from '@angular/core';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ComponentBase, ComponentFeatures, InheritsBaseLifecycleHooks } from '@ngxhq/common-ui';

import { SetRoles } from '../core/store/actions';
import { CoreState } from '../core/store/reducers';
import { getUserRoles } from '../core/store/selectors';
import { UserRole } from '../shared/models/user-roles.model';
import { AppConfiguration } from '../shared/config/app-config.model';
import { environment } from '@app/environment';

@Component({
    styleUrls: ['./home.component.scss'],
    templateUrl: './home.component.html',
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class HomeComponent extends ComponentBase {
    public displayRoles = this.configService && this.configService.accueilDisplayRoles || false;

    public userRole: typeof UserRole = UserRole;
    public userRoles$: Observable<UserRole[]> = this.store.pipe(select(getUserRoles));
    public version = environment.appversion;

    constructor(
        private readonly store: Store<CoreState>,
        private configService: AppConfiguration,
    ) {
        super();
    }

    public setRole(role: UserRole): void {
        this.store.dispatch(new SetRoles([role]));
    }
}
