import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { PostLine } from '../../../shared/models/database/post-line.model';
import { PostLineActionTypes, PostLinesAction } from '../actions/post-lines.action';

export interface PostLineState extends EntityState<PostLine> {
    loading: boolean;
    loaded: boolean;
}

export const postLineAdapter: EntityAdapter<PostLine> = createEntityAdapter<PostLine>({
    selectId: (postLine: PostLine) => postLine.id,
});

export const postLineInitialState = postLineAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function postLineReducer(
    state: PostLineState = postLineInitialState,
    action: PostLinesAction,
): PostLineState {
    switch (action.type) {
        case PostLineActionTypes.DELETE_ONE_POST_LINE:
        case PostLineActionTypes.CREATE_POST_LINE:
        case PostLineActionTypes.UPDATE_ONE_POST_LINE: {
            return {
                ...state,
                loading: true,
            };
        }

        case PostLineActionTypes.CREATE_POST_LINE_SUCCESS: {
            // return postLineAdapter.addOne(action.postLine, {
            return {
                ...state,
                loading: false,
            };
        }

        case PostLineActionTypes.UPDATE_ONE_POST_LINE_SUCCESS: {
            // return postLineAdapter.upsertOne(action.postLine, {
            return {
                ...state,
                loading: false,
            };
        }

        case PostLineActionTypes.DELETE_ONE_POST_LINE_FAIL:
        case PostLineActionTypes.CREATE_POST_LINE_FAIL:
        case PostLineActionTypes.UPDATE_ONE_POST_LINE_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case PostLineActionTypes.LOAD_POST_LINES: {
            return {
                ...state,
                loading: true,
            };
        }

        case PostLineActionTypes.LOAD_POST_LINES_SUCCESS: {
            return postLineAdapter.setAll(action.postLines, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case PostLineActionTypes.LOAD_POST_LINES_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case PostLineActionTypes.LOAD_POST_LINES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        case PostLineActionTypes.DELETE_ONE_POST_LINE_SUCCESS: {
            // return postLineAdapter.removeOne(action.postLine.id, {
            return {
                ...state,
                loading: false,
            };
        }

        default:
            return state;
    }
}
