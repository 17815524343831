import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { OgivCoreState, ogivCoreStoreReducers } from './reducers';

export const OGIV_CORE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<OgivCoreState>>('Ogiv Core reducers');

export function getOgivCoreReducers(): ActionReducerMap<OgivCoreState> {
    return ogivCoreStoreReducers;
}
