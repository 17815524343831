import { createFeatureSelector } from '@ngrx/store';

import { timesheetReportsReducer, TimesheetReportsState } from './timesheet-reports.reducer';
import { timesheetReportsSummaryReducer, TimesheetReportsSummaryState } from './timesheet-reports-summary.reducer';
import { timesheetReportsSummaryTechReducer, TimesheetReportsSummaryTechState } from './timesheet-reports-summary-tech.reducer';
import { progressReportsGlobalReducer, ProgressReportsGlobalState } from './progress-reports-global.reducer';
import { progressReportsSpecificReducer, ProgressReportsSpecificState } from './progress-reports-specific.reducer';

/* eslint-disable */
export interface ReportsState {
    timesheetReports: TimesheetReportsState;
    timesheetReportsSummary: TimesheetReportsSummaryState;
    timesheetReportsSummaryTech: TimesheetReportsSummaryTechState;
    progressReportsGlobal: ProgressReportsGlobalState;
    progressReportsSpecific: ProgressReportsSpecificState;
}

export const reportsStoreReducers: any = {
    timesheetReports: timesheetReportsReducer,
    timesheetReportsSummary: timesheetReportsSummaryReducer,
    timesheetReportsSummaryTech: timesheetReportsSummaryTechReducer,
    progressReportsGlobal: progressReportsGlobalReducer,
    progressReportsSpecific: progressReportsSpecificReducer
};

export const getReportsState = createFeatureSelector<ReportsState>('reports');
