import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-edit-charge-number-form',
    templateUrl: './edit-charge-number-form.component.html',
    styleUrls: ['./edit-charge-number-form.component.scss']
})
export class EditChargeNumberFormComponent {
    public chargeNumberForm: FormGroup;

    @Input() public translatePrefix: string;

    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public update: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<EditChargeNumberFormComponent>,
    ) {
        this.chargeNumberForm = this.formBuilder.group({
            chargeNumber: ['', [Validators.required]],
        });
    }

    public onUpdate(): void {
        if (this.chargeNumberForm.valid && this.chargeNumberForm.controls.chargeNumber.dirty) {
            this.dialogRef.close(this.chargeNumberForm.controls.chargeNumber.value);
        }
    }

    public onCancel(): void {
        this.dialogRef.close();
    }
}
