import { createFeatureSelector } from '@ngrx/store';

import { OrdersState, ordersReducer } from './orders.reducer';
import { TransportCodesState, transportCodeReducer } from './transport-codes.reducer';
import { MealCodesState, mealCodeReducer } from './meal-codes.reducer';
import { EquipmentCodesState, equipmentCodeReducer } from './equipment-codes.reducer';
import { JobCodesState, jobCodeReducer } from './job-codes.reducer';
import { UnitCodesState, unitCodeReducer } from './unit-codes.reducer';
import { billingItemReducer, BillingItemsState } from '../reducers/billing-item.reducer';

export interface OgivCoreState {
    orders: OrdersState;
    transportCodes: TransportCodesState;
    mealCodes: MealCodesState;
    equipmentCodes: EquipmentCodesState;
    jobCodes: JobCodesState;
    unitCodes: UnitCodesState;
    billingItems: BillingItemsState;
}

export const ogivCoreStoreReducers: any = {
    orders: ordersReducer,
    transportCodes: transportCodeReducer,
    mealCodes: mealCodeReducer,
    equipmentCodes: equipmentCodeReducer,
    jobCodes: jobCodeReducer,
    unitCodes: unitCodeReducer,
    billingItems: billingItemReducer,
};

export const getOgivCoreState = createFeatureSelector<OgivCoreState>('ogivCore');
