import { UnitCode } from '../../../shared/models/database/unit-code.model';
import { OrderType } from '../../../shared/models/database/order-type.enum';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { Action } from '@ngrx/store';

/**
 * Types
 */
export enum UnitCodeActionType {
    LOAD_UNIT_CODES = '[UnitCode] Load UnitCodes',
    LOAD_UNIT_CODES_SUCCESS = '[UnitCode] Load UnitCodes Success',
    LOAD_UNIT_CODES_FAIL = '[UnitCode] Load UnitCodes Fail',
    LOAD_UNIT_CODES_NO_RESULTS = '[UnitCode] Load UnitCodes No Result',

    CREATE_UNIT_CODE = '[UnitCode] Create UnitCode',
    CREATE_UNIT_CODE_SUCCESS = '[UnitCode] Create UnitCode Success',
    CREATE_UNIT_CODE_FAIL = '[UnitCode] Create UnitCode Fail',

    UPDATE_ONE_UNIT_CODE = '[UnitCode] Update One UnitCode',
    UPDATE_ONE_UNIT_CODE_SUCCESS = '[UnitCode] Update One UnitCode Success',
    UPDATE_ONE_UNIT_CODE_FAIL = '[UnitCode] Update One UnitCode Fail',
}

/**
 * ACTIONS
 */
/**
 * CREATE
 */
export class CreateUnitCode implements Action {
    readonly type = UnitCodeActionType.CREATE_UNIT_CODE;
    constructor(public unitCode: UnitCode, public path: AlternativePath) { }
}

export class CreateUnitCodeSuccess implements Action {
    readonly type = UnitCodeActionType.CREATE_UNIT_CODE_SUCCESS;
    constructor(public unitCode: UnitCode) { }
}

export class CreateUnitCodeFail implements Action {
    readonly type = UnitCodeActionType.CREATE_UNIT_CODE_FAIL;
    constructor(public error: any) { }
}

/**
 * LOAD
 */
export class LoadUnitCodes implements Action {
    readonly type = UnitCodeActionType.LOAD_UNIT_CODES;
    constructor(public orderType?: OrderType) { }
}

export class LoadUnitCodesNoResult implements Action {
    readonly type = UnitCodeActionType.LOAD_UNIT_CODES_NO_RESULTS;
}

export class LoadUnitCodesSuccess implements Action {
    readonly type = UnitCodeActionType.LOAD_UNIT_CODES_SUCCESS;
    constructor(public unitCodes: UnitCode[]) { }
}

export class LoadUnitCodesFail implements Action {
    readonly type = UnitCodeActionType.LOAD_UNIT_CODES_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneUnitCode implements Action {
    readonly type = UnitCodeActionType.UPDATE_ONE_UNIT_CODE;
    constructor(public unitCode: UnitCode, public path: AlternativePath) { }
}

export class UpdateOneUnitCodeSuccess implements Action {
    readonly type = UnitCodeActionType.UPDATE_ONE_UNIT_CODE_SUCCESS;
    constructor(public unitCode: UnitCode) { }
}

export class UpdateOneUnitCodeFail implements Action {
    readonly type = UnitCodeActionType.UPDATE_ONE_UNIT_CODE_FAIL;
    constructor(public error: any) { }
}

export type UnitCodeAction =
    | CreateUnitCode
    | CreateUnitCodeSuccess
    | CreateUnitCodeFail
    | LoadUnitCodes
    | LoadUnitCodesNoResult
    | LoadUnitCodesSuccess
    | LoadUnitCodesFail
    | UpdateOneUnitCode
    | UpdateOneUnitCodeSuccess
    | UpdateOneUnitCodeFail;
