import { Action } from '@ngrx/store';
import { BillingSapEntry } from '../../../shared/models/database/billing-sap-entry.model';

/**
 * Types
 */
export enum BillingSapEntriesActionType {
    LOAD_BILLING_SAP_ENTRIES = '[Billing Sap Entries] Load Billing Sap Entries',
    LOAD_BILLING_SAP_ENTRIES_SUCCESS = '[Billing Sap Entries] Load Billing Sap Entries Success',
    LOAD_BILLING_SAP_ENTRIES_FAIL = '[Billing Sap Entries] Load Billing Sap Entries Fail',
}

/**
 * ACTIONS
 */
/**
 * LOAD
 */
export class LoadBillingSapEntries implements Action {
    readonly type = BillingSapEntriesActionType.LOAD_BILLING_SAP_ENTRIES;
    constructor(public billingReportId: number) { }
}

export class LoadBillingSapEntriesSuccess implements Action {
    readonly type = BillingSapEntriesActionType.LOAD_BILLING_SAP_ENTRIES_SUCCESS;
    constructor(public billingSapEntries: BillingSapEntry[]) { }
}

export class LoadBillingSapEntriesFail implements Action {
    readonly type = BillingSapEntriesActionType.LOAD_BILLING_SAP_ENTRIES_FAIL;
    constructor(public error: any) { }
}

export type BillingSapEntriesAction =
    | LoadBillingSapEntries
    | LoadBillingSapEntriesSuccess
    | LoadBillingSapEntriesFail;
