import { Component } from '@angular/core';

@Component({
    selector: 'app-special-measures',
    template: `<router-outlet></router-outlet>`,
    styleUrls: ['./special-measures.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'specialMeasuresComponent',
    }
})
export class SpecialMeasuresComponent { }
