import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Error } from '../../../../../shared/models/database/error.model';
import { Timesheet } from '../../../../../shared/models/database/timesheet.model';

@Component({
    selector: 'app-modal-timesheet-status',
    templateUrl: './modal-timesheet-status.component.html',
    styleUrls: ['./modal-timesheet-status.component.scss'],
})
export class ModalTimesheetStatusComponent implements OnChanges {
    public errors: Error | null;
    public errorsList: Error[] = [];
    public currentTimesheet: Timesheet;
    public disableButton = false;

    @Input() public formType: string;
    @Input() public title: string;
    @Input() public message: string;
    @Input() public entity?: any;

    @Output() public closed: EventEmitter<boolean> = new EventEmitter();
    @Output() public saved: EventEmitter<boolean> = new EventEmitter();

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.entity && changes.entity.currentValue) {
            this.currentTimesheet = new Timesheet(changes.entity.currentValue);
            this.validateForSubmit();
        }
    }

    public fetchError(errors: Error | null): void {
        if (errors && errors.list.length > 0) {
            if (typeof errors.list[0] !== 'string') {
                this.errors = errors;
                this.errorsList = errors.list as Error[];
            }
        }
    }
    public validateForSubmit(): void {
        const myErrors: Error | null = this.currentTimesheet.validateForSubmit();
        this.fetchError(myErrors);

        if (this.errors && this.errors !== null) {
            this.disableButton = true;
        }
    }

    public onClose(): void {
        this.closed.emit();
    }

    public onConfirm(): void {
        this.saved.emit(true);
    }
}
