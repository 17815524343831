<mat-expansion-panel (opened)="openPanel(accordionIndex)" (closed)="closePanel(accordionIndex)" [class]="getCssChildrenStatus(orderGroup)">
    <mat-expansion-panel-header [collapsedHeight]="'64px'" [expandedHeight]="'64px'" [class]="getCssChildrenStatus(orderGroup)">
        <div class="accordion__header">
            <div class="header__title">
                <div class="title__main">
                    <div class="main__text">{{ 'timesheet.order.title' | translate }} : {{orderGroup.number}}</div>
                </div>
                <div class="title__sub">
                    <div class="sub__text" [class]="getCssChildrenStatus(orderGroup)">{{ 'timesheet.order.subTitle' | translate }} : {{orderGroup.accountingEntry}}</div>
                </div>
            </div>
            <div class="header__bubble" [class.header__bubble--hide]="shouldDisplayBubble(accordionIndex)">
                <app-info *ngIf="hasUnitlyOrder(orderGroup)" [logo]="'fab fa-buromobelexperte'"
                    [number]="getUnitDone(orderGroup.unitly!)" [status]="getChildrenStatus(orderGroup)">
                </app-info>
                <app-info *ngIf="hasHourlyOrder(orderGroup)" [logo]="'fas fa-hourglass-half'"
                    [number]="getUnitDone(orderGroup.hourly!)" [status]="getChildrenStatus(orderGroup)"></app-info>
                <app-info *ngIf="hasOutageOrder(orderGroup)" [logo]="'fas fa-bolt'"
                    [number]="getUnitDone(orderGroup.outage!)" [status]="getChildrenStatus(orderGroup)"></app-info>
            </div>
            <div *hasRole="editAllowedRoles" class="header__button"
                [class.header__button--display]="shouldDisplayActionsButtons(accordionIndex, orderGroup)">
                <div class="button__content">
                    <app-generic-other-menu
                        [addOnly]="displayAddButton"
                        [addborder]="true"
                        [displayMenu]="displayMenu"
                        [hideItems]="hideButtonMenu"
                        [isLock]="shouldLockActionButton(orderGroup)"
                        (add)="createOrder(orderGroup, $event)"
                        (delete)="deleteOrder(orderGroup, $event)"></app-generic-other-menu>
                </div>
            </div>
        </div>
    </mat-expansion-panel-header>
    <app-tab-view [pathChoice]="pathChoice" [editAllowedRoles]="editAllowedRoles" [orderGroup]="orderGroup" [prefixTranslate]="'timesheet.order.tab'"
        [timesheetDateId]="timesheetDateId">
    </app-tab-view>
</mat-expansion-panel>
