import { Inject, Injectable } from '@angular/core';

import { WINDOW } from '../../tokens/window.token';

@Injectable()
export class WindowRefService {
    constructor(@Inject(WINDOW) private window: Window) { }

    public get nativeWindow(): any {
        return this.window;
    }
}
