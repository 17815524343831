import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { AlternativePath } from '../../../../shared/models/atlernative-path.model';
import { CoreState } from '../../../../core/store/reducers';
import { FormMode } from '../../../../shared/models/form-mode.model';
import { getUserRoles } from '../../../../core/store/selectors';
import { UserRole } from '../../../../shared/models/user-roles.model';

@Component({
    templateUrl: './display-billing-report.component.html',
    styleUrls: ['./display-billing-report.component.scss']
})
export class DisplayBillingReportComponent implements OnInit {
    public translatePrefix: string;
    public userRole: typeof UserRole = UserRole;
    public currentUserRoles$: Observable<UserRole[]> = this.store.pipe(select(getUserRoles));
    public pathChoice: AlternativePath;
    public formMode = FormMode.display;
    public backPressed = false;

    constructor(
        private readonly store: Store<CoreState>,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.pathChoice = this.route.snapshot.data['pathChoice'];
        this.translatePrefix = 'billing.' + (this.pathChoice === AlternativePath.ent ? 'contractor' : 'cgc') + '.displayReport';
    }
}
