import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { TimesheetState, getTimesheetState } from '../reducers';
import { PostLine } from '../../../shared/models/database/post-line.model';
import { postLineAdapter, PostLineState } from '../reducers/post-lines.reducer';

const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = postLineAdapter.getSelectors();

export const getPostLinesState = createSelector(
    getTimesheetState,
    (state: TimesheetState) => state.postLines,
);

export const getPostLinesLoading = createSelector(
    getPostLinesState,
    (state: PostLineState) => state.loading,
);

export const getPostLinesLoaded = createSelector(
    getPostLinesState,
    (state: PostLineState) => state.loaded,
);

export const shouldLoadPostLines = createSelector(
    getPostLinesLoaded,
    getPostLinesLoading,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllPostLines = createSelector(
    getPostLinesState,
    selectAll,
);

export const getPostLinesEntities = createSelector(
    getPostLinesState,
    selectEntities,
);

export const getPostLinesIds = createSelector(
    getPostLinesState,
    selectIds,
);

export const getPostLineById = (props: any) => createSelector(
    getPostLinesEntities,
    (entities: Dictionary<PostLine>) => {
        return entities && entities[props.entityId] || null;
    }
);

export const getPostLinesCount = createSelector(
    getPostLinesState,
    selectTotal
);
