import { TableColumns } from '../../../../../../shared/models/table-columns.model';

export const editTimesheetChargeNumberColumns: TableColumns[] = [
    { field: 'teamLeader', header: 'teamLeader', width: '100px' },
    { field: 'date', header: 'date', type: 'date', width: '100px' },
    { field: 'order', header: 'order' },
    { field: 'accountingEntry', header: 'accountingEntry' },
    { field: 'billingType', header: 'billingType' },
    { field: 'chargeNumber', header: 'chargeNumber', width: '115px' },
    { field: 'outageNumber', header: 'outageNumber' },
    { field: 'postElect', header: 'postElect', width: '50px' },
    { field: 'line', header: 'line', width: '50px' },
    { field: 'code', header: 'code', width: '100px' },
    { field: 'timesheetReportStatus', header: 'timesheetReportStatus' },
    { field: 'timesheetReportNumber', header: 'timesheetReportNumber' },
    { field: 'billingReportStatus', header: 'billingReportStatus' },
    { field: 'billingReportNumber', header: 'billingReportNumber' },
];
