import { createFeatureSelector } from '@ngrx/store';

import { ArboristsState, arboristReducer } from './arborists.reducer';
import { EquipmentState, equipmentReducer } from './equipments.reducer';
import { JobClassesState, jobClassReducer } from './job-classes.reducer';
import { PostLineState, postLineReducer } from './post-lines.reducer';
import { WorkersState, workersReducer } from './team-mates.reducer';
import { timesheetsReducer, TimesheetsState } from './timesheets.reducer';
import { UnitState, unitReducer } from './units.reducer';
import { VehiclesState, vehicleReducer } from './vehicles.reducer';

export interface TimesheetState {
    arborists: ArboristsState;
    equipments: EquipmentState;
    jobClasses: JobClassesState;
    postLines: PostLineState;
    workers: WorkersState;
    timesheets: TimesheetsState;
    units: UnitState;
    vehicles: VehiclesState;
}

export const timesheetStoreReducers: any = {
    arborists: arboristReducer,
    equipments: equipmentReducer,
    jobClasses: jobClassReducer,
    postLines: postLineReducer,
    workers: workersReducer,
    timesheets: timesheetsReducer,
    units: unitReducer,
    vehicles: vehicleReducer,
};

export const getTimesheetState = createFeatureSelector<TimesheetState>('timesheet');
