import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
    BusyModule,
    BusyRouterService,
    BusyService,
    CommonUiModule,
    LoadingBehaviorsModule,
    ShellModule,
} from '@ngxhq/common-ui';
import { ConfigModule } from '@ngxhq/config';
import { APP_ENVIRONMENT_CONFIG, OpenIdModule, SecurityModule } from '@ngxhq/security';
import { UtilitiesModule } from '@ngxhq/utilities';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';

/* Store imports */
import { CustomRouterStateSerializer, logger } from './store/utils';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '@app/environment';

import { GlobalErrorComponent, GlobalErrorHandler } from './errors';
import { throwIfAlreadyLoaded } from './module-import.guard';
import { coreReducers } from './store/reducers';
import { coreEffects } from './store/effects';
import { I18nService } from './services/i18n.service';
import { UserService } from './services/user.service';
import { coreGuards } from './guards';

/* Store setup */
export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : [];

/* Translate setup */
export function httpLoaderFactory(myHttp: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(myHttp);
}

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule.forRoot(),
        ConfigModule,
        ConfigModule.forRoot(),
        OpenIdModule.forRoot(),
        SecurityModule.forRoot(),
        CommonUiModule,
        CommonUiModule.forRoot(),
        ShellModule.forRoot(),
        BusyModule,
        BusyModule.forRoot(),
        LoadingBehaviorsModule.forRoot(),
        ToastrModule.forRoot(),

        /* Translate */

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        /* Store */
        StoreModule.forRoot(coreReducers, { metaReducers, runtimeChecks: { strictActionImmutability: true, strictStateImmutability: true } }),
        environment.production ? [] : StoreDevtoolsModule.instrument(),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomRouterStateSerializer,
            stateKey: 'router',
        }),
        EffectsModule.forRoot(coreEffects),
    ],
    exports: [
        CommonModule,
        UtilitiesModule,
        ConfigModule,
        SecurityModule,
        CommonUiModule,
    ],
    declarations: [
        GlobalErrorComponent,
    ],
    providers: [
        BusyRouterService,
        BusyService,
        I18nService,
        UserService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: APP_ENVIRONMENT_CONFIG,
            useValue: environment,
        },
        {
            provide: RouterStateSerializer,
            useClass: CustomRouterStateSerializer,
        },
        ...coreGuards,
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
