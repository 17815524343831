import { utilisateursExternesTeamLeadersAdapter, UtilisateursExternesTeamLeadersState } from '../reducers/utilisateurs-externes-team-leaders.reducer';
import { createSelector } from '@ngrx/store';
import { getPilotagesState, PilotagesState } from '../reducers';
import { UtilisateurExterne } from '../../models/utilisateur-externe.model';
import { UtilisateurExterneTeamLeaderSelectItem } from '../../models/utilisateur-externe-team-leader-select-item.model';

const {
    selectAll
} = utilisateursExternesTeamLeadersAdapter.getSelectors();

export const getUtilisateursExternesTeamLeadersState = createSelector(
    getPilotagesState,
    (state: PilotagesState) => state.utilisateursExternesTeamLeader,
);

export const getUtilisateursExternesTeamLeadersLoading = createSelector(
    getUtilisateursExternesTeamLeadersState,
    (state: UtilisateursExternesTeamLeadersState) => state.loading,
);

export const getUtilisateursExternesTeamLeadersLoaded = createSelector(
    getUtilisateursExternesTeamLeadersState,
    (state: UtilisateursExternesTeamLeadersState) => state.loaded,
);

export const shouldLoadUtilisateursExternesTeamLeaders = createSelector(
    getUtilisateursExternesTeamLeadersLoading,
    getUtilisateursExternesTeamLeadersLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllUtilisateursExternesTeamLeaders = createSelector(
    getUtilisateursExternesTeamLeadersState,
    selectAll
);

export const getUtilisateursExternesTeamLeadersOptions = createSelector(
    getAllUtilisateursExternesTeamLeaders,
    (utilisateursExternes: UtilisateurExterne[]): UtilisateurExterneTeamLeaderSelectItem[] => {
        const mappedTeamLeader = utilisateursExternes.map((utilisateursExterne: UtilisateurExterne) => {
            return {
                label: {
                    nom: `${utilisateursExterne.nom}`,
                    prenom: `${utilisateursExterne.prenom}`,
                    idAgol: `${utilisateursExterne.id_agol}`
                } as any,
                value: utilisateursExterne.id_agol
            } as UtilisateurExterneTeamLeaderSelectItem;
        });

        return mappedTeamLeader.sort((a: UtilisateurExterneTeamLeaderSelectItem, b: UtilisateurExterneTeamLeaderSelectItem): number => {
            return (a.label.nom.toLocaleLowerCase()).localeCompare(b.label.nom.toLocaleLowerCase());
        });
    }
);

export const getUtilisateursExternesTeamLeaderByidAgol = (props: { idAgol?: string } = {}) => createSelector(
    getAllUtilisateursExternesTeamLeaders,
    (utilisateursExternes: UtilisateurExterne[]) => {
        const found = utilisateursExternes.filter(utilisateursExterne =>
            (utilisateursExterne.id_agol.toLowerCase() === props.idAgol?.toLowerCase()) ||
            (utilisateursExterne.id_agol.toLowerCase() === props.idAgol?.toLowerCase() + '_prod'));
        return found[0] ?? null;
    }
);
