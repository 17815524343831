import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { BillingTimesheet } from '../../../shared/models/database/billing-timesheet.model';
import { BillingTimesheetAction, BillingTimesheetActionType } from '../actions';

export interface BillingTimesheetsState extends EntityState<BillingTimesheet> {
    loading: boolean;
    loaded: boolean;
}

export const billingTimesheetsAdapter: EntityAdapter<BillingTimesheet> = createEntityAdapter<BillingTimesheet>({
    selectId: (billingTimesheet: BillingTimesheet) => billingTimesheet.id,
});

export const billingTimesheetsInitialState = billingTimesheetsAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function billingTimesheetReducer(
    state: BillingTimesheetsState = billingTimesheetsInitialState,
    action: BillingTimesheetAction,
): BillingTimesheetsState {
    switch (action.type) {
        case BillingTimesheetActionType.LOAD_BILLING_TIMESHEETS: {
            return {
                ...state,
                loading: true,
            };
        }
        case BillingTimesheetActionType.LOAD_BILLING_TIMESHEETS_SUCCESS: {
            return billingTimesheetsAdapter.setAll(action.billingTimesheets, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case BillingTimesheetActionType.LOAD_BILLING_TIMESHEETS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
