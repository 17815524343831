import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import moment from 'moment';

import { environment } from '@app/environment';

@Injectable()
export class I18nService {
    private defaultLocale: string = environment.config.defaultLocale;
    private availableLanguages: string[] = environment.config.availableLanguages;

    constructor(
        private translateService: TranslateService,
    ) { }

    public get currentLang(): string {
        return this.translateService.currentLang;
    }

    public init(): void {
        this.setDefaultLanguage();
    }

    public changeLanguage(requestedLang: string): void {
        if (this.canChangeToGivenLang(requestedLang)) {
            this.translateService.use(requestedLang);

            moment.locale(requestedLang);
        }
    }

    public getCurrentLang(): Observable<string> {
        return this.translateService.onLangChange.pipe(
            startWith({ lang: this.translateService.currentLang, translations: {} }),
            map((event: LangChangeEvent) => {
                return event.lang;
            })
        );
    }

    private setDefaultLanguage(): void {
        // Set fallback lang
        const defaultLocale = this.defaultLocale;
        const defaultLang = this.extractLangInLocale(defaultLocale);
        this.translateService.setDefaultLang(defaultLang);

        // Detect browser lang and use it if available
        let browserLang: string | undefined = undefined;
        const browserLocale = this.translateService.getBrowserCultureLang();
        if (browserLocale) {
            browserLang = this.extractLangInLocale(browserLocale);
        }

        if (browserLang && this.isLangAvailable(browserLang)) {
            this.translateService.use(browserLang);
            moment.locale(browserLocale);
        } else {
            this.translateService.use(defaultLang);
            moment.locale(defaultLocale);
        }
    }

    private extractLangInLocale(locale: string): string {
        return locale.split('-')[0].toLowerCase();
    }

    private canChangeToGivenLang(requestedLang: string): boolean {
        return this.isLangAvailable(requestedLang) && this.translateService.currentLang !== requestedLang;
    }

    private isLangAvailable(requestedLang: string): boolean {
        return this.availableLanguages.includes(requestedLang);
    }
}
