export class VehicleRawData {
    public id?: number = undefined;
    public number?: string = undefined;
    public description?: string = undefined;
    public companyId?: number = undefined;
    public status?: VehicleStatus = undefined;
    public createdBy?: string = undefined;
    public createdDate?: Date = undefined;
    public modifiedBy?: string = undefined;
    public modifiedDate?: Date = undefined;
}

export class Vehicle {
    public id: number;
    public number: string;         // max-length 255
    public description: string;    // max-length 255
    public companyId?: number | null;
    public status: VehicleStatus;
    public createdBy?: string;       // max-length 255
    public createdDate?: Date;
    public modifiedBy?: string;       // max-length 255
    public modifiedDate?: Date;

    constructor(data: Partial<Vehicle>) {
        Object.keys(data).forEach((key: string) => {
            if (key in new VehicleRawData) {
                switch (key) {
                    case 'date':
                    case 'createdDate':
                    case 'modifiedDate':
                        const date = data[key];

                        if (date) {
                            this[key] = new Date(date);
                        }
                        break;
                    default:
                        this[key] = data[key];
                }
            }
        });
    }
}
// export interface Vehicle {
//     id: number | null;
//     number: string;         // max-length 255
//     description: string;    // max-length 255
//     companyId?: number | null;
//     status: VehicleStatus;
//     createdBy?: string;       // max-length 255
//     createdDate?: Date;
//     modifiedBy?: string;       // max-length 255
//     modifiedDate?: Date;
// }

export enum VehicleStatus {
    active = 'Actif',
    inactive = 'Inactif',
}
