import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { TimesheetReportSummary } from '../../../shared/models/database/timesheet-report-summary.model';
import { TimesheetReportsSummaryAction, TimesheetReportSummaryActionTypes } from '../actions/timesheet-reports-summary.action';

export interface TimesheetReportsSummaryState extends EntityState<TimesheetReportSummary> {
    loading: boolean;
    loaded: boolean;
}

export const timesheetReportsSummaryAdapter: EntityAdapter<TimesheetReportSummary> = createEntityAdapter<TimesheetReportSummary>({
    selectId: (timesheet: TimesheetReportSummary) => {
        const timesheeReporttInstance = new TimesheetReportSummary(timesheet);
        return timesheeReporttInstance.getUniqueId();
    }
});

export const timesheetReportsSummaryInitialState = timesheetReportsSummaryAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function timesheetReportsSummaryReducer(
    state: TimesheetReportsSummaryState = timesheetReportsSummaryInitialState,
    action: TimesheetReportsSummaryAction
): TimesheetReportsSummaryState {
    switch (action.type) {
        case TimesheetReportSummaryActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY: {
            return {
                ...state,
                loading: true,
            };
        }

        case TimesheetReportSummaryActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_SUCCESS: {
            return timesheetReportsSummaryAdapter.setAll(action.timesheetReports, {
                ...state,
                loading: false,
                loaded: true,
            });
        }
        case TimesheetReportSummaryActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_NO_RESULTS: {
            return timesheetReportsSummaryAdapter.setAll(action.timesheetReports, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case TimesheetReportSummaryActionTypes.LOAD_TIMESHEET_REPORTS_SUMMARY_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
