import { entrepreneursAdapter, EntrepreneursState } from '../reducers/entrepreneurs.reducer';
import { createSelector } from '@ngrx/store';
import { PilotagesState, getPilotagesState } from '../reducers';
import { Dictionary } from '@ngrx/entity';
import { Entrepreneur } from '../../models/entrepreneur.model';
import { SelectItem } from 'primeng/api';

const {
    selectAll,
    selectEntities,
    selectIds,
} = entrepreneursAdapter.getSelectors();

export const getEntrepreneursState = createSelector(
    getPilotagesState,
    (state: PilotagesState) => {
        return state.entrepreneurs;
    }
);

export const getEntrepreneursLoading = createSelector(
    getEntrepreneursState,
    (state: EntrepreneursState) => state.loading,
);

export const getEntrepreneursLoaded = createSelector(
    getEntrepreneursState,
    (state: EntrepreneursState) => state.loaded,
);

export const shouldLoadEntrepreneurs = createSelector(
    getEntrepreneursLoading,
    getEntrepreneursLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllEntrepreneurs = createSelector(
    getEntrepreneursState,
    selectAll
);

export const getAllEntrepreneursActifs = createSelector(
    getAllEntrepreneurs,
    (entrepreneurs: Entrepreneur[]) => entrepreneurs.map((entrepreneur: Entrepreneur) => entrepreneur)
        .filter(x => x.statut === 'Actif'),
);

export const getAllFetchedEntrepreneurs = createSelector(
    getAllEntrepreneurs,
    (entrepreneurs: Entrepreneur[]) => entrepreneurs.map((entrepreneur: Entrepreneur) => entrepreneur),
);

export const getEntrepreneursEntities = createSelector(
    getEntrepreneursState,
    selectEntities
);

export const getEntrepreneursIds = createSelector(
    getEntrepreneursState,
    selectIds,
);

export const getEntrepreneurById = (prop: any) => createSelector(
    getEntrepreneursEntities,
    (entities: Dictionary<Entrepreneur>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getEntrepreneursActifsSorted = createSelector(
    getAllEntrepreneursActifs,
    (entrepreneurs: Entrepreneur[]): Entrepreneur[] => {
        const tempoEntrepreneurs = [...entrepreneurs];
        tempoEntrepreneurs.sort((a: Entrepreneur, b: Entrepreneur): number => {
            return (a.nom.toLocaleLowerCase()).localeCompare(b.nom.toLocaleLowerCase());
        });
        return tempoEntrepreneurs;
    }
);

export const getEntrepreneurActifByAbreviation = (prop: { abbr: string }) => createSelector(
    getAllEntrepreneursActifs,
    (entrepreneurs: Entrepreneur[]): Entrepreneur | null => {
        if (entrepreneurs !== undefined) {
            const found = entrepreneurs.find((entrepreneur => entrepreneur.abreviation === prop.abbr));
            if (found === undefined) {
                return null;
            } else {
                return found;
            }
        } else {
            return null;
        }
    }
);

export const getAllEntrepreneursActifsSelectOptions = createSelector(
    getEntrepreneursActifsSorted,
    (entrepreneurs: Entrepreneur[]): SelectItem<number>[] => {
        const entrepreneurOptions = entrepreneurs.map((entrepreneur: Entrepreneur) => {
            return {
                label: entrepreneur.nom,
                value: Number(entrepreneur.id),
            } as SelectItem<number>;
        });

        entrepreneurOptions.unshift({
            label: 'Sélectionner une entreprise',
            value: 0,
        } as SelectItem<number>);

        return entrepreneurOptions;
    }
);
