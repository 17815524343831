import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { map, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { Back } from '../../../../core/store/actions';
import { BillingReport } from '../../../../shared/models/database/billing-report.model';
import { BillingSapEntry } from '../../../../shared/models/database/billing-sap-entry.model';
import { getBillingSapEntries, getBillingSapEntriesLoading, getCurrentBillingReport } from '../../../store/selectors';
import { LoadBillingSapEntries } from '../../../store/actions';
import { BillingReportPreviewPageHeader } from '../../../../shared/models/database/billing-report-preview.model';
import moment from 'moment';
import { Workbook, Worksheet } from 'exceljs';
import { ExcelHelpers } from '../../../../shared/helpers/excel.helper';

@Component({
    selector: 'app-sap-entries-report',
    templateUrl: './sap-entries-report.component.html',
    styleUrls: ['./sap-entries-report.component.scss']
})
export class SapEntriesReportComponent implements OnInit, OnDestroy {

    public rowGroupMetadata: any = {};
    public billingReportId: number;
    public billingSapEntries: BillingSapEntry[];
    public currentBillingReport: BillingReport;
    public pageHeaders: BillingReportPreviewPageHeader[] = [];
    public translatePrefix = 'billing.cgc.sap-entries-report.';

    public isBillingSapEntriesLoading$: Observable<boolean> = this.store.pipe(
        select(getBillingSapEntriesLoading)
    );

    public billingSapEntries$: Observable<BillingSapEntry[]> = this.store.pipe(
        select(getBillingSapEntries)
    );
    public billingReport$: Observable<BillingReport | null> = this.store.pipe(
        select(getCurrentBillingReport),
    );

    private subscriptions: Subscription[] = [];
    private workbook: Workbook;
    private formatedWorkSheetName = this.translate.instant(this.translatePrefix + 'workSheetName');
    private rawWorkSheetName = this.translate.instant(this.translatePrefix + 'rawDataWorkSheetName');

    constructor(
        private currentRoute: ActivatedRoute,
        private readonly store: Store,
        public translate: TranslateService,
    ) { }

    ngOnInit(): void {

        this.billingReportId = this.currentRoute.snapshot.params['billingReportId'];

        this.loadSapEntries();

        this.subscriptions.push(
            this.updateSapEntries(),
            this.setCurrentBillingReport()
        );
    }

    private loadSapEntries(): void {
        this.store.dispatch(new LoadBillingSapEntries(this.billingReportId));
    }

    private updateSapEntries(): Subscription {
        return this.billingSapEntries$.pipe(
            map(
                (billingSapEntries: BillingSapEntry[]) => {
                    if (billingSapEntries) {
                        // eslint-disable-next-line
                        this.billingSapEntries = billingSapEntries;
                        this.sortData();
                        this.updateRowGroupMetaData();
                    }
                }
            )).subscribe();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private setCurrentBillingReport(): Subscription {
        return this.billingReport$.pipe(
            tap((billingReport: BillingReport | null) => {
                if (billingReport) {
                    this.currentBillingReport = billingReport;
                    this.setPageHeaderValues();
                }
            })
        ).subscribe();
    }

    private setPageHeaderValues(): void {
        this.pageHeaders = [{
            rowTitle: this.translate.instant('billing.common.summary.company'),
            rowValue: this.currentBillingReport.company
        },
        {
            rowTitle: this.translate.instant('billing.common.reportForm.invoiceNumber'),
            rowValue: this.currentBillingReport.invoiceNumber
        },
        {
            rowTitle: this.translate.instant('billing.common.result.orderAccountingEntry'),
            rowValue: `${this.currentBillingReport.order} / ${this.currentBillingReport.accountingEntry}`,
        },
        {
            rowTitle: this.translate.instant('billing.common.summary.invoiceDate'),
            rowValue: moment(this.currentBillingReport.createdDate).format('yyyy-MM-DD')
        }];
    }

    private sortData(): void {
        this.billingSapEntries.sort((previous, next) =>
            (previous.numeroImputation ?? '').localeCompare(next.numeroImputation ?? '') ||
            (previous.natureComptable ?? '').localeCompare(next.natureComptable ?? '') ||
            (previous.numeroFicheService ?? '').localeCompare(next.numeroFicheService ?? '') ||
            (previous.code ?? '').localeCompare(next.code ?? '')
        );
    }

    /**
   * This metadata in parallel/background of the p-table is used to determine which rows will be spanned and for how many rows.
   *
   */
    private updateRowGroupMetaData(): void {
        this.rowGroupMetadata = {};

        let currentRowGroupNumeroImputation = '';
        let currentRowGroupNumeroImputationNatureComptable = '';

        if (this.billingSapEntries?.length > 0) {
            for (let i = 0; i < this.billingSapEntries.length; i++) {
                const rowData: BillingSapEntry = this.billingSapEntries[i];
                currentRowGroupNumeroImputation = rowData.numeroImputation.toString();
                currentRowGroupNumeroImputationNatureComptable = (rowData.numeroImputation + '-' + rowData.natureComptable).toString();

                if (i === 0) {
                    this.rowGroupMetadata[currentRowGroupNumeroImputation] = { index: 0, size: 1 };
                    this.rowGroupMetadata[currentRowGroupNumeroImputationNatureComptable] = { index: 0, size: 1 };
                } else {
                    const previousRowData = this.billingSapEntries[i - 1];

                    const previousRowGroupNumeroImputation = previousRowData.numeroImputation.toString();
                    if (currentRowGroupNumeroImputation === previousRowGroupNumeroImputation) {
                        this.rowGroupMetadata[currentRowGroupNumeroImputation].size++;
                    } else {
                        this.rowGroupMetadata[currentRowGroupNumeroImputation] = { index: i, size: 1 };
                    }

                    const previousRowGroupNumeroImputationNatureComptable = (previousRowData.numeroImputation + '-' + previousRowData.natureComptable).toString();
                    if (currentRowGroupNumeroImputationNatureComptable === previousRowGroupNumeroImputationNatureComptable) {
                        this.rowGroupMetadata[currentRowGroupNumeroImputationNatureComptable].size++;
                    } else {
                        this.rowGroupMetadata[currentRowGroupNumeroImputationNatureComptable] = { index: i, size: 1 };
                    }
                }
            }
        }
    }

    public backToReport(): void {
        this.store.dispatch(new Back());
    }

    public exportExcel(): void {
        this.workbook = new Workbook();
        this.renderSapEntriesReportFormatedWorksheet(this.workbook);
        this.renderSapEntriesReportRawWorksheet(this.workbook);
        this.downloadWorkbook(this.workbook);
    }

    private renderSapEntriesReportFormatedWorksheet(workbook: Workbook): void {
        const COLUMNS_WIDTH = [30, 30, 30, 20, 15, 15];
        const worksheet = ExcelHelpers.addNewWorksheet(workbook, this.formatedWorkSheetName, COLUMNS_WIDTH);

        this.renderBillingReportInfo(worksheet);
        this.renderSapEntriesReportHeader(worksheet);
        this.renderSapEntriesReportDetails(worksheet);
    }

    private renderSapEntriesReportRawWorksheet(workbook: Workbook): void {

        const REPORT_HEADER_RANGE = 'A1:F1';
        const COLUMNS_WIDTH = [30, 30, 30, 20, 15, 15];
        const worksheet = ExcelHelpers.addNewWorksheet(workbook, this.rawWorkSheetName, COLUMNS_WIDTH);

        worksheet.autoFilter = REPORT_HEADER_RANGE;
        this.renderSapEntriesReportRawDataHeader(worksheet);
        this.renderSapEntriesReportRawDataDetail(worksheet);
    }

    private renderBillingReportInfo(worksheet: Worksheet): void {
        const REPORT_INFO_RANGE = 'A1:B4';
        const TITLE_CELL = 'D1';
        const TITLE_RANGE = 'D1:F2';

        worksheet.addRow([this.translate.instant('billing.common.summary.company'), this.currentBillingReport.company]);
        worksheet.addRow([this.translate.instant('billing.common.reportForm.invoiceNumber'), this.currentBillingReport.invoiceNumber]);
        worksheet.addRow([
            this.translate.instant('billing.common.result.orderAccountingEntry'), `${this.currentBillingReport.order} / ${this.currentBillingReport.accountingEntry}`
        ]);
        worksheet.addRow([this.translate.instant('billing.common.summary.invoiceDate'), moment(this.currentBillingReport.createdDate).startOf('day').toDate()]);
        worksheet.getCell('B4').numFmt = 'yyyy-MM-dd';
        worksheet.getCell('B4').alignment = { horizontal: 'left' };

        ExcelHelpers.setCellsBorder(worksheet, REPORT_INFO_RANGE);

        const titleCell = worksheet.getCell(TITLE_CELL);
        titleCell.value = this.translate.instant(this.translatePrefix + 'title');
        titleCell.font = { name: 'Calibri', family: 4, size: 20, bold: true };

        worksheet.mergeCells(TITLE_RANGE);
        worksheet.addRow([]);
        worksheet.addRow([]);
    }

    private renderSapEntriesReportHeader(worksheet: Worksheet): void {
        const HEADER_RANGE = 'A7:F7';

        worksheet.addRow([
            this.translate.instant(this.translatePrefix + 'numeroImputation'),
            this.translate.instant(this.translatePrefix + 'natureComptable'),
            this.translate.instant(this.translatePrefix + 'numeroFicheService'),
            this.translate.instant(this.translatePrefix + 'code'),
            this.translate.instant(this.translatePrefix + 'heure'),
            this.translate.instant(this.translatePrefix + 'quantite'),
        ]);

        ExcelHelpers.setCellsBold(worksheet, HEADER_RANGE);
        ExcelHelpers.setCellsAlignment(worksheet, HEADER_RANGE, { horizontal: 'center' });
        ExcelHelpers.setCellsBackGroudColor(worksheet, HEADER_RANGE, 'cccccc');
        ExcelHelpers.setCellsBorder(worksheet, HEADER_RANGE);
    }

    private renderSapEntriesReportDetails(worksheet: Worksheet): void {
        const FIRST_DETAIL_ROW = 8;
        const LAST_ROW = this.billingSapEntries.length - 1 + FIRST_DETAIL_ROW;
        const DETAIL_RANGE = 'A8:F' + LAST_ROW.toString();
        const HOURS_RANGE = 'E8:E' + LAST_ROW.toString();
        const QUANTITY_RANGE = 'F8:F' + LAST_ROW.toString();
        const FIRST_GROUPING_COLUMN = 'A';
        const LAST_GROUPING_COLUMN = 'B';

        this.billingSapEntries.forEach(sapEntry => {
            const heuresValue = sapEntry.heure === 0 ? '-' : sapEntry.heure;
            const quantiteValue = sapEntry.quantite === 0 ? '-' : sapEntry.quantite;

            worksheet.addRow([sapEntry.numeroImputation,
            sapEntry.natureComptable,
            sapEntry.numeroFicheService,
            sapEntry.code,
                heuresValue,
                quantiteValue,
            ]);
        });

        ExcelHelpers.setCellsBorder(worksheet, DETAIL_RANGE);
        ExcelHelpers.setCellsNumberFormat(worksheet, HOURS_RANGE, '0.000');
        ExcelHelpers.setCellsNumberFormat(worksheet, QUANTITY_RANGE, '0.000');
        ExcelHelpers.setCellsAlignment(worksheet, 'A8:B' + LAST_ROW.toString(), { horizontal: 'right', vertical: 'middle' });
        ExcelHelpers.setCellsAlignment(worksheet, 'C8:C' + LAST_ROW.toString(), { horizontal: 'right', vertical: 'bottom' });
        ExcelHelpers.setCellsAlignment(worksheet, 'D8:D' + LAST_ROW.toString(), { horizontal: 'center', vertical: 'bottom' });
        ExcelHelpers.setCellsAlignment(worksheet, HOURS_RANGE, { horizontal: 'right', vertical: 'middle' });
        ExcelHelpers.setCellsAlignment(worksheet, QUANTITY_RANGE, { horizontal: 'right', vertical: 'middle' });

        ExcelHelpers.groupRows(worksheet, FIRST_DETAIL_ROW, worksheet.rowCount, FIRST_GROUPING_COLUMN, LAST_GROUPING_COLUMN);
    }

    private renderSapEntriesReportRawDataHeader(worksheet: Worksheet): void {
        worksheet.addRow([
            this.translate.instant(this.translatePrefix + 'numeroImputation'),
            this.translate.instant(this.translatePrefix + 'natureComptable'),
            this.translate.instant(this.translatePrefix + 'numeroFicheService'),
            this.translate.instant(this.translatePrefix + 'code'),
            this.translate.instant(this.translatePrefix + 'heure'),
            this.translate.instant(this.translatePrefix + 'quantite'),
        ]);
    }

    private renderSapEntriesReportRawDataDetail(worksheet: Worksheet): void {
        this.billingSapEntries.forEach(sapEntry => {
            worksheet.addRow([sapEntry.numeroImputation,
            sapEntry.natureComptable,
            sapEntry.numeroFicheService,
            sapEntry.code,
            sapEntry.heure,
            sapEntry.quantite,
            ]);
        });
    }

    private downloadWorkbook(workbook: Workbook): void {
        const filename =
            `${this.translate.instant(this.translatePrefix + 'exportFileNamePrefix')}` +
            (this.currentBillingReport.invoiceNumber ? this.currentBillingReport.invoiceNumber.trim() :
                this.currentBillingReport.id.toString().trim()) + '_' + `${new Date().getTime()}`;

        ExcelHelpers.save(workbook, filename);
    }
}
