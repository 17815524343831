import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
    ApproveTimesheet,
    ApproveTimesheetFail,
    ApproveTimesheetSuccess,
    CreateTimesheet,
    CreateTimesheetFail,
    CreateTimesheetSuccess,
    DeleteTimesheet,
    DeleteTimesheetFail,
    DeleteTimesheetSuccess,
    LoadTimesheetById,
    LoadTimesheetByIdAndImpersonnification,
    LoadTimesheetsFail,
    LoadTimesheetsSuccess,
    SubmitTimesheet,
    SubmitTimesheetFail,
    SubmitTimesheetSuccess,
    TimesheetsActionTypes,
} from '../actions/timesheets.action';

import { ApiService } from '../../../ogiv-core/services/api.service';
import { Timesheet } from '../../../shared/models/database/timesheet.model';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class TimesheetsEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public loadTimesheetsById$ = createEffect(() => this.actions$.pipe(
        ofType<LoadTimesheetById>(TimesheetsActionTypes.LOAD_TIMESHEET_BY_ID),
        switchMap((action: LoadTimesheetById) => {
            return this.apiService.get<Timesheet>(`${action.path}/fdt/${action.dateId}`).pipe(
                map((timesheet: Timesheet) => {
                    return new LoadTimesheetsSuccess(new Timesheet(timesheet));
                }),
                catchError((error: any) => of(new LoadTimesheetsFail(error))),
            );
        }),
    ));

    public loadTimesheetsByIdAndImpersonnification$ = createEffect(() => this.actions$.pipe(
        ofType<LoadTimesheetByIdAndImpersonnification>(TimesheetsActionTypes.LOAD_TIMESHEET_BY_ID_AND_IMPERSONNIFICATION),
        switchMap((action: LoadTimesheetByIdAndImpersonnification) => {
            return this.apiService.get<Timesheet>(`${action.path}/fdt/impersonification/${action.dateId}/${action.idAgol}`).pipe(
                map((timesheet: Timesheet) => {
                    return new LoadTimesheetsSuccess(new Timesheet(timesheet));
                }),
                catchError((error: any) => of(new LoadTimesheetsFail(error))),
            );
        }),
    ));

    public createTimesheet$ = createEffect(() => this.actions$.pipe(
        ofType<CreateTimesheet>(TimesheetsActionTypes.CREATE_TIMESHEET),
        mergeMap((action: CreateTimesheet) => {
            const instanciatedTimesheet = new Timesheet(action.timesheet);

            return this.apiService.post<Timesheet, Timesheet>(`${action.path}/fdt/${instanciatedTimesheet.getDateId()}`, action.timesheet).pipe(
                map((createdTimesheet: Timesheet) => new CreateTimesheetSuccess(
                    new Timesheet(createdTimesheet)
                )),
                catchError((error: any) => of(new CreateTimesheetFail(error))),
            );
        }),
    ));

    public deleteTimesheet$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteTimesheet>(TimesheetsActionTypes.DELETE_TIMESHEET),
        mergeMap((action: DeleteTimesheet) => {
            return this.apiService.delete<Timesheet>(`${action.path}/fdt/${action.timesheetDateId}`).pipe(
                map(() => new DeleteTimesheetSuccess(
                    action.timesheetDateId
                )),
                catchError((error: any) => of(new DeleteTimesheetFail(error))),
            );
        }),
    ));

    public approveTimesheet$ = createEffect(() => this.actions$.pipe(
        ofType<ApproveTimesheet>(TimesheetsActionTypes.APPROVE_TIMESHEET),
        mergeMap((action: ApproveTimesheet) => {
            return this.apiService.patch<Timesheet, Partial<Timesheet>>(`${action.path}/fdt/${action.timesheetDateId}/approve`, {}).pipe(
                map((approvedTimesheet: Timesheet) => new ApproveTimesheetSuccess(
                    approvedTimesheet
                )),
                catchError((error: any) => of(new ApproveTimesheetFail(error))),
            );
        }),
    ));

    /************************/
    /*******  SUBMIT  *******/
    /************************/
    public submitTimesheet$ = createEffect(() => this.actions$.pipe(
        ofType<SubmitTimesheet>(TimesheetsActionTypes.SUBMIT_TIMESHEET),
        mergeMap((action: SubmitTimesheet) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/soumettre`;
                } else {
                    url += `${action.timesheetDateId}/soumettre`;
                }
            } else {
                url += `${action.timesheetDateId}/soumettre`;
            }

            return this.apiService.put<Timesheet, Partial<Timesheet>>(url, {}).pipe(
                map((submittedTimesheet: Timesheet) => new SubmitTimesheetSuccess(
                    submittedTimesheet
                )),
                catchError((error: any) => of(new SubmitTimesheetFail(error))),
            );
        }),
    ));

    public submitTimesheetSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<SubmitTimesheetSuccess>(TimesheetsActionTypes.SUBMIT_TIMESHEET_SUCCESS),
        mergeMap(
            () => [
                new AddOneAppNotification(new SuccessNotification({
                    summary: 'notifications.success',
                    detail: 'timesheet.notifications.timesheet.submit.success',
                }))
            ]
        ),
    ));

    public submitTimesheetFail$ = createEffect(() => this.actions$.pipe(
        ofType<SubmitTimesheetFail>(TimesheetsActionTypes.SUBMIT_TIMESHEET_FAIL),
        mergeMap(
            (errorHttp: any) => {

                return [
                    new AddOneAppNotification(new ErrorNotification({
                        summary: 'notifications.error',
                        detail: errorHttp.error.error.code !== 500
                            ? errorHttp.error.error.description
                            : 'timesheet.notifications.timesheet.submit.error',
                    }))
                ];
            }
        ),
    ));
}
