import { createSelector } from '@ngrx/store';

import moment from 'moment';

import { ReportsState, getReportsState } from '../reducers';
import { timesheetReportsSummaryAdapter, TimesheetReportsSummaryState } from '../reducers/timesheet-reports-summary.reducer';
import { TimesheetReportSummary } from '../../../shared/models/database/timesheet-report-summary.model';

const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = timesheetReportsSummaryAdapter.getSelectors();

/**
 * General TimesheetReport State selector
 */

export const getTimesheetReportsSummaryState = createSelector(
    getReportsState,
    (state: ReportsState) => state.timesheetReportsSummary,
);

export const getTimesheetReportsSummaryLoading = createSelector(
    getTimesheetReportsSummaryState,
    (state: TimesheetReportsSummaryState) => state.loading,
);

export const getTimesheetReportsSummaryLoaded = createSelector(
    getTimesheetReportsSummaryState,
    (state: TimesheetReportsSummaryState) => state.loaded,
);

export const shouldLoadTimesheetReportsSummary = createSelector(
    getTimesheetReportsSummaryLoading,
    getTimesheetReportsSummaryLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllTimesheetReportsSummary = createSelector(
    getTimesheetReportsSummaryState,
    selectAll,
);

export const getTimesheetReportsSummaryEntities = createSelector(
    getTimesheetReportsSummaryState,
    selectEntities,
);

export const getTimesheetReportsSummaryIds = createSelector(
    getTimesheetReportsSummaryState,
    selectIds,
);

export const getTimesheetReportsSummaryCount = createSelector(
    getTimesheetReportsSummaryState,
    selectTotal,
);

export const getTimesheetReportsSummaryByWeek = ({ selectedDate }: { selectedDate?: string | Date } = {}) => createSelector(
    getAllTimesheetReportsSummary,
    (reports: TimesheetReportSummary[]) => {
        if (!selectedDate) {
            return reports;
        }

        return reports.filter((report: TimesheetReportSummary) => {
            const isSame = moment(report.week).isSame(moment(selectedDate), 'week');
            return isSame;
        });
    }
);
