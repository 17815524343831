import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../ogiv-core/services';
import {
    LoadEtatSynchronisations,
    EtatSynchronisationsActionType,
    LoadEtatSynchronisationsSuccess,
    LoadEtatSynchronisationsFail,
    LoadEtatSynchronisationsNoResult,
    DeleteOneReplica,
    DeleteOneReplicaSuccess,
    DeleteOneReplicaFail,
} from '../actions';
import { UtilisateurSynchro } from '../../models/utilisateur-synchronisation.model';

@Injectable()
export class EtatSynchronisationsEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService
    ) { }

    public loadEtatSynchronisations$ = createEffect(() => this.action$.pipe(
        ofType<LoadEtatSynchronisations>(EtatSynchronisationsActionType.LOAD_ETAT_SYNCHRONISATIONS),
        switchMap((action: LoadEtatSynchronisations) => {
            return this.apiService.get<UtilisateurSynchro[]>(`${action.path}/pilotage/utilisateurs/actions/consulter/etat/synchronisation`).pipe(
                map((utilisateurSynchro: UtilisateurSynchro[]) =>
                    (utilisateurSynchro.length > 0)
                        ? new LoadEtatSynchronisationsSuccess(utilisateurSynchro)
                        : new LoadEtatSynchronisationsNoResult()
                ),
                catchError((error: any) => of(new LoadEtatSynchronisationsFail(error))),
            );

        }),
    ));

    public deleteOneReplica$ = createEffect(() => this.action$.pipe(
        ofType<DeleteOneReplica>(EtatSynchronisationsActionType.DELETE_ONE_REPLICA),
        mergeMap((action: DeleteOneReplica) => {
            return this.apiService.delete<UtilisateurSynchro>(
                `${action.path}/pilotage/utilisateurs/actions/supprimer/etat/synchronisation/${action.utilisateurSynchro.replicaID}`).pipe(
                    map((utilisateurSynchro: UtilisateurSynchro) =>
                        new DeleteOneReplicaSuccess(utilisateurSynchro)
                    ),
                    catchError((error: any) => of(new DeleteOneReplicaFail(error))),
                );
        })
    ));
}
