import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import { FilterService } from 'primeng/api';

import {
    LoadUtilisateursExternes,
    CreateOneUtilisateurExterne,
    UpdateOneUtilisateurExterne,
    DeleteOneUtilisateurExterne,
    VerifierFDTIncompletes,
    UtilisateurExterneActionType,
    ReassignEntreprise,
    FinalizeUserCreation,
    LoadEntrepreneurs
} from '../../../../store/actions';
import { getUtilisateursExternesFormatAdresses, getUtilisateursExternesLoading } from '../../../../store/selectors';
import { PilotagesState } from '../../../../store/reducers';
import { ConfirmModalComponent } from '../../../components/modals/confirm-modal/confirm-modal.component';
import { ConfirmModalData } from '../../modals/confirm-modal/confirm-modal-data.model';
import { UtilisateurExterne, Statut, Horaire, Habilitation } from '../../../../models/utilisateur-externe.model';
import { UtilisateurRequest } from '../../../../models/profil-formation-utilisateur.model';
import { TableColumns } from '../../../../../shared/models/table-columns.model';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { GenericOtherMenuItemComponent } from '../../../../../shared/components/generic-other-menu/generic-other-menu-item.model';
import { PilotageFormModalComponent } from '../../modals/pilotage-form-modal/pilotage-form-modal.component';
import { PilotageFormModalData, PilotageFormModalDataFormName } from '../../modals/pilotage-form-modal/pilotage-form-modal-data.model';
import { ProfilFormationUtilisateurModalData } from '../../modals/profil-formation-utilisateur-modal/profil-formation-utilisateur-modal-data.model';
import { ProfilFormationUtilisateurModalComponent } from '../../modals/profil-formation-utilisateur-modal/profil-formation-utilisateur-modal.component';
import { utilisateursExternesColumns } from './utilisateurs-externes-table.column';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-utilisateurs-externes-table',
    templateUrl: './utilisateurs-externes-table.component.html',
    styleUrls: ['./utilisateurs-externes-table.component.scss']
})
export class UtilisateursExternesTableComponent implements OnInit, OnChanges {
    public columns: TableColumns[] = utilisateursExternesColumns;
    public loaderLogoSize = 75;
    public statuses: any[];
    public scheduleStatuses: any[];
    public habilitationStatuses: any[];

    public currentActionButton: GenericOtherMenuItemComponent[];
    public userIncompletItems: GenericOtherMenuItemComponent[];
    public updateUserItems: GenericOtherMenuItemComponent[];
    public updateEntrepriseItems: GenericOtherMenuItemComponent[];
    public userNoCipItems: GenericOtherMenuItemComponent[];

    public selectedUser: UtilisateurExterne;
    public feuillesDeTempsCompletes: boolean;
    public waitingForTimesheetVerification: boolean;

    public selectedUsersFormation: UtilisateurRequest[];
    public defautSelectedUsersFormation: UtilisateurRequest[];
    public selectedUserFormation: UtilisateurRequest;

    public dateFiltersCreeLe: Moment[];
    public dateFiltersModifieLe: Moment[];
    public dateFiltersDateFin: Moment[];
    public menuSelected = false;

    public nbUtilisateurs: {
        value: number;
    };

    private informationButtonValue = 'information';
    private formationButtonValue = 'formation';
    private incompletButtonValue = 'incomplet';
    private entepriseButtonValue = 'enterprise';
    private deleteUserButtonValue = 'delete';

    public utilisateursExternesLoading$: Observable<boolean> = this.store.pipe(
        select(getUtilisateursExternesLoading),
    );

    public utilisateursExternes$: Observable<UtilisateurExterne[] | null> = this.store.pipe(
        select(getUtilisateursExternesFormatAdresses),
        distinctUntilChanged(),
        tap((utilisateurExterne: UtilisateurExterne[]) => {
            this.reloadedDone.emit(true);
            this.prepareData(utilisateurExterne);
        }),
    );

    @Input() public translatePrefix: string;
    @Input() public sortField: string;
    @Input() public sortOrder: number;
    @Input() public data: UtilisateurExterne[];
    @Input() public pathChoice: AlternativePath;
    @Input() public reloadData = false;

    @Output() public selectedModule: EventEmitter<string> = new EventEmitter();
    @Output() public reloadedDone: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('utilisateursExternesDataTable') private utilisateursExternesDataTable: Table;

    constructor(
        public dialog: MatDialog,
        private translateService: TranslateService,
        private readonly store: Store<PilotagesState>,
        private actions$: Actions,
        private filterService: FilterService,
    ) {
        this.filterService.register('horaire', (value: any, filter: any): boolean => {
            return this.customFilter(value, filter, Horaire.aucun);
        });

        this.filterService.register('habilitation', (value: any, filter: any): boolean => {
            return this.customFilter(value, filter, Habilitation.unknown);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.reloadData && changes.reloadData.currentValue) {
            this.store.dispatch(new LoadUtilisateursExternes(this.pathChoice));
        }
    }

    public ngOnInit(): void {
        this.store.dispatch(new LoadUtilisateursExternes(this.pathChoice));
        this.store.dispatch(new LoadEntrepreneurs(this.pathChoice));

        this.selectedModule.emit('ExternalUsers');

        this.filterService.register('dateRangeFilterModifieLe', (value: any): boolean => {
            return this.filtrerDates(value, this.dateFiltersModifieLe);
        });

        this.filterService.register('dateRangeFilterCreeLe', (value: any): boolean => {
            return this.filtrerDates(value, this.dateFiltersCreeLe);
        });

        this.filterService.register('dateRangeFilterDateFin', (value: any): boolean => {
            return this.filtrerDates(value, this.dateFiltersDateFin);
        });

        this.statuses = [
            { label: this.translateService.instant('pilotages.general.status.Active'), value: Statut.active },
            { label: this.translateService.instant('pilotages.general.status.Inactive'), value: Statut.inactive },
            { label: this.translateService.instant('pilotages.general.status.Incomplet'), value: Statut.incomplet },
            { label: this.translateService.instant('pilotages.general.status.Error'), value: Statut.error },
            { label: this.translateService.instant('pilotages.general.status.Redo'), value: Statut.redo },
            { label: this.translateService.instant('pilotages.general.status.AwaitingCreation'), value: Statut.awaitingCreation },
            { label: this.translateService.instant('pilotages.general.status.InReassignment'), value: Statut.inReassignation },
        ];

        this.scheduleStatuses = [
            { label: this.translateService.instant('pilotages.general.horaire.Aucun'), value: Horaire.aucun },
            { label: this.translateService.instant('pilotages.general.horaire.Lundi'), value: Horaire.lundi },
            { label: this.translateService.instant('pilotages.general.horaire.Mardi'), value: Horaire.mardi },
            { label: this.translateService.instant('pilotages.general.horaire.Mercredi'), value: Horaire.mercredi },
            { label: this.translateService.instant('pilotages.general.horaire.Jeudi'), value: Horaire.jeudi },
            { label: this.translateService.instant('pilotages.general.horaire.Vendredi'), value: Horaire.vendredi }
        ];

        this.habilitationStatuses = [
            { label: this.translateService.instant('pilotages.general.habilitation.Autorisé D.25.12'), value: Habilitation.authorized },
            { label: this.translateService.instant('pilotages.general.habilitation.Informé'), value: Habilitation.informed },
            { label: this.translateService.instant('pilotages.general.habilitation.Initié'), value: Habilitation.initiated },
            { label: this.translateService.instant('pilotages.general.habilitation.Habilité'), value: Habilitation.allowed },
            { label: this.translateService.instant('pilotages.general.habilitation.Inconnu'), value: Habilitation.unknown },
        ];

        this.updateUserItems = this.getMenuUserItems();
        this.updateEntrepriseItems = this.getMenuEntrepriseItems();
        this.userIncompletItems = this.getMenuIncomplet();
        this.userNoCipItems = this.getMenuNoCip();

        this.actions$.pipe(ofType(UtilisateurExterneActionType.VERIFY_FDT_INCOMPLETES_SUCCESS)).subscribe((data: any) => {
            if (this.waitingForTimesheetVerification) {
                this.waitingForTimesheetVerification = false;
                this.feuillesDeTempsCompletes = data.fdtIncompletes.is_valid;

                if (this.feuillesDeTempsCompletes) {
                    this.showReassignationEntreprise();
                } else {
                    this.showNotification();
                }
            }
        });
    }

    public prepareData(utilisateurExterne: UtilisateurExterne[]): void {
        this.defautSelectedUsersFormation = this.utilisateurToUtilisateurRequest(utilisateurExterne);
        this.selectedUsersFormation = this.defautSelectedUsersFormation;
        this.nbUtilisateurs = {
            value: utilisateurExterne.length
        };
    }

    public shouldDisplayActionButton(rowData: UtilisateurExterne): boolean {
        if (rowData['statut'] === 'En erreur' || rowData['statut'] === 'En réassignation') {
            return false;
        }

        if (rowData['cip'] === null || rowData['cip'] === '') {
            this.currentActionButton = this.userNoCipItems;
        } else {
            switch (rowData['statut']) {
                case 'Incomplet': this.currentActionButton = this.userIncompletItems; break;
                case 'Actif': this.currentActionButton = this.updateEntrepriseItems; break;
                default: this.currentActionButton = this.updateUserItems;
            }
        }
        return true;
    }

    /***************************************
     * Construction du menu selon le type
     ***************************************/
    public getMenuUserItems(): GenericOtherMenuItemComponent[] {
        return [this.getUserEditButton(), this.getUserFormationButton()];
    }

    public getMenuEntrepriseItems(): GenericOtherMenuItemComponent[] {
        return [this.getReassignButton(), this.getUserEditButton(), this.getUserFormationButton()];
    }

    public getMenuIncomplet(): GenericOtherMenuItemComponent[] {
        return [this.getUserChekButton(), this.getUserEditButton(), this.getUserFormationButton()];
    }

    public getMenuNoCip() {
        return [this.getReassignButton(), this.getUserEditButton(), this.getRemoveButton()];
    }

    /***************************************
     * Retourne un bouton configurer
     ***************************************/
    private getUserEditButton(): GenericOtherMenuItemComponent {
        return {
            icon: 'fa fa-user-edit',
            label: this.translateService.instant(this.translatePrefix + '.menu-labels' + '.mettreAJourInfoSupplementaires'),
            clickValue: this.informationButtonValue,
        } as GenericOtherMenuItemComponent;
    }

    private getUserFormationButton(): GenericOtherMenuItemComponent {
        return {
            icon: 'fa fa-id-badge',
            label: this.translateService.instant(this.translatePrefix + '.menu-labels' + '.afficherProfilFormationUtilisateur'),
            clickValue: this.formationButtonValue,
        } as GenericOtherMenuItemComponent;
    }

    private getRemoveButton(): GenericOtherMenuItemComponent {
        return {
            icon: 'far fa-trash-alt',
            label: this.translateService.instant(this.translatePrefix + '.menu-labels' + '.deleteUser'),
            clickValue: this.deleteUserButtonValue,
            isRed: true,
        } as GenericOtherMenuItemComponent;
    }

    private getUserChekButton(): GenericOtherMenuItemComponent {
        return {
            icon: 'fa fa-user-check',
            label: this.translateService.instant(this.translatePrefix + '.menu-labels' + '.finaliserCreation'),
            clickValue: this.incompletButtonValue,
        } as GenericOtherMenuItemComponent;
    }

    private getReassignButton(): GenericOtherMenuItemComponent {
        return {
            icon: 'far fa-building',
            label: this.translateService.instant(this.translatePrefix + '.menu-labels' + '.reassignerEntreprise'),
            clickValue: this.entepriseButtonValue
        } as GenericOtherMenuItemComponent;
    }

    public menuItemPressed(selectedOption: string, rowdata: UtilisateurExterne) {
        this.setValues(rowdata);

        switch (selectedOption) {
            case this.informationButtonValue: this.showMajUtilisateurExterne(); break;
            case this.formationButtonValue: this.showProfilFormationUtilisateur(); break;
            case this.incompletButtonValue: this.showFinaliserCreationUtilisateur(); break;
            case this.entepriseButtonValue: this.verifierFDTIncompletes(); break;
            case this.deleteUserButtonValue: this.showDeleteUser(rowdata['id'], `${rowdata['prenom']} ${rowdata['nom']}`); break;
        }
    }

    /***************************************
     * Call pour les differents popup
     ***************************************/
    public showDeleteUser(userId: number, userName: string): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.deleteUserDialog' + '.title'),
                message: this.translateService.instant(this.translatePrefix + '.deleteUserDialog' + '.message'),
                subMessage: userName,
                isSubMessageBold: true,
                question: this.translateService.instant(this.translatePrefix + '.deleteUserDialog' + '.question'),
                translatePrefix: this.translatePrefix + '.boutons',
                logoString: 'far fa-trash-alt',
                isForDelete: true,
            } as ConfirmModalData,
            width: '430px',
            height: 'fit-content'
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.store.dispatch(new DeleteOneUtilisateurExterne(userId, this.pathChoice));
            }
        });
    }

    public verifierFDTIncompletes(): void {
        this.waitingForTimesheetVerification = true;
        this.store.dispatch(new VerifierFDTIncompletes(this.selectedUser.id, AlternativePath.hq));
    }

    public showNotification(): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.feuillesDeTempsIncompletesDialog' + '.title'),
                message: this.translateService.instant(this.translatePrefix + '.feuillesDeTempsIncompletesDialog' + '.message'),
                translatePrefix: this.translatePrefix + '.boutons',
                logoString: 'fas fa-exclamation',
            } as ConfirmModalData,
            width: '430px',
            height: 'fit-content'
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.showReassignationEntreprise();
            }
        });
    }

    public showCreateNewUser(): void {
        this.showMajUtilisateurExterne(true);
    }

    public showMajUtilisateurExterne(isAddMode = false): void {
        const dialogRef = this.dialog.open(PilotageFormModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.majUtilisateurExterneForm' + (isAddMode ? '.addTitle' : '.title')),
                entity: isAddMode ? null : this.selectedUser,
                translatePrefix: this.translatePrefix + '.majUtilisateurExterneForm',
                logoString: isAddMode ? 'fas fa-plus' : 'fa fa-user-edit',
                formName: PilotageFormModalDataFormName.utilisateurExterne,
                isAddMode: isAddMode
            } as PilotageFormModalData,
            minWidth: 'fit-content',
            width: '60%'
        });
        dialogRef.afterClosed().subscribe((result: UtilisateurExterne) => {
            if (result) {
                this.store.dispatch(
                    isAddMode
                        ? new CreateOneUtilisateurExterne(result, AlternativePath.hq)
                        : new UpdateOneUtilisateurExterne(result, AlternativePath.hq)
                );
            }
        });
    }

    public showReassignationEntreprise(): void {
        const dialogRef = this.dialog.open(PilotageFormModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.reassignEntrepriseForm' + '.title'),
                message: this.translateService.instant(this.translatePrefix + '.reassignEntrepriseForm' + '.message'),
                entity: this.selectedUser,
                translatePrefix: this.translatePrefix + '.reassignEntrepriseForm',
                logoString: 'fa fa-briefcase',
                formName: PilotageFormModalDataFormName.reassignEntreprise,
            } as PilotageFormModalData,
            minWidth: 'fit-content',
            width: '20%'
        });
        dialogRef.afterClosed().subscribe((result: UtilisateurExterne) => {
            if (result) {
                this.store.dispatch(new ReassignEntreprise(result, AlternativePath.hq));
            }
        });
    }

    public showFinaliserCreationUtilisateur(): void {
        const dialogRef = this.dialog.open(PilotageFormModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.finaliseUserCreationForm' + '.title'),
                message: this.translateService.instant(this.translatePrefix + '.finaliseUserCreationForm' + '.message'),
                entity: this.selectedUser,
                translatePrefix: this.translatePrefix + '.finaliseUserCreationForm',
                logoString: 'fa fa-user-check',
                formName: PilotageFormModalDataFormName.reassignEntreprise,
            } as PilotageFormModalData,
            minWidth: 'fit-content',
            width: '20%'
        });
        dialogRef.afterClosed().subscribe((result: UtilisateurExterne) => {
            if (result) {
                this.store.dispatch(new FinalizeUserCreation(result, AlternativePath.hq));
            }
        });
    }

    public showProfilFormationUtilisateur(): void {
        this.dialog.open(ProfilFormationUtilisateurModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.profilFormationUtilisateurModal' + '.title'),
                utilisateurs: [this.selectedUserFormation],
                translatePrefix: this.translatePrefix + '.profilFormationUtilisateurModal',
                logoString: 'fa fa-id-badge',
            } as ProfilFormationUtilisateurModalData,
            minWidth: 'fit-content',
            width: '20%',
        });
    }

    public showProfilsFormationPlusieursUtilisateurs() {
        this.dialog.open(ProfilFormationUtilisateurModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.profilFormationUtilisateurModal' + '.title'),
                utilisateurs: this.selectedUsersFormation,
                translatePrefix: this.translatePrefix + '.profilFormationUtilisateurModal',
                logoString: 'fa fa-id-badge',
            } as ProfilFormationUtilisateurModalData,
            minWidth: 'fit-content',
            width: '20%',
        });
    }

    /***************************************
     * Filtres
     ***************************************/
    public customFilter(value: string, filter: any, valueComparaison: string): boolean {
        if (filter === undefined || filter === null || filter.value === null) {
            return true;
        }

        if (filter.value === valueComparaison) {
            if (value === undefined || value === null || value === valueComparaison || value.trim() === '') {
                return true;
            } else {
                return false;
            }
        } else {
            if (value === undefined || value === null) {
                return false;
            }
        }

        return value.toString() === filter.value.toString();
    }

    public filtrerDates(value: any, dateFilters: Moment[]): boolean {
        if (dateFilters) {
            const tableValue = moment(value);
            const firstFilterDate = moment(dateFilters[0]);
            const secondFilterDate = moment(dateFilters[1]);

            if (tableValue.isValid() && firstFilterDate.isValid()) {
                if (secondFilterDate.isValid()) {
                    return tableValue.isSameOrAfter(firstFilterDate, 'day') && tableValue.isSameOrBefore(secondFilterDate, 'day');
                } else {
                    return tableValue.isSame(firstFilterDate, 'day');
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public saveFilteredValues(dt: any): void {
        if (dt.filteredValue) {
            if (dt.filteredValue.length > 0) {
                this.selectedUsersFormation = this.utilisateurToUtilisateurRequest(dt.filteredValue);
            } else {
                this.selectedUsersFormation = [];
            }
        } else {
            this.selectedUsersFormation = this.defautSelectedUsersFormation;
        }
        this.nbUtilisateurs = {
            value: this.selectedUsersFormation.length
        };
    }

    /***************************************
     * Préparation des données formation
     ***************************************/
    public setValues(rowdata: UtilisateurExterne): void {
        this.selectedUser = rowdata;
        this.selectedUserFormation = {
            cii: rowdata.cip ? rowdata.cip.toUpperCase() : rowdata.cip,
            nom: rowdata.nom,
            prenom: rowdata.prenom
        };
    }

    public utilisateurToUtilisateurRequest(utilisateursList: UtilisateurExterne[]): UtilisateurRequest[] {
        return utilisateursList.map(function (a: UtilisateurExterne) {
            if (a.cip) {
                return {
                    nom: a.nom,
                    prenom: a.prenom,
                    cii: a.cip.toUpperCase(),
                } as UtilisateurRequest;
            } else {
                return {
                    nom: a.nom,
                    prenom: a.prenom,
                    cii: a.cip,
                } as UtilisateurRequest;
            }
        });
    }

    public inputFilterColumn(value: Event, field: string, filterMatchMode?: string): void {
        this.utilisateursExternesDataTable.filter((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains');
    }

    public inputGlobalFilter(value: Event, filterMatchMode?: string): void {
        this.utilisateursExternesDataTable.filterGlobal((value.target as HTMLInputElement).value, filterMatchMode || 'contains');
    }
}
