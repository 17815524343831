import { Params } from '@angular/router';

import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { BillingState, getBillingState } from '../reducers';
import { billingReportsAdapter, BillingReportsState } from '../reducers/billing-report.reducer';
import { BillingReport } from '../../../shared/models/database/billing-report.model';
import { getRouterParams } from '../../../core/store/selectors';

const {
    selectAll,
    selectEntities,
    selectIds,
} = billingReportsAdapter.getSelectors();

export const getBillingReportsState = createSelector(
    getBillingState,
    (state: BillingState) => state.billingReports,
);

export const getBillingReportsLoading = createSelector(
    getBillingReportsState,
    (state: BillingReportsState) => state.loading,
);

export const getBillingReportsSaving = createSelector(
    getBillingReportsState,
    (state: BillingReportsState) => state.saving,
);

export const getBillingReportsLoaded = createSelector(
    getBillingReportsState,
    (state: BillingReportsState) => state.loaded,
);

export const shouldLoadBillingReports = createSelector(
    getBillingReportsLoading,
    getBillingReportsLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllBillingReports = createSelector(
    getBillingReportsState,
    selectAll
);

export const getBillingReportsEntities = createSelector(
    getBillingReportsState,
    selectEntities,
);

export const getBillingReportsIds = createSelector(
    getBillingReportsState,
    selectIds,
);

export const shouldLoadGivenBillingReport = createSelector(
    getBillingReportsLoading,
    (loading: boolean) => !loading
);

export const getBillingReportById = (prop: any) => createSelector(
    getBillingReportsEntities,
    (entities: Dictionary<BillingReport>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getCurrentBillingReport = createSelector(
    getRouterParams,
    getBillingReportsEntities,
    (params: Params, entities: Dictionary<BillingReport>) => {
        return entities && params.billingReportId && entities[params.billingReportId] || null;
    }
);
