import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { TimesheetCommandePosteType } from '../../models/timesheet-commande-poste-type.model';
import { TimesheetsCommandePosteTypeAction, TimesheetsCommandePosteTypeActionType } from '../actions';

export interface TimesheetCommandePosteTypeState extends EntityState<TimesheetCommandePosteType> {
    loading: boolean;
    loaded: boolean;
}

export const timesheetCommandePosteTypeAdapter: EntityAdapter<TimesheetCommandePosteType> = createEntityAdapter<TimesheetCommandePosteType>({
    selectId: (timesheetCommandePosteType: TimesheetCommandePosteType) => JSON.stringify(timesheetCommandePosteType)
});

export const timesheetCommandePosteTypeInitialState: TimesheetCommandePosteTypeState = timesheetCommandePosteTypeAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function timesheetCommandePosteTypeReducer(
    state: TimesheetCommandePosteTypeState = timesheetCommandePosteTypeInitialState,
    action: TimesheetsCommandePosteTypeAction,
): TimesheetCommandePosteTypeState {
    switch (action.type) {
        case TimesheetsCommandePosteTypeActionType.UPDATE_ONE_TIMESHEETS_COMMANDE_POSTE_TYPE:
        case TimesheetsCommandePosteTypeActionType.LOAD_TIMESHEETS_COMMANDE_POSTE_TYPE: {
            return {
                ...state,
                loading: true,
            };
        }
        case TimesheetsCommandePosteTypeActionType.UPDATE_ONE_TIMESHEETS_COMMANDE_POSTE_TYPE_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case TimesheetsCommandePosteTypeActionType.UPDATE_ALL_TIMESHEETS_COMMANDE_POSTE_TYPE_SUCCESS:
        case TimesheetsCommandePosteTypeActionType.LOAD_TIMESHEETS_COMMANDE_POSTE_TYPE_SUCCESS: {
            return timesheetCommandePosteTypeAdapter.setAll(action.timesheetsCommandePosteType, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case TimesheetsCommandePosteTypeActionType.LOAD_TIMESHEETS_COMMANDE_POSTE_TYPE_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
