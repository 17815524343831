export class Entrepreneur {
    id: number;
    nom: string;
    abreviation: string;
    adresse: string;
    statut: EntrepreneurStatus;
    responsable: string;
    telephone: string;
}

export enum EntrepreneurStatus {
    loadingCreation = 'En attente de création',
    loadingDeactivation = 'En attente de désactivation',
    awaitDelete = 'En attente de suppression',
    active = 'Actif',
    inactive = 'Inactif',
    error = 'En erreur',
    incomplet = 'Incomplet',
    all = '',
}
