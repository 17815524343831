import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PilotageFormModalData, PilotageFormModalDataFormName } from './pilotage-form-modal-data.model';

@Component({
    selector: 'app-pilotage-form-modal',
    templateUrl: './pilotage-form-modal.component.html',
    styleUrls: ['./pilotage-form-modal.component.scss']
})
export class PilotageFormModalComponent {
    public formNameEntrepreneur = PilotageFormModalDataFormName.entrepreneur;
    public formNameReassignEntreprise = PilotageFormModalDataFormName.reassignEntreprise;
    public formNameUtilisateurExterne = PilotageFormModalDataFormName.utilisateurExterne;
    public formNameUtilisateurInterne = PilotageFormModalDataFormName.utilisateurInterne;
    public formNameTableCodesUnite = PilotageFormModalDataFormName.tableCodesUnite;
    public formNameEditTimesheetChargeNumber = PilotageFormModalDataFormName.editTimesheetChargeNumber;
    public formNameEditTimesheetCommandePosteType = PilotageFormModalDataFormName.editTimesheetCommandePosteType;

    public title: string;
    public translatePrefix: string;
    public formName: string;
    public logoString: string | null;
    public message: string | null;
    public entity: any | null;
    public entityArray: any | null;
    public isAddMode: boolean;

    constructor(
        public dialogRef: MatDialogRef<PilotageFormModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PilotageFormModalData
    ) {
        this.title = data.title;
        this.translatePrefix = data.translatePrefix;
        this.formName = data.formName;
        this.logoString = data.logoString || null;
        this.message = data.message || null;
        this.entity = data.entity || null;
        this.entityArray = data.entityArray || null;
        this.isAddMode = data.isAddMode || false;
    }

    public onClose(): void {
        this.dialogRef.close();
    }

    public onCreate(event: any): void {
        this.dialogRef.close(event);
    }

    public onUpdate(event: any): void {
        this.dialogRef.close(event);
    }

}
