import { TableColumns } from '../../../../shared/models/table-columns.model';

export const OPERATION_COLS: TableColumns[] = [
    { field: 'numeroOperation', header: 'Operation_Numero', width: '100px' },
    { field: 'statutSysteme', header: 'Operation_StatutSysteme', width: '100px' },
    { field: 'workCenter', header: 'Operation_WorkCenter', width: '100px' },
    { field: 'clefReference', header: 'Operation_ClefReference', width: '110px' },
    { field: 'designation', header: 'Operation_Designation', width: '200px' },
    { field: 'matricule', header: 'Operation_Matricule', width: '130px' },
    {
        field: 'dateStatutLance', header: 'Operation_DateStatutLance', width: '120px',
        type: 'date', format: 'yyyy/MM/dd'
    },
    { field: 'statutUtilisateur', header: 'Operation_StatutUtilisateur', width: '120px' },
];
