import { AbstractControl, ValidatorFn } from '@angular/forms';

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: AbstractControl) {
        let checked = 0;

        Object.keys(formGroup['controls']).forEach(key => {
            const control = formGroup['controls'][key];

            if (control.value === true) {
                checked++;
            }
        });

        if (checked < minRequired) {
            return {
                requireCheckboxesToBeChecked: true,
            };
        }

        return null;
    };
}
