import { etatSynchronisationsAdapter, EtatSynchronisationsState } from '../reducers/etat-synchronisations.reducer';
import { createSelector } from '@ngrx/store';
import { PilotagesState, getPilotagesState } from '../reducers';
import { Dictionary } from '@ngrx/entity';
import { UtilisateurSynchro } from '../../models/utilisateur-synchronisation.model';

const {
    selectAll,
    selectEntities,
    selectIds,
} = etatSynchronisationsAdapter.getSelectors();

export const getEtatSynchronisationsState = createSelector(
    getPilotagesState,
    (state: PilotagesState) => state.utilisateursSynchro,
);

export const getEtatSynchronisationsLoading = createSelector(
    getEtatSynchronisationsState,
    (state: EtatSynchronisationsState) => state.loading,
);

export const getEtatSynchronisationsLoaded = createSelector(
    getEtatSynchronisationsState,
    (state: EtatSynchronisationsState) => state.loaded,
);

export const shouldLoadEtatSynchronisations = createSelector(
    getEtatSynchronisationsLoading,
    getEtatSynchronisationsLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllEtatSynchronisations = createSelector(
    getEtatSynchronisationsState,
    selectAll
);

export const getAllFetchedEtatSynchronisations = createSelector(
    getAllEtatSynchronisations,
    (utilisateursSynchro: UtilisateurSynchro[]) => utilisateursSynchro.map((utilisateurSynchro: UtilisateurSynchro) => utilisateurSynchro),
);

export const getEtatSynchronisationsEntities = createSelector(
    getEtatSynchronisationsState,
    selectEntities
);

export const getEtatSynchronisationsIds = createSelector(
    getEtatSynchronisationsState,
    selectIds,
);

export const getEtatSynchronisationsById = (prop: any) => createSelector(
    getEtatSynchronisationsEntities,
    (entities: Dictionary<UtilisateurSynchro>) => {
        return entities && entities[prop.entityId] || null;
    }
);
