import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { ConfirmationService } from 'primeng/api';

import { CommonUiModule } from '@ngxhq/common-ui';
import { ThemeHqCommonUiSupportModule } from '@ngxhq/theme-ngxhq';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '../shared/shared.module';
import { DemandesClientRoutingModule } from './demandes-client-routing.module';
import { demandesClientVendorsModules } from './demandes-client-vendors-modules';
import { DEMANDES_CLIENT_TOKEN, getDemandesClientReducers } from './store';
import { demandesClientStoreEffects } from './store/effects';
import { DemandesClientComponent } from './components/demandes-client.component';
import { demandesClientComponents } from './';
import { getPilotagesReducers, PILOTAGES_TOKEN } from '../pilotages/store';
import { pilotagesStoreEffects } from '../pilotages/store/effects';
import { DemandesClientEngagementFormComponent } from './modules/components/demandes-client-engagement-form/demandes-client-engagement-form.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CommonUiModule,
        SharedModule,
        ThemeHqCommonUiSupportModule,
        TranslateModule,
        ReactiveFormsModule,
        // Vendors
        ...demandesClientVendorsModules,
        // Routing
        DemandesClientRoutingModule,
        // Store
        StoreModule.forFeature('demandesClient', DEMANDES_CLIENT_TOKEN),
        EffectsModule.forFeature(demandesClientStoreEffects),
        StoreModule.forFeature('pilotages', PILOTAGES_TOKEN),
        EffectsModule.forFeature(pilotagesStoreEffects),
    ],
    declarations: [
        DemandesClientComponent,
        ...demandesClientComponents,
        DemandesClientEngagementFormComponent,
    ],
    providers: [
        {
            provide: DEMANDES_CLIENT_TOKEN,
            useFactory: getDemandesClientReducers,
        },
        {
            provide: PILOTAGES_TOKEN,
            useFactory: getPilotagesReducers,
        },
        ConfirmationService
    ]
})
export class DemandesClientModule { }
