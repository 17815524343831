import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { OgivCoreState, getOgivCoreState } from '../reducers';
import { mealCodesAdapter, MealCodesState } from '../reducers/meal-codes.reducer';
import { MealCode, MealCodeStatus } from '../../../shared/models/database/meal-code.model';
import { SelectItem } from 'primeng/api';
import moment from 'moment';

const {
    selectAll,
    selectEntities,
    selectIds,
} = mealCodesAdapter.getSelectors();

export const getMealCodesState = createSelector(
    getOgivCoreState,
    (state: OgivCoreState) => state.mealCodes,
);

export const getMealCodesLoading = createSelector(
    getMealCodesState,
    (state: MealCodesState) => state.loading,
);

export const getMealCodesLoaded = createSelector(
    getMealCodesState,
    (state: MealCodesState) => state.loaded,
);

export const shouldLoadMealCodes = createSelector(
    getMealCodesLoading,
    getMealCodesLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllMealCodes = createSelector(
    getMealCodesState,
    selectAll
);

export const getMealCodesEntities = createSelector(
    getMealCodesState,
    selectEntities,
);

export const getMealCodesIds = createSelector(
    getMealCodesState,
    selectIds,
);

export const getMealCodeById = (prop: { entityId: number }) => createSelector(
    getMealCodesEntities,
    (entities: Dictionary<MealCode>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllMealCodesSorted = createSelector(
    getAllMealCodes,
    (mealCodes: MealCode[]): MealCode[] => {
        const temporaire = [...mealCodes];
        temporaire.sort((a: MealCode, b: MealCode): number => {
            return (a.code.toLocaleLowerCase()).localeCompare(b.code.toLocaleLowerCase());
        });
        return temporaire;
    }
);

export const getActiveMealCodes = (props: { date: string } = { date: '' }) => createSelector(
    getAllMealCodesSorted,
    (mealCodes: MealCode[]): MealCode[] => {
        return getAllActiveMealCodes(mealCodes, props.date);
    }
);

export const doesSelectedMealCodeIsActive = (props: { code: string } = { code: '' }) => createSelector(
    getActiveMealCodes(),
    (mealCodes: MealCode[]): boolean => {
        const found = (mealCodes.filter((mealCode: MealCode) => mealCode.code === props.code));
        return found.length > 0;
    }
);

export const getActiveMealCodesOptions = (props: { date: string } = { date: '' }) => createSelector(
    getAllMealCodesSorted,
    (mealCodes: MealCode[]): SelectItem[] => {
        const mealCodesOptions = getAllActiveMealCodes(mealCodes, props.date);
        return mealCodesOptions.map((mealCode: MealCode) => {
            return {
                label: `(${mealCode.code}) ${mealCode.description}`,
                value: mealCode.code,
            } as SelectItem;
        });
    }
);

function getAllActiveMealCodes(codes: MealCode[], date: string): MealCode[] {
    let activeCodes;
    if (date === undefined || date === '') {
        activeCodes = codes.filter(code => code.status.toLocaleLowerCase() === MealCodeStatus.active.toLocaleLowerCase());
    } else {
        activeCodes = codes.filter(code => code.status.toLocaleLowerCase() === MealCodeStatus.active.toLocaleLowerCase()
            || moment(code.date_inactif || '1900-01-01').format('YYYYMMDD') > date);
    }
    return activeCodes;
}
