<div class="remarkCard">
    <div class="remarkCard__header">
        <div class="header__title">{{title}}</div>
        <div *ngIf="status !== null" class="header__status">
            <app-tag [status]="status">
                {{ status | translate }}
            </app-tag>
        </div>
    </div>
    <div class="remarkCard__remark">{{remark}}</div>
    <div *ngIf="displayFooter()" class="remarkCard__footer">
        <div *ngIf="displaySiteName" class="footer__site">{{ translationPrefix + '.site' | translate }}: {{ siteName }}</div>
        <div *ngIf="formatedDate !== ''" class="footer__date">{{ formatedDate }}</div>
        <div *ngIf="name !== null" class="footer__name">{{ name }}</div>
    </div>
</div>
