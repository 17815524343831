<div class="timesheetDetail">
    <div *ngIf="timesheet$ | async as timesheet" class="timesheetDetail__accordion">
        <app-timesheet-status-bar [pathChoice]="pathChoice" [orderGroups]="timesheet.orderGroups || null" [numberOfGroup]="(timesheet.orderGroups && timesheet.orderGroups.length) || 0" [editAllowedRoles]="editAllowedRoles"></app-timesheet-status-bar>
        <app-order-section [pathChoice]="pathChoice" [timesheet]="timesheet" [editAllowedRoles]="editAllowedRoles"></app-order-section>
        <ng-container *hasRole="editAllowedRoles">
            <div class="timesheetDetail__notFound">
                <div *ngIf="timesheet !== null && !displayOrders(timesheet) && (timesheetLoaded$ | async)" class="notFound__text">
                    {{ 'timesheet.noOrders.notOrderText' | translate }}
                </div>
                <div class="notFound__button">
                    <button type="submit" mat-flat-button class="button__createOrder" (click)="createOrder()">
                        {{ 'timesheet.noOrders.noOrderButtonTitle' | translate | uppercase }}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
