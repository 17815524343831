import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, switchMap, map, mergeMap } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { ApiService } from '../../../ogiv-core/services/api.service';
import {
    JobClassesActionTypes,
    LoadJobClasses,
    LoadJobClassesSuccess,
    LoadJobClassesFail,
    LoadJobClassesNoResult,
    CreateJobClass,
    CreateJobClassSuccess,
    CreateJobClassFail,
} from '../actions/job-classes.action';
import { JobClass } from '../../../shared/models/database/job-class.model';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class JobClassesEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService,
    ) { }

    /*************************/
    /*******  LOADING  *******/
    /*************************/
    public loadJobClass$ = createEffect(() => this.action$.pipe(
        ofType<LoadJobClasses>(JobClassesActionTypes.LOAD_JOB_CLASSES),
        switchMap((action: LoadJobClasses) => {
            return this.apiService.get<JobClass[]>(`${action.path}/fdt/data/jobClasses`).pipe(
                map((returnedJobClass: JobClass[]) =>
                    (returnedJobClass.length > 0)
                        ? new LoadJobClassesSuccess(returnedJobClass)
                        : new LoadJobClassesNoResult()
                ),
                catchError((error: any) => of(new LoadJobClassesFail(error))),
            );
        }),
    ));

    /************************/
    /*******  CREATE  *******/
    /************************/
    public createJobClass$ = createEffect(() => this.action$.pipe(
        ofType<CreateJobClass>(JobClassesActionTypes.CREATE_JOB_CLASS),
        mergeMap((action: CreateJobClass) => {
            return this.apiService.post<JobClass[], JobClass>(`${action.path}/fdt/data/jobClass`, action.jobClass).pipe(
                map((returnedJobClasses: JobClass[]) => new CreateJobClassSuccess(returnedJobClasses)),
                catchError((error: any) => of(new CreateJobClassFail(error))),
            );
        }),
    ));

    public createJobClassSuccess$ = createEffect(() => this.action$.pipe(
        ofType<CreateJobClassSuccess>(JobClassesActionTypes.CREATE_JOB_CLASS_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.jobClass.create.success',
            }))
        ]
        ),
    ));

    public createJobClassFail$ = createEffect(() => this.action$.pipe(
        ofType<CreateJobClassFail>(JobClassesActionTypes.CREATE_JOB_CLASS_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.jobClass.create.error',
            }))
        ]),
    ));
}
