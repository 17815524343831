import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { BillingReportPreviewRow } from '../../../shared/models/database/billing-report-preview.model';
import { BillingReportPreviewAction, BillingReportPreviewActionType } from '../actions/billing-report-preview.action';

export interface BillingReportPreviewState extends EntityState<BillingReportPreviewRow> {
    loading: boolean;
    loaded: boolean;
}

export const billingReportPreviewAdapter: EntityAdapter<BillingReportPreviewRow> = createEntityAdapter<BillingReportPreviewRow>({
    selectId: (billingReportPreviewRow: BillingReportPreviewRow) => billingReportPreviewRow.rowId
});

export const billingReportPreviewInitialState = billingReportPreviewAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function billingReportPreviewReducer(
    state: BillingReportPreviewState = billingReportPreviewInitialState,
    action: BillingReportPreviewAction,
): BillingReportPreviewState {
    switch (action.type) {
        case BillingReportPreviewActionType.LOAD_BILLING_REPORT_PREVIEW: {
            return {
                ...state,
                loading: true,
            };
        }

        case BillingReportPreviewActionType.LOAD_BILLING_REPORT_PREVIEW_SUCCESS: {
            return billingReportPreviewAdapter.setAll(action.billingReportPreviewRows, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case BillingReportPreviewActionType.LOAD_BILLING_REPORT_PREVIEW_FAIL: {
            return billingReportPreviewAdapter.setAll([], {
                ...state,
                loading: false,
                loaded: false,
            });
        }

        default:
            return state;
    }
}
