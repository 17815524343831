import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { CommonUiModule } from '@ngxhq/common-ui';
import { ThemeHqCommonUiSupportModule } from '@ngxhq/theme-ngxhq';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { SharedModule } from '../shared/shared.module';
import { PilotagesRoutingModule } from './pilotages-routing.module';
import { pilotagesVendorsModules } from './pilotages-vendors-modules';
import { PILOTAGES_TOKEN, getPilotagesReducers } from './store';
import { pilotagesStoreEffects } from './store/effects';
import { pilotagesComponents } from './';

export const options: Partial<IConfig> | (() => Partial<IConfig>) | null = null;

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CommonUiModule,
        SharedModule,
        ThemeHqCommonUiSupportModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        // Vendors
        ...pilotagesVendorsModules,
        // Routing
        PilotagesRoutingModule,
        // Store
        StoreModule.forFeature('pilotages', PILOTAGES_TOKEN),
        EffectsModule.forFeature(pilotagesStoreEffects),
        NgxMaskModule.forRoot(),
    ],
    declarations: [
        ...pilotagesComponents
    ],
    providers: [
        {
            provide: PILOTAGES_TOKEN,
            useFactory: getPilotagesReducers,
        }
    ]
})
export class PilotagesModule {
}
