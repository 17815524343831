<div mat-dialog-title class="dialog__title">
    <button mat-dialog-close pButton icon="fas fa-times" class="close-button" (click)="onExit()"></button>
    <h1>{{ translatePrefix + 'title' | translate:{ value: demandeClientSelectionnees.length } }}</h1>
</div>

<div mat-dialog-content>
    <p-panel *ngIf="!showResults">
        <div class="p-grid">
            <div class="p-col">
                <div class="item">
                    <label class="dropdown-label">{{ commonTranslatePrefix + 'StatutOGIV' | translate }}</label>
                    <p-dropdown [options]="statutOGIVSelectOptions" [(ngModel)]="selectedStatutOGIV" appendTo="body"></p-dropdown>
                </div>
                <div class="item">
                    <label class="dropdown-label">{{ commonTranslatePrefix + 'AssigneOGIV' | translate }}</label>
                    <p-dropdown [options]="assigneOGIVSelectOptions" [(ngModel)]="selectedAssigneOGIV" appendTo="body"></p-dropdown>
                </div>
                <div class="item">
                    <label class="dropdown-label">{{ commonTranslatePrefix + 'Notes' | translate }}</label>
                    <p-dropdown [options]="$any(noteSelectOptions$ | async)" [(ngModel)]="selectedNotes" appendTo="body"></p-dropdown>
                </div>
                <div class="item">
                    <label class="dropdown-label">{{ commonTranslatePrefix + 'ContactTechnicien' | translate }}</label>
                    <input type="text" pInputText [(ngModel)]="selectedContactTechnicien" appendTo="body">
                </div>
            </div>
            <div class="p-col">
                <app-demandes-client-engagement-form
                    [parentFormIsEditing]="engagementIsUpdatable()"
                    [demandesClient]="demandeClientSelectionnees"
                    [currentUser]="currentUser"
                    (engagementChange)="onEngagementChange($event)"
                    (newEngagementClickChange)="onNewEngagementClickChange($event)"
                    (engagementValidityChange)="onEngagementValidityChange($event)">
                </app-demandes-client-engagement-form>
                <div class="engagement-error-message">
                    <small class="error-message" *ngIf="!engagementIsUpdatable()">
                        {{ commonTranslatePrefix + 'engagementNotUpdatable' | translate }}
                    </small>
                </div>
            </div>
        </div>
    </p-panel>
    <p-panel *ngIf="showResults">
        <div class="results">
            <div>
                <h2>{{ translatePrefix + 'results' | translate }}</h2>
                <p>{{ translatePrefix + 'successCount' | translate }} : {{results?.successCount || ''}}</p>
                <p *ngIf="results && results.failedUpdates.length > 0 ">{{ translatePrefix + 'failedCount' | translate }} :
                    {{results.failedUpdates.length}}</p>
                <ul *ngIf="results && results.failedUpdates.length > 0">
                    <ng-template ngFor let-failedUpdate [ngForOf]="results.failedUpdates" let-i="index">
                        <li>{{formatErrors(failedUpdate)}}</li>
                    </ng-template>
                </ul>
            </div>
        </div>
    </p-panel>
</div>

<div mat-dialog-actions>
    <section class="actions">
        <div class="actions__spacer"></div>
        <button *ngIf="!showResults" pButton icon="fa fa-cogs" class="actions__button right" label="Soumettre"
            (click)="submit()"
            [disabled]="selectedStatutOGIV === null && selectedAssigneOGIV === null && selectedNotes === null && selectedContactTechnicien === null && ((!engagementIsValid && isNewEngagementClicked) || !isNewEngagementClicked)"></button>
        <button *ngIf="showResults" pButton icon="fa fa-times" class="actions__button right" label="Fermer"
            (click)="onExit()"></button>
    </section>
</div>


<app-element-loader id="spinner" *ngIf="getDemandesClientBatchUpdatesProcessing$ | async"></app-element-loader>
