import { PostLine } from './post-line.model';
import { Error } from './error.model';
import { OrderType } from './order-type.enum';

export class PostLineGroupRawData {
    public chargeNumber: string;
    public powerOutageNumber?: string | null;
    public postLines?: PostLine[] | null;
}
export class PostLineGroup {
    public chargeNumber: string;
    public powerOutageNumber?: string | null;
    public postLines?: PostLine[] | null;

    constructor(data: PostLineGroupRawData) {
        Object.keys(data).forEach((key: string) => {
            this[key] = data[key];
        });
    }

    public validate(validateChildren: boolean = false, orderType: OrderType): Error | null {
        let errors: Error | null = null;

        if (validateChildren) {
            errors = this.validateChildren(validateChildren, orderType);
        }

        return errors;
    }

    private validateChildren(validateChildren: boolean = false, orderType: OrderType): Error | null {
        let errors: Error | null = null;
        const errorPostLines: Error[] = [];

        this.postLines?.forEach((postLine: PostLine) => {
            const err = new PostLine(postLine).validate(validateChildren, orderType);
            if (err !== null) {
                errorPostLines.push(err);
            }
        });

        if (errorPostLines.length > 0) {
            errors = { name: this.chargeNumber, list: errorPostLines, last: false };
        }

        return errors;
    }
}
