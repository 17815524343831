import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

import moment from 'moment';
import { ReportFiltersLocalStorage } from '../../../../../../shared/models/database/report-filters-local-storage.model';
import { ReportFiltersRaw } from '../../../../../../shared/models/report-filters.model';
import { StatusReportFilters } from '../../../../../../shared/models/status-report-filters.model';
import { TableColumns } from '../../../../../../shared/models/table-columns.model';

@Component({
    selector: 'app-report-filters',
    templateUrl: './report-filters.component.html',
    styleUrls: ['./report-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportFiltersComponent implements OnChanges, OnInit {
    public filterForm: FormGroup;
    public panelOpenState: boolean;
    public currentStatuses: StatusReportFilters[];
    public originalStatusesState: StatusReportFilters[];
    public currentPartielStatuses?: StatusReportFilters[];
    public originalPartielStatusesState: StatusReportFilters[];
    public hasLocalStorageValue = false;
    private reportFilterString: string | null;

    @Input() public localStorageName: string;
    @Input() public statuses: StatusReportFilters[];
    @Input() public partialStatuses?: StatusReportFilters[];
    @Input() public searchColumn: TableColumns[];
    @Input() public columnTranslatePrefix: string;

    @Output() public filter: EventEmitter<ReportFiltersRaw> = new EventEmitter();

    constructor(
        formBuilder: FormBuilder
    ) {
        this.filterForm = formBuilder.group({
            week: [],
            statuses: new FormArray([]),
            partialStatuses: new FormArray([]),
            columnName: [''],
            searchValue: [''],
        });
    }

    public ngOnInit(): void {
        const reportFilterJson: ReportFiltersLocalStorage | null = this.reportFilterString ? JSON.parse(this.reportFilterString) as ReportFiltersLocalStorage : null;

        if (reportFilterJson) {
            this.fetchFilters(reportFilterJson);
        }
    }

    public formatStatusesForSave(): StatusReportFilters[] {
        return this.currentStatuses.map((status, index) => {
            status.checked = this.filterForm.value.statuses[index];
            return status;
        });
    }

    public formatPartialStatusesForSave(): StatusReportFilters[] {
        if (this.currentPartielStatuses) {
            return this.currentPartielStatuses.map((partialStatus, index) => {
                partialStatus.checked = this.filterForm.value.partialStatuses[index];
                return partialStatus;
            });
        }

        return [];
    }

    public get statusesFormArray(): FormArray {
        return this.filterForm.controls.statuses as FormArray;
    }

    public get partialStatusesFormArray(): FormArray {
        return this.filterForm.controls.partialStatuses as FormArray;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        let localPanelOpenState = true;
        if ((changes && changes.statuses && changes.statuses.currentValue) ||
            (changes && changes.partialStatuses && changes.partialStatuses.currentValue)) {
            this.reportFilterString = localStorage.getItem(`ogic_fac_reportFilters${this.localStorageName}`);
            this.hasLocalStorageValue = this.reportFilterString !== null;
        }

        if (changes && changes.statuses && changes.statuses.currentValue) {
            this.originalStatusesState = changes.statuses.currentValue;
            if (!this.hasLocalStorageValue) {
                this.currentStatuses = changes.statuses.currentValue;
                this.fetchStatuses();
                this.applyFilters();
                localPanelOpenState = false;
            }
        }

        if (changes && changes.partialStatuses && changes.partialStatuses.currentValue) {
            this.originalPartielStatusesState = changes.partialStatuses.currentValue;
            if (!this.hasLocalStorageValue) {
                this.currentPartielStatuses = changes.partialStatuses.currentValue;
                this.fetchPartialStatuses();
                this.applyFilters();
                localPanelOpenState = false;
            }
        }

        this.panelOpenState = localPanelOpenState;
    }

    public changeSelectedStatuses(event: MatCheckboxChange, index: number): void {
        this.statusesFormArray.at(index).setValue(event.checked);
    }

    public changeSelectedPartialStatuses(event: MatCheckboxChange, index: number): void {
        this.partialStatusesFormArray.at(index).setValue(event.checked);
    }

    public isFiltereble(column: TableColumns): boolean {
        if (column === undefined || (column && column.globalFilter === undefined) || (column && column.globalFilter !== undefined && column.globalFilter === false)) {
            return false;
        }

        return true;
    }

    public resetFitlers(explicitValue?: boolean): void {
        this.filterForm.reset();

        if (this.currentStatuses && this.currentStatuses.length > 0) {
            this.currentStatuses.forEach((_: StatusReportFilters, index: number) => {
                const parse = explicitValue === undefined ? false : explicitValue;
                this.statusesFormArray.at(index).patchValue(parse);
            });

            if (explicitValue === undefined) {
                this.currentStatuses.forEach((_: StatusReportFilters, index: number) => {
                    this.statusesFormArray.at(index).patchValue(this.originalStatusesState[index].checked);
                });
            }
        }

        if (this.currentPartielStatuses && this.currentPartielStatuses.length > 0) {
            this.currentPartielStatuses.forEach((_: StatusReportFilters, index: number) => {
                const parse = explicitValue === undefined ? false : explicitValue;
                this.partialStatusesFormArray.at(index).patchValue(parse);
            });

            if (explicitValue === undefined) {
                this.currentPartielStatuses.forEach((_: StatusReportFilters, index: number) => {
                    this.partialStatusesFormArray.at(index).patchValue(this.originalPartielStatusesState[index].checked);
                });
            }
        }

        this.applyFilters(explicitValue !== undefined ? false : true);
    }

    public applyFilters(closeIt: boolean = true): void {
        this.saveToLocalStorage();

        const filtersToSend: ReportFiltersRaw = new ReportFiltersRaw();

        if (this.filterForm.value.week) {
            filtersToSend.week = moment(this.filterForm.value.week).format('YYYYMMDD');
        }

        const compliedStatus: string[] = [];
        this.filterForm.value.statuses.forEach((checkBoxValue: boolean, index: number) => {
            if (checkBoxValue) {
                compliedStatus.push(this.currentStatuses[index].name);
            }
        });

        if (compliedStatus.length > 0) {
            filtersToSend.status = compliedStatus;
        }

        const compliedPartialStatus: string[] = [];
        this.filterForm.value.partialStatuses.forEach((checkBoxValue: boolean, index: number) => {
            if (this.currentPartielStatuses && checkBoxValue) {
                compliedPartialStatus.push(this.currentPartielStatuses[index].name);
            }
        });

        if (compliedPartialStatus.length > 0) {
            filtersToSend.partialStatus = compliedPartialStatus;
        }

        if (this.filterForm.value.columnName && this.filterForm.value.columnName !== '' &&
            this.filterForm.value.searchValue && this.filterForm.value.searchValue !== '') {
            filtersToSend.filter = {
                name: this.filterForm.value.columnName,
                value: this.filterForm.value.searchValue,
            };
        }

        this.filter.emit(filtersToSend);
        if (closeIt) {
            this.panelOpenState = !this.panelOpenState;
        }
    }

    public displayOtherFilters(): boolean {
        return this.filterForm.controls.columnName.value !== null &&
            this.filterForm.controls.columnName.value !== '' &&
            this.filterForm.controls.searchValue.value !== null &&
            this.filterForm.controls.searchValue.value !== '';
    }

    public getSelectedDate(): string {
        return moment(this.filterForm.controls.week.value).format('ll');
    }

    public isEmptyValues(): boolean {
        return (this.filterForm.controls.columnName.value === null || this.filterForm.controls.columnName.value === '')
            && (this.filterForm.controls.searchValue.value === null || this.filterForm.controls.searchValue.value === '')
            && (this.filterForm.controls.week.value === undefined || this.filterForm.controls.week.value === null)
            && this.filterForm.controls.statuses.value.every((status: boolean) => status === false);
    }

    public isOneStatusSelected(): boolean {
        return !this.filterForm.controls.statuses.value.every((status: boolean) => status === false);
    }

    public isOnePartialStatusSelected(): boolean {
        return !this.filterForm.controls.partialStatuses.value.every((status: boolean) => status === false);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////          Private function           //////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    private saveToLocalStorage(): void {
        localStorage.setItem(`ogic_fac_reportFilters${this.localStorageName}`, JSON.stringify({
            week: this.filterForm.value.week ? this.filterForm.value.week : '',
            statuses: this.formatStatusesForSave(),
            partialStatuses: this.formatPartialStatusesForSave(),
            columnName: this.filterForm.value.columnName,
            searchValue: this.filterForm.value.searchValue,
        }));
    }

    private resetStatuses(): void {
        const length = this.filterForm.value.statuses.length;
        if (length > 0) {
            for (let i = 0; i < length; i++) {
                this.statusesFormArray.removeAt(0);
            }
        }
    }

    private resetPartialStatuses(): void {
        const length = this.filterForm.value.partialStatuses.length;
        if (length > 0) {
            for (let i = 0; i < length; i++) {
                this.partialStatusesFormArray.removeAt(0);
            }
        }
    }

    private fetchStatuses(): void {
        this.currentStatuses.forEach((value: StatusReportFilters) => {
            this.addCheckboxesStatus(value);
        });
    }

    private fetchPartialStatuses(): void {
        if (this.currentPartielStatuses) {
            this.currentPartielStatuses.forEach((value: StatusReportFilters) => {
                this.addCheckboxesPartielStatus(value);
            });
        }
    }

    public fetchFilters(reportFilterJson: ReportFiltersLocalStorage): void {
        if (reportFilterJson.week) {
            this.filterForm.controls.week.setValue(new Date(reportFilterJson.week));
        }

        if (reportFilterJson.columnName) {
            this.filterForm.controls.columnName.setValue(reportFilterJson.columnName);
        }

        if (reportFilterJson.searchValue) {
            this.filterForm.controls.searchValue.setValue(reportFilterJson.searchValue);
        }

        if (reportFilterJson.statuses) {
            this.currentStatuses = reportFilterJson.statuses;
            this.resetStatuses();
            this.fetchStatuses();
        }

        if (reportFilterJson.partialStatuses) {
            this.currentPartielStatuses = reportFilterJson.partialStatuses;
            this.resetPartialStatuses();
            this.fetchPartialStatuses();
        } else {
            this.currentPartielStatuses = this.originalPartielStatusesState;
            this.fetchPartialStatuses();
        }
        this.applyFilters();
        this.panelOpenState = false;
    }

    private addCheckboxesStatus(value: StatusReportFilters): void {
        this.statusesFormArray.push(new FormControl(value.checked));
    }

    private addCheckboxesPartielStatus(value: StatusReportFilters): void {
        this.partialStatusesFormArray.push(new FormControl(value.checked));
    }
}
