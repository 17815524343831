import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { OgivCoreState, getOgivCoreState } from '../reducers';
import { transportCodesAdapter, TransportCodesState } from '../reducers/transport-codes.reducer';
import { TransportCode, TransportCodeStatus } from '../../../shared/models/database/transport-code.model';
import { SelectItem } from 'primeng/api';
import moment from 'moment';

const {
    selectAll,
    selectEntities,
    selectIds,
} = transportCodesAdapter.getSelectors();

export const getTransportCodesState = createSelector(
    getOgivCoreState,
    (state: OgivCoreState) => state.transportCodes,
);

export const getTransportCodesLoading = createSelector(
    getTransportCodesState,
    (state: TransportCodesState) => state.loading,
);

export const getTransportCodesLoaded = createSelector(
    getTransportCodesState,
    (state: TransportCodesState) => state.loaded,
);

export const shouldLoadTransportCodes = createSelector(
    getTransportCodesLoading,
    getTransportCodesLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllTransportCodes = createSelector(
    getTransportCodesState,
    selectAll
);

export const getTransportCodesEntities = createSelector(
    getTransportCodesState,
    selectEntities,
);

export const getTransportCodesIds = createSelector(
    getTransportCodesState,
    selectIds,
);

export const getTransportCodeById = (prop: { entityId: number }) => createSelector(
    getTransportCodesEntities,
    (entities: Dictionary<TransportCode>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllTransportCodesSorted = createSelector(
    getAllTransportCodes,
    (transportCodes: TransportCode[]): TransportCode[] => {
        const temporaire = [...transportCodes];
        temporaire.sort((a: TransportCode, b: TransportCode): number => {
            return (a.code.toLocaleLowerCase().localeCompare(b.code.toLocaleLowerCase()));
        });
        return temporaire;
    }
);

export const getActiveTransportCodes = (props: { date: string } = { date: '' }) => createSelector(
    getAllTransportCodesSorted,
    (transportCodes: TransportCode[]): TransportCode[] => {
        return getAllActiveTransportCodes(transportCodes, props.date);
    }
);

export const doesSelectedTransportCodeIsActive = (props: { code: string } = { code: '' }) => createSelector(
    getActiveTransportCodes(),
    (transportCodes: TransportCode[]): boolean => {
        const found = (transportCodes.filter((transportCode: TransportCode) => transportCode.code === props.code));
        return found.length > 0;
    }
);

export const getActiveTransportCodesOptions = (props: { date: string } = { date: '' }) => createSelector(
    getAllTransportCodesSorted,
    (transportCodes: TransportCode[]): SelectItem[] => {
        const transportCodesOptions = getAllActiveTransportCodes(transportCodes, props.date);
        return transportCodesOptions.map((transportCode: TransportCode) => {
            return {
                label: `(${transportCode.code}) ${transportCode.description}`,
                value: transportCode.code,
            } as SelectItem;
        });
    }
);

function getAllActiveTransportCodes(codes: TransportCode[], date: string): TransportCode[] {
    let activeCodes;
    if (date === undefined || date === '') {
        activeCodes = codes.filter(code => code.status.toLocaleLowerCase() === TransportCodeStatus.active.toLocaleLowerCase());
    } else {
        activeCodes = codes.filter(code => code.status.toLocaleLowerCase() === TransportCodeStatus.active.toLocaleLowerCase()
            || moment(code.date_inactif || '1900-01-01').format('YYYYMMDD') > date);
    }
    return activeCodes;
}
