export class BillingSapEntry {
    id: string;
    code: string;
    heure: number;
    natureComptable: string;
    numeroFicheService: string;
    numeroImputation: string;
    quantite: number;

    constructor(billingSapEntry: BillingSapEntryRaw) {
        Object.keys(billingSapEntry).forEach((key: string) => {
            this[this.convertFirstChar(key)] = billingSapEntry[key];
        });
    }

    private convertFirstChar(value: string): string {
        return `${(value.substring(0, 1).toLowerCase())}${value.substring(1)}`;
    }
}

export class BillingSapEntryRaw {
    id: string;
    code: string;
    heure: number;
    natureComptable: string;
    numeroFicheService: string;
    numeroImputation: string;
    quantite: number;
}
