import { cloneDeep } from 'lodash';
import moment from 'moment';

import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FilterService, ConfirmationService, SelectItem } from 'primeng/api';

import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { distinctUntilChanged, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { OpenIdService, OpenIdUserInfo } from '@ngxhq/security';

import { AlternativePath } from '../../../../shared/models/atlernative-path.model';
import { AppConfiguration, getLayerValue } from '../../../../shared/config/app-config.model';
import {
    AssigneOGIVEnum,
    DemandeClient,
    EngagementDemandeClient,
    FailedUpdates,
    NouvelEngagementInfo,
    Operation,
    StatutOgivEnum,
    UpdateDemandeClient,
    UpdateDemandeClientResult
} from '../../../../shared/models/database/demande-client.model';
import {
    getAllUtilisateursInternes,
    getUtilisateursInternesCipsOptions,
    getUtilisateursInternesLoading
} from '../../../../pilotages/store/selectors';
import { getDemandeClientById, getDemandesClientLoadingOne, getDemandesClientUpdateResult } from '../../../store/selectors';
import { getNoteDeVisiteOptions } from '../../../../core/store/selectors';
import { LoadDemandeClientById, UpdateOneDemandeClient, UpdateOneDemandeClientClearFailResult } from '../../../store/actions';
import { LoadUtilisateursInternes } from '../../../../pilotages/store/actions';
import { LockedOperation, LockedOrder } from '../../../../shared/models/demande-client-locked-order.model';
import { TableColumns } from '../../../../shared/models/table-columns.model';
import { UtilisateurInterne } from '../../../../pilotages/models/utilisateur-interne.model';
import { DemandesClientRemarqueDialogComponent } from '../demandes-client-remarque-dialog/demandes-client-remarque-dialog.component';
import { DemandeClientRemarqueDialogDataModel } from '../demandes-client-remarque-dialog/demande-client-remarque-dialog-data.model';
import { DemandesClientHelper } from '../../../../shared/helpers/demandes-client.helper';

@Component({
    selector: 'app-demandes-client-form',
    templateUrl: './demandes-client-form.component.html',
    styleUrls: ['./demandes-client-form.component.scss']
})
export class DemandesClientFormComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];
    private userInfo: OpenIdUserInfo | undefined;
    private mustExit: boolean;

    public commonTranslatePrefix = 'demandes-client.common.';
    public translatePrefix = 'demandes-client.form.';

    public dateFormat = 'yyyy/MM/dd';
    public calendarDateFormat = 'yy/mm/dd';

    public currentUser: UtilisateurInterne | undefined;
    public isEditing: boolean;
    public demandeClientForm: FormGroup;
    public assigneOGIVSelectOptions: SelectItem[];
    public moyenSelectOptions: SelectItem[];
    public statutOGIVSelectOptions: SelectItem[];
    public typeEngagementSelectOptions: SelectItem[];
    public justificationSaisie = '';
    public remarqueSaisie = '';
    public remarqueAbregee = '';
    public remarqueEtJustification = '';
    public typeEngagement = '';
    public isNotesLoaded: boolean;
    public showNoteDialog: boolean;
    public newNoteForm: FormGroup;
    public showErrorDialog: boolean;
    public failedUpdates: FailedUpdates[];
    public isLockedOrder: boolean;
    public lockedOperations: LockedOperation[];
    public originalValue: any;
    public engagementIsValid: boolean = true;
    public engagementDemandeClient: EngagementDemandeClient | null;

    public isUtilisateursInternesLoading$: Observable<boolean> = this.store.pipe(
        select(getUtilisateursInternesLoading),
    );

    public utilisateursInternes$: Observable<UtilisateurInterne[] | null> = this.store.pipe(
        select(getAllUtilisateursInternes),
        distinctUntilChanged()
    );

    public prisParSelectOptions$: Observable<SelectItem[] | null> = this.store.pipe(
        select(getUtilisateursInternesCipsOptions),
        distinctUntilChanged()
    );

    public noteSelectOptions$: Observable<SelectItem[] | null> = this.store.pipe(
        select(getNoteDeVisiteOptions),
        distinctUntilChanged()
    );

    public isDemandeClientUpdating$: Observable<boolean> = this.store.pipe(
        select(getDemandesClientLoadingOne)
    );

    private updatingResult$: Observable<UpdateDemandeClientResult | null> = this.store.pipe(
        select(getDemandesClientUpdateResult)
    );

    private demandeClientById$: Observable<DemandeClient | null> = this.store.pipe(
        select(getDemandeClientById({ entityId: this.demandeClient.numeroOrdre })),
        distinctUntilChanged(),
    );

    public operationsCols: TableColumns[] = [
        { field: 'numeroOperation', header: this.translateService.instant(this.commonTranslatePrefix + 'Operation_Numero'), width: '90px' },
        { field: 'statutSysteme', header: this.translateService.instant(this.commonTranslatePrefix + 'Operation_StatutSysteme'), width: '100px' },
        { field: 'workCenter', header: this.translateService.instant(this.commonTranslatePrefix + 'Operation_WorkCenter'), width: '100px' },
        { field: 'clefReference', header: this.translateService.instant(this.commonTranslatePrefix + 'Operation_ClefReference'), width: '110px' },
        { field: 'designation', header: this.translateService.instant(this.commonTranslatePrefix + 'Operation_Designation'), width: '200px' },
        { field: 'matricule', header: this.translateService.instant(this.commonTranslatePrefix + 'Operation_Matricule'), width: '130px' },
        {
            field: 'dateStatutLance', header: this.translateService.instant(this.commonTranslatePrefix + 'Operation_DateStatutLance'), width: '120px',
            type: 'date', format: this.dateFormat
        },
        { field: 'statutUtilisateur', header: this.translateService.instant(this.commonTranslatePrefix + 'Operation_StatutUtilisateur'), width: '120px' },
        { field: 'actions', header: this.translateService.instant(this.commonTranslatePrefix + 'Actions'), width: '150px', type: 'action' },
    ];

    constructor(
        private openIdService: OpenIdService,
        private readonly store: Store,
        private translateService: TranslateService,
        private configService: AppConfiguration,
        private formBuilder: FormBuilder,
        private confirmationService: ConfirmationService,
        private filterService: FilterService,
        public dialogRef: MatDialogRef<DemandesClientFormComponent>,
        public remarqueDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public demandeClient: DemandeClient,
    ) {
        this.openIdService.getUserInfo()
            .then((user) => {
                this.userInfo = user;
            }).catch(err => { throw err; });

        this.demandeClientForm = this.createDemandeClientForm();
        if (this.demandeClient.ordre.remarqueAbregee) {
            this.remarqueAbregee = this.demandeClient.ordre.remarqueAbregee;
        }
        this.newNoteForm = this.createNewNoteForm();
    }

    private createDemandeClientForm(): FormGroup {
        return this.formBuilder.group({
            statutOGIV: [{ value: this.demandeClient.statutOGIV, disabled: true }, [this.statutOGIVValidator.bind(this)]],
            assigneOGIV: [{ value: this.demandeClient.assigneOGIV, disabled: true }, []],
            note: [{ value: this.demandeClient.notes, disabled: true }, []],
            contactTechnicien: [{ value: this.demandeClient.contactTechnicien, disabled: true }, []],
        });
    }

    private createNewNoteForm(): FormGroup {
        return this.formBuilder.group({
            name: new FormControl(),
        });
    }

    public statutOGIVValidator(control: AbstractControl): ValidationErrors | null {
        if (!this.demandeClient.statutOGIV) {
            return null;
        }
        if (this.demandeClient.statutOGIV === StatutOgivEnum.Filtrage) {
            if ([StatutOgivEnum.Filtrage, StatutOgivEnum.AttenteClientLorsFiltrage, StatutOgivEnum.DemandeTermineeAuFiltrage, StatutOgivEnum.Visite]
                .indexOf(control.value) !== -1) {
                return null;
            }
        } else if (this.demandeClient.statutOGIV === StatutOgivEnum.AttenteClientLorsFiltrage) {
            if ([StatutOgivEnum.AttenteClientLorsFiltrage, StatutOgivEnum.Filtrage, StatutOgivEnum.DemandeTermineeAuFiltrage, StatutOgivEnum.Visite]
                .indexOf(control.value) !== -1) {
                return null;
            }
        } else if (this.demandeClient.statutOGIV === StatutOgivEnum.DemandeTermineeAuFiltrage) {
            if ([StatutOgivEnum.DemandeTermineeAuFiltrage, StatutOgivEnum.Filtrage, StatutOgivEnum.AttenteClientLorsFiltrage, StatutOgivEnum.Visite]
                .indexOf(control.value) !== -1) {
                return null;
            }
        } else if (this.demandeClient.statutOGIV === StatutOgivEnum.Visite) {
            if ([StatutOgivEnum.Visite, StatutOgivEnum.AttenteClientLorsVisite, StatutOgivEnum.DemandeTermineeVisite, StatutOgivEnum.Travaux]
                .indexOf(control.value) !== -1) {
                return null;
            }
        } else if (this.demandeClient.statutOGIV === StatutOgivEnum.AttenteClientLorsVisite) {
            if ([StatutOgivEnum.AttenteClientLorsVisite, StatutOgivEnum.DemandeTermineeVisite, StatutOgivEnum.Travaux].indexOf(control.value) !== -1) {
                return null;
            }
        } else if (this.demandeClient.statutOGIV === StatutOgivEnum.DemandeTermineeVisite) {
            if ([StatutOgivEnum.DemandeTermineeVisite].indexOf(control.value) !== -1) {
                return null;
            }
        } else if (this.demandeClient.statutOGIV === StatutOgivEnum.Travaux) {
            if ([StatutOgivEnum.Travaux, StatutOgivEnum.TravauxExecutes].indexOf(control.value) !== -1) {
                return null;
            }
        } else if (this.demandeClient.statutOGIV === StatutOgivEnum.PrescriptionsMultiples) {
            if ([StatutOgivEnum.PrescriptionsMultiples].indexOf(control.value) !== -1) {
                return null;
            }
        } else if (this.demandeClient.statutOGIV === StatutOgivEnum.MesureParticuliereTravaux) {
            if ([StatutOgivEnum.MesureParticuliereTravaux].indexOf(control.value) !== -1) {
                return null;
            }
        } else if (this.demandeClient.statutOGIV === StatutOgivEnum.TravauxExecutes) {
            if ([StatutOgivEnum.TravauxExecutes, StatutOgivEnum.DemandeTermineeTravaux].indexOf(control.value) !== -1) {
                return null;
            }
        } else if (this.demandeClient.statutOGIV === StatutOgivEnum.DemandeTermineeTravaux) {
            if ([StatutOgivEnum.DemandeTermineeTravaux].indexOf(control.value) !== -1) {
                return null;
            }
        }
        return { 'statutError': true };
    }

    public get newNoteInDropDown(): string {
        return this.newNoteForm.controls['name'].value;
    }

    public get statutOGIV(): AbstractControl {
        return this.demandeClientForm.controls['statutOGIV'];
    }

    private get assigneOGIV(): AbstractControl {
        return this.demandeClientForm.controls['assigneOGIV'];
    }

    private get note(): AbstractControl {
        return this.demandeClientForm.controls['note'];
    }

    private get contactTechnicien(): AbstractControl {
        return this.demandeClientForm.controls['contactTechnicien'];
    }

    public ngOnInit(): void {
        this.isEditing = false;
        this.mustExit = false;
        this.getLocalStorageLockedInfo();

        this.loadDemandeClient();
        this.loadUtilisateursInternes();
        this.initDateFilters();
        this.populateAllSelectOptions();

        this.subscriptions.push(
            this.updateCurrentUserInfos(),
            this.updateNotes(),
            this.updateDemandeClientById(),
            this.handleUpdateErrors()
        );
    }

    private getLocalStorageLockedInfo(): void {
        const storedLockedOrders: LockedOrder[] = JSON.parse(localStorage.getItem('DemandeClientLockedOrders') || '[]');
        // eslint-disable-next-line
        const index = storedLockedOrders.findIndex((order: LockedOrder) => order.numeroOrdre === this.demandeClient.ordre.numeroOrdre);
        this.isLockedOrder = false;
        if (index !== -1) {
            this.isLockedOrder = moment().subtract(15, 'minutes').isSameOrBefore(moment(storedLockedOrders[index].timestampLocked));
        }

        this.lockedOperations = JSON.parse(localStorage.getItem('DemandeClientLockedOperations') || '[]');
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    private loadDemandeClient(): void {
        this.store.dispatch(new LoadDemandeClientById(this.demandeClient.numeroOrdre));
    }

    private loadUtilisateursInternes(): void {
        this.store.dispatch(new LoadUtilisateursInternes(AlternativePath.hq));
    }

    private initDateFilters(): void {
        // Added for MB-ANGULAR-11
        this.filterService.register('dateFilter', (): boolean => {
            return true;
        });
        // Remove for MB-ANGULAR-11
        // FilterUtils['dateFilter'] = (): boolean => {
        //     return true;
        // };
    }

    private populateAllSelectOptions(): void {
        this.assigneOGIVSelectOptions = this.createEnumSelectOptions(AssigneOGIVEnum);
        this.statutOGIVSelectOptions = this.createEnumSelectOptions(StatutOgivEnum);
    }

    private createEnumSelectOptions(enumeration: any, translatePrefixe: Readonly<string> = ''): SelectItem[] {
        return Object.values(enumeration).map((value: any) => {
            return {
                label: translatePrefixe.length > 0 ? this.translateService.instant(translatePrefixe + value) : value,
                value: value,
            } as SelectItem;
        });
    }

    private updateCurrentUserInfos(): Subscription {
        return this.utilisateursInternes$.pipe(
            tap((utilisateursInternes: UtilisateurInterne[] | null) => {
                if (utilisateursInternes && utilisateursInternes.length > 0) {
                    if (this.userInfo && this.userInfo.email) {
                        // eslint-disable-next-line
                        this.currentUser =
                            utilisateursInternes.find((utilisateur: UtilisateurInterne) =>
                                utilisateur.courriel?.toLowerCase() === this.userInfo?.email?.toLowerCase() ||
                                utilisateur.upn?.toLowerCase() === this.userInfo?.email?.toLowerCase());
                    }
                }
            })
        ).subscribe();
    }

    private updateNotes(): Subscription {
        return this.noteSelectOptions$.pipe(
            tap((notes: SelectItem[] | null) => {
                if (notes && notes.findIndex((note: SelectItem) => note.value === '00000000') === -1) {
                    notes.push({
                        label: this.translateService.instant(this.translatePrefix + 'newNote'),
                        value: '00000000'
                    } as SelectItem);
                }
                this.isNotesLoaded = true;
            })
        ).subscribe();
    }

    public updateDemandeClientById(): Subscription {
        return this.demandeClientById$.pipe(
            tap((demandeClient: DemandeClient | null) => {
                this.updateCurrentDemande(demandeClient);
            })
        ).subscribe();
    }

    private handleUpdateErrors(): Subscription {
        return this.updatingResult$.pipe(
            tap((updateDemandeClientResult: UpdateDemandeClientResult | null) => {
                if (updateDemandeClientResult) {
                    this.failedUpdates = updateDemandeClientResult.failedUpdates;
                    this.showErrorDialog = true;
                    this.isEditing = true;
                }
            })
        ).subscribe();
    }

    private updateCurrentDemande(demandeClient: DemandeClient | null): void {
        if (demandeClient) {
            this.demandeClient = cloneDeep(demandeClient);

            this.demandeClient.ordre.indexOperation = this.selectCurrentOperation(this.demandeClient);
            this.demandeClient.ordre.operationCourante = demandeClient.ordre.operations[this.demandeClient.ordre.indexOperation];

            const derniereRemarque = DemandesClientHelper.extractDerniereRemarque(this.demandeClient.ordre.remarque);
            const textRemarque = DemandesClientHelper.extractTextRemarque(derniereRemarque);
            this.demandeClient.ordre.remarqueAbregee = DemandesClientHelper.getRemarqueAbregee(textRemarque);
            this.remarqueAbregee = this.demandeClient.ordre.remarqueAbregee;
        }
    }

    private selectCurrentOperation(demande: DemandeClient): number {
        let index = -1;

        if (demande.ordre.nombreOperations > 1) {
            index = demande.ordre.operations.findIndex((operation: Operation) => operation.statutSysteme.includes('CONF') === false);
            if (index === -1) {
                index = 0;
            }
        } else if (demande.ordre.nombreOperations === 1) {
            index = 0;
        }
        return index;
    }

    public openSapPiecesJointes(): void {
        const url = `${this.configService.sapConfig.url}?%7etransaction=*ZAGIL%20G_AUFNR=${this.demandeClient.numeroOrdre}`;
        window.open(url, 'sapWebGui');
    }

    public openWAB(): void {
        const layerName = getLayerValue(this.configService.wabConfig, 'IOG_DEMANDE_CLIENT');

        const url = `${this.configService.wabConfig.url}?query=${layerName},NumeroOrdre,${this.demandeClient.numeroOrdre}&level=18`;
        window.open(url, 'OgivWeb');
    }

    public showRemarque(): void {
        // this.panelRemarque.showRemarque(this.demandeClient, event);
        const remarqueDialogRef = this.remarqueDialog.open(DemandesClientRemarqueDialogComponent, {
            width: '95%',
            maxWidth: '575px',
            maxHeight: '90%',
            autoFocus: false,
            data: {
                demandeClient: this.demandeClient,
                isEditing: this.isEditing,
                existingNouvelleRemarque: this.remarqueEtJustification,
                tagRemarque: DemandesClientHelper.initNouvelleRemarque(this.currentUser),
            } as DemandeClientRemarqueDialogDataModel
        });

        remarqueDialogRef.afterClosed().subscribe((nouvelleRemarque?: string) => {
            if (nouvelleRemarque && nouvelleRemarque !== undefined) {
                this.onNouvelleRemarqueEvent(nouvelleRemarque);
            }
        });
    }

    public onNouvelleRemarqueEvent(nouvelleRemarque: string): void {
        if (nouvelleRemarque === '') {
            this.remarqueAbregee = this.demandeClient.ordre.remarqueAbregee ? this.demandeClient.ordre.remarqueAbregee : '';
            this.remarqueSaisie = '';
            this.remarqueEtJustification = '';
        } else {
            this.remarqueEtJustification = nouvelleRemarque;
            this.remarqueSaisie = nouvelleRemarque.replace(this.justificationSaisie, '');
            this.remarqueAbregee = DemandesClientHelper.getRemarqueAbregee(this.remarqueEtJustification);
        }
    }

    public noteValueChanges(value: Readonly<string>): void {
        if (value === '00000000') {
            this.showNoteDialog = true;
        }
    }

    public onEdit(): void {
        this.copieOriginalValue();
        this.isEditing = true;
        this.statutOGIV.enable();
        this.assigneOGIV.enable();
        this.note.enable();
        this.contactTechnicien.enable();
        this.setDefaultValues();
    }

    private copieOriginalValue(): void {
        this.originalValue = this.demandeClientForm.value;
    }

    private setDefaultValues(): void {
        // Valeur par défaut = Filtrage
        this.statutOGIV.setValue(this.demandeClient.statutOGIV ? this.demandeClient.statutOGIV : StatutOgivEnum.Filtrage);
        this.statutOGIV.markAsTouched();
        // Valeur par défaut = Lecture
        this.assigneOGIV.setValue(this.demandeClient.assigneOGIV ? this.demandeClient.assigneOGIV : AssigneOGIVEnum.Lecture);
        this.assigneOGIV.markAsTouched();
    }

    public onSubmitNewNote(): void {
        this.subscriptions.push(this.addNewNote());
        this.note.setValue(this.newNoteInDropDown.trim());
        this.closeNoteDialog();
    }

    private addNewNote(): Subscription {
        return this.noteSelectOptions$.pipe(
            tap((notes: SelectItem[] | null) => {
                if (notes && this.newNoteInDropDown) {
                    notes.splice(notes.length - 1, 0, {
                        label: this.newNoteInDropDown.trim(),
                        value: this.newNoteInDropDown.trim()
                    } as SelectItem);
                }
            })
        ).subscribe();
    }

    public cancelNoteDialog(): void {
        this.note.setValue(this.demandeClient.notes ? this.demandeClient.notes : null);
        this.closeNoteDialog();
    }

    private closeNoteDialog(): void {
        this.newNoteForm.controls['name'].reset();
        this.newNoteForm.controls['name'].updateValueAndValidity();
        this.showNoteDialog = false;
    }

    public onCancel(): void {
        this.displayConfirmationDialog();
    }

    public onExit(): void {
        this.mustExit = true;
        this.displayConfirmationDialog();
    }

    private displayConfirmationDialog(): void {
        if (this.demandeClientForm.touched) {
            this.confirmationService.confirm({
                message: this.translateService.instant(this.translatePrefix + 'confirmDialog' + '.message'),
                header: this.translateService.instant(this.translatePrefix + 'confirmDialog' + '.title'),
                icon: 'pi pi-question-circle',
                acceptLabel: this.translateService.instant('common.cancel'),
                acceptIcon: 'pi pi-times',
                rejectLabel: this.translateService.instant('common.continue'),
                rejectIcon: 'pi pi-check',
                accept: () => this.closeDialog(),
                reject: () => this.mustExit = false,
            });
        } else {
            this.closeDialog();
        }
    }

    private closeDialog(): void {
        if (this.mustExit) {
            this.dialogRef.close();
        } else {
            this.isEditing = false;
            this.demandeClientForm = this.createDemandeClientForm();
            if (this.demandeClient.ordre.remarqueAbregee) {
                this.remarqueAbregee = this.demandeClient.ordre.remarqueAbregee;
            }
            this.justificationSaisie = '';
            this.remarqueSaisie = '';
            this.remarqueEtJustification = '';
        }
    }

    public onSubmit(): void {
        if (this.engagementIsValid && this.demandeClientForm.valid) {
            const updateDemandeClient = this.mapDemandeClientForm();
            this.store.dispatch(new UpdateOneDemandeClient(updateDemandeClient, updateDemandeClient.numerosOrdre[0]));
            this.demandeClientForm.markAsUntouched();
            this.isEditing = false;
            this.justificationSaisie = '';
            this.remarqueSaisie = '';
            this.remarqueEtJustification = '';
        }
    }

    private mapDemandeClientForm(): UpdateDemandeClient {
        const updateDemandeClientValue = Object.assign({}, this.demandeClientForm.value);
        let nouvelEngagement: NouvelEngagementInfo | null = null;
        if (this.engagementDemandeClient?.moyen || this.engagementDemandeClient?.prisPar || this.engagementDemandeClient?.justificationInfos) {
            nouvelEngagement = {
                moyen: this.engagementDemandeClient.moyen,
                prisPar: this.engagementDemandeClient.prisPar,
                justificationInfos: this.engagementDemandeClient.justificationInfos
            };
        }

        const updateDemandeClient: UpdateDemandeClient = {
            globalIds: [this.demandeClient.globalId],
            numerosOrdre: [this.demandeClient.numeroOrdre],
            dateEngagement: this.engagementDemandeClient?.dateEngagement ? new Date(this.engagementDemandeClient.dateEngagement) : null,
            typeEngagement: this.engagementDemandeClient?.typeEngagement ? this.engagementDemandeClient.typeEngagement : null,
            nouvelEngagement: nouvelEngagement,
            statutOGIV: updateDemandeClientValue.statutOGIV ? updateDemandeClientValue.statutOGIV : null,
            assigneOGIV: updateDemandeClientValue.assigneOGIV ? updateDemandeClientValue.assigneOGIV : null,
            notes: updateDemandeClientValue.note ? updateDemandeClientValue.note : null,
            contactTechnicien: updateDemandeClientValue.contactTechnicien ? updateDemandeClientValue.contactTechnicien : null,
            remarqueInfos: this.remarqueEtJustification ?
                [{
                    numerosOrdre: [this.demandeClient.numeroOrdre],
                    remarque: DemandesClientHelper.formatRemarqueForSAP(DemandesClientHelper.initNouvelleRemarque(this.currentUser) + this.remarqueEtJustification)
                }] : null
        };

        return updateDemandeClient;
    }


    public onEngagementChange(engagement: EngagementDemandeClient): void {
        this.engagementDemandeClient = engagement;
        if (this.engagementDemandeClient !== undefined && this.engagementDemandeClient !== null &&
            this.engagementDemandeClient.justificationInfos !== undefined && this.engagementDemandeClient.justificationInfos !== null &&
            this.engagementDemandeClient.justificationInfos.length > 0) {

            this.justificationSaisie = `\n${this.engagementDemandeClient.justificationInfos[0].justification.trim()}`;
            this.remarqueEtJustification = this.remarqueSaisie + this.justificationSaisie;
            this.remarqueAbregee = DemandesClientHelper.getRemarqueAbregee(this.remarqueEtJustification);
        }
    }

    public onEngagementValidityChange(isValid: boolean): void {
        this.engagementIsValid = isValid;
    }

    public closeErrorDialog(): void {
        this.showErrorDialog = false;
        this.failedUpdates = [];
        this.store.dispatch(new UpdateOneDemandeClientClearFailResult());
        this.demandeClientForm.markAsTouched();
    }

    public isOperationIW32Enabled(operation: Operation): boolean {
        return !(operation.statutSysteme.includes('CONF') || this.isLockedOrder || this.isLockedOperation(operation));
    }

    public isLockedOperation(operation: Operation): boolean {
        const index = this.lockedOperations.findIndex((lockedOperation: LockedOperation) =>
            // eslint-disable-next-line
            (lockedOperation.numeroOrdre === this.demandeClient.numeroOrdre) && (lockedOperation.numeroOperation === operation.numeroOperation));

        let isLocked = false;
        if (index !== -1) {
            isLocked = moment().subtract(15, 'minutes').isSameOrBefore(moment(this.lockedOperations[index].timestampLocked));
        }
        return this.isLockedOrder || isLocked;
    }

    public displayWarningIW32Dialog(): void {
        this.confirmationService.confirm({
            message: this.translateService.instant(this.translatePrefix + 'warningDialog' + '.message'),
            header: this.translateService.instant(this.translatePrefix + 'warningDialog' + '.title'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: this.translateService.instant('common.confirm'),
            rejectLabel: this.translateService.instant('common.cancel'),
            accept: () => this.openIW32(),
        });
    }

    private openIW32(): void {
        this.lockOrder();
        const url = `${this.configService.sapConfig.url}?~transaction=*IW32%20CAUFVD-AUFNR=${this.demandeClient.numeroOrdre}`;
        window.open(url, 'sapIW32');
    }

    private lockOrder(): void {
        this.isLockedOrder = true;
        const storedLockedOrders: LockedOrder[] = JSON.parse(localStorage.getItem('DemandeClientLockedOrders') || '[]');
        // eslint-disable-next-line
        const index = storedLockedOrders.findIndex((lockedOrder: LockedOrder) => lockedOrder.numeroOrdre === this.demandeClient.numeroOrdre);
        if (index === -1) {
            storedLockedOrders.push({
                numeroOrdre: this.demandeClient.numeroOrdre,
                timestampLocked: new Date()
            });
        } else {
            storedLockedOrders[index].timestampLocked = new Date();
        }
        localStorage.setItem('DemandeClientLockedOrders', JSON.stringify(storedLockedOrders));
    }

    public displayWarningIW41Dialog(operation: Operation): void {
        this.confirmationService.confirm({
            message: this.translateService.instant(this.translatePrefix + 'warningDialog' + '.message'),
            header: this.translateService.instant(this.translatePrefix + 'warningDialog' + '.title'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: this.translateService.instant('common.confirm'),
            rejectLabel: this.translateService.instant('common.cancel'),
            accept: () => this.openIW41(operation),
        });
    }

    private openIW41(operation: Operation): void {
        this.lockOperation(operation);
        const url = `${this.configService.sapConfig.url}?~transaction=*IW41%20CORUF-AUFNR=${this.demandeClient.numeroOrdre};CORUF-VORNR=${operation.numeroOperation}#`;
        window.open(url, 'sapIW41');
    }

    private lockOperation(operation: Operation): void {
        const index = this.lockedOperations.findIndex((lockedOperation: LockedOperation) =>
            // eslint-disable-next-line
            (lockedOperation.numeroOrdre === this.demandeClient.numeroOrdre) && (lockedOperation.numeroOperation === operation.numeroOperation));
        if (index === -1) {
            this.lockedOperations.push({
                numeroOrdre: this.demandeClient.numeroOrdre,
                numeroOperation: operation.numeroOperation,
                timestampLocked: new Date()
            });
        } else {
            this.lockedOperations[index].timestampLocked = new Date();
        }
        localStorage.setItem('DemandeClientLockedOperations', JSON.stringify(this.lockedOperations));
    }
}
