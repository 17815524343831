import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ApiService } from '../../../ogiv-core/services';

import { AddOneAppNotification, DomaineDeValeurActionTypes, LoadDomaineDeValeur, LoadDomaineDeValeurFail, LoadDomaineDeValeurSuccess } from '../actions';
import { DomaineDeValeur } from '../../../shared/models/database/domaine-valeur.model';
import { ErrorNotification } from '../models/app-notification.model';

@Injectable()
export class DomaineDeValeurEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    public domaineDeValeur$ = createEffect(() => this.actions$.pipe(
        ofType<LoadDomaineDeValeur>(DomaineDeValeurActionTypes.LOAD_DOMAINE_VALEUR),
        switchMap((action: LoadDomaineDeValeur) => {
            return this.apiService.get<DomaineDeValeur[]>(`domaineDeValeur/${action.concern}?type=${action.typeDomaineDeValeur}`).pipe(
                map((valeurs: DomaineDeValeur[]) => {
                    return new LoadDomaineDeValeurSuccess(valeurs);
                }),
                catchError((error: any) => of(new LoadDomaineDeValeurFail(error))),
            );
        }),
    ));

    public loadDomaineDeValeurFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadDomaineDeValeurFail>(DomaineDeValeurActionTypes.LOAD_DOMAINE_VALEUR_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'domaine-valeur.notifications.load.error',
            }))
        ]),
    ));
}
