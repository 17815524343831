import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { ApiService } from '../../../ogiv-core/services/api.service';
import {
    LoadEquipmentCodes,
    EquipmentCodeActionType,
    LoadEquipmentCodesSuccess,
    LoadEquipmentCodesFail,
    LoadEquipmentCodesNoResult
} from '../actions/equipment-codes.action';
import { EquipmentCode } from '../../../shared/models/database/equipment-code.model';

@Injectable()
export class EquipmentCodesEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService,
    ) { }

    public loadEquipmentCode$ = createEffect(() => this.action$.pipe(
        ofType<LoadEquipmentCodes>(EquipmentCodeActionType.LOAD_EQUIPMENT_CODES),
        switchMap(() => {
            return this.apiService.get<EquipmentCode[]>('sap/data/equipmentCodes').pipe(
                map((returnedEquipmentCodes: EquipmentCode[]) =>
                    (returnedEquipmentCodes.length > 0)
                        ? new LoadEquipmentCodesSuccess(returnedEquipmentCodes)
                        : new LoadEquipmentCodesNoResult()
                ),
                catchError((error: any) => of(new LoadEquipmentCodesFail(error))),
            );
        }),
    ));
}
