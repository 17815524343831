import { Component, Output, EventEmitter, Input, ViewChild, } from '@angular/core';
import { MatMenuTrigger, MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { TooltipPosition } from '@angular/material/tooltip';
import { GenericOtherMenuItemComponent } from './generic-other-menu-item.model';

@Component({
    selector: 'app-generic-other-menu',
    templateUrl: './generic-other-menu.component.html',
    styleUrls: ['./generic-other-menu.component.scss'],
})
export class GenericOtherMenuComponent {
    public isButtonsLock = false;
    public clickedMenu = false;

    // Affichage des boutons individuellement
    @Input() public deleteOnly = false;
    @Input() public editOnly = false;
    @Input() public addOnly = false;

    // disabled button except in sub menu
    @Input() public disabledButton = false;

    // Bouton générique avec un logo éditable
    @Input() public mySingleButtonOnly = false;
    // Utiliser les icones de "Font Awesome"
    @Input() public singleButtonIcon = 'far fa-smile-beam';
    // Type de bouton qui doit être implémenté
    @Input() public singleButtonType = 'button';
    // fontSize de l'icones sans unité
    @Input() public iconFontSize = 16;
    // fontWeight de l'icones
    @Input() public iconFontWeight = 900;
    // fontColor de l'icones
    @Input() public iconFontColor: string | null = null;

    // Affiche le menu de base (add, edit, delete)
    @Input() public displayMenu = false;

    // Affiche un menu vide qui contient seulement un ng-content
    //  ajouter des boutons qui contiennent la directive mat-menu-item
    @Input() public myOwnMenu = false;

    // Positionnement du menu
    @Input() public xposition: MenuPositionX = 'before';
    @Input() public yposition: MenuPositionY = 'below';

    // Contrôle les classes qui seront appliqué au bouton/menu
    @Input() public addborder = false;
    @Input() public addBackground = false;
    @Input() public addBackgroundMenu = false;

    // Dans le menu de base (add, edit, delete), on peut cacher les bouton boutons non désirable
    @Input() public hideItems: string[] = [];
    @Input() public myOwnMenuListItems: GenericOtherMenuItemComponent[] = [];

    // Si isLock est "true" le bouton/menu ne s'affiche pas
    @Input() public set isLock(value: boolean) {
        this.isButtonsLock = value;
    }

    // Applique la class "hover" quand on passe au-dessus
    @Input() public hoverButtonOrMenu = false;
    // Applique le bouton de couleur rouger et inverse la couleur avec un hover
    @Input() public isRedButton = false;
    // Texte à afficher dans le tooltip
    @Input() public toolTipLabel: string | null = null;
    // Position du tooltip (valeur possible: 'left' | 'right' | 'above' | 'below' | 'before' | 'after')
    @Input() public toolTipPosition: TooltipPosition = 'above';

    // Emit par les boutons "addOnly" et "add" dans le menu
    @Output() public add: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    // Emit par les boutons "editOnly" et "edit" dans le menu
    @Output() public edit: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    // Emit par les boutons "deleteOnly" et "delete" dans le menu
    @Output() public delete: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    // Emit par les boutons "mySingleButtonOnly"
    @Output() public singleButtonPressed: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    @Output() public myOwnMenuItemPressed: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;

    public closeMenu(): void {
        if (this.clickedMenu) {
            this.clickedMenu = !this.clickedMenu;
            this.trigger.closeMenu();
        }
    }

    public addSpaceBtw(): boolean {
        return ((this.deleteOnly || this.editOnly || this.addOnly) && this.displayMenu);
    }

    public addRecord(event: MouseEvent): void {
        this.closeMenu();
        this.add.emit(event);
    }

    public editRecord(event: MouseEvent): void {
        this.closeMenu();
        this.edit.emit(event);
    }

    public deleteRecord(event: MouseEvent): void {
        this.closeMenu();
        this.delete.emit(event);
    }

    public mySingleButtonPressed(event: MouseEvent): void {
        this.closeMenu();
        this.singleButtonPressed.emit(event);
    }

    public clickedDisplayMenu(event: MouseEvent): void {
        event.stopPropagation();
        this.clickedMenu = true;
    }

    public displayItem(value: string): boolean {
        return this.hideItems.length > 0 ? !this.hideItems.includes(value) : true;
    }

    public displayMenuSpacer(): boolean {
        return (this.displayMenu && (this.deleteOnly || this.editOnly || this.addOnly));
    }

    public selectMyOwnMenuItem(value: string): void {
        this.myOwnMenuItemPressed.emit(value);
    }
}
