import { JobCode } from '../../../shared/models/database/job-code.model';
import { JobCodeAction, JobCodeActionType } from '../actions/job-codes.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface JobCodesState extends EntityState<JobCode> {
    loading: boolean;
    loaded: boolean;
}

export const jobCodesAdapter: EntityAdapter<JobCode> = createEntityAdapter<JobCode>({
    selectId: (jobCode: JobCode) => jobCode.id,
});

export const jobCodesInitialState = jobCodesAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function jobCodeReducer(
    state: JobCodesState = jobCodesInitialState,
    action: JobCodeAction,
): JobCodesState {
    switch (action.type) {
        case JobCodeActionType.CREATE_JOB_CODE:
        case JobCodeActionType.UPDATE_ONE_JOB_CODE:
        case JobCodeActionType.LOAD_JOB_CODES: {
            return {
                ...state,
                loading: true,
            };
        }

        case JobCodeActionType.CREATE_JOB_CODE_SUCCESS: {
            return jobCodesAdapter.addOne(action.jobCode, {
                ...state,
                loading: false,
            });
        }

        case JobCodeActionType.UPDATE_ONE_JOB_CODE_SUCCESS: {
            return jobCodesAdapter.upsertOne(action.jobCode, {
                ...state,
                loading: false,
            });
        }

        case JobCodeActionType.CREATE_JOB_CODE_FAIL:
        case JobCodeActionType.UPDATE_ONE_JOB_CODE_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case JobCodeActionType.LOAD_JOB_CODES_SUCCESS: {
            return jobCodesAdapter.setAll(action.jobCodes, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case JobCodeActionType.LOAD_JOB_CODES_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case JobCodeActionType.LOAD_JOB_CODES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
