import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { TimesheetState, getTimesheetState } from '../reducers';
import { Equipment } from '../../../shared/models/database/equipment.model';
import { equipmentAdapter, EquipmentState } from '../reducers/equipments.reducer';

const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,
} = equipmentAdapter.getSelectors();

export const getEquipmentsState = createSelector(
    getTimesheetState,
    (state: TimesheetState) => state.equipments,
);

export const getEquipmentsLoading = createSelector(
    getEquipmentsState,
    (state: EquipmentState) => state.loading,
);

export const getEquipmentsLoaded = createSelector(
    getEquipmentsState,
    (state: EquipmentState) => state.loaded,
);

export const shouldLoadEquipments = createSelector(
    getEquipmentsLoaded,
    getEquipmentsLoading,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllEquipments = createSelector(
    getEquipmentsState,
    selectAll,
);

export const getEquipmentsEntities = createSelector(
    getEquipmentsState,
    selectEntities,
);

export const getEquipmentsIds = createSelector(
    getEquipmentsState,
    selectIds,
);

export const getEquipmentById = (props: any) => createSelector(
    getEquipmentsEntities,
    (entities: Dictionary<Equipment>) => {
        return entities && entities[props.entityId] || null;
    }
);

export const getEquipmentsCount = createSelector(
    getEquipmentsState,
    selectTotal
);

export const getEquipmentsTotalHours = createSelector(
    getAllEquipments,
    (equipments: Equipment[]): number =>
        equipments.reduce((acc: number, equipment: Equipment) =>
            acc + (equipment.hour || 0), 0)
);

export const getEquipmentsByOrderId = ({ orderId = null }: { orderId?: number | null } = {}) => createSelector(
    getAllEquipments,
    (equipments: Equipment[]) => {
        return equipments.filter(equipement => equipement.orderId === orderId);
    }
);
