import { Component, Input } from '@angular/core';
import moment from 'moment';
import { TimesheetRemark } from '../../../../../../shared/models/database/timesheet-remark.model';

@Component({
    selector: 'app-timesheet-remark',
    templateUrl: './timesheet-remark.component.html',
    styleUrls: ['./timesheet-remark.component.scss'],
})
export class TimesheetRemarkComponent {
    public translatePrefix = 'timesheet.reports.detail.timesheetRemark';

    @Input() remarks: TimesheetRemark[];

    public getDate(date: Date): string {
        return moment(date).format('DD-MM-YYYY');
    }
}
