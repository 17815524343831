import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { Order } from '../../../shared/models/database/order.model';
import { OrderActionTypes, OrdersAction } from '../actions/orders.action';

export interface OrdersState extends EntityState<Order> {
    loading: boolean;
    loaded: boolean;
}

export const ordersAdapter: EntityAdapter<Order> = createEntityAdapter<Order>({
    selectId: (order: Order) => order.id,
});

export const ordersInitialState = ordersAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function ordersReducer(
    state: OrdersState = ordersInitialState,
    action: OrdersAction,
): OrdersState {
    switch (action.type) {
        case OrderActionTypes.DELETE_ONE_ORDER:
        case OrderActionTypes.CREATE_ORDER:
        case OrderActionTypes.UPDATE_ONE_ORDER: {
            return {
                ...state,
                loading: true,
            };
        }

        case OrderActionTypes.DELETE_ONE_ORDER_FAIL:
        case OrderActionTypes.UPDATE_ONE_ORDER_FAIL:
        case OrderActionTypes.CREATE_ORDER_FAIL:
        case OrderActionTypes.DELETE_ONE_ORDER_SUCCESS:
        case OrderActionTypes.UPDATE_ONE_ORDER_SUCCESS:
        case OrderActionTypes.CREATE_ORDER_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }

        case OrderActionTypes.LOAD_ORDERS: {
            return {
                ...state,
                loading: true,
            };
        }

        case OrderActionTypes.LOAD_ORDERS_SUCCESS: {
            return ordersAdapter.setAll(action.orders, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case OrderActionTypes.LOAD_ORDERS_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case OrderActionTypes.LOAD_ORDERS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
