<app-element-loader *ngIf="equipmentsLoading$ | async" [size]="50"></app-element-loader>
<mat-list>
    <mat-list-item *ngFor="let equipment of equipmentList; trackBy: equipmentTrackById; let equipmentIndex = index">
        <div class="equipment">
            <div class="equipment__info" (click)="editRecord(equipment)" (mouseover)="mouseOver(equipmentIndex)" (mouseout)="mouseOut()" [class.hover]="equipmentIndex === equipmentOverIndex">
                <i class="fas fa-wrench info__logo" [class.isLock]="isLock"></i>
                <div class="info__info">
                    <div class="info__equipment">
                        <div class="equipment__name" [class.isLock]="isLock">{{equipment.number}}</div>
                        <div class="equipment__description" [class.isLock]="isLock" *ngIf="(equipment.code !== null && equipment.code !== '') ||
                            (equipment.description !== null && equipment.description !== '')">({{equipment.code}}) {{equipment.description}}</div>
                    </div>
                    <div *ngIf="canAddEditTime" class="info__time">
                        <div class="time__logo" [class.isLock]="isLock">
                            <i class="far fa-clock"></i>
                        </div>
                        <div class="time__hour" [class.isLock]="isLock">
                            {{equipment.hour}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="equipment__action" *hasRole="editAllowedRoles">
                <div class="action__menu">
                    <app-generic-other-menu class="menu__other"
                        [editOnly]="equipmentIndex === equipmentOverIndex"
                        [hoverButtonOrMenu]="equipmentIndex === equipmentOverIndex"
                        [isLock]="isLock"
                        [addborder]="true"
                        [displayMenu]="equipmentIndex !== equipmentOverIndex"
                        [hideItems]="['add']"
                        (edit)="editRecord(equipment)"
                        (delete)="deleteRecord(equipment)">
                    </app-generic-other-menu>
                </div>
            </div>
        </div>
    </mat-list-item>
</mat-list>
