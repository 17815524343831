import { createSelector } from '@ngrx/store';

import { DemandesClientGlobalState, getDemandesClientGlobalState } from '../reducers';
import { DemandesClientEcartsState, demandesClientEcartsAdapter } from '../reducers/demandes-client-ecarts.reducers';

const {
    selectAll,
    selectEntities,
} = demandesClientEcartsAdapter.getSelectors();

/**
 * General State selector
 */
export const getDemandesClientEcartsState = createSelector(
    getDemandesClientGlobalState,
    (state: DemandesClientGlobalState) => state.demandesClientEcarts,
);

export const getDemandesClientEcartsLoading = createSelector(
    getDemandesClientEcartsState,
    (state: DemandesClientEcartsState) => state.loading,
);

export const getDemandesClientEcartsLoaded = createSelector(
    getDemandesClientEcartsState,
    (state: DemandesClientEcartsState) => state.loaded,
);

export const getAllDemandesClientEcarts = createSelector(
    getDemandesClientEcartsState,
    selectAll,
);

export const getDemandesClientEcartsEntities = createSelector(
    getDemandesClientEcartsState,
    selectEntities,
);
