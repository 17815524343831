import { Injectable } from '@angular/core';

import { catchError, switchMap, map, mergeMap } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { AddOneAppNotification } from '../../../core/store/actions';
import { ApiService } from '../../../ogiv-core/services';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';
import { SpecialMeasures, UpdatedPrescription } from '../../../shared/models/database/special-measures.model';
import {
    SpecialMeasuresActionType,
    LoadSpecialMeasures,
    LoadSpecialMeasuresSuccess,
    LoadSpecialMeasuresFail,
    UpdateOnePrescription,
    UpdateOnePrescriptionSuccess,
    UpdateOnePrescriptionFail,
} from '../actions/special-measures.actions';

@Injectable()
export class SpecialMeasuresEffects {
    _apiService: any;
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    /************************/
    /*******  LOADING *******/
    /************************/
    public loadSpecialMeasures$ = createEffect(() => this.actions$.pipe(
        ofType<LoadSpecialMeasures>(SpecialMeasuresActionType.LOAD_SPECIAL_MEASURES),
        switchMap((action: LoadSpecialMeasures) => {
            const urlList = [
                'demandes/mesures/securisation/consulter?typeMesurePart=1,2,3',
                'demandes/mesures/securisation/consulter?typeMesurePart=4,5,6',
                'demandes/mesures/securisation/consulter?typeMesurePart=7,8,9'
            ];

            const results: Observable<SpecialMeasures>[] = [];
            urlList.forEach((url) => {
                results.push(this.apiService.get<SpecialMeasures>(`${action.path}/${url}`));
            });

            return forkJoin(
                results
            ).pipe(
                map((res: SpecialMeasures[]) => {
                    const allResults = [];
                    for (const value of res) {
                        allResults.push(new LoadSpecialMeasuresSuccess(value));
                    }

                    return allResults;
                }),
                map((specialMeasures: LoadSpecialMeasuresSuccess[]) => {
                    const mergedValues: LoadSpecialMeasuresSuccess = specialMeasures[0];

                    mergedValues.specialMeasures.PRESCRIPTION_DATA.PRESCRIPTIONS = [
                        ...specialMeasures[0].specialMeasures.PRESCRIPTION_DATA.PRESCRIPTIONS,
                        ...specialMeasures[1].specialMeasures.PRESCRIPTION_DATA.PRESCRIPTIONS,
                        ...specialMeasures[2].specialMeasures.PRESCRIPTION_DATA.PRESCRIPTIONS
                    ];

                    return mergedValues;
                }),
                catchError((error: any) => of(new LoadSpecialMeasuresFail(error)))
            );
        }),
    ));

    public loadSpecialMeasuresFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadSpecialMeasuresFail>(SpecialMeasuresActionType.LOAD_SPECIAL_MEASURES_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'specialMeasures.notifications.load.error',
            }))
        ]),
    ));

    /************************/
    /*******  UPDATE  *******/
    /************************/
    public updateOnePrescription$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOnePrescription>(SpecialMeasuresActionType.UPDATE_ONE_PRESCRIPTION),
        mergeMap((action: UpdateOnePrescription) => {
            return this.apiService
                .put<any, UpdatedPrescription>(`${action.path}/demandes/mesures/securisation/sauvegarder`, action.prescription).pipe(
                    map((updatedSpecialMeasuresPrescription: any) => new UpdateOnePrescriptionSuccess(updatedSpecialMeasuresPrescription)),
                    catchError((error: any) => of(new UpdateOnePrescriptionFail(error))),
                );
        }),
    ));

    public updateOnePrescriptionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOnePrescriptionSuccess>(SpecialMeasuresActionType.UPDATE_ONE_PRESCRIPTION_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'specialMeasures.notifications.update.success',
            }))
        ]
        ),
    ));

    public updateOnePrescriptionFail$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOnePrescriptionFail>(SpecialMeasuresActionType.UPDATE_ONE_PRESCRIPTION_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'specialMeasures.notifications.update.error',
            }))
        ]),
    ));
}
