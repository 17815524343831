import { TableColumns } from '../../../../../shared/models/table-columns.model';

export const profilFormationColumns: TableColumns[] = [
    { field: 'CII', header: 'CII' },
    { field: 'NomComplet', header: 'NomComplet' },
    { field: 'NoFormation', header: 'NoFormation' },
    { field: 'TitreFormation', header: 'TitreFormation' },
    { field: 'TypeActivite', header: 'TypeActivite' },
    { field: 'DateFormationAchevee', header: 'DateFormationAchevee' }
];
