<div>
    <form [formGroup]="vehicleForm" (ngSubmit)="createVehicle()">
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField main" [hideRequiredMarker]="true">
                <mat-label>{{ translatePrefix + '.number' | translate }}<span class="field__required">*</span></mat-label>
                <input matInput type="text" formControlName="number" autocomplete="off" [tabIndex]="tabIndex">
            </mat-form-field>
        </div>
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField" [hideRequiredMarker]="true">
                <mat-label>{{ translatePrefix + '.description' | translate }}<span class="field__required">*</span></mat-label>
                <input matInput type="text" formControlName="description" autocomplete="off" [tabIndex]="tabIndex">
            </mat-form-field>
        </div>
        <app-generic-form-buttons [disabled]="vehicleForm.invalid" [cancelLogo]="'back'" (cancel)="cancelForm()" [freezeTabIndex]="freezeTabIndex">
        </app-generic-form-buttons>
    </form>
</div>
