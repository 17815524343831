import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from '../../shared/config/app-config.model';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {

    constructor(
        private httpClient: HttpClient,
        private configService: AppConfiguration,
    ) { }

    public get<T>(route: string): Observable<T> {
        return this.httpClient.get<T>(this.getApiPath(route));
    }

    public post<T, R>(route: string, body: R): Observable<T> {
        return this.httpClient.post<T>(this.getApiPath(route), body);
    }

    public put<T, R>(route: string, body: R): Observable<T> {
        return this.httpClient.put<T>(this.getApiPath(route), body);
    }

    public patch<T, R>(route: string, body: R): Observable<T> {
        return this.httpClient.patch<T>(this.getApiPath(route), body);
    }

    public delete<T>(route: string): Observable<T> {
        return this.httpClient.delete<T>(this.getApiPath(route));
    }

    protected getApiPath(route: string): string {
        return this.configService.baseBackendUrl + route;
    }
}
