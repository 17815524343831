<app-element-loader *ngIf="isBillingReportPreviewLoading$ | async"></app-element-loader>

<app-generic-detail-page-header
    [pageLogo]="'fas fa-eye'"
    [pageTitle]="'billing.common.result.billingReportTitle' | translate"
    (back)="backToReport()">
</app-generic-detail-page-header>

<div class="pageHeaderTable">
    <p-table [value]="billingReportPreviewPageHeaders">
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td class="rowHeadings">{{ rowData.rowTitle }}</td>
                <td>{{ rowData.rowValue }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="reportTable">
    <p-table *ngIf="billingReportPreviewRows" [value]="billingReportPreviewRows" sortField="rfdtNumber"
        sortMode="single" (onSort)="onSort()">
        <ng-template pTemplate="header">
            <tr>
                <th>{{ 'billing.common.reportForm.reportId' | translate }}</th>
                <th>{{ 'billing.common.result.rfdtWeek' | translate }}</th>
                <th>{{ 'billing.common.result.rfdtType' | translate }}</th>
                <th>{{ 'billing.common.result.itemDescription' | translate }}</th>
                <th>{{ 'billing.common.result.itemCode' | translate }}</th>
                <th>{{ 'billing.common.result.itemHours' | translate }}</th>
                <th>{{ 'billing.common.result.itemQuantites' | translate }}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr>
                <td class="groupingCell" *ngIf="rowGroupMetadata[rowData.groupByRfdtWeekTypeKey].index === rowIndex"
                    [attr.rowspan]="rowGroupMetadata[rowData.groupByRfdtWeekTypeKey].size">
                    <span class="p-text-bold p-ml-2">{{ rowData.rfdtNumber }}</span>
                </td>
                <td class="groupingCell" *ngIf="rowGroupMetadata[rowData.groupByRfdtWeekTypeKey].index === rowIndex"
                    [attr.rowspan]="rowGroupMetadata[rowData.groupByRfdtWeekTypeKey].size">
                    {{rowData.rfdtWeek}}</td>
                <td class="groupingCell" *ngIf="rowGroupMetadata[rowData.groupByRfdtWeekTypeKey].index === rowIndex"
                    [attr.rowspan]="rowGroupMetadata[rowData.groupByRfdtWeekTypeKey].size">
                    {{rowData.rfdtType}}</td>
                <td class="groupingCell itemDescription"
                    *ngIf="rowGroupMetadata[rowData.groupByRfdtWeekTypeItemKey].index === rowIndex"
                    [attr.rowspan]="rowGroupMetadata[rowData.groupByRfdtWeekTypeItemKey].size">
                    {{rowData.itemDescription}}</td>
                <td class="itemCode">{{ rowData.itemCode }}</td>
                <td class="numericCell">{{ rowData.itemHours | number: '1.2' }}</td>
                <td class="numericCell">{{ rowData.itemQuantites | number: '1.2' }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<section class="actions">
    <div class="actions__spacer"></div>
    <button pButton icon="fa fa-download" class="actions__button right" type="button" (click)="exportExcel()"
        [label]="'common.export' | translate"></button>
</section>
