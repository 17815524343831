import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import moment, { Moment } from 'moment';
import { Table } from 'primeng/table';
import { FilterService, SelectItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';

import { tableUniteTravailColumns } from './table-unite-travail.column';

import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { TableCodeUnite, TableCodeUniteStatus, TableCodeUniteCycleType, TableCodeUniteCycleTypeTitle } from '../../../../models/table-code-unite.model';
import { TableColumns } from '../../../../../shared/models/table-columns.model';
import { trackByColumnField } from '../../../../../shared/helpers/track-by.helper';
import { CreateOneTableCodeUnite, LoadTableCodesUnite, UpdateOneTableCodeUnite } from '../../../../store/actions';
import { getAllTableCodesUnite, getTableCodesUniteLoading } from '../../../../store/selectors';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { PilotageFormModalComponent } from '../../modals/pilotage-form-modal/pilotage-form-modal.component';
import { PilotageFormModalData, PilotageFormModalDataFormName } from '../../modals/pilotage-form-modal/pilotage-form-modal-data.model';
import { doesSelectedUnitCodeIsActive } from '../../../../../ogiv-core/store/selectors';
import { LoadUnitCodes } from '../../../../../ogiv-core/store/actions';

@Component({
    selector: 'app-table-unite-travail',
    templateUrl: './table-unite-travail.component.html',
    styleUrls: ['./table-unite-travail.component.scss']
})
export class TableUniteTravailComponent implements OnInit, OnChanges, AfterViewInit {
    private readonly moduleName = 'tableUniteTravail';
    public selectedStatus: string | null = null;
    public selectedCycleType: SelectItem | null = null;
    public trackByColumnField = trackByColumnField;
    public columns: TableColumns[] = tableUniteTravailColumns;
    public translatePrefix = 'pilotages.gestionCodesSAP.tableUnitOfWork.';
    public sortField = 'Nom';
    public sortOrder = 1;

    public loaderLogoSize = 75;
    public rowIndiexOnEdition: number | null = null;

    public dateFiltersCreeLe: Moment[];
    public dateFiltersModifieLe: Moment[];

    public modifiedData = false;
    public filterChange = true;
    public checkboxesField: string[] = [];
    public tableCodesUnite: TableCodeUnite[] = [];
    public originalTableCodesUnite: TableCodeUnite[];
    public cloneTableCodesUnite: TableCodeUnite[] = [];
    public shouldApplyStatusFilter = true;
    public isRequiredValuesFilled = false;
    public isInputNatureComptableErrror = false;

    public cancelEdit = 'fas fa-times';
    public editLogo = 'fas fa-pen';
    public checkLogo = 'fas fa-check';
    public addLogo = 'fa fa-plus';
    public disabledCheckButton = false;

    public currentDropdowns: Dropdown[] = [];

    @Input() public pathChoice: AlternativePath;
    @Input() public reloadData = false;

    @Output() public selectedModule: EventEmitter<string> = new EventEmitter();
    @Output() public reloadedDone: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('codeUniteDataTable', { static: true }) private codeUniteDataTable: Table;
    @ViewChildren('editedDropdown') editedDropdown: QueryList<Dropdown>;

    public statuses: SelectItem[] = [
        { label: this.translateService.instant(this.translatePrefix + 'statuses.new'), value: TableCodeUniteStatus.new, disabled: true },
        { label: this.translateService.instant(this.translatePrefix + 'statuses.active'), value: TableCodeUniteStatus.active },
        { label: this.translateService.instant(this.translatePrefix + 'statuses.inactive'), value: TableCodeUniteStatus.inactive },
    ];

    public filterStatuses: SelectItem<string>[] = [
        { label: this.translateService.instant(this.translatePrefix + 'statuses.new'), value: TableCodeUniteStatus.new, disabled: false },
        { label: this.translateService.instant(this.translatePrefix + 'statuses.active'), value: TableCodeUniteStatus.active },
        { label: this.translateService.instant(this.translatePrefix + 'statuses.inactive'), value: TableCodeUniteStatus.inactive },
        { label: this.translateService.instant(this.translatePrefix + 'statuses.all'), value: TableCodeUniteStatus.all },
    ];

    public typeCycles: SelectItem[] = [
        {
            label: this.translateService.instant(this.translatePrefix + 'typeCycle.none'),
            value: TableCodeUniteCycleType.none,
            title: TableCodeUniteCycleTypeTitle.none,
        },
        {
            label: this.translateService.instant(this.translatePrefix + 'typeCycle.dlr'),
            value: TableCodeUniteCycleType.dlr,
            title: TableCodeUniteCycleTypeTitle.dlr
        },
        {
            label: this.translateService.instant(this.translatePrefix + 'typeCycle.dlrCc'),
            value: TableCodeUniteCycleType.dlrCc,
            title: TableCodeUniteCycleTypeTitle.dlrCc
        },
        {
            label: this.translateService.instant(this.translatePrefix + 'typeCycle.dlu'),
            value: TableCodeUniteCycleType.dlu,
            title: TableCodeUniteCycleTypeTitle.dlu
        },
        {
            label: this.translateService.instant(this.translatePrefix + 'typeCycle.dluCc'),
            value: TableCodeUniteCycleType.dluCc,
            title: TableCodeUniteCycleTypeTitle.dluCc
        },
    ];

    /**********************************************************************/
    // Observables from store
    /**********************************************************************/
    public tableCodesUnite$: Observable<TableCodeUnite[] | null> = this.store.pipe(
        select(getAllTableCodesUnite),
        distinctUntilChanged(),
        tap((tableCodesUnite: TableCodeUnite[]) => {
            this.reloadedDone.emit(true);
            this.reset(tableCodesUnite, true);
            this.resetVisual();
        }),
    );
    public tableCodesUniteLoading$: Observable<boolean> = this.store.pipe(
        select(getTableCodesUniteLoading),
    );

    public constructor(
        private readonly store: Store,
        public dialog: MatDialog,
        private translateService: TranslateService,
        private filterService: FilterService,
    ) { }

    /**********************************************************************/
    // Angular life cycle Hooks methods
    /**********************************************************************/
    public loadSapValues(): void {
        this.store.dispatch(new LoadUnitCodes());
    }

    public loadAllValues(): void {
        this.store.dispatch(new LoadTableCodesUnite(this.pathChoice));
        this.loadSapValues();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.reloadData && changes.reloadData.currentValue) {
            this.loadAllValues();
        }
    }

    public ngOnInit(): void {
        this.loadAllValues();
        this.selectedModule.emit(this.moduleName);

        // Added for MB-ANGULAR-11
        this.filterService.register('dateRangeFilterModifieLe', (value: any): boolean => {
            return this.filtrerDates(value, this.dateFiltersModifieLe);
        });

        // Added for MB-ANGULAR-11
        this.filterService.register('dateRangeFilterCreeLe', (value: any): boolean => {
            return this.filtrerDates(value, this.dateFiltersCreeLe);
        });

        // // Remove for MB-ANGULAR-11
        // FilterUtils['dateRangeFilterModifieLe'] = (value: any): boolean => {
        //     return this.filtrerDates(value, this.dateFiltersModifieLe);
        // };

        // // Remove for MB-ANGULAR-11
        // FilterUtils['dateRangeFilterCreeLe'] = (value: any): boolean => {
        //     return this.filtrerDates(value, this.dateFiltersCreeLe);
        // };

        this.selectedStatus = this.statuses[0].value;
        this.shouldApplyStatusFilter = true;
    }

    public ngAfterViewInit(): void {
        this.editedDropdown.changes.subscribe(data =>
            data.forEach((dropdown: Dropdown) => {
                this.currentDropdowns.forEach((currentDropdown: Dropdown) => {
                    if (currentDropdown.selectId !== dropdown.selectId) {
                        this.currentDropdowns.push(dropdown);
                    }
                });
            })
        );
    }

    /**********************************************************************/
    // Class methods
    /**********************************************************************/
    /**************************/
    // Ré-initialiser les datas
    /**************************/
    public reset(tableCodesUnite: TableCodeUnite[], applyStatusFilter: boolean): void {
        this.tableCodesUnite = [...tableCodesUnite];
        this.originalTableCodesUnite = [...tableCodesUnite];

        if (this.codeUniteDataTable && applyStatusFilter) {
            this.shouldApplyStatusFilter = false;
            this.applyStatusFilter(tableCodesUnite);
        }
    }

    public resetVisual(): void {
        this.cloneTableCodesUnite = [];
        this.modifiedData = false;
        this.rowIndiexOnEdition = null;
    }

    public disableCheckButton(rowIndex: number): void {
        this.checkRequiredValuesFilled(this.cloneTableCodesUnite[this.tableCodesUnite[rowIndex].id]);

        if (this.modifiedData && this.isRequiredValuesFilled) {
            this.disabledCheckButton = false;
        } else {
            this.disabledCheckButton = true;
        }
    }

    /**************************/
    // Filtrer les datas
    /**************************/
    public inputFilterColumn(value: Event, field: string, filterMatchMode?: string): void {
        this.columnFilter((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains');
    }

    public columnFilter(value: string | boolean | null, columnName: string, matchMode: string): void {
        this.filterChange = true;
        this.codeUniteDataTable.filter(value, columnName, matchMode);
    }

    public checkboxFilter(checked: boolean, columnName: string, matchMode: string): void {
        const value = checked || null;
        this.columnFilter(value, columnName, matchMode);
    }

    public applyStatusFilter(tableCodesUnite: TableCodeUnite[]): void {
        this.saveCodeUniteDataTableEditingRowKey({});
        this.rowIndiexOnEdition = null;

        const NEW_STATUS: string = this.filterStatuses[0].value;
        const CODE_WITH_STATUT_NEW = tableCodesUnite.filter((tableCodeUnite: TableCodeUnite) => tableCodeUnite.statut === NEW_STATUS);

        if (CODE_WITH_STATUT_NEW.length > 0) {
            this.selectedStatus = NEW_STATUS;
            this.columnFilter(NEW_STATUS, 'statut', 'equals');
        } else {
            this.columnFilter(TableCodeUniteStatus.all, 'statut', 'equals');
            this.selectedStatus = this.filterStatuses[3].value;
        }

    }

    public restructureObjects(event: any): void {
        if (this.filterChange) {
            this.filterChange = false;
            this.reset(event.filteredValue, this.shouldApplyStatusFilter);
        }
    }

    public filtrerDates(value: any, dateFilters: Moment[]): boolean {
        if (dateFilters) {
            const tableValue = moment(value);
            const firstFilterDate = moment(dateFilters[0]);
            const secondFilterDate = moment(dateFilters[1]);

            if (tableValue.isValid() && firstFilterDate.isValid()) {
                if (secondFilterDate.isValid()) {
                    return tableValue.isSameOrAfter(firstFilterDate, 'day') && tableValue.isSameOrBefore(secondFilterDate, 'day');
                } else {
                    return tableValue.isSame(firstFilterDate, 'day');
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    /**************************/
    // Modification du data
    /**************************/
    public checkRequiredNatureComptableFilled(tableCodesUnite: TableCodeUnite): boolean {
        if (tableCodesUnite.nature_comptable !== undefined
            && tableCodesUnite.nature_comptable !== null
            && tableCodesUnite.nature_comptable !== '') {
            this.isInputNatureComptableErrror = false;
        } else {
            this.isInputNatureComptableErrror = true;
        }

        return !this.isInputNatureComptableErrror;
    }
    public checkRequiredStatusFilled(tableCodesUnite: TableCodeUnite): boolean {
        return tableCodesUnite.statut !== TableCodeUniteStatus.new;
    }

    public checkRequiredValuesFilled(tableCodesUnite: TableCodeUnite): void {
        this.isRequiredValuesFilled = this.checkRequiredNatureComptableFilled(tableCodesUnite) && this.checkRequiredStatusFilled(tableCodesUnite);
    }

    public saveValue(value: string | number | boolean, columnName: any, rowIndex: number): void {
        const codeUnit = this.tableCodesUnite[rowIndex];
        if (codeUnit && this.cloneTableCodesUnite[codeUnit.id][columnName] !== undefined) {
            this.cloneTableCodesUnite[codeUnit.id][columnName] = value;

            this.areDataModified(rowIndex);
            this.disableCheckButton(rowIndex);
        }
    }

    public saveDropdown(value: string, tableCodesUnite: TableCodeUnite, columnName: any): void {
        const rowIndex = this.getIndexOfInOriginal(tableCodesUnite.id);
        this.saveValue(value, columnName, rowIndex);
    }

    public async saveDropdownStatus(value: string, tableCodesUnite: TableCodeUnite, columnName: any): Promise<void> {
        if (value === TableCodeUniteStatus.inactive) {
            this.saveDropdown(value, tableCodesUnite, columnName);
        } else if (value === TableCodeUniteStatus.active) {
            if (await this.verifyIfExist(tableCodesUnite)) {
                this.displayErrorExist(tableCodesUnite);
            } else {
                this.saveDropdown(value, tableCodesUnite, columnName);
            }
        }
    }

    public async verifyIfExist(tableCodesUnite: TableCodeUnite): Promise<boolean> {
        let itDoesExist = false;
        await this.store.select(doesSelectedUnitCodeIsActive({ code: tableCodesUnite.code_sap || '' })).subscribe(t => itDoesExist = t);
        return itDoesExist;
    }

    public displayErrorExist(tableCodesUnite: TableCodeUnite): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + 'codeExist' + '.title'),
                message: this.translateService.instant(this.translatePrefix + 'codeExist' + '.message', { code: tableCodesUnite.code_sap }),
                translatePrefix: this.translatePrefix + '.codeExist.boutons',
                logoString: 'fas fa-exclamation',
                isForDelete: true,
                displayDeleteButton: false
            } as ConfirmModalComponent,
            width: '560px',
            maxWidth: '560px',
            height: 'fit-content'
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.editedDropdown.forEach((dropdown: Dropdown) => {
                    if (dropdown.selectId === `dropdown-status-${tableCodesUnite.id}`) {
                        dropdown.selectedOption = { label: TableCodeUniteStatus.inactive, value: TableCodeUniteStatus.inactive };
                        dropdown.value = TableCodeUniteStatus.inactive;
                    }
                });
            }
        });
    }

    public saveInput(value: number | string, tableCodesUnite: TableCodeUnite, columnName: any): void {
        const rowIndex = this.getIndexOfInOriginal(tableCodesUnite.id);
        if (typeof value === 'string') {
            value = value.trim();
        }
        this.saveValue(value, columnName, rowIndex);
    }

    public saveCheckbox(event: any, tableCodesUnite: TableCodeUnite, columnName: any): void {
        const rowIndex = this.getIndexOfInOriginal(tableCodesUnite.id);

        const checked = event.checked;
        this.saveValue(checked, columnName, rowIndex);
    }

    public cancelModification(result: boolean, rowIndex: number): void {
        if (result) {
            if (this.rowIndiexOnEdition !== null && this.rowIndiexOnEdition !== rowIndex) {
                if (this.codeUniteDataTable.editingRowKeys[this.tableCodesUnite[this.rowIndiexOnEdition].id]) {
                    delete this.codeUniteDataTable.editingRowKeys[this.tableCodesUnite[this.rowIndiexOnEdition].id];
                }

                if (this.cloneTableCodesUnite[this.tableCodesUnite[this.rowIndiexOnEdition].id]) {
                    delete this.cloneTableCodesUnite[this.tableCodesUnite[this.rowIndiexOnEdition].id];
                }
                this.areDataModified(this.rowIndiexOnEdition);
            }

            if (this.rowIndiexOnEdition === rowIndex) {
                this.tableCodesUnite[this.rowIndiexOnEdition] = this.originalTableCodesUnite[this.rowIndiexOnEdition];
                if (this.cloneTableCodesUnite[this.tableCodesUnite[this.rowIndiexOnEdition].id]) {
                    delete this.cloneTableCodesUnite[this.tableCodesUnite[this.rowIndiexOnEdition].id];
                }
                delete this.codeUniteDataTable.editingRowKeys[this.tableCodesUnite[this.rowIndiexOnEdition].id];
                this.areDataModified(this.rowIndiexOnEdition);
            }
            this.rowIndiexOnEdition = null;

        } else {
            if (this.rowIndiexOnEdition !== null) {
                this.tableCodesUnite[this.rowIndiexOnEdition] = this.originalTableCodesUnite[this.rowIndiexOnEdition];
            }
        }
    }

    public areDataModified(rowIndex: number): void {
        const keys = Object.keys(this.tableCodesUnite[rowIndex]);
        const originalValues: any = {};
        const clonedValues: any = {};

        if (this.cloneTableCodesUnite[this.tableCodesUnite[rowIndex].id] !== undefined) {
            keys.forEach((key: string) => {
                if (this.checkboxesField.includes(key)) {
                    originalValues[key] = (this.originalTableCodesUnite[rowIndex][key] === null || this.originalTableCodesUnite[rowIndex][key] === false) ?
                        '' : this.originalTableCodesUnite[rowIndex][key];
                    clonedValues[key] = (this.cloneTableCodesUnite[this.tableCodesUnite[rowIndex].id][key] === null
                        || this.cloneTableCodesUnite[this.tableCodesUnite[rowIndex].id][key] === false) ?
                        '' : this.cloneTableCodesUnite[this.tableCodesUnite[rowIndex].id][key];
                } else {
                    originalValues[key] = this.originalTableCodesUnite[rowIndex][key];
                    clonedValues[key] = this.cloneTableCodesUnite[this.tableCodesUnite[rowIndex].id][key];
                }
            });

            const original = JSON.stringify(originalValues);
            const cloned = JSON.stringify(clonedValues);

            if (cloned === original) {
                this.modifiedData = false;
            } else {
                this.modifiedData = true;
            }
        } else {
            this.modifiedData = false;
        }
    }

    public emptyObject(object: any): boolean {
        return Object.keys(object).length === 0;
    }

    /**************************/
    // Interaction avec utilisateur
    /**************************/
    public addUniteOfWork(): void {
        const dialogRef = this.dialog.open(PilotageFormModalComponent, {
            width: '560px',
            data: {
                title: this.translateService.instant(this.translatePrefix + 'form' + '.title'),
                translatePrefix: this.translatePrefix,
                logoString: this.addLogo,
                formName: PilotageFormModalDataFormName.tableCodesUnite
            } as PilotageFormModalData
        });

        dialogRef.afterClosed().subscribe((result: TableCodeUnite) => {
            if (result !== undefined) {
                this.store.dispatch(new CreateOneTableCodeUnite(result, this.pathChoice));
            }
        });
    }

    public changeSelection(tableCodesUnite: TableCodeUnite, rowIndex: number): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + 'changeSelectionConfirmModalComponent' + '.title'),
                message: this.translateService.instant(this.translatePrefix + 'changeSelectionConfirmModalComponent' + '.message'),
                translatePrefix: this.translatePrefix + 'changeSelectionConfirmModalComponent',
                logoString: this.cancelEdit,
                isForDelete: true,
            } as ConfirmModalComponent,
            width: '525px',
            height: 'fit-content'
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            this.cancelModification(result, rowIndex);
            this.finishOnRowEditInit(tableCodesUnite, rowIndex, result);
        });
    }

    public saveCodeUniteDataTableEditingRowKey(value: number | string | {}) {
        this.codeUniteDataTable.editingRowKeys = typeof value === 'number' ? { [value as any]: true } : {};
    }

    public finishOnRowEditInit(tableCodesUnite: TableCodeUnite, rowIndex: number, result: boolean): void {
        if (result) {
            if (this.rowIndiexOnEdition !== null && this.rowIndiexOnEdition !== rowIndex) {
                if (this.codeUniteDataTable.editingRowKeys[this.tableCodesUnite[this.rowIndiexOnEdition].id]) {
                    delete this.codeUniteDataTable.editingRowKeys[this.tableCodesUnite[this.rowIndiexOnEdition].id];
                }

                if (this.cloneTableCodesUnite[this.tableCodesUnite[this.rowIndiexOnEdition].id]) {
                    delete this.cloneTableCodesUnite[this.tableCodesUnite[this.rowIndiexOnEdition].id];
                }
            }

            this.saveCodeUniteDataTableEditingRowKey(tableCodesUnite.id);

            if (this.cloneTableCodesUnite[tableCodesUnite.id]) {
                this.tableCodesUnite[rowIndex] = { ...this.cloneTableCodesUnite[tableCodesUnite.id] };
            } else {
                this.cloneTableCodesUnite[tableCodesUnite.id] = { ...this.tableCodesUnite[rowIndex] };
            }
            this.disableCheckButton(rowIndex);
        }
    }

    public onRowEditInit(tableCodesUnite: TableCodeUnite, event: any): void {
        event.stopPropagation();
        this.loadSapValues();
        const rowIndex = this.getIndexOfInOriginal(tableCodesUnite.id);

        if (this.modifiedData) {
            if (!this.emptyObject(this.codeUniteDataTable.editingRowKeys)) {
                this.changeSelection(tableCodesUnite, rowIndex);
            }
        } else {
            this.finishOnRowEditInit(tableCodesUnite, rowIndex, true);
            this.rowIndiexOnEdition = rowIndex;
        }
    }

    public sendDataToServer(rowIndex: number): void {
        const dataToSend = { ...this.cloneTableCodesUnite[this.tableCodesUnite[rowIndex].id] };
        dataToSend.demande_client = dataToSend.demande_client || false;
        dataToSend.ajout_unitaire = dataToSend.ajout_unitaire || false;
        dataToSend.ajout_horaire = dataToSend.ajout_horaire || false;
        dataToSend.detail_requis = dataToSend.detail_requis || false;

        delete this.cloneTableCodesUnite[this.tableCodesUnite[rowIndex].id];

        this.store.dispatch(new UpdateOneTableCodeUnite(dataToSend, this.pathChoice));
    }

    public onRowEditSave(tableCodesUnite: TableCodeUnite, event: any): void {
        event.stopPropagation();
        const rowIndex = this.getIndexOfInOriginal(tableCodesUnite.id);
        delete this.codeUniteDataTable.editingRowKeys[this.tableCodesUnite[rowIndex].id];
        this.rowIndiexOnEdition = null;

        this.sendDataToServer(rowIndex);
    }

    public onRowEditCancel(tableCodesUnite: TableCodeUnite, event: any): void {
        event.stopPropagation();
        const rowIndex = this.getIndexOfInOriginal(tableCodesUnite.id);

        if (this.modifiedData) {
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
                data: {
                    title: this.translateService.instant(this.translatePrefix + 'cancelConfirmModalComponent' + '.title'),
                    message: this.translateService.instant(this.translatePrefix + 'cancelConfirmModalComponent' + '.message'),
                    translatePrefix: this.translatePrefix + 'cancelConfirmModalComponent',
                    logoString: this.cancelEdit,
                    isForDelete: true,
                    logoFontSize: 24,
                } as ConfirmModalComponent,
                width: '525px',
                height: 'fit-content'
            });

            dialogRef.afterClosed().subscribe((result: boolean) => {
                this.cancelModification(result, rowIndex);
            });
        } else {
            if (this.cloneTableCodesUnite[this.tableCodesUnite[rowIndex].id]) {
                delete this.cloneTableCodesUnite[this.tableCodesUnite[rowIndex].id];
            }
            delete this.codeUniteDataTable.editingRowKeys[this.tableCodesUnite[rowIndex].id];
            this.rowIndiexOnEdition = null;
        }
    }

    public getIndexOfInOriginal(searchId: number) {
        return this.tableCodesUnite.findIndex((item: TableCodeUnite) => {
            return item.id === searchId;
        });
    }
}
