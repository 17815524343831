import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { TreeTableModule } from 'primeng/treetable';
import { InputTextModule } from 'primeng/inputtext';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';

import { DragDropModule } from '@angular/cdk/drag-drop';

export const demandesClientVendorsModules = [
    ToastModule,
    TableModule,
    TreeTableModule,
    ButtonModule,
    TooltipModule,
    FieldsetModule,
    InputTextModule,
    DropdownModule,
    ConfirmDialogModule,
    MultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    OverlayPanelModule,
    ScrollPanelModule,
    PanelModule,
    InputTextareaModule,
    DialogModule,

    // Material
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatDividerModule,
    MatTabsModule,

    // CDK
    DragDropModule,
];
