import { FormGroup } from '@angular/forms';
import { StatusReportFilters } from '../../../../../../shared/models/status-report-filters.model';
import { TableColumns } from '../../../../../../shared/models/table-columns.model';
import { Observable } from 'rxjs';
import { TimesheetReportSummary } from '../../../../../../shared/models/database/timesheet-report-summary.model';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ReportFilters, ReportFiltersRaw } from '../../../../../../shared/models/report-filters.model';
import { ModalWindowComponent } from '../../../../../../shared/components/modal-window/modal-window.component';
import { ModalWindowData } from '../../../../../../shared/components/modal-window/modal-window-data.model';

export abstract class RoleTimesheetReporstList {
    public abstract localStorageName: string;
    public abstract translatePrefix: string;
    public abstract statuses: StatusReportFilters[];
    public abstract cols: TableColumns[];

    public sortField = 'week';
    public sortOrder = -1;
    public coreTranslatePrefix = 'timesheet.reports';
    public dateSelectForm: FormGroup;
    public fr: any = {
        firstDayOfWeek: 0,
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aou', 'Sept', 'Oct', 'Nov', 'Dec'],
        today: 'Aujourd\'hui',
        clear: 'Effacer',
        weekHeader: 'Sem',
    };

    public abstract timesheetRepportSummary$: Observable<TimesheetReportSummary[]>;
    public abstract timesheetRepportSummaryLoading$: Observable<boolean>;

    constructor(
        protected dialog: MatDialog,
        protected readonly store: Store,
    ) { }

    public abstract reload(): void;

    public getFilter(localStorageName: string): ReportFilters {
        const reportFilter = new ReportFilters();
        reportFilter.fetchFilterFromLocalStorage(localStorageName);
        return reportFilter;
    }

    public abstract onGoToReport(report?: TimesheetReportSummary): void;
    public abstract applyFilter(event: ReportFiltersRaw): void;

    public onDisplayRemarks(report: TimesheetReportSummary): void {
        this.dialog.open(ModalWindowComponent, {
            width: '80%',
            maxWidth: '650px',
            data: {
                icon: 'fas fa-exclamation',
                componentName: 'displayRemark',
                titleKey: 'title',
                translatePrefix: 'shared.remarkPopup',
                report: report,
            } as ModalWindowData
        });
    }
}
