import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
    MealCodeActionType,
    LoadMealCodes,
    LoadMealCodesSuccess,
    LoadMealCodesFail,
    LoadMealCodesNoResult
} from '../actions/meal-codes.action';
import { ApiService } from '../../../ogiv-core/services/api.service';
import { MealCode } from '../../../shared/models/database/meal-code.model';

@Injectable()
export class MealCodesEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService,
    ) { }

    public loadMealCode$ = createEffect(() => this.action$.pipe(
        ofType<LoadMealCodes>(MealCodeActionType.LOAD_MEAL_CODES),
        switchMap(() => {
            return this.apiService.get<MealCode[]>('sap/data/mealCodes').pipe(
                map((returnedMealCodes: MealCode[]) =>
                    (returnedMealCodes.length > 0)
                        ? new LoadMealCodesSuccess(returnedMealCodes)
                        : new LoadMealCodesNoResult()
                ),
                catchError((error: any) => of(new LoadMealCodesFail(error))),
            );
        }),
    ));
}
