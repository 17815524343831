<h3 mat-dialog-title>
    <ng-container *ngIf="logoString !== null">
        <i class="{{ logoString }} space"></i>
    </ng-container>
    {{ title }}
</h3>
<div mat-dialog-content>
    <app-element-loader *ngIf="profilFormationUtilisateurLoading$ | async" [size]="75"></app-element-loader>
    <div *ngIf="profilFormationUtilisateur$ | async as tableData">
        <app-profil-formation-utilisateur-table
            [translatePrefix]="translatePrefix + '.profilFormationTable'"
            [columns]="profilFormationColumns" [data]="tableData" [sortOrder]="sortOrder">
        </app-profil-formation-utilisateur-table>
    </div>
</div>
