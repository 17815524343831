<h3>{{ 'timesheet.reports.general.filters.title' | translate }}</h3>
<mat-accordion>
    <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngStyle]="{'overflow': 'unset'}">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'40px'">
                <div *ngIf="!panelOpenState" class="header">
                    <div *ngIf="isEmptyValues()" class="header__emptyText">
                        Aucune filtre sélectionné
                    </div>
                    <div *ngIf="!isEmptyValues()">
                        <div *ngIf="filterForm.controls.week.value !== null" class="header__component">
                            <div class="component__text">
                                {{ 'timesheet.reports.general.filters.fields.date.title' | translate }} :
                            </div>
                            <div class="component__container">
                                {{ 'timesheet.reports.general.filters.fields.date.description' | translate }} <b>{{ getSelectedDate() }}</b>
                            </div>
                        </div>
                        <div *ngIf="isOneStatusSelected()" class="header__component">
                            <div class="component__text">
                                {{ 'timesheet.reports.general.filters.fields.status.title' | translate }} :
                            </div>
                            <div class="component__container">
                                <ng-container *ngFor="let status of statusesFormArray.controls; let i = index">
                                    <div *ngIf="currentStatuses[i].display && status.value" class="container__wrap">
                                        <app-tag [status]="currentStatuses[i].name" class="wrap__tag">{{ currentStatuses[i].name }}</app-tag>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div *ngIf="isOnePartialStatusSelected()" class="header__component">
                            <div class="component__text">
                                {{ 'timesheet.reports.general.filters.fields.partialStatus.title' | translate }} :
                            </div>
                            <div class="component__container">
                                <ng-container *ngFor="let partialStatus of partialStatusesFormArray.controls; let i = index">
                                    <div *ngIf="currentPartielStatuses && currentPartielStatuses[i].display && partialStatus.value" class="container__wrap">
                                        <app-tag [status]="currentPartielStatuses[i].name" class="wrap__tag">{{ currentPartielStatuses[i].name }}</app-tag>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div *ngIf="displayOtherFilters()" class="header__component">
                            <div class="component__text">
                                {{ 'timesheet.reports.general.filters.fields.otherFilter.title' | translate }} :
                            </div>
                            <div class="component__container">
                                <b>{{ columnTranslatePrefix+filterForm.controls.columnName.value | translate }}</b> {{ 'timesheet.reports.general.filters.fields.otherFilter.description' | translate }} <b>{{ filterForm.controls.searchValue.value }}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="panelOpenState">
                    <h4>
                        {{ 'timesheet.reports.general.filters.fields.accodionTitle' | translate }}
                    </h4>
                </div>
        </mat-expansion-panel-header>

        <div class="filters">
            <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">
                <section class="filters__container">
                    <div class="container__title">
                        <div class="title__text">
                            {{ 'timesheet.reports.general.filters.fields.date.title' | translate }}
                        </div>
                    </div>
                    <div class="container__component wrap paddingBottom">
                        <div class="component__precalandar">
                            {{ 'timesheet.reports.general.filters.fields.date.description' | translate }}
                        </div>
                        <div class="date-select__calendar">
                            <p-calendar formControlName="week" dateFormat="dd MM yy" [showIcon]="true" class="calendar"
                                [inputStyle]="{'font-size':'14px', 'width': '200px'}">
                            </p-calendar>
                        </div>
                    </div>
                </section>
                <mat-divider></mat-divider>
                <section class="filters__container">
                    <div class="container__title">
                        <div class="title__text">
                            {{ 'timesheet.reports.general.filters.fields.status.title' | translate }}
                        </div>
                    </div>
                    <div class="container__component wrap paddingBottom">
                        <div formArrayName="statuses" *ngFor="let status of statusesFormArray.controls; let i = index" class="component__status">
                            <mat-checkbox *ngIf="currentStatuses[i].display" [checked]="status.value" class="status__checkbox" [value]="currentStatuses[i].name" (change)="changeSelectedStatuses($event, i)">
                                <div [ngStyle]="{'height': '100%', 'width' : '100%' }">
                                    <app-tag [isButton]="true" [status]="currentStatuses[i].name" class="checkbox__tag">{{ currentStatuses[i].name }}</app-tag>
                                </div>
                            </mat-checkbox>
                        </div>
                    </div>
                </section>
                <ng-container *ngIf="partialStatuses !== undefined">
                    <mat-divider></mat-divider>
                    <section class="filters__container">
                        <div class="container__title">
                            <div class="title__text">
                                {{ 'timesheet.reports.general.filters.fields.partialStatus.title' | translate }}
                            </div>
                        </div>
                        <div class="container__component wrap paddingBottom">
                            <div formArrayName="partialStatuses" *ngFor="let partialStatus of partialStatusesFormArray.controls; let i = index" class="component__status">
                                <mat-checkbox *ngIf="currentPartielStatuses && currentPartielStatuses[i].display" [checked]="partialStatus.value" class="status__checkbox" [value]="currentPartielStatuses[i].name" (change)="changeSelectedPartialStatuses($event, i)">
                                    <div [ngStyle]="{'height': '100%', 'width' : '100%' }">
                                        <app-tag [isButton]="true" [status]="currentPartielStatuses[i].name" class="checkbox__tag">{{ currentPartielStatuses[i].name }}</app-tag>
                                    </div>
                                </mat-checkbox>
                            </div>
                        </div>
                    </section>
                </ng-container>
                <mat-divider></mat-divider>
                <section class="filters__container">
                    <div class="container__title">
                        <div class="title__text">
                            {{ 'timesheet.reports.general.filters.fields.otherFilter.title' | translate }}
                        </div>
                    </div>
                    <div class="container__component">
                        <mat-form-field appearance="fill">
                            <mat-label> {{ 'timesheet.reports.general.filters.fields.otherFilter.dDplaceholder' | translate }}</mat-label>
                            <mat-select formControlName="columnName">
                                <ng-container *ngFor="let column of searchColumn">
                                    <mat-option  *ngIf="isFiltereble(column)" [value]="column.field">{{ columnTranslatePrefix+column.header | translate }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <span class="component__simpleText">{{ 'timesheet.reports.general.filters.fields.otherFilter.description' | translate }}</span>
                        <mat-form-field appearance="fill">
                            <input matInput formControlName="searchValue" placeholder="{{ 'timesheet.reports.general.filters.fields.otherFilter.inputPlaceholder' | translate }}">
                        </mat-form-field>
                    </div>
                </section>
                <mat-divider></mat-divider>
                <section class="filters__actions">
                    <button mat-button type="button" class="actions__button left" (click)="resetFitlers(false)">{{ 'timesheet.reports.general.filters.emptyAllButton' | translate | uppercase }}</button>
                    <div class="actions__spacer"></div>
                    <button mat-button type="button" class="actions__button right" (click)="resetFitlers()">{{ 'timesheet.reports.general.filters.resetButton' | translate | uppercase }}</button>
                    <button mat-raised-button type="submit" class="actions__button right apply">{{ 'timesheet.reports.general.filters.applyFilter' | translate | uppercase }}</button>
                </section>
            </form>
        </div>
    </mat-expansion-panel>
</mat-accordion>
