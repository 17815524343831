import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import moment from 'moment';

@Component({
    selector: 'app-remark-cell',
    templateUrl: './remark-cell.component.html',
    styleUrls: ['./remark-cell.component.scss'],
})
export class RemarkCellComponent implements OnChanges {
    public site = '';
    public formatedDate = '';

    @Input() public title: string;
    @Input() public remark: string | null = null;
    @Input() public status: string | null = null;
    @Input() public siteName: string | null = null;
    @Input() public date: string | null = null;
    @Input() public name: string | null = null;
    @Input() public displaySiteName = false;
    @Input() public translationPrefix: string;

    constructor() { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.siteName && changes.siteName.currentValue) {
            this.site = changes.siteName.currentValue;
        }

        if (changes && changes.date && changes.date.currentValue) {
            this.formatedDate = moment(changes.date.currentValue).format('DD-MM-yyyy');
        }
    }

    public displayFooter(): boolean {
        return this.date !== null || this.name !== null;
    }
}
