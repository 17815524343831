import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import moment, { Moment } from 'moment';
import { FilterService } from 'primeng/api';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { TableColumns } from '../../../../../shared/models/table-columns.model';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { UtilisateurSynchro, UtilisateurSynchroType } from '../../../../models/utilisateur-synchronisation.model';
import { ConfirmModalData } from '../../modals/confirm-modal/confirm-modal-data.model';
import { PilotagesState } from '../../../../store/reducers';
import { DeleteOneReplica, LoadEtatSynchronisations } from '../../../../store/actions';
import { getAllEtatSynchronisations, getEtatSynchronisationsLoading } from '../../../../store/selectors/etat-synchronisations.selector';
import { utilisateursSynchronisationColumns } from './etat-synchronisations-table.column';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-etat-synchronisations-table',
    templateUrl: './etat-synchronisations-table.component.html',
    styleUrls: ['./etat-synchronisations-table.component.scss']
})
export class EtatSynchronisationsTableComponent implements OnInit, OnChanges {
    public columns: TableColumns[] = utilisateursSynchronisationColumns;

    @Input() public translatePrefix: string;
    @Input() public sortField: string;
    @Input() public sortOrder: number;
    @Input() public data: UtilisateurSynchro[];
    @Input() public pathChoice: AlternativePath;
    @Input() public reloadData = false;

    @Output() public selectedModule: EventEmitter<string> = new EventEmitter();
    @Output() public reloadedDone: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('etatFormationDataTable') private etatFormationDataTable: Table;

    public loaderLogoSize = 75;
    public dateFiltersReplica: Moment[];
    public dateFiltersSynchro: Moment[];
    public syncRowOver: number | null = null;

    public types: any[] = [
        {
            label: this.translateService.instant('pilotages.utilisateurs-synchronisation.table-utilisateurs-synchronisation.inventory'),
            value: UtilisateurSynchroType.inventory
        },
        {
            label: this.translateService.instant('pilotages.utilisateurs-synchronisation.table-utilisateurs-synchronisation.execution'),
            value: UtilisateurSynchroType.execution
        },
    ];

    public etatSynchronisationsLoading$: Observable<boolean> = this.store.pipe(
        select(getEtatSynchronisationsLoading),
    );

    public etatSynchronisations$: Observable<UtilisateurSynchro[] | null> = this.store.pipe(
        select(getAllEtatSynchronisations),
        distinctUntilChanged(),
    );

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.reloadData && changes.reloadData.currentValue) {
            this.store.dispatch(new LoadEtatSynchronisations(this.pathChoice));
        }
    }

    public ngOnInit(): void {
        this.store.dispatch(new LoadEtatSynchronisations(this.pathChoice));
        this.selectedModule.emit('EtatSync');

        // Added for MB-ANGULAR-11
        this.filterService.register('dateRangeFilterReplica', (value: any): boolean => {
            return this.filtrerDates(value, this.dateFiltersReplica);
        });

        // Remove for MB-ANGULAR-11
        // FilterUtils['dateRangeFilterReplica'] = (value: any): boolean => {
        //     return this.filtrerDates(value, this.dateFiltersReplica);
        // };

        // Added for MB-ANGULAR-11
        this.filterService.register('dateRangeFilterSynchro', (value: any): boolean => {
            return this.filtrerDates(value, this.dateFiltersSynchro);
        });

        // Remove for MB-ANGULAR-11
        // FilterUtils['dateRangeFilterSynchro'] = (value: any): boolean => {
        //     return this.filtrerDates(value, this.dateFiltersSynchro);
        // };
    }

    constructor(
        private readonly store: Store<PilotagesState>,
        public dialog: MatDialog,
        private translateService: TranslateService,
        private filterService: FilterService,
    ) { }

    public filtrerDates(value: any, dateFilters: Moment[]): boolean {
        if (dateFilters) {
            const tableValue = moment(value);
            const firstFilterDate = moment(dateFilters[0]);
            const secondFilterDate = moment(dateFilters[1]);

            if (tableValue.isValid() && firstFilterDate.isValid()) {
                if (secondFilterDate.isValid()) {
                    return tableValue.isSameOrAfter(firstFilterDate, 'day') && tableValue.isSameOrBefore(secondFilterDate, 'day');
                } else {
                    return tableValue.isSame(firstFilterDate, 'day');
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public onDeleteReplica(utilisateurSynchro: UtilisateurSynchro): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: {
                title: this.translateService.instant(this.translatePrefix + '.deleteDialog' + '.title'),
                message: this.translateService.instant(this.translatePrefix + '.deleteDialog' + '.message'),
                translatePrefix: this.translatePrefix + '.boutons',
                logoString: 'far fa-trash-alt',
                isForDelete: true
            } as ConfirmModalData,
            width: 'fit-content',
            height: 'fit-content'
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.store.dispatch(new DeleteOneReplica(utilisateurSynchro as UtilisateurSynchro, AlternativePath.hq));
            }
        });
    }

    public rowOver(index: number | null) {
        this.syncRowOver = index;
    }

    public callFunction(utilisateurSynchro: UtilisateurSynchro): void {
        if (utilisateurSynchro['canDelete'] === 'true' && this.syncRowOver !== null) {
            this.onDeleteReplica(utilisateurSynchro);
        }
    }

    public inputFilterColumn(value: Event, field: string, filterMatchMode?: string): void {
        this.etatFormationDataTable.filter((value.target as HTMLInputElement).value, field, filterMatchMode || 'contains');
    }

    public inputGlobalFilter(value: Event, filterMatchMode?: string): void {
        this.etatFormationDataTable.filterGlobal((value.target as HTMLInputElement).value, filterMatchMode || 'contains');
    }
}
