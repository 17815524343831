
import { FormationUtilisateur } from '../../models/profil-formation-utilisateur.model';
import { ProfilFormationAction, ProfilFormationActionType } from '../actions';

export interface ProfilFormationUtilisateurState {
    loading: boolean;
    loaded: boolean;
    formationCounts: FormationUtilisateur[];
}

export const formationUtilisateursInitialState: ProfilFormationUtilisateurState = {
    loading: false,
    loaded: false,
    formationCounts: []
};

export function formationUtilisateurReducer(
    state: ProfilFormationUtilisateurState = formationUtilisateursInitialState,
    action: ProfilFormationAction,
): ProfilFormationUtilisateurState {
    switch (action.type) {
        case ProfilFormationActionType.LOAD_PROFIL_FORMATION: {
            return {
                ...state,
                loading: true,
            };
        }

        case ProfilFormationActionType.LOAD_PROFIL_FORMATION_SUCCESS: {
            return {
                ...state,
                formationCounts: action.profilFormation,
                loading: false,
                loaded: true,
            };
        }

        case ProfilFormationActionType.LOAD_PROFIL_FORMATION_NO_RESULTS: {
            return {
                ...state,
                formationCounts: action.profilFormation,
                loading: false,
                loaded: true,
            };
        }

        case ProfilFormationActionType.LOAD_PROFIL_FORMATION_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
