import { CanActivate, Params } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { AlternativePath } from '../../shared/models/atlernative-path.model';
import { getRouterStateUrl } from '../../core/store/selectors';
import { LoadBillingReportById } from '../store/actions';
import { RouterStateUrl } from '../../core/store/models';
import { shouldLoadGivenBillingReport } from '../store/selectors';

interface ShouldLoadBillingReport {
    shouldLoad: boolean;
    billingReportId: number;
}

@Injectable()
export class LoadBillingReportGuard implements CanActivate {
    constructor(
        private readonly store: Store,
    ) { }

    public canActivate(): Observable<boolean> {
        let pathChoice = AlternativePath.ent;
        return this.store.pipe(
            select(getRouterStateUrl)
        ).pipe(
            tap((state: RouterStateUrl) => {
                if (state && state.data && state.data.pathChoice) {
                    pathChoice = state.data.pathChoice;
                }
            }),
            map((state: RouterStateUrl) => state && state.params || {}),
            switchMap((params: Params) => {
                return this.store.pipe(
                    select(shouldLoadGivenBillingReport),
                    map((shouldLoad: boolean) => ({ shouldLoad, billingReportId: params.billingReportId }))
                );
            }),
            map(({ shouldLoad, billingReportId }: ShouldLoadBillingReport) => {
                if (billingReportId && shouldLoad) {
                    this.store.dispatch(new LoadBillingReportById(billingReportId, pathChoice));
                }

                return !shouldLoad;
            }),
            filter(hasLoaded => hasLoaded),
            take(1),
        );
    }
}
