<div class="orderForm">
    <form [formGroup]="orderForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="standard" class="formField" [hideRequiredMarker]="true">
            <mat-label>{{ translatePrefix + '.orderNumber' | translate }}<span class="formField__required">*</span></mat-label>
            <input matInput type="text" formControlName="number" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="standard" class="formField" [hideRequiredMarker]="true">
            <mat-label>{{ translatePrefix + '.accountingEntry' | translate }}<span class="formField__required">*</span></mat-label>
            <input matInput type="text" formControlName="accountingEntry" autocomplete="off">
        </mat-form-field>

        <mat-label class="field__label">{{ translatePrefix + '.billingType' | translate }}<span class="label__required">*</span></mat-label>
        <div class="field__billingType">
            <mat-button-toggle-group class="billingType__component" name="orderStyle" aria-label="Font Style"
                formControlName="billingType">
                <mat-button-toggle class="component__button" value="{{ unitly }}" [disabled]="isUnitlyExist">
                    {{ translatePrefix + '.unitly' | translate }}</mat-button-toggle>
                <mat-button-toggle class="component__button" value="{{ hourly }}" [disabled]="isHourlyExist">
                    {{ translatePrefix + '.hourly' | translate }}</mat-button-toggle>
                <mat-button-toggle class="component__button" value="{{ outage }}" [disabled]="isOutageExist">
                    {{ translatePrefix + '.outage' | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <app-generic-form-buttons [disabled]="orderForm.invalid" (cancel)="cancelForm()"></app-generic-form-buttons>
    </form>
</div>
