import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { BillingItem } from '../../../shared/models/database/billing-item.model';
import { BillingItemAction, BillingItemActionType } from '../actions/billing-item.action';

export interface BillingItemsState extends EntityState<BillingItem> {
    loading: boolean;
    loaded: boolean;
}

export const billingItemsAdapter: EntityAdapter<BillingItem> = createEntityAdapter<BillingItem>({
    selectId: (billingItem: BillingItem) => billingItem.id,
});

export const billingItemsInitialState = billingItemsAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function billingItemReducer(
    state: BillingItemsState = billingItemsInitialState,
    action: BillingItemAction,
): BillingItemsState {
    switch (action.type) {
        case BillingItemActionType.LOAD_BILLING_ITEMS: {
            return {
                ...state,
                loading: true,
            };
        }
        case BillingItemActionType.LOAD_BILLING_ITEMS_SUCCESS: {
            return billingItemsAdapter.setAll(action.billingItems, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case BillingItemActionType.LOAD_BILLING_ITEMS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
