import { Component, Output, EventEmitter, Input, AfterViewInit, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
    selector: 'app-generic-form-buttons',
    templateUrl: './generic-form-buttons.component.html',
    styleUrls: ['./generic-form-buttons.component.scss'],
})
export class GenericFormButtonsComponent implements AfterViewInit, OnChanges {
    public tabIndex = 0;
    public disableButton = false;

    @Input() public disabled: boolean;
    @Input() public cancelLogo: 'cancel' | 'back' = 'cancel';
    @Input() public freezeTabIndex = false;
    @Input() public displayCancelButton = true;

    @Input() public cancelTooltipLabel: string | null = null;
    @Input() public saveTooltipLabel: string | null = null;
    // Position du tooltip (valeur possible: 'left' | 'right' | 'above' | 'below' | 'before' | 'after')
    @Input() public toolTipPosition: TooltipPosition = 'above';

    @Output() public save: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('cancelButton') cancelButton: ElementRef<HTMLElement>;
    @ViewChild('saveButton') saveButton: ElementRef<HTMLElement>;

    constructor(private _focusMonitor: FocusMonitor) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.freezeTabIndex && changes.freezeTabIndex.currentValue !== undefined && changes.freezeTabIndex.currentValue !== null) {
            this.tabIndex = changes.freezeTabIndex.currentValue ? -1 : 0;
        }

        if (changes && changes.disabled && changes.disabled.currentValue !== undefined && changes.disabled.currentValue !== null) {
            this.disableButton = changes.disabled.currentValue;
        }
    }

    ngAfterViewInit() {
        this._focusMonitor.stopMonitoring(this.cancelButton);
        this._focusMonitor.stopMonitoring(this.saveButton);
    }

    public canceled(): void {
        this.cancel.emit(true);
    }

    public saved(): void {
        this.save.emit(true);
    }
}
