import { UnitRawData } from '../../models/database/unit.model';

export const units: UnitRawData[] = [
    {
        id: 1,
        postLineId: 4,
        code: 'C-EA1',
        description: '(C-EA1) Élag.DCL/Access./Densité 1/50%',
        quantity: 3,
        hour: 4,
        fromInventory: true,
        detail: null,
    },
    {
        id: 2,
        postLineId: 5,
        code: 'EA1',
        description: '(EA1) Élagage/Accessible/Densité 1/50%',
        quantity: 5,
        hour: 6,
        fromInventory: false,
        detail: null,
    },
    {
        id: 3,
        postLineId: 1,
        code: 'C-A1A',
        description: '(C-A1A) Abattage DCL/01-10/Accessible',
        quantity: 2,
        hour: 1,
        fromInventory: false,
        detail: null,
    },
    {
        id: 4,
        postLineId: 8,
        code: 'STA',
        description: '(STA) Surplomb/Trois mètres/Accessible',
        quantity: 6,
        hour: 1,
        fromInventory: false,
        detail: null,
    },
    {
        id: 5,
        postLineId: 6,
        code: 'STA',
        description: '(STA) Surplomb/Trois mètres/Accessible',
        quantity: 7,
        hour: 5,
        fromInventory: false,
        detail: null,
    },
    {
        id: 6,
        postLineId: 7,
        code: 'EA1',
        description: '(EA1) Élagage/Accessible/Densité 1/50%',
        quantity: 9,
        hour: 4,
        fromInventory: false,
        detail: null,
    },
    {
        id: 7,
        postLineId: 6,
        code: 'STA',
        description: '(STA) Surplomb/Trois mètres/Accessible',
        quantity: 2,
        hour: 1,
        fromInventory: false,
        detail: null,
    },
    {
        id: 8,
        postLineId: 9,
        code: 'EA1',
        description: '(EA1) Élagage/Accessible/Densité 1/50%',
        quantity: 1,
        hour: 3,
        fromInventory: false,
        detail: null,
    },
    {
        id: 9,
        postLineId: 3,
        code: 'C-EA1',
        description: '(C-EA1) Élag.DCL/Access./Densité 1/50%',
        quantity: 8,
        hour: 3,
        fromInventory: true,
        detail: null,
    },
];
