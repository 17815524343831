import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-modal-info-order-status',
    templateUrl: './modal-info-order-status.component.html',
    styleUrls: ['./modal-info-order-status.component.scss'],
})
export class ModalInfoOrderStatusComponent {
    public displayCancelButton = false;
    public tranlationPrefix = 'timesheet.general.status.legendForm';

    @Input() public message: string;
    @Output() public closed: EventEmitter<boolean> = new EventEmitter();

    constructor() { }

    public onClose(): void {
        this.closed.emit();
    }
}
