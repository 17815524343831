<app-generic-module-header
    [moduleLogo]="'fas fa-dollar-sign'"
    [moduleTitle]="'billing.contractor.summary.title' | translate"
    [hideButton]="true"
></app-generic-module-header>

<app-billing-report-table *ngLet="billingReports$ | async as billingReports" [translatePrefix]="translatePrefix"
    [cols]="cols" [reports]="billingReports" [sortField]="sortField" [sortOrder]="sortOrder" [actions]="actions"
    (tableEvent)="onTableEvent($event)" (displayRemark)="onDisplayRemarks($event)" [isEnabledActionButtonFunction]="isEnabledActionButton"
    [pathChoice]="pathChoice">
</app-billing-report-table>

<app-element-loader *ngIf="billingReportsLoading$ | async" [size]="100" [displayLabel]="true" [position]="'fixed'"></app-element-loader>

<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
