import moment from 'moment';

export class BillingReportPreviewPageHeader {
    rowTitle: string;
    rowValue: string;
}

export class BillingReportPreviewRawData {
    rowId: string;
    company: string;
    invoiceID: number;
    invoiceNumber: string;
    invoiceOrder: string;
    invoiceAccountingEntry: string;
    invoiceDate: string;
    rfdtID: number | null;
    rfdtNumber: string | null;
    rfdtWeek: string | null;
    rfdtWeekNumber: number | null;
    rfdtType: string | null;
    itemDescription: string;
    itemCode: string;
    itemQuantites: number | null;
    itemHours: number | null;
}

export class BillingReportPreviewRow extends BillingReportPreviewRawData {

    groupByRfdtWeekTypeKey: string;
    groupByRfdtWeekTypeItemKey: string;

    constructor(data: BillingReportPreviewRawData) {
        super();
        Object.keys(data).forEach((key: string) => {
            this[key] = data[key];
        });
        this.rfdtNumber = this.rfdtNumber === null ? '-' : this.rfdtNumber;
        this.rfdtWeek = data?.rfdtWeek ? moment(data?.rfdtWeek).format('yyyy-MM-DD') : '-';
        this.rfdtType = this.rfdtType === null ? '-' : this.rfdtType;
        this.groupByRfdtWeekTypeKey = `${data.rfdtNumber}-${data.rfdtWeek}-${data.rfdtType}`;
        this.groupByRfdtWeekTypeItemKey = `${data.rfdtNumber}-${data.rfdtWeek}-${data.rfdtType}-${data.itemDescription}`;
    }
}
