/**
 * Types
 */

import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { ProgressReportGlobalWrapper } from '../../../shared/models/database/progress-report-global-wrapper.model';

export enum ProgressReportsGlobalActionType {
    LOAD_PROGRESS_REPORTS_GLOBAL = '[ProgressReportsGlobal] Load Progress Reports Global',
    LOAD_PROGRESS_REPORTS_GLOBAL_SUCCESS = '[ProgressReportsGlobal] Load Progress Reports Global Success',
    LOAD_PROGRESS_REPORTS_GLOBAL_FAIL = '[ProgressReportsGlobal] Load Progress Reports Global Fail',
    LOAD_PROGRESS_REPORTS_GLOBAL_NO_RESULTS = '[ProgressReportsGlobal] Load Progress Reports Global No Result'
}

/**
 *  ACTIONS
 */
/**
 * LOAD
 */

export class LoadProgressReportsGlobal implements Action {
    readonly type = ProgressReportsGlobalActionType.LOAD_PROGRESS_REPORTS_GLOBAL;
    constructor(public queryString: string, public path: AlternativePath) { }
}

export class LoadProgressReportsGlobalSuccess implements Action {
    readonly type = ProgressReportsGlobalActionType.LOAD_PROGRESS_REPORTS_GLOBAL_SUCCESS;
    constructor(public globalReportWrapper: ProgressReportGlobalWrapper) { }
}

export class LoadProgressReportsGlobalFail implements Action {
    readonly type = ProgressReportsGlobalActionType.LOAD_PROGRESS_REPORTS_GLOBAL_FAIL;
    constructor(public error: any) { }
}

export class LoadProgressReportsGlobalNoResult implements Action {
    readonly type = ProgressReportsGlobalActionType.LOAD_PROGRESS_REPORTS_GLOBAL_NO_RESULTS;
}

export type ProgressReportsGlobalAction =
    | LoadProgressReportsGlobal
    | LoadProgressReportsGlobalSuccess
    | LoadProgressReportsGlobalFail
    | LoadProgressReportsGlobalNoResult;
