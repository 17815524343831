<app-generic-module-header
    [moduleLogo]="'fas fa-tasks'"
    [moduleTitle]="'demandes-projet.titre' | translate"
    [hideButton]="true"
></app-generic-module-header>

<div class="p-grid">
    <div class="p-col">
        <span class="file-icon excel-icon">
            <i class="fa fa-file-excel" aria-hidden="true"></i>
        </span>
        <a href='https://lk.hydro.qc.ca/lk/cs.exe/Open/172916573' target='_blank'>{{ 'demandes-projet.feuille-de-route'
            | translate }}</a>
    </div>
    <div class="p-col">
        <span class="file-icon word-icon">
            <i class="fa fa-file-word" aria-hidden="true"></i>
        </span>
        <a href='https://lk.hydro.qc.ca/lk/cs.exe/Open/161564910' target='_blank'>{{
            'demandes-projet.guide-feuille-de-route' | translate }}</a>
    </div>
    <div class="p-col-6"></div>
</div>
