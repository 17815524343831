import { ChangeDetectorRef, Component, NgZone, AfterViewInit, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

import { BusyRouterService, ComponentBase, ComponentFeatures, DoInit, InheritsBaseLifecycleHooks } from '@ngxhq/common-ui';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { OpenIdService } from '@ngxhq/security';
import { Store } from '@ngrx/store';

import { I18nService } from './core/services/i18n.service';
import { CoreState } from './core/store/reducers';
import { GetUserFromClaims } from './core/store/actions';
import { UIService } from './ogiv-core/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                width: '263px',
            })),
            state('closed', style({
                width: '64px',
            })),
            state('mobileOpen', style({
                width: '263px',
            })),
            state('mobileClosed', style({
                width: '0px',
            })),
            transition('open => closed, mobileOpen => mobileClosed, open => mobileClosed', [
                animate('0.10s ease-out')
            ]),
            transition('closed => open, mobileClosed => mobileOpen', [
                animate('0.10s ease-out')
            ]),
            transition('open => mobileClosed', [
                animate('0s ease-out')
            ]),
        ])
    ],
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class AppComponent extends ComponentBase implements DoInit, AfterViewInit {
    public layout = 'shell';
    public isMainMenuExpanded = false;
    public autoLogoutIn: number | undefined;
    private initNavigation = true;
    public hoverCloseButton = false;
    public displayName = true;
    public navigationMode: MatDrawerMode = 'side';
    public closeName = '';
    public openName = '';
    public alwaysOpen: boolean;
    public isOpen: boolean = false;
    public documentWidthUpdated = false;
    private mobileWidth = 480;

    @ViewChild('menu', { static: true }) menu: MatSidenav;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private busyRouterService: BusyRouterService,
        private openIdService: OpenIdService,
        private zone: NgZone,
        private i18nService: I18nService,
        private readonly store: Store<CoreState>,
        private titleService: Title,
        private translate: TranslateService,
        private uiService: UIService,
        private config: PrimeNGConfig,
    ) {
        super();
        this.activateMainMenuAutoExpansion();

        if (document.documentElement.clientWidth <= this.mobileWidth) {
            this.isMainMenuExpanded = false;
        } else {
            this.isMainMenuExpanded = true;
        }
    }

    public async doInit(): Promise<void> {
        this.activateApplicationAutoLayout();
        this.activateNavigationBusyState();
        this.activateMainMenuAutoExpansion();
        this.listenToSessionTimeout();

        this.i18nService.init();
        this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
        this.translate.get('header.title').subscribe((translatedString: string) => this.titleService.setTitle(translatedString));

        const claims = await this.openIdService.getClaims();
        const userInfo = await this.openIdService.getUserInfo();

        this.store.dispatch(new GetUserFromClaims(
            userInfo || {} as any,
            claims || {},
        ));

        this.uiService.documentWidth$.pipe(
            tap((width: number) => {
                if (width <= this.mobileWidth) {
                    this.alwaysOpen = false;
                } else {
                    this.alwaysOpen = true;
                }
                this.setUpMenuCondtions();
                this.setMenuExpanded(this.isMainMenuExpanded);
            })
        ).subscribe();

        this.setUpMenuCondtions();
        this.setMenuExpanded(this.isMainMenuExpanded);
    }

    public ngAfterViewInit(): void {
        this.uiService.setDocumentWidth(document.documentElement.clientWidth);
    }

    private activateApplicationAutoLayout() {
        this.router.events
            .pipe(
                filter((event) => event instanceof ActivationEnd),
                tap((event) => this.setComponentLayout(event as ActivationEnd)),
                takeUntil(this.destroyed)
            )
            .subscribe();
    }

    private setComponentLayout(event: ActivationEnd): void {
        const newLayout = event.snapshot.data &&
            event.snapshot.data.layout ?
            event.snapshot.data.layout : 'shell';

        if (newLayout !== this.layout) {
            this.layout = newLayout;
            this.changeDetectorRef.detectChanges();
        }
    }

    private activateNavigationBusyState() {
        this.busyRouterService.showBusyWhenNavigating()
            .pipe(takeUntil(this.destroyed))
            .subscribe();
    }

    private activateMainMenuAutoExpansion() {
        this.router.events
            .pipe(
                tap((event) => {
                    if (event instanceof NavigationStart) {
                        this.detectInitialNavigation();
                    } else if (event instanceof NavigationEnd) {
                        this.handleMainMenuExpansion();
                    }
                }),
                takeUntil(this.destroyed)
            )
            .subscribe();
    }

    private detectInitialNavigation(): void {
        this.initNavigation = !this.router.navigated;
    }
    private setUpMenu(isExpended: boolean, navMode: MatDrawerMode, closeName: string, openName: string): void {
        this.navigationMode = navMode;
        this.closeName = closeName;
        this.openName = openName;
        this.isOpen = isExpended;
        this.alwaysOpen = isExpended;
    }

    private setUpMenuCondtions(): void {
        const isMobile = this.uiService.getDocumentWidth() <= this.mobileWidth;

        if (isMobile) {
            this.setUpMenu(false, 'over', 'mobileClosed', 'mobileOpen');
        } else if (this.initNavigation) {
            this.setUpMenu(true, 'side', 'closed', 'open');
        }
    }
    private handleMainMenuExpansion(): void {
        this.setUpMenuCondtions();

        this.setMenuExpanded(this.isMainMenuExpanded);
        this.uiService.setMenuIsExpended(this.isMainMenuExpanded);
    }

    private listenToSessionTimeout() {
        this.openIdService.idleTimeoutRemainingSeconds
            .subscribe((x: number | undefined) => {
                this.zone.run(() => {
                    this.autoLogoutIn = x;
                });
            }, (err: any) => {
                console.error('idleTimeoutRemainingSeconds failed', err);
                this.zone.run(() => {
                    this.autoLogoutIn = undefined;
                });
            });
    }

    public setMenuExpanded(value: boolean): void {
        this.isMainMenuExpanded = value;
        this.uiService.setMenuIsExpended(this.isMainMenuExpanded);

        if (this.alwaysOpen) {
            this.isOpen = true;
        } else {
            this.isOpen = value;
            if (this.menu && this.navigationMode === 'over') {
                void this.menu.toggle();
            }
        }

        if (!this.documentWidthUpdated) {
            this.uiService.setDocumentWidth(document.documentElement.clientWidth);
            this.documentWidthUpdated = true;
        }
    }

    public backdropClick(): void {
        this.setMenuExpanded(false);
    }
}
