<app-generic-accordion [title]="postLine.postElect + '-' + postLine.line" [icon]="'fas fa-map-pin'"
    [bubbleIcon]="'fas fa-list-ol'" [totalTime]="hoursOfUnits" [totalQuantity]="numberOfUnits"
    [canAddEditTime]="canAddEditTime" [isLock]="isLock">
    <app-generic-other-menu actions *hasRole="editAllowedRoles" [addOnly]="true" [addborder]="true" [displayMenu]="displayMenu" [hideItems]="hideButtonMenu"
    [isLock]="isLock" (add)="addUnit($event)" (edit)="editPostLine($event, postLine)" (delete)="deletePostLine($event, postLine)">
    </app-generic-other-menu>
    <app-work-done-list itemsList [pathChoice]="pathChoice" [editAllowedRoles]="editAllowedRoles" [units]="postLine.units || []" [isLock]="isLock" [canAddEditTime]="canAddEditTime"
        [timesheetDateId]="timesheetDateId" [orderType]="orderType">
    </app-work-done-list>
</app-generic-accordion>
