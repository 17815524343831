import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { CommonUiModule } from '@ngxhq/common-ui';
import { ThemeHqCommonUiSupportModule } from '@ngxhq/theme-ngxhq';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { DemandesProjetRoutingModule } from './demandes-projet-routing.module';
import { demandesProjetVendorsModules } from './demandes-projet-vendors-modules';
import { DemandesProjetComponent } from './components/demandes-projet.component';
import { demandesProjetComponents } from './';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CommonUiModule,
        SharedModule,
        ThemeHqCommonUiSupportModule,
        TranslateModule,
        ReactiveFormsModule,

        // Vendors
        ...demandesProjetVendorsModules,

        // Routing
        DemandesProjetRoutingModule,

    ],
    declarations: [
        DemandesProjetComponent,
        ...demandesProjetComponents,
    ],
    providers: []
})
export class DemandesProjetModule { }
