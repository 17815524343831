import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { map, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Go, GoReload, RouterActionsTypes } from '../actions/router.actions';
import { WindowRefService } from '../../services/window-ref.service';

@Injectable()
export class RouterEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private location: Location,
        private windowRef: WindowRefService,
    ) { }

    public navigate$ = createEffect(() => this.actions$.pipe(
        ofType(RouterActionsTypes.GO),
        map((action: Go) => action.payload),
        tap(async ({ path, query: queryParams, extras }) => {
            await this.router.navigate(path, { queryParams, ...extras });
        }),
    ), { dispatch: false });

    public navigateAndReload$ = createEffect(() => this.actions$.pipe(
        ofType(RouterActionsTypes.GO_RELOAD),
        map((action: GoReload) => action.payload),
        tap(async ({ path, query: queryParams, extras }) => {
            await this.router.navigate(path, { queryParams, ...extras });
            this.windowRef.nativeWindow.location.reload();
        }),
    ), { dispatch: false });

    public navigateBack$ = createEffect(() => this.actions$.pipe(
        ofType(RouterActionsTypes.BACK),
        tap(() => {
            this.location.back();
        }),
    ), { dispatch: false });

    public navigateForward$ = createEffect(() => this.actions$.pipe(
        ofType(RouterActionsTypes.FORWARD),
        tap(() => {
            this.location.forward();
        }),
    ), { dispatch: false });
}
