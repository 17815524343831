import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { GestionCodesSAP } from '../../models/gestion-code-sap.model';
import { GestionCodesSAPAction, GestionCodesSAPActionType } from '../actions';

export interface GestionCodesSAPState extends EntityState<GestionCodesSAP> {
    loading: boolean;
    loaded: boolean;
}

export const gestionCodesSAPAdapter: EntityAdapter<GestionCodesSAP> = createEntityAdapter<GestionCodesSAP>({
    selectId: (gestionCodesSAP: GestionCodesSAP) => gestionCodesSAP.id,
});

export const gestionCodesSAPInitialState = gestionCodesSAPAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function gestionCodesSAPReducer(
    state: GestionCodesSAPState = gestionCodesSAPInitialState,
    action: GestionCodesSAPAction,
): GestionCodesSAPState {
    switch (action.type) {
        case GestionCodesSAPActionType.UPDATE_ONE_GESTION_CODES_SAP:
        case GestionCodesSAPActionType.LOAD_GESTION_CODES_SAP: {
            return {
                ...state,
                loading: true,
            };
        }
        case GestionCodesSAPActionType.UPDATE_ONE_GESTION_CODES_SAP_SUCCESS: {
            return gestionCodesSAPAdapter.upsertOne(action.gestionCodeSAP, {
                ...state,
                loading: false,
            });
        }

        case GestionCodesSAPActionType.UPDATE_ONE_GESTION_CODES_SAP_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case GestionCodesSAPActionType.UPDATE_ALL_GESTION_CODES_SAP_SUCCESS:
        case GestionCodesSAPActionType.LOAD_GESTION_CODES_SAP_SUCCESS: {
            return gestionCodesSAPAdapter.setAll(action.gestionCodesSAP, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case GestionCodesSAPActionType.LOAD_GESTION_CODES_SAP_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case GestionCodesSAPActionType.LOAD_GESTION_CODES_SAP_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
