<mat-accordion [togglePosition]="'before'" [multi]="false">
    <ng-container *ngIf="displayOrders(timesheet)">
        <app-order-accordion *ngFor="let orderGroup of timesheet.orderGroups; index as accordionIndex; trackBy: orderGroupTracking;"
            [pathChoice]="pathChoice"
            [timesheet]="timesheet"
            [editAllowedRoles]="editAllowedRoles"
            [orderGroup]="orderGroup"
            [accordionIndex]="accordionIndex">
        </app-order-accordion>
    </ng-container>
</mat-accordion>
