import { APP_BASE_HREF, CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSidenavModule } from '@angular/material/sidenav';

/* Third parties */
import { ButtonModule } from 'primeng/button';
import { ThemeHqPrimeNgA11yModule } from '@ngxhq/theme-ngxhq';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

/* Shared Module*/
import { SharedModule } from './shared/shared.module';

/* App Root */
import { AppComponent } from './app.component';

/* Routing Module */
import { AppRoutingModule } from './app-routing.module';

/* Feature Modules */
import { CoreModule } from './core/core.module';
import { ShellModule } from './shell/shell.module';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { WINDOW } from './tokens/window.token';
import { WindowRefService } from './core/services/window-ref.service';
import { OgivCoreModule } from './ogiv-core/ogiv-core.module';
import { appGuards } from './guards/index';

/* Get global JS objects */
const getWindow = () => (typeof window !== 'undefined' ? window : null);

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LogoutComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        ButtonModule,
        ThemeHqPrimeNgA11yModule,
        MatSidenavModule,

        /* Non-lazy loaded Feature Modules */
        CoreModule,
        OgivCoreModule,
        ShellModule,
    ],
    providers: [
        ...appGuards,
        {
            provide: APP_BASE_HREF,
            useValue: '/',
        },
        {
            provide: WINDOW,
            useFactory: getWindow,
        },
        WindowRefService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() { }
}
