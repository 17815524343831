<div class="section" (click)="toggleTextArea('section')" (mouseover)="mouseOver()" (mouseout)="mouseOut()">
    <div class="section__title">
        <div class="title__info">
            <h3 *ngIf="fontLogo && title">
                <i *ngIf="fontLogo" class="{{fontLogo}} info__logo"></i>
                <span *ngIf="title" class="info__text">
                    {{ title | translate }}
                </span>
            </h3>
        </div>
        <div class="title__actions">
            <div *ngIf="!toggle && !disableEdition && !expliciteDisable" class="actions__activate">
                <div *ngIf="logoAdd" class="activate__button">
                    <button pButton pRipple type="button" class="p-button-rounded p-button-primary button__add" [class.buttonHover]="isOver"
                        icon="fas fa-plus" (click)="toggleTextArea()" [disabled]="disableByStatus" [pTooltip]="translatePrefix + '.add' | translate" tooltipPosition="top"></button>
                </div>
                <div *ngIf="!logoAdd" class="activate__button">
                    <button pButton pRipple type="button" class="p-button-rounded p-button-primary button__edit" [class.buttonHover]="isOver"
                        icon="fas fa-pen" (click)="toggleTextArea()" [disabled]="disableByStatus" [pTooltip]="translatePrefix + '.edit' | translate" tooltipPosition="top"></button>
                </div>
            </div>
            <div *ngIf="toggle" class="actions__enable">
                <div class="enable__button">
                    <button pButton pRipple type="button" class="p-button-rounded p-button-primary button__cancel" icon="fas fa-times"
                        (click)="onCancel($event)" [pTooltip]="translatePrefix + '.cancel' | translate"
                        tooltipPosition="top"></button>
                </div>
                <div class="enable__button">
                    <button pButton pRipple type="button" class="p-button-rounded p-button-primary button__save" icon="far fa-save"
                        (click)="onSave($event)" [pTooltip]="translatePrefix + '.save' | translate"
                        tooltipPosition="top" [disabled]="remarkForm.invalid"></button>
                </div>
                <div class="enable__button">
                    <button pButton pRipple type="button" class="p-button-rounded p-button-primary button__delete" icon="far fa-trash-alt"
                        (click)="onDelete($event)" [pTooltip]="translatePrefix + '.delete' | translate"
                        tooltipPosition="top" [disabled]="disabledDelete"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="section__remark">
        <div *ngIf="toggle">
            <form [formGroup]="remarkForm">
                <div class="remark__container">
                    <div class="container__text">
                        <textarea #remark class="text__textarea" rows="5" pInputTextarea [autoResize]="true"
                            formControlName="remark" maxlength="1000"
                            ></textarea>
                        </div>
                    <div class="container__hint">
                        <mat-hint [align]="'end'"> {{remark.value.length}} / 1000</mat-hint>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="!toggle">
            <div class="remark__text">
                {{value}}
            </div>
        </div>
    </div>
</div>
