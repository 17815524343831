export enum ModalEntityType {
    equipment = 'equipment',
    worker = 'worker',
    unit = 'unit',
    postLine = 'postLine',
    timesheet = 'timesheet',
    note = 'note',
    infoOrderStatus = 'infoOrderStatus',
    order = 'order',
}

export enum ModalType {
    delete = 'delete',
    submit = 'submit',
    info = 'info',
}

export interface ModalData {
    title: string;
    message: string;
    formType: ModalType;
    messageParams?: string[];
    entityType?: ModalEntityType;
    entity?: any;
    canAddEditTime?: boolean;
    displayOutageNumber?: boolean;
}
