import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { SelectItem } from 'primeng/api';
import moment from 'moment';

import { OgivCoreState, getOgivCoreState } from '../reducers';
import { billingItemsAdapter, BillingItemsState } from '../reducers/billing-item.reducer';
import { BillingItem, BillingItemStatus } from '../../../shared/models/database/billing-item.model';

const {
    selectAll,
    selectEntities,
    selectIds,
} = billingItemsAdapter.getSelectors();

export const getBillingItemsState = createSelector(
    getOgivCoreState,
    (state: OgivCoreState) => state.billingItems,
);

export const getBillingItemsLoading = createSelector(
    getBillingItemsState,
    (state: BillingItemsState) => state.loading,
);

export const getBillingItemsLoaded = createSelector(
    getBillingItemsState,
    (state: BillingItemsState) => state.loaded,
);

export const shouldLoadBillingItems = createSelector(
    getBillingItemsLoading,
    getBillingItemsLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllBillingItems = createSelector(
    getBillingItemsState,
    selectAll
);

export const getBillingItemsEntities = createSelector(
    getBillingItemsState,
    selectEntities,
);

export const getBillingItemsIds = createSelector(
    getBillingItemsState,
    selectIds,
);

export const getBillingItemById = (prop: any) => createSelector(
    getBillingItemsEntities,
    (entities: Dictionary<BillingItem>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllBillingItemsSorted = createSelector(
    getAllBillingItems,
    (billingItems: BillingItem[]): BillingItem[] => {
        const temporaire = [...billingItems];
        temporaire.sort((a: BillingItem, b: BillingItem): number => {
            return (a.code.toLocaleLowerCase()).localeCompare(b.code.toLocaleLowerCase());
        });
        return temporaire;
    }
);

export const getActiveBillingItems = (props: { date: string } = { date: '' }) => createSelector(
    getAllBillingItemsSorted,
    (billingItems: BillingItem[]): BillingItem[] => {
        const activeBillingItems = getAllActiveBillingItems(billingItems, props.date);
        return activeBillingItems;
    }
);

export const getActiveBillingItemsByCode = (props: { date: string, code: string } = { date: '', code: '' }) => createSelector(
    getAllBillingItemsSorted,
    (billingItems: BillingItem[]): BillingItem | null => {
        const foundItem = getAllActiveBillingItems(billingItems, props.date).find((billingItem: BillingItem) => billingItem.code === props.code);
        return foundItem || null;
    }
);

export const doesSelectedBillingItemsIsActive = (props: { code: string } = { code: '' }) => createSelector(
    getActiveBillingItems(),
    (billingItems: BillingItem[]): boolean => {
        const found = (billingItems.filter((billingItem: BillingItem) => billingItem.code === props.code));
        return found.length > 0;
    }
);

export const getBillingItemsSelectOptions = (props: { date: Date }) => createSelector(
    getAllBillingItemsSorted,
    (billingItems: BillingItem[]): SelectItem[] => {
        const billingItemsOptions = billingItems.filter(
            billingItem => billingItem.status.toLocaleLowerCase() === BillingItemStatus.active.toLocaleLowerCase()
        ).map((item: BillingItem) => {
            return {
                label: `(${item.code}) ${item.description}`,
                value: item.code,
                disabled: item.status === BillingItemStatus.inactive && moment(item.date_inactif || '1900-01-01') < moment(props.date)
            } as SelectItem;
        });

        return billingItemsOptions;
    }
);

function getAllActiveBillingItems(billingItems: BillingItem[], date?: string): BillingItem[] {
    let activeBillingItems;
    if (date === undefined || date === '') {
        activeBillingItems = billingItems.filter(billingItem => billingItem.status.toLocaleLowerCase() === BillingItemStatus.active.toLocaleLowerCase());
    } else {
        activeBillingItems = billingItems.filter(billingItem => billingItem.status.toLocaleLowerCase() === BillingItemStatus.active.toLocaleLowerCase()
            || moment(billingItem.date_inactif || '1900-01-01').format('YYYYMMDD') > date);
    }
    return activeBillingItems;
}
