import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';

import { RouterStateUrl } from '../models';
import { appNotificationsReducer, AppNotificationsState } from './app-notifications.reducer';
import { domaineDeValeurReducer, DomaineDeValeurState } from './domaine-valeur.reducers';
import { userReducer, UserState } from './user.reducer';

export interface CoreState {
    router: RouterReducerState<RouterStateUrl>;
    user: UserState;
    appNotifications: AppNotificationsState;
    domaineDeValeur: DomaineDeValeurState;
}

export const coreReducers: any = {
    router: routerReducer,
    user: userReducer,
    appNotifications: appNotificationsReducer,
    domaineDeValeur: domaineDeValeurReducer,
};

export const getCoreState = createFeatureSelector<CoreState>('core');
