import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiService } from '../../../ogiv-core/services';
import {
    GestionCodesSAPActionType,
    LoadGestionCodesSAP,
    LoadGestionCodesSAPSuccess,
    LoadGestionCodesSAPFail,
    LoadGestionCodesSAPNoResult,
    UpdateOneGestionCodeSAP,
    UpdateAllGestionCodeSAPSuccess,
    UpdateOneGestionCodeSAPFail,
} from '../actions';
import { GestionCodesSAP } from '../../models/gestion-code-sap.model';

@Injectable()
export class GestionCodesSAPEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService
    ) { }

    public loadGestionCodesSAP$ = createEffect(() => this.action$.pipe(
        ofType<LoadGestionCodesSAP>(GestionCodesSAPActionType.LOAD_GESTION_CODES_SAP),
        mergeMap((action: LoadGestionCodesSAP) => {
            return this.apiService.get<GestionCodesSAP[]>(`${action.path}/pilotage/import-sap`).pipe(
                map((returnedEntrepreneur: GestionCodesSAP[]) =>
                    (returnedEntrepreneur.length > 0)
                        ? new LoadGestionCodesSAPSuccess(returnedEntrepreneur)
                        : new LoadGestionCodesSAPNoResult()
                ),
                catchError((error: any) => of(new LoadGestionCodesSAPFail(error))),
            );

        }),
    ));

    public updatedGestionCodeSAP$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneGestionCodeSAP>(GestionCodesSAPActionType.UPDATE_ONE_GESTION_CODES_SAP),
        mergeMap((action: UpdateOneGestionCodeSAP) => {
            return this.apiService.put<GestionCodesSAP[], GestionCodesSAP>(`${action.path}/pilotage/import-sap/${action.gestionCodeSAP.id}`, action.gestionCodeSAP).pipe(
                map((updatedGestionCodeSAP: GestionCodesSAP[]) =>
                    new UpdateAllGestionCodeSAPSuccess(updatedGestionCodeSAP)
                ),
                catchError((error: any) => of(new UpdateOneGestionCodeSAPFail(error))),
            );
        })
    ));
}
