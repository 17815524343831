export class Arborist {
    public id: number;
    public cip: string;
    public firstName: string;
    public lastName: string;
    public companyId: number;
    public status: ArboristStatus;
    public createdBy?: string;
    public createdDate?: Date;
    public modifiedBy?: string;
    public modifiedDate?: Date;
}

export enum ArboristStatus {
    active = 'Actif',
    inactive = 'Inactif',
}
