import { Component, OnInit, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { MenuItem } from 'primeng/api/menuitem';
import { Observable, Subscription } from 'rxjs';
import { getRouterData } from '../../../../../core/store/selectors';
import { AlternativePath } from '../../../../../shared/models/atlernative-path.model';
import { getProgressReportsGlobalLoading } from '../../../../store/selectors/progress-reports-global.selector';
import { getProgressReportsSpecificLoading } from '../../../../store/selectors';
import { LoadProgressReportsGlobal } from '../../../../store/actions';
import { ReportsState } from '../../../../store/reducers';

@Component({
    selector: 'app-progress-reports-list',
    templateUrl: './progress-reports-list.component.html',
    styleUrls: ['./progress-reports-list.component.scss']
})
export class ProgressReportsListComponent implements OnInit, OnDestroy {
    public isProgressReportsGlobalLoading$: Observable<boolean> = this.store.pipe(
        select(getProgressReportsGlobalLoading)
    );
    public isProgressReportsSpecificLoading$: Observable<boolean> = this.store.pipe(
        select(getProgressReportsSpecificLoading)
    );

    public alternativePath: AlternativePath;
    public hasGlobalReportTabOpen = false;
    public hasReportByStatusTabOpen = false;
    public menuItems: MenuItem[] = [
        {
            label: this.translateService.instant('progressReport.general.links.globalReport'), icon: 'fa fa-file-alt', command: () => {
                this.hasGlobalReportTabOpen = true;
                this.hasReportByStatusTabOpen = false;
            },
        },
        {
            label: this.translateService.instant('progressReport.general.links.specificReport'), icon: 'fa fa-file-alt', command: () => {
                this.hasGlobalReportTabOpen = false;
                this.hasReportByStatusTabOpen = true;
            },
        }
    ];

    private subscriptions = new Subscription;

    constructor(
        public translateService: TranslateService,
        protected readonly store: Store<ReportsState>,
    ) { }

    ngOnInit(): void {
        this.setAlternativePath();
        this.loadProgressReports();
    }

    private setAlternativePath(): void {
        this.store.pipe(select(getRouterData)).subscribe((data: any) => {
            this.alternativePath = data && data.pathChoice;
        });
    }

    private loadProgressReports(): void {
        this.store.dispatch(new LoadProgressReportsGlobal('', this.alternativePath));
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
