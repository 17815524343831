import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DemandeClient } from '../../../../shared/models/database/demande-client.model';
import { DemandeClientRemarqueDialogDataModel } from './demande-client-remarque-dialog-data.model';

@Component({
    selector: 'app-demandes-client-remarque-dialog',
    templateUrl: './demandes-client-remarque-dialog.component.html',
    styleUrls: ['./demandes-client-remarque-dialog.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'remarqueDialogComponent'
    }
})
export class DemandesClientRemarqueDialogComponent {
    public isEditing: boolean;
    public existingNouvelleRemarque: string;
    public currentRemarque = '';
    public currentDemande: DemandeClient | undefined;
    public texteNouvelleRemarque: string;
    public headerNouvelleRemarque: string;
    public hasValue: boolean;

    constructor(
        public dialogRef: MatDialogRef<DemandesClientRemarqueDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: DemandeClientRemarqueDialogDataModel,
    ) {
        this.isEditing = data.isEditing;
        this.existingNouvelleRemarque = data.existingNouvelleRemarque || '';
        this.headerNouvelleRemarque = data.tagRemarque || '';

        this.showRemarque(data.demandeClient);
    }

    public showRemarque(demande: DemandeClient | undefined): void {
        this.currentDemande = demande;
        if (demande && demande.ordre) {
            this.currentRemarque = demande.ordre.remarque.split('\n').join('<br/>');
        }
        this.initShow();
    }

    public initShow(): void {
        this.texteNouvelleRemarque = this.existingNouvelleRemarque;
        this.hasValue = this.existingNouvelleRemarque !== '';
    }

    public onRemarqueChanged(value: Readonly<string>): void {
        this.hasValue = (value.trim() !== '');
    }

    public addNouvelleRemarque(): void {
        this.dialogRef.close(this.texteNouvelleRemarque);
    }

    public deleteNouvelleRemarque(): void {
        this.dialogRef.close('');
    }

    public closeRemarque(): void {
        this.dialogRef.close();
    }
}
