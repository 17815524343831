import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../../ogiv-core/services';
import {
    LoadUtilisateursExternes,
    UtilisateurExterneActionType,
    LoadUtilisateursExternesSuccess,
    LoadUtilisateursExternesNoResult,
    LoadUtilisateursExternesFail,
    CreateOneUtilisateurExterne,
    CreateUtilisateurExterneSuccess,
    CreateUtilisateurExterneFail,
    UpdateOneUtilisateurExterne,
    UpdateOneUtilisateurExterneSuccess,
    UpdateOneUtilisateurExterneFail,
    DeleteOneUtilisateurExterne,
    DeleteOneUtilisateurExterneSuccess,
    DeleteOneUtilisateurExterneFail,
    ReassignEntreprise,
    ReassignEntrepriseSuccess,
    ReassignEntrepriseFail,
    VerifierFDTIncompletes,
    VerifierFDTIncompletesSuccess,
    VerifierFDTIncompletesFail,
    FinalizeUserCreation,
    FinalizeUserCreationSuccess,
    FinalizeUserCreationFail
} from '../actions/utilisateurs-externes.action';
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators';
import { UtilisateurExterne } from '../../models/utilisateur-externe.model';
import { of } from 'rxjs';
import { VerificationFDT } from '../../models/verification-fdt.model';
import { AddOneAppNotification } from '../../../core/store/actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class UtilisateursExternesEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService
    ) { }

    /*************************/
    /*******  Loading  *******/
    /*************************/
    public loadUtilisateursExternes$ = createEffect(() => this.action$.pipe(
        ofType<LoadUtilisateursExternes>(UtilisateurExterneActionType.LOAD_UTILISATEURS_EXTERNES),
        switchMap((action: LoadUtilisateursExternes) => {
            return this.apiService.get<UtilisateurExterne[]>(`${action.path}/pilotage/utilisateurs-externes`).pipe(
                map((returnedUtilisateurExterne: UtilisateurExterne[]) =>
                    (returnedUtilisateurExterne.length > 0)
                        ? new LoadUtilisateursExternesSuccess(returnedUtilisateurExterne)
                        : new LoadUtilisateursExternesNoResult()
                ),
                catchError((error: any) => of(new LoadUtilisateursExternesFail(error))),
            );
        })
    ));

    /************************/
    /*******  CREATE  *******/
    /************************/
    public createOneUtilisateurExterne$ = createEffect(() => this.action$.pipe(
        ofType<CreateOneUtilisateurExterne>(UtilisateurExterneActionType.CREATE_UTILISATEUR_EXTERNE),
        mergeMap((action: CreateOneUtilisateurExterne) => {
            return this.apiService.post<UtilisateurExterne, UtilisateurExterne>(`${action.path}/pilotage/utilisateurs-externes`, action.utilisateurExterne).pipe(
                map((returnedUtilisateurExterne: UtilisateurExterne) => new CreateUtilisateurExterneSuccess(returnedUtilisateurExterne)),
                catchError((error: any) => of(new CreateUtilisateurExterneFail(error))),
            );
        })
    ));

    public createUtilisateurExterneSuccess$ = createEffect(() => this.action$.pipe(
        ofType<CreateUtilisateurExterneSuccess>(UtilisateurExterneActionType.CREATE_UTILISATEUR_EXTERNE_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'pilotages.notifications.utilisateurExterne.create.success',
            }))
        ])
    ));

    public createUtilisateurExterneFail$ = createEffect(() => this.action$.pipe(
        ofType<CreateUtilisateurExterneFail>(UtilisateurExterneActionType.CREATE_UTILISATEUR_EXTERNE_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.utilisateurExterne.create.error',
            }))
        ]),
    ));

    /************************/
    /*******  UPDATE  *******/
    /************************/
    public updateOneUtilisateurExterne$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneUtilisateurExterne>(UtilisateurExterneActionType.UPDATE_ONE_UTILISATEUR_EXTERNE),
        mergeMap((action: UpdateOneUtilisateurExterne) => {
            return this.apiService.put<UtilisateurExterne, UtilisateurExterne>(`hq/pilotage/utilisateurs-externes/${action.utilisateurExterne.id}`,
                action.utilisateurExterne).pipe(
                    map((updatedUtilisateurExterne: UtilisateurExterne) =>
                        new UpdateOneUtilisateurExterneSuccess(updatedUtilisateurExterne)
                    ),
                    catchError((error: any) => of(new UpdateOneUtilisateurExterneFail(error))),
                );
        })
    ));

    public updateOneUtilisateurExterneSuccess$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneUtilisateurExterneSuccess>(UtilisateurExterneActionType.UPDATE_ONE_UTILISATEUR_EXTERNE_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'pilotages.notifications.utilisateurExterne.updateOne.success',
            }))
        ])
    ));

    public updateOneUtilisateurExterneFail$ = createEffect(() => this.action$.pipe(
        ofType<UpdateOneUtilisateurExterneFail>(UtilisateurExterneActionType.UPDATE_ONE_UTILISATEUR_EXTERNE_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.utilisateurExterne.updateOne.error',
            }))
        ]
        ),
    ));

    /************************/
    /*******  DELETE  *******/
    /************************/
    public deleteOneUtilisateurExterne$ = createEffect(() => this.action$.pipe(
        ofType<DeleteOneUtilisateurExterne>(UtilisateurExterneActionType.DELETE_ONE_UTILISATEUR_EXTERNE),
        mergeMap((action: DeleteOneUtilisateurExterne) => {
            return this.apiService.delete<{ deletedId: number }>(`${action.path}/pilotage/utilisateurs-externes/${action.utilisateurExterneId}`).pipe(
                map((utilisateurExtern: { deletedId: number }) => new DeleteOneUtilisateurExterneSuccess(utilisateurExtern.deletedId)),
                catchError((error: any) => of(new DeleteOneUtilisateurExterneFail(error))),
            );
        })
    ));

    public deleteOneUtilisateurExterneSuccess$ = createEffect(() => this.action$.pipe(
        ofType<DeleteOneUtilisateurExterneSuccess>(UtilisateurExterneActionType.DELETE_ONE_UTILISATEUR_EXTERNE_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'pilotages.notifications.utilisateurExterne.delete.success',
            }))
        ])
    ));

    public deleteOneUtilisateurExterneFail$ = createEffect(() => this.action$.pipe(
        ofType<DeleteOneUtilisateurExterneFail>(UtilisateurExterneActionType.DELETE_ONE_UTILISATEUR_EXTERNE_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.utilisateurExterne.delete.error',
            }))
        ]
        ),
    ));

    /**************************/
    /*******  REASSIGN  *******/
    /**************************/
    public reassignEntreprise$ = createEffect(() => this.action$.pipe(
        ofType<ReassignEntreprise>(UtilisateurExterneActionType.REASSIGN_ENTREPRISE),
        mergeMap((action: ReassignEntreprise) => {
            return this.apiService.put<UtilisateurExterne[], UtilisateurExterne>(
                `hq/pilotage/utilisateurs-externes/${action.utilisateurExterne.id}/actions/reassigner-entreprise`,
                action.utilisateurExterne).pipe(
                    map((updatedUtilisateursExternes: UtilisateurExterne[]) =>
                        new ReassignEntrepriseSuccess(updatedUtilisateursExternes)
                    ),
                    catchError((error: any) => of(new ReassignEntrepriseFail(error))),
                );
        })
    ));

    public reassignEntrepriseSuccess$ = createEffect(() => this.action$.pipe(
        ofType<ReassignEntrepriseSuccess>(UtilisateurExterneActionType.REASSIGN_ENTREPRISE_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'pilotages.notifications.utilisateurExterne.reassing.success',
            }))
        ])
    ));

    public reassignEntrepriseFail$ = createEffect(() => this.action$.pipe(
        ofType<ReassignEntrepriseFail>(UtilisateurExterneActionType.REASSIGN_ENTREPRISE_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.utilisateurExterne.reassing.error',
            }))
        ]
        ),
    ));

    /**********************************/
    /*******  VERIFY INCOMPLET  *******/
    /**********************************/
    public verifyFDTIncompletes$ = createEffect(() => this.action$.pipe(
        ofType<VerifierFDTIncompletes>(UtilisateurExterneActionType.VERIFY_FDT_INCOMPLETES),
        mergeMap((action: VerifierFDTIncompletes) => {
            return this.apiService.get<VerificationFDT>(`hq/pilotage/utilisateurs-externes/${action.utilisateurExterneID}/actions/fdt/valider`).pipe(
                map((fdtIncompletes: VerificationFDT) =>
                    new VerifierFDTIncompletesSuccess(fdtIncompletes)
                ),
                catchError((error: any) => of(new VerifierFDTIncompletesFail(error))),
            );
        })
    ));

    public verifierFDTIncompletesFail$ = createEffect(() => this.action$.pipe(
        ofType<VerifierFDTIncompletesFail>(UtilisateurExterneActionType.VERIFY_FDT_INCOMPLETES_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.utilisateurExterne.verifyIncomplet.error',
            }))
        ]
        ),
    ));

    /************************************/
    /*******  FINALISER CREATION  *******/
    /************************************/
    public finalizeUserCreation$ = createEffect(() => this.action$.pipe(
        ofType<FinalizeUserCreation>(UtilisateurExterneActionType.FINALIZE_USER_CREATION),
        mergeMap((action: FinalizeUserCreation) => {
            return this.apiService.put<any, UtilisateurExterne>(`hq/pilotage/utilisateurs-externes/${action.utilisateurExterne.id}/actions/assigner-entreprise`,
                action.utilisateurExterne).pipe(
                    map((updatedUtilisateurExterne: UtilisateurExterne) =>
                        new FinalizeUserCreationSuccess(updatedUtilisateurExterne)
                    ),
                    catchError((error: any) => of(new FinalizeUserCreationFail(error))),
                );
        })
    ));

    public finalizeUserCreationFail$ = createEffect(() => this.action$.pipe(
        ofType<FinalizeUserCreationFail>(UtilisateurExterneActionType.FINALIZE_USER_CREATION_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'pilotages.notifications.utilisateurExterne.finalizeUserCreation.error',
            }))
        ]
        ),
    ));
}
