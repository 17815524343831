import { Injectable } from '@angular/core';

import { catchError, switchMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { AddOneAppNotification, NoOperationAction } from '../../../core/store/actions';
import { ApiService } from '../../../ogiv-core/services';
import { BillingReport, NewBillingReport, UpdatedBillingReport } from '../../../shared/models/database/billing-report.model';
import {
    BillingReportActionType,
    CreateBillingReport,
    CreateBillingReportFail,
    CreateBillingReportSuccess,
    DeleteBillingReport,
    DeleteBillingReportSuccess,
    DeleteBillingReportFail,
    ExecuteActionToBillingReport,
    ExecuteActionToBillingReportSuccess,
    ExecuteActionToBillingReportFail,
    LoadBillingReports,
    LoadBillingReportsSuccess,
    LoadBillingReportsFail,
    LoadBillingReportById,
    LoadBillingReportByIdSuccess,
    LoadBillingReportByIdFail,
    LoadBillingReportByIdNoResult,
    UpdateOneBillingReport,
    UpdateOneBillingReportSuccess,
    UpdateOneBillingReportFail
} from '../actions/billing-report.action';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';

@Injectable()
export class BillingReportsEffects {
    _apiService: any;
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    /************************/
    /*******  LOADING *******/
    /************************/
    public loadBillingReports$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingReports>(BillingReportActionType.LOAD_BILLING_REPORTS),
        switchMap((action: LoadBillingReports) => {
            return this.apiService.get<BillingReport[]>(`${action.path}/rapports/rapportsfacturation`).pipe(
                map((returnedBillingReports: BillingReport[]) =>
                    new LoadBillingReportsSuccess(returnedBillingReports)
                ),
                catchError((error: any) => of(new LoadBillingReportsFail(error))),
            );
        }),
    ));

    public loadBillingReportsFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingReportsFail>(BillingReportActionType.LOAD_BILLING_REPORTS_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'billing.notifications.load.error',
            }))
        ]),
    ));

    public loadBillingReportById$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingReportById>(BillingReportActionType.LOAD_BILLING_REPORT_BY_ID),
        switchMap((action: LoadBillingReportById) => {
            return this.apiService.get<BillingReport>(`${action.path}/rapports/rapportsfacturation/${action.billingReportId}`).pipe(
                map((returnedBillingReports: BillingReport) =>
                    (returnedBillingReports)
                        ? new LoadBillingReportByIdSuccess(new BillingReport(returnedBillingReports))
                        : new LoadBillingReportByIdNoResult()
                ),
                catchError((error: any) => of(new LoadBillingReportByIdFail(error))),
            );
        }),
    ));

    public loadBillingReportByIdFail$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingReportByIdFail>(BillingReportActionType.LOAD_BILLING_REPORT_BY_ID_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'billing.notifications.loadById.error',
            }))
        ]),
    ));

    public loadBillingReportByIdNoResult$ = createEffect(() => this.actions$.pipe(
        ofType<LoadBillingReportByIdNoResult>(BillingReportActionType.LOAD_BILLING_REPORT_BY_ID_NO_RESULTS),
        mergeMap(() => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: 'billing.notifications.loadById.noResult',
            }))
        ]),
    ));

    /************************/
    /*******  CREATE  *******/
    /************************/
    public createBillingReport$ = createEffect(() => this.actions$.pipe(
        ofType<CreateBillingReport>(BillingReportActionType.CREATE_BILLING_REPORT),
        mergeMap((action: CreateBillingReport) => {
            return this.apiService.post<BillingReport, NewBillingReport>(`${action.path}/rapports/rapportsfacturation`, action.billingReport).pipe(
                map((createdBillingReport: BillingReport) => new CreateBillingReportSuccess(
                    new BillingReport(createdBillingReport)
                )),
                catchError((error: any) => of(new CreateBillingReportFail(error))),
            );
        }),
    ));

    public createBillingReportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<CreateBillingReportSuccess>(BillingReportActionType.CREATE_BILLING_REPORT_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'billing.notifications.create.success',
            }))
        ]
        ),
    ));

    public createBillingReportFail$ = createEffect(() => this.actions$.pipe(
        ofType<CreateBillingReportFail>(BillingReportActionType.CREATE_BILLING_REPORT_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'billing.notifications.create.error',
            }))
        ]),
    ));

    /************************/
    /*******  UPDATE  *******/
    /************************/
    public updateOneBillingReport$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneBillingReport>(BillingReportActionType.UPDATE_ONE_BILLING_REPORT),
        mergeMap((action: UpdateOneBillingReport) => {
            return this.apiService
                .put<BillingReport, UpdatedBillingReport>(`${action.path}/rapports/rapportsfacturation/${action.billingReportId}`, action.billingReport).pipe(
                    map((updateBillingReport: BillingReport) => new UpdateOneBillingReportSuccess(
                        new BillingReport(updateBillingReport)
                    )),
                    catchError((error: any) => of(new UpdateOneBillingReportFail(error))),
                );
        }),
    ));

    public updateOneBillingReportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneBillingReportSuccess>(BillingReportActionType.UPDATE_ONE_BILLING_REPORT_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'billing.notifications.update.success',
            }))
        ]
        ),
    ));

    public updateOneBillingReportFail$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOneBillingReportFail>(BillingReportActionType.UPDATE_ONE_BILLING_REPORT_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: (errorHttp.error.error.code !== 500 && errorHttp.error.error.description)
                    ? errorHttp.error.error.description
                    : 'billing.notifications.update.error',
            }))
        ]),
    ));

    /************************/
    /*******  DELETE  *******/
    /************************/
    public deleteBillingReport$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteBillingReport>(BillingReportActionType.DELETE_BILLING_REPORT),
        mergeMap((action: DeleteBillingReport) => {
            return this.apiService.delete<BillingReport>(`${action.path}/rapports/rapportsfacturation/${action.billingReportId}`).pipe(
                mergeMap(() => [
                    new DeleteBillingReportSuccess(action.billingReportId),
                ]),
                catchError((error: any) => of(new DeleteBillingReportFail(error))),
            );
        }),
    ));

    public deleteBillingReportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteBillingReportSuccess>(BillingReportActionType.DELETE_BILLING_REPORT_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'billing.notifications.delete.success',
            }))
        ]
        ),
    ));

    public deleteBillingReportFail$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteBillingReportFail>(BillingReportActionType.DELETE_BILLING_REPORT_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500 && errorHttp.error.error.description
                    ? errorHttp.error.error.description
                    : 'billing.notifications.delete.error',
            }))
        ]
        ),
    ));

    /******************************/
    /******* Execute Action *******/
    /******************************/
    public executeActionToBillingReport$ = createEffect(() => this.actions$.pipe(
        ofType<ExecuteActionToBillingReport>(BillingReportActionType.EXECUTE_ACTION_TO_BILLING_REPORT),
        mergeMap((action: ExecuteActionToBillingReport) => {
            return this.apiService.put<BillingReport, any>(`${action.pathChoice}/rapports/rapportsfacturation/${action.billingReportId}/${action.action}`, {
                id: action.billingReportId,
                validatedComment: action.comment
            }).pipe(
                mergeMap((returnedBillingReport: BillingReport) => [
                    new ExecuteActionToBillingReportSuccess(returnedBillingReport, action.action, action.showNotification),
                ]),
                catchError((error: any) => of(new ExecuteActionToBillingReportFail(error, action.action))),
            );
        }),
    ));

    public executeActionToBillingReportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<ExecuteActionToBillingReportSuccess>(BillingReportActionType.EXECUTE_ACTION_TO_BILLING_REPORT_SUCCESS),
        map((actionParameters: ExecuteActionToBillingReportSuccess) => {
            if (actionParameters.showNotification) {
                return new AddOneAppNotification(new SuccessNotification({
                    summary: 'notifications.success',
                    detail: `billing.notifications.${actionParameters.action}.success`,
                }));
            } else {
                return new NoOperationAction();
            }
        }
        ),
    ));

    public executeActionToBillingReportFail$ = createEffect(() => this.actions$.pipe(
        ofType<ExecuteActionToBillingReportFail>(BillingReportActionType.EXECUTE_ACTION_TO_BILLING_REPORT_FAIL),
        mergeMap((actionParameters: ExecuteActionToBillingReportFail) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: actionParameters.error.error.error.code !== 500 && actionParameters.error.error.error.description
                    ? actionParameters.error.error.error.description
                    : `billing.notifications.${actionParameters.action}.error`,
            }))
        ]
        ),
    ));
}
