import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-erase-all-filter',
    templateUrl: './erase-all-filter.component.html',
    styleUrls: ['./erase-all-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EraseAllFilterComponent {
    @Input() public disabled: boolean;

    @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

    public clearAllFilters() {
        this.clicked.emit();
    }
}
