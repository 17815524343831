import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../../ogiv-core/services';
import {
    UtilisateurExterneTeamLeaderActionType,
    LoadUtilisateursExternesTeamLeaderList,
    LoadUtilisateursExternesTeamLeaderListSuccess,
    LoadUtilisateursExternesTeamLeaderListFail
} from '../actions/utilisateurs-externes-team-leaders.action';
import { switchMap, catchError, map } from 'rxjs/operators';
import { UtilisateurExterne } from '../../models/utilisateur-externe.model';
import { of } from 'rxjs';

@Injectable()
export class UtilisateursExternesTeamLeasersEffects {
    constructor(
        private action$: Actions,
        private apiService: ApiService
    ) { }
    /*****************************************/
    /*******  Loading TeamLeader List  *******/
    /*****************************************/
    public loadUtilisateursExternesTeamLeaderList$ = createEffect(() => this.action$.pipe(
        ofType<LoadUtilisateursExternesTeamLeaderList>(UtilisateurExterneTeamLeaderActionType.LOAD_UTILISATEURS_EXTERNES_TEAMLEADER_LIST),
        switchMap(() => {
            return this.apiService.get<UtilisateurExterne[]>('ent/pilotage/utilisateurs-externes/team-leaders').pipe(
                map((returnedUtilisateurExterne: UtilisateurExterne[]) => new LoadUtilisateursExternesTeamLeaderListSuccess(returnedUtilisateurExterne)),
                catchError((error: any) => of(new LoadUtilisateursExternesTeamLeaderListFail(error))),
            );
        })
    ));
}
