import { Action } from '@ngrx/store';

import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { BillingCompany } from '../../../shared/models/database/billing-company.model';

/**
 * Types
 */
export enum BillingCompanyActionType {
    LOAD_BILLING_COMPANY = '[Billing Company] Load Billing Company',
    LOAD_BILLING_COMPANY_SUCCESS = '[Billing Company] Load Billing Company Success',
    LOAD_BILLING_COMPANY_FAIL = '[Billing Company] Load Billing Company Fail',
}

/**
 * ACTIONS
 */
/**
 * LOAD
 */
export class LoadBillingCompany implements Action {
    readonly type = BillingCompanyActionType.LOAD_BILLING_COMPANY;
    constructor(public path: AlternativePath) { }
}

export class LoadBillingCompanySuccess implements Action {
    readonly type = BillingCompanyActionType.LOAD_BILLING_COMPANY_SUCCESS;
    constructor(public billingCompany: BillingCompany[]) { }
}

export class LoadBillingCompanyFail implements Action {
    readonly type = BillingCompanyActionType.LOAD_BILLING_COMPANY_FAIL;
    constructor(public error: any) { }
}

export type BillingCompanyAction =
    | LoadBillingCompany
    | LoadBillingCompanySuccess
    | LoadBillingCompanyFail;
