import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { ApiService } from '../../../ogiv-core/services/api.service';
import { PostLine } from '../../../shared/models/database/post-line.model';
import { Timesheet } from '../../../shared/models/database/timesheet.model';
import {
    CreateTimesheetSuccess,
    PostLineActionTypes,
    CreatePostLine,
    CreatePostLineFail,
    UpdateOnePostLine,
    UpdateOnePostLineFail,
    DeleteOnePostLine,
    DeleteOnePostLineFail,
    CreatePostLineSuccess,
    UpdateOnePostLineSuccess,
    DeleteOnePostLineSuccess,
} from '../actions';
import { ErrorNotification, SuccessNotification } from '../../../core/store/models/app-notification.model';
import { AddOneAppNotification } from '../../../core/store/actions';

@Injectable()
export class PostLinesEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService,
    ) { }

    /************************/
    /*******  CREATE  *******/
    /************************/
    public createPostLine$ = createEffect(() => this.actions$.pipe(
        ofType<CreatePostLine>(PostLineActionTypes.CREATE_POST_LINE),
        mergeMap((action: CreatePostLine) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/postLines`;
                } else {
                    url += `${action.timesheetDateId}/postLines`;
                }
            } else {
                url += `${action.timesheetDateId}/postLines`;
            }

            return this.apiService.post<Timesheet, PostLine>(url, action.postLine).pipe(
                mergeMap((createdTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(createdTimesheet)),
                    new CreatePostLineSuccess(),
                ]),
                catchError((error: any) => of(new CreatePostLineFail(error))),
            );
        }),
    ));

    public createPostLineSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<CreatePostLineSuccess>(PostLineActionTypes.CREATE_POST_LINE_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.postLine.create.success',
            }))
        ]
        ),
    ));

    public createPostLineFail$ = createEffect(() => this.actions$.pipe(
        ofType<CreatePostLineFail>(PostLineActionTypes.CREATE_POST_LINE_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.postLine.create.error',
            }))
        ]),
    ));

    /************************/
    /*******  UPDATE  *******/
    /************************/
    public updateOnePostLine$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOnePostLine>(PostLineActionTypes.UPDATE_ONE_POST_LINE),
        mergeMap((action: UpdateOnePostLine) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/postLines`;
                } else {
                    url += `${action.timesheetDateId}/postLines`;
                }
            } else {
                url += `${action.timesheetDateId}/postLines`;
            }
            return this.apiService.put<Timesheet, PostLine>(url, action.postLine).pipe(
                mergeMap((createdTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(createdTimesheet)),
                    new UpdateOnePostLineSuccess(),
                ]),
                catchError((error: any) => of(new UpdateOnePostLineFail(error))),
            );
        }),
    ));

    public updateOnePostLineSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOnePostLineSuccess>(PostLineActionTypes.UPDATE_ONE_POST_LINE_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.postLine.update.success',
            }))
        ]
        ),
    ));

    public updateOnePostLineFail$ = createEffect(() => this.actions$.pipe(
        ofType<UpdateOnePostLineFail>(PostLineActionTypes.UPDATE_ONE_POST_LINE_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.postLine.update.error',
            }))
        ]),
    ));

    /************************/
    /*******  DELETE  *******/
    /************************/
    public deleteOnePostLine$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOnePostLine>(PostLineActionTypes.DELETE_ONE_POST_LINE),
        mergeMap((action: DeleteOnePostLine) => {
            let url = `${action.path}/fdt/`;
            if (action.imperson && action.imperson.isImpersonnified) {
                if (action.imperson.idAgol) {
                    url += `impersonification/${action.timesheetDateId}/${action.imperson.idAgol}/postLines/${action.postLine.id}`;
                } else {
                    url += `${action.timesheetDateId}/postLines/${action.postLine.id}`;
                }
            } else {
                url += `${action.timesheetDateId}/postLines/${action.postLine.id}`;
            }

            return this.apiService.delete<Timesheet>(url).pipe(
                mergeMap((createdTimesheet: Timesheet) => [
                    new CreateTimesheetSuccess(new Timesheet(createdTimesheet)),
                    new DeleteOnePostLineSuccess()
                ]),
                catchError((error: any) => of(new DeleteOnePostLineFail(error))),
            );
        }),
    ));

    public deleteOnePostLineSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOnePostLineSuccess>(PostLineActionTypes.DELETE_ONE_POST_LINE_SUCCESS),
        mergeMap(() => [
            new AddOneAppNotification(new SuccessNotification({
                summary: 'notifications.success',
                detail: 'timesheet.notifications.postLine.delete.success',
            }))
        ]
        ),
    ));

    public deleteOnePostLineFail$ = createEffect(() => this.actions$.pipe(
        ofType<DeleteOnePostLineFail>(PostLineActionTypes.DELETE_ONE_POST_LINE_FAIL),
        mergeMap((errorHttp: any) => [
            new AddOneAppNotification(new ErrorNotification({
                summary: 'notifications.error',
                detail: errorHttp.error.error.code !== 500
                    ? errorHttp.error.error.description
                    : 'timesheet.notifications.postLine.delete.error',
            }))
        ]),
    ));

    /*************************/
    /*******  LOADING  *******/
    /*************************/
    // public loadPostLines$ = createEffect(() => this.actions$.pipe(
    //     ofType<LoadPostLines>(PostLineActionTypes.LOAD_POST_LINES),
    //     switchMap(() => {
    //         const mappedPostLines = postLines.map(postLine => new PostLine(postLine));
    //         return of(new LoadPostLinesSuccess(mappedPostLines));
    //     }),
    // ));
}
