import { CommonConfiguration } from '@ngxhq/config';
import { PageSelector } from '../models/page-selector.model';

/**
 * Configuration applicative chargée au démarrage à partir du fichier app.json.
 * Les valeurs de la configuration peuvent être accédées seulement après qu'elles ont été chargées : lorsque les APP_INITIALIZER sont terminés ou après que
 * @see configurationAvailable de @see CommonConfigurationService<AppConfiguration> soit terminé.
 * @summary Configuration applicative chargée à partir du fichier app.json
 */
export class AppConfiguration implements CommonConfiguration {
    // IMPORTANT!!!
    // - INITIALISEZ les `= undefined as any` pour chaque propriétés racine pour que Internet Explorer fonctionne correctement.
    baseBackendUrl: string = undefined as any;
    wabConfig: AppUrlConfiguration = undefined as any;
    sapConfig: AppUrlConfiguration = undefined as any;
    pages: AppPagesConfiguration = undefined as any;
    accueilDisplayRoles: boolean = undefined as any;
}

export class AppUrlConfiguration {
    url: string = undefined as any;
    layers: AppLayerConfiguration[] = undefined as any;

}

export const getLayerValue = (urlConfig: AppUrlConfiguration, layerName: string): string | undefined => {
    return urlConfig.layers.find((layer: AppLayerConfiguration) => layer.name === layerName)?.value;
};

export class AppLayerConfiguration {
    name: string = undefined as any;
    value: string = undefined as any;
}

export class AppPagesConfiguration {
    [PageSelector.timesheet]: boolean = undefined as any;
    [PageSelector.reportEnt]: boolean = undefined as any;
    [PageSelector.reportHQ]: boolean = undefined as any;
    [PageSelector.progressReport]: boolean = undefined as any;
    [PageSelector.billingEnt]: boolean = undefined as any;
    [PageSelector.billingHQ]: boolean = undefined as any;
    [PageSelector.clientRequest]: boolean = undefined as any;
    [PageSelector.specialMeasures]: boolean = undefined as any;
    [PageSelector.pilotage]: boolean = undefined as any;
    [PageSelector.ogivEnt]: boolean = undefined as any;
    [PageSelector.ogivTech]: boolean = undefined as any;
    [PageSelector.projectRequest]: boolean = undefined as any;
}
