import { utilisateursExternesAdapter, UtilisateursExternesState } from '../reducers/utilisateurs-externes.reducer';
import { createSelector } from '@ngrx/store';
import { getPilotagesState, PilotagesState } from '../reducers';
import { UtilisateurExterne } from '../../models/utilisateur-externe.model';

const {
    selectAll
} = utilisateursExternesAdapter.getSelectors();

export const getUtilisateursExternesState = createSelector(
    getPilotagesState,
    (state: PilotagesState) => state.utilisateursExternes,
);

export const getUtilisateursExternesLoading = createSelector(
    getUtilisateursExternesState,
    (state: UtilisateursExternesState) => state.loading,
);

export const getUtilisateursExternesLoaded = createSelector(
    getUtilisateursExternesState,
    (state: UtilisateursExternesState) => state.loaded,
);

export const shouldLoadUtilisateursExternes = createSelector(
    getUtilisateursExternesLoading,
    getUtilisateursExternesLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllUtilisateursExternes = createSelector(
    getUtilisateursExternesState,
    selectAll
);

export const getUtilisateursExternesFormatAdresses = createSelector(
    getAllUtilisateursExternes,
    (utilisateursExternes: UtilisateurExterne[]): UtilisateurExterne[] => {
        const utilisateursExternesFormates = utilisateursExternes.map(utilisateurExterne => {
            if (utilisateurExterne.adresse_personnelle) {
                return {
                    ...utilisateurExterne,
                    adresse_personnelle: JSON.parse(utilisateurExterne.adresse_personnelle.toString())
                } as UtilisateurExterne;
            } else {
                return {
                    ...utilisateurExterne
                } as UtilisateurExterne;
            }

        });

        return utilisateursExternesFormates;
    }
);
