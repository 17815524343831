import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { PilotagesState, getPilotagesState } from '../reducers';
import { tableCodeUniteAdapter, TableCodeUniteState } from '../reducers/table-code-unite.reducer';
import { TableCodeUnite } from '../../models/table-code-unite.model';

const {
    selectAll,
    selectEntities,
    selectIds,
} = tableCodeUniteAdapter.getSelectors();

export const getTableCodeUniteState = createSelector(
    getPilotagesState,
    (state: PilotagesState) => state.tableCodeUnite,
);

export const getTableCodesUniteLoading = createSelector(
    getTableCodeUniteState,
    (state: TableCodeUniteState) => state.loading,
);

export const getTableCodesUniteLoaded = createSelector(
    getTableCodeUniteState,
    (state: TableCodeUniteState) => state.loaded,
);

export const shouldLoadTableCodesUnite = createSelector(
    getTableCodesUniteLoading,
    getTableCodesUniteLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllTableCodesUnite = createSelector(
    getTableCodeUniteState,
    selectAll
);

export const getAllFetchedTableCodesUnite = createSelector(
    getAllTableCodesUnite,
    (tableCodeUnites: TableCodeUnite[]) => tableCodeUnites.map((tableCodeUnite: TableCodeUnite) => tableCodeUnite),
);

export const getTableCodesUniteEntities = createSelector(
    getTableCodeUniteState,
    selectEntities
);

export const getTableCodesUniteIds = createSelector(
    getTableCodeUniteState,
    selectIds,
);

export const getTableCodesUniteById = (prop: any) => createSelector(
    getTableCodesUniteEntities,
    (entities: Dictionary<TableCodeUnite>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getTCUByCodeSAPFicheService = (prop: { codeSAP: string, ficheService: string }) => createSelector(
    getAllTableCodesUnite,
    (tableCodeUnites: TableCodeUnite[]): TableCodeUnite[] => {
        return tableCodeUnites.filter((tableCodeUnite: TableCodeUnite) => tableCodeUnite.fiche_service === prop.ficheService && tableCodeUnite.code_sap === prop.codeSAP);
    }
);

export const isTCUFound = (props: { codeSAP: string | null, ficheService: string | null }) => createSelector(
    getAllTableCodesUnite,
    (tableCodeUnites: TableCodeUnite[]): boolean | null => {
        if (props.codeSAP !== undefined && props.codeSAP !== null && props.ficheService !== undefined && props.ficheService !== null) {
            const found = tableCodeUnites.filter((tableCodeUnite: TableCodeUnite) =>
                tableCodeUnite.code_sap === props.codeSAP && tableCodeUnite.fiche_service === props.ficheService);
            return found.length > 0 ? true : null;
        }

        return null;
    }
);
