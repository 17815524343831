import { BilledItem, SelectedBilledItem } from './billed-item.model';

export enum BillingReportStatus {
    created = 'Créé',
    edited = 'Édité',
    exported = 'Exporté',
}

export class NewBillingReport {
    public invoiceNumber?: string;
    public order: string;
    public accountingEntry: string;
    public billedItems?: SelectedBilledItem[];
    public timesheetReportIds?: number[];
}

export class UpdatedBillingReport {
    public id: number;
    public invoiceNumber?: string;
    public order: string;
    public accountingEntry: string;
    public billedItems?: SelectedBilledItem[];
    public timesheetReportIds?: number[];
}

export class BillingReportRawData {
    public id: number;
    public company: string;
    public companyId: number;
    public order: string;
    public accountingEntry: string;
    public invoiceNumber?: string;
    public totalQuantities: number;
    public totalHours: number;
    public status: BillingReportStatus;
    public createBy: string;
    public createdDate: Date;
    public modifiedBy?: string;
    public modifiedDate?: Date;
    public validate?: number;
    public validatedBy?: string;
    public validatedDate?: Date;
    public billedItems?: BilledItem[];
    public timesheetReportIds?: number[];
    public validatedComment?: string;
    public validatedRole?: string;
}

export class BillingReport {
    public id: number;
    public company: string;
    public companyId: number;
    public order: string;
    public accountingEntry: string;
    public invoiceNumber: string;
    public totalQuantities: number;
    public totalHours: number;
    public status: BillingReportStatus;
    public createBy: string;
    public createdDate: Date;
    public modifiedBy?: string;
    public modifiedDate?: Date;
    public validate?: number;
    public validatedBy?: string;
    public validatedDate?: Date;
    public billedItems?: BilledItem[];
    public timesheetReportIds?: number[];
    public validatedComment?: string;
    public validatedRole?: string;

    constructor(data: BillingReportRawData) {
        Object.keys(data).forEach((key: string) => {
            this[key] = data[key];
        });
    }
}
