import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { DomaineDeValeur } from '../../../shared/models/database/domaine-valeur.model';
import { DomaineDeValeurAction, DomaineDeValeurActionTypes } from '../actions';

export interface DomaineDeValeurState extends EntityState<DomaineDeValeur> {
    loading: boolean;
    loaded: boolean;
}

export const domaineDeValeurAdapter: EntityAdapter<DomaineDeValeur> = createEntityAdapter<DomaineDeValeur>({
    selectId: (domaineDeValeur: DomaineDeValeur) => domaineDeValeur.id,
});

export const domaineDeValeurInitialState = domaineDeValeurAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function domaineDeValeurReducer(
    state: DomaineDeValeurState = domaineDeValeurInitialState,
    action: DomaineDeValeurAction,
): DomaineDeValeurState {
    switch (action.type) {
        case DomaineDeValeurActionTypes.LOAD_DOMAINE_VALEUR: {
            return {
                ...state,
                loading: true,
            };
        }

        case DomaineDeValeurActionTypes.LOAD_DOMAINE_VALEUR_SUCCESS: {
            return domaineDeValeurAdapter.setAll(action.valeurs, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case DomaineDeValeurActionTypes.LOAD_DOMAINE_VALEUR_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
