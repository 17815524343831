<div class="fullheight">
    <form [formGroup]="entrepreneurForm" (ngSubmit)="onSubmit()">
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ this.translatePrefix + '.name' | translate }}<span class="required">*</span></mat-label>
                <input matInput type="text" formControlName="nom"/>
                <mat-error *ngIf="entrepreneurForm.controls.nom.invalid">{{ getErrorMessageName() }}</mat-error>
            </mat-form-field>
        </div>
        <div class="iab">
            <div class="iab__input group__field">
                <mat-form-field appearance="standard" class="field__formField">
                    <mat-label>{{ this.translatePrefix + '.abbreviation' | translate }}<span class="required">*</span>
                    </mat-label>
                    <input matInput type="text" formControlName="abreviation" />
                    <mat-error *ngIf="entrepreneurForm.controls.abreviation.invalid">{{ getErrorMessageAbbreviation() }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="iab__verify" *ngIf="diplayAbeviationStatusLogo">
                <div class="verify__checkTime">
                    <div class="checkTime__box">
                        <i *ngIf="logoAbreviationMatchFound === null"class="far fa-circle box__icon"></i>
                        <i *ngIf="logoAbreviationMatchFound === false" class="far fa-check-circle box__accept"></i>
                        <i *ngIf="logoAbreviationMatchFound === true"class="far fa-times-circle box__refused"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ this.translatePrefix + '.address' | translate }}</mat-label>
                <input matInput type="text" formControlName="adresse" />
            </mat-form-field>
        </div>
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ this.translatePrefix + '.responsible' | translate }}</mat-label>
                <input matInput type="text" formControlName="responsable" />
            </mat-form-field>
        </div>
        <div class="group__field">
            <mat-form-field appearance="standard" class="field__formField">
                <mat-label>{{ this.translatePrefix + '.phone' | translate }}</mat-label>
                <input matInput type="text" formControlName="telephone"
                    [patterns]="pattern"
                    [specialCharacters]="['#',':', '(', ')', '-', ' ']"
                    mask="(000) 000-0000||(000) 000-0000    #: 0 ||(000) 000-0000    #: 00 ||(000) 000-0000    #: 000 ||(000) 000-0000    #: 0000 ||(000) 000-0000    #: 00000 ||(000) 000-0000    #: 000000"/>
                <mat-error *ngIf="entrepreneurForm.controls.telephone.invalid">{{ getErrorMessageTelephone() }}
                </mat-error>
            </mat-form-field>
        </div>
        <app-generic-form-buttons
            [disabled]="entrepreneurForm.invalid"
            [cancelTooltipLabel]="'Cancel'"
            [saveTooltipLabel]="'Confirmer'"
            (cancel)="cancelForm()"></app-generic-form-buttons>
    </form>

</div>
