import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UtilisateurExterne } from '../../models/utilisateur-externe.model';
import {
    UtilisateurExterneTeamLeaderAction,
    UtilisateurExterneTeamLeaderActionType
} from '../actions/utilisateurs-externes-team-leaders.action';

export interface UtilisateursExternesTeamLeadersState extends EntityState<UtilisateurExterne> {
    loading: boolean;
    loaded: boolean;
}

export const utilisateursExternesTeamLeadersAdapter: EntityAdapter<UtilisateurExterne> = createEntityAdapter<UtilisateurExterne>({
    selectId: (utilisateurExterne: UtilisateurExterne) => utilisateurExterne.id,
});

export const utilisateursExternesTeamLeadersInitialState = utilisateursExternesTeamLeadersAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function utilisateurExterneTeamLeaderReducer(
    state: UtilisateursExternesTeamLeadersState = utilisateursExternesTeamLeadersInitialState,
    action: UtilisateurExterneTeamLeaderAction,
): UtilisateursExternesTeamLeadersState {
    switch (action.type) {
        case UtilisateurExterneTeamLeaderActionType.LOAD_UTILISATEURS_EXTERNES_TEAMLEADER_LIST: {
            return {
                ...state,
                loading: true,
            };
        }

        case UtilisateurExterneTeamLeaderActionType.LOAD_UTILISATEURS_EXTERNES_TEAMLEADER_LIST_SUCCESS: {
            return utilisateursExternesTeamLeadersAdapter.setAll(action.utilisateursExternesTeamLeader, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case UtilisateurExterneTeamLeaderActionType.LOAD_UTILISATEURS_EXTERNES_TEAMLEADER_LIST_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;

    }
}
