import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { AlternativePath } from '../../../../shared/models/atlernative-path.model';
import { CoreState } from '../../../../core/store/reducers';
import { FormMode } from '../../../../shared/models/form-mode.model';
import { getUserRoles } from '../../../../core/store/selectors';
import { UserRole } from '../../../../shared/models/user-roles.model';

@Component({
    templateUrl: './add-billing-report.component.html',
    styleUrls: ['./add-billing-report.component.scss']
})
export class AddBillingReportComponent implements OnInit {
    public translatePrefix = 'billing.contractor.createReport';
    public userRole: typeof UserRole = UserRole;
    public userRoles$: Observable<UserRole[]> = this.store.pipe(select(getUserRoles));
    public formMode = FormMode.add;
    public pathChoice: AlternativePath;
    public backPressed = false;

    constructor(
        private readonly store: Store<CoreState>,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.pathChoice = this.route.snapshot.data['pathChoice'];
    }
}
