export enum UserRole {
    visualisateur = 'Visualisateur',
    pilote = 'Pilote',
    techinter = 'TechInter',
    tech = 'Tech',
    planificateur = 'Planificateur',
    cgc = 'CGC',
    support = 'Support',
    entrepreneur = 'Entrepreneur',
    chefgroupe = 'ChefGroupe',
    chefequipe = 'ChefEquipe',
}

export const userRoleNameMap = {
    visualisateur: { text: 'Visualisateur' },
    pilote: { text: 'Pilote' },
    techinter: { text: 'TechInter' },
    tech: { text: 'Technicien' },
    planificateur: { text: 'Planificateur' },
    cgc: { text: 'CGC' },
    support: { text: 'Support' },
    entrepreneur: { text: 'Entrepreneur' },
    chefgroupe: { text: 'Chef de groupe' },
    chefequipe: { text: 'Chef d\'équipe' },
};

export function getUserRoleNameMap(value: string): string {
    if (value === undefined || value === null || value === '') {
        return '';
    }

    return userRoleNameMap[value.toLowerCase()].text;
}
