import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { PilotagesState, getPilotagesState } from '../reducers';
import { timesheetChargeNumberAdapter, TimesheetChargeNumberState } from '../reducers/timesheet-charge-number.reducer';
import { TimesheetChargeNumber } from '../../models/timesheet-charge-number.model';
// import { SelectItem } from 'primeng/api';

const {
    selectAll,
    selectEntities,
    selectIds,
} = timesheetChargeNumberAdapter.getSelectors();

export const getTimesheetChargeNumberState = createSelector(
    getPilotagesState,
    (state: PilotagesState) => state.timesheetChargeNumber,
);

export const getTimesheetChargeNumberLoading = createSelector(
    getTimesheetChargeNumberState,
    (state: TimesheetChargeNumberState) => state.loading,
);

export const getTimesheetChargeNumberLoaded = createSelector(
    getTimesheetChargeNumberState,
    (state: TimesheetChargeNumberState) => state.loaded,
);

export const shouldLoadTimesheetChargeNumber = createSelector(
    getTimesheetChargeNumberLoading,
    getTimesheetChargeNumberLoaded,
    (loading: boolean, loaded: boolean) => !loaded && !loading,
);

export const getAllTimesheetChargeNumber = createSelector(
    getTimesheetChargeNumberState,
    selectAll
);

export const getAllFetchedTimesheetChargeNumber = createSelector(
    getAllTimesheetChargeNumber,
    (timesheetsChargeNumber: TimesheetChargeNumber[]) => timesheetsChargeNumber.map((timesheetChargeNumber: TimesheetChargeNumber) => timesheetChargeNumber),
);

export const getTimesheetChargeNumberEntities = createSelector(
    getTimesheetChargeNumberState,
    selectEntities
);

export const getTimesheetChargeNumberIds = createSelector(
    getTimesheetChargeNumberState,
    selectIds,
);

export const getTimesheetChargeNumberById = (prop: any) => createSelector(
    getTimesheetChargeNumberEntities,
    (entities: Dictionary<TimesheetChargeNumber>) => {
        return entities && entities[prop.entityId] || null;
    }
);

export const getAllTimesheetChargeNumberSorted = createSelector(
    getAllTimesheetChargeNumber,
    (timesheetsChargeNumber: TimesheetChargeNumber[]): TimesheetChargeNumber[] => {
        const temporaire = [...timesheetsChargeNumber];
        temporaire.sort((a: TimesheetChargeNumber, b: TimesheetChargeNumber): number => {
            return (a.teamLeader.toLocaleLowerCase()).localeCompare(b.teamLeader.toLocaleLowerCase());
        });
        return temporaire;
    }
);

export const getTimesheetsChargeNumberTeamLeaders = (props: { searchValue: string } = { searchValue: '' }) => createSelector(
    getAllTimesheetChargeNumberSorted,
    (timesheetsChargeNumber: TimesheetChargeNumber[]): string[] => {
        const teamLeaders: string[] = [];
        timesheetsChargeNumber.forEach((timesheet: TimesheetChargeNumber) => {
            if (props.searchValue === '') {
                if (!teamLeaders.includes(timesheet.teamLeader)) {
                    teamLeaders.push(timesheet.teamLeader);
                }
            } else {
                if (timesheet.teamLeader !== null) {
                    if (timesheet.teamLeader.toLocaleLowerCase().includes(props.searchValue.toLocaleLowerCase())
                        && !teamLeaders.includes(timesheet.teamLeader)) {
                        teamLeaders.push(timesheet.teamLeader);
                    }
                }
            }
        });
        return teamLeaders;
    }
);
