import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { Entrepreneur } from '../../models/entrepreneur.model';

/**
 * Types
 */
export enum EntrepreneurActionType {
    LOAD_ENTREPRENEURS = '[Enetrepreneur] Load Entrepreneurs',
    LOAD_ENTREPRENEURS_SUCCESS = '[Enetrepreneur] Load Enetrepreneurs Success',
    LOAD_ENTREPRENEURS_FAIL = '[Enetrepreneur] Load Enetrepreneus Fail',
    LOAD_ENTREPRENEURS_NO_RESULTS = '[Enetrepreneur] Load Enetrepreneurs No Result',

    UPDATE_ONE_ENTREPRENEUR = '[Enetrepreneur] Update One Enetrepreneur',
    UPDATE_ONE_ENTREPRENEUR_SUCCESS = '[Enetrepreneur] Update One Enetrepreneur Success',
    UPDATE_ONE_ENTREPRENEUR_FAIL = '[Enetrepreneur] Update One Enetrepreneur Fail',

    DEACTIVATE_ONE_ENTREPRENEUR = '[Enetrepreneur] Deactivate One Enetrepreneur',
    DEACTIVATE_ONE_ENTREPRENEUR_SUCCESS = '[Enetrepreneur] Deactivate One Enetrepreneur Success',
    DEACTIVATE_ONE_ENTREPRENEUR_FAIL = '[Enetrepreneur] Deactivate One Enetrepreneur Fail',

    REACTIVATE_ONE_ENTREPRENEUR = '[Enetrepreneur] Reactivate One Enetrepreneur',
    REACTIVATE_ONE_ENTREPRENEUR_SUCCESS = '[Enetrepreneur] Reactivate One Enetrepreneur Success',
    REACTIVATE_ONE_ENTREPRENEUR_FAIL = '[Enetrepreneur] Reactivate One Enetrepreneur Fail',
}

/**
 *  ACTIONS
 */

/**
 * LOAD
 */

export class LoadEntrepreneurs implements Action {
    readonly type = EntrepreneurActionType.LOAD_ENTREPRENEURS;
    constructor(public path: AlternativePath) { }
}

export class LoadEntrepreneursNoResult implements Action {
    readonly type = EntrepreneurActionType.LOAD_ENTREPRENEURS_NO_RESULTS;
}

export class LoadEntrepreneursSuccess implements Action {
    readonly type = EntrepreneurActionType.LOAD_ENTREPRENEURS_SUCCESS;
    constructor(public entrepreneurs: Entrepreneur[]) { }
}

export class LoadEntrepreneursFail implements Action {
    readonly type = EntrepreneurActionType.LOAD_ENTREPRENEURS_FAIL;
    constructor(public error: any) { }
}

/**
 * UPDATE
 */
export class UpdateOneEntrepreneur implements Action {
    readonly type = EntrepreneurActionType.UPDATE_ONE_ENTREPRENEUR;
    constructor(public entrepreneur: Entrepreneur, public path: AlternativePath) { }
}

export class UpdateOneEntrepreneurSuccess implements Action {
    readonly type = EntrepreneurActionType.UPDATE_ONE_ENTREPRENEUR_SUCCESS;
    constructor(public entrepreneur: Entrepreneur) { }
}

export class UpdateOneEntrepreneurFail implements Action {
    readonly type = EntrepreneurActionType.UPDATE_ONE_ENTREPRENEUR_FAIL;
    constructor(public error: any) { }
}

/**
 * DEACTIVATE
 */
export class DeactivateOneEntrepreneur implements Action {
    readonly type = EntrepreneurActionType.DEACTIVATE_ONE_ENTREPRENEUR;
    constructor(public entrepreneur: Entrepreneur, public path: AlternativePath) { }
}

export class DeactivateOneEntrepreneurSuccess implements Action {
    readonly type = EntrepreneurActionType.DEACTIVATE_ONE_ENTREPRENEUR_SUCCESS;
    constructor(public entrepreneur: Entrepreneur) { }
}

export class DeactivateOneEntrepreneurFail implements Action {
    readonly type = EntrepreneurActionType.DEACTIVATE_ONE_ENTREPRENEUR_FAIL;
    constructor(public error: any) { }
}

/**
 * REACTIVATE
 */
export class ReactivateOneEntrepreneur implements Action {
    readonly type = EntrepreneurActionType.REACTIVATE_ONE_ENTREPRENEUR;
    constructor(public entrepreneur: Entrepreneur, public path: AlternativePath) { }
}

export class ReactivateOneEntrepreneurSuccess implements Action {
    readonly type = EntrepreneurActionType.REACTIVATE_ONE_ENTREPRENEUR_SUCCESS;
    constructor(public entrepreneur: Entrepreneur) { }
}

export class ReactivateOneEntrepreneurFail implements Action {
    readonly type = EntrepreneurActionType.REACTIVATE_ONE_ENTREPRENEUR_FAIL;
    constructor(public error: any) { }
}

export type EntrepreneurAction =
    | LoadEntrepreneurs
    | LoadEntrepreneursNoResult
    | LoadEntrepreneursSuccess
    | LoadEntrepreneursFail
    | UpdateOneEntrepreneur
    | UpdateOneEntrepreneurSuccess
    | UpdateOneEntrepreneurFail
    | DeactivateOneEntrepreneur
    | DeactivateOneEntrepreneurSuccess
    | DeactivateOneEntrepreneurFail
    | ReactivateOneEntrepreneur
    | ReactivateOneEntrepreneurSuccess
    | ReactivateOneEntrepreneurFail;
