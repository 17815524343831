import { UtilisateurSynchro } from '../../models/utilisateur-synchronisation.model';
import { EtatSynchronisationsAction, EtatSynchronisationsActionType } from '../actions/etat-synchronisations.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface EtatSynchronisationsState extends EntityState<UtilisateurSynchro> {
    loading: boolean;
    loaded: boolean;
}

export const etatSynchronisationsAdapter: EntityAdapter<UtilisateurSynchro> = createEntityAdapter<UtilisateurSynchro>({
    selectId: (utilisateurSynchro: UtilisateurSynchro) => utilisateurSynchro.replicaID,
});

export const etatSynchronisationsInitialState = etatSynchronisationsAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function etatSynchronisationsReducer(
    state: EtatSynchronisationsState = etatSynchronisationsInitialState,
    action: EtatSynchronisationsAction,
): EtatSynchronisationsState {
    switch (action.type) {
        case EtatSynchronisationsActionType.DELETE_ONE_REPLICA:
        case EtatSynchronisationsActionType.LOAD_ETAT_SYNCHRONISATIONS: {
            return {
                ...state,
                loading: true,
            };
        }
        case EtatSynchronisationsActionType.DELETE_ONE_REPLICA_SUCCESS: {
            return etatSynchronisationsAdapter.upsertOne(action.utilisateurSynchro, {
                ...state,
                loading: false,
            });
        }
        case EtatSynchronisationsActionType.DELETE_ONE_REPLICA_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }
        case EtatSynchronisationsActionType.LOAD_ETAT_SYNCHRONISATIONS_SUCCESS: {
            return etatSynchronisationsAdapter.setAll(action.utilisateursSynchro, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case EtatSynchronisationsActionType.LOAD_ETAT_SYNCHRONISATIONS_NO_RESULTS: {
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        }

        case EtatSynchronisationsActionType.LOAD_ETAT_SYNCHRONISATIONS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
