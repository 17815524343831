import { Action } from '@ngrx/store';

import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { BillingItem } from '../../../shared/models/database/billing-item.model';

/**
 * Types
 */
export enum BillingItemActionType {
    LOAD_BILLING_ITEMS = '[Billing Item] Load Billing Items',
    LOAD_SAP_BILLING_ITEMS = '[Billing Item] Load SAP Billing Items',
    LOAD_BILLING_ITEMS_SUCCESS = '[Billing Item] Load Billing Items Success',
    LOAD_BILLING_ITEMS_FAIL = '[Billing Item] Load Billing Items Fail',
}

/**
 * ACTIONS
 */
/**
 * LOAD
 */
export class LoadBillingItems implements Action {
    readonly type = BillingItemActionType.LOAD_BILLING_ITEMS;
    constructor(public path: AlternativePath) { }
}

export class LoadBillingItemsSuccess implements Action {
    readonly type = BillingItemActionType.LOAD_BILLING_ITEMS_SUCCESS;
    constructor(public billingItems: BillingItem[]) { }
}

export class LoadBillingItemsFail implements Action {
    readonly type = BillingItemActionType.LOAD_BILLING_ITEMS_FAIL;
    constructor(public error: any) { }
}

export class LoadSapBillingItems implements Action {
    readonly type = BillingItemActionType.LOAD_SAP_BILLING_ITEMS;
    constructor() { }
}

export type BillingItemAction =
    | LoadBillingItems
    | LoadSapBillingItems
    | LoadBillingItemsSuccess
    | LoadBillingItemsFail;
