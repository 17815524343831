import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { tap } from 'rxjs/operators';

import { UserRole } from '../../shared/models/user-roles.model';
import { AppConfiguration } from '../../shared/config/app-config.model';
import { UIService } from '../../ogiv-core/services';
import { HqSideBarComponent } from '@ngxhq/theme-ngxhq';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
    public pageTimesheet = this.configService && this.configService.pages && this.configService.pages.timesheet || false;
    public pageReportEnt = this.configService && this.configService.pages && this.configService.pages.reportEnt || false;
    public pageReportHQ = this.configService && this.configService.pages && this.configService.pages.reportHQ || false;
    public pageProgressReport = this.configService && this.configService.pages && this.configService.pages.progressReport || false;
    public pageBillingEnt = this.configService && this.configService.pages && this.configService.pages.billingEnt || false;
    public pageBillingHQ = this.configService && this.configService.pages && this.configService.pages.billingHQ || false;
    public pageClientRequest = this.configService && this.configService.pages && this.configService.pages.clientRequest || false;
    public pageMesuresParticulieres = this.configService && this.configService.pages && this.configService.pages.specialMeasures || false;
    public pagePilotage = this.configService && this.configService.pages && this.configService.pages.pilotage || false;
    public pageOgivEnt = this.configService && this.configService.pages && this.configService.pages.ogivEnt || false;
    public pageOgivHQ = this.configService && this.configService.pages && this.configService.pages.ogivTech || false;
    public pageProjectRequest = this.configService && this.configService.pages && this.configService.pages.projectRequest || false;

    public isConnected = true;
    public navBarOpended = true;
    public displayTitle = false;

    public displayName: boolean;
    @Output() expandedChange = new EventEmitter<boolean>();
    @ViewChild(HqSideBarComponent, { static: true })

    public roleFDT = [UserRole.support, UserRole.pilote, UserRole.chefequipe, UserRole.chefgroupe, UserRole.entrepreneur];
    public roleRapportFDT = [UserRole.support, UserRole.pilote, UserRole.chefgroupe, UserRole.entrepreneur];
    public roleRapportsAvancement = [UserRole.support, UserRole.pilote, UserRole.chefgroupe, UserRole.entrepreneur];
    public roleFacturation = [UserRole.support, UserRole.pilote, UserRole.entrepreneur];
    public roleOGIVEntrepreneur = [UserRole.support, UserRole.pilote, UserRole.chefgroupe, UserRole.entrepreneur];

    public roleRapportFDTHQ = [UserRole.pilote, UserRole.techinter, UserRole.tech, UserRole.planificateur, UserRole.cgc];
    public roleFacturationHQ = [UserRole.pilote, UserRole.techinter, UserRole.planificateur, UserRole.cgc];
    public roleClientRequests = [UserRole.pilote, UserRole.techinter, UserRole.tech, UserRole.planificateur];
    public roleProjectRequests = [UserRole.pilote, UserRole.techinter, UserRole.tech, UserRole.planificateur];
    public roleSpecialMeasures = [UserRole.pilote, UserRole.techinter, UserRole.tech, UserRole.planificateur];
    public rolePilotage = [UserRole.pilote];
    public roleOGIVTechnicien = [UserRole.pilote, UserRole.techinter, UserRole.tech, UserRole.planificateur, UserRole.cgc];

    constructor(
        private configService: AppConfiguration,
        private uiService: UIService,
    ) {
        this.navBarOpended = this.uiService.getMenuIsExpended();
        this.uiService.menuIsExpended$.pipe(
            tap((isExpended: boolean) => {

                this.navBarOpended = isExpended;

                let time = 0;
                if (isExpended) {
                    time = 99;
                }
                setTimeout(() => this.displayTitle = isExpended, time);
            })
        ).subscribe();
    }

    public ngOnInit(): void {
        this.isConnected = !(window.location.href.includes('deconnexion'));

    }

    public externalInternPath(path: string): void {
        window.open(`/${path}/`, '_blank');
    }
}
