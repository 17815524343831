import { Action } from '@ngrx/store';
import { AlternativePath } from '../../../shared/models/atlernative-path.model';
import { UtilisateurSynchro } from '../../models/utilisateur-synchronisation.model';

/**
 * Types
 */
export enum EtatSynchronisationsActionType {
    LOAD_ETAT_SYNCHRONISATIONS = '[EtatSynchronisations] Load Etat Synchronisations',
    LOAD_ETAT_SYNCHRONISATIONS_SUCCESS = '[EtatSynchronisations] Load Etat Synchronisations Success',
    LOAD_ETAT_SYNCHRONISATIONS_FAIL = '[EtatSynchronisations] Load Etat Synchronisations Fail',
    LOAD_ETAT_SYNCHRONISATIONS_NO_RESULTS = '[EtatSynchronisations] Load Etat Synchronisations No Result',

    DELETE_ONE_REPLICA = '[EtatSynchronisations] Delete One Replica',
    DELETE_ONE_REPLICA_SUCCESS = '[EtatSynchronisations] Delete One Replica Success',
    DELETE_ONE_REPLICA_FAIL = '[EtatSynchronisations] Delete One Replica Fail',
}

/**
 * ACTIONS
 */
/**
 * LOAD
 */

export class LoadEtatSynchronisations implements Action {
    readonly type = EtatSynchronisationsActionType.LOAD_ETAT_SYNCHRONISATIONS;
    constructor(public path: AlternativePath) { }
}

export class LoadEtatSynchronisationsNoResult implements Action {
    readonly type = EtatSynchronisationsActionType.LOAD_ETAT_SYNCHRONISATIONS_NO_RESULTS;
}

export class LoadEtatSynchronisationsSuccess implements Action {
    readonly type = EtatSynchronisationsActionType.LOAD_ETAT_SYNCHRONISATIONS_SUCCESS;
    constructor(public utilisateursSynchro: UtilisateurSynchro[]) { }
}

export class LoadEtatSynchronisationsFail implements Action {
    readonly type = EtatSynchronisationsActionType.LOAD_ETAT_SYNCHRONISATIONS_FAIL;
    constructor(public error: any) { }
}

/**
 * DELETE
 */
export class DeleteOneReplica implements Action {
    readonly type = EtatSynchronisationsActionType.DELETE_ONE_REPLICA;
    constructor(public utilisateurSynchro: UtilisateurSynchro, public path: AlternativePath) { }
}

export class DeleteOneReplicaSuccess implements Action {
    readonly type = EtatSynchronisationsActionType.DELETE_ONE_REPLICA_SUCCESS;
    constructor(public utilisateurSynchro: UtilisateurSynchro) { }
}

export class DeleteOneReplicaFail implements Action {
    readonly type = EtatSynchronisationsActionType.DELETE_ONE_REPLICA_FAIL;
    constructor(public error: any) { }
}

export type EtatSynchronisationsAction =
    | LoadEtatSynchronisations
    | LoadEtatSynchronisationsNoResult
    | LoadEtatSynchronisationsSuccess
    | LoadEtatSynchronisationsFail
    | DeleteOneReplica
    | DeleteOneReplicaSuccess
    | DeleteOneReplicaFail;
