import { OgivHelpers } from '../../../shared/helpers/ogiv.helper';

export class BaseAppNotification {
    public summary: string;
    public detail?: string;
    public arguments?: any;
}

export class AppNotification extends BaseAppNotification {
    public severity: string;
    public id: string = OgivHelpers.uuidv4();
    public timestamp: number = Date.now();
    public arguments: any = undefined;

    public constructor(baseNotification: BaseAppNotification) {
        super();
        Object.keys(baseNotification).forEach(k => this[k] = baseNotification[k]);
    }
}

export class SuccessNotification extends AppNotification { severity = 'success'; }
export class WarnNotification extends AppNotification { severity = 'warning'; }
export class InfoNotification extends AppNotification { severity = 'info'; }
export class ErrorNotification extends AppNotification { severity = 'error'; }
