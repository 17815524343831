export class GestionCodesSAP {
    id: number;
    fiche_service: string | null;
    service: string;
    code: string | null;
    article: boolean | null;
    article_unite: string | null;
    article_nature_comptable: number | null;
    repas: boolean | null;
    transport: boolean | null;
    emploi: boolean | null;
    equipement: boolean | null;
    unite_travail: boolean | null;
    cree_le: Date | null;
    modifie_le: Date | null;
    modifie_par: string | null;
    statut: string | null;
    ratio: number | null;
}

export enum GestionCodesSAPStatus {
    new = 'Nouveau',
    all = '',
    treated = 'Traité',
}
