<!-- <h1> {{ translatePrefix + 'titre' | translate }}</h1> -->
<app-generic-module-header
    [moduleLogo]="'fas fa-question'"
    [moduleTitle]="translatePrefix + 'titre' | translate"
    [hideButton]="true"
></app-generic-module-header>

<mat-tab-group [animationDuration]="'0'" #mainTabs class="demandesClientBasic__groupe">
    <mat-tab #demandesOuvertes>
        <ng-template mat-tab-label>
            <span>{{ translatePrefix + 'tab-demandes-ouvertes' | translate }}
            </span>
        </ng-template>
        <ng-template matTabContent>
            <ng-container>
                <app-demandes-client-list>
                </app-demandes-client-list>
            </ng-container>
        </ng-template>
    </mat-tab>

    <mat-tab #ecartOgivSap>
        <ng-template mat-tab-label>
            <span>{{ translatePrefix + 'tab-ecarts' | translate }}
            </span>
        </ng-template>
        <ng-template matTabContent>
            <ng-container>
                <app-demandes-client-ecarts>
                </app-demandes-client-ecarts>
            </ng-container>
        </ng-template>
    </mat-tab>
</mat-tab-group>
