import { createSelector } from '@ngrx/store';

import { BillingState, getBillingState } from '../reducers';
import { billingSapEntriesAdapter, BillingSapEntriesState } from '../reducers/billing-sap-entries.reducer';

const {
    selectAll
} = billingSapEntriesAdapter.getSelectors();

export const getBillingSapEntriesState = createSelector(
    getBillingState,
    (state: BillingState) => state.billingSapEntries,
);

export const getBillingSapEntriesLoading = createSelector(
    getBillingSapEntriesState,
    (state: BillingSapEntriesState) => state.loading,
);

export const getBillingSapEntriesLoaded = createSelector(
    getBillingSapEntriesState,
    (state: BillingSapEntriesState) => state.loaded,
);

export const getBillingSapEntries = createSelector(
    getBillingSapEntriesState,
    selectAll
);
