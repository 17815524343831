import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { DemandeClient } from '../../../shared/models/database/demande-client.model';
import { DemandesClientEcartsAction, DemandesClientEcartsActionTypes } from '../actions/demandes-client-ecarts.actions';

export interface DemandesClientEcartsState extends EntityState<DemandeClient> {
    loading: boolean;
    loaded: boolean;
}

export const demandesClientEcartsAdapter: EntityAdapter<DemandeClient> = createEntityAdapter<DemandeClient>({
    selectId: (demandeClient: DemandeClient) => demandeClient.numeroOrdre,
});

export const demandesClientEcartsInitialState = demandesClientEcartsAdapter.getInitialState({
    loading: false,
    loaded: false,
});

export function demandesClientEcartsReducer(
    state: DemandesClientEcartsState = demandesClientEcartsInitialState,
    action: DemandesClientEcartsAction
): DemandesClientEcartsState {
    switch (action.type) {
        case DemandesClientEcartsActionTypes.DEMANDES_CLIENT_ECARTS_LOAD: {
            return {
                ...state,
                loading: true,
            };
        }

        case DemandesClientEcartsActionTypes.DEMANDES_CLIENT_ECARTS_LOAD_SUCCESS: {
            return demandesClientEcartsAdapter.setAll(action.demandesClient, {
                ...state,
                loading: false,
                loaded: true,
            });
        }

        case DemandesClientEcartsActionTypes.DEMANDES_CLIENT_ECARTS_LOAD_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        default:
            return state;
    }
}
