<app-element-loader *ngIf="utilisateursExternesLoading$ | async"
    [size]="loaderLogoSize"
    [displayLabel]="true"
    [labelFontSize]="20"></app-element-loader>
<p-table #utilisateursExternesDataTable [columns]="columns" [value]="$any(utilisateursExternes$ | async)" [paginator]="true" [rows]="10"
    [sortField]="sortField" [sortOrder]="sortOrder" [autoLayout]="true" [reorderableColumns]="true" [rowsPerPageOptions]="[10,25,50,100,200]"
    currentPageReportTemplate="Affiche de {first} à {last} sur {totalRecords} items" [showCurrentPageReport]="true"
    (onFilter)="saveFilteredValues(utilisateursExternesDataTable)">

    <ng-template pTemplate="caption">
        <div class="table__header">
            <div class="header__search">
                <div class="search__search">
                    <i class="fas fa-search search__icon"></i>
                    <input type="text" class="search__input" pInputText size="30"
                        [placeholder]="'common.search' | translate" [attr.aria-placeholder]="'common.search' | translate"
                        (input)="inputGlobalFilter($event, 'contains')">
                </div>
            </div>
            <div class="header__button">
                <div class="button__add">
                    <button id="formation-button" pButton icon="fas fa-plus" class="ui-button-raised" (click)="showCreateNewUser()"
                        [label]="translatePrefix + '.boutons.ajouter-utilisateur' | translate"
                        [pTooltip]="translatePrefix + '.tooltip.ajouter-utilisateur' | translate" tooltipPosition="top">
                    </button>
                </div>
                <div class="button__formation">
                    <button id="formation-button" [disabled]="selectedUsersFormation.length === 0 || selectedUsersFormation.length > 200" pButton icon="fa fa-id-badge" class="ui-button-raised" (click)="showProfilsFormationPlusieursUtilisateurs()"
                        [label]="translatePrefix + '.boutons.afficher-profil-formation' | translate:nbUtilisateurs"
                        [pTooltip]="translatePrefix + '.tooltip.afficher-plusieurs-profils' | translate" tooltipPosition="top">
                    </button>
                    <div>
                        <h6 class="profil-formation-warning" *ngIf="selectedUsersFormation.length === 0">{{ 'pilotages.general.profil-formation.min' | translate }}</h6>
                        <h6 class="profil-formation-warning" *ngIf="selectedUsersFormation.length > 200"> {{ 'pilotages.general.profil-formation.max' | translate }}</h6>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
                <th *ngIf="col.type === 'shortTag'">
                </th>
                <th *ngIf="col.type !== 'tag' && col.type !== 'shortTag'" [pSortableColumn]="col.field" pReorderableColumn>
                    <div class="p-d-flex">
                        {{ translatePrefix + '.' + col.header | translate }}

                        <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                            [attr.aria-label-desc]="'common.sortDesc' | translate"
                            [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
                    </div>
                </th>
                <th *ngIf="col.type === 'tag'" [pSortableColumn]="col.field" pReorderableColumn  [ngStyle]="{ 'width': col.width }">
                    <div class="p-d-flex">
                        {{ translatePrefix + '.' + col.header | translate }}

                        <p-sortIcon [field]="col.field" [attr.aria-label]="'common.sort' | translate"
                            [attr.aria-label-desc]="'common.sortDesc' | translate"
                            [attr.aria-label-asc]="'common.sortAsc' | translate"></p-sortIcon>
                    </div>
                </th>
            </ng-container>
            <th class="sticky-column">{{ 'common.actions' | translate }}</th>
        </tr>
        <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <div *ngSwitchCase="'indicator'">
                </div>
                <div *ngSwitchCase="'statut'">
                    <p-dropdown appendTo="body" [options]="statuses"
                        (onChange)="utilisateursExternesDataTable.filter($event.value, 'statut', 'equals')"
                        styleClass="p-column-filter-sm"
                        [placeholder]="'pilotages.status-dropdown.placeholder' | translate" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span>{{option.label}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div *ngSwitchCase="'horaire'">
                    <p-dropdown appendTo="body" [options]="scheduleStatuses"
                        [style]="{'width':'fit-content'}"
                        (onChange)="utilisateursExternesDataTable.filter($event, col.field, 'horaire')"
                        styleClass="p-column-filter-sm"
                        [placeholder]="'pilotages.horaire-dropdown.placeholder' | translate" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span>{{option.label}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div *ngSwitchCase="'habilitation'">
                    <p-dropdown appendTo="body" [options]="habilitationStatuses"
                        [style]="{'width':'fit-content'}"
                        (onChange)="utilisateursExternesDataTable.filter($event, col.field, 'habilitation')"
                        styleClass="p-column-filter-sm"
                        [placeholder]="'pilotages.habilitation-dropdown.placeholder' | translate" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span>{{option.label}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div *ngSwitchCase="'modifie_le'" style="width: 175px;">
                    <p-calendar
                        [(ngModel)]="dateFiltersModifieLe"
                        appendTo="body"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="'pilotages.general.date-filter' | translate"
                        (onClearClick)="utilisateursExternesDataTable.filter($event, col.field, 'dateRangeFilterModifieLe')"
                        (onSelect)="utilisateursExternesDataTable.filter($event, col.field, 'dateRangeFilterModifieLe')">
                    </p-calendar>
                </div>
                <div *ngSwitchCase="'cree_le'" style="width: 175px;">
                    <p-calendar
                        [(ngModel)]="dateFiltersCreeLe"
                        appendTo="body"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="'pilotages.general.date-filter' | translate"
                        (onClearClick)="utilisateursExternesDataTable.filter($event, col.field, 'dateRangeFilterCreeLe')"
                        (onSelect)="utilisateursExternesDataTable.filter($event, col.field, 'dateRangeFilterCreeLe')">
                    </p-calendar>
                </div>
                <div *ngSwitchCase="'date_fin'" style="width: 175px;">
                    <p-calendar
                        [(ngModel)]="dateFiltersDateFin"
                        appendTo="body"
                        selectionMode="range"
                        [readonlyInput]="false"
                        dateFormat="dd-mm-yy"
                        [showButtonBar]="true"
                        [placeholder]="'pilotages.general.date-filter' | translate"
                        (onClearClick)="utilisateursExternesDataTable.filter($event, col.field, 'dateRangeFilterDateFin')"
                        (onSelect)="utilisateursExternesDataTable.filter($event, col.field, 'dateRangeFilterDateFin')">
                    </p-calendar>
                </div>
                <div *ngSwitchDefault>
                    <input pInputText *ngSwitchDefault type="text" styleClass="p-column-filter"
                        (input)="inputFilterColumn($event, col.field, 'contains')"
                        [placeholder]="'common.filter' | translate"
                        [attr.aria-placeholder]="'common.filter' | translate">
                </div>

            </th>
            <th class="sticky-column"></th>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length+1">
                <h3>
                    {{translatePrefix + '.empty' | translate}}
                </h3>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [pReorderableRow]="rowIndex">
            <td *ngFor="let col of columns">
                <ng-container [ngSwitch]="true">
                    <ng-container *ngSwitchCase="col.type === 'tag'">
                        <app-tag [status]="rowData[col.field]" [capitalize]="false">
                            {{  translatePrefix + '.' + rowData[col.field] | translate}}
                        </app-tag>
                    </ng-container>
                    <ng-container *ngSwitchCase="col.type === 'shortTag'">
                        <div class="indicatorContainer">
                            <app-tag [isIndicator]="true" [status]="rowData['statut']"></app-tag>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="col.field === 'numero_cellulaire' || col.field === 'telephone' || col.field === 'telephone_residence'">
                        <label>
                            {{ rowData[col.field] | mask: "(000) 000-0000 ,000000" }}
                        </label>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <ng-container
                            *ngIf="col.field === 'adresse_personnelle' && rowData[col.field] !== '' && rowData[col.field] !== null; else horaire">
                            {{rowData[col.field].numero_civique}}
                            {{rowData[col.field].rue}}
                            {{rowData[col.field].ville}}
                            {{rowData[col.field].code_postal}}
                        </ng-container>

                        <ng-template #horaire>
                            <ng-container *ngIf="col.field === 'horaire'; else habilitation">
                                <span>
                                    {{  (rowData[col.field] === null || rowData[col.field] === '' || rowData[col.field] === 'Aucun' ? 'pilotages.general.horaire.Aucun' : 'pilotages.general.horaire.' + rowData[col.field]) | translate}}
                                </span>
                            </ng-container>
                        </ng-template>

                        <ng-template #habilitation>
                            <ng-container *ngIf="col.field === 'habilitation'; else cree">
                                {{ (rowData[col.field] === null || rowData[col.field] === '' || rowData[col.field] === 'Inconnu' ? 'pilotages.general.habilitation.Inconnu' : 'pilotages.general.habilitation.' + rowData[col.field]) | translate}}
                            </ng-container>
                        </ng-template>

                        <ng-template #cree>
                            <ng-container *ngIf="col.field === 'cree_le'; else modifie">
                                {{ rowData[col.field] | date:'dd-MM-yyyy' }}
                            </ng-container>
                        </ng-template>
                        <ng-template #modifie>
                            <ng-container *ngIf="col.field === 'modifie_le'; else dateFin">
                                {{ rowData[col.field] | date:'dd-MM-yyyy' }}
                            </ng-container>
                        </ng-template>
                        <ng-template #dateFin>
                            <ng-container *ngIf="col.field === 'date_fin'; else default">
                                {{ rowData[col.field] | date:'dd-MM-yyyy' }}
                            </ng-container>
                        </ng-template>
                        <ng-template #default>
                            {{ rowData[col.field] }}
                        </ng-template>
                    </ng-container>
                </ng-container>
            </td>
            <td class="sticky-column">
                <ng-container *ngIf="shouldDisplayActionButton(rowData)">
                    <app-generic-other-menu
                        [displayMenu]="true"
                        [addborder]="true"
                        [myOwnMenu]="true"
                        [toolTipLabel]="translatePrefix + '.options' | translate"
                        [myOwnMenuListItems]="currentActionButton"
                        (myOwnMenuItemPressed)="menuItemPressed($event, rowData)">
                    </app-generic-other-menu>
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>
