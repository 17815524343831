import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { WeekdaysStatus, WeekdayStatus } from '../../models/database/timesheet-report-summary.model';
import { requireCheckboxesToBeCheckedValidator } from '../../validators/require-checkboxes-to-be-checked.validator';
import { CommentFormModalData } from './comment-form-modal-data.model';

@Component({
    selector: 'app-comment-form-modal',
    templateUrl: './comment-form-modal.component.html',
    styleUrls: ['./comment-form-modal.component.scss'],
})
export class CommentFormModalComponent {
    public icon: string;
    public commentForm: FormGroup;
    public weekDays: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    public weekdaysStatus: WeekdaysStatus | null;
    public displayWeekDays = false;
    public isHq = false;
    private quantityOfCheck = 0;
    public maxLength: number | null;

    constructor(
        public dialogRef: MatDialogRef<CommentFormModalComponent>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: CommentFormModalData,
    ) {
        this.weekdaysStatus = data.weekdaysStatus || null;
        this.displayWeekDays = this.weekdaysStatus !== null;

        this.isHq = data.isHq || false;
        if (this.isHq) {
            this.displayWeekDays = false;
            this.quantityOfCheck = 0;
        } else {
            this.displayWeekDays = this.weekdaysStatus !== null;
            this.quantityOfCheck = 1;
        }
        this.maxLength = data.maxLength;

        this.commentForm = this.formBuilder.group({
            comment: ['', [Validators.required]],
            checkBoxGroup: this.formBuilder.group({
                day0: [false],
                day1: [false],
                day2: [false],
                day3: [false],
                day4: [false],
                day5: [false],
                day6: [false],
            }, { validators: requireCheckboxesToBeCheckedValidator(this.quantityOfCheck) })
        });

        this.fillinDays();
    }

    private fillinDays(): void {
        if (this.displayWeekDays && this.weekdaysStatus !== null) {
            for (const [key, value] of Object.entries(this.weekdaysStatus)) {
                const myValue = value as WeekdayStatus;
                if (myValue.isRefused === null || myValue.isRefused === false) {
                    (this.commentForm.controls.checkBoxGroup as FormGroup).controls[key].setValue(false);
                } else {
                    (this.commentForm.controls.checkBoxGroup as FormGroup).controls[key].setValue(true);
                }

                if (myValue.isDisabled) {
                    (this.commentForm.controls.checkBoxGroup as FormGroup).controls[key].disable();
                }
            }
        }
    }

    public onClose(): void {
        this.dialogRef.close();
    }

    private getRejectedDays(): string[] {
        const rejected: string[] = [];
        const checkBoxValues = this.commentForm.value.checkBoxGroup;

        for (const [key, value] of Object.entries(checkBoxValues)) {
            if (value) {
                rejected.push(key);
            }
        }

        return rejected;
    }

    public onSubmit(): void {
        const { valid, value: { comment } } = this.commentForm;

        if (this.isHq) {
            if (valid && comment) {
                this.dialogRef.close(comment);
            }
        } else {
            if (this.weekdaysStatus !== null) {
                const rejectedDays = this.getRejectedDays();
                if (rejectedDays.length > 0 && comment) {
                    this.dialogRef.close({
                        rejectedDays: rejectedDays,
                        comment: comment
                    });
                }
            } else {
                if (valid && comment) {
                    this.dialogRef.close(comment);
                }
            }
        }

    }
}
